import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-plan',
  templateUrl: './add-edit-plan.component.html',
  styleUrls: ['./add-edit-plan.component.scss'],
})
export class AddEditPlanComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  listOfRoutes = [];
  selectedRoute: string = '';
  selectedType: any;
  popular: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  listOfFeatures: any = [];

  nameControl = new FormControl('Select Plan', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  subTypeControl = new FormControl('Select Plan SubType', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  featureControl = new FormControl('');
  // listingPriceControl = new FormControl('', [Validators.required]);
  // sellingPriceControl = new FormControl('', [Validators.required]);
  // rzpPlanIdControl = new FormControl('', [
  //   Validators.required,
  //   Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  // ]);

  addPlanForm: FormGroup = new FormGroup({
    name: this.nameControl,
    subType: this.subTypeControl,
    feature: this.featureControl,
    // description: this.descriptionControl,
    // listingPrice: this.listingPriceControl,
    // sellingPrice: this.sellingPriceControl,
    // rzpPlanId: this.rzpPlanIdControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.addPlanForm = this.formBuilder.group({
      name: this.nameControl,
      subType: this.subTypeControl,
      feature: this.featureControl,
      // description: this.descriptionControl,
      // listingPrice: this.listingPriceControl,
      // sellingPrice: this.sellingPriceControl,
      // rzpPlanId: this.rzpPlanIdControl,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addPlanForm = this.formBuilder.group({
        name: this.nameControl,
        subType: this.subTypeControl,
        feature: this.featureControl,
        // description: this.descriptionControl,
        // listingPrice: this.listingPriceControl,
        // sellingPrice: this.sellingPriceControl,
        // rzpPlanId: this.rzpPlanIdControl,
      });
      this.addPlanForm.get('name')?.setValue(this.dataToEdit['name']);
      this.addPlanForm.get('subType')?.setValue(this.dataToEdit['subType']);
      this.listOfFeatures = this.dataToEdit['features'];
      this.selectedType = this.dataToEdit['name'];

      if (this.selectedType != 'Free') {
        this.addPlanFields();
        this.addPlanForm
          .get('listingPrice')
          ?.setValue(this.dataToEdit['listingPrice']);
        this.addPlanForm
          .get('sellingPrice')
          ?.setValue(this.dataToEdit['sellingPrice']);
        this.addPlanForm
          .get('rzpPlanId')
          ?.setValue(this.dataToEdit['rzpPlanId']);
      }
      this.popular = this.dataToEdit['isPopular'];
    }
  }

  typeSelected(event: any) {
    this.selectedType = event.target.value;
    if (this.selectedType != 'Free') {
      this.addPlanFields();
    } else {
      this.addPlanForm.removeControl('listingPrice');
      this.addPlanForm.removeControl('sellingPrice');
      this.addPlanForm.removeControl('rzpPlanId');
    }
  }

  addPlanFields() {
    this.addPlanForm.addControl(
      'listingPrice',
      this.formBuilder.control('', [Validators.required])
    );
    this.addPlanForm.addControl(
      'sellingPrice',
      this.formBuilder.control('', [Validators.required])
    );
    this.addPlanForm.addControl(
      'rzpPlanId',
      this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ])
    );
  }

  setIsPopular(event: any) {
    this.popular = event.target.checked;
  }

  routeChangeHandler(event: any) {
    this.addPlanForm.get('route')?.setValue(event.value);
    this.selectedRoute = event.value;
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addFeature() {
    if (this.addPlanForm.get('feature')?.value != '') {
      this.listOfFeatures.push(this.addPlanForm.get('feature')?.value);
      this.addPlanForm.get('target')?.setValue('');
    }
  }

  removeFeature(tag: String) {
    this.listOfFeatures = this.listOfFeatures.filter(
      (item: String) => item != tag
    );
  }

  addEditPlan() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('planId', this.dataToEdit['_id']);
    } else {
      this.formdata.append('planId', '0');
    }

    this.formdata.append('name', this.addPlanForm.get('name')?.value);
    this.formdata.append('subType', this.addPlanForm.get('subType')?.value);

    if (this.selectedType == 'Free') {
      this.formdata.append('rzpPlanId', '');
      this.formdata.append('listingPrice', '0');
      this.formdata.append('sellingPrice', '0');
    } else {
      this.formdata.append(
        'rzpPlanId',
        this.addPlanForm.get('rzpPlanId')?.value
      );
      this.formdata.append(
        'listingPrice',
        this.addPlanForm.get('listingPrice')?.value
      );
      this.formdata.append(
        'sellingPrice',
        this.addPlanForm.get('sellingPrice')?.value
      );
    }

    this.formdata.append('isPopular', this.popular.toString());
    this.formdata.append('features', JSON.stringify(this.listOfFeatures));

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdatePlan}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addPlanForm.reset();
                this.selectedRoute = '';
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
