<div class="main-container" *ngIf="listOfUser.length>0">
    <table class="table-style">
        <thead class="table-heading">
            <th class="table-heading-content">Name</th>
            <th class="table-heading-content">Email</th>
            <th class="table-heading-content" style="width: 18rem;">Phone Number</th>
            <th class="table-heading-content" style="width: 18rem;">Date of enrollment</th>
            <th class="table-heading-content">Course Name</th>

        </thead>
        <div style="height: 0.6em;"> </div>
        <tbody>
            <tr class="table-data" *ngFor="let user of listOfUser">
                <th class="table-data-content">{{user['name']}}</th>
                <th class="table-data-content">{{user['email']}}</th>
                <th class="table-data-content">{{user['mobileNo']}}</th>
                <th class="table-data-content">{{user['joinedDate'] |date}}</th>
                <th class="table-data-content">{{user['courseName']}}</th>
            </tr>
        </tbody>
    </table>

</div>