import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-quizzes',
  templateUrl: './quizzes.component.html',
  styleUrls: ['./quizzes.component.scss'],
})
export class QuizzesComponent implements OnInit {
  userDetail: any;
  constructor(private router: Router, public as: AppService, private matDialog: MatDialog,
    private toastr: ToastrService) {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }

  ngOnInit(): void {
    this.fetchQuiz();
  }

  isLoading: boolean = true;
  listOfQuizOriginal: any = [];
  listOfQuiz: any = [];

  async fetchQuiz() {
    this.isLoading = true;
    this.listOfQuizOriginal = [];
    this.listOfQuiz = [];
    const param = {
      quizId: '0',
    };

    const URL = `${webApi.domain + webApi.endPoint.fetchAllQuiz}`;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
    await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfQuiz = data['result'];
        this.listOfQuizOriginal = this.listOfQuiz;
        this.listOfQuiz.forEach((qz: any) => {
          qz['isChangingStatus'] = false;
          qz['isDeleting'] = false;
        });
        // console.log(this.listOfQuiz);
        this.isLoading = false;
      }
    });
  }
  searchQuiz(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let newCourseList: any = [];
    this.listOfQuiz = [...this.listOfQuizOriginal];
    if (serachQuery != '') {
      this.listOfQuiz.forEach((course: any) => {
        if (String(course['title']).toLowerCase().includes(String(serachQuery).toLowerCase())) {
          newCourseList.push(course);
        }
      });
      this.listOfQuiz = [...newCourseList];
    } else {
      this.listOfQuiz = [...this.listOfQuizOriginal];

    }
  }
  deleteQuiz(quiz: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { 'source': 'Quiz Module', 'quiz': quiz }
    let dialogRef = this.matDialog.open(DeleteModuleActivityDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfQuiz.forEach((qz: any) => {
          qz['isDeleting'] = false;
        });
        return;
      } else {
        const param = {
          "quizId": res['value']['_id']
        };
        const URL = `${webApi.domain + webApi.endPoint.deleteQuiz}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetail['accessToken']}`,
        });
        await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
          if (data['success']) {
            this.listOfQuiz.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                this.listOfQuiz.splice(index, 1);
              }
            });
            this.showSuccessToaster('Quiz Delete Successfully', 'Success');
          } else {
            this.listOfQuiz.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                quiz['isDeleting'] = false;
              }
            });
            this.showErrorToaster('Error Occurred while deleteing quiz. Try again', 'Error');
          }
        });
      }
    });
  }

  async updateQuizStatus(quiz: any) {
    this.listOfQuiz.forEach((qz: any) => {
      if (qz['_id'] == quiz['_id']) {
        qz['isChangingStatus'] = true;
      }
    });
    const param = {
      "quizId": quiz['_id'],
      "status": quiz['isActive'] ? false : true
    };
    const URL = `${webApi.domain + webApi.endPoint.updateQuizStatus}`;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
    await this.as.fetchDetail(param, URL,httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfQuiz.forEach((qz: any) => {
          if (qz['_id'] == quiz['_id']) {
            qz['isActive'] = quiz['isActive'] ? false : true;
            qz['isChangingStatus'] = false;
          }
        });
        this.showSuccessToaster(!quiz['isActive'] ? 'Quiz Unpublished Successfully' : 'Quiz Published Successfully', 'Success');
      }
    });
  }
  // async deleteQuiz(quiz: any) {
  //   this.listOfQuiz.forEach((crs: any) => {
  //     if (crs['_id'] == quiz['_id']) {
  //       crs['isDeleting'] = true;
  //     }
  //   });
  //   const param = {
  //     "quizId": quiz['_id'],
  //   };
  //   try {


  //     const URL = `${webApi.domain + webApi.endPoint.deleteQuizAlong}`;
  //     await this.as.deleteSingleModuleFromService(param, URL,).then((data: any) => {
  //       if (data['success']) {
  //         this.listOfQuiz.forEach((qz: any, index: number) => {
  //           if (qz['_id'] == quiz['_id']) {
  //             this.listOfQuiz.splice(index, 1);
  //           }
  //         });
  //         this.showSuccessToaster('Quiz Deleted Successfully', 'Success');
  //       }
  //     });
  //   } catch (error) {
  //     this.listOfQuiz.forEach((qz: any, index: number) => {
  //       if (qz['_id'] == quiz['_id']) {
  //         qz['isDeleting'] = false;
  //       }
  //     });
  //     this.showErrorToaster('Error Occurred while deleting quiz, try again', 'Error');

  //   }
  // }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  addNewQuiz(quizDetails: any) {
    this.as.listOfQuizToEditFromQuizSection = quizDetails;
    this.router.navigate(['/catalog/add-new-quiz']);
  }
}
