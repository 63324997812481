import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-new-field',
  templateUrl: './add-new-field.component.html',
  styleUrls: ['./add-new-field.component.scss']
})
export class AddNewFieldComponent implements OnInit {
  monthNames: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  // Image 
  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImageUrl: any;

  // Video
  selectedVideo: any = null;
  selectedVideoName: any = null;
  defaultVideoUrl: any;

  title: string = '';
  batchMonth: string = '';
  batchRating: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddNewFieldComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log(data);
    dialogRef.disableClose = true;
  }
  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  removeImage() {
    this.selectedImage = null;
    this.selectedImageName = null;
    this.defaultImageUrl = '';
  }
  selectImageFile(event: any) {
    this.selectedImage = event.target.files[0];
    this.selectedImageName = this.selectedImage['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultImageUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }
  removeVideo() {
    this.selectedVideo = null;
    this.selectedVideoName = null;
    this.defaultVideoUrl = '';
  }
  selectVideoFile(event: any) {
    this.selectedVideo = event.target.files[0];
    this.selectedVideoName = this.selectedVideo['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultVideoUrl = '../../../../../assets/img/video.png';
    };
    reader.readAsDataURL(this.selectedVideo);

  }

  addFeature() {
    if (this.selectedImage == null || this.selectedImage == undefined) {
      return false;
    }
    if (this.title == null || this.title == undefined) {
      return false;
    }
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({ title: this.title, icon: this.selectedImage, url: this.defaultImageUrl });

    return;
  }
  addAdvantage() {
    if (this.selectedImage == null || this.selectedImage == undefined) {
      return false;
    }
    if (this.title == null || this.title == undefined) {
      return false;
    }
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({ title: this.title, icon: this.selectedImage, url: this.defaultImageUrl });

    return;
  }
  addReviewVideo() {
    if (this.selectedImage == null || this.selectedImage == undefined) {
      return false;
    }
    if (this.selectedVideo == null || this.selectedVideo == undefined) {
      return false;
    }
    if (this.batchMonth == null || this.batchMonth == undefined) {
      return false;
    }
    if (this.batchRating == null || this.batchRating == undefined) {
      return false;
    }
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({ batchYear: String(this.batchMonth).split('-')[0], batchMonth: this.getMonth(this.batchMonth), batchRating: this.batchRating, video: this.selectedVideo, videoThumbnail: this.selectedImage, url: this.defaultImageUrl });

    return;
  }
  getMonth(str: string) {
    let dt = str.split('-')[1]; return this.monthNames[parseInt(dt)-1];
  }
}
