<div style="height: 100vh;">

    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-tgadvisory-user *ngIf="opened && isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-tgadvisory-user>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row top-row-sb">
                <div class="user-heading-text" *ngIf="searchedUser.length==0">
                    Users ({{totalCount}})
                </div>
                <div class="user-heading-text" *ngIf="searchedUser.length!=0">
                    Users ({{searchedUser.length}})
                </div>
                <div *ngIf="!isLoading" class="search">
                    <div class="col-md-4"
                        style="text-align: right;display: flex;justify-content: space-around;align-items: center; margin-right: 4rem;font-size: 1em;">

                        <mat-form-field appearance="outline">
                            <mat-label>Pick a date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="Start date" [value]="startDate"
                                    (dateChange)="dateChanged( 'start',$event)">
                                <input matEndDate placeholder="End date" [value]="endDate"
                                    (dateChange)="dateChanged( 'end',$event)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                            </mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <div (click)="clearDateRange()"
                            style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 15px; margin-bottom: 1.3rem;">
                            Clear
                        </div>
                    </div>
                    <div class="search-field">
                        <input type="text" class="search__input" placeholder="Search" [(ngModel)]="searchString"
                            (input)="emptySearchString($event.target)" (keyup.enter)="searchUser()">
                    </div>
                    <div class="searchUser" (click)="addNewUser()">
                        <h5 style="margin-bottom:0;font-size: 1rem; ">Add user</h5>
                    </div>

                </div>
            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="!isLoading">
                <div *ngIf=" toggleSortDiv" class="sort-div">
                    <div style="font-size:13px;padding:5px;">
                        Sort By Date
                    </div>
                    <div style="font-size:13px;padding:5px;">
                        Sort By Name
                    </div>
                    <div style="font-size:13px;padding:5px;">
                        Sort By Date
                    </div>
                </div>
                <div *ngIf="listOfUser.length==0 ">
                    <div class="no-user-found">
                        No user found
                    </div>
                </div>
                <div *ngIf="searchedUser.length==0 && searched">
                    <div class="no-user-found">
                        No user found
                    </div>
                </div>
                <div *ngIf="searchedUser.length>0 ">
                    <table class="table-style" cellspacing="0" cellpadding="0">
                        <thead class="table-heading">
                            <th class="table-heading-content">Sr.No.</th>
                            <th class="table-heading-content">Name</th>
                            <th class="table-heading-content">Phone</th>
                            <th class="table-heading-content">Email</th>
                            <th class="table-heading-content">Created At</th>
                            <th class="table-heading-content">Source</th>
                            <th class="table-heading-content">Action</th>

                        </thead>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let user of searchedUser | paginate: { itemsPerPage: count, currentPage: p };let indexOfelement=index;let i=index">
                                <th class="table-data-content">{{i+1}}
                                </th>
                                <th class="table-data-content" style="max-width: 15em;word-break: break-all;">
                                    {{user['fullName']}}</th>
                                <th class="table-data-content">
                                    {{user['phone']}}</th>
                                <th class="table-data-content">{{user['email']}}
                                </th>
                                <th class="table-data-content">
                                    {{user['createdAt']|date}}
                                </th>

                                <th class="table-data-content">
                                    {{user['registrationSource']==null
                                    ||user['registrationSource']==undefined?'N.A.':user['registrationSource'] }}
                                </th>

                                <th class="table-data-content" (click)="editUser(user)">
                                    <img style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                </th>

                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                    </div>
                    <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>

                </div>
                <div *ngIf="listOfUser.length>0 && !searched">
                    <table class="table-style" cellspacing="0" cellpadding="0">
                        <thead class="table-heading">
                            <th class="table-heading-content">Sr.No.</th>
                            <th class="table-heading-content">Name</th>
                            <th class="table-heading-content">Phone</th>
                            <th class="table-heading-content">Email</th>
                            <!-- <th class="table-heading-content">State</th>
                            <th class="table-heading-content">Subscribed</th> -->
                            <th class="table-heading-content">App Installation Date</th>
                            <th class="table-heading-content">Source</th>
                            <th class="table-heading-content">Action</th>

                        </thead>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let user of listOfUser | paginate: { itemsPerPage: count, currentPage: p };let indexOfelement=index;">
                                <th class="table-data-content">{{user['srno']}}
                                </th>
                                <th class="table-data-content" style="max-width: 15em;word-break: break-all;">
                                    {{user['fullName']}}</th>
                                <th class="table-data-content">
                                    {{user['phone']}}</th>
                                <th class="table-data-content">{{user['email']}}
                                </th>
                                <!-- <th class="table-data-content">
                                    {{user['address']==null?'N.A.':user['address']['state']}}</th>
                                <th class="table-data-content">
                                    {{user['activeSubscription']==null || user['activeSubscription']==undefined
                                    ?'NO':'YES'}}</th> -->





                                <th class="table-data-content">
                                    {{user['createdAt']|date}}
                                </th>

                                <th class="table-data-content">
                                    {{user['registrationSource']==null
                                    ||user['registrationSource']==undefined?'N.A.':user['registrationSource'] }}
                                </th>

                                <th class="table-data-content" (click)="editUser(user)">
                                    <img style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                </th>

                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                    </div>
                    <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>

                </div>
            </div>
            <div style="margin-top: 10em;">

            </div>
        </div>
    </ng-sidebar-container>

</div>