import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';

export interface ShortVideo {
  _id: string;
  createdAt: Date;
  isActive: boolean;
  caption: string;
  thumbnail: string;
  viewCount: number;
  video: string;
  author: any;
  type: string;
  textColor: string;
}

@Component({
  selector: 'app-list-short-videos',
  templateUrl: './list-short-videos.component.html',
  styleUrls: ['./list-short-videos.component.scss'],
})
export class ListShortVideosComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchVideos();
  }

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfVideosToDisplay: Array<ShortVideo> = [];
  listOfVideos: Array<ShortVideo> = [];
  listOfVideosOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];

  myHeaders = new Headers();

  async fetchVideos() {
    this.isLoading = true;
    this.listOfVideosToDisplay = [];
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.fetchVideoForAdmin}`;

      await this.as
        .fetchDetailViaGet(URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfVideosToDisplay = data['result'];
            this.listOfVideos = [...this.listOfVideosToDisplay];
            this.isLoading = false;
          } else {
            this.listOfVideosToDisplay = [];
            this.listOfVideos = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfVideosToDisplay = [];
      this.listOfVideos = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfVideosToDisplay = [];
      this.listOfVideos.forEach((employee: any, index: number) => {
        if (
          String(employee.language)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(employee.categoryId.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(employee);
        }
      });
      this.listOfVideosToDisplay = tempSearchList;
    } else {
      this.listOfVideosToDisplay = [];
      this.listOfVideosToDisplay = this.listOfVideos;
    }
  }

  updateActiveStatus(video: ShortVideo, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'Active Video' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          videoId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };

        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusShortVideo
        }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfVideosToDisplay.forEach(
                (employee: any, index: number) => {
                  if (employee['_id'] == res['value']['_id']) {
                    employee.isActive = isActive;
                  }
                }
              );
              this.listOfVideos = [...this.listOfVideosToDisplay];
              if (isActive) {
                this.showSuccessToaster(
                  'Video Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Video Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating video. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  deleteVideo(video: ShortVideo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'Delete Video' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          videoId: res['value']['_id'],
          action: 'Delete',
        };

        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusShortVideo
        }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfVideosToDisplay.forEach((employee: any, i: number) => {
                if (employee['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfVideosToDisplay.splice(index, 1);
              this.listOfVideos = [...this.listOfVideosToDisplay];

              this.showSuccessToaster('Video Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting video. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  addNewVideo() {
    this.toggleOpened();
  }

  editVideo(video: any) {
    let data;
    this.listOfVideosToDisplay.forEach((sv: any) => {
      if (sv['_id'] == video._id) {
        data = sv;
      }
    });
    this.dataToEdit = data;
    this.toggleOpened();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfVideosToDisplay.findIndex(
          (video: any) => video['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfVideosToDisplay.unshift(event);
          this.listOfVideos = [...this.listOfVideosToDisplay];
          this.showSuccessToaster('Added successfully', 'Success');
        } else {
          this.listOfVideosToDisplay[index] = event;
          this.listOfVideos = [...this.listOfVideosToDisplay];
          this.showSuccessToaster('Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  kFormatter(num: number) {
    return Math.abs(num) > 999
      ? Math.sign(num) * parseFloat((Math.abs(num) / 1000).toFixed(1)) + 'k'
      : Math.sign(num) * Math.abs(num);
  }
}
