<div class="main-container">
    <div class="row align-center" style="justify-content: space-between;">

        <div class="col-md-4 top-heading" style="cursor: pointer;margin-bottom: 1rem;margin-top: 1rem;">
            <span style="font-size: 1.5rem;cursor: pointer;">
                Batch Announcement
            </span>
        </div>
        <div class="col-md-8">
            <div class="row align-center">
                <!-- <div class="col-md-6" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchBatch($event)">
                </div> -->
                <div class="col-md-4" style="text-align: center;">
                    <button class="add-new-emp" style="width: 11rem;" (click)="addEditAnnouncement(null)">
                        Add Announcement
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;overflow-y: scroll;" *ngIf="!isLoading">

        <div *ngIf="listOfAnnouncementToDisplay.length==0">
            <div class="no-available">
                Announcement not found!
            </div>
        </div>
        <div *ngIf="listOfAnnouncementToDisplay.length>0">
            <table class="table-style">
                <thead class="table-heading">

                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                        class="table-heading-content">
                        Image</th>
                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                        class="table-heading-content">Caption</th>
                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                        class="table-heading-content">Date</th>

                    <th style="cursor: pointer;" data-order="desc" data-name="description" scope="col"
                        class="table-heading-content">Status
                    </th>
                    <th class="table-heading-content">Action</th>

                </thead>
                <div style="height: 0.6em;"> </div>
                <tbody>
                    <tr class="table-data"
                        *ngFor="let announcement of listOfAnnouncementToDisplay | paginate: { itemsPerPage: count, currentPage: p }">

                        <th class="table-data-content"
                            *ngIf="announcement.image!=null && announcement.image!=undefined && announcement.image!=''">
                            <img style="height: 2em;" [src]="announcement['image']" alt="" srcset="">
                        </th>
                        <th class="table-data-content"
                            *ngIf="announcement.image==null|| announcement.image==undefined|| announcement.image==''">
                            NA</th>
                        <th class="table-data-content">{{announcement.caption}}</th>
                        <th class="table-data-content">{{announcement.createdAt==null ||
                            announcement.createdAt==undefined?'N.A'
                            :announcement.createdAt |date}}</th>
                        <th class="table-data-content">
                            <img *ngIf="announcement.isActive"
                                class="table-data-content__status-icon table-data-content__status-icon-on"
                                src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem; cursor: pointer;"
                                (click)="updateAnnouncementStatus(announcement,false)" slot="start" />
                            <img *ngIf="!announcement.isActive"
                                class="table-data-content__status-icon table-data-content__status-icon-off"
                                src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem; cursor: pointer;"
                                (click)="updateAnnouncementStatus(announcement,true)" slot="start" />
                        </th>
                        <th class="table-data-content" style="width: 10em;">
                            <div style="display: contents;">
                                <img (click)="addEditAnnouncement(announcement)"
                                    style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../assets/img/editing.png'>
                                <img (click)="deleteAnnouncement(announcement)"
                                    style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../assets/img/delete.png'>
                            </div>
                        </th>
                    </tr>
                </tbody>
            </table>
            <div class="text-right" style="font-size: medium;margin: 1em;">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>


    </div>
</div>