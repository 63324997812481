<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 90vh;" *ngIf="!isLoading">


        <div ng-sidebar-content style="overflow: hidden;padding: 2em;">
            <div class="row top-row-sb">
                <div class="offer-heading-text">
                    Access Control
                </div>
                <div class="search">
                    <div class="search-field">
                        <input type="text" class="search__input" style="width: 95%;" placeholder="Search Admin"
                            [(ngModel)]="searchString" (keyup.enter)="searchUser()">

                    </div>
                    <div class="searchUser" (click)="searchUser()">
                        <h5 style="margin-bottom:0; ">Search Admin</h5>
                    </div>
                </div>
            </div>
            <div *ngIf="userInfo==null">
                <div class="no-user-found">
                    {{userStatus}}
                </div>
            </div>
            <div *ngIf="userInfo!=null">
                <!-- <div style="margin-bottom: 5rem;"
                    *ngIf="userInfo['accessControlList'].length>0 && userInfo['accessControlList']!=null ">
                    <div class="row top-row-sb" style="margin-bottom: 2rem;">
                        <div class="user-heading-text">
                            User Access
                        </div>
                    </div>
                    <div class="access-row" *ngIf="userInfo['accessControlList'].length!=0">
                        <div class="access" *ngFor="let access of userInfo['accessControlList']">
                            <input type="checkbox" class="checkbox" [id]="access" value="ACL"
                                (click)="removeAccess(access)" checked>
                            <label class="accessName" [for]="access">{{access}}</label><br>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="accessList.length>0">
                    <div class="row top-row-sb" style="margin-bottom: 2rem;">
                        <div class="user-heading-text">
                            Access List
                        </div>
                    </div>
                    <div class="access-row" *ngIf="accessList.length>0">
                        <div class="access" *ngFor="let access of accessList">
                            <input *ngIf="!access['permission']" type="checkbox" class="checkbox" [id]="access"
                                value="ACL" (click)="addAccess(access)">
                            <input *ngIf="access['permission']" type="checkbox" class="checkbox" [id]="access"
                                value="ACL" (click)="removeAccess(access)" checked>
                            <label class="accessName" [for]="access['title']">{{access['title']}}</label><br>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isLoading && userInfo!=null" class="addUserEnroll" (click)="updateAccess()">
                    <h5 style="margin-bottom:0; ">Update admin access</h5>
                </div>
            </div>

        </div>

    </div>
</div>