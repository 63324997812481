<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 88vh;margin-top: 1em;" *ngIf="!isLoading">

        <div ng-sidebar-content style="overflow: hidden;padding: 1em;">
            <div class="row top-row-sb">
                <div class="user-heading-text">
                    Users
                </div>
                <div class="search">
                    <div class="search-field">
                        <input type="text" class="search__input" placeholder="Search" (input)="searchUser($event)">

                    </div>
                    <div title="Download report of all users">
                        <img *ngIf="!downloadReport" src="../../../assets/svgIcons/download.svg" class="download"
                            title="Download report of all users" (click)="exportAllUserSummary()">
                        <div *ngIf="downloadReport" class="delete">
                            <mat-spinner mode="indeterminate" diameter="20"
                                style="margin:0 auto;height:20px;width:20px;">
                            </mat-spinner>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="listOfUser.length==0">
                <div class="no-user-found">
                    No user found
                </div>
            </div>
            <div *ngIf="listOfUser.length>0">
                <table class="table-style">
                    <thead class="table-heading">
                        <th class="table-heading-content">Name</th>
                        <th class="table-heading-content">Email</th>
                        <th class="table-heading-content">Phone Number</th>
                        <th class="table-heading-content">Unpaid Referral </th>
                        <th class="table-heading-content">Total Referral</th>
                        <th class="table-heading-content">Unpaid Commission</th>
                        <th class="table-heading-content">Pay</th>
                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let user of listOfUser | paginate: { itemsPerPage: count, currentPage: p }">
                            <th class="table-data-content">{{user['userName']}}</th>
                            <th class="table-data-content">{{user['email']}}</th>
                            <th class="table-data-content">{{user['mobileNo']}}</th>
                            <th class="table-data-content">{{getUnpaidReferral(user)}}</th>
                            <th class="table-data-content" (click)="openDialog(user)">{{user['referredTo'].length}}</th>
                            <th class="table-data-content"> &#x20b9;{{user['totalUnpaidCommission']}}</th>
                            <th class="table-data-content" (click)="paymentDialog(user)">
                                <diV class="pay">
                                    <h4>Pay</h4>
                                </diV>
                                <!-- <img class="rupee" src="../../../../assets/svgIcons/rupee.svg"> -->
                            </th>

                            <!-- <img *ngIf="user['isCommissionReceived'] == true && user['referredBy']!=null"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" (click)="updateUser()" slot="start" />
                                <img *ngIf="user['isCommissionReceived'] == false && user['referredBy']!=null"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" (click)="updateUser()" slot="start" />
                                <h2 class="table-data-content" *ngIf="user['referredBy']==null">N/A</h2>
                            </th>
                        </tr> -->

                    </tbody>
                </table>
                <div class="text-right"
                    style="font-size: medium;margin: 1em;position: absolute;bottom: 0.1em;right: 2em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>

        </div>
    </div>