import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ExcelService } from '../../excel.service';
import { PaymentComponent } from '../payment/payment.component';
import { ReferralPopupComponent } from '../referral-popup/referral-popup.component';

@Component({
  selector: 'app-main-referral',
  templateUrl: './main-referral.component.html',
  styleUrls: ['./main-referral.component.scss'],
})
export class MainReferralComponent implements OnInit {
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public excelExport: ExcelService
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
  }

  p: any = 1;
  count: any = 6;
  isLoading: boolean = false;
  listOfUserOriginal: any = [];
  listOfUser: any = [];
  downloadReport: Boolean = false;
  searchUser(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let newUserList: any = [];
    this.listOfUser = [...this.listOfUserOriginal];
    if (serachQuery != '') {
      this.listOfUser.forEach((user: any) => {
        if (
          String(user['userName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(user['mobileNo'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(user['email'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(user['totalUnpaidCommission'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          newUserList.push(user);
        }
      });
      this.listOfUser = [...newUserList];
    } else {
      this.listOfUser = [...this.listOfUserOriginal];
    }
  }

  exportAllUserSummary() {
    this.downloadReport = true;
    var toExport: any = [];
    var i: any = 1;
    this.listOfUserOriginal.forEach((user: any) => {
      if (user['referredTo'].length >= 1) {
        toExport.push({
          SrNo: i,
          ReferredByName: user['userName'],
          ReferredByEmail: user['email'],
          ReferredByMobileNo: user['mobileNo'],
          ReferredByUserId: user['_id'],
          TotalUnpaidCommission: user['totalUnpaidCommission'],
          ReferredToName: user['referredTo'][0]['name'],
          ReferredToEmail: user['referredTo'][0]['email'],
          ReferredToMobileNo: user['referredTo'][0]['mobileNo'],
          CourseName: user['referredTo'][0]['courseName'],
          CourseEnrolledDate: user['referredTo'][0]['joinedDate'],
        });
        if (user['referredTo'].length >= 1) {
          for (let index = 1; index < user['referredTo'].length; index++) {
            var userData: any = {};
            userData = {
              ReferredToName: user['referredTo'][index]['name'],
              ReferredToEmail: user['referredTo'][index]['email'],
              ReferredToMobileNo: user['referredTo'][index]['mobileNo'],
              CourseName: user['referredTo'][index]['courseName'],
              CourseEnrolledDate: user['referredTo'][index]['joinedDate'],
            };
            toExport.push(userData);
          }
          toExport.push({});
          i = i + 1;
        }
      }
    });
    this.downloadReport = false;
    this.excelExport.exportAsExcelFile(toExport, 'Referral Report');
  }

  updateUser() {}

  async fetchUsers() {
    this.listOfUser = [];
    this.isLoading = true;
    const param = {
      skip: 0,
      limit: 1000,
    };
    // const URL = `${webApi.domain + webApi.endPoint.fetchUser}`;
    // await this.as.fetchUsersFromService(param, URL).then((data: any) => {
    //   if (data['success']) {
    //     data['data'].forEach((user: any) => {
    //       if (user['referredTo'].length > 0) {
    //         this.listOfUser.push(user);
    //       }
    //     });
    //     this.listOfUserOriginal = this.listOfUser;

    //     this.isLoading = false;
    //   }
    // });
  }

  openDialog(user: any): void {
    this.as.listOfReferralsUser = user['referredTo'];
    const dialogRef = this.matDialog.open(ReferralPopupComponent, {
      maxHeight: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  paymentDialog(user: any): void {
    this.as.userInfo = user;
    const dialogRef = this.matDialog.open(PaymentComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        this.listOfUser.forEach((userDetails: any) => {
          if (userDetails['userManualId'] == user['userManualId']) {
            userDetails['totalUnpaidCommission'] =
              userDetails['totalUnpaidCommission'] - res['value'];
          }
        });
      }
    });
  }

  getUnpaidReferral(user: any) {
    let count = 0;
    user['referredTo'].forEach((referred: any) => {
      if (!referred['isPaid']) {
        count = count + 1;
      }
    });
    return count;
  }
}
