<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-trade-advisory *ngIf="isAddEditCalled && !generalChat" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-trade-advisory>
            <app-add-general-chat *ngIf="!isAddEditCalled && generalChat" (closeSideForm)="toggleCloseChat($event)">
            </app-add-general-chat>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;width: 100%;">
                <div class="col-md-2 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;">
                        Trade Analysis
                    </span>
                </div>
                <div class="col-md-10">
                    <div class="row align-center">
                        <div class="col-md-4"
                            style="text-align: right;display: flex;justify-content: space-around;align-items: center; margin-right: 1rem;font-size: 1em;">

                            <mat-form-field appearance="outline">
                                <mat-label>Pick a date range</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate placeholder="Start date" [value]="startDate"
                                        (dateChange)="dateChanged( 'start',$event)">
                                    <!-- (dateChange)="dateChanged( 'start',$event)" -->
                                    <input matEndDate placeholder="End date" [value]="endDate"
                                        (dateChange)="dateChanged( 'end',$event)">
                                    <!-- (dateChange)="dateChanged( 'end',$event)" -->
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                                </mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>

                            <div (click)="clearDateRange()"
                                style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 15px; margin-bottom: 1.3rem;">
                                Clear
                            </div>
                        </div>
                        <div class="col-md-3 display-flex align-items-center justify-content-end">
                            <label for="advisoryType" class="label required"
                                style="margin-right: 10px;    margin-bottom: 0px;">Trading
                                Type</label>
                            <select id="advisoryType" name="advisoryType" class="input-style"
                                style="width: 60%;margin: 0px;" (change)="selectAdvisoryType($event.target)">
                                <option value="Select Trading Type" disabled>Select Trading Type</option>
                                <option value="All" selected>All</option>
                                <option value="Swing">Swing</option>
                                <option value="Intraday">Intraday</option>
                                <option value="Positional">Positional</option>
                                <option value="Nifty & BNF Options">Nifty & BNF Options</option>
                                <option value="Equity Options">Equity Options</option>
                                <option value="Short Term Hourly Trades">Short Term Hourly Trades</option>
                                <option value="BTST Trades">BTST Trades</option>
                            </select>
                        </div>
                        <div class="col-md-2" style="text-align: center;">
                            <button class="add-btn" (click)="toggleChatOpened()">
                                Add General Chat
                            </button>
                        </div>
                        <div class="col-md-2" style="text-align: center;">
                            <button class="add-new-emp" (click)="addNewTradeAdvisory()">
                                Create Analysis
                            </button>
                        </div>
                        <div *ngIf="!downloadReport" style="width: 5em; position: relative;cursor: pointer;">
                            <img class="mr-4" src="../../../../assets/svgIcons/Save.svg" title="Download Report" alt=""
                                srcset="" (click)="downloadTrade()">
                        </div>
                        <div *ngIf="downloadReport" style="width: 5em; position: relative;cursor: pointer;">
                            <mat-progress-spinner mode="indeterminate" diameter="25" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfTradeAdvisoryToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No trade advisory found
                </div>
            </div>
            <div *ngIf="listOfTradeAdvisoryToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="title" scope="col"
                            class="table-heading-content">
                            Title</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="type" scope="col"
                            class="table-heading-content">Format
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="advisoryType" scope="col"
                            class="table-heading-content">Advisory Type
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Plans
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Trade Type
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Trade Result
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Opening Price
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Closing Price
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">P/L (In Amount)
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">P/L (In %)
                        </th>
                        <!-- <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Plan SubTypes
                        </th> -->

                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Details
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Publish Date</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Create & Copy</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                            class="table-heading-content">
                            Active</th>

                        <th class="table-heading-content">Action</th>

                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let advisory of listOfTradeAdvisoryToDisplay | paginate: { itemsPerPage: count, currentPage: p },let i=index">

                            <th class="table-data-content">{{advisory.title}}
                            </th>
                            <th class="table-data-content">{{advisory.type}}</th>
                            <th class="table-data-content">{{advisory.type=='Chat' ? 'N.A.':advisory.advisoryType}}</th>
                            <th class="table-data-content">{{advisory.plans}}</th>
                            <th class="table-data-content">{{advisory.tradeType==null
                                ||advisory.tradeType==undefined? 'N.A.':advisory.tradeType}}</th>
                            <th class="table-data-content">{{advisory.tradeResult==null
                                ||advisory.tradeResult==undefined? 'N.A.':advisory.tradeResult}}</th>

                            <th class="table-data-content">{{advisory.opening==0||advisory.opening==null
                                ||advisory.opening==undefined ?'N.A.':advisory.opening}}</th>

                            <th class="table-data-content">{{advisory.closing==0||advisory.closing==null
                                ||advisory.closing==undefined?'N.A.':advisory.closing}}</th>

                            <th class="table-data-content">{{advisory.opening==0||advisory.opening==null
                                ||advisory.opening==undefined ||advisory.closing==0||advisory.closing==null
                                ||advisory.closing==undefined?'N.A.':advisory.tradeType!='Buy'?
                                (advisory.opening-advisory.closing).toFixed(2):(advisory.closing-advisory.opening).toFixed(2)}}
                            </th>

                            <th class="table-data-content">{{advisory.opening==0||advisory.opening==null
                                ||advisory.opening==undefined ||advisory.closing==0||advisory.closing==null
                                ||advisory.closing==undefined?'N.A.':advisory.tradeType!='Buy'?
                                (((advisory.opening-advisory.closing)/advisory.opening)*100).toFixed(2)
                                : (((advisory.closing-advisory.opening)/advisory.closing)*100).toFixed(2)
                                +'%'}}</th>

                            <th class="table-data-content">
                                <img (click)="viewAdvisory(advisory)"
                                    style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../../assets/img/view.png'>
                            </th>
                            <th class="table-data-content">{{advisory.publishDate |date}}</th>
                            <th (click)="createLink(advisory._id)" style="text-decoration: underline;color: blue;cursor: pointer;" class="table-data-content">Create Link</th>
                            <th class="table-data-content">
                                <img *ngIf="advisory.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(advisory,false)" slot="start" />
                                <img *ngIf="!advisory.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(advisory,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">

                                    <img (click)="editTradeAdvisory(advisory)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img title="View" (click)="deleteTradeAdvisory(advisory)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
                <div style="height: 10em;"> </div>
            </div>


        </div>
    </ng-sidebar-container>

</div>