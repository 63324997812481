import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainHelpComponent } from './main-help/main-help.component';

const routes: Routes = [
  {
    path: '',
    component: MainHelpComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/help' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HelpRoutingModule {}
