import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListYoutubeVideoComponent } from './list-youtube-video/list-youtube-video.component';
import { ViewYoutubeVideoAnalyticComponent } from './view-youtube-video-analytic/view-youtube-video-analytic.component';

const routes: Routes = [
  {
    path: '',
    component: ListYoutubeVideoComponent,
  },
  {
    path: 'view-analytic',
    component: ViewYoutubeVideoAnalyticComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/youtube' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class YoutubeVideoRoutingModule { }
