<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading">
        <div class="row about-us-heading">
            Terms and Condition
        </div>
        <form name="addEditTnCForm" [formGroup]="addEditTnCForm">

            <quill-editor class="content-editor" [placeholder]="''" name="contentText" formControlName="contentText">
            </quill-editor>
        </form>
        <div style="display:flex;justify-content: flex-end;margin-top: 10px;">

            <button class="save-btn" (click)="isAddingData? null:saveAboutUs()" [disabled]="addEditTnCForm.invalid">
                <div *ngIf="!isAddingData">
                    Save
                </div>
                <div *ngIf="isAddingData">
                    <mat-progress-spinner mode="indeterminate" diameter="18" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </button>
        </div>
    </div>
</div>