import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../delete-module-activity-dialog/delete-module-activity-dialog.component';
export interface EnroledUser {
  _id: string;
  course: string;
  user: any;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  isActive: boolean;
}
@Component({
  selector: 'app-course-user-list',
  templateUrl: './course-user-list.component.html',
  styleUrls: ['./course-user-list.component.scss'],
})
export class CourseUserListComponent implements OnInit {
  courseId: String = '';
  p: any = 1;
  count: any = 50;
  skip: any = 0;
  limit: any = 500;
  totalCount: any = 0;
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    console.log('Called Constructor');
    this.routes.params.subscribe((params) => {
      this.courseId = params['courseId'];
    });
  }

  userDetail: any;
  httpHeaders: any;
  isLoading: boolean = true;
  myHeaders = new Headers();
  listOfEnrolledUser: Array<EnroledUser> = [];
  listOfEnrolledUserOriginal: Array<EnroledUser> = [];
  listOfUser: any = [];
  isLoadingExtraData: boolean = false;
  isLoadingData: boolean = false;

  backToCourse() {
    this.router.navigate(['/catalog/courses']);
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchUsers();
  }

  async fetchUsers() {
    this.listOfUser = [];

    const URL = `${webApi.domain + webApi.endPoint.fetchCourseEnroledUser}`;
    const param = {
      limit: this.limit,
      skip: this.skip,
      course: this.courseId,
    };
    await this.as
      .fetchDetail(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.listOfUser = data['result'];
          this.totalCount = data['count'];

          this.listOfEnrolledUser = this.listOfUser;
          this.listOfEnrolledUserOriginal = [...this.listOfEnrolledUser];
        } else {
          this.isLoading = false;
        }
      });
  }

  async fetchExtraUsers(isLoadingExtraData: boolean) {
    const URL = `${webApi.domain + webApi.endPoint.fetchCourseEnroledUser}`;
    const param = {
      limit: this.limit,
      skip: this.skip,
      course: this.courseId,
    };
    this.isLoadingExtraData = isLoadingExtraData;
    await this.as
      .fetchDetail(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          data['result'].forEach((data: any) => {
            this.listOfUser.push(data);
          });

          this.listOfEnrolledUserOriginal = this.listOfEnrolledUser;
        } else {
          this.isLoading = false;
        }
      });
  }

  pageChanged(event: any) {
    this.p = event;
    console.log(event);

    if (this.p * this.count >= this.listOfUser.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraUsers(false);
    } else if (this.p * this.count >= this.listOfUser.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraUsers(true);
    }


  }

  searchCourseUserList(event: any) {
    let serachQuery = event.target.value;
    let newCourseList: any = [];
    this.listOfEnrolledUser = [...this.listOfEnrolledUserOriginal];
    if (serachQuery != '') {
      this.listOfEnrolledUser.forEach((course: any) => {
        if (
          String(course['userName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          newCourseList.push(course);
        }
      });
      this.listOfEnrolledUser = [...newCourseList];
    } else {
      this.listOfEnrolledUser = [...this.listOfEnrolledUserOriginal];
    }
  }

  async updateUserEnrolmentStatus(
    enrolment: EnroledUser,
    enrolledStatus: boolean
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Enrolment', enrolment };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        return;
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          userId: res['value']['user']['_id'],
          isActive: enrolledStatus,
          course: this.courseId,
        };

        const URL = `${webApi.domain + webApi.endPoint.updateUserEnrolmentStatus
          }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfEnrolledUser.forEach((user: any, index: number) => {
                if (user['user']['_id'] == res['value']['user']['_id']) {
                  user['isActive'] = enrolledStatus;
                }
              });
              this.showSuccessToaster(
                'Enroled Status Updated Successfully',
                'Success'
              );
            } else {
              this.showSuccessToaster('Something went wrong', 'Success');
            }
          });
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
}
