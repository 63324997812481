import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-analytic-report-dialog',
  templateUrl: './analytic-report-dialog.component.html',
  styleUrls: ['./analytic-report-dialog.component.scss']
})
export class AnalyticReportDialogComponent implements OnInit {


  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;

  fileNameControl = new FormControl('', [Validators.required]);


  generateReportForm: FormGroup = new FormGroup({
    fileName: this.fileNameControl,

  });
  userDetails: any;
  constructor(
    public dialogRef: MatDialogRef<AnalyticReportDialogComponent>,
    private toastr: ToastrService,
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }

  ngOnInit(): void {
    this.generateReportForm = this.formBuilder.group({
      fileName: this.fileNameControl,
    });
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  async generateReport() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({ fileName: this.generateReportForm.get('fileName')?.value });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarninigToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
