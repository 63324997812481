<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Ticker
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addTickerForm" [formGroup]="addTickerForm">
        <div class="row">
            <div class="col-md-12">
                <label for="title" class="label required">Title</label>
                <input type="text" placeholder="Title" id="title" formControlName="title" class="input-style">
            </div>
        </div>

        <div class="row">
            <div [ngClass]="{'col-md-6':selectedType=='Internal' ,'col-md-12':selectedType!='Internal' }">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Type</label>
                <select id="type" name="type" class="input-style" formControlName="type"
                    (change)="typeSelected($event)">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="External">External</option>
                    <option value="Internal">Internal</option>
                </select>
            </div>
            <div *ngIf="selectedType=='Internal'" class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Route</label>
                <select id="type" name="type" class="input-style" formControlName="route"
                    (change)="routeChangeHandler($event.target)">
                    <option value="Select Route" selected disabled>Select Route</option>
                    <option *ngFor="let route of listOfRoutes" [value]="route">{{route}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row">
            <div  *ngIf="selectedType=='External'" class="col-md-6">
                <label for="route" class="label required">Route</label>
                <input type="text" placeholder="Route" id="route" formControlName="route" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="position" class="label required">Position</label>
                <input type="number" placeholder="Position" id="position" formControlName="position" class="input-style">
            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <label for="Image" class="label required">Icon</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultTickerImage!=null" src="{{defaultTickerImage}}" class="proofImage">
                                <label *ngIf="defaultTickerImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultTickerImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">

                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addTickerForm.invalid  ,'save-btn':addTickerForm.valid }"
                value="{{'Save'}}" [disabled]="addTickerForm.invalid  " (click)="addEditBrand()" />
        </div>
    </form>
</div>