<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{filterToEdit!=null?'Edit': 'Apply'}} Filter
            </h1>
        </div>
        <div (click)="cancel('close')">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="subscriptionFilter" [formGroup]="subscriptionFilter">

        <div class="row">
            <div class="col-md-6">
                <label for="type" class="label">Subscription Type</label>
                <select id="type" name="type" class="input-style" formControlName="type"
                    (change)="typeSelected($event.target)">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="PAID">PAID</option>
                    <option value="FREE">FREE</option>
                    <option value="MANUALLY">MANUALLY</option>
                </select>
            </div>

            <div class="col-md-6">
                <label for="status" class="label">Status</label>
                <select id="status" name="status" class="input-style" formControlName="status"
                    (change)="statusSelected($event.target)">
                    <option value="Select Status" selected disabled>Select Status</option>
                    <option value="Expiring">Expiring</option>
                    <option value="Expired">Expired</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="startDate" class="label">Start Date</label>
                <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label">End Date</label>
                <input type="date" placeholder="End Date" id="endDate" formControlName="endDate" class="input-style">
            </div>
        </div>

        <div class="save" style="float: right;margin-top: 2rem;">
            <button class="cancel-btn" (click)="cancel(null)">Clear filter</button>
            <input type="submit" style="width: 5rem;"
                [ngClass]="{'save-btn-disabled':subscriptionFilter.invalid || selectedStatus==null || selectedType==null,'save-btn':subscriptionFilter.valid && selectedStatus!=null && selectedType!=null }"
                value="{{'Apply'}}" [disabled]="subscriptionFilter.invalid || selectedStatus==null || selectedType==null"
                (click)="editUser()" />
        </div>
    </form>
</div>