<div *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
</div>
<div *ngIf="!isLoading" style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Campaign Detail</h2>
    <hr>
    <div class="row">
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Campaign Name: </span> {{campaignDetail['campaignName']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Campaign Type: </span> {{campaignDetail['campaignType']}}
        </div>
        <div *ngIf="campaignDetail['campaignType']=='WhatsApp'" style="margin-bottom: 1em;">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Total Target User Count: </span> {{campaignDetail['userData']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Sent Count: </span> {{campaignDetail['campaignViewDetail']!=undefined && campaignDetail['campaignViewDetail']!=null? campaignDetail['campaignViewDetail']['sentCount']: campaignDetail['receiveCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Deliverd Count: </span> {{campaignDetail['campaignViewDetail']!=undefined && campaignDetail['campaignViewDetail']!=null? campaignDetail['campaignViewDetail']['deliveredCount']:campaignDetail['receiveCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Read Count: </span> {{campaignDetail['campaignViewDetail']!=undefined && campaignDetail['campaignViewDetail']!=null? campaignDetail['campaignViewDetail']['readCount']:campaignDetail['receiveCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Undelivered Count: </span> {{campaignDetail['userData']-(campaignDetail['campaignViewDetail']!=undefined && campaignDetail['campaignViewDetail']!=null? campaignDetail['campaignViewDetail']['sentCount']:campaignDetail['receiveCount'])}}
            </div>
        </div>
        <div *ngIf="campaignDetail['campaignType']=='Email' || campaignDetail['campaignType']=='SMS'">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Subject : </span> {{campaignDetail['campaignSubject']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Body : </span> <span [innerHtml]="campaignDetail['campaignBody']"></span>
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Total Target User Count: </span> {{campaignDetail['userData']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Sent Count: </span> {{campaignDetail['receiveCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Received Count: </span> {{campaignDetail['receiveCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Undelivered Count: </span> {{campaignDetail['userData']-campaignDetail['receiveCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Email Opened : </span> {{campaignDetail['openedCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Link Opened : </span> {{campaignDetail['linkOpenedCount']}}
            </div>
        </div>
    </div>

</div>