import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { tgAdvisoryApi } from 'src/app/api';
import { HttpHeaders } from '@angular/common/http';
import { TGAdvisorySocketioService } from '../tgadvisory-socketio.service';

@Component({
  selector: 'app-add-edit-community',
  templateUrl: './add-edit-community.component.html',
  styleUrls: ['./add-edit-community.component.scss']
})
export class AddEditCommunityComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = true;
  isAddEditCommunityLoading: boolean = false;

  userDetail: any;
  httpHeaders: any;

  // Image 
  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImageUrl: any;

  listOfDays: any = [
    {
      label: 'MON',
    },
    {
      label: 'TUE',
    },
    {
      label: 'WED',
    },
    {
      label: 'THU',
    },
    {
      label: 'FRI',
    },
    {
      label: 'SAT',
    },
    {
      label: 'SUN',
    },

  ];
  selectedDays: any = [];



  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'label',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  addEditCommunityForm: FormGroup;
  communityDetail: any;
  constructor(
    public dialogRef: MatDialogRef<AddEditCommunityComponent>, public socketService: TGAdvisorySocketioService,
    public service: AppService, private formBuilder: FormBuilder, private toastr: ToastrService,
    public appService: AppService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
    this.addEditCommunityForm = this.formBuilder.group({
      communityName: new FormControl(null, [Validators.required]),
      communityGuideline: new FormControl(null, [Validators.required]),
      chatType: new FormControl('Select Chat Type', [Validators.required]),
    });

  }
  ngOnInit(): void {
    if (this.data['id'] != null && this.data['id'] != undefined) {
      this.fetchCommunityWithId();
    }else{
      this.isLoading=false;
    }
  }
  async fetchCommunityWithId() {
    this.isLoading = true;
    try {
      this.communityDetail = null;

      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchCommunityWithId}/${this.data['id']}`;
      await this.appService.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.communityDetail = data['result'];

          if (this.communityDetail != null && this.communityDetail != undefined) {
            this.addEditCommunityForm.get('chatType')?.setValue(this.communityDetail['chatType']);
            this.addEditCommunityForm.get('communityName')?.setValue(this.communityDetail['name']);
            this.addEditCommunityForm.get('communityGuideline')?.setValue(this.communityDetail['description']);       

            this.defaultImageUrl =
              this.communityDetail['image'] != null
                ? this.communityDetail['image']
                : 'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
          }
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  removeImage() {
    this.selectedImage = null;
    this.selectedImageName = null;
    this.defaultImageUrl = '';
  }
  selectImageFile(event: any) {
    this.selectedImage = event.target.files[0];
    this.selectedImageName = this.selectedImage['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultImageUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedImage);

  }

  onSubmit() {
    this.isAddEditCommunityLoading = true;
    try {


      this.formdata = new FormData();
      if (this.data['id'] != null && this.data['id'] != undefined) {
        this.formdata.set('communityId', this.data['id']);
      } else {
        this.formdata.set('communityId', '0');
      }

      if (this.selectedImage == null || this.selectedImage == undefined) {
        if (this.communityDetail != null && this.communityDetail != undefined) {
          this.formdata.set('image', this.communityDetail['image']);
        }
      } else {
        this.formdata.set('image', this.selectedImage);
      }


      this.formdata.set('name', this.addEditCommunityForm.get('communityName')?.value);
      this.formdata.set('description', this.addEditCommunityForm.get('communityGuideline')?.value);
      this.formdata.set('chatType', this.addEditCommunityForm.get('chatType')?.value);

      let daysForFree: any = [];
      this.selectedDays.forEach((dys: any, i: number) => {
        daysForFree.push(dys.label);
      });
      this.formdata.set('daysForFree', JSON.stringify(daysForFree));

      this.myHeaders.append(
        'Authorization',
        `Bearer ${this.userDetail['accessToken']}`
      );

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };

      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.addEditCommunity}`;

      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            this.isAddEditCommunityLoading = false;
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.dialogRef.close();
                this.closeActivityAtDialog.emit({ result: res['result'] });
                this.isAddEditCommunityLoading = true;
                if (this.data['id'] != null && this.data['id'] != undefined) {
                  this.showSuccessToaster(
                    'Community Updated Successfully',
                    'Success'
                  );
                } else {
                  this.showSuccessToaster(
                    'Community Created Successfully',
                    'Success'
                  );
                }
              } else {
                this.myHeaders = new Headers();
                if (this.data['id'] != null && this.data['id'] != undefined) {
                  this.showWarningToaster(
                    'Unable to update community. Please try again.',
                    'Warning'
                  );
                } else {
                  this.showWarningToaster(
                    'Unable to create community. Please try again.',
                    'Warning'
                  );
                }
              }
            });
          }
        })
        .catch((error) => console.log('error', error));
      return;
    } catch (error) {
      this.isAddEditCommunityLoading = false;
      if (this.data['id'] != null && this.data['id'] != undefined) {
        this.showWarningToaster(
          'Unable to update community. Please try again.',
          'Warning'
        );
      } else {
        this.showWarningToaster(
          'Unable to create community. Please try again.',
          'Warning'
        );
      }
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
