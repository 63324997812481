<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-short-video *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-short-video>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;    width: 100%;">
                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;">
                        Video Management
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">

                        <div class="col-md-3" style="text-align: center;">
                            <button class="add-new-emp" (click)="addNewVideo()">
                                Add Video
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfVideosToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Video found
                </div>
            </div>
            <div *ngIf="listOfVideosToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Thumbnail</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Author</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Type</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Video Link</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Caption
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Text Color
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">View Count
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Created Date</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                            class="table-heading-content">
                            Active</th>

                        <th class="table-heading-content">Action</th>

                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let video of listOfVideosToDisplay | paginate: { itemsPerPage: count, currentPage: p },let i=index">
                            <th class="table-data-content">
                                <div class="avatar rounded-circle">
                                    <img *ngIf="video.thumbnail==null || video.thumbnail==undefined || video.thumbnail=='' "
                                        style="height: 30px;object-fit: cover;
                                        width: 30px;
                                        border-radius: 50%;" src='../../../../assets/img/placeholder-course.jpg'
                                        alt="">
                                    <img *ngIf="video.thumbnail!=null && video.thumbnail!=undefined && video.thumbnail!='' "
                                        style="height: 30px;object-fit: cover;
                                        width: 30px;
                                        border-radius: 50%;" src={{video.thumbnail}} alt="">
                                </div>
                            </th>
                            <th class="table-data-content">{{video.author.name}}</th>
                            <th class="table-data-content">{{video.type}}</th>
                            <th class="table-data-content" *ngIf="video.type!='Video'">N.A.</th>
                            <th class="table-data-content" *ngIf="video.type=='Video'"><a [href]="video.video">Video
                                    Link</a></th>
                            <th style="max-width:20rem" class="table-data-content">{{video.caption}}
                            </th>
                            <th class="table-data-content">{{video.textColor}}
                            </th>
                            <th class="table-data-content">{{kFormatter(video.viewCount)}}
                            </th>
                            <th class="table-data-content">{{video.createdAt |date}}</th>

                            <th class="table-data-content">
                                <img *ngIf="video.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(video,false)" slot="start" />
                                <img *ngIf="!video.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(video,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">
                                    <img (click)="editVideo(video)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img title="View" (click)="deleteVideo(video)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div style="height: 10em;"> </div>
            </div>


        </div>
    </ng-sidebar-container>

</div>