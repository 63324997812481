<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div *ngIf="isOptionSelected==null && !isLoading" class="container-fluid main-content" id="container-wrapper">
    <div class="row mb-3" style="margin: 2em;">
        <div class="col-md-3" *ngFor="let data of listOfBox" (click)="navigateToRoute(data['route'])">
            <div class="card h-13em">
                <div class="card-body center-align">
                    <div class="row align-items-center" style="justify-content: center;">
                        <div class="col-md-12 text-align-center">
                            <img [src]="data['img']" [alt]="data['label']" style="height: 3em;margin-bottom: 10px;cursor: pointer;">
                        </div>
                        <div class="col-md-12 text-align-center" style="font-size: 15px;">
                            {{data['label']}}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isOptionSelected!=null && !isLoading">
    <router-outlet></router-outlet>
</div>