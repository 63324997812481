<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading">

        <form class="form-style" name="courseForm" [formGroup]="courseForm">
            <div class="row upper">
                <div class="title">
                    <label for="title" class="label required">Course Title:</label>
                    <input type="text" placeholder="Enter a title" id="title" formControlName="courseName"
                        class="title-input" (input)="onCourseNameChangeEvent($event)" required>
                    <label for="slug" class="label required">Course Slug:</label>
                    <input type="text" placeholder="Enter a course slug" id="slug" formControlName="courseSlug"
                        class="title-input" required>
                    <div class="row-2">
                        <div class="title">
                            <label for="author" class="label required">Author:</label>
                            <select name="author" id="author" class="dropDown" formControlName="authorName"
                                (change)="selectionChange($event.target)" required>
                                <option *ngFor="let author of listOfAuthor" [value]="author['_id']" class="levels">
                                    {{author['name']}}</option>

                            </select>
                        </div>
                        <div class="title">
                            <label for="level" class="label required">Course Level:</label>
                            <select name="level" id="level" class="dropDown" formControlName="level" required>
                                <option value="All Levels" class="levels">All Levels</option>
                                <option value="Experts" class="levels">Experts</option>
                                <option value="Indeterminate" class="levels">Indeterminate</option>
                            </select>
                        </div>
                    </div>
                    <div class="row-2">

                        <div class="langauge">
                            <div class="title">
                                <label for="language" class="label required">Course Language:</label>
                                <select name="language" id="language" class="dropDown" formControlName="language">
                                    <option value="Hindi" class="langauge">Hindi</option>
                                    <option value="English" class="langauge">English</option>
                                </select>
                            </div>
                        </div>
                        <div class="langauge">
                            <div class="title">
                                <label for="period" class="label required">Course Period:</label>
                                <select name="period" id="period" class="dropDown" formControlName="coursePeriod">
                                    <option value="7" class="langauge">7 days</option>
                                    <option value="15" class="langauge">15 days</option>
                                    <option value="30" class="langauge">1 month</option>
                                    <option value="90" class="langauge">3 months</option>
                                    <option value="182" class="langauge">6 months</option>
                                    <option value="365" class="langauge">1 year</option>
                                    <option value="730" class="langauge">2 years</option>
                                    <option value="1095" class="langauge">3 years</option>
                                    <option value="1825" class="langauge">5 years</option>
                                    <option value="2555" class="langauge">7 years</option>
                                    <option value="3650" class="langauge">10 years</option>
                                    <option value="L" class="langauge">Lifetime</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row-2">
                        <div class="title">
                            <div>
                                <label for="Free" style="font-size:medium;margin-right: 1em;">Free</label>
                                <input type="radio" style="font-size:medium;margin-right: 1em;" id="Free" class="radio"
                                    name="radio-btn" value="Free" (change)="radioChangeHandler($event)"
                                    [checked]="radioSelectedValue=='Free'">
                                <label for="Paid" style="font-size:medium;margin-right: 1em;">Paid</label>
                                <input type="radio" style="font-size:medium;margin-right: 1em;" id="Paid" class="radio"
                                    name="radio-btn" value="Paid" (change)="radioChangeHandler($event)"
                                    [checked]="radioSelectedValue=='Paid'">
                            </div>
                        </div>
                    </div>
                    <div class="row-2">
                        <div *ngIf="showPriceBox">
                            <label for="coursePrice" class="label required">Course Price:</label>
                            <input type="text" id="coursePrice" placeholder="&#x20b9; Enter course price" id="title"
                                formControlName="coursePrice" class="title-input">
                        </div>
                        <div *ngIf="showPriceBox">
                            <label for="courseCommission" class="label required">Course Commission:</label>
                            <input type="text" id="courseCommission" placeholder="&#x20b9; Enter course commission rate"
                                id="title" formControlName="courseCommission" class="title-input">
                        </div>
                    </div>
                    <div class="row-2">
                        <div>
                            <label for="position" class="label required">Course Position:</label>
                            <input type="number" id="position" placeholder="Enter Position"
                                id="title" formControlName="position" class="title-input">
                        </div>
                    </div>
                    <!-- <div class="row-2"> -->

                    <!-- <div class="langauge">
                            <div class="title">
                                <label for="period" class="label required">Is available for first purchase?</label>
                                <div class="course">
                                    <input type="checkbox" class="checkbox" id="hidden" value="true" checked>
                                    <label class="courseName" for="hidden">YES</label><br>
                                </div>
                            </div>

                        </div> -->
                    <!-- </div> -->
                </div>
                <div class="image">
                    <label for="imageText" class="label1 required">Course Image (750x422):</label>
                    <div class="course">
                        <img src="{{defaultCoverImage}}" class="courseImage">
                        <label class="imageLabel"> {{defaultCoverImage ==
                            'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg'? 'Upload Image':'Edit\
                            Image'}}
                            <input (change)="displayPhoto($event)" type="file" accept="image/*">
                        </label>
                    </div>
                </div>
            </div>
            <div class="row row-3">
                <div class="learn">
                    <label for="learnText" class="label1 required">What you will learn:</label>
                    <textarea id="learnText" placeholder="What you will learn" formControlName="learn"
                        itemid="learnText" class="learnText"></textarea>
                </div>
            </div>
            <div class="row desc">
                <label for="description" class="label1 required">Description:</label>
                <textarea id="learnText" placeholder="Description" formControlName="courseDescription"
                    itemid="description" class="learnText"></textarea>
            </div>
            <label style="margin-left: -15px;" for="learnText" class="label1 required">Tags:</label>
            <div class="tags">
                <div style="display: flex;margin-left: -15px;">
                    <input type="text" placeholder="+ Add tags" id="addTags" formControlName="tag" class="tagsText">
                    <button class="add-tag-btn" (click)="addTags()">
                        Add
                    </button>
                </div>

                <div class="tagList" *ngFor="let item of listOfTags">
                    <div class="tag-row" (click)="removeTag(item)">
                        <span class="tag">{{item}}</span>
                        <img src="../../../../assets/svgIcons/close.svg" class="close">
                    </div>
                </div>
            </div>
            <!-- <label style="margin-left: -15px;" for="learnText" class="label1 required">Requirements:</label>
            <div class="requirements">
                <div style="display: flex;margin-left: -15px;">
                    <input type="text" placeholder="+ Add Requirements" id="addRequirements" formControlName="requirement" class="requirementText">
                    <button class="add-requirement-btn" (click)="addRequirement()">
                        Add
                    </button>
                </div>

                <div class="requirementList" *ngFor="let item of listOfRequirement">
                    <div class="requirement-row" (click)="removeRequirement(item)">
                        <span class="requirement">{{item}}</span>
                        <img src="../../../../assets/svgIcons/close.svg" class="close">
                    </div>
                </div>
            </div> -->
            <div style="display: flex;justify-content:space-between;">
                <div *ngIf="!isAddindCourse && defaultCoverImage!='https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg'"
                    class="create" (click)="createEditCourse()">
                    <button [ngClass]="{'create-btn-disabled':courseForm.invalid,'create-btn':courseForm.valid}"
                        type="submit" [disabled]="courseForm.invalid">{{isAddindSuccessFully? 'Save Course':'Create
                        Course'}}</button>
                </div>
                <div class="loading-comp" *ngIf="isAddindCourse">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="courseDetailToEdit!=null" class="create" (click)="gotoModules()">
                    <button class="goto-module-btn">{{'Go to Module'}}</button>
                </div>
            </div>
        </form>
    </div>

</div>