import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListBatchComponent } from './list-batch/list-batch.component';
import { BatchComponent } from './batch.component';
import { BatchRoutingModule } from './batch-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { AddEditBatchComponent } from './add-edit-batch/add-edit-batch.component';
import { MatStepperModule } from '@angular/material/stepper';
import { QuillModule } from 'ngx-quill';
import { BatchDetailComponent } from './add-edit-batch/batch-detail/batch-detail.component';
import { CurriculumDetailComponent } from './add-edit-batch/curriculum-detail/curriculum-detail.component';
import { ActivityDetailComponent } from './add-edit-batch/activity-detail/activity-detail.component';
import { AddNewFieldComponent } from './add-edit-batch/add-new-field/add-new-field.component';
import { AddModuleDetailComponent } from './add-edit-batch/add-module-detail/add-module-detail.component';
import { AddEditActivityDetailComponent } from './add-edit-batch/add-edit-activity-detail/add-edit-activity-detail.component';
import { ViewBatchEnrolledUserComponent } from './view-batch-enrolled-user/view-batch-enrolled-user.component';
import { BatchAnnouncementComponent } from './batch-announcement/batch-announcement.component';
import { AddBatchAnnouncementComponent } from './add-batch-announcement/add-batch-announcement.component';



@NgModule({
  declarations: [BatchComponent,
    ListBatchComponent,
    AddEditBatchComponent,BatchDetailComponent,CurriculumDetailComponent,ActivityDetailComponent, AddNewFieldComponent, AddModuleDetailComponent, AddEditActivityDetailComponent, ViewBatchEnrolledUserComponent, BatchAnnouncementComponent, AddBatchAnnouncementComponent
  ],
  imports: [
    CommonModule,BatchRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    NgxPaginationModule,
    SidebarModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link', 'image', 'video'],
        ],
      },
    }),
  ]
})
export class BatchModule { }
