import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CoursesComponent } from './courses/courses.component';
import { QuizzesComponent } from './quizzes/quizzes.component';
import { MainCatalogComponent } from './main-catalog.component';
import { MainCatalogRoutingModule } from './main-catalog-routing.module';
import { QuizzesModule } from './quizzes/quizzes.module';
import { SpecialOffersModule } from './special-offers/special-offers.module';
import { CoursesModule } from './courses/courses.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExcelService } from './excel.service';
import { ForumComponent } from './forum/forum.component';
import { ForumModule } from './forum/forum.module';
import { ReferralComponent } from './referral/referral.component';
import { ReferralModule } from './referral/referral.module';
import { EnrollAddUserComponent } from './enroll-add-user/enroll-add-user.component';
import { EnrollAddUserModule } from './enroll-add-user/enroll-add-user.module';
import { NotificationComponent } from './notification/notification.component';
import { NotificationModule } from './notification/notification.module';
import { AclComponent } from './acl/acl.component';
import { AclModule } from './acl/acl.module';
import { HelpComponent } from './help/help.component';
import { HelpModule } from './help/help.module';
import { JwPaginationModule } from 'jw-angular-pagination';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { UserEnrolledCourseComponent } from './user/user-enrolled-course/user-enrolled-course.component';
import { ReportsModule } from './reports/reports.module';
import { UserModule } from './user/user.module';
import { BannerManagementModule } from './banner-management/banner-management.module';
import { AuthFormComponent } from './1component/auth-form/auth-form.component';
import { AvatarComponent } from './1component/avatar/avatar.component';
import { BadgeComponent } from './1component/badge/badge.component';
import { BarChartComponent } from './1component/bar-chart/bar-chart.component';
import { BasicTableComponent } from './1component/basic-table/basic-table.component';
import { ButtonGroupComponent } from './1component/button-group/button-group.component';
import { ButtonComponent } from './1component/button/button.component';
import { CardComponent } from './1component/card/card.component';
import { DataTableComponent } from './1component/data-table/data-table.component';
import { DropdownComponent } from './1component/dropdown/dropdown.component';
import { FormChecksComponent } from './1component/form-checks/form-checks.component';
import { FormGroupComponent } from './1component/form-group/form-group.component';
import { IconComponent } from './1component/icon/icon.component';
import { InputGroupComponent } from './1component/input-group/input-group.component';
import { PieChartComponent } from './1component/pie-chart/pie-chart.component';
import { TagComponent } from './1component/tag/tag.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { StepperComponent } from './1component/stepper/stepper.component';
import { WebinarManagementModule } from './webinar-management/webinar-management.module';
import { PlanModule } from './plan/plan.module';
import { TradeAdvisoryModule } from './trade-advisory/trade-advisory.module';
import { TickerModule } from './ticker/ticker.module';
import { ReferralDashboardModule } from './referral-dashboard/referral-dashboard.module';
import { PayoutModule } from './payout/payout.module';
import { SubscriptionModule } from './subscription/subscription.module';
import { EmployeeManagementModule } from './employee-management/employee-management.module';
import { WebhookSearchModule } from './webhook-search/webhook-search.module';
import { ComplianceModule } from './compliance/compliance.module';
import { ShortVideosModule } from './short-videos/short-videos.module';
import { CampaignManagementModule } from './campaign-management/campaign-management.module';
import { AuditLogModule } from './audit-log/audit-log.module';
import { TransactionLogModule } from './transaction-log/transaction-log.module';
import { UtmModule } from './utm/utm.module';
import { LeadsModule } from './leads/leads.module';
import { YoutubeVideoModule } from './youtube-video/youtube-video.module';
import { BatchModule } from './batch/batch.module';
import { CommunityModule } from './community/community.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LeaderboardModule } from './leaderboard/leaderboard.module';
import { QuillModule } from 'ngx-quill';
@NgModule({
  declarations: [
    CoursesComponent,
    QuizzesComponent,
    MainCatalogComponent,
    ForumComponent,
    ReferralComponent,
    // NotificationComponent,
    AclComponent,
    HelpComponent,

    LeftSidebarComponent,
    HeaderComponent,
    FooterComponent,

    //  UI Component Library

    // AlertComponent,
    AvatarComponent,
    BadgeComponent,
    TagComponent,
    ButtonComponent,
    ButtonGroupComponent,
    FormGroupComponent,
    InputGroupComponent,
    FormChecksComponent,
    AuthFormComponent,
    DropdownComponent,
    IconComponent,
    CardComponent,
    BasicTableComponent,
    DataTableComponent,
    PieChartComponent,
    BarChartComponent,
    StepperComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    FormsModule,
    ForumModule,
    ReactiveFormsModule,
    MainCatalogRoutingModule,
    NotificationModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    QuizzesModule,
    AclModule,
    CoursesModule,
    SpecialOffersModule,
    HelpModule,
    ReferralModule,
    NgxPaginationModule,
    JwPaginationModule,
    UserModule,
    SidebarModule.forRoot(),
    MatNativeDateModule,
    WebinarManagementModule,
    ReportsModule,
    WebinarManagementModule,
    WebhookSearchModule,
    ComplianceModule,
    CampaignManagementModule,
    AuditLogModule, UtmModule, EmployeeManagementModule, SubscriptionModule,
    PlanModule, TradeAdvisoryModule, TickerModule, PayoutModule,
    LeadsModule, YoutubeVideoModule, ShortVideosModule, ReferralDashboardModule,
    TransactionLogModule, BatchModule, CommunityModule, DashboardModule, LeaderboardModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link'],
        ],
      },
    }),
  ],
  providers: [ExcelService, DatePipe, Clipboard],
})
export class MainCatalogModule { }
