<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Plan
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addPlanForm" [formGroup]="addPlanForm">

        <div class="row">
            <div class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Plan</label>
                <select id="type" name="type" class="input-style" formControlName="name"
                    (change)="typeSelected($event)">
                    <option value="Select Plan" selected disabled>Select Plan</option>
                    <option value="Silver">Silver</option>
                    <option value="Gold">Gold</option>
                    <option value="Diamond">Diamond</option>
                    <!-- <option value="Free">Free</option> -->
                </select>
            </div>
            <div class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Plan SubType</label>
                <select id="type" name="type" class="input-style" formControlName="subType">
                    <option value="Select Plan SubType" selected disabled>Select Plan SubType</option>
                    <option *ngIf="selectedType!='Free' && selectedType!=null" value="Monthly">Monthly</option>
                    <option *ngIf="selectedType!='Free' && selectedType!=null" value="Quarterly">Quarterly</option>
                    <option *ngIf="selectedType!='Free' && selectedType!=null" value="Half Yearly">Half Yearly</option>
                    <option value="Ten Days">Ten Days</option>
                    <option value="Seven Days">Seven Days</option>
                </select>
            </div>
        </div>

        <div *ngIf="selectedType!='Free' && selectedType!=null" class="row">
            <div class="col-md-6">
                <label for="listingPrice" class="label required">Listing Price</label>
                <input type="number" placeholder="Listing Price" id="listingPrice" formControlName="listingPrice"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="sellingPrice" class="label required">Selling Price</label>
                <input type="number" placeholder="Selling Price" id="sellingPrice" formControlName="sellingPrice"
                    class="input-style">
            </div>
        </div>

        <div *ngIf="selectedType!='Free' && selectedType!=null" class="row">
            <div class="col-md-6">
                <label for="rzpPlanId" class="label required">RazorPay PLanId</label>
                <input type="string" placeholder="RazorPay PLanId" id="rzpPlanId" formControlName="rzpPlanId"
                    class="input-style">
            </div>
            
        </div>

        <div *ngIf="selectedType!='Free' && selectedType!=null" class="row" style="margin-bottom: 1rem;margin-top: .5rem;">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <label for="isSponsored" class="label " style="margin-bottom: 0px;margin-right: 1em;">Is Popular
                    ?</label>
                <input type="checkbox" placeholder="Is Sponsored?" id="isSponsored" [checked]="popular"
                    (change)="setIsPopular($event)">
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-12">
                <label for="description" class="label required">Description</label>
                <textarea type="text" placeholder="Description" id="description" formControlName="description"
                    class="textarea-style"
                    style="width: 100%;height: 6rem;margin-bottom: 1rem;padding-left: 0.5rem;padding-top: 0.5rem;border-radius: 5px;padding-right: 0.5rem"></textarea>
            </div>
        </div> -->
        <div  class="row">
            <div class="col-md-10">
                <label for="Target" class="label required">Features</label>
                <input type="text" placeholder="Add" style="width: 80%;" id="Target" class="input-style" formControlName="feature">
            </div>
            <div class="col-md-2" style="margin-top:1rem">
                <input type="submit" class="save-btn" value="Add" (click)="addFeature()" />
            </div>
        </div>

        <div  class="row" style="width: 39em;margin-left: .1rem;">
            <div class="tagList" *ngFor="let item of listOfFeatures">
                <div class="tag-row" (click)="removeFeature(item)">
                    <span class="tag">{{item}}</span>
                    <img src="../../../../assets/svgIcons/close.svg" class="close">
                </div>
            </div>
        </div>

        <div class="save">
            <input type="submit" [ngClass]="{'save-btn-disabled':addPlanForm.invalid || listOfFeatures.length==0 ,'save-btn':addPlanForm.valid &&  listOfFeatures.length!=0 }"
                value="{{'Save'}}" [disabled]="addPlanForm.invalid  || listOfFeatures.length==0 " (click)="addEditPlan()" />
        </div>
    </form>
</div>