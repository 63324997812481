import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainReferralComponent } from './main-referral/main-referral.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: MainReferralComponent,
  // },
  // { path: '', pathMatch: 'full', redirectTo: 'catalog/referral' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReferralRoutingModule {}
