import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-user-enrolled-course',
  templateUrl: './user-enrolled-course.component.html',
  styleUrls: ['./user-enrolled-course.component.scss']
})
export class UserEnrolledCourseComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<UserEnrolledCourseComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log(data);
    dialogRef.disableClose = true;

    // this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

}
