<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{filerToEdit!=null?'Edit': 'Add'}} Filter
            </h1>
        </div>
        <div (click)="cancel('close')">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="filterUserForm" [formGroup]="filterUserForm" *ngIf="!isLoading">
        <div class="row">

            <div class="col-md-12">
                <label for="campaignDateTime" class="label required">Select User Group</label>
                <select id="type" name="type" class="input-style" (change)="userTargetSelected($event)"
                    formControlName="targetUser">
                    <option value="Select User Group" selected disabled>Select User Group</option>
                    <option value="All User">All User</option>
                    <option value="Course Enrolled">Course Enrolled</option>
                    <option value="Course Consumption">Course Consumption</option>
                    <option value="Course Recommendation">Course Recommendation</option>
                    <option value="Wishlist">Wishlist</option>
                    <option value="Expiry">Expiry</option>
                    <option value="Active User">Active User</option>
                    <option value="Inactive User">Inactive User</option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12"
                *ngIf="selectedUserTarget=='Course Enrolled'||selectedUserTarget=='Course Consumption' ||selectedUserTarget=='Course Recommendation'||selectedUserTarget=='Wishlist'||selectedUserTarget=='Expiry'">
                <label for="course" class="label required">{{getSelectTitle(selectedUserTarget)}}</label>
                <div style="width: 80%;">
                    <ng-multiselect-dropdown
                        *ngIf="selectedUserTarget=='Course Consumption' || selectedUserTarget=='Expiry'"
                        style="width: 100%;" [placeholder]="'Select Course'" [settings]=" course1DropdownSettings"
                        formControlName="course" [data]="listOfCourse" [(ngModel)]="selectedCourse"
                        (onSelect)="onBranchSelect($event)" (onDeSelect)="onBranchDeSelect($event)"
                        (onSelectAll)="onBranchSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <ng-multiselect-dropdown
                        *ngIf="selectedUserTarget!='Course Consumption' &&  selectedUserTarget!='Expiry'"
                        style="width: 100%;" [placeholder]="'Select Course'" [settings]=" courseDropdownSettings"
                        formControlName="course" [data]="listOfCourse" [(ngModel)]="selectedCourse"
                        (onSelect)="onBranchSelect($event)" (onDeSelect)="onBranchDeSelect($event)"
                        (onSelectAll)="onBranchSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-12 mt-3" *ngIf="selectedUserTarget=='Course Recommendation'">
                <label for="course" class="label required">Recommended Course</label>
                <div style="width: 80%;">
                    <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Course'"
                        formControlName="recommendedCourse" [settings]="courseDropdownSettings" [data]="listOfCourse"
                        [(ngModel)]="selectedRecommendedCourse" (onSelect)="onBranchSelect($event)"
                        (onDeSelect)="onBranchDeSelect($event)" (onSelectAll)="onBranchSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-12  mt-3" *ngIf="selectedUserTarget=='Course Consumption'">
                <label for="course" class="label required">Select Percentage Range</label>
                <div style="width: 80%;">
                    <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Percentage Range'"
                        [settings]="rangeDropdownSettings" [data]="listOfRange" [(ngModel)]="selectedRange"
                        formControlName="range" (onSelect)="onBranchSelect($event)"
                        (onDeSelect)="onBranchDeSelect($event)" (onSelectAll)="onBranchSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-12 mt-3" *ngIf="selectedUserTarget=='Expiry' ">
                <label for="course" class="label required">Select Duration</label>
                <div style="width: 80%;">
                    <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Duration'"
                        formControlName="expiryDuration" [settings]="duartionDropdownSettings" [data]="listOfDuartion"
                        [(ngModel)]="selectedDuration" (onSelect)="onBranchSelect($event)"
                        (onDeSelect)="onBranchDeSelect($event)" (onSelectAll)="onBranchSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-12 mt-3" *ngIf="selectedUserTarget=='Inactive User' || selectedUserTarget=='Active User' || selectedUserTarget=='Course Enrolled'||selectedUserTarget=='Course Consumption' ||selectedUserTarget=='Course Recommendation'||selectedUserTarget=='Wishlist'||selectedUserTarget=='Expiry'">
                <label for="course" class="label ">{{selectedUserTarget=='Inactive User' || selectedUserTarget=='Active User'? 'Select Duration' :'Select User Active Duration'}}</label>
                <div style="width: 80%;">
                    <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Duration'"
                        formControlName="duration" [settings]="duartion1DropdownSettings" [data]="listOfDuartion"
                        [(ngModel)]="selectedDuration" (onSelect)="onBranchSelect($event)"
                        (onDeSelect)="onBranchDeSelect($event)" (onSelectAll)="onBranchSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <input type="checkbox" style="font-size:medium;margin-right: 1em;" id="freeExcluded" class="radio"
                    value="Free" [checked]="isFreeExcluded" (change)="freeExcludedChange($event)">
                <label for="freeExcluded" style="font-size:medium;margin-right: 1em;">Exclude User with Free
                    Course</label>
                <!--  -->
                <br>
                <div style="height:10px;"></div>
                <input type="checkbox" style="font-size:medium;margin-right: 1em;" id="paidExcluded" class="radio"
                    value="Paid" [checked]="isPaidExcluded" (change)="paidExcludedChange($event)">
                <label for="paidExcluded" style="font-size:medium;margin-right: 1em;">Exclude User with paid
                    Course</label>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-6">
                <label for="startDate" class="label" style="margin-bottom: 0.5em;">Start Date</label>
                <input type="datetime-local" id="startDate" class="input-style" [max]="maxStartDate"
                    formControlName="startDate" (input)="startDateChange($event)">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label" style="margin-bottom: 0.5em;">End Date</label>
                <input type="datetime-local" id="endDate" class="input-style" [min]="minEndDate"
                    formControlName="endDate" (input)="endDateChange($event)">
            </div>
        </div> -->
        <div class="save">

            <button class="cancel-btn" (click)="cancel(null)">Clear filter</button>
            <input type="submit"
                [ngClass]="{'save-btn-disabled':filterUserForm.invalid || filterUserForm.get('targetUser')?.value=='Select User Group','save-btn':filterUserForm.valid  &&  filterUserForm.get('targetUser')?.value!='Select User Group'}"
                value="{{'Apply filter'}}"
                [disabled]="filterUserForm.invalid || filterUserForm.get('targetUser')?.value=='Select User Group'"
                (click)="filterUserForm.invalid || filterUserForm.get('targetUser')?.value=='Select User Group'? null:applyFilter()" />

        </div>

    </form>
</div>