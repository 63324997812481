import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListLeaderboardComponent } from './list-leaderboard/list-leaderboard.component';
import { LeaderboardComponent } from './leaderboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { LeaderboardRoutingModule } from './leaderboard-routing.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';



@NgModule({
  declarations: [
    ListLeaderboardComponent,LeaderboardComponent
  ],
  imports: [
    CommonModule,
    LeaderboardRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    SidebarModule.forRoot(),MatNativeDateModule,MatDatepickerModule
  ]
})
export class LeaderboardModule { }
