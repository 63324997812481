<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit?'Edit':'Add'}} User
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="editUserForm" [formGroup]="editUserForm">

        <div *ngIf="dataToEdit==null || dataToEdit==undefined" class="br mb" style="margin-bottom: 1rem">
            <input type="radio" name="addParticipantType" value="single" class="input-checkbox-style" checked
                (change)="addUserTypeChange($event)">
            <label for="name" class="label" style="display: inline-block;margin: 1em;margin-top: 1.2em;">Single</label>

            <input type="radio" name="addParticipantType" value="bulkUpload" class="input-checkbox-style"
                (change)="addUserTypeChange($event)">
            <label for="name" class="label" style="display: inline-block;margin: 1em;margin-top: 1.2em;">Bulk
                Upload</label>
        </div>
        <div *ngIf="isBulkUpload">
            <label for="Brand Image" class="label required">Excel File: <a
                    href="https://s3.ap-south-1.amazonaws.com/mmtc-tg2.0/Sample+Files/advisory_user_bulk_upload_Sample_excel_file.xlsx">(Download
                    Sample File)</a> </label>
            <div class="row">
                <div class="col-md-12">
                    <div class="image">
                        <div class="proof">
                            <img *ngIf="defaultCampaignExcel!=null" src="{{defaultCampaignExcel}}" class="proofImage">
                            <label *ngIf="defaultCampaignExcel==null" class="imageLabel"> Upload
                                <input (change)="displayExcel($event)" type="file" accept=".xlsx">
                            </label>
                            <label *ngIf="defaultCampaignExcel!=null" class="imageLabelEdit">
                                <mat-icon class="matIcon">edit</mat-icon>
                                <input (change)="displayExcel($event)" type="file" accept=".xlsx">
                            </label>

                        </div>
                    </div>
                </div>
            </div>

            <div class="save">
                <input type="submit" class="save-btn" value="Save" [disabled]="defaultCampaignExcel==null"
                    (click)="addBulkUser()" />
            </div>
        </div>
        <div *ngIf="!isBulkUpload">
            <div class="row">
                <div class="col-md-12">
                    <label for="fullName" class="label required">Full Name</label>
                    <input type="text" placeholder="Full Name" id="fullName" formControlName="fullName"
                        class="input-style">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="email" class="label required">Email</label>
                    <input type="email" placeholder="Email" id="email" formControlName="email" class="input-style">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="phone" class="label required">Phone Number</label>
                    <input type="text" pattern="\d*" placeholder="Phone Number" id="phone" formControlName="phone"
                        maxlength="10" class="input-style">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="dob" class="label required">DOB</label>
                    <input type="date" placeholder="Phone Number" id="dob" formControlName="dob" class="input-style">
                </div>
                <div class="col-md-12">
                    <label for="gender" class="label">Gender</label>
                    <select id="gender" name="gender" class="input-style" formControlName="gender">
                        <option value="Select Gender" selected disabled>Select Gender</option>
                        <option *ngFor="let gender of ['Male','Female','Other']" [value]="gender" class="levels">
                            {{gender}}</option>
                    </select>
                </div>
            </div>
            <div class="save">
                <input type="submit"
                    [ngClass]="{'save-btn-disabled':editUserForm.invalid  ,'save-btn':editUserForm.valid }"
                    value="{{'Save'}}" [disabled]="editUserForm.invalid" (click)="addEditUser()" />
            </div>
        </div>
    </form>
</div>