import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditWebinarDetailComponent } from './add-edit-webinar-detail/add-edit-webinar-detail.component';
import { ListWebinarComponent } from './list-webinar/list-webinar.component';

const routes: Routes = [
  {
    path: '',
    component: ListWebinarComponent,
  },
  {
    path: 'add-edit-webinar',
    component: AddEditWebinarDetailComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'catalog/webinar' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebinarManagementRoutingModule { }
