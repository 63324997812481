import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tgAdvisoryApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-edit-tgadvisory-user',
  templateUrl: './add-edit-tgadvisory-user.component.html',
  styleUrls: ['./add-edit-tgadvisory-user.component.scss']
})
export class AddEditTgadvisoryUserComponent implements OnInit {

  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  httpHeaders: any;

  myHeaders = new Headers();
  formdata = new FormData();

  // Excel Bulk
  selectedCampaignExcel: any = null;
  selectedCampaignExcelName: any = null;
  defaultCampaignExcel: any;
  excelTarget: any;

  fullNameControl = new FormControl('', [
    Validators.required,
  ]);

  emailControl = new FormControl('', [
    Validators.required,
  ]);

  phoneControl = new FormControl('', [Validators.required, Validators.pattern('^[- +()0-9]+$'),Validators.minLength(10),Validators.maxLength(10)]);
  dobControl = new FormControl('', []);
  genderControl = new FormControl('Select Gender', []);

  editUserForm: FormGroup = new FormGroup({
    fullName: this.fullNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
    dob: this.dobControl,
    gender: this.genderControl,
  });
  userDetails: any;
  isBulkUpload: boolean = false;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.editUserForm = this.formBuilder.group({
      fullName: this.fullNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      dob: this.dobControl,
      gender: this.genderControl,
    });
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.editUserForm.get('fullName')?.setValue(this.dataToEdit['fullName']);
      this.editUserForm.get('email')?.setValue(this.dataToEdit['email']);
      this.editUserForm.get('phone')?.setValue(this.dataToEdit['phone']);
      if (this.dataToEdit['dob']) {
        this.editUserForm
          .get('dob')
          ?.setValue(
            new Date(this.dataToEdit['dob'])
              .toISOString()
              .slice(0, 10)
          );
      }
      if (this.dataToEdit['gender']) {
        this.editUserForm.get('gender')?.setValue(this.dataToEdit['gender']);
      }
    }
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }


  displayExcel(event: any) {
    this.selectedCampaignExcel = event.target.files[0];
    this.selectedCampaignExcelName = this.selectedCampaignExcel['name'];
    var reader = new FileReader();
    this.excelTarget = event.target;
    reader.onload = (event1: any) => {
      this.defaultCampaignExcel = '../../../../assets/img/excel.png';
    };
    reader.readAsDataURL(this.selectedCampaignExcel);
  }

  addBulkUser() {
    if (this.excelTarget.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(this.excelTarget.files[0]);

    var excelList: any = [];
    var userData: any = [];
    reader.onload = async (e: any) => {
      const storeNewxls: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(storeNewxls, {
        type: 'binary',
        cellDates: true,
      });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      excelList = data;
      userData = [];
      excelList.forEach((data: any) => {
        if (!data['phone']) {
          return;
        }
        let param: any = {};
        if (data['fullName']) {
          param['fullName'] = data['fullName'];
        }
        if (data['email']) {
          param['email'] = data['email'];
        }
        if (data['phone']) {
          param['phone'] = data['phone'];
        }
        if (data['dob']) {
          param['dob'] = new Date(new Date(data['dob']).setHours(new Date(data['dob']).getHours() + 1));
        }
        if (data['gender']) {
          param['gender'] = data['gender'];
        }
        userData.push(param);
      });
      console.log(userData);

      this.formdata.set('userData', JSON.stringify(userData));
      this.myHeaders.append(
        'Authorization',
        `Bearer ${this.userDetails['accessToken']}`
      );
      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };
      let URL: any;
      URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.addBulkUser}`;
      try {
        fetch(URL, requestOptions)
          .then((result) => {
            if (result) {
              let data = result.json();
              data.then((res) => {
                if (res['success']) {
                  this.cancel({ success: true });
                }
                this.isLoading = false;
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log('error', error);
          });
        return;
      } catch (error) {
        console.log('error', error);
        this.isLoading = false;
      }
    };
  }


  addEditUser() {
    this.isLoading = true;

    var formdata = new FormData();

    if (this.dataToEdit != null) {
      formdata.set('id', this.dataToEdit['_id']);
    } else {
      formdata.set('id', '0');
    }

    formdata.set('fullName', this.editUserForm.get('fullName')?.value);
    formdata.set('email', this.editUserForm.get('email')?.value);
    formdata.set('phone', this.editUserForm.get('phone')?.value);
    formdata.set('dob', this.editUserForm.get('dob')?.value);
    if (this.editUserForm.get('dob')?.value) {
      formdata.set('dob', this.editUserForm.get('dob')?.value);
    }
    if (this.editUserForm.get('gender')?.value && this.editUserForm.get('gender')?.value != 'Select Gender') {
      formdata.set('gender', this.editUserForm.get('gender')?.value);
    }

    var headers = new Headers();

    headers.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: formdata,
      redirect: 'follow',
    };
    const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.addEditUser}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.editUserForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                if (res['message'] == 'PHONE_EMAIL_ANY_EXIST') {
                  this.showErrorToaster(
                    'Phone Number or Email is already in use.',
                    'Failed'
                  );
                }
                // else if (res['message'] == 'EMAIL_EXIST') {
                //   this.showErrorToaster(
                //     'Email Address is already in use.',
                //     'Failed'
                //   );
                // } 
                else {
                  this.showErrorToaster('Something went wrong', 'Failed');
                }
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  async addUserTypeChange(event: any) {
    var value = event.target.value;
    if (value == 'single') {
      this.isBulkUpload = false;
    } else {
      this.isBulkUpload = true;
    }
    console.log('addUserypeChange', value);
  }

}
