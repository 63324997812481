import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewCourseComponent } from './add-new-course/add-new-course.component';
import { CourseUserListComponent } from './course-user-list/course-user-list.component';
import { ViewReviewsComponent } from './view-reviews/view-reviews.component';
import { CourseForumComponent } from './course-forum/course-forum.component';

const routes: Routes = [
  {
    path: 'new-course',
    component: AddNewCourseComponent,
  },
  {
    path: 'course-user-list',
    component: CourseUserListComponent,
  },
  {
    path: 'view-reviews',
    component: ViewReviewsComponent,
  },
  {
    path: 'course-forum',
    component: CourseForumComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/courses/new-course' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoursesRoutingModule {}
