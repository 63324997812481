import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';
import { AddZoomFileComponent } from '../add-zoom-file/add-zoom-file.component';
import { InterestedUserReportDialogComponent } from '../interested-user-report-dialog/interested-user-report-dialog.component';
import { ViewWebinarComponent } from '../view-webinar/view-webinar.component';

export interface Webinar {
  _id: string;
  title: string;
  thumbnail: string;
  author: any;
  link: string;
  webinarDate: Date;
  startTime: number;
  endTime: number;
  lobbyTime: number;
  totalDuration: number;
  description: string;
  webinarFor: string;
  takeAway: [string];
  type: string;
  pdfLink: string;
  zoomFile: string;
  linkType:string;
  // interestedUsers:string;
  // courses:string;
  schedulerId: any;
  isActive: boolean;
}

@Component({
  selector: 'app-list-webinar',
  templateUrl: './list-webinar.component.html',
  styleUrls: ['./list-webinar.component.scss'],
})
export class ListWebinarComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchWebinar();
  }

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  // downloadReport: boolean = false;

  listOfWebinarToDisplay: Array<Webinar> = [];
  listOfWebinar: Array<Webinar> = [];
  // listOfWebinarOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];

  myHeaders = new Headers();

  async fetchWebinar() {
    this.isLoading = true;
    this.listOfWebinarToDisplay = [];
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.fetchWebinarsForAdmin}`;

      await this.as
        .fetchDetailViaGet(URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfWebinar = data['result'];
            this.listOfWebinarToDisplay = [...this.listOfWebinar];
            this.isLoading = false;
          } else {
            this.listOfWebinar = [];
            this.listOfWebinarToDisplay = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfWebinar = [];
      this.listOfWebinarToDisplay = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfWebinarToDisplay = [];
      this.listOfWebinar.forEach((webinar: any, index: number) => {
        if (
          String(webinar.language)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(webinar.categoryId.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(webinar);
        }
      });
      this.listOfWebinarToDisplay = tempSearchList;
    } else {
      this.listOfWebinarToDisplay = [];
      this.listOfWebinarToDisplay = this.listOfWebinar;
    }
  }

  updateActiveStatus(webinar: Webinar, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { webinar: webinar, source: 'Active Webinar' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          webinarId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };

        const URL = `${webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusWebinar
          }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfWebinarToDisplay.forEach(
                (webinar: any, index: number) => {
                  if (webinar['_id'] == res['value']['_id']) {
                    webinar.isActive = isActive;
                  }
                }
              );
              this.listOfWebinar = [...this.listOfWebinarToDisplay];
              if (isActive) {
                this.showSuccessToaster(
                  'Webinar Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Webinar Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating webinar. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  downloadReport(webinar: Webinar) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      webinar: webinar['_id'],
    };
    dialogConfig.maxHeight = `95vh`;
    let dialogRef = this.matDialog.open(
      InterestedUserReportDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        return;
      } else if (res['error'] != null || res['error'] != undefined) {

        return;
      } else {
        return;
      }
    });
  }
  uploadZoomFile(webinar: Webinar) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      webinar: webinar['_id'],
    };
    dialogConfig.maxHeight = `95vh`;
    let dialogRef = this.matDialog.open(
      AddZoomFileComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        return;
      } else if (res['error'] != null || res['error'] != undefined) {

        return;
      } else {
        this.listOfWebinar.forEach((web, i: number) => {
          if (web['_id'] == webinar['_id']) {
            web.zoomFile = res['zoomFile'];
          }
        });
        this.listOfWebinarToDisplay = this.listOfWebinar;
        this.showSuccessToaster(
          'Zoom Excel file uploaded Successfully',
          'Success'
        );
        return;
      }
    });
  }

  deleteWebinar(webinar: Webinar) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { webinar: webinar, source: 'Delete Webinar' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          webinarId: res['value']['_id'],
          action: 'Delete',
        };

        const URL = `${webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusWebinar
          }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfWebinarToDisplay.forEach((webinar: any, i: number) => {
                if (webinar['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfWebinarToDisplay.splice(index, 1);
              this.listOfWebinar = [...this.listOfWebinarToDisplay];

              this.showSuccessToaster(
                'Webinar Deleted Successfully',
                'Success'
              );
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting webinar. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  addNewWebinar() {
    this.toggleOpened();
  }

  editWebinar(magazine: any) {
    let magazineToEdit;
    this.listOfWebinarToDisplay.forEach((mgz: any) => {
      if (mgz['_id'] == magazine._id) {
        magazineToEdit = mgz;
      }
    });
    this.dataToEdit = magazineToEdit;
    this.toggleOpened();
  }
  gotoAddWebinar() {
    this.router.navigate(['/catalog/webinar/add-edit-webinar']);
  }
  editWebinarDetail(webinar: any) {
    this.as.webinarEdit = webinar;
    this.router.navigate(['/catalog/webinar/add-edit-webinar']);
  }
  viewWebinarDetail(webinar: any) {
    this.as.webinarDetailToView = webinar;
    this.router.navigate(['/catalog/webinar/webinar-statistic']);

  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing user details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding user details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfWebinarToDisplay.findIndex(
          (webinar: any) => webinar['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfWebinarToDisplay.push(event);
          this.listOfWebinar = [...this.listOfWebinarToDisplay];
          this.showSuccessToaster('Webinar added successfully', 'Success');
        } else {
          this.listOfWebinarToDisplay[index] = event;
          this.listOfWebinar = [...this.listOfWebinarToDisplay];
          this.showSuccessToaster('Webinar Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  viewWebinar(webinar: Webinar) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { webinar: webinar };
    dialogConfig.width = '40vw';
    dialogConfig.maxHeight = '70vh';
    let dialogRef = this.matDialog.open(ViewWebinarComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
      }
    });
  }
}
