<div class="main-popup-div">
    <div class="popup-heding">
        <div style="font-size: medium;">
            Add New Module
        </div>
        <div (click)="close(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading">
        <div class="popup-body">
            <form name="addModuleForm" [formGroup]="addModuleForm">
                <label class="required">Module Name</label>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field style="width:100%;line-height: 0;" appearance="outline">
                            <input matInput placeholder="Module Name" type="text" formControlName="moduleName">
                            <mat-error *ngIf="moduleNameFormControl.invalid">
                                {{getModuleNameErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <label class="required">Position</label>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field style="width:100%; ;line-height: 0" appearance="outline">
                            <input matInput placeholder="Enter position" type="number" formControlName="position">
                            <mat-error *ngIf="PositionFormControl.invalid">
                                {{getPositionErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" style="margin-top:2em;">
                    <div class="col-md-12">
                        <button
                            [ngClass]="{ 'save-btn-disabled': addModuleForm.invalid, 'save-btn-enabled': addModuleForm.valid }"
                            type="submit" (click)="addNewModule()" [disabled]="addModuleForm.invalid">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>