<div *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
</div>
<div *ngIf="!isLoading" style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon" style="font-size: 1rem;">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Referral Details</h2>
    <hr>
    <div class="row" style="margin-top:1rem;">
       
        <div class="table-responsive">
            <table class="table align-items-center table-flush">
                <thead class="thead-light">
                    <tr>
                        <th style="text-align: center;font-size: 13px;">Name</th>
                        <th style="text-align: center;font-size: 13px;">Email</th>
                        <th style="text-align: center;font-size: 13px;">Phone</th>
                        <th style="text-align: center;font-size: 13px;">Enrolled Course</th>
                        <th style="text-align: center;font-size: 13px;">App Installation Date</th>
                        
                    </tr>
                </thead>
                <tbody *ngIf="userDetails.length==0">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                            style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                           Referral not found!
                        </td>
                        <td></td>
                    </tr>
                </tbody>
                <tbody *ngIf="userDetails.length>0">
                    <tr *ngFor="let user of userDetails">
                        <td style="text-align: center;font-size: 13px;">{{user['user']['fullName']}}</td>
                        <td style="text-align: center;font-size: 13px;">{{user['user']['email']}}</td>
                        <td style="text-align: center;font-size: 13px;">{{user['user']['phone']}}</td>
                        <td style="text-align: center;font-size: 13px;">{{user['user']['enrolledCourse'].length}}</td>
                        <td style="text-align: center;font-size: 13px;">{{user['user']['createdAt']|date}}</td>
                    </tr>

                </tbody>
            </table>
        </div>
        
    </div>