import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../delete-module-activity-dialog/delete-module-activity-dialog.component';
import { HttpHeaders } from '@angular/common/http';
export interface IUser {
  id: number;
  name: string;
  phone: number;
  email: string;
  subscriptionCount: number;
  address: string;
}
@Component({
  selector: 'app-view-reviews',
  templateUrl: './view-reviews.component.html',
  styleUrls: ['./view-reviews.component.scss'],
})
export class ViewReviewsComponent implements OnInit {
  userDetail: any;
  isLoading: boolean = true;
  myHeaders = new Headers();
  listOfReviews: any = [];
  listOfReviewsOriginal: any = [];
  courseId: String = '';
  p: any = 1;
  count: any = 50;
  httpHeaders: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    console.log('Called Constructor');
    this.routes.params.subscribe((params) => {
      this.courseId = params['courseId'];
    });
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
  }

  ngOnInit(): void {
    this.fetchReviews();
  }


  backToCourse() {
    this.router.navigate(['/catalog/courses']);
  }

  searchReviewList(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let reviewList: any = [];
    this.listOfReviews = [...this.listOfReviewsOriginal];
    if (serachQuery != '') {
      this.listOfReviews.forEach((course: any) => {
        if (
          String(course['userName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(course['reviewText'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          reviewList.push(course);
        }
      });
      this.listOfReviews = [...reviewList];
    } else {
      this.listOfReviews = [...this.listOfReviewsOriginal];
    }
  }

  async fetchReviews() {
    this.isLoading = true;
    try {


      const param = {
        course: this.courseId, admin: true,
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchCourseReview}`;
      await this.as.postMethodWithToken(param, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfReviews = data['result'];
          this.listOfReviews.forEach((review: any) => {
            review['isDeleting'] = false;
          });
          this.listOfReviewsOriginal = this.listOfReviews;
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  async featureReview(reviewId: String, isFeatured: boolean) {
    const param = {
      reviewId: reviewId,
      isFeatured: isFeatured,
    };

    this.listOfReviews.forEach((review: any) => {
      if (review['_id'] == reviewId) {
        review['isFeatured'] = isFeatured;
      }
    });
    if (isFeatured) {
      this.showSuccessToaster('Review Featured Successfully', 'Success');
    } else {
      this.showSuccessToaster('Review Removed From Featured Successfully', 'Success');
    }
    const URL = `${webApi.domain + webApi.endPoint.featureReview}`;
    await this.as
      .putMethodWithToken(param, URL,this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          // this.listOfUser = data['data'];
        }
      });
  }

  deleteReview(review: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Review', review: review };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfReviews.forEach((review: any) => {
          review['isDeleting'] = false;
        });
        return;
      } else {
        this.listOfReviews.forEach((review: any) => {
          if (review['_id'] == res['value']) {
            review['isDeleting'] = true;
          }
        });
        const URL = `${webApi.domain + webApi.endPoint.deleteCourseReview}/${res['value']}`;
        await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
          if (data['success']) {
            this.listOfReviews.forEach((review: any, index: number) => {
              if (review['_id'] == res['value']) {
                this.listOfReviews.splice(index, 1);
              }
            });
            this.showSuccessToaster('Review Delete Successfully', 'Success');
          } else {
            this.listOfReviews.forEach((review: any, index: number) => {
              if (review['_id'] == res['value']) {
                review['isDeleting'] = false;
              }
            });
            this.showErrorToaster(
              'Error Occurred while deleteing Review. Try again',
              'Error'
            );
          }
        });
      }
    });
  }
  // async deleteReview(reviewId: String) {
  //   const param = {
  //     reviewId: reviewId,
  //   };

  //   this.listOfReviews = this.listOfReviews.filter(
  //     (review: any) => review['_id'] != reviewId
  //   );
  //   this.showSuccessToaster('Review Deleted Successfully', 'Success');
  //   const URL = `${webApi.domain + webApi.endPoint.deleteReview}`;
  //   await this.as.deleteReviewFromService(param, URL).then((data: any) => {
  //     if (data['success']) {
  //     }
  //   });
  // }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
