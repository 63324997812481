import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { tgAdvisoryApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-delete-message',
  templateUrl: './delete-message.component.html',
  styleUrls: ['./delete-message.component.scss']
})
export class DeleteMessageComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  userDetail: any;
  httpHeaders: any;
  constructor(
    public dialogRef: MatDialogRef<DeleteMessageComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  //Message
  async deleteMessage() {
    this.isLoading = true;
    try {

      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.deleteMessage}`;
      await this.as.fetchDetail({ messageId: this.data['message']['_id'], }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.dialogRef.close();
          this.closeActivityAtDialog.emit({
            deleted: true,
          });
          this.showSuccessToaster('Message Deleted successfully.', 'Success');
        } else {
          this.showWarningToaster('Unable to delete message. Try again', 'Warning');
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.showWarningToaster('Unable to delete message. Try again', 'Warning');
    }
  }

  //Community
  async deleteCommunity() {
    this.isLoading = true;
    try {

      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.deleteCommunity}`;
      await this.as.fetchDetail({ communityId: this.data['community']['_id'], }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.dialogRef.close();
          this.closeActivityAtDialog.emit({
            deleted: true,
          });
          this.showSuccessToaster('Community Deleted successfully.', 'Success');
        } else {
          this.showWarningToaster('Unable to delete community. Try again', 'Warning');
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.showWarningToaster('Unable to delete community. Try again', 'Warning');
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
