<div class="main-container">
    <div class="row align-center" style="justify-content: space-between;margin-bottom: 2rem;">

        <div class="col-md-4 top-heading" style="cursor: pointer;">
            <span style="font-size: 1.5rem;cursor: pointer;">
                Activities
            </span>
        </div>
        <div class="col-md-8">
            <div class="row align-center">
                <div class="col-md-6" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchBatch($event)">
                </div>
                <div class="col-md-2" style="text-align: center;">
                    <button class="add-new-emp" style="width: 11rem;" (click)="addNewBatch()">
                        Add {{isAssessment?'Assessment':(isNote?'Notes':'Polls')}}
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div
        style="background-color: #e9ecf6;border-radius: 5px;padding: 10px;margin: 1em 0px;width: 50%;display: flex;align-items: center;">
        <div class="active-section" [ngClass]="{'active-section':isAssessment,'inactive-section':!isAssessment }"
            (click)="changeHandler('Assessment')">
            Assessment
        </div>
        <div class="active-section" [ngClass]="{'active-section':isNote,'inactive-section':!isNote }"
            (click)="changeHandler('Notes')">
            Notes
        </div>
        <div class="active-section" [ngClass]="{'active-section':isPoll,'inactive-section':!isPoll }"
            (click)="changeHandler('Polls')">
            Polls
        </div>
    </div>
    <hr>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading && isAssessment">
        <div class="no-data" *ngIf="listOfAssessmentToDisplay.length==0">
            No Assessment found
        </div>
        <div class="course-row" *ngIf="listOfAssessmentToDisplay.length>0">
            <div class="course" *ngFor="let assessment of listOfAssessmentToDisplay">
                <!--  (click)="gotoCourseEdit(batch)" -->
                <div class="upper1">
                    <img src="../../../../../assets/img/assessment.png" class="course-image" alt="Offer image">

                </div>
                <div class="course-content">
                    <h1 class="name">{{assessment['assessment']['title']}}</h1>

                    <div class="publish-row">
                        <div class="created">Created date: {{assessment['createdAt'] | date}}</div>
                        <div>
                            <div
                                [ngClass]="{'status':assessment['isActive'],'inactive-status':!assessment['isActive']}">
                                {{assessment['isActive']? "Published":"Unpublished"}}</div>
                        </div>
                    </div>


                    <div style="border-top: dotted 1px black;margin: 10px 0px;"></div>

                    <div class="row" style="justify-content: space-around;">
                        <div class="icon-div" (click)="gotoBatchEdit(assessment)">
                            <img src="../../../../assets/img/batch-edit.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" style="top: 43px;" (click)="deleteActivity(assessment)">
                            <img src="../../../../assets/img/batch-delete.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="assessment.isActive" style="top: 82px;"
                            (click)="updateActivityStatus(assessment)">
                            <img src="../../../../assets/img/batch-publish.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="!assessment.isActive" style="top: 82px;"
                            (click)="updateActivityStatus(assessment)">
                            <img src="../../../../assets/img/batch-unpublish.png" style="height: 1em;" alt="" srcset="">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading && isNote">
        <div class="no-data" *ngIf="listOfNoteToDisplay.length==0">
            No Notes found
        </div>
        <div class="course-row" *ngIf="listOfNoteToDisplay.length>0">
            <div class="course" *ngFor="let notes of listOfNoteToDisplay">
                <!--  (click)="gotoCourseEdit(batch)" -->
                <div class="upper1">
                    <img src="../../../../../assets/img/notes.png" class="course-image" alt="Offer image">

                </div>
                <div class="course-content">
                    <h1 class="name">{{notes['note']['title']}}</h1>

                    <div class="publish-row">
                        <div class="created">Created date: {{notes['createdAt'] | date}}</div>
                        <div>
                            <div [ngClass]="{'status':notes['isActive'],'inactive-status':!notes['isActive']}">
                                {{notes['isActive']? "Published":"Unpublished"}}</div>
                        </div>
                    </div>


                    <div style="border-top: dotted 1px black;margin: 10px 0px;"></div>

                    <div class="row" style="justify-content: space-around;">
                        <div class="icon-div" (click)="gotoBatchEdit(notes)">
                            <img src="../../../../assets/img/batch-edit.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" style="top: 43px;" (click)="deleteActivity(notes)">
                            <img src="../../../../assets/img/batch-delete.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="notes.isActive" style="top: 82px;"
                            (click)="updateActivityStatus(notes)">
                            <img src="../../../../assets/img/batch-publish.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="!notes.isActive" style="top: 82px;"
                            (click)="updateActivityStatus(notes)">
                            <img src="../../../../assets/img/batch-unpublish.png" style="height: 1em;" alt="" srcset="">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading && isPoll">
        <div class="no-data" *ngIf="listOfPollToDisplay.length==0">
            No Poll found
        </div>
        <div style="margin-bottom: 4rem;padding-bottom: 5em;" *ngIf="listOfPollToDisplay.length>0">
            <div class="row" style="justify-content: space-between;align-items: center; ">
                <div class="col-md-6" style="font-size: 20px;font-weight: 500;">
                    Poll
                </div>
                <div class="col-md-6">

                </div>
            </div>

            <hr>
            <div *ngFor="let poll of listOfPollToDisplay;let i=index;" class="row"
                style="    border-bottom: solid 2px #c4c4c4;margin-bottom: 1em;">
                <div class="col-md-12" style="color: #6B6C75;font-size: 15px;">
                    Created At :{{poll['createdAt'] | date}}
                </div>
                <div class="col-md-6" style="font-size: 17px;font-weight: 500;">
                    Poll - {{i+1}}
                </div>
                <div class="col-md-6" style="display: flex;align-items: center;justify-content: end; ">
                    <div class="edit-delete-btn" (click)="gotoBatchEdit(poll)">
                        Edit
                    </div>
                    <div class="edit-delete-btn" (click)="deleteActivity(poll)">
                        Delete
                    </div>
                </div>
                <div class="col-md-12">
                    <span [innerHTML]="poll['poll']['question']"></span>
                </div>
                <div class="col-md-12" *ngFor="let option of poll['poll']['options']" style="background: #ddecf6;padding: 10px 20px;border-radius: 5px;
                    margin: 1em;">
                    <div class="row">
                        <div class="col-md-6">{{option['option']}}</div>
                        <div class="col-md-6" style="text-align-last: end;font-size: 14px;color: #84858E;">
                            {{option['votePercentage']}} % Students
                        </div>
                    </div>

                </div>
                <hr>
            </div>
        </div>
    </div>
</div>