import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-otp-verify-page',
  templateUrl: './otp-verify-page.component.html',
  styleUrls: ['./otp-verify-page.component.scss'],
})
export class OtpVerifyPageComponent implements OnInit {
  isConfirmClicked: boolean = false;
  otp: any;
  windowRef: any;
  userDetail: any;
  mobileNo: any;
  action: any;

  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'margin-top': '8px',
      'margin-right': '8px',
    },
  };
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if (this.userDetail != null) {
      this.router.navigate(['/catalog/user']);
    }
    this.routes.queryParams.subscribe((params) => {
      this.mobileNo = params['mobileNo'];
      this.action = params['action'];
    });
  }
  ngOnInit(): void {
    this.windowRef = this.as.windowRef;
  }
  onOtpChange(event: any) {
    this.otp = event;
  }
  async insertAdmin() {
    const param = {
      mobileNo: this.mobileNo,
      email: this.as.usersDetailForSignUp['email'],
      fullName: this.as.usersDetailForSignUp['fullName'],
    };
    const URL = `${webApi.domain + webApi.endPoint.insertAdmin}`;
    await this.as.fetchCourseFromService(param, URL).then((data: any) => {
      if (data['success']) {
        localStorage.setItem(
          'TGV2WebAdminDetails',
          JSON.stringify({
            userId: this.as.validUsersDetail['_id'],
            name: this.as.validUsersDetail['fullName'],
            mobileNo: this.as.validUsersDetail['mobileNo'],
            email: this.as.validUsersDetail['email'],
          })
        );
        this.showSuccessToaster('Signup done successfully.', 'Success');
        this.router.navigate(['/catalog/user']);
      } else {
        if (
          data['error']['error'] == 'User found with this email' ||
          data['error']['error'] == 'User found with this mobile number'
        ) {
          this.showWarningToaster(
            'User already exists with same mobile number or email.',
            'Alert'
          );
        } else {
        }
      }
    });
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  async confirmOtp() {
    this.isConfirmClicked = true;

    const param = {
      mobileNo: this.as.validUsersDetail['mobileNo'],
      otp: this.otp,
    };
    const URL = `${webApi.domain + webApi.endPoint.verifyOTPofUser}`;
    await this.as.postMethod(param, URL).then(async (data: any) => {
      if (data['result']['type'] == 'success') {
        if (this.as.validUsersDetail != null) {
          localStorage.setItem(
            'TGV2WebAdminDetails',
            JSON.stringify({
              userId: this.as.validUsersDetail['_id'],
              name: this.as.validUsersDetail['fullName'],
              mobileNo: this.as.validUsersDetail['mobileNo'],
              email: this.as.validUsersDetail['email'],
              accessControlList: this.as.validUsersDetail['accessControlList'],
              accessToken: this.as.validUsersDetail['accessToken'],
            })
          );

          this.isConfirmClicked = false;
          await this.as.sideBar();
          this.router.navigate([this.as.sidebarOptions[0]['route']]);
          this.as.sidebarOptions[0]['isSelected'] = true;
        } else {
          this.showErrorToaster('Incorret OTP', 'Alert');
          this.isConfirmClicked = false;
        }
      } else {
        this.isConfirmClicked = false;
        this.showErrorToaster('Incorret OTP', 'Alert');
      }
    });
  }
}
