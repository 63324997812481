import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-box-report-list',
  templateUrl: './box-report-list.component.html',
  styleUrls: ['./box-report-list.component.scss'],
})
export class BoxReportListComponent implements OnInit {
  isLoading: boolean = true;
  listOfCourse: any = [];
  listOfBox: any = [
    {
      label: 'Registered User',
      route: '/catalog/reports/registered-user',
      routeId: 'registered-user',
      img: '../../../assets/img/RegisteredUser.png',
    },
    {
      label: 'Last Active User',
      route: '/catalog/reports/last-active-user',
      routeId: 'last-active-user',
      img: '../../../assets/img/UserLastActive.png',
    },
    {
      label: 'CSR Report',
      route: '/catalog/reports/csr-report',
      routeId: 'csr-report',
      img: '../../../assets/img/collection.svg',
    },
    {
      label: 'Analytics Report',
      route: '/catalog/reports/analytics-report',
      routeId: 'analytics-report',
      img: '../../../assets/svgIcons/leaderboard.svg',
    },
  ];
  isOptionSelected: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.isOptionSelected = null;

    console.log('Box list Report');
  }
  userDetails: any;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.listOfBox = [
      {
        label: 'Registered User',
        route: '/catalog/reports/registered-user',
        routeId: 'registered-user',
        img: '../../../assets/img/RegisteredUser.png',
      },
      {
        label: 'Last Active User',
        route: '/catalog/reports/last-active-user',
        routeId: 'last-active-user',
        img: '../../../assets/img/UserLastActive.png',
      },
      {
        label: 'CSR Report',
        route: '/catalog/reports/csr-report',
        routeId: 'csr-report',
        img: '../../../assets/img/collection.svg',
      },
      {
        label: 'Analytics Report',
        route: '/catalog/reports/analytics-report',
        routeId: 'analytics-report',
        img: '../../../assets/svgIcons/leaderboard.svg',
      },
    ];
    this.isLoading = false;
    this.fetchAllCourse();
  }
  async fetchAllCourse() {
    this.isLoading = true;
    this.listOfCourse = [];
    let param = { courseId: '0' };
    try {
      const URL = `${webApi.domain + webApi.endPoint.fetchAllCourse}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.postMethodWithToken(param, URL,httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
          this.listOfCourse.forEach((val: any) => {
            val['nameToShow'] = val['courseType'] + '-' + val['courseName'];
          });
          console.log(this.listOfCourse);
          this.isLoading = false;
        } else {
          this.listOfCourse = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfCourse = [];
      this.isLoading = false;
    }
  }
  // async fetchAllVendors() {
  //   this.listOfVendor = [];
  //   try {
  //     const param = {};
  //     const httpHeaders = new HttpHeaders({
  //       Authorization: `Bearer ${this.userDetails['accessToken']}`,
  //     });
  //     const URL = `${webApi.domain + webApi.endPoint.fetchAllVendor}`;
  //     await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
  //       if (data['success']) {
  //         this.listOfVendor = data['result'];
  //         this.listOfVendor.forEach((val: any) => {
  //           val['nameToShow'] = val['firstName'] + '-' + val['lastName'];
  //         });
  //         console.log(this.listOfVendor);
  //         this.isLoading = false;
  //       } else {
  //         this.listOfVendor = [];
  //         this.isLoading = false;
  //       }
  //     });
  //   } catch (error) {
  //     this.listOfVendor = [];
  //     this.isLoading = false;
  //   }
  // }
  closeTab() {
    this.isOptionSelected = null;
    this.as.typeOfReport = null;
  }
  navigateToRoute(route: string) {
    let routeId;
    this.listOfBox.forEach((box: any) => {
      if (box['route'] == route) {
        this.isOptionSelected = box['label'];
        routeId = box['routeId'];
        this.as.typeOfReport = box['label'];
      }
    });
    // this.location.go(route);
    this.as.listOfCourseForReport=this.listOfCourse;
    this.router.navigate([route]);
  }
}
