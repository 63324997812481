import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-change-campaign-status',
  templateUrl: './change-campaign-status.component.html',
  styleUrls: ['./change-campaign-status.component.scss']
})
export class ChangeCampaignStatusComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  statusControl = new FormControl('Select Status', [
    Validators.required,

  ]);

  changeCampaignStatusForm: FormGroup = new FormGroup({
    status: this.statusControl,
  });
  constructor(public dialogRef: MatDialogRef<ChangeCampaignStatusComponent>,
    private toastr: ToastrService,
    public appService: AppService,
    protected _sanitizer: DomSanitizer,
    public routes: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.changeCampaignStatusForm = this.formBuilder.group({
      status: this.statusControl,
    });
    this.changeCampaignStatusForm.get('status')?.setValue(this.data['campaign']['status']);
  }
  getNewDate() {
    return new Date();
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  changeCampaignStatus() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Yes',
      value: this.changeCampaignStatusForm.get('status')!.value,
    });
  }

}
