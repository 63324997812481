<div *ngIf="data['source']=='Add Participant'">
    <div mat-dialog-title
        style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
        <div style="font-size: 18px;font-weight: 600;">
            Add Participant
        </div>
        <div>
            <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
        </div>
    </div>
    <mat-dialog-content style="max-width: inherit;margin: 0px;padding: 0em 1em  2em  1em !important;">
        <div class="container__filter" *ngIf="isLoading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin: 0 auto;">
            </mat-progress-spinner>
        </div>
        <div class="container__filter" *ngIf="!isLoading">
            <div class="br mb" style="margin-bottom: 1rem">
                <label class="label mb" for>Member Phone Number</label>
                <input class="input-style" type="number" minlength="10" maxlength="10" placeholder="Enter phone number"
                    [(ngModel)]="phone">
            </div>

        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
        <div style="display: flex; gap: 1rem">
            <button class="btn btn-1" type="button" (click)="close()">
                No
            </button>
            <button class="btn btn-2" type="button" (click)="addMember()">
                Yes, Add
            </button>
        </div>

    </mat-dialog-actions>

</div>
<div *ngIf="data['source']=='Remove Participant'">
    <div mat-dialog-title
        style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
        <div style="font-size: 18px;font-weight: 600;">
            Remove Participant
        </div>
        <div>
            <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
        </div>
    </div>
    <mat-dialog-content style="max-width: inherit;margin: 0px;padding: 0em 2em  0em  2em !important;">
        <div class="container__filter">
            Are you sure you want to remove {{ data['info']['user']['fullName']!=null &&
            data['info']['user']['fullName']!=undefined?(data['info']['user']['fullName']+' -\
            '+data['info']['user']['phone']):data['info']['user']['phone']}} ?
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
        <div style="display: flex; gap: 1rem">
            <button class="btn btn-1" type="button" (click)="close()">
                No
            </button>
            <button class="btn btn-2" type="button" (click)="deleteMember()">
                Yes, Delete
            </button>
        </div>

    </mat-dialog-actions>
</div>