<div mat-dialog-title
    style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
    <div style="font-size: 18px;font-weight: 600;">
        Post Comment
    </div>
    <div>
        <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
    </div>
</div>
<mat-dialog-content
    style="max-height: 45vh;min-height: 43vh;overflow-y: scroll;max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
    <div class="container__filter" *ngIf="!isLoading">



        <div class="mb" style="margin-bottom: 2px">
            <label class="label mb required" for>Select Module</label>
            <div class=" mb">
                <select name="module" class="input-style" id="module" (change)="moduleSelected($event)">
                    <option value="Select Module" selected disabled>Select Module</option>
                    <option *ngFor="let module of listOfModule" [value]="module['_id']">{{module['name']}}</option>
                </select>
            </div>
        </div>

        <div class="mb" style="margin-bottom: 2px" *ngIf="isActivityLoading">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
        <div class="mb" style="margin-bottom: 2px" *ngIf="!isActivityLoading">
            <label class="label mb required" for>Select Activity</label>
            <div class=" mb">
                <select name="activity" class="input-style" id="activity" [(ngModel)]="activity">
                    <option value="Select Activity" selected disabled>Select Activity</option>
                    <option *ngFor="let activity of listOfActivity" [value]="activity['_id']">{{activity['name']}}
                    </option>
                </select>
            </div>
        </div>
        <div class="mb" style="margin-bottom: 2px">
            <label class="label mb required" for>Enter comment</label>
            <div class=" mb">
                <input type="text" class="input-style" placeholder="Enter comment" [(ngModel)]="comment"
                    [ngModelOptions]="{standalone: true}">
            </div>
        </div>


        <div class="mb" style="margin-bottom: 2px">
            <label class="label required" for="">Upload Media</label>
            <div *ngIf="defaultImageUrl==null || defaultImageUrl==undefined || defaultImageUrl==''"
                style="height: 15.6rem" class="uploadBox">
                <div style="text-align: center;">
                    <label for="uploadImage" style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                        class="uploadImage">+ Add New File</label>
                    <input type="file" id="uploadImage" for="uploadImage" style="display: none" accept="image/*"
                        (change)="selectImageFile($event)" required />
                    <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                        Upload File
                    </div>
                    <img style="width: 3.3rem; margin-bottom: 2rem" src="../../../../../assets/img/upload-file.png"
                        alt="" srcset="" />
                </div>
            </div>
            <div *ngIf="defaultImageUrl!=null && defaultImageUrl!=undefined && defaultImageUrl!=''" class="uploadBox"
                style="position: relative; height: 15.6rem">
                <img (click)="removeImage()" class="removeImage" src="../../../../../assets/svgIcons/close.svg" alt=""
                    srcset="" />
                <img style="max-height: 13rem; max-width: 20rem;padding: 1rem;border-radius: 25px;"
                    [src]="defaultImageUrl" alt="" srcset="" />
                <div class="fileName">
                    {{selectedImageName}}
                </div>
            </div>
        </div>







    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-1" type="button" (click)="close()">
            Cancel
        </button>
        <button class="btn" [ngClass]="{'btn-2-disabled':activity==null,'btn-2-enabled':activity!=null}" type="button"
            (click)="activity==null ? null:onSubmit()">
            <div *ngIf="!isPostCommentLoading">
                Post
            </div>
            <div *ngIf="isPostCommentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </button>
    </div>

</mat-dialog-actions>