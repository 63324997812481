<div *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
</div>
<div *ngIf="!isLoading" style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon" style="font-size: 1rem;">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Subscription Detail</h2>
    <hr>
    <div class="row">
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Name: </span> {{subscription['user']['fullName']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Phone: </span> {{subscription['user']['phone']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Email: </span> {{subscription['user']['email']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Subscription Type: </span> {{subscription['type']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Razorpay Subscription ID: </span> {{subscription['rzpSubscriptionId']}}
        </div>

        <div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Plan: </span>
                {{subscription['plan']['name']+' ('+subscription['plan']['subType']+')'}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Start Date: </span>
                {{subscription['startDate']|date}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> End Date: </span>
                {{subscription['endDate']|date}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Total Cycle Paid Count: </span>
                {{subscription['totalBilligCycle']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Remaining Cycle Count: </span>
                {{subscription['remainingCycleCount']}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Total Billing Cycle Count: </span>
                {{subscription['totalCyclePaidCount']}}
            </div>
        </div>

    </div>