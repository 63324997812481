import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { UpdateUtmSourceDialogComponent } from '../../transaction-log/update-utm-source-dialog/update-utm-source-dialog.component';
import { ViewTransactionLogComponent } from '../../transaction-log/view-transaction-log/view-transaction-log.component';

@Component({
  selector: 'app-view-youtube-video-analytic',
  templateUrl: './view-youtube-video-analytic.component.html',
  styleUrls: ['./view-youtube-video-analytic.component.scss']
})
export class ViewYoutubeVideoAnalyticComponent implements OnInit {


  downloadReport: boolean = false;

  isLoading: boolean = true;
  isLoadingExtraData: boolean = false;

  dataToEdit: any;

  searchString: any = '';

  p: any = 1;
  count: any = 50;
  limit: any = 500;
  skip: any = 0;

  startDate: any;
  endDate: any;

  listOfVideoLog: any = [];
  listOfVideoLogToDisplay: any = [];
  userDetails: any;

  videoId: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    this.routes.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.videoId = allParam['params']['id'];
    });
  }
  ngOnInit(): void {
    this.fetchAllYoutubeVideoLog();
  }


  async fetchAllYoutubeVideoLog() {
    this.isLoading = true;
    this.listOfVideoLog = [];
    this.listOfVideoLogToDisplay = [];
    try {
      let URL = '';
      const param = {
        skip: this.skip,
        limit: this.limit,
        id: this.videoId,
        startDate: this.startDate == null || this.startDate == undefined ? undefined : new Date(this.startDate.setHours(0, 0, 0, 0)),
        endDate: this.endDate == null || this.endDate == undefined ? undefined : new Date(this.endDate.setHours(23, 59, 59, 59)),
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchAllYoutubeVideoLog}`;

      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfVideoLog = data['result'];
          this.listOfVideoLogToDisplay = this.listOfVideoLog;
          this.isLoading = false;
        } else {
          this.listOfVideoLog = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfVideoLog = [];
      this.isLoading = false;
    }
  }
  async fetchExtraTransactionLog(load: boolean,) {
    this.isLoadingExtraData = load;
    const param = {
      skip: this.skip,
      limit: this.limit,
      id: this.videoId,
      startDate: this.startDate == null || this.startDate == undefined ? undefined : new Date(this.startDate.setHours(0, 0, 0, 0)),
      endDate: this.endDate == null || this.endDate == undefined ? undefined : new Date(this.endDate.setHours(23, 59, 59, 59)),
    };
    const URL = `${webApi.domain + webApi.endPoint.fetchAllYoutubeVideoLog}`;
    await this.as
      .postMethod(param, URL,)
      .then((data: any) => {
        if (data['success']) {
          data['result'].forEach((user: any) => {
            this.listOfVideoLog.push(user);
          });
          this.isLoadingExtraData = false;
        } else {
          this.isLoadingExtraData = false;
        }
      });
  }

  clearDateRange() {
    if (this.endDate != null && this.startDate != null) {
      this.endDate = null;
      this.startDate = null;
      this.fetchAllYoutubeVideoLog();
      this.p = 1;
    }
  }

  setDateNull() {
    this.startDate = null;
    this.endDate = null;
    this.fetchAllYoutubeVideoLog();
  }

  async dateChanged(type: string, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }
    if (this.startDate != null && this.endDate != null) {
      this.fetchAllYoutubeVideoLog();
    }
  }

  pageChanged(event: any) {
    this.p = event;
    console.log(event);

    if (this.p * this.count >= this.listOfVideoLog.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraTransactionLog(false);
    } else if (this.p * this.count >= this.listOfVideoLog.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraTransactionLog(true);
    }


  }
  searchTransLog(event: any) {
    // console.log(event.target.value);
    this.searchString = event.target.value;
    let tempList: any = [];
    this.listOfVideoLogToDisplay = [...this.listOfVideoLog];
    if (this.searchString != '') {
      this.listOfVideoLogToDisplay.forEach((transa: any) => {
        if (
          String(transa['user'] == null || transa['user'] == undefined ? 'NA' : transa['user']['email'])
            .toLowerCase()
            .includes(String(this.searchString).toLowerCase()) ||
          String(transa['transactionMode'])
            .toLowerCase()
            .includes(String(this.searchString).toLowerCase()) ||
          String(transa['transactionStatus'])
            .toLowerCase()
            .includes(String(this.searchString).toLowerCase()) ||
          String(transa['transactionSource'])
            .toLowerCase()
            .includes(String(this.searchString).toLowerCase()) ||
          String(transa['paymentAmount'])
            .toLowerCase()
            .includes(String(this.searchString).toLowerCase())
        ) {
          tempList.push(transa);
        }
      });
      this.listOfVideoLogToDisplay = [...tempList];
    } else {
      this.listOfVideoLogToDisplay = [...this.listOfVideoLog];
    }
  }
  async generateLogReport() {

    this.downloadReport = true;
    try {
      let URL = '';
      const param = {
        id: this.videoId,
        startDate: this.startDate == null || this.startDate == undefined ? undefined : new Date(this.startDate.setHours(0, 0, 0, 0)),
        endDate: this.endDate == null || this.endDate == undefined ? undefined : new Date(this.endDate.setHours(23, 59, 59, 59)),
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.generateTransactionLogReport}`;

      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['type']) {
          window.open(data['result']);
          this.downloadReport = false;
        } else {
          this.downloadReport = false;
        }
      });
    } catch (error) {
      this.downloadReport = false;
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
