import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { MainCatalogComponent } from './main-catalog/main-catalog.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: '',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule',
      },
    ],
  },
  {
    path: 'catalog',
    component: MainCatalogComponent,
    children: [
      {
        path: '',
        loadChildren: './main-catalog/main-catalog.module#MainCatalogModule',
      },
    ],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },

  // {
  //   path: 'auth',
  //   component: AuthenticationComponent,
  // }
  { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
