import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ListWebhookSearchComponent } from './list-webhook-search/list-webhook-search.component';


const routes: Routes = [
  {
    path: '',
    component: ListWebhookSearchComponent,
  },
  // {
  //   path: '',
  //   component: UserComponent,
  // },
  { path: '', pathMatch: 'full', redirectTo: 'catalog/webhook' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebhookSearchRoutingModule { }
