<div mat-dialog-title
    style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
    <div style="font-size: 18px;font-weight: 600;">
        Report Reason
    </div>
    <div>
        <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
    </div>
</div>
<mat-dialog-content
    style="max-height: 45vh;min-height: 43vh;overflow-y: scroll;max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
    <div class="container__filter" *ngIf="!isLoading">


        <div *ngIf="listOfReportReason.length>0">

            <li class="mb" style="margin-bottom: 2px;" *ngFor="let dt of listOfReportReason">
                {{dt['reason']}} - ({{dt['user']['fullName']}})
            </li>
        </div>
        <div *ngIf="listOfReportReason.length==0">
            No Report reason available.
        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-1" type="button" (click)="close()">
            Close
        </button>
        <!-- <button class="btn btn-2" type="button" (click)="onSubmit()">
            Send
        </button> -->
    </div>

</mat-dialog-actions>