import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import { webApi } from 'src/app/api';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    mobileNo: new FormControl('', [Validators.required]),
  });
  signupForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    mobileNo: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
  });

  userDetail: any;
  isSignup: boolean = false;
  isValidUser: boolean = false;
  isContinueClicked: boolean = false;
  windowRef: any;

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.windowRef = this.as.windowRef;

    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if (this.userDetail == null) {
      this.router.navigate(['/auth/login']);
    } 
    // else {
    //   this.router.navigate(['/catalog/user']);
    // }
    this.loginForm = this.formBuilder.group({
      mobileNo: new FormControl('', [Validators.required]),
    });
  }
  switchToSignupLogin() {
    this.isSignup = !this.isSignup;
    if (this.isSignup) {
      this.signupForm = this.formBuilder.group({
        fullName: new FormControl('', [Validators.required]),
        mobileNo: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
      });
    } else {
      this.loginForm = this.formBuilder.group({
        mobileNo: new FormControl('', [Validators.required]),
      });
    }
  }
  async checkForValidUser() {
    this.isContinueClicked = true;
    if (this.loginForm.invalid) {
      this.isContinueClicked = false;
      return false;
    }
    const param = {
      mobileNo: this.loginForm.get('mobileNo')?.value,
    };
    const URL = `${webApi.domain + webApi.endPoint.isValidAdminUserExists}`;
    await this.as.fetchCourseFromService(param, URL).then((data: any) => {
      if (data != null) {
        this.isValidUser = true;
        this.as.validUsersDetail = data['data'];
        this.as.validUsersDetail['accessToken'] = data['accessToken'];
        this.gotoConfirmOtp();
      } else {
        this.isValidUser = false;
        this.isContinueClicked = false;

        this.showErrorToaster('Not a valid Admin', 'Invalid User');
      }
    });
    return;
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  async gotoConfirmOtp() {
    // if (this.isSignup) {
    // if (this.signupForm.invalid) {
    //   this.isContinueClicked = false;

    //   return false;
    // }
    this.as.usersDetailForSignUp = {
      fullName: this.signupForm.get('fullName')?.value,
      mobileNo: this.signupForm.get('mobileNo')?.value,
      email: this.signupForm.get('email')?.value,
    };
    const phoneNumberString = String(this.loginForm.get('mobileNo')!.value);
    const param = {
      mobileNo: phoneNumberString,
    };
    const URL = `${webApi.domain + webApi.endPoint.sendOTPtoUser}`;
    await this.as.postMethod(param, URL).then((data: any) => {
      if (data['result']['type'] == 'success') {
        this.router.navigate(['auth/verify-otp'], {
          queryParams: {
            mobileNo: this.loginForm.get('mobileNo')!.value,
            action: 'login',
          },
        });
        this.isContinueClicked = false;
      } else {
        this.showErrorToaster('Something went wrong', 'Alert');
        this.isContinueClicked = false;
      }
    });

    return;
  }
}
