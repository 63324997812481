import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { io } from 'socket.io-client';
import { webApi } from 'src/app/api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DropDownItem } from '../../1component/dropdown/dropdown.component';
import { SocketioService } from '../socketio.service';
import { MediaDialogComponent } from '../media-dialog/media-dialog.component';
import { AudioRecorderComponent } from '../audio-recorder/audio-recorder.component';
import { AddEditCommunityComponent } from '../add-edit-community/add-edit-community.component';
import { BroadcastMessageComponent } from '../broadcast-message/broadcast-message.component';
import { DeleteMessageComponent } from '../delete-message/delete-message.component';
import { ReportedMsgComponent } from '../reported-msg/reported-msg.component';
import { FilterCommunityComponent } from '../filter-community/filter-community.component';
import { PinUnpinDialogComponent } from '../pin-unpin-dialog/pin-unpin-dialog.component';
import { ViewCommunityParticipantComponent } from '../view-community-participant/view-community-participant.component';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-list-community',
  templateUrl: './list-community.component.html',
  styleUrls: ['./list-community.component.scss']
})
export class ListCommunityComponent implements OnInit {

  userDetails: any;

  isLoading: boolean = true;
  isChangeStatusLoading: boolean = false;
  isChangeTagLoading: boolean = false;
  isMessageSendLoading: boolean = false;
  isLoadingExtraData: boolean = false;
  sendPushNotification: boolean = false;

  message: string = '';
  filterObject: any;

  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImage: any;


  myHeaders = new Headers();
  formdata = new FormData();
  replyMessageDetail: any;
  constructor(
    private route: Router,
    private _ActivatedRoute: ActivatedRoute,
    public socketService: SocketioService,
    private toastr: ToastrService, private clipboard: Clipboard,
    private domSanitizer: DomSanitizer,
    private matDialog: MatDialog,
    public service: AppService
  ) {

    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.socketService.setupSocketConnection();
  }

  ngOnInit(): void {
    this.fetchListData();
  }

  fetchListData() {
    this.isLoading = true;
    try {
      this.socketService.listOfCommunity = [];
      let param = {
        searchedString: this.socketService.searchString,
        limit: this.socketService.limit,
        skip: this.socketService.skip, filterObject: this.filterObject,
      };
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchAllCommunity}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.service
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          console.log(data);
          if (data['success']) {
            this.socketService.listOfCommunity = data.result;
            this.socketService.sortCommunity();
            this.socketService.listOfCommunityToDisplay =
              this.socketService.listOfCommunity;
            // if (this.chatRoomId != null && this.chatRoomId != undefined) {
            //   let ctDetail: any;
            //   this.socketService.listOfCommunity.forEach(
            //     (element: any) => {
            //       if (element['_id'] == this.chatRoomId) {
            //         ctDetail = element;
            //       }
            //     }
            //   );
            //   if (ctDetail != null && ctDetail != undefined) {
            //     this.socketService.chatRoom = ctDetail;
            //     this.socketService.fetchAllMessageByChatRoomId();
            //   }
            // }
          }
          this.isLoading = false;
        });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }
  fetchExtraListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        searchedString: this.socketService.searchString,
        limit: this.socketService.limit,
        skip: this.socketService.skip, filterObject: this.filterObject,
      };
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchAllCommunity}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.service
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          console.log(data);
          if (data['success']) {
            data.result['chatRooms'].forEach((complaint: any) => {
              this.socketService.listOfCommunity.push(complaint);
            });
            this.socketService.sortCommunity();

            this.socketService.listOfCommunityToDisplay =
              this.socketService.listOfCommunity;
          }
          this.isLoadingExtraData = false;
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  pageChanged(event: any) {
    this.socketService.Page = event;
    console.log(event);

    if (this.socketService.Page * this.socketService.count >= this.socketService.listOfCommunity.length - this.socketService.count * 3) {
      this.socketService.skip = this.socketService.skip + this.socketService.limit;
      if (!this.isLoadingExtraData) this.fetchExtraListData(false);
    } else if (this.socketService.Page * this.socketService.count >= this.socketService.listOfCommunity.length) {
      this.socketService.skip = this.socketService.skip + this.socketService.limit;
      this.fetchExtraListData(true);
    }
  }
  async searchCommunityData() {
    this.isLoading = true;
    if (this.socketService.searchString == '') {
      this.socketService.limit = 250;
      this.socketService.skip = 0;
      this.socketService.Page = 1;
      this.fetchListData();
    } else {
      this.socketService.limit = 250;
      this.socketService.skip = 0;
      this.socketService.Page = 1;
      try {
        this.socketService.listOfCommunity = [];
        this.socketService.listOfCommunityToDisplay = this.socketService.listOfCommunity;
        let param = {
          searchedString: this.socketService.searchString,
          limit: this.socketService.limit,
          skip: this.socketService.skip, filterObject: this.filterObject,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.fetchAllCommunity}`;
        await this.service
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              data['result'].forEach((patient: any) => {
                this.socketService.listOfCommunity.push(patient);
              });
              this.socketService.sortCommunity();

              this.socketService.listOfCommunityToDisplay = [...this.socketService.listOfCommunity];
            }
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
  searchData(target: any) {
    let serachQuery = target.value;
    if (serachQuery == '') {
      this.socketService.limit = 250;
      this.socketService.skip = 0;
      this.socketService.Page = 1;
      this.socketService.searchString = '';
      this.fetchListData();
    }
  }
  selectCommunity(info: any) {
    this.socketService.selectedCommunity = info;
    this.socketService.fetchAllMessageByCommunityId();
    this.replyMessageDetail = null;
  }
  getDuration(createdAt: any) {
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate()
    );
    const messageDate = new Date(createdAt);
    const chatDate = new Date(
      messageDate.getFullYear(),
      messageDate.getMonth() + 1,
      messageDate.getDate()
    );

    let differenceInDays = this.differenceDays(today, chatDate);
    let differenceInMonths = this.differenceMonths(today, chatDate);
    let differenceInYear = this.differenceYears(today, chatDate);

    if (differenceInDays == 0 && differenceInMonths == 0 && differenceInYear == 0) {
      return 'TODAY';
    } else if (differenceInDays == 1 && differenceInMonths == 0 && differenceInYear == 0) {
      return 'YESTERDAY';
    } else {
      return `${chatDate.getDate() <= 9
        ? `0${chatDate.getDate()}`
        : `${chatDate.getDate()}`
        }/${chatDate.getMonth() <= 9
          ? `0${chatDate.getMonth() }`
          : `${chatDate.getMonth() }`
        }/${chatDate.getFullYear()}`;
    }
  }
  differenceDays(date1: any, date2: any) {
    var differenceInTime =
      new Date(date1).getDate() - new Date(date2).getDate();
    return Math.abs(differenceInTime);
  }
  differenceMonths(date1: any, date2: any) {
    var differenceInTime =
      new Date(date1).getMonth() - new Date(date2).getMonth();
    return Math.abs(differenceInTime);
  }
  differenceYears(date1: any, date2: any) {
    var differenceInTime =
      new Date(date1).getFullYear() - new Date(date2).getFullYear();
    return Math.abs(differenceInTime);
  }
  downloadImage(url: any) {
    window.open(url);
  }
  getFileExtension(url: string) {
    let urlChunk = url.split('.');
    return urlChunk[urlChunk.length - 1];
  }
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  downloadDoc(url: any) {
    window.open(url);
  }
  handleKeyDownEvent(e: any) {
    if (e.key == 'Enter' && !e.shiftKey) {
      // prevent default behavior
      e.preventDefault();
    }
  }
  pickMediaAndSend(event: any) {
    if (event.target.files.length > 0) {
      this.message = '';
      this.selectedImage = event.target.files[0];
      this.selectedImageName = this.selectedImage['name'];
      var reader = new FileReader();
      reader.onload = (event1: any) => {
        this.defaultImage = event1.target.result;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  }
  closeImage() {
    this.selectedImage = null;
    this.selectedImageName = null;
    this.defaultImage = null;
  }
  setReplyMsg(message: any) {
    this.replyMessageDetail = message;
  }
  unsetReplyMsg() {
    this.replyMessageDetail = null;
  }
  deleteMsg(message: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Delete Message', message: message };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(DeleteMessageComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['deleted']) {
            this.socketService.fetchAllMessageByCommunityId();
          }
        }
      }
    );
  }
  deleteCommunity(community: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Delete Community', community: community };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(DeleteMessageComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['deleted']) {
            this.socketService.limit = 250;
            this.socketService.skip = 0;
            this.socketService.Page = 1;
            this.fetchListData();
          }
        }
      }
    );
  }
  filterCommunity() {
    const dialog = new MatDialogConfig();
    dialog.data = { filterObject: this.filterObject };
    dialog.panelClass = 'custom-dialog-container';
    dialog.minWidth = '20vw';
    dialog.maxWidth = '35vw';
    dialog.height = 'fit-content';
    dialog.maxHeight = '70vh';
    let dialogRef = this.matDialog.open(FilterCommunityComponent, dialog);
    dialogRef.updatePosition(dialog.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          if (res['clear'] != null && res['clear'] != undefined) {
            this.filterObject = null;
            this.socketService.limit = 250;
            this.socketService.skip = 0;
            this.socketService.Page = 1;
            this.socketService.searchString = '';
            this.fetchListData();
          } else {
            this.filterObject = res['param'];
            this.socketService.limit = 250;
            this.socketService.skip = 0;
            this.socketService.Page = 1;
            this.socketService.searchString = '';
            this.fetchListData();
          }
        }
      }
    );

  }
  sendPushNotificationChange(event: any) {
    this.sendPushNotification = event.target.checked;
  }
  async sendMessage() {
    this.isMessageSendLoading = true;

    if (this.message == '') {
      return false;
    }
    let messageDetail;

    if (this.message != '') {

      messageDetail = {
        type: 'Text',
        text: this.message,
      };
    }
    let match = this.message.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    if (match != null && match != undefined && match.length > 0) {
      this.formdata.set('isLink', 'true');
    }
    this.formdata.set('sentBy', 'Admin');
    // this.formdata.set('userId', this.socketService.selectedCommunity['user']['_id']);
    this.formdata.set('community', this.socketService.selectedCommunity['_id']);
    this.formdata.set('content', JSON.stringify(messageDetail));
    if (this.replyMessageDetail != null && this.replyMessageDetail != undefined) {
      this.formdata.set('contextMessage', this.replyMessageDetail['_id']);
    }
    this.formdata.set('sendPushNotification', this.sendPushNotification.toString());

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.sendMessageFromAdminEnd}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isMessageSendLoading = false;
                this.formdata.delete('isLink');
                this.message = '';
                this.replyMessageDetail = null;
              } else {
                this.isMessageSendLoading = false;
                this.showWarningToaster('Message Not Send', 'Warning');
              }
            });
          }
        })
        .catch((error) => {
          this.isMessageSendLoading = false;
          console.log('error', error);
        });

      return;
    } catch (error) {
      this.isMessageSendLoading = false;
    }
    return;
  }
  URLReplacer(str: string) {
    let match = str.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    if (match != null && match != undefined) {
      let final = str;
      match!.map(url => {
        final = final.replace(url, "<a href=\"" + url + "\" target=\"_BLANK\">" + url + "</a>")
      })
      return final;
    } else {
      return str;
    }
  }
  gotoReportedMessage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(
      ReportedMsgComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {


      }
    });
  }
  viewCommunityPaticipant(info: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { communityInfo: info, id: info._id, source: 'View Participant', type: info.type, subType: info.subType };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(ViewCommunityParticipantComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
          }
        }
      }
    );
  }
  async getCommunityLink(community: any) {
    try {
      if (community.communityJoinLink == '' || community.communityJoinLink == null || community.communityJoinLink == undefined) {
        let URL = `${webApi.domain + webApi.endPoint.createDynamicLinksForCommunity}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        await this.service
          .postMethodWithToken({ communityId: community._id }, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.clipboard.copy(data['result']['shortLink']);
              this.socketService.selectedCommunity.communityJoinLink = data['result']['shortLink'];
              this.showSuccessToaster('Link Copied', 'Success');
            } else {
              this.showErrorToaster('Error occur while creating link', 'Error');
            }
            this.isLoading = false;
          });
      } else {
        this.clipboard.copy(community.communityJoinLink);
        this.showSuccessToaster('Link Copied', 'Success')
      }

    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }
  viewMessageSeenUser(id: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { id, source: 'Message Seen' };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(ViewCommunityParticipantComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
          }
        }
      }
    );
  }
  addEditCommunity(id: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { id };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(AddEditCommunityComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
            if ((this.socketService.selectedCommunity != null && this.socketService.selectedCommunity != undefined) && (this.socketService.selectedCommunity['_id'] == res['result']['_id'])) {
              this.socketService.selectedCommunity = res['result'];
            }
            this.socketService.limit = 250;
            this.socketService.skip = 0;
            this.socketService.Page = 1;
            this.socketService.searchString = '';
            this.fetchListData();
          }
        }
      }
    );
  }
  broadcastMessage() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(BroadcastMessageComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
            this.socketService.limit = 250;
            this.socketService.skip = 0;
            this.socketService.Page = 1;
            this.socketService.searchString = '';
            this.fetchListData();
          }
        }
      }
    );
  }
  openMessageDialog(event: any) {
    let param = {
      doc: event.target.files[0],
      community: this.socketService.selectedCommunity,
      contextMessage: this.replyMessageDetail != null && this.replyMessageDetail != undefined ? this.replyMessageDetail._id : undefined
    };
    console.log(param);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: param };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '50vw';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(MediaDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['success']) {
            this.replyMessageDetail = null;
          }
        }
      }
    );
  }
  pinCommunity(info: any, isPin: boolean) {
    let param = {
      community: info._id,
      isPin: isPin,
    };
    console.log(param);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: param };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '30vw';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(PinUnpinDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['success']) {

            this.fetchListData();
          }
        }
      }
    );
  }
  openAudioRecorderDialog() {
    let param = {
      community: this.socketService.selectedCommunity,
      contextMessage: this.replyMessageDetail != null && this.replyMessageDetail != undefined ? this.replyMessageDetail._id : undefined
    };
    console.log(param);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: param };
    console.log(dialogConfig.data);
    let dialogRef = this.matDialog.open(AudioRecorderComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
          }
        }
      }
    );
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title, {
      titleClass: 'titleClass',
      messageClass: 'messageClass',
    });
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title, {
      titleClass: 'titleClass',
      messageClass: 'messageClass',
    });
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title, {
      titleClass: 'titleClass',
      messageClass: 'messageClass',
    });
  }

}
