<div style="width: 45em;">
    <div class="row mx-1" style="justify-content: space-between;">
        <h2 mat-dialog-title style="font-weight: 500;">Enrolled Course Details:</h2>
        <mat-icon (click)="close()" style="cursor: pointer;">close</mat-icon>
    </div>
    <hr>
    <mat-dialog-content>
        <div *ngIf="data['enrolled'].length>0">
            <div *ngFor="let enrolled of data['enrolled']">
                <div class="col-md-12 data">
                    <p>
                        Course Name: {{enrolled['courseName']}} months
                    </p>
                </div>
                <div class="col-md-12 data">
                    <p>
                        Enrolled Date: {{enrolled['enrolledDate']|date}}
                    </p>
                </div>
                <div class="col-md-12 data">
                    <p>
                        End Date: {{enrolled['endDate']|date}}
                    </p>
                </div>
                <div class="col-md-12 data">
                    <p>
                        Complete Date: {{data['completeDate']==null?'N.A.':(data['completeDate']|date)}}
                    </p>
                </div>
                <hr>
            </div>

        </div>
        <div *ngIf="data['enrolled'].length==0">
            <p class="noSubscription">Enrollment not found.</p>
        </div>
    </mat-dialog-content>
</div>