<div class="main-container">
    <div *ngIf="!isLoading">
        <mat-horizontal-stepper linear #stepper>
            <mat-step (click)="stepClicked('Course')">
                <ng-template matStepLabel>Course Detail</ng-template>
                <app-add-course-detail [courseDetailToEdit]="courseDetail" (moveToActivity)="moveToModule($event)">
                </app-add-course-detail>
            </mat-step>
            <mat-step (click)="stepClicked('Module')">
                <ng-template matStepLabel>Module Detail</ng-template>
                <app-module [courseDtls]="courseDetail" (moveToActivity)="moveToActivity($event)" (moveToPrevious)="moveToPrevious()"></app-module>
            </mat-step>
            <mat-step (click)="stepClicked('Activity')">
                <ng-template matStepLabel>Activity Detail</ng-template>
                <app-activity *ngIf="showActivityDetails" (moveToPrevious)="moveToPrevious()"></app-activity>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>