import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from 'src/app/main-catalog/courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-curriculum-detail',
  templateUrl: './curriculum-detail.component.html',
  styleUrls: ['./curriculum-detail.component.scss']
})
export class CurriculumDetailComponent implements OnInit {
  batchDetail: any;
  @Input() batchId: any;

  @Output() moveToActivity: EventEmitter<any> = new EventEmitter();
  @Output() moveToPrevious: EventEmitter<any> = new EventEmitter();

  listOfModule: any = [];
  isLoading: boolean = true;
  callAddModule: boolean = false;
  // isChangingStatus: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  moduleToEdit: any;

  userDetails: any;
  httpHeaders: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  disable: boolean = true;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    // if (this.as.batchDetail != null) {
    //   this.batchDetail = this.as.batchDetail;
    // }
    if (this.batchId != null) {
      this.fetchBatchWithId();
    } else {
      this.isLoading = false;
    }
  }

  async fetchBatchWithId() {
    this.isLoading = true;
    try {
      let URL = `${webApi.domain + webApi.endPoint.fetchBatchWithId + `/${this.batchId}`}`;

      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.batchDetail = data['result'];
        }
        this.fetchBatchModulesByBatchId();
      });
    } catch (error) {
      this.fetchBatchModulesByBatchId();
    }
  }
  async fetchBatchModulesByBatchId() {
    this.listOfModule = [];
    const param = {
      course: this.batchDetail['_id'],
      admin: true,
    };
    const URL = `${webApi.domain + webApi.endPoint.fetchBatchModulesByBatchId}/${this.batchDetail['_id']}`;
    await this.as
      .fetchDetailViaGet(URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfModule = data['result'];
          this.listOfModule.forEach((mod: any) => {
            mod['isDeleting'] = false;
            mod['isChangingStatus'] = false;
          });
          this.listOfModule.sort((b: any, a: any) => b.position - a.position);
          this.isLoading = false;
        }
      });

  }
  backToCourse() {
    this.moveToPrevious.emit(null);
  }
  addNewModule() {
    this.callAddModule = true;
    this.moduleToEdit = null;
    this.toggleOpened(null);
  }
  viewActivity(module: any) {
    this.moveToActivity.emit(module);
  }
  editModule(detail: any) {
    this.callAddModule = true;
    this.moduleToEdit = detail;
    this.toggleOpened(null);
  }
  openAlertWhenContactNotConfirmed(module: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Batch Module Delete', batchModule: module };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        this.listOfModule.forEach((mod: any) => {
          mod['isDeleting'] = false;
        });
        return;
      } else {
        this.listOfModule.forEach((mod: any) => {
          if (mod['_id'] == module['_id']) {
            mod['isDeleting'] = true;
          }
        });
        const param = {
          moduleId: res['value']['_id'],
          action: 'Delete',
        };
        const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteBatchModule}`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfModule.forEach((module: any, index: number) => {
                if (module['_id'] == res['value']['_id']) {
                  this.listOfModule.splice(index, 1);
                }
              });
              this.showSuccessToaster('Module Delete Successfully', 'Success');
            } else {
              this.listOfModule.forEach((module: any, index: number) => {
                if (module['_id'] == res['value']['_id']) {
                  module['isDeleting'] = false;
                }
              });
              this.showErrorToaster(
                'Error Occurred while deleteing module. Try again',
                'Error'
              );
            }
          });
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  async changeModuleStatus(module: any) {
    this.listOfModule.forEach((mod: any) => {
      if (mod['_id'] == module['_id']) {
        mod['isChangingStatus'] = true;
      }
    });
    const param = {
      moduleId: module['_id'],
      isActive: module['isActive'] ? false : true,
      action: 'Active',
    };
    const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteBatchModule}`;
    await this.as
      .updateDataViaPut(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfModule.forEach((mod: any) => {
            if (mod['_id'] == module['_id']) {
              mod['isActive'] = module['isActive'] ? false : true;
            }
          });
          this.listOfModule.forEach((mod: any) => {
            if (mod['_id'] == module['_id']) {
              mod['isChangingStatus'] = false;
            }
          });
          this.showSuccessToaster(
            !module['isActive']
              ? 'Module Unpublished Successfully'
              : 'Module Published Successfully',
            'Success'
          );
        }
      });
  }

  deleteModule(module: any) {
    this.openAlertWhenContactNotConfirmed(module);
  }
  toggleOpened(event: any): void {
    // console.log(event);
    this.opened = !this.opened;
    if (event) {
    }
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.callAddModule = false;
    if (event != null) {
      let isExists = false;
      this.listOfModule.forEach((mod: any) => {
        if (mod['_id'] == event['_id']) {
          isExists = true;
          mod['title'] = event['title'];
          mod['meetingLink'] = event['meetingLink'];
          mod['position'] = event['position'];
          mod['duration'] = event['duration'];
          mod['startTime'] = event['startTime'];
          mod['endTime'] = event['endTime'];
          mod['publishDate'] = event['publishDate'];
        }
      });

      if (!isExists) {
        this.listOfModule.push(event);
      }
      this.listOfModule.sort((b: any, a: any) => b.position - a.position);
    }
  }

  toggleDock(): void {
    this.dock = !this.dock;
  }

  toggleCloseOnClickOutside(): void {
    this.closeOnClickOutside = !this.closeOnClickOutside;
  }

  toggleCloseOnClickBackdrop(): void {
    this.closeOnClickBackdrop = !this.closeOnClickBackdrop;
  }

  toggleShowBackdrop(): void {
    this.showBackdrop = !this.showBackdrop;
  }
  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

}
