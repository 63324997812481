import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainForumComponent } from './main-forum/main-forum.component';
import { QuestionDetailsComponent } from './question-details/question-details.component';

const routes: Routes = [
  {
    path: '',
    component: MainForumComponent,
  },
  {
    path: 'question-details',
    component: QuestionDetailsComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'catalog/forum' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ForumRoutingModule {}
