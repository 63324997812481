<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading">

        <form class="form-style" name="activityAddEditForm" [formGroup]="activityAddEditForm">
            <div class="top-heading" style="cursor: pointer;">
                <span style="font-size: 1.5rem;cursor: pointer;font-weight: 600;">
                    {{activityId!=null?'Edit':'Add'}} {{activityType}}
                </span>
            </div>
            <div class="row" *ngIf="this.activityType != 'Poll'">
                <div class="col-md-12">
                    <label for="title" class="label required"> Title</label>
                    <input type="text" placeholder="Enter a title" id="title" formControlName="title"
                        class="title-input" required>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="module" class="label required">Select Module</label>
                    <select name="module" id="module" class="title-input" formControlName="module" required>
                        <option value="Select Module" selected disabled>Select Module</option>
                        <option *ngFor="let module of listOfModule" [value]="module['_id']" class="levels">
                            {{module['title']}}</option>

                    </select>
                </div>
            </div>
            <div class="row" style="margin: 1em 0px;" *ngIf="activityType=='Poll'">

                <div class="col-md-12">
                    <div class="row question-details">
                        <div class="question">
                            <div class="questionText">
                                <label for="questionTitle" class="label1 required">Question:</label>
                                <!-- <quill-editor class="content-editor" placeholder="Enter a question" name="question"
                                    formControlName="question">
                                </quill-editor> -->
                                <input type="text" cl placeholder="Enter a question" formControlName="question"
                                    class="title-input required">
                            </div>
                            <div class="answerText">
                                <div class="answerTitle required">Options (Atleast 2 option):</div>
                                <div class="answer-row">

                                    <input type="text" placeholder="Add an option" formControlName="option1"
                                        class="answer" maxlength="150">
                                </div>
                                <div class="answer-row">

                                    <input type="text" placeholder="Add an option" formControlName="option2"
                                        class="answer" maxlength="150">
                                </div>
                                <div class="answer-row">

                                    <input type="text" placeholder="Add an option" formControlName="option3"
                                        class="answer" maxlength="150">
                                </div>
                                <div class="answer-row">

                                    <input type="text" placeholder="Add an option" formControlName="option4"
                                        class="answer" maxlength="150">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row" style="margin: 1em 0px;" *ngIf="activityType=='Assessment'">

                <div class="col-md-12">
                    <div class="row question-details" *ngIf="addMoreQuizes" id="addQuizSection">
                        <div class="question">
                            <div class="questionText">
                                <label for="questionTitle" class="label1 required">Question:</label>
                                <quill-editor class="content-editor" placeholder="Enter a question" name="question"
                                    formControlName="question">
                                </quill-editor>
                            </div>
                            <div class="answerText">
                                <div class="answerTitle required">Answers:</div>
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="0"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==0">
                                    <input type="text" placeholder="Add an answer" formControlName="answer1"
                                        class="answer" maxlength="150">
                                </div>
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="1"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==1">
                                    <input type="text" placeholder="Add an answer" formControlName="answer2"
                                        class="answer" maxlength="150">
                                </div>
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="2"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==2">
                                    <input type="text" placeholder="Add an answer" formControlName="answer3"
                                        class="answer" maxlength="150">
                                </div>
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="3"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==3">
                                    <input type="text" placeholder="Add an answer" formControlName="answer4"
                                        class="answer" maxlength="150">
                                </div>
                                <div style="display: flex;justify-content: flex-end;margin: 1em;">
                                    <button style="font-size: small;" class="cancel-btn"
                                        (click)="closeQuizAddEditSection()">
                                        {{'Close'}}
                                    </button>
                                    <button style="font-size: small;"
                                        [ngClass]="{'create-btn-disabled':activityAddEditForm.invalid,'create-btn':activityAddEditForm.valid}"
                                        [disabled]="activityAddEditForm.invalid" (click)="insertEditQuiz()">
                                        {{isEditingExistingQuiz?'Save':'Save'}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top:1em;" *ngIf="listOfQuiz.length>0">
                        <div *ngFor="let quiz of listOfQuiz; let i = index">
                            <div class="row quiz-div" *ngIf="quiz['timeStamp']!=editingExistingQuizTimeStamp">


                                <div class="col-md-10" style="align-self: center;font-size: 14px;font-weight: 500; word-break: break-all;max-height: 10em;
                                    overflow-y: auto; ">
                                    <span style="font-weight: 600;font-size: medium;"
                                        [innerHTML]="quiz['question']"></span>

                                </div>
                                <div class="col-md-2" style="display: flex;justify-content: space-around;">
                                    <div class="edit-delete-div" (click)="editQuiz(quiz)">
                                        <img src="../../../../../assets/img/editing.png" style="height: 1.5em;">
                                    </div>
                                    <div *ngIf="!quiz['isDeleting']" class="edit-delete-div" (click)="deleteQuiz(quiz)">
                                        <img src="../../../../../assets/img/delete.png" style="height: 1.5em;">
                                    </div>
                                    <div *ngIf="quiz['isDeleting']" class="delete-spinner">
                                        <mat-progress-spinner mode="indeterminate" diameter="20"
                                            style="margin:0 auto;height:20px;width:20px;color: white;">
                                        </mat-progress-spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!addMoreQuizes" class="addNewField" (click)="addMoreQuiz('addQuizSection')">
                        <img style="width: 1.6rem; margin-right: 1rem" src="../../../../../assets/svgIcons/addIcon.svg"
                            alt="" srcset="" />
                        Add new quiz
                    </div>
                </div>
            </div>
            <div class="row" style="margin: 1em 0px;" *ngIf="activityType=='Notes'">

                <div class="col-md-12">
                    <div>
                        <label class="label required" for="">Upload Notes</label>
                        <div *ngIf="defaultNoteUrl==null || defaultNoteUrl==undefined || defaultNoteUrl==''"
                            style="height: 15.6rem" class="uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadNote"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadNote">+ Add New File</label>
                                <input type="file" id="uploadNote" for="uploadNote" style="display: none"
                                    (change)="selectNoteFile($event)" required />
                                <!-- accept="image/*" -->
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>
                        </div>
                        <div *ngIf="defaultNoteUrl!=null && defaultNoteUrl!=undefined && defaultNoteUrl!=''"
                            class="uploadBox" style="position: relative; height: 15.6rem">
                            <img (click)="removeNote()" class="removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 20rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultNoteUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedNoteName}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>



            <div style="display: flex;justify-content:space-between;">
                <div *ngIf="!isAddindActivity && (activityType=='Assessment' )" class="create"
                    (click)="createEditActivity()">
                    <button
                        [ngClass]="{'create-btn-disabled':(activityAddEditForm.invalid || this.activityAddEditForm.get('module')?.value=='Select Module' || addMoreQuizes),'create-btn':(activityAddEditForm.valid && this.activityAddEditForm.get('module')?.value!='Select Module' && !addMoreQuizes)}"
                        type="submit"
                        [disabled]="(activityAddEditForm.invalid || this.activityAddEditForm.get('module')?.value=='Select Module' || addMoreQuizes)">{{'Save\
                        Info'}}</button>
                </div>
                <div *ngIf="!isAddindActivity && ( activityType=='Notes')" class="create"
                    (click)="createEditActivity()">
                    <button
                        [ngClass]="{'create-btn-disabled':(activityAddEditForm.invalid || this.activityAddEditForm.get('module')?.value=='Select Module'),'create-btn':(activityAddEditForm.valid && this.activityAddEditForm.get('module')?.value!='Select Module')}"
                        type="submit"
                        [disabled]="(activityAddEditForm.invalid || this.activityAddEditForm.get('module')?.value=='Select Module')">{{'Save\
                        Info'}}</button>
                </div>

                <div *ngIf="!isAddindActivity && activityType=='Poll'" class="create" (click)="createEditActivity()">
                    <button
                        [ngClass]="{'create-btn-disabled':(activityAddEditForm.invalid || this.activityAddEditForm.get('module')?.value=='Select Module'),'create-btn':(activityAddEditForm.valid && this.activityAddEditForm.get('module')?.value!='Select Module')}"
                        type="submit"
                        [disabled]="(activityAddEditForm.invalid || this.activityAddEditForm.get('module')?.value=='Select Module')">{{'Save\
                        Info'}}</button>
                </div>
                <div class="loading-comp" *ngIf="isAddindActivity">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>

            </div>
        </form>
    </div>

</div>