import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-add-edit-performance',
  templateUrl: './add-edit-performance.component.html',
  styleUrls: ['./add-edit-performance.component.scss']
})
export class AddEditPerformanceComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = true;
  isSendMessageLoading: boolean = false;

  userDetail: any;
  httpHeaders: any;

  // Image 
  selectedPDF: any = null;
  selectedPDFName: any = null;
  defaultImageUrl: any;
  selectedFileContentExtension: any = null;


  listOfCommunity: any = [];
  selectedCommunity: any = [];
  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  communityDetail: any;
  constructor(
    public dialogRef: MatDialogRef<AddEditPerformanceComponent>,
    public service: AppService, private formBuilder: FormBuilder, private toastr: ToastrService,
    public appService: AppService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });

    if (this.data['performance']['value']['link'] != '') {
      this.defaultImageUrl = '../../../../assets/img/image-pdf.png';
      this.selectedPDFName = this.data['performance']['value']['fileName'];
    }

  }
  ngOnInit(): void {

    this.fetchAllCommunity();
  }

  async fetchAllCommunity() {
    this.isLoading = true;
    try {
      this.listOfCommunity = [];

      const URL = `${webApi.domain + webApi.endPoint.fetchAllCommunity}`;
      await this.appService.fetchDetail({ courseId: '0', }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCommunity = data['result'];

        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  removePDF() {
    this.selectedPDF = null;
    this.selectedPDFName = null;
    this.defaultImageUrl = '';
  }
  selectPDFFile(event: any) {
    this.selectedPDF = event.target.files[0];
    this.selectedPDFName = this.selectedPDF['name'];
    this.selectedFileContentExtension = String(this.selectedPDF['name']).split('.')[String(this.selectedPDF['name']).split('.').length - 1];
    this.defaultImageUrl = '../../../../assets/img/image-pdf.png';
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultImageUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedPDF);

  }

  onSubmit() {
    this.isSendMessageLoading = true;

    if (this.selectedPDF != null) {
      this.formdata.set('link', this.selectedPDF);
    } else {
      this.formdata.set('link', this.data['performance']['value']['link']);
    }

    this.formdata.set('fileName', this.selectedPDFName);

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'PUT',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.updatePerformancePDF}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isSendMessageLoading = false;
                this.closeActivityAtDialog.emit({ result: true, data: res['result'] })
                this.dialogRef.close();
                this.showSuccessToaster('PDF Uploaded successfully.', 'Success');

              } else {
                this.isSendMessageLoading = false;
                this.showWarningToaster('Unable to upload PDF.', 'Warning');
              }
            });
          }
        })
        .catch((error) => {
          this.showWarningToaster('Unable to upload PDF.', 'Warning');
          this.isSendMessageLoading = false;
          console.log('error', error);
        });

      return;
    } catch (error) {
      this.showWarningToaster('Unable to upload PDF.', 'Warning');
      this.isSendMessageLoading = false;
    }
    return;
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }


}
