<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 90vh;" *ngIf="!isLoading">



        <div ng-sidebar-content style="overflow: hidden;padding: 2em;">
            <div class="row top-row-sb">
                <div class="back-1" style="display: flex;
                align-items: flex-end;">
                    <div class="back" (click)="backToForum()">
                        <mat-icon>arrow_back</mat-icon>
                    </div>
                    <div class="offer-heading-text">
                        Comments
                    </div>
                </div>

                <div class="search">
                    <div class="search-field">
                        <input type="text" class="search__input" style="width: 100%;" placeholder="Search"
                            (input)="searchComment($event)">

                    </div>
                    <!-- <button class="search__filter">
                        <img class="search__filter-logo" src="../../../assets/svgIcons/filters.svg" alt="Filter">
                    </button> -->

                </div>
            </div>


        </div>
        <form name="forumForm" [formGroup]="forumForm">
            <div class="row main-row">
                <div class="question">
                    <div class="top-main">
                        <div class="top">
                            <h1 class="user">{{question['userName'][0]}}</h1>
                            <div class="name-row">
                                <h3 class="fullname">{{question['userName']}}</h3>
                                <h5 class="date">{{question['creationDate']|date}}</h5>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="question['postImage']!=''" style="margin-left: 1rem;
                margin-bottom: 2rem;">
                        <img *ngIf="question['postImage']!=''" class="image" [src]="question['postImage']">
                    </div>

                    <h4 class="question-text">
                        {{question['question']}}
                    </h4>
                    <div style="margin-left: 1rem;">
                        <!-- <input type="text"
                        style="padding-left: 15px;padding-right: 15px; width: 40rem; margin-right: 1.3rem;"
                        placeholder="Write your comment" [(ngModel)]="comment"> -->
                        <quill-editor class="content-editor" placeholder="Write your comment" name="aboutUsText"
                            formControlName="comment"></quill-editor>
                        <!--  -->
                        <div class="send-btn" (click)="sendComment()">
                            <h5 style="padding-top: .2rem;">Send</h5>
                        </div>
                    </div>
                </div>
                <div *ngIf="listOfComments.length==0" class="no-comment">
                    <h5 class="no-comment-text">No Comments found</h5>
                </div>
                <div *ngIf="listOfComments.length>0" class="comments">
                    <div *ngFor="let comment of listOfComments">
                        <div class="top">
                            <h1 class="user-1">{{comment['userName'][0]}}</h1>
                            <div style="display: flex;" class="name-row-1">
                                <div>
                                    <div>
                                        <h4 *ngIf="comment['type']=='text'" class="comment-text"
                                            [innerHTML]="comment['comment']">
                                        </h4>
                                        <div *ngIf="comment['type']=='image'" style="margin-left: 1rem;
                                    margin-bottom: 2rem;">
                                            <img class="image" [src]="comment['comment']">
                                        </div>
                                        <div class="reply">
                                            <h5 class="date-1">{{comment['creationDate']|date}}</h5>
                                            <h5 class="date-1" style="cursor: pointer;"
                                                (click)="replyOnComment(comment)">
                                                Reply
                                            </h5>
                                            <h4 class="view" *ngIf="comment['reply'].length>0"
                                                (click)="viewReply(comment['_id'])">
                                                {{comment['viewReply']?'Hide Replies': 'View Replies'}}</h4>
                                            <h4 class="view" style="color:black; font-weight: 700;"
                                                *ngIf="comment['reportCount']!=0">
                                                Report Count: {{comment['reportCount']}}</h4>
                                        </div>
                                    </div>
                                    <div *ngIf="comment['_id']==replyId" style="margin-top:1.3rem;margin-left: 1rem;">
                                        <!-- <input type="text" style="padding-left: 15px;padding-right: 15px; width: 40rem; margin-right: 1.3rem; margin-left: -1rem;
                                        margin-top: 2rem; " placeholder="Reply here....." [(ngModel)]="replyText"> -->
                                        <quill-editor class="content-editor" placeholder="Reply here....."
                                            name="aboutUsText" formControlName="reply"></quill-editor>
                                        <div class="send-btn" (click)="sendReply()">
                                            <h5 style="padding-top: .2rem;">Send</h5>
                                        </div>
                                    </div>
                                </div>
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="deleteComment(comment)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Delete</span>
                                    </button>
                                    <button mat-menu-item (click)="suspendUserFromForum(comment)">
                                        <mat-icon>report</mat-icon>
                                        <span>Suspend User</span>
                                    </button>
                                </mat-menu>

                            </div>
                        </div>
                        <hr style="margin-right:1rem;">
                        <div class="div" *ngIf="comment['viewReply']">
                            <div *ngFor="let reply of comment['reply']">
                                <div class="reply-all">
                                    <div class="top-1">
                                        <h1 class="user-2">{{comment['userName'][0]}}</h1>
                                        <div class="name-row-2">
                                            <h4 *ngIf="reply['type']=='text'" class="reply-text"
                                                [innerHTML]="reply['replyText']">
                                            </h4>
                                            <div *ngIf="reply['type']=='image'" style="margin-left: 1rem;
                                        margin-bottom: 2rem;">
                                                <img class="image" [src]="reply['replyText']">
                                            </div>
                                            <div class="reply">
                                                <h5 class="date-2">{{comment['creationDate']|date}}</h5>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <!-- <hr style="margin-right:17rem;"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>