import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss'],
})
export class AddActivityComponent implements OnInit {
  isLoading: boolean = false;
  saveAndMore: boolean = false;
  addMoreQuizes: boolean = false;
  isEditingExistingQuiz: boolean = false;
  editingQuiz: any;
  listOfAddedQuizzes: any = [];
  dataTosendBackend: any;
  activityIdToUpdateWhenSaveAndAddMore: any;
  @Input() moduleDetail: any;
  @Input() activityCount: any;
  @Input() actionToPerform: any;
  @Input() detailToEdit: any;
  radioSelectedValue: any;

  listOfResources: any = [];
  defaultCoverImage = null;
  showAddNewResources: boolean = false;
  disable: boolean = true;
  selectedFile: any = null;
  selectedFileName: any = 'No File Selected';
  editResources: boolean = false;
  editLinks: boolean = false;
  isYoutube: boolean = false;
  listOfDescription: any = [];
  myHeaders = new Headers();
  formdata = new FormData();
  userDetails: any;
  httpHeaders: any;

  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();
  @Output() warningSidebar: EventEmitter<any> = new EventEmitter();
  activityNameFormControl = new FormControl('', [Validators.required]);
  mobileLinkFormControl = new FormControl('', [Validators.required]);
  webLinkFormControl = new FormControl('', [Validators.required]);
  linkFormControl = new FormControl();
  positionFormControl = new FormControl('', [Validators.required]);
  videoDurationFormControl = new FormControl('', [Validators.required]);
  convertedVideoFormControl = new FormControl('');
  addVideoForm: FormGroup = new FormGroup({
    activityName: this.activityNameFormControl,
    webLink: this.webLinkFormControl,
    mobileLink: this.mobileLinkFormControl,
    videoDuration: this.videoDurationFormControl,
    convertedVideo: this.convertedVideoFormControl,
    position: this.positionFormControl,
    link: this.linkFormControl,
  });

  questionFormControl = new FormControl('', [Validators.required]);
  option1FormControl = new FormControl('', [Validators.required]);
  option2FormControl = new FormControl('', [Validators.required]);
  option3FormControl = new FormControl('', [Validators.required]);
  option4FormControl = new FormControl('', [Validators.required]);
  correctAnswerFormControl = new FormControl('', [Validators.required]);
  addQuizForm: FormGroup = new FormGroup({
    activityName: this.activityNameFormControl,
    question: this.questionFormControl,
    option1: this.option1FormControl,
    option2: this.option2FormControl,
    option3: this.option3FormControl,
    option4: this.option4FormControl,
    // correctAnswerRadio:this.correctAnswerFormControl
  });
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    console.log(this.moduleDetail);

    if (this.actionToPerform == 'Video') {
      this.addVideoForm = this.formBuilder.group({
        webLink: this.webLinkFormControl,
        mobileLink: this.mobileLinkFormControl,
        activityName: this.activityNameFormControl,
        videoDuration: this.videoDurationFormControl,
        position: this.positionFormControl,
        convertedVideo: this.convertedVideoFormControl,
        link: this.linkFormControl,
      });
      if (this.detailToEdit != null) {
        this.addVideoForm
          .get('activityName')
          ?.setValue(this.detailToEdit['name']);
        this.addVideoForm
          .get('position')
          ?.setValue(this.detailToEdit['position']);
        this.addVideoForm
          .get('webLink')
          ?.setValue(this.detailToEdit['videoContent']['webLink']);
        this.addVideoForm
          .get('mobileLink')
          ?.setValue(this.detailToEdit['videoContent']['link']);
        this.addVideoForm
          .get('convertedVideo')
          ?.setValue(this.detailToEdit['videoContent']['path']);

        this.isYoutube = this.detailToEdit['videoContent']['useYoutubeLink'] ?? false;

        if (this.isYoutube) {
          let youtubeLink = this.detailToEdit['videoContent']['youtubeLink'] == null && this.detailToEdit['videoContent']['youtubeLink'] == undefined ? '' : this.detailToEdit['videoContent']['youtubeLink'];
          this.addVideoForm.addControl(`youtubeLink`, this.formBuilder.control('', [Validators.required]));
          this.addVideoForm.get('youtubeLink')?.setValue(youtubeLink);
        }

        this.addVideoForm
          .get('videoDuration')
          ?.setValue(this.detailToEdit['videoContent']['duration']);
        if (
          this.detailToEdit['resources'] != undefined ||
          this.detailToEdit['resources'] != null
        ) {
          this.listOfResources = this.detailToEdit['resources'];
          this.listOfResources.forEach((res: any) => {
            res['isDeleting'] = false;
            res['isEdit'] = false;
          });
        }
        if (
          this.detailToEdit['description'] != undefined ||
          this.detailToEdit['description'] != null
        ) {
          this.listOfDescription = [];
          this.detailToEdit['description'].forEach((desc: any) => {
            this.listOfDescription.push({ link: desc, isEdit: false });
          });
        }
      } else {
        this.listOfDescription = [];
        this.listOfResources = [];
        this.showAddNewResources = true;
      }
    } else if (this.actionToPerform == 'Quiz') {
      this.addQuizForm = this.formBuilder.group({
        activityName: this.activityNameFormControl,
        position: this.positionFormControl,
        question: this.questionFormControl,
        option1: this.option1FormControl,
        option2: this.option2FormControl,
        option3: this.option3FormControl,
        option4: this.option4FormControl,
      });
      if (this.detailToEdit != null) {
        this.addVideoForm
          .get('activityName')
          ?.setValue(this.detailToEdit['name']);
        this.addVideoForm
          .get('position')
          ?.setValue(this.detailToEdit['position']);
        this.listOfAddedQuizzes = this.detailToEdit['quizContent'];
        this.listOfAddedQuizzes.forEach((quiz: any) => {
          quiz['isDeleting'] = false;
        });
        this.listOfAddedQuizzes.sort(
          (b: any, a: any) =>
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime()
        );
      } else {
        this.addMoreQuizes = true;
      }
    }
  }
  getActivityNameErrorMessage() {
    return this.activityNameFormControl.hasError('required')
      ? 'Please enter activity name'
      : '';
  }
  getMobileLinkErrorMessage() {
    return this.mobileLinkFormControl.hasError('required')
      ? 'Please enter video link for mobile'
      : '';
  }

  getConvertedLinkErrorMessage() {
    return this.convertedVideoFormControl.hasError('required')
      ? 'Please enter converted video link'
      : '';
  }

  getWebLinkErrorMessage() {
    return this.webLinkFormControl.hasError('required')
      ? 'Please enter video link for web'
      : '';
  }
  getVideoDurationErrorMessage() {
    return this.videoDurationFormControl.hasError('required')
      ? 'Please enter video duration in minutes'
      : '';
  }
  getPositionErrorMessage() {
    return this.positionFormControl.hasError('required')
      ? 'Please enter activity position'
      : '';
  }
  // Add Video Activity Function

  async addVideoActivity() {
    if (!this.addVideoForm.get('convertedVideo')?.value.includes('assets') && !this.isYoutube) {
      return this.showErrorToaster('Upload proper converted link', 'Error');
    }

    this.isLoading = true;
    var param;
    var URL;

    if (this.detailToEdit != null) {
      this.formdata.set('activityId', this.detailToEdit['_id']);
    } else {
      this.formdata.set('activityId', '0');
    }
    let activityContent = {
      link: this.addVideoForm.get('mobileLink')?.value,
      webLink: this.addVideoForm.get('webLink')?.value,
      duration: this.addVideoForm.get('videoDuration')?.value,
      path: this.addVideoForm.get('convertedVideo')?.value,
      domain: "https://d2zkzydyjnq9zq.cloudfront.net/",
      useYoutubeLink: this.isYoutube,
      youtubeLink: !this.isYoutube ? '' : this.addVideoForm.get('youtubeLink')?.value,
    };


    this.formdata.set('name', this.addVideoForm.get('activityName')?.value);
    this.formdata.set('position', this.addVideoForm.get('position')?.value);
    this.formdata.set('videoContent', JSON.stringify(activityContent));
    this.formdata.set('module', this.moduleDetail['_id']);
    this.formdata.set('course', this.moduleDetail['course']);
    this.formdata.set('type', 'Video');

    if (this.selectedFile != null) {
      this.formdata.set('resources', this.selectedFile, this.selectedFileName);
    }
    if (this.addVideoForm.get('link')?.value != null) {
      this.formdata.set('description', this.addVideoForm.get('link')?.value);
    }

    URL = `${webApi.domain + webApi.endPoint.createAndUpdateActivity}`;

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    fetch(URL, requestOptions)
      .then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.myHeaders = new Headers();
              this.isLoading = false;
              if (res['result']['resources'] != null) {
                this.listOfResources = res['result']['resources'];
                this.listOfResources.forEach((res: any) => {
                  res['isDeleting'] = false;
                  res['isEdit'] = false;
                });
              }

              this.listOfDescription = [];
              res['result']['description'].forEach((desc: any) => {
                this.listOfDescription.push({ link: desc, isEdit: false });
              });
              this.showAddNewResources = false;
              this.defaultCoverImage = null;
              this.selectedFile = null;
              this.addVideoForm.get('link')?.setValue('');
              // this.addModuleForm.get('moduleName')?.setValue('');
              if (this.detailToEdit == null) {
                this.showSuccessToaster(
                  'Activity Created Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Activity Updated Successfully',
                  'Success'
                );
              }
              this.closeSideForm.emit(res['result']);
            } else {
              this.isLoading = false;
              this.showErrorToaster(
                'Some Error occurred while saving data, try again',
                'Error Occurred'
              );
              this.myHeaders = new Headers();
            }
          });
        }
      })
      .catch((error) => {
        this.isLoading = false;
        console.log('error', error);
        this.myHeaders = new Headers();
      });
  }
  // Add Quiz Activity Function

  addNewResources() {
    this.showAddNewResources = true;
  }

  async deleteResource(res: any) {
    const param = {
      resourceId: res['_id'],
      activityId: this.detailToEdit['_id'],
      resource: true,
    };
    const URL = `${webApi.domain + webApi.endPoint.deleteResourceOfActivity}`;
    await this.as
      .updateDataViaPut(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.listOfResources.forEach((list: any, index: any) => {
            if (list['_id'] == res['_id']) {
              this.listOfResources.splice(index, 1);
            }
          });
          this.showSuccessToaster('Deleted Successfully', 'Success');
        }
      });
  }
  async deleteDesc(desc: any) {
    const param = {
      activityId: this.detailToEdit['_id'],
      resource: false,
      desc: desc['link'],
    };
    const URL = `${webApi.domain + webApi.endPoint.deleteResourceOfActivity}`;
    await this.as
      .updateDataViaPut(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.listOfDescription.forEach((list: any, index: any) => {
            if (list['link'] == desc['link']) {
              this.listOfDescription.splice(index, 1);
            }
          });
          this.detailToEdit['description'] = this.listOfDescription;
          this.showSuccessToaster('Deleted Successfully', 'Success');
        }
      });
  }
  handlePickedFile(event: any) {
    this.selectedFile = event.target.files[0];
    this.selectedFileName = this.selectedFile['name'];
    // this.formdata.set('offerImage', this.selectedFile);
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCoverImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }
  editDescriptionLink(link: any) {
    this.listOfDescription.forEach((link: any) => {
      link['isEdit'] = false;
    });
    this.editLinks = true;
    link['isEdit'] = true;
    // this.addQuizForm.get('link')?.setValue(link['link']);
    this.addVideoForm.get('link')?.setValue(link['link']);
  }

  editResourceLink(res: any) {
    this.listOfResources.forEach((link: any) => {
      link['isEdit'] = false;
    });
    this.editResources = true;
    res['isEdit'] = true;
    this.selectedFile = res['resourceLink'];
    this.defaultCoverImage = res['resourceLink'];
  }
  cancelResourceEdit(res: any) {
    this.listOfResources.forEach((link: any) => {
      link['isEdit'] = false;
    });
    this.editResources = false;
    res['isEdit'] = false;
    this.selectedFile = null;
    this.defaultCoverImage = null;
    this.selectedFileName = null;
  }

  async saveResources(res: any, index: number) {
    res['isDeleting'] = true;
    if (this.selectedFile != null) {
      this.formdata.set('resource', this.selectedFile);
    } else {
      this.formdata.set('resource', JSON.stringify(res['resourceLink']));
    }

    this.formdata.set('activityId', this.detailToEdit['_id']);
    this.formdata.set('action', 'Resources');
    this.formdata.set('resourceId', res['_id']);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );

    var requestOptions: RequestInit = {
      method: 'PUT',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.editResourcesOfActivity}`;

    fetch(URL, requestOptions)
      .then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.editResources = false;
              this.listOfResources = [];
              this.listOfResources = res['result']['resources'];
              this.listOfResources.forEach((res: any) => {
                res['isDeleting'] = false;
                res['isEdit'] = false;
              });
              this.detailToEdit['resources'] = this.listOfResources;
              this.showSuccessToaster(
                'Resources Updated Successfully',
                'Success'
              );
            } else {
              this.showErrorToaster(
                'Some Error occurred while saving data, try again',
                'Error Occurred'
              );
              res['isDeleting'] = false;
            }
          });
        }
      })
      .catch((error) => {
        this.isLoading = false;
        console.log('error', error);
      });
  }

  cancelEdit(desc: any) {
    this.listOfDescription.forEach((link: any) => {
      link['isEdit'] = false;
    });
    this.editLinks = false;
    desc['isEdit'] = false;
    this.addVideoForm.get('link')?.setValue('');
  }
  async saveLink(desc: any, index: number) {
    var array: any = [];
    this.listOfDescription.forEach((desc: any) => {
      array.push(desc['link']);
    });
    array.splice(index, 1);
    array.splice(index, 0, this.addVideoForm.get('link')?.value);
    const param = {
      activityId: this.detailToEdit['_id'],
      action: 'Link',
      description: array,
    };
    const URL = `${webApi.domain + webApi.endPoint.editResourcesOfActivity}`;
    await this.as
      .updateDataViaPut(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfDescription = [];
          data['result']['description'].forEach((desc: any) => {
            this.listOfDescription.push({ link: desc, isEdit: false });
          });
          this.detailToEdit['description'] = [];
          this.detailToEdit['description'] = this.listOfDescription;
          this.addVideoForm.get('link')?.setValue('');
          this.editLinks = false;
          this.showSuccessToaster('Link Updated Successfully', 'Success');
        } else {
          this.showErrorToaster(
            'Some Error occurred while saving data, try again',
            'Error Occurred'
          );
        }
      });
  }

  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    // console.log(event);
  }
  addMoreQuiz() {
    this.addMoreQuizes = true;
  }
  async deleteQuiz(quiz: any) {
    this.listOfAddedQuizzes.forEach((qz: any) => {
      if (qz['localActivityQuizId'] == quiz['localActivityQuizId']) {
        qz['isDeleting'] = true;
      }
    });
    const param = {
      activityId: this.detailToEdit['_id'],
      questionId: quiz['_id'],
    };
    const URL = `${webApi.domain + webApi.endPoint.deleteQuestionFromQuiz}`;
    await this.as
      .updateDataViaPut(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          var index = -1;
          this.listOfAddedQuizzes.forEach((qz: any, i: number) => {
            if (qz['localActivityQuizId'] == quiz['localActivityQuizId']) {
              qz['isDeleting'] = false;
              index = i;
            }
          });
          if (index != -1) {
            this.listOfAddedQuizzes.splice(index, 1);
          }

          this.showSuccessToaster('Question Deleted Successfully', 'Success');
          this.closeSideForm.emit(data['data']);
        } else {
          this.listOfAddedQuizzes.forEach((qz: any, i: number) => {
            if (qz['localActivityQuizId'] == quiz['localActivityQuizId']) {
              qz['isDeleting'] = false;
            }
          });
          this.showErrorToaster('Something went wrong', 'Failure');
        }
      });
  }
  editQuiz(quiz: any) {
    if (this.editingQuiz != null) {
      this.listOfAddedQuizzes.push(this.editingQuiz);
      this.editingQuiz = null;
    }
    this.addMoreQuizes = true;
    this.editingQuiz = quiz;
    this.listOfAddedQuizzes.forEach((qz: any, index: number) => {
      if (qz['question'] == quiz['question']) {
        this.listOfAddedQuizzes.splice(index, 1);
      }
    });
    this.listOfAddedQuizzes.sort(
      (b: any, a: any) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    this.isEditingExistingQuiz = true;
    this.addQuizForm.get('question')?.setValue(quiz['question']);
    this.addQuizForm.get('option1')?.setValue(quiz['options'][0]['value']);
    this.addQuizForm.get('option2')?.setValue(quiz['options'][1]['value']);
    this.addQuizForm.get('option3')?.setValue(quiz['options'][2]['value']);
    this.addQuizForm.get('option4')?.setValue(quiz['options'][3]['value']);
    quiz['options'].forEach((value: any, index: number) => {
      if (value['isCorrect']) {
        this.radioSelectedValue = index;
      }
    });
  }
  closeQuizAddEditSection() {
    if (this.editingQuiz != null) {
      this.listOfAddedQuizzes.push(this.editingQuiz);
      this.listOfAddedQuizzes.sort(
        (b: any, a: any) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );

      this.editingQuiz = null;
    }
    this.listOfAddedQuizzes.sort(
      (b: any, a: any) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    this.addMoreQuizes = false;
    this.isEditingExistingQuiz = false;
    this.addQuizForm.get('question')?.setValue('');
    this.addQuizForm.get('option1')?.setValue('');
    this.addQuizForm.get('option2')?.setValue('');
    this.addQuizForm.get('option3')?.setValue('');
    this.addQuizForm.get('option4')?.setValue('');
    this.radioSelectedValue = null;
  }
  getLocalQuizUniqueId() {
    if (this.listOfAddedQuizzes.length > 0) {
      var lstOfQuiz = this.listOfAddedQuizzes.sort(
        (b: any, a: any) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
      return lstOfQuiz[lstOfQuiz.length - 1]['localActivityQuizId'] + 1;
    } else {
      return 1;
    }
  }

  async addQuizActivity() {
    this.isLoading = true;
    var param;
    var URL;
    if (this.detailToEdit != null || this.saveAndMore) {
      var content = {
        question: this.addQuizForm.get('question')?.value,
        score: 1,
        options: [
          {
            value: this.addQuizForm.get('option1')?.value,
            isCorrect: this.radioSelectedValue == 0 ? true : false,
          },
          {
            value: this.addQuizForm.get('option2')?.value,
            isCorrect: this.radioSelectedValue == 1 ? true : false,
          },
          {
            value: this.addQuizForm.get('option3')?.value,
            isCorrect: this.radioSelectedValue == 2 ? true : false,
          },
          {
            value: this.addQuizForm.get('option4')?.value,
            isCorrect: this.radioSelectedValue == 3 ? true : false,
          },
        ],
        localActivityQuizId: this.isEditingExistingQuiz
          ? this.editingQuiz['_id']
          : this.getLocalQuizUniqueId(),
        createdDate: new Date(),
      };
      param = {
        activityId:
          this.detailToEdit == null
            ? this.activityIdToUpdateWhenSaveAndAddMore
            : this.detailToEdit['_id'],
        isEditingExistingQuiz: this.isEditingExistingQuiz,
        name: this.addQuizForm.get('activityName')?.value,
        quizContent: content,
        type: 'Quiz',
        position: this.addQuizForm.get('position')?.value,
      };
      URL = `${webApi.domain + webApi.endPoint.updateOrInsertQuizQuestion}`;
    } else {
      var questions = [
        {
          question: this.addQuizForm.get('question')?.value,
          score: 1,
          options: [
            {
              value: this.addQuizForm.get('option1')?.value,
              isCorrect: this.radioSelectedValue == 0 ? true : false,
            },
            {
              value: this.addQuizForm.get('option2')?.value,
              isCorrect: this.radioSelectedValue == 1 ? true : false,
            },
            {
              value: this.addQuizForm.get('option3')?.value,
              isCorrect: this.radioSelectedValue == 2 ? true : false,
            },
            {
              value: this.addQuizForm.get('option4')?.value,
              isCorrect: this.radioSelectedValue == 3 ? true : false,
            },
          ],
          localActivityQuizId: this.isEditingExistingQuiz
            ? this.editingQuiz['localActivityQuizId']
            : this.getLocalQuizUniqueId(),
        },
      ];

      param = {
        name: this.addQuizForm.get('activityName')?.value,
        course: this.moduleDetail['course'],
        module: this.moduleDetail['_id'],
        type: 'Quiz',
        position: this.addQuizForm.get('position')?.value,
        quizContent: questions,
        activityId: '0',
      };
      URL = `${webApi.domain + webApi.endPoint.createAndUpdateActivity}`;
    }
    await this.as.addData(param, URL, this.httpHeaders).then((data: any) => {
      if (data['success']) {
        this.addQuizForm.get('question')?.setValue('');
        this.addQuizForm.get('option1')?.setValue('');
        this.addQuizForm.get('option2')?.setValue('');
        this.addQuizForm.get('option3')?.setValue('');
        this.addQuizForm.get('option4')?.setValue('');
        this.radioSelectedValue = null;
        this.editingQuiz = null;
        this.isLoading = false;
        this.isEditingExistingQuiz = false;
        this.saveAndMore = true;
        this.dataTosendBackend = data['result'];
        this.activityIdToUpdateWhenSaveAndAddMore = data['result']['_id'];
        this.addMoreQuizes = false;
        this.listOfAddedQuizzes = data['result']['quizContent'];
        // this.listOfAddedQuizzes.sort(
        //   (b: any, a: any) =>
        //     new Date(b.createdDate).getTime() -
        //     new Date(a.createdDate).getTime()
        // );

        if (this.detailToEdit == null) {
          this.showSuccessToaster('Activity Created Successfully', 'Success');
        } else {
          this.showSuccessToaster('Activity Updated Successfully', 'Success');
        }
        // this.closeSideForm.emit(data['data']);
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  close(source: any) {
    this.closeQuizAddEditSection();
    this.isEditingExistingQuiz = false;
    this.addMoreQuizes = false;
    if (this.actionToPerform == 'Quiz') {
      this.saveAndMore = false;
      this.closeSideForm.emit(this.dataTosendBackend);
    } else {
      this.saveAndMore = false;
      this.closeSideForm.emit(null);
    }
  }

  setYoutubeVideo(event: any) {
    this.isYoutube = event.target.checked;
    if (this.isYoutube) {
      // this.addVideoForm.addControl(`youtubeLink`, this.formBuilder.control('', [Validators.required]));
      let youtubeLink = this.detailToEdit['videoContent']['youtubeLink'] == null && this.detailToEdit['videoContent']['youtubeLink'] == undefined ? '' : this.detailToEdit['videoContent']['youtubeLink'];
      this.addVideoForm.addControl(`youtubeLink`, this.formBuilder.control('', [Validators.required]));
      this.addVideoForm.get('youtubeLink')?.setValue(youtubeLink);
    } else {
      this.addVideoForm.removeControl('youtubeLink');
    }
  }
}
