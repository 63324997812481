import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListUtmComponent } from './list-utm/list-utm.component';

const routes: Routes = [
  {
    path: '',
    component: ListUtmComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/utm' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtmRoutingModule { }
