import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
@Component({
  selector: 'app-main-catalog',
  templateUrl: './main-catalog.component.html',
  styleUrls: ['./main-catalog.component.scss'],
})
export class MainCatalogComponent implements OnInit {
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router
  ) {}
  userDetail: any;
  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if(this.userDetail==null){
      this.router.navigate(['/auth/login']);

    }
  }

  logout() {
    localStorage.removeItem('TGV2WebAdminDetails');
    this.router.navigate(['/auth/login']);
  }
}
