import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-delete-module-activity-dialog',
  templateUrl: './delete-module-activity-dialog.component.html',
  styleUrls: ['./delete-module-activity-dialog.component.scss'],
})
export class DeleteModuleActivityDialogComponent implements OnInit {
  userDetail: any;
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<DeleteModuleActivityDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log(data);
    dialogRef.disableClose = true;

    // this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
  }
  ngOnInit(): void { }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  deleteModule() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Module',
      value: this.data['module'],
    });
  }
  deleteActivity() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Activity',
      value: this.data['activity'],
    });
  }
  deleteQuiz() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Quiz Activity',
      value: this.data['quiz'],
    });
  }
  deleteCourse() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Course',
      value: this.data['course'],
    });
  }
  publishCourse() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Course Status',
      value: this.data['course'],
    });
  }
  deleteQuizModule() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Quiz Module',
      value: this.data['quiz'],
    });
  }
  deleteOffer() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Offer',
      value: this.data['offer'],
    });
  }
  deleteReview() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Review',
      value: this.data['review'],
    });
  }
  deleteQuestion() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Question',
      value: this.data['question'],
    });
  }
  reportQuestion() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Report Question',
      value: this.data['question'],
    });
  }
  suspendQuestion() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Suspend Question',
      value: this.data['question'],
    });
  }

  suspendUser() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Suspend User',
      value: this.data['question'],
    });
  }
  deleteComment() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Comment',
      value: this.data['comment'] ?? null,
    });
  }
  suspendUserFromComment() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Suspend User From Comment',
      value: this.data['comment'],
    });
  }
  payCommission() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Pay Commission',
      value: this.data['user'],
    });
  }
  unEnrollUser() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Uneroll',
      value: this.data['user'],
    });
  }
  deleteUser() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete User',
      value: this.data['user'],
    });
  }

  activeEnrolment() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'activeEnrolment',
      value: this.data['enrolment'],
    });
  }

  activeBatchModule() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'activeBatchMadule',
      value: this.data['batchModule'],
    });
  }

  deleteBatchModule() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'deleteBatchMadule',
      value: this.data['batchModule'],
    });
  }

  activeBatch() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'activeBatch',
      value: this.data['batch'],
    });
  }

  deleteBatch() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'deleteBatch',
      value: this.data['batch'],
    });
  }
  deleteAssessment() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'deleteAssessment',
      value: this.data['assessment'],
    });
  }
  deleteNotes() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'deleteNotes',
      value: this.data['notes'],
    });
  }
  deletePoll() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'deletePoll',
      value: this.data['poll'],
    });
  }
  activeAssessment() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'activeAssessment',
      value: this.data['assessment'],
    });
  }
  activeNotes() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'activeNotes',
      value: this.data['notes'],
    });
  }
  activePoll() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'activePoll',
      value: this.data['poll'],
    });
  }
  exitActivity() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({ action: 'Activity Warning' });
  }
}
