import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { AddEditPerformanceComponent } from '../add-edit-performance/add-edit-performance.component';
import { webApi } from 'src/app/api';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-list-compliance',
  templateUrl: './list-compliance.component.html',
  styleUrls: ['./list-compliance.component.scss'],
})
export class ListComplianceComponent implements OnInit {
  userDetails: any;
  isLoading: boolean = true;
  performanceData: any;

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public router: Router,
    public activateRoute: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.fetchPerformanceDetails();
    // if (this.userDetails['role'] != 'Admin') {
    // this.router.navigate([this.appService.sidebarOptions[0]['route']]);
    // }
  }

  ngOnInit(): void {
    this.isLoading = false;
  }
  moveToPrivacyPolicy() {
    this.router.navigate([`/catalog/privacy-policy`]);
  }
  moveToTermsAndCondition() {
    this.router.navigate([`/catalog/terms-and-condition`]);
  }

  fetchPerformanceDetails() {
    this.isLoading = true;
    try {
      let param = { type: 'PerformancePDF' };
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchAppConfig}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          console.log(data);
          if (data['success'] && data['result'] != null) {
            this.performanceData = data['result'];
          }
          this.isLoading = false;
        });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  addEditPerformance() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { 'performance': this.performanceData };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(AddEditPerformanceComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
            this.performanceData = res['data'];
          }
        }
      }
    );
  }
}
