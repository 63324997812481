<div class="main-container">
    <div class="row top-row-sb">
        <div class="quiz-heading-text">
            Quizzes
        </div>
        <div class="search">
            <div class="search-field">
                <input type="text" class="search__input" style="width: 100%;" placeholder="Search" (input)="searchQuiz($event)">
                
            </div>
            <!-- <input type="text" class="search__input" placeholder="&#61442; Search" (input)="searchQuiz($event)"> -->
            <!-- <button class="search__filter">
                <img class="search__filter-logo" src="../../../assets/svgIcons/filters.svg" alt="Filter">
            </button> -->
            <div class="add-quiz-text" (click)="addNewQuiz(null)">
                <mat-icon class="add-quiz-icon">add</mat-icon> Add new quiz
            </div>
        </div>
    </div>

    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div class="no-quiz" *ngIf="listOfQuiz.length==0">
        No Quiz found
    </div>
    <div class="quiz-row" *ngIf="listOfQuiz.length>0">
        <div class="quiz" *ngFor="let quiz of listOfQuiz">
            <div class="upper">
                <div class="upper1">
                    <img [src]="quiz['image']==undefined || quiz['image']=='' || quiz['image']==null ? '../../../assets/img/placeholder-course.jpg':quiz['image']" class="quiz-image" alt="Quiz image">
                    <button class="edit" (click)="addNewQuiz(quiz)"><img src="../../../assets/svgIcons/edit-icon.svg"
                            class="edit-icon"></button>
                    <button *ngIf="!quiz['isChangingStatus']" class="publish" (click)="updateQuizStatus(quiz)"><img
                            [src]="quiz['isActive']? '../../../../assets/svgIcons/visibility.svg':'../../../../assets/svgIcons/hide.svg'"
                            class="publish-icon"></button>
                    <div *ngIf="quiz['isChangingStatus']" class="publish">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;height:20px;width:20px;color: white;">
                        </mat-progress-spinner>
                    </div>
                    <button *ngIf="!quiz['isDeleting']" class="delete" (click)="deleteQuiz(quiz)"><img
                        [src]=" '../../../../assets/svgIcons/delete.svg'" class="delete-icon"></button>
                    <div *ngIf="quiz['isDeleting']" class="delete">
                        <mat-spinner mode="indeterminate" diameter="20" class="mat-white" style="margin:0 auto;height:20px;width:20px;">
                        </mat-spinner>
                    </div>
                </div>
            </div>
            <div class="quiz-content">
                <h1 class="name">{{quiz['name']}}</h1>
                <p class="length">Total: {{quiz['quizContent'].length}} Quiz</p>
                <p class="length">Position: {{quiz['position']}}</p>
                <p class="validity">Created date:{{quiz['createdAt'] | date}}</p>
                <p [ngClass]="{'status':quiz['isActive'],'inactive-status':!quiz['isActive']}">{{quiz['isActive']?'Published':'Unpublished'}}</p>
            </div>
        </div>
    </div>
</div>