import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { Routes, RouterModule } from '@angular/router';
import { ListAnalyticUserComponent } from './list-analytic-user/list-analytic-user.component';



const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'user-list',
    component: ListAnalyticUserComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/dashboard' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
