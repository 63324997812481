<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <!-- <p></p> -->
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;    width: 100%;">
                <div class="col-md-3 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;">
                        Leaderboard
                    </span>
                </div>
                <div class="col-md-6" style="justify-content: end;display: flex;    align-items: baseline;">
                    <div class="search-field" style="    width: 80%;margin-right: 1em;">
                        <input type="text" class="search__input" placeholder="Search" [(ngModel)]="searchString"
                            (keyup.enter)="searchLeaderboardData()" (input)="searchData($event.target)">
                    </div>
                    <!-- </div>
                <div class="col-md-4"
                    style="text-align: right;display: flex;align-items: center;font-size: 1em;    justify-content: end;"> -->

                    <mat-form-field appearance="outline">
                        <mat-label>Pick a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" [value]="startDate"
                                (dateChange)="dateChanged( 'start',$event)">
                            <input matEndDate placeholder="End date" [value]="endDate"
                                (dateChange)="dateChanged( 'end',$event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>

                    <div (click)="clearDateRange()"
                        style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 15px; margin-bottom: 1.3rem;">
                        Clear
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfLeaderboardDataToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No data found
                </div>
            </div>
            <div *ngIf="listOfLeaderboardDataToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Rank</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Profile</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Name</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Email</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Phone
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Points
                        </th>



                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let info of listOfLeaderboardDataToDisplay | paginate: { itemsPerPage: count, currentPage: Page,id:'pagination' },let i=index"
                            [ngStyle]="{'background':(((Page-1)*count)
                                +i)==0?'#FFEDBF':((((Page-1)*count)
                                +i)==1?'#D3E2E7':((((Page-1)*count)
                                +i)==2?'#FFD3AC':'transparent'))}">

                            <th class="table-data-content" *ngIf="searchString!='' || startDate!=null">{{info.rank}}
                            </th>
                            <th class="table-data-content" *ngIf="searchString=='' && startDate==null">{{((Page-1)*count)
                                +(i+1)}}
                            </th>
                            <th class="table-data-content"
                                *ngIf="info['user'].avatar!=null && info['user'].avatar!=undefined && info['user'].avatar!=''">
                                <div class="avtr">

                                    <img [src]="info['user'].avatar"
                                        style="height: 100%;object-fit: contain;     width: 100%;  border-radius: 100%;">
                                </div>
                            </th>
                            <th class="table-data-content"
                                *ngIf="info['user'].avatar==null || info['user'].avatar==undefined || info['user'].avatar==''">
                                <div class="avtr" style=" border-radius: 100%;    background: #d9d9d9;">
                                    {{info['user'].fullName!=null && info['user'].fullName!=undefined &&info['user'].fullName!=''?
                                    this.as.getAvatarText(info['user'].fullName):'NA'}}
                                </div>
                            </th>
                            <th class="table-data-content">{{info['user'].fullName!=null && info['user'].fullName!=undefined
                                &&info['user'].fullName!=''?info['user'].fullName:'NA'}}
                            </th>

                            <th class="table-data-content">{{info['user'].email}}
                            </th>
                            <th class="table-data-content">{{info['user'].phone}}</th>
                            <th class="table-data-content">{{info.totalPoints!=null &&
                                info.totalPoints!=undefined?info.totalPoints:'0'}}</th>


                        </tr>
                    </tbody>
                </table>
                <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium; margin: 1em">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!isLoadingExtraData" class="text-right">
                    <pagination-controls style="grid-column: 1/-1; font-size: 1rem; justify-self: end" id="pagination"
                        (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
                <div style="height: 10em;"> </div>
            </div>


        </div>
    </ng-sidebar-container>

</div>