import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarModule } from 'ng-sidebar';
import { ToastrModule } from 'ngx-toastr';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule, } from '@angular/material/stepper';
import { NotificationRoutingModule } from './notification-routing.module';
import { QuillModule } from 'ngx-quill';
import { ListCampaignComponent } from './list-campaign/list-campaign.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NotificationSortDirective } from './notificationSort.directive';
import { NotificationComponent } from './notification.component';
import { AddEditCampaignComponent } from './add-edit-campaign/add-edit-campaign.component';
import { ViewCampaignStatisticComponent } from './view-campaign-statistic/view-campaign-statistic.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChangeCampaignStatusComponent } from './change-campaign-status/change-campaign-status.component';

@NgModule({
  declarations: [
    ListCampaignComponent,
    NotificationSortDirective,
    NotificationComponent,
    AddEditCampaignComponent,
    ViewCampaignStatisticComponent,
    ChangeCampaignStatusComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    NotificationRoutingModule,
    ToastrModule.forRoot(), NgxPaginationModule,NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline'], // toggled buttons
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ['link'],
        ],
      },
    }),
  ],

})
export class NotificationModule { }
