import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSubscriptionComponent } from './list-subscription/list-subscription.component';
import { SubscriptionComponent } from './subscription.component';
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from 'ng-sidebar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ViewSubscriptionComponent } from './view-subscription/view-subscription.component';
import { BulkSubscriptionComponent } from './bulk-subscription/bulk-subscription.component';
import { FilterSubscriptionComponent } from './filter-subscription/filter-subscription.component';
import { SubDashboardComponent } from './sub-dashboard/sub-dashboard.component';
import { ListFilterSubscriptionComponent } from './list-filter-subscription/list-filter-subscription.component';

@NgModule({
  declarations: [ListSubscriptionComponent, SubscriptionComponent, ViewSubscriptionComponent, BulkSubscriptionComponent, FilterSubscriptionComponent, SubDashboardComponent, ListFilterSubscriptionComponent],
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    MatProgressSpinnerModule,
    NgMultiSelectDropDownModule,
    MatNativeDateModule,
    MatDatepickerModule,

  ],
})
export class SubscriptionModule { }
