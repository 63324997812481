import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ExcelService } from '../../excel.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-zoom-file',
  templateUrl: './add-zoom-file.component.html',
  styleUrls: ['./add-zoom-file.component.scss']
})
export class AddZoomFileComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  userDetails: any;
  httpHeaders: any;

  myHeaders = new Headers();
  formdata = new FormData();

  selectedZoomExcelFile: any = null;
  selectedZoomExcelFileName: any = null;
  defaultZoomExcelFile: any;


  listOfInterestedUser: any = [];

  constructor(
    public dialogRef: MatDialogRef<AddZoomFileComponent>,
    private toastr: ToastrService,
    public appService: AppService, private excelService: ExcelService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
  }

  ngOnInit(): void {

  }
  displayExcelFile(event: any) {
    this.selectedZoomExcelFile = event.target.files[0];
    this.selectedZoomExcelFileName = this.selectedZoomExcelFile['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      // this.defaultCampaignExcel = '../../../../../assets/img/excel.png';
    };
    reader.readAsDataURL(this.selectedZoomExcelFile);
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  async saveZoomExcel() {
    let excelList: any = [];
    let userData: any = [];
    if (this.selectedZoomExcelFile != null) {
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(this.selectedZoomExcelFile);


      reader.onload = async (e: any) => {
        const storeNewxls: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(storeNewxls, {
          type: 'binary',
          cellDates: true,
        });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        excelList = data;
        userData = [];
        excelList.forEach((data: any) => {
          if (data['Approval Status'] == 'approved') {
            let params: any;
            params = {
              email: data['Email'],
              // phone: data['Phone'],
              fullName: data['First Name'] + ' ' + data['Last Name'],
            };
            if (data['Phone'] != null && data['Phone'] != undefined && data['Phone'].toString().length > 10) {
              data['Phone'] = String(data['Phone']).replace(/\ /gi, '');
              data['Phone'] = data['Phone'].substr(data['Phone'].length - 10, data['Phone'].length);
            }
            params['phone'] = String(data['Phone']);
            userData.push(params);
          }
        });
        console.log(userData);
        this.isLoading = true;
        if (this.selectedZoomExcelFile != null) {
          this.formdata.set('excel-file', this.selectedZoomExcelFile);
        }
        this.formdata.set('webinarId', this.data['webinar']);
        this.formdata.set('userList', JSON.stringify(userData));

        this.myHeaders.set(
          'Authorization',
          `Bearer ${this.userDetails['accessToken']}`
        );
        var requestOptions: RequestInit = {
          method: 'POST',
          headers: this.myHeaders,
          body: this.formdata,
          redirect: 'follow',
        };
        this.listOfInterestedUser = [];
        const URL = `${webApi.domain + webApi.endPoint.updateZoomFileForWebinar}`;

        try {
          fetch(URL, requestOptions)
            .then((result) => {
              if (result) {
                let data = result.json();
                data.then((res) => {
                  if (res['success']) {
                    this.isLoading = false;
                    this.dialogRef.close();
                    this.closeActivityAtDialog.emit({ zoomFile: res['result']['zoomFile'] });
                  } else {
                    this.isLoading = false;
                  }
                });
              }
            })
            .catch((error) => {
              this.isLoading = false;

            });
          return;
        } catch (error) {
          this.isLoading = false;
        }



      }
    } else {
      return;
    }


  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarninigToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
