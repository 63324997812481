import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-short-video',
  templateUrl: './add-edit-short-video.component.html',
  styleUrls: ['./add-edit-short-video.component.scss'],
})
export class AddEditShortVideoComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  selectedContentImage: any = null;
  selectedContentImageName: any = null;
  defaultContentImage: any;

  selectedVideo: any = null;
  selectedVideoName: any = null;
  defaultVideoImage: any;

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();
  selectedType: string = 'Video';

  listOfAuthor: any = [];

  timeSetting: IDropdownSettings = {
    singleSelection: false,
    idField: 'duration',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };

  urlError: boolean = false;
  authorControl = new FormControl('', [Validators.required]);
  textColorControl = new FormControl('', [Validators.required]);
  typeControl = new FormControl('Video', [Validators.required]);
  captionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  addVideoForm: FormGroup = new FormGroup({
    author: this.authorControl,
    caption: this.captionControl,
    type: this.typeControl,
    textColor: this.textColorControl,
  });
  userDetails: any;
  httpHeaders: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}
  async ngOnInit(): Promise<void> {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });

    await this.fetchAllAuthor();
    this.assignEditValues();
    this.addVideoForm = this.formBuilder.group({
      author: this.authorControl,
      caption: this.captionControl,
      type: this.typeControl,
      textColor: this.textColorControl,
    });
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addVideoForm.get('caption')?.setValue(this.dataToEdit['caption']);
      this.addVideoForm.get('type')?.setValue(this.dataToEdit['type']);
      this.addVideoForm
        .get('textColor')
        ?.setValue(this.dataToEdit['textColor']);
      this.selectedType = this.dataToEdit['type'];
      this.addVideoForm
        .get('author')
        ?.setValue(this.dataToEdit['author']['_id']);
      this.defaultContentImage =
        this.dataToEdit['thumbnail'] == null
          ? null
          : this.dataToEdit['thumbnail'];

      this.defaultVideoImage =
        this.dataToEdit['video'] == null
          ? null
          : 'https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png';
    }
  }

  async fetchAllAuthor() {
    this.isLoading = true;
    this.listOfAuthor = [];

    const URL = `${webApi.domain + webApi.endPoint.fetchAuthors}`;
    await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfAuthor = data['result'];
        this.isLoading = false;
      }
    });
  }

  selectType(target: any) {
    this.selectedType = target.value;
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayAdhaar(event: any) {
    this.selectedContentImage = event.target.files[0];
    this.selectedContentImageName = this.selectedContentImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultContentImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedContentImage);
  }
  selectVideo(event: any) {
    this.selectedVideo = event.target.files[0];
    this.selectedVideoName = this.selectedVideo['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultVideoImage =
        'https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png';
    };
    reader.readAsDataURL(this.selectedContentImage);
  }

  checkUrl(event: any) {
    var url = event.value;
    if (url == '') {
      this.urlError = false;
      return;
    }
    if (!url.startsWith('https:') || !url.endsWith('.pdf')) {
      this.urlError = true;
      return;
    } else {
      this.urlError = false;
    }
  }

  addEditVideo() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('videoId', this.dataToEdit['_id']);
      this.formdata.set('thumbnail', this.dataToEdit['thumbnail']);
      if (this.selectedType == 'Video') {
        this.formdata.set('video', this.dataToEdit['video']);
      } else {
        this.formdata.set('video', '');
      }
    } else {
      this.formdata.set('videoId', '0');
    }

    if (this.selectedContentImage != null) {
      this.formdata.set('thumbnail', this.selectedContentImage);
    }

    if (this.selectedVideo != null) {
      this.formdata.set('video', this.selectedVideo);
    }

    this.formdata.set('caption', this.addVideoForm.get('caption')?.value);
    this.formdata.set('textColor', this.addVideoForm.get('textColor')?.value);
    this.formdata.set('author', this.addVideoForm.get('author')?.value);
    this.formdata.set('type', this.addVideoForm.get('type')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateShortVideo}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addVideoForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  disable() {}
}
