import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListTransactionLogComponent } from './list-transaction-log/list-transaction-log.component';



const routes: Routes = [
  {
    path: '',
    component: ListTransactionLogComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/transaction' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionLogRoutingModule { }
