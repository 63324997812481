import { NgModule } from '@angular/core';
import { ListBatchComponent } from './list-batch/list-batch.component';
import { Routes, RouterModule } from '@angular/router';
import { AddEditBatchComponent } from './add-edit-batch/add-edit-batch.component';
import { ActivityDetailComponent } from './add-edit-batch/activity-detail/activity-detail.component';
import { AddEditActivityDetailComponent } from './add-edit-batch/add-edit-activity-detail/add-edit-activity-detail.component';
import { ViewBatchEnrolledUserComponent } from './view-batch-enrolled-user/view-batch-enrolled-user.component';
import { BatchAnnouncementComponent } from './batch-announcement/batch-announcement.component';


const routes: Routes = [
  {
    path: '',
    component: ListBatchComponent,
  },
  {
    path: 'add-batch',
    component: AddEditBatchComponent,
  },
  {
    path: 'edit-batch',
    component: AddEditBatchComponent,
  },
  {
    path: 'batch-activity',
    component: ActivityDetailComponent,
  },
  {
    path: 'add-activity',
    component: AddEditActivityDetailComponent,
  },
  {
    path: 'edit-activity',
    component: AddEditActivityDetailComponent,
  },
  {
    path: 'batch-enroled-user',
    component: ViewBatchEnrolledUserComponent,
  },
  {
    path: 'batch-announcement',
    component: BatchAnnouncementComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'catalog/batch' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BatchRoutingModule { }
