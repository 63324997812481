<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Offer
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addEditOfferForm" [formGroup]="addEditOfferForm">
        <div class="row">
            <div class="col-md-6">
                <label for="code" class="label required">Coupon Code</label>
                <input type="text" placeholder="Coupon Code" id="code" formControlName="code"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="type" class="label required">Coupon Type</label>
                <select id="type" name="type" class="input-style" formControlName="type">
                    <option value="Coupon Type" selected disabled>Coupon Type</option>
                    <option value="PERCENTAGE">PERCENTAGE</option>
                    <option value="RUPEES">RUPEES</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="couponQty" class="label required">Discount Percentage</label>
                <input type="number" placeholder="Discount Percentage" id="couponQty" formControlName="discountQuantity"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="maxDiscountAmount" class="label">Max Discount</label>
                <input type="number" placeholder="Max Discount" id="maxDiscountAmount" formControlName="maxDiscountAmount"
                    class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="startDate" class="label required">Start Date</label>
                <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label required">End Date</label>
                <input type="date" placeholder="End Date" id="endDate" formControlName="endDate" class="input-style">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="access" class="label required">Coupon Access</label>
                <select id="access" name="access" class="input-style" formControlName="access">
                    <option value="Coupon Access" selected disabled>Coupon Access</option>
                    <option value="INTERNAL">INTERNAL</option>
                    <option value="EXTERNAL">EXTERNAL</option>
                </select>
            </div>
           
        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="description" class="label required">Description</label>
                <textarea type="text" placeholder="Description" id="description" formControlName="description"
                    class="textarea-style"
                    style="width: 100%;height: 6rem;margin-bottom: 1rem;padding-left: 0.5rem;padding-top: 0.5rem;border-radius: 5px;padding-right: 0.5rem"></textarea>
            </div>
        </div>
        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addEditOfferForm.invalid ,'save-btn':addEditOfferForm.valid  }"
                value="{{'Save'}}" [disabled]="addEditOfferForm.invalid " (click)="addEditOffer()" />
        </div>

    </form>
</div>