<div style="width: 30em;background-color: white;">
    <h1 mat-dialog-title style="font-weight: 600;color: black;">Change Status</h1>
    <form name="changeCampaignStatusForm" [formGroup]="changeCampaignStatusForm">
        <div>
            <label for="status" class="label" >Select Status</label>
            <select id="status" name="status" class="input-style" style="margin-top: 10px;" formControlName="status">
                <option value="Select Status" selected disabled>Select Status</option>
                <option value="Active">Active</option>
                <option value="Pause">Pause</option>
                <option value="Completed">Completed</option>
            </select>
        </div>
    </form>
    <div mat-dialog-actions>
        <button class="cancel-btn" (click)="close()">Cancel</button>

        <button [ngClass]="{'ok-btn-disabled':changeCampaignStatusForm.invalid ,'ok-btn-enabled':changeCampaignStatusForm.valid }"
            (click)="changeCampaignStatusForm.invalid ?null: changeCampaignStatus()">Save</button>
    </div>
</div>