<div style="width: 35em;">
    <h1 mat-dialog-title style="font-weight: 500;">Generate Report</h1>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading">
        <div class="row">
            <div class="col-md-12">
                <label for="image" class="label">Excel File</label>
                <div>
                    <div class="d-flex">
                        <input type="text" placeholder="Choose file...." name="file-name" id="file-name"
                            class="file-name" [(ngModel)]="selectedZoomExcelFileName"
                            [ngModelOptions]="{standalone: true}" readonly="readonly">
                        <label class="btn">
                            Select
                            <input type="file" name="file" id="file" class="file" (change)="displayExcelFile($event)">

                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button class="cancel-btn" (click)="isLoading?null:close()">Cancel</button>
            <button *ngIf="isLoading"
                [ngClass]="{'ok-btn-disabled':selectedZoomExcelFileName==null || selectedZoomExcelFileName==undefined,'ok-btn-enabled':selectedZoomExcelFileName!=null && selectedZoomExcelFileName!=undefined }"
                [disabled]="selectedZoomExcelFileName==null || selectedZoomExcelFileName==undefined">
                <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                </mat-progress-spinner>
            </button>

            <button *ngIf="!isLoading "
                [ngClass]="{'ok-btn-disabled':selectedZoomExcelFileName==null || selectedZoomExcelFileName==undefined,'ok-btn-enabled':selectedZoomExcelFileName!=null && selectedZoomExcelFileName!=undefined }"
                [disabled]="selectedZoomExcelFileName==null || selectedZoomExcelFileName==undefined"
                (click)="saveZoomExcel()">Save</button>
        </div>
    </div>
</div>