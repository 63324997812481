<div style="width: 25em;">
    <h1 mat-dialog-title style="font-weight: 500;">Update Status</h1>

    <div>
        <label for="duration" class="label required">Select Duration</label>
        <select class="input-style" (change)="selectStatus($event.target)">
            <option value="Select Status" disabled selected>Select Status</option>
            <option value="Success">Success</option>
            <option value="Rejected">Rejected</option>

        </select>
    </div>
    <div mat-dialog-actions>
        <button class="cancel-btn" (click)="isLoading?null:close()">Cancel</button>

        <button *ngIf="isLoading"
            [ngClass]="{'ok-btn-disabled':selectedStatus==null ,'ok-btn-enabled':selectedStatus!=null }"
            [disabled]="selectedStatus==null ">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </button>


        <button *ngIf="!isLoading"
            [ngClass]="{'ok-btn-disabled':selectedStatus==null ,'ok-btn-enabled':selectedStatus!=null }"
            [disabled]="selectedStatus==null " (click)="updateStatus()">Update</button>
    </div>
</div>