<div class="main-container">
    <div class="contents">
        <div class="row">
            <div class="col-md-6" style="align-self: center;margin-bottom: 1em;">
                <div class="w-60">
                    <h2 class="welcome-text">Welcome to Tushar Ghone Web Admin</h2>
                </div>
                <div class="mini-welcome w-60">
                    {{!isSignup?'Log In' : 'Sign up'}} and start managing
                </div>

                <div *ngIf="!isSignup">
                    <form name="loginForm" [formGroup]="loginForm">
                        <div class="w-60">
                            <input class="input-field w-60" placeholder="Enter Mobile Number" type="text"
                                formControlName="mobileNo" id="mobileNo" maxlength="10" required >
                        </div>


                    </form>
                </div>
                <div id="recaptcha-container" style="margin-top: 1em;display: flex;justify-content: center;width: 60%;"></div>

                <div class="w-60">
                    <button *ngIf="!isContinueClicked"
                        [ngClass]="{ 'continue-btn-disabled': loginForm.invalid, 'continue-btn-enabled': loginForm.valid }"
                        type="submit" (click)="checkForValidUser()" [disabled]="loginForm.invalid">Continue</button>
                    <div class="continue-btn-enabled" *ngIf="isContinueClicked">
                        <mat-progress-spinner mode="indeterminate" diameter="20"
                            style="margin:0 auto;height:20px;width:20px;color: white;">
                        </mat-progress-spinner>

                    </div>
                </div>
                <!-- <div *ngIf="isSignup">
                    <form name="signupForm" [formGroup]="signupForm">
                        <div class="w-60">
                            <input class="input-field w-60" placeholder="Enter Full Name" type="text"
                                formControlName="fullName" id="fullName" required>
                        </div>
                        <div class="w-60">
                            <input class="input-field w-60" placeholder="Enter Mobile Number" type="text"
                                formControlName="mobileNo" id="mobileNo" maxlength="10" required>
                        </div>
                        <div class="w-60">
                            <input class="input-field w-60" placeholder="Enter Email" type="email"
                                formControlName="email" id="email" required>
                        </div>

                        <div class="w-60">
                            <button type="submit" (click)="gotoConfirmOtp()"
                                [ngClass]="{ 'continue-btn-disabled': signupForm.invalid, 'continue-btn-enabled': signupForm.valid }"
                                [disabled]="signupForm.invalid">Continue</button>
                        </div>
                    </form>
                </div> -->

                <!-- <div class="w-60" *ngIf="!isSignup" (click)="switchToSignupLogin()" style="font-weight: 500;">
                    <div class="m-auto" style="margin-top: 2em !important;">
                        Don't have an account? <span style="color:rgb(14, 163, 255);cursor: pointer;">Sign up</span>
                    </div>
                </div>
                <div class="w-60" *ngIf="isSignup" (click)="switchToSignupLogin()" style="font-weight: 500;">
                    <div class="m-auto" style="margin-top: 2em !important;">
                        Already have an account? <span style="color:rgb(14, 163, 255);cursor: pointer;">Log In</span>
                    </div>
                </div> -->
                <div class="w-60">
                    <div class="policies m-auto">By continuing you agree to the <strong style="color:black">Terms of
                            Services</strong> and
                        <strong style="color:black">Privacy
                            policy.</strong>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img class="phone-img-size" src="../../../assets/img/login.jpg" alt="phoneimg" srcset="">
            </div>
        </div>
    </div>
</div>