import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ExcelService } from '../../excel.service';
import { ViewSubscriptionComponent } from '../view-subscription/view-subscription.component';
export interface Subscription {
  _id: string;
  user: any;
  startDate: Date;
  endDate: Date;
  totalAmount: number;
  plan: any;
  priceAndDuration: any;
  rzpSubscriptionId: string;
  subscriptionStatus: string;
  totalBilligCycle: number;
  remainingCycleCount: number;
  totalCyclePaidCount: number;
  visitCount: number;
  type: string;
  createdAt: Date;
  srNo: number;
}
@Component({
  selector: 'app-list-filter-subscription',
  templateUrl: './list-filter-subscription.component.html',
  styleUrls: ['./list-filter-subscription.component.scss']
})
export class ListFilterSubscriptionComponent implements OnInit {

  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;

  startDate: any;
  endDate: any;
  filterToEdit: any;
  limit: any = 500;
  skip: any = 0;
  index: any = 1;
  totalCount: number = 0;
  isLoadingExtraData: boolean = false;
  isLoadingData: boolean = false;

  isAddEditCalled: boolean = false;
  isFilter: boolean = false;
  opened: boolean = false;

  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;

  searchedSubscription: Array<Subscription> = ([] = []);
  searched: boolean = false;
  downloadReport: boolean = false;
  searchString: any = '';
  timer: any;
  dateResult: any;

  // totalCount:number=0;
  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    public excelExport: ExcelService
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    if (this.as.planType != null && this.as.planType != undefined) {
      this.fetchSubscription();
    } else {
      this.router.navigate(['/catalog/subscription']);
    }
  }

  dataToEdit: any;
  p: any = 1;
  count: any = 50;

  listOfSubscriptionToDisplay: Array<Subscription> = [];
  listOfSubscription: Array<Subscription> = [];
  listOfSubscriptionOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];

  myHeaders = new Headers();

  async fetchSubscription() {
    this.isLoading = true;
    this.listOfSubscriptionToDisplay = [];
    this.listOfSubscription = [];
    try {
      let URL = '';
      this.skip = 0;
      this.limit = 500;
      this.index = 1;
      let param: any;
      if (this.endDate != null && this.startDate != null) {

        param = {
          startDate: this.startDate,
          endDate: this.endDate,
          skip: this.skip,
          limit: this.limit,
          planType: this.as.planType,
          status: this.as.subsStatus != null && this.as.subsStatus != undefined ? this.as.subsStatus : ['ACTIVE']
        };
      } else {
        param = {
          skip: this.skip,
          limit: this.limit,
          planType: this.as.planType,
          status: this.as.subsStatus != null && this.as.subsStatus != undefined ? this.as.subsStatus : ['ACTIVE']
        };
      }

      URL = this.as.onlyActive ? `${webApi.domain + webApi.endPoint.fetchActiveFilterSubscription}` : `${webApi.domain + webApi.endPoint.fetchAllFilterSubscription}`;

      await this.as
        .fetchDetail(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.totalCount = data['subsCount'];
            data['result'].forEach((sub: any, index: number) => {
              if (sub['user'] != null && sub['user'] != undefined) {
                console.log(sub['user']);
                sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';

                // else {
                //   sub['user']['fullName'] = 'N.A.';
                // }
                this.listOfSubscription.push({
                  srNo: this.index,
                  _id: sub['_id'],
                  user: sub['user'],
                  startDate: sub['startDate'],
                  endDate: sub['endDate'],
                  totalAmount: sub['totalAmount'],
                  plan: sub['plan'],
                  priceAndDuration: sub['priceAndDuration'],
                  rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                  subscriptionStatus: sub['subscriptionStatus'],
                  totalBilligCycle: sub['totalBilligCycle'] ?? 0,
              visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],

                  remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                  totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                  type: sub['type'],
                  createdAt: sub['createdAt'],
                });
                this.index++;
              }
            });
            // this.totalCount = data['count'];
            this.listOfSubscriptionToDisplay = [...this.listOfSubscription];
            this.isLoading = false;
          } else {
            this.listOfSubscriptionToDisplay = [];
            this.listOfSubscription = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfSubscriptionToDisplay = [];
      this.listOfSubscription = [];
      this.isLoading = false;
    }
  }

  async fetchExtraSubscription(load: boolean) {
    this.isLoadingExtraData = true;
    try {
      let URL = '';
      let param: any;
      if (this.endDate != null && this.startDate != null) {

        param = {
          startDate: this.startDate,
          endDate: this.endDate,
          planType: this.as.planType,
          skip: this.skip,
          limit: this.limit,
          status: this.as.subsStatus != null && this.as.subsStatus != undefined ? this.as.subsStatus : ['ACTIVE']

        };
      } else {
        param = {
          skip: this.skip,
          limit: this.limit,
          planType: this.as.planType,
          status: this.as.subsStatus != null && this.as.subsStatus != undefined ? this.as.subsStatus : ['ACTIVE']

        };
      }
      URL = this.as.onlyActive ? `${webApi.domain + webApi.endPoint.fetchActiveFilterSubscription}` : `${webApi.domain + webApi.endPoint.fetchAllFilterSubscription}`;

      await this.as
        .fetchDetail(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.totalCount = data['subsCount'];
            data['result'].forEach((sub: any, index: number) => {
              if (sub['user'] != null && sub['user'] != undefined) {
                sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';

                // else {
                //   sub['user']['fullName'] = 'N.A.';
                // }
                this.listOfSubscription.push({
                  srNo: this.index,
                  _id: sub['_id'],
                  user: sub['user'],
                  startDate: sub['startDate'],
                  endDate: sub['endDate'],
                  totalAmount: sub['totalAmount'],
                  plan: sub['plan'],
                  priceAndDuration: sub['priceAndDuration'],
                  rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                  subscriptionStatus: sub['subscriptionStatus'],
              visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],

                  totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                  remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                  totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                  type: sub['type'],
                  createdAt: sub['createdAt'],
                });
                this.index++;
              }
            });
            this.listOfSubscriptionToDisplay = [...this.listOfSubscription];
            this.isLoadingExtraData = false;
          } else {
            this.listOfSubscriptionToDisplay = [];
            this.listOfSubscription = [];
            this.isLoadingExtraData = false;
          }
        });
    } catch (error) {
      this.listOfSubscriptionToDisplay = [];
      this.listOfSubscription = [];
      this.isLoading = false;
    }
  }

  // editSubscriptionDetail(subscription: any) {
  //   this.as.webinarEdit = subscription;
  //   this.router.navigate(['/catalog/subscription/add-edit-subscription']);
  // }
  // viewSubscriptionDetail(subscription: any) {
  //   this.as.webinarDetailToView = subscription;
  //   this.router.navigate(['/catalog/subscription/subscription-statistic']);
  // }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  viewSubscription(subscription: Subscription) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { subscription: subscription };
    dialogConfig.width = '40vw';
    dialogConfig.maxHeight = '70vh';
    let dialogRef = this.matDialog.open(
      ViewSubscriptionComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
      }
    });
  }

  selectStatus(target: any) {
    this.listOfSubscriptionToDisplay = [];
    let value = target.value;
    if (value == 'All') {
      this.listOfSubscriptionToDisplay = this.listOfSubscription;
    } else {
      this.listOfSubscriptionToDisplay = this.listOfSubscription.filter(
        (data: any) => data.type == value
      );
    }
  }

  pageChanged(event: any) {
    this.p = event;
    console.log(event);
    if (this.filterToEdit == null) {
      if (this.startDate == null && this.endDate == null) {
        if (
          this.p * this.count >=
          this.listOfSubscription.length - this.count * 3
        ) {
          this.skip = this.skip + this.limit;
          if (!this.isLoadingExtraData) this.fetchExtraSubscription(false);
        } else if (this.p * this.count >= this.listOfSubscription.length) {
          this.skip = this.skip + this.limit;
          this.fetchExtraSubscription(true);
        }
      }
    }
  }

  async dateChanged(type: string, event: any) {
    this.searched = false;
    this.searchString = '';
    clearTimeout(this.timer);
    this.searchedSubscription = [];
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      this.fetchSubscription();
    }
  }



  clearDateRange() {
    // this.listOfUser = [...this.listOfUserOriginal];
    // this.dateResult = null;
    if (this.endDate != null && this.startDate != null) {
      this.endDate = null;
      this.startDate = null;
      this.fetchSubscription();
      this.p = 1;
      this.totalCount = 0;
      this.index = 1;
    }
    this.searched = false;
    clearTimeout(this.timer);
    this.searchString = '';
    this.searchedSubscription = [];
  }

  setDateNull() {
    this.startDate = null;
    this.endDate = null;
    this.searched = false;
    clearTimeout(this.timer);
    this.searchedSubscription = [];
    this.searchString = '';
    this.filterToEdit = null;
  }

  emptySearchString(event: any) {
    if (event.value == '') {
      this.searchedSubscription = [];
      this.searched = false;
    }
  }
  async searchSubscription() {
    if (this.searchString != '') {
      try {
        this.filterToEdit = null;
        clearTimeout(this.timer);
        this.isLoading = true;
        this.searched = true;
        this.fetchSubscription();
        this.searchedSubscription = [];
        const param = {
          searchedString: String(this.searchString).toLowerCase(),
          planType: this.as.planType,
          status: this.as.subsStatus != null && this.as.subsStatus != undefined ? this.as.subsStatus : ['ACTIVE']
        };
        const URL = `${webApi.domain + webApi.endPoint.searchFilterSubscription}`;
        await this.as
          .fetchDetail(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              data['result'].forEach((sub: any, index: number) => {
                sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';
                this.searchedSubscription.push({
                  srNo: index + 1,
                  _id: sub['_id'],
                  user: sub['user'],
                  startDate: sub['startDate'],
                  endDate: sub['endDate'],
                  totalAmount: sub['totalAmount'],
                  plan: sub['plan'],
                  priceAndDuration: sub['priceAndDuration'],
                  rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                  subscriptionStatus: sub['subscriptionStatus'],
              visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],

                  totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                  remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                  totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                  type: sub['type'],
                  createdAt: sub['createdAt'],
                });
              });
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
          });
      } catch (error) {
        this.isLoading = false;
        this.searchedSubscription = [];
      }
    } else {
      this.searchedSubscription = [];
    }
    this.dateResult = null;
  }
  async searchSubscriptionWhileTyping() {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      if (this.searchString != '') {
        try {
          this.filterToEdit = null;
          this.isLoading = true;
          this.fetchSubscription();
          this.searched = true;
          const param = {
            searchedString: String(this.searchString).toLowerCase(),
            planType: this.as.planType,
            status: this.as.subsStatus != null && this.as.subsStatus != undefined ? this.as.subsStatus : ['ACTIVE']
          };
          const URL = `${webApi.domain + webApi.endPoint.searchFilterSubscription}`;
          await this.as
            .fetchDetail(param, URL, this.httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.searchedSubscription = [];
                data['result'].forEach((sub: any, index: number) => {
                  sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';
                  this.searchedSubscription.push({
                    srNo: index + 1,
                    _id: sub['_id'],
                    user: sub['user'],
                    startDate: sub['startDate'],
                    endDate: sub['endDate'],
                    totalAmount: sub['totalAmount'],
                    plan: sub['plan'],
                    priceAndDuration: sub['priceAndDuration'],
                    rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                    subscriptionStatus: sub['subscriptionStatus'],
                    totalBilligCycle: sub['totalBilligCycle'] ?? 0,
              visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],

                    remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                    totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                    type: sub['type'],
                    createdAt: sub['createdAt'],
                  });
                });
                this.isLoading = false;
              } else {
                this.isLoading = false;
              }
            });
        } catch (error) {
          this.isLoading = false;
          this.searchedSubscription = [];
        }
      } else {
        this.searchedSubscription = [];
      }
    }, 2000);

    this.dateResult = null;
  }


  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }



  getFilterCount() { }
  generateReport() {
    this.downloadReport = true;
    var toExport: any = [];
    this.listOfSubscriptionToDisplay.forEach(
      (subscription: any, index: number) => {
        let query: any = {
          SrNo: index + 1,
          Name: subscription.user['fullName'],
          Phone: subscription.user['phone'],
          Email: subscription.user['email'],
          Plan: subscription.plan.name + ' (' + subscription.plan.subType + ')',
          Type: subscription.type,
          Rzp_Sub_Id: subscription.rzpSubscriptionId,
          StartDate: new Date(subscription.startDate)
            .toISOString()
            .slice(0, 10),
          EndDate: new Date(subscription.endDate).toISOString().slice(0, 10),
          Duration: `${subscription.priceAndDuration['duration'] ?? ''} ${subscription.priceAndDuration['durationType'] ?? ''
            }`,
          Amount: subscription.priceAndDuration['price'] ?? 0,
        };
        toExport.push(query);
      }
    );
    this.excelExport.exportAsExcelFile(toExport, 'Subscription Report');
    this.downloadReport = false;
  }

  gotoSubDashboard() {
    this.router.navigate(['/catalog/subscription']);
  }
}
