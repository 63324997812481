<div mat-dialog-title
    style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
    <div style="font-size: 18px;font-weight: 600;">
        {{data['id']==null ||data['id']==undefined? 'Add':'Edit'}} Community
    </div>
    <div>
        <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
    </div>
</div>
<mat-dialog-content
    style="max-height: 45vh;min-height: 43vh;overflow-y: scroll;max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
    <div class="container__filter" *ngIf="!isLoading">
        <form class="container__filter__form" [formGroup]="addEditCommunityForm" *ngIf="!isLoading">



            <div class="mb" style="margin-bottom: 2px">
                <label class="label mb required" for>Community Name</label>
                <div class=" mb">
                    <input type="text" class="input-style" placeholder="Enter community name"
                        formControlName="communityName">
                </div>
            </div>

            <div class="mb" style="margin-bottom: 2px">
                <label class="label mb required" for>Community Guideline</label>
                <div class=" mb">
                    <input type="text" class="input-style" placeholder="Enter community guideline"
                        formControlName="communityGuideline">
                </div>
            </div>

            <div class="mb" style="margin-bottom: 2px">
                <label class="label mb required" for>Community Type</label>
                <div class=" mb">
                    <select name="communityType" id="communityType" formControlName="communityType" class="input-style"
                        (change)="typeSelected($event)">
                        <option value="Select Type" disabled selected> Select Type</option>
                        <option value="Sample"> Sample</option>
                        <option value="Trade Analysis"> Trade Analysis</option>
                    </select>
                </div>
            </div>

            <div class="mb" style="margin-bottom: 2px"
                *ngIf="this.addEditCommunityForm.get('communityType')?.value=='Sample'">
                <label class="label mb required" for>Community Sub Type</label>
                <div class=" mb">
                    <select name="communitySubType" id="communitySubType" formControlName="communitySubType"
                        class="input-style" (change)="subTypeSelected($event)">
                        <option value="Select Sub Type" disabled selected> Select Sub Type</option>
                        <option value="Free"> Free</option>
                        <option value="Paid"
                            *ngIf="this.addEditCommunityForm.get('communityType')?.value=='Trade Analysis'"> Paid
                        </option>
                        <option value="Course"> Course</option>
                        <option value="Buddy Group"> Buddy Group</option>
                    </select>
                </div>
            </div>
            <div class="mb" style="margin-bottom: 2px">
                <label class="label mb required" for>Chat Type</label>
                <div class=" mb">
                    <select name="chatType" id="chatType" formControlName="chatType" class="input-style">
                        <option value="Select Chat Type" disabled selected> Select Chat Type</option>
                        <option value="All"> All</option>
                        <option value="Admin"> Admin</option>
                    </select>
                </div>
            </div>


            <div class="mb" style="margin-bottom: 2px"
                *ngIf="this.addEditCommunityForm.get('communityType')?.value=='Sample' && (this.addEditCommunityForm.get('communitySubType')?.value!='Buddy Group' && this.addEditCommunityForm.get('communitySubType')?.value!='Select Sub Type')">
                <label class="label mb required" for>Community Duration (In Days)</label>
                <div class=" mb">
                    <input type="number" class="input-style" placeholder="Enter community Duration"
                        formControlName="expiresInDays">
                </div>
            </div>

            <div class="mb" style="margin-bottom: 2px"
                *ngIf="this.addEditCommunityForm.get('communitySubType')?.value=='Course'">
                <label class="label mb required" for>Select Course</label>
                <div class=" mb">
                    <select name="course" id="course" formControlName="course" class="input-style">
                        <option value="Select Course" disabled selected> Select Course</option>
                        <option value="Free"> Free</option>
                        <option *ngFor="let dt of listOfCourse" [value]="dt['_id']">{{dt['courseName']}}</option>
                    </select>
                </div>
            </div>

            <div class="mb" style="margin-bottom: 2px"
                *ngIf="this.addEditCommunityForm.get('communitySubType')?.value=='Free'">
                <label class="label mb required" for>Available Day</label>
                <div class=" mb">
                    <ng-multiselect-dropdown class="multi-select" style="width: 100%" [placeholder]="'Select Day'"
                        [ngModelOptions]="{ standalone: true }" [settings]="daysDropdownSettings" [data]="listOfDays"
                        [(ngModel)]="selectedDays">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <!-- <div class="mb" style="margin-bottom: 2px"
                *ngIf="this.addEditCommunityForm.get('communityType')?.value=='BuddyGroup'">
                <label class="label mb required" for>Select Members</label>
                <div class=" mb">

                </div>
            </div> -->
            <div class="mb" style="margin-bottom: 2px">
                <label class="label required" for="">Upload Icon</label>
                <div *ngIf="defaultImageUrl==null || defaultImageUrl==undefined || defaultImageUrl==''"
                    style="height: 15.6rem" class="uploadBox">
                    <div style="text-align: center;">
                        <label for="uploadImage"
                            style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                            class="uploadImage">+ Add New File</label>
                        <input type="file" id="uploadImage" for="uploadImage" style="display: none" accept="image/*"
                            (change)="selectImageFile($event)" required />
                        <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                            Upload File
                        </div>
                        <img style="width: 3.3rem; margin-bottom: 2rem" src="../../../../../assets/img/upload-file.png"
                            alt="" srcset="" />
                    </div>
                </div>
                <div *ngIf="defaultImageUrl!=null && defaultImageUrl!=undefined && defaultImageUrl!=''"
                    class="uploadBox" style="position: relative; height: 15.6rem">
                    <img (click)="removeImage()" class="removeImage" src="../../../../../assets/svgIcons/close.svg"
                        alt="" srcset="" />
                    <img style="max-height: 13rem; max-width: 20rem;padding: 1rem;border-radius: 25px;"
                        [src]="defaultImageUrl" alt="" srcset="" />
                    <div class="fileName">
                        {{selectedImageName}}
                    </div>
                </div>
            </div>





        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-1" type="button" (click)="close()">
            Cancel
        </button>
        <button class="btn btn-2" type="button" (click)="onSubmit()">
            Save
        </button>
    </div>

</mat-dialog-actions>