<div>
    <div class="dialog-header">
        <div>Add {{data['source']}}</div>
        <div class="dialog-header__close-btn" (click)="close()">
            <img class="dialog-header__close-btn__icon" src="../../../../../assets/svgIcons/close.svg">
        </div>
    </div>
    <div class="dialog-body">
        <div *ngIf="data['source']=='Feature'">
            <div class="row">
                <div class="col-md-12">
                    <label for="title" class="label required">Title</label>
                    <input type="text" placeholder="Enter a title" id="title" [(ngModel)]="title"
                        class="dialog-body__title-input" required>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <label class="label required" for="">Upload Icon</label>
                        <div *ngIf="selectedImage==null" style="height: 15.6rem" class="dialog-body__uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadImage"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadImage">+ Add New File</label>
                                <input type="file" id="uploadImage" for="uploadImage" style="display: none"
                                    accept="image/*" (change)="selectImageFile($event)" required />
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>
                        </div>
                        <div *ngIf="selectedImage!=null" class="dialog-body__uploadBox"
                            style="position: relative; height: 15.6rem">
                            <img (click)="removeImage()" class="dialog-body__removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 15rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultImageUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedImageName}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="data['source']=='Advantage'">
            <div class="row">
                <div class="col-md-12">
                    <label for="title" class="label required">Title</label>
                    <input type="text" placeholder="Enter a title" id="title" [(ngModel)]="title"
                        class="dialog-body__title-input" required>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <label class="label required" for="">Upload Icon</label>
                        <div *ngIf="selectedImage==null" style="height: 15.6rem" class="dialog-body__uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadImage"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadImage">+ Add New File</label>
                                <input type="file" id="uploadImage" for="uploadImage" style="display: none"
                                    accept="image/*" (change)="selectImageFile($event)" required />
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>
                        </div>
                        <div *ngIf="selectedImage!=null" class="dialog-body__uploadBox"
                            style="position: relative; height: 15.6rem">
                            <img (click)="removeImage()" class="dialog-body__removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 15rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultImageUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedImageName}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="data['source']=='Review Video'">
            <div class="row">
                <div class="col-md-6">
                    <label for="batchMonth" class="label required">Select Month</label>
                    <input type="month" placeholder="Enter month" id="batchMonth" [(ngModel)]="batchMonth"
                        class="dialog-body__title-input" required>
                </div>
                <div class="col-md-6">
                    <label for="batchRating" class="label required">Select Rating</label>
                    <select name="batchRating" class="dialog-body__title-input" id="batchRating"
                        [(ngModel)]="batchRating">
                        <option value="1">1 Star</option>
                        <option value="2">2 Star</option>
                        <option value="3">3 Star</option>
                        <option value="4">4 Star</option>
                        <option value="5">5 Star</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <label class="label required" for="">Upload Video</label>
                        <div *ngIf="selectedVideo==null" style="height: 15.6rem" class="dialog-body__uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadVideo"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadVideo">+ Add New File</label>
                                <input type="file" id="uploadVideo" for="uploadVideo" style="display: none"
                                    accept="video/*" (change)="selectVideoFile($event)" required />
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>

                        </div>
                        <div *ngIf="selectedVideo!=null" class="dialog-body__uploadBox"
                            style="position: relative; height: 15.6rem">
                            <img (click)="removeVideo()" class="dialog-body__removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 15rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultVideoUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedVideoName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <label class="label required" for="">Upload Thumbnail</label>
                        <div *ngIf="selectedImage==null" style="height: 15.6rem" class="dialog-body__uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadImage"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadImage">+ Add New File</label>
                                <input type="file" id="uploadImage" for="uploadImage" style="display: none"
                                    accept="image/*" (change)="selectImageFile($event)" required />
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>
                        </div>
                        <div *ngIf="selectedImage!=null" class="dialog-body__uploadBox"
                            style="position: relative; height: 15.6rem">
                            <img (click)="removeImage()" class="dialog-body__removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 15rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultImageUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedImageName}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div mat-dialog-actions style="justify-content: end;">
            <button class="no-btn" mat-button mat-dialog-close (click)="close()">Cancel</button>
            <button *ngIf="data['source']=='Feature'" class="yes-btn" mat-button mat-dialog-close
                (click)="addFeature()">Save</button>
            <button *ngIf="data['source']=='Advantage'" class="yes-btn" mat-button mat-dialog-close
                (click)="addAdvantage()">Save</button>
            <button *ngIf="data['source']=='Review Video'" class="yes-btn" mat-button mat-dialog-close
                (click)="addReviewVideo()">Save</button>
        </div>
    </div>
</div>