<div class="main-container">
    <div class="row align-center" style="justify-content: space-between;">

        <div class="col-md-4 top-heading" style="cursor: pointer;margin-bottom: 1rem;margin-top: 1rem;">
            <span style="font-size: 1.5rem;cursor: pointer;">
                Enroled User ({{this.totalCount}})
            </span>
        </div>

    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;overflow-y: scroll;" *ngIf="!isLoading">

        <div *ngIf="listOfUserToDisplay.length==0">
            <div class="no-available">
                Enrolment not found!
            </div>
        </div>
        <div *ngIf="listOfUserToDisplay.length>0">
            <table class="table-style">
                <thead class="table-heading">

                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                        class="table-heading-content">
                        Name</th>
                    <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                        class="table-heading-content">Mobile Number</th>

                    <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                        class="table-heading-content">
                        Email</th>

                    <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                        class="table-heading-content">Start Date
                    </th>
                    <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                        class="table-heading-content">End Date
                    </th>

                    <!-- <th style="cursor: pointer;" data-order="desc" data-name="description" scope="col"
                        class="table-heading-content">Active
                    </th> -->
                    <!-- <th class="table-heading-content">Action</th> -->

                </thead>
                <div style="height: 0.6em;"> </div>
                <tbody>
                    <tr class="table-data"
                        *ngFor="let enrolment of listOfUserToDisplay | paginate: { itemsPerPage: count, currentPage: p }">

                        <th class="table-data-content">{{enrolment.user.fullName}}</th>
                        <th class="table-data-content">{{enrolment.user.phone}}</th>
                        <th class="table-data-content">{{enrolment.user.email}}</th>
                        <th class="table-data-content">{{enrolment.startDate |date}}</th>
                        <th class="table-data-content">{{enrolment.endDate==null || enrolment.endDate==undefined?'N.A'
                            :enrolment.endDate |date}}</th>
                        <!-- <th class="table-data-content">
                            <img *ngIf="enrolment.isActive"
                                class="table-data-content__status-icon table-data-content__status-icon-on"
                                src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem; cursor: pointer;"
                                (click)="updateUserEnrolmentStatus(enrolment,false)" slot="start" />
                            <img *ngIf="!enrolment.isActive"
                                class="table-data-content__status-icon table-data-content__status-icon-off"
                                src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem; cursor: pointer;"
                                (click)="updateUserEnrolmentStatus(enrolment,true)" slot="start" />
                        </th> -->
                    </tr>
                </tbody>
            </table>
            <div class="text-right" style="font-size: medium;margin: 1em;">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>


    </div>
</div>