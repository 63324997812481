import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-campaign-statistic',
  templateUrl: './view-campaign-statistic.component.html',
  styleUrls: ['./view-campaign-statistic.component.scss']
})
export class ViewCampaignStatisticComponent implements OnInit {

  isLoading: any = true;
  listOfCampaignDeliveryReport: any = [];
  campaign: any;
  userDetail: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router, private toastr: ToastrService,
    public appService: AppService, private formBuilder: FormBuilder
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if (this.appService.campaignDetailToView != null) {
      this.campaign = this.appService.campaignDetailToView;
      this.appService.campaignDetailToView = null;
    }
    if (this.campaign == null) {
      this.router.navigate(['/catalog/campaign']);
    }
  }
  ngOnInit(): void {
    this.fetchAllCampaignDeliveryReport();
  }


  async fetchAllCampaignDeliveryReport() {
    this.isLoading = true;

    let param = {
      campaignId: this.campaign['_id']
    };
    try {
      let URL = `${webApi.domain + webApi.endPoint.fetchCampaignDeliveryReport}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCampaignDeliveryReport = data['result'];
          this.isLoading = false;
        } else {
          this.listOfCampaignDeliveryReport = [];
        } this.isLoading = false;

      });
    } catch (error) {
      this.listOfCampaignDeliveryReport = [];
      this.isLoading = false;

    }
  }

  getOnlyTime(time: any) {
    let times = String(time).split('.');
    if (times.length == 1) {
      times.push('0');
    }
    return new Date(new Date(new Date().setHours(parseInt(times[0]), parseInt(String(times[1]).length > 1 ? times[1] : times[1] + '0'), 0, 0)).toISOString());
  }
  getDate(campaignDateTime: any) {
    return new Date(campaignDateTime).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  }

}
