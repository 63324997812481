import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { EnroledUser } from '../../courses/course-user-list/course-user-list.component';
import { DeleteModuleActivityDialogComponent } from '../../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';
import { AddBatchAnnouncementComponent } from '../add-batch-announcement/add-batch-announcement.component';

@Component({
  selector: 'app-batch-announcement',
  templateUrl: './batch-announcement.component.html',
  styleUrls: ['./batch-announcement.component.scss']
})
export class BatchAnnouncementComponent implements OnInit {

  batchId: String = '';
  p: any = 1;
  count: any = 50;
  skip: any = 0;
  limit: any = 500;
  totalCount: any = 0;
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    console.log('Called Constructor');
    this.routes.params.subscribe((params) => {
      this.batchId = params['batchId'];
    });
  }

  userDetail: any;
  httpHeaders: any;
  isLoading: boolean = true;
  myHeaders = new Headers();
  listOfAnnouncementToDisplay: any = [];
  listOfAnnouncement: any = [];
  isLoadingExtraData: boolean = false;
  isLoadingData: boolean = false;

  backToCourse() {
    this.router.navigate(['/catalog/courses']);
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchBatchAnnouncements();
  }

  async fetchBatchAnnouncements() {
    this.listOfAnnouncement = [];

    const URL = `${webApi.domain + webApi.endPoint.fetchBatchAnnouncements}/${this.batchId}/${this.skip}/${this.limit}`;
    const param = {
      limit: this.limit,
      skip: this.skip,
      batchId: this.batchId, latest: "false"
    };
    await this.as
      .fetchDetailViaGet(URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.listOfAnnouncement = data['result'];
          this.totalCount = data['count'];

          this.listOfAnnouncementToDisplay = this.listOfAnnouncement;
        } else {
          this.isLoading = false;
        }
      });
  }

  async fetchExtraUsers(isLoadingExtraData: boolean) {
    const URL = `${webApi.domain + webApi.endPoint.fetchBatchAnnouncements}/${this.batchId}/${this.skip}/${this.limit}`;
    const param = {
      limit: this.limit,
      skip: this.skip,
      batchId: this.batchId, latest: "false"
    };
    this.isLoadingExtraData = isLoadingExtraData;
    await this.as
      .fetchDetailViaGet(URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          data['result'].forEach((data: any) => {
            this.listOfAnnouncement.push(data);
          });
          this.listOfAnnouncementToDisplay = this.listOfAnnouncement;
        } else {
          this.isLoading = false;
        }
      });
  }

  pageChanged(event: any) {
    this.p = event;
    console.log(event);

    if (this.p * this.count >= this.listOfAnnouncement.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraUsers(false);
    } else if (this.p * this.count >= this.listOfAnnouncement.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraUsers(true);
    }


  }

  addEditAnnouncement(announcement: any) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { announcement, batchId: this.batchId };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(AddBatchAnnouncementComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
            this.limit = 250;
            this.skip = 0;
            this.p = 1;
            this.fetchBatchAnnouncements();
          }
        }
      }
    );
  }



  deleteAnnouncement(announcement: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { announcement, source: 'Delete Announcement' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          announcementId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.updateBatchAnnouncementStatus
          }`;
        await this.as
          .fetchDetail(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfAnnouncementToDisplay.forEach((announcement: any, i: number) => {
                if (announcement['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfAnnouncementToDisplay.splice(index, 1);
              this.listOfAnnouncement = [...this.listOfAnnouncementToDisplay];

              this.showSuccessToaster('Announcement Deleted Successfully', 'Success');
            } else {
              this.showWarningToaster(
                'Error Occurred while deleting announcement. Try again',
                'Warning'
              );
            }
          });
      }
    });
  }

  updateAnnouncementStatus(announcement: any, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { announcement, source: 'Active Announcement' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          announcementId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.updateBatchAnnouncementStatus
          }`;
        await this.as
          .fetchDetail(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfAnnouncement.forEach((announcement: any, index: number) => {
                if (announcement['_id'] == res['value']['_id']) {
                  announcement.isActive = isActive;
                }
              });
              this.listOfAnnouncementToDisplay = this.listOfAnnouncement;
              if (isActive) {
                this.showSuccessToaster(
                  'Annoucement Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Annoucement Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showWarningToaster(
                'Error Occurred while updating Annoucement. Try again',
                'Warning'
              );
            }
          });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
