import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-update-status-dialog',
  templateUrl: './update-status-dialog.component.html',
  styleUrls: ['./update-status-dialog.component.scss'],
})
export class UpdateStatusDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  selectedStatus: any;

  constructor(
    public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    
  }

  ngOnInit(): void {}

  selectStatus(target: any) {
    this.selectedStatus = target.value;
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  updateStatus() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      result: this.selectedStatus,
    });
  }
}
