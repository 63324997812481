import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { SocketioService } from '../../community/socketio.service';

@Component({
  selector: 'app-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss']
})
export class PostCommentComponent implements OnInit {


  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = true;
  isActivityLoading: boolean = false;
  isPostCommentLoading: boolean = false;

  comment: string = '';
  activity: any = null;
  userDetail: any;
  httpHeaders: any;

  // Image 
  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImageUrl: any;
  selectedFileContentExtension: any = null;


  listOfModule: any = [];
  listOfActivity: any = [];
  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  communityDetail: any;
  constructor(
    public dialogRef: MatDialogRef<PostCommentComponent>, public socketService: SocketioService,
    public service: AppService, private formBuilder: FormBuilder, private toastr: ToastrService,
    public appService: AppService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });


  }
  ngOnInit(): void {

    this.fetchModulesByCourseId();
  }

  async fetchModulesByCourseId() {
    try {
      this.isLoading = true;
      this.listOfModule = [];
      const param = {
        course: this.data['course'],
        admin: false,
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchModulesByCourseId}`;
      await this.appService
        .fetchDetail(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfModule = data['result'];
            this.listOfModule.sort((b: any, a: any) => b.position - a.position);
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
    }
  }

  moduleSelected(event: any) {
    console.log(event.target.value);
    this.fetchActivityByModuleId(event.target.value);
  }
  async fetchActivityByModuleId(module: any) {
    try {
      this.isActivityLoading = true;
      this.listOfActivity = [];
      const param = {
        module: module,
        admin: true,
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchActivityByModuleId}`;
      await this.appService
        .fetchDetail(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfActivity = data['result'];
            this.listOfActivity.sort((b: any, a: any) => b.position - a.position);
          }
          this.isActivityLoading = false;
        });
    } catch (error) {
      this.isActivityLoading = false;

    }
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  removeImage() {
    this.selectedImage = null;
    this.selectedImageName = null;
    this.defaultImageUrl = '';
  }
  selectImageFile(event: any) {
    this.selectedImage = event.target.files[0];
    this.selectedImageName = this.selectedImage['name'];
    this.selectedFileContentExtension = String(this.selectedImage['name']).split('.')[String(this.selectedImage['name']).split('.').length - 1];

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultImageUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedImage);

  }

  onSubmit() {
    this.isPostCommentLoading = true;

    if (this.comment == '') {
      // return false;
    }
    let contentType = 'Text';
    if (this.selectedFileContentExtension == 'mp3') {
      contentType = 'Audio';
    } else if (this.selectedFileContentExtension == 'mp4') {
      contentType = 'Video';
    } else if (this.selectedFileContentExtension == "doc" ||
      this.selectedFileContentExtension == "pdf" ||
      this.selectedFileContentExtension == "docx" ||
      this.selectedFileContentExtension == "csv" ||
      this.selectedFileContentExtension == "xls" ||
      this.selectedFileContentExtension == "ods" ||
      this.selectedFileContentExtension == "xlsx") {
      contentType = 'Document';
    } else if (this.selectedFileContentExtension == "png" ||
      this.selectedFileContentExtension == "jpg" ||
      this.selectedFileContentExtension == "jpeg" ||
      this.selectedFileContentExtension == "gif") {
      contentType = 'Image';
    }
    if (this.selectedImage != null) {
      this.formdata.set('url', this.selectedImage);
    }
    let content: any = {
      type: contentType,
      docName: this.selectedImageName ?? '',
      text: this.comment
    };


    this.formdata.set('course', this.data['course']);
    this.formdata.set('content', JSON.stringify(content));
    this.formdata.set('type', 'Comment');
    this.formdata.set('activity', this.activity);

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.createForumMessage}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isPostCommentLoading = false;
                this.closeActivityAtDialog.emit({ result: true })
                this.dialogRef.close();
                this.comment = '';
                this.showSuccessToaster('Comment Posted successfully.', 'Success');

              } else {
                this.isPostCommentLoading = false;
                this.showWarningToaster('Unable to post Comment.', 'Warning');
              }
            });
          }
        })
        .catch((error) => {
          this.showWarningToaster('Unable to post Comment.', 'Warning');
          this.isPostCommentLoading = false;
          console.log('error', error);
        });

      return;
    } catch (error) {
      this.showWarningToaster('Unable to post Comment.', 'Warning');
      this.isPostCommentLoading = false;
    }
    return;
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
