import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTgadvisoryUserComponent } from './list-tgadvisory-user/list-tgadvisory-user.component';
import { TgadvisoryUserComponent } from './tgadvisory-user.component';
import { TgadvisoryUserRoutingModule } from './tgadvisory-user-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { JwPaginationModule } from 'jw-angular-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddEditTgadvisoryUserComponent } from './add-edit-tgadvisory-user/add-edit-tgadvisory-user.component';



@NgModule({
  declarations: [ListTgadvisoryUserComponent,TgadvisoryUserComponent, AddEditTgadvisoryUserComponent],
  imports: [
    CommonModule,
    TgadvisoryUserRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxPaginationModule,
    JwPaginationModule,
    SidebarModule.forRoot(),NgMultiSelectDropDownModule,
    MatNativeDateModule,
  ]
})
export class TgadvisoryUserModule { }
