import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(
    public as: AppService,
    private router: Router,
    public routes: ActivatedRoute
  ) {
    this.routes.queryParams.subscribe((params) => {
      this.back = params['back'];
    });
  }

  ngOnInit(): void {}
  back: boolean = true;

  backToHomePage() {
    this.router.navigate([this.as.sidebarOptions[0]['route']]);
  }
}
