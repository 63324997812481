<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-help-video *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-help-video>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center" style="justify-content: space-between;">

                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;cursor: pointer;">
                        Help Videos
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">
                        <div class="col-md-2" style="text-align: center;">
                            <button class="add-new-emp" (click)="addNewHelp()">
                                Add Video
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfHelpToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Help found
                </div>
            </div>
            <div *ngIf="listOfHelpToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;text-align: left;padding-left:2rem;" data-order="desc"
                            data-name="code" scope="col" class="table-heading-content">
                            Sr.No</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Name</th>


                        <th style="cursor: pointer;" data-order="desc" data-name="helpQty" scope="col"
                            class="table-heading-content">
                            Link</th>


                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Created Date
                        </th>

                        <th style="cursor: pointer;" data-order="desc" data-name="description" scope="col"
                            class="table-heading-content">Active
                        </th>
                        <th class="table-heading-content">Action</th>

                    </thead>
                    <!-- <div style="height: 0.6em;"> </div> -->
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let help of listOfHelpToDisplay | paginate: { itemsPerPage: count, currentPage: p };let i=index">
                            <th class="table-data-content" style="text-align: left;padding-left:2rem">
                                {{i+1}}</th>
                            <th class="table-data-content">{{help.name}}</th>
                            <th class="table-data-content">{{help.content}}</th>
                            <th class="table-data-content">{{help.createdAt |date}}</th>
                            <th class="table-data-content">
                                <img *ngIf="help.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(help,false)" slot="start" />
                                <img *ngIf="!help.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(help,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">
                                    <img (click)="editHelp(help)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img (click)="deleteHelp(help)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>


        </div>
    </ng-sidebar-container>
</div>