<div *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
</div>
<div *ngIf="!isLoading" style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon" style="font-size: 1rem;">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Details</h2>
    <hr>
    <div class="row" style="margin-top:1rem;">
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Title: </span> {{advisoryDetails['title']}}
        </div>
        
        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Trading Type: </span> {{advisoryDetails['advisoryType']}}
        </div>
        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Entry Point: </span> {{advisoryDetails['entryPoint']}}
        </div>
        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Number of Shares: </span> {{advisoryDetails['noOfShares']}}
        </div>
        <div *ngIf="advisoryDetails['type']=='Trading'">
            <div class="col-md-12" style="margin-bottom: 1em;"
                *ngFor="let target of advisoryDetails['targets'];let i=index">
                <span style="font-size: 15px;font-weight: 500;">Target {{i+1}}: </span>{{target}}
            </div>
        </div>
        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Stop Loss: </span> {{advisoryDetails['stopLoss']}}
        </div>
       
        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Trade Result: </span> {{advisoryDetails.tradeResult==null ||advisoryDetails.tradeResult==undefined? 'N.A.':advisoryDetails.tradeResult}}
        </div>
        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Conclusion </span> {{advisoryDetails.conclusion==0||advisoryDetails.conclusion==null ||advisoryDetails.conclusion==undefined ?'N.A.':advisoryDetails.conclusion}}
        </div>
        
        <hr>

        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 20px;font-weight: 500;"> Expected: </span>
        </div>
        <div *ngIf="advisoryDetails['type']=='Trading'">
            <div class="col-md-12" style="margin-bottom: 1em;"
                *ngFor="let target of advisoryDetails['expectedTargets'];let i=index">
                <span style="font-size: 15px;font-weight: 500;">Target {{i+1}}: </span>{{target}}
            </div>
        </div>
        <div *ngIf="advisoryDetails['type']=='Trading'" class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Loss at Stop Loss: </span>
            {{advisoryDetails['lossAtStopLoss']}}
        </div>

        <div class="col-md-12"
            *ngIf="advisoryDetails['description']!='' && advisoryDetails['description']!=null && advisoryDetails['description']!=undefined">
            <span style="font-size: 15px;font-weight: 500;"> Description : </span> <span
                [innerHtml]="advisoryDetails['description']"></span>
        </div>

        <div *ngIf="advisoryDetails['image']!=''" class="col-md-12" style="display: flex;align-items: center;">
            <img class="prod-img" [src]="advisoryDetails['image']" style="height: 10rem;margin-top: 2rem;" />
        </div>
    </div>