<div class="main-container">
    <div class="row align-center" style="justify-content: space-between;margin-bottom: 2rem;">

        <div class="col-md-4 top-heading" style="cursor: pointer;">
            <span style="font-size: 1.5rem;cursor: pointer;">
                Batches
            </span>
        </div>
        <div class="col-md-8">
            <div class="row align-center">
                <div class="col-md-6" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchBatch($event)">
                </div>
                <div class="col-md-2" style="text-align: center;">
                    <button class="add-new-emp" style="width: 11rem;" (click)="addNewBatch()">
                        Add New Batch
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div>
        <div class="no-course" *ngIf="listOfBatchToDisplay.length==0">
            No batch found
        </div>
        <div class="course-row" *ngIf="listOfBatchToDisplay.length>0">

            <div class="course" *ngFor="let batch of listOfBatchToDisplay">
                <!--  (click)="gotoCourseEdit(batch)" -->
                <div class="upper1">
                    <img [src]="batch['image']==''? '../../../assets/img/placeholder-course.jpg':batch['image']"
                        class="course-image" alt="Offer image">

                </div>
                <div class="course-content">
                    <h1 class="name">{{batch['title']}}</h1>

                    <div class="publish-row">
                        <div class="created">Created date: {{batch['createdAt'] | date}}</div>
                        <div>
                            <div [ngClass]="{'status':batch['isActive'],'inactive-status':!batch['isActive']}">
                                {{batch['isActive']? "Published":"Unpublished"}}</div>
                        </div>
                    </div>
                    <div style="display: flex;justify-content:space-between;margin: 10px 0px;">
                        <div class="price">Paid: {{('Price:\
                            \u20b9'+batch['sellingPrice'])}} <span
                                style="color: #6B6C75;text-decoration: line-through;">{{(' \
                                \u20b9'+batch['listingPrice'])}}</span>
                        </div>
                        <!-- <p class="rating">Position: {{batch['position']}}</p> -->
                    </div>

                    <div class="publish">
                        <div class="view-enrolled-user" (click)="courseUserList(batch['_id'])">
                            View Enrolled User
                        </div>
                        <div class="view-enrolled-user" (click)="viewAnnouncement(batch['_id'])">
                            Announcement
                        </div>
                    </div>
                    <div style="border-top: dotted 1px black;margin: 10px 0px;"></div>

                    <div class="row" style="justify-content: space-around;">
                        <div class="icon-div" (click)="gotoBatchEdit(batch)">
                            <img src="../../../../assets/img/batch-edit.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" style="top: 43px;" (click)="deleteBatch(batch)">
                            <img src="../../../../assets/img/batch-delete.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="batch.isActive" style="top: 82px;"
                            (click)="updateBatchStatus(batch)">
                            <img src="../../../../assets/img/batch-publish.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="!batch.isActive" style="top: 82px;"
                            (click)="updateBatchStatus(batch)">
                            <img src="../../../../assets/img/batch-unpublish.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="activities-div" style="top: 82px;" (click)="gotoActivity(batch)">
                            Assessment/Homework
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>