import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-ticker',
  templateUrl: './add-edit-ticker.component.html',
  styleUrls: ['./add-edit-ticker.component.scss'],
})
export class AddEditTickerComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();
  selectedTickerImage: any = null;
  selectedTickerImageName: any = null;
  defaultTickerImage: any;

  isLoading: boolean = false;
  listOfRoutes = [];
  selectedRoute: string = '';
  selectedType: string = '';
  sponsored: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  titleControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  routeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  typeControl = new FormControl('Select Type', [Validators.required]);
  positionControl = new FormControl('', [Validators.required]);

  addTickerForm: FormGroup = new FormGroup({
    title: this.titleControl,
    route: this.routeControl,
    type: this.typeControl,
    position: this.positionControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.fetchRoutes();
    this.addTickerForm = this.formBuilder.group({
      title: this.titleControl,
      route: this.routeControl,
      type: this.typeControl,
      position: this.positionControl,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addTickerForm = this.formBuilder.group({
        title: this.titleControl,
        route: this.routeControl,
        type: this.typeControl,
        position: this.positionControl,
      });

      this.addTickerForm.get('title')?.setValue(this.dataToEdit['title']);
      this.addTickerForm.get('route')?.setValue(this.dataToEdit['route']);
      this.addTickerForm.get('type')?.setValue(this.dataToEdit['type']);
      this.addTickerForm.get('position')?.setValue(this.dataToEdit['position']);
      this.selectedType = this.dataToEdit['type'];
      this.defaultTickerImage =
        this.dataToEdit['icon'] == null ||
        this.dataToEdit['icon'] == '' ||
        this.dataToEdit['icon'] == undefined
          ? null
          : this.dataToEdit['icon'];
    }
  }

  async fetchRoutes() {
    this.isLoading = true;
    this.listOfRoutes = [];
    let param = {
      type: 'BannerWeb',
    };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchAppConfig}`;
    await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfRoutes = data['result']['value'];
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  typeSelected(event: any) {
    this.selectedType = event.target.value;
    this.addTickerForm.get('route')?.setValue('');
  }

  routeChangeHandler(event: any) {
    this.addTickerForm.get('route')?.setValue(event.value);
    this.selectedRoute = event.value;
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayAdhaar(event: any) {
    this.selectedTickerImage = event.target.files[0];
    this.selectedTickerImageName = this.selectedTickerImage['title'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultTickerImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedTickerImage);
  }

  addEditBrand() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('tickerId', this.dataToEdit['_id']);
      this.formdata.append('icon', JSON.stringify(this.dataToEdit['icon']));
    } else {
      this.formdata.append('tickerId', '0');
    }

    if (this.selectedTickerImage != null) {
      this.formdata.append('icon', this.selectedTickerImage);
    }

    this.formdata.append('title', this.addTickerForm.get('title')?.value);
    this.formdata.append('route', this.addTickerForm.get('route')?.value);
    this.formdata.append('type', this.addTickerForm.get('type')?.value);
    this.formdata.append('position', this.addTickerForm.get('position')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateTicker}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addTickerForm.reset();
                this.selectedRoute = '';
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
