<div mat-dialog-title
    style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
    <div style="font-size: 18px;font-weight: 600;">
        {{data['source']['isPin']? 'Unpin':'Pin'}} Community
    </div>
    <div>
        <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
    </div>
</div>
<mat-dialog-content style="max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
    <div class="container__filter">
        Are you sure you want to {{data['source']['isPin']? 'Unpin':'Pin'}} this Community?
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-1" type="button" (click)="close()">
            No
        </button>
        <button class="btn btn-2" type="button" (click)="pinUnpinCommunity()">
            Yes
        </button>
    </div>

</mat-dialog-actions>