<h2 mat-dialog-title style="font-size: 18px;font-weight: 600;padding: 1em 1.4em  0em  1.4em !important;">Apply Filter
</h2>
<mat-dialog-content
  style="max-height: 45vh;min-height: 43vh;overflow-y: scroll;max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
  <div class="container__filter" *ngIf="!isLoading">
    <form class="container__filter__form" [formGroup]="filterForm" *ngIf="!isLoading">

      <div class="br mb" style="margin-bottom: 1rem">
        <label class="label mb" for>Group Type</label>
        <div class=" mb">
          <ng-multiselect-dropdown class="multi-select" style="width: 100%" [placeholder]="'Select Group Type'"
            [ngModelOptions]="{ standalone: true }" [settings]="statusSettings" [data]="listOfType"
            [(ngModel)]="selectedTypeList">
          </ng-multiselect-dropdown>

        </div>
      </div>
     
      <!-- <div class="br mb" style="margin-bottom: 1rem">
        <label class="label mb" for>Registeration End Date</label>
        <div class=" mb">
          <input type="date" class="input-style" placeholder="Enter State" [ngModelOptions]="{standalone: true}"
            [min]="minEndDate" (input)="endDateChange($event)" [(ngModel)]="endDate">
        </div>
      </div> -->




    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
  <div style="display: flex; gap: 1rem">
    <button class="btn btn-1" type="button" (click)="clearFilter()">
      Clear
    </button>
    <button class="btn"
      [ngClass]="{'btn-2-enabled':selectedTypeList.length>0,'btn-2-disabled':selectedTypeList.length==0 }"
      type="button" (click)="selectedTypeList.length>0?onSubmit():null">
      Apply
    </button>
  </div>

</mat-dialog-actions>