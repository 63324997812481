import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-audit-log-value',
  templateUrl: './view-audit-log-value.component.html',
  styleUrls: ['./view-audit-log-value.component.scss']
})
export class ViewAuditLogValueComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();


  isLoading: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<ViewAuditLogValueComponent>,
    public appService: AppService,
    public routes: ActivatedRoute, private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);

  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
}
