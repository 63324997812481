import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-enrol-course',
  templateUrl: './view-enrol-course.component.html',
  styleUrls: ['./view-enrol-course.component.scss']
})
export class ViewEnrolCourseComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ViewEnrolCourseComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  //Coupons
  deleteCoupon() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Coupon',
      value: this.data['coupon'],
    });
  }

}
