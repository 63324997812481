import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WA_API } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-campaign-detail',
  templateUrl: './view-campaign-detail.component.html',
  styleUrls: ['./view-campaign-detail.component.scss'],
})
export class ViewCampaignDetailComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  windowRef: any;
  userDetails: any;

  isLoading: boolean = true;
  campaignViewDetail: any;
  campaignDetail: any;

  constructor(
    public dialogRef: MatDialogRef<ViewCampaignDetailComponent>,
    private toastr: ToastrService,
    public as: AppService,
    public appService: AppService,
    public routes: ActivatedRoute,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.campaignDetail = this.data['campaign'];
    this.windowRef = this.as.windowRef;
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  ngOnInit(): void {
    this.isLoading=false;
    // this.getcampaignViewDetail();
  }
  // async getcampaignViewDetail() {
  //   this.isLoading = true;
  //   this.campaignViewDetail = null;
  //   try {
  //     let param = {
  //       campaignId: this.campaignDetail['_id'],
  //     };

  //     const httpHeaders = new HttpHeaders({
  //       Authorization: `Bearer ${this.userDetails['accessToken']}`,
  //     });
  //     const URL = `${
  //       WA_API.domain + WA_API.endPoint.fetchMessageDetailByCampaignId
  //     }`;
  //     await this.appService
  //       .fetchDetail(param, URL, httpHeaders)
  //       .then((data: any) => {
  //         if (data['success']) {
  //           this.campaignViewDetail = data['result'];
  //           console.log(this.campaignViewDetail);
  //           this.isLoading = false;
  //         } else {
  //           this.isLoading = false;
  //         }
  //       });
  //   } catch (error) {
  //     this.isLoading = false;
  //   }
  // }
  // fetchMessageDetailByCampaignId
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
