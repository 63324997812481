<div class="display-flex align-items-center justify-content-end">
    <div class="close-img-div" (click)="close()">
        <img src="../../../../assets/svgIcons/close.svg" style="height: 1em;" alt="close-image" srcset="">
    </div>
</div>
<div class="main-container">

    <div class="media-div display-flex align-items-center justify-content-center">
        <div *ngIf="!recording" (click)="initiateRecording()" class="start-recording">
            Start Recording
        </div>
        <div *ngIf="recording" (click)="stopRecording()" class="stop-recording">
            Stop Recording
        </div>
    </div>
    <div *ngIf="recording" style="display: flex;align-items: center;justify-content: center;">
        <img style="height: 4em;margin-right: 10px;" src="../../../../assets/img/cound.jpg" alt="">
        <img style="height: 4em;" src="../../../../assets/img/sound.gif" alt="">
        <img style="height: 4em;" src="../../../../assets/img/sound.gif" alt="">
        <img style="height: 4em;" src="../../../../assets/img/sound.gif" alt="">
        <img style="height: 4em;" src="../../../../assets/img/sound.gif" alt="">
    </div>
    <div *ngIf="url!=null && url!=undefined" style="margin-top: 2em;">
        <audio controls>
            <source [src]="sanitize(url)" type="audio/wav">
        </audio>
    </div>
    <div class="fileName">
        {{selectedFileContentName}}
    </div>
    <hr>
    <div class="caption-div">
        <div style="width: 90%;">
            <textarea name="message" id="message" placeholder="Type a message" cols="30" rows="5" class="input-style"
                [(ngModel)]="caption"></textarea>
        </div>
        <button *ngIf="isLoading" class="send-btn">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </button>
        <button *ngIf="!isLoading" class="send-btn" (click)="sendMessage()">Send</button>
    </div>
</div>