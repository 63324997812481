// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBa08SXQh6cIpz6NLUq0iOLjaG76ESLXJA",
    authDomain: "tg-app-a95b2.firebaseapp.com",
    projectId: "tg-app-a95b2",
    storageBucket: "tg-app-a95b2.appspot.com",
    messagingSenderId: "102018769671",
    appId: "1:102018769671:web:a95bb3efdf326e8826dba2",
    measurementId: "G-2L4SGFS6YM"
  },
  SOCKET_ENDPOINT: 'https://apiv2.tusharghone.com/',
  // SOCKET_ENDPOINT: 'http://localhost:7046/'

  ADVISORY_SOCKET_ENDPOINT: 'https://advisory-api.tusharghone.com/',
  // ADVISORY_SOCKET_ENDPOINT: 'http://localhost:8046/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
