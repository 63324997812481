<div class="container__dashboard">
    <div class="row-div">

        <div class="col-md-5">

            <div style="display: flex;align-items: center;    justify-content: flex-start;">
                <mat-form-field appearance="outline">
                    <mat-label>Pick a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [value]="startDate"
                            (dateChange)="dateChanged( 'start',$event)">
                        <input matEndDate placeholder="End date" [value]="endDate"
                            (dateChange)="dateChanged( 'end',$event)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <div (click)="clearDateRange()"
                    style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 15px; margin-bottom: 1.3rem;">
                    Clear
                </div>
            </div>
            <div class="funnel_outer">
                <ul>
                    <!-- <li class="funnel_step_1"><span (click)="stepClickHandler('Step 1')"
                            [ngStyle]="{'border-top-color':isStep1?'#0db6f9eb':'#bbe3f3eb'}">
                            <p>App Install( <span *ngIf="isStep1FunnelDataLoading"> <mat-progress-spinner mode="indeterminate"
                                        diameter="20" style="margin:0 auto;">
                                    </mat-progress-spinner></span> <span
                                    *ngIf="!isStep1FunnelDataLoading">{{step1DataCount.toFixed(0)}}</span> )</p>
                        </span></li> -->
                    <li class="funnel_step_2"><span (click)="stepClickHandler('Step 2')"
                            [ngStyle]="{'border-top-color':isStep2?'#0db6f9eb':'#bbe3f3eb'}">
                            <div>Sign Up(
                                <p *ngIf="isStep2FunnelDataLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                                    </mat-progress-spinner>
                                </p>
                                <p *ngIf="!isStep2FunnelDataLoading">
                                    {{step2DataCount.toFixed(0)}}
                                </p> )
                            </div>
                        </span> </li>
                    <li class="funnel_step_3"><span (click)="stepClickHandler('Step 3')"
                            [ngStyle]="{'border-top-color':isStep3?'#0db6f9eb':'#bbe3f3eb'}">
                            <div>Profiling & Drop out
                                (
                                <p *ngIf="isStep3FunnelDataLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                                    </mat-progress-spinner>
                                </p>
                                <p *ngIf="!isStep3FunnelDataLoading">
                                    {{step3DataCount.toFixed(0)}}
                                </p> )
                            </div>
                        </span></li>
                    <li class="funnel_step_4"><span (click)="stepClickHandler('Step 4')"
                            [ngStyle]="{'border-top-color':isStep4?'#0db6f9eb':'#bbe3f3eb'}">
                            <div>Number of Active User
                                (
                                <p *ngIf="isStep4FunnelDataLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                                    </mat-progress-spinner>
                                </p>
                                <p *ngIf="!isStep4FunnelDataLoading">
                                    {{step4DataCount.toFixed(1)}}
                                </p> )
                            </div>
                        </span></li>
                    <li class="funnel_step_5"><span (click)="stepClickHandler('Step 5')"
                            [ngStyle]="{'border-top-color':isStep5?'#0db6f9eb':'#bbe3f3eb'}">
                            <div>Activity Completed
                                (
                                <p *ngIf="isStep5FunnelDataLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                                    </mat-progress-spinner>
                                </p>
                                <p *ngIf="!isStep5FunnelDataLoading">
                                    {{step5DataCount.toFixed(1)}}
                                </p> )
                            </div>
                        </span></li>
                    <li class="funnel_step_6"><span (click)="stepClickHandler('Step 6')"
                            [ngStyle]="{'border-top-color':isStep6?'#0db6f9eb':'#bbe3f3eb'}">
                            <div>Quiz
                                (
                                <p *ngIf="isStep6FunnelDataLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                                    </mat-progress-spinner>
                                </p>
                                <p *ngIf="!isStep6FunnelDataLoading">
                                    {{step6DataCount.toFixed(1)}}
                                </p> )
                            </div>
                        </span></li>
                    <li class="funnel_step_7"><span (click)="stepClickHandler('Step 7')"
                            [ngStyle]="{'border-top-color':isStep7?'#0db6f9eb':'#bbe3f3eb'}">
                            <div>Course Buy
                                (
                                <p *ngIf="isStep7FunnelDataLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                                    </mat-progress-spinner>
                                </p>
                                <p *ngIf="!isStep7FunnelDataLoading">
                                    {{step7DataCount.toFixed(1)}}
                                </p> )
                            </div>
                        </span></li>

                </ul>
            </div>
        </div>
        <div class="col-md-7" style="height: 100%;word-wrap: break-word;overflow-y: scroll;overflow-x: hidden;">
            <div *ngIf="isStep1 || isStep2 || isStep3|| isStep4">
                <div *ngIf="isLoading">
                    <div class="center-loading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div *ngIf="!isLoading">
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div>
                            <span style="font-size: 16px;font-weight: 600;"> Total User Count: </span>
                            <span>{{totalUserCount.toFixed(2)}}</span>
                        </div>
                        <div *ngIf="listOfUser.length==0"
                            style="cursor: no-drop;color: rgba(0, 0, 255, 0.603);text-decoration: underline;font-size: 15px;margin-right: 20px;">
                            <!-- Download list -->
                        </div>
                        <div *ngIf="listOfUser.length>0"
                            style="cursor: pointer;color: blue;text-decoration: underline;font-size: 15px;margin-right: 20px;"
                            (click)="downloadListData()">
                            Download list
                        </div>
                    </div>
                    <div>
                        <table class="table-style" cellspacing="0" cellpadding="0">
                            <thead class="table-heading">
                                <th class="table-heading-content">Sr.No.</th>
                                <th class="table-heading-content">Name</th>
                                <th class="table-heading-content">Phone</th>
                                <th class="table-heading-content">Email</th>
                                <th class="table-heading-content">Date</th>
                                <th class="table-heading-content">Source</th>
                                <th *ngIf="isStep4" class="table-heading-content">Atempt</th>

                            </thead>
                            <div *ngIf="listOfUser.length==0"
                                style="text-align: center;height: 10em;display: flex; align-items: center; justify-content: center;">
                                No Data available
                            </div>
                            <tbody *ngIf="listOfUser.length>0">
                                <tr class="table-data"
                                    *ngFor="let user of listOfUser;let indexOfelement=index;let i=index">
                                    <th class="table-data-content">{{i+1}}
                                    </th>
                                    <th class="table-data-content" style="max-width: 15em;word-break: break-all;">
                                        {{user['fullName']}}</th>
                                    <th class="table-data-content">
                                        {{user['phone']}}</th>
                                    <th class="table-data-content">{{user['email']}}
                                    </th>
                                    <th class="table-data-content">
                                        {{user['createdAt']|date}}
                                    </th>
                                    <th class="table-data-content">
                                        {{user['registrationSource']==null
                                        ||user['registrationSource']==undefined?'N.A.':user['registrationSource'] }}
                                    </th>
                                    <th *ngIf="isStep4" class="table-data-content">
                                        {{user['numberOfOpenAttempt']}}
                                    </th>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="isStep5 || isStep6">
                <div *ngIf="isLoading">
                    <div class="center-loading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div *ngIf="!isLoading">
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div>
                            <span style="font-size: 16px;font-weight: 600;"> Total User Count: </span>
                            <span>{{totalUserCount.toFixed(2)}}</span>
                        </div>
                        <div *ngIf="listOfUser.length==0"
                            style="cursor: no-drop;color: rgba(0, 0, 255, 0.603);text-decoration: underline;font-size: 15px;margin-right: 20px;">
                            <!-- Download list -->
                        </div>
                        <div *ngIf="listOfUser.length>0"
                            style="cursor: pointer;color: blue;text-decoration: underline;font-size: 15px;margin-right: 20px;"
                            (click)="downloadListData()">
                            Download list
                        </div>
                    </div>
                    <div>
                        <table class="table-style" cellspacing="0" cellpadding="0">
                            <thead class="table-heading">
                                <th class="table-heading-content">Sr.No.</th>
                                <th class="table-heading-content">Name</th>
                                <th class="table-heading-content">Phone</th>
                                <th class="table-heading-content">Email</th>
                                <th class="table-heading-content">Date</th>
                                <th class="table-heading-content">Source</th>
                                <th class="table-heading-content">{{isStep6?'Quiz':'Activity'}} Completed</th>

                            </thead>
                            <div *ngIf="listOfUser.length==0"
                                style="text-align: center;height: 10em;display: flex; align-items: center; justify-content: center;">
                                No Data available
                            </div>
                            <tbody *ngIf="listOfUser.length>0">
                                <tr class="table-data"
                                    *ngFor="let user of listOfUser;let indexOfelement=index;let i=index">
                                    <th class="table-data-content">{{i+1}}
                                    </th>
                                    <th class="table-data-content" style="max-width: 15em;word-break: break-all;">
                                        {{user['user'][0]['fullName']}}</th>
                                    <th class="table-data-content">
                                        {{user['user'][0]['phone']}}</th>
                                    <th class="table-data-content">{{user['user'][0]['email']}}
                                    </th>
                                    <th class="table-data-content">
                                        {{user['user'][0]['createdAt']|date}}
                                    </th>
                                    <th class="table-data-content">
                                        {{user['user'][0]['registrationSource']==null
                                        ||user['user'][0]['registrationSource']==undefined?'N.A.':user['user'][0]['registrationSource']
                                        }}
                                    </th>
                                    <th class="table-data-content">
                                        {{user['totalCount']}}
                                    </th>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="isStep7">
                <div *ngIf="isLoading">
                    <div class="center-loading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div *ngIf="!isLoading">
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div>
                            <span style="font-size: 16px;font-weight: 600;"> Total User Count: </span>
                            <span>{{totalUserCount.toFixed(2)}}</span>
                        </div>
                        <div *ngIf="listOfUser.length==0"
                            style="cursor: no-drop;color: rgba(0, 0, 255, 0.603);text-decoration: underline;font-size: 15px;margin-right: 20px;">
                            <!-- Download list -->
                        </div>
                        <div *ngIf="listOfUser.length>0"
                            style="cursor: pointer;color: blue;text-decoration: underline;font-size: 15px;margin-right: 20px;"
                            (click)="downloadListData()">
                            Download list
                        </div>
                    </div>
                    <div>
                        <table class="table-style" cellspacing="0" cellpadding="0">
                            <thead class="table-heading">
                                <th class="table-heading-content">Sr.No.</th>
                                <th class="table-heading-content">Name</th>
                                <th class="table-heading-content">Phone</th>
                                <th class="table-heading-content">Email</th>
                                <th class="table-heading-content">Date</th>
                                <th class="table-heading-content">Course</th>

                            </thead>
                            <div *ngIf="listOfUser.length==0"
                                style="text-align: center;height: 10em;display: flex; align-items: center; justify-content: center;">
                                No Data available
                            </div>
                            <tbody *ngIf="listOfUser.length>0">
                                <tr class="table-data"
                                    *ngFor="let user of listOfUser;let indexOfelement=index;let i=index">
                                    <th class="table-data-content">{{i+1}}
                                    </th>
                                    <th class="table-data-content" style="max-width: 15em;word-break: break-all;">
                                        {{user['user'][0]['fullName']}}</th>
                                    <th class="table-data-content">
                                        {{user['user'][0]['phone']}}</th>
                                    <th class="table-data-content">{{user['user'][0]['email']}}
                                    </th>
                                    <th class="table-data-content">
                                        {{user['user'][0]['createdAt']|date}}
                                    </th>
                                    <th class="table-data-content">
                                        {{user['course']==null
                                        ||user['course']==undefined?'N.A.':user['course'][0]['courseName']
                                        }}
                                    </th>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>