<div class="main-container">

    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height:92vh;">

        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" >
            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [closeOnClickOutside]="true" [dockedSize]="'50px'"
                [closeOnClickOutside]="closeOnClickOutside" [closeOnClickBackdrop]="closeOnClickBackdrop"
                [showBackdrop]="showBackdrop" [animate]="animate" [trapFocus]="trapFocus" [autoFocus]="autoFocus"
                [ariaLabel]="'My sidebar'" style="overflow: scroll !important;">

                <div *ngIf="callAddActivity">

                    <app-add-activity [moduleDetail]="moduleDetail" [detailToEdit]="activityToEdit"
                        [activityCount]="listOfActivity.length" [actionToPerform]="selectedActivityType"
                        (closeSideForm)="toggleClose($event)" (warningSidebar)="showWarningToUser()">
                    </app-add-activity>
                </div>
            </ng-sidebar>
            <div ng-sidebar-content *ngIf="!isLoading" style="margin-left: 4em;margin-right: 4em;position: relative;">
                <div style="position: relative;margin-bottom: 2em;">
                    <div class="row" style="justify-content:space-between;align-items: center;">
                        <div class="goback" (click)="backToModule()">
                            <mat-icon style="width: 15px;margin-right: -7px;font-size: 12px;">
                                arrow_back_ios
                            </mat-icon>
                            Back to Module
                        </div>
                        <div class="add-module-text " (click)="addNewActivityClicked() ">
                            Add new Activity
                            <mat-icon class="add-module-icon ">keyboard_arrow_down</mat-icon>
                            <!-- <p style="padding: .4rem;margin: 0;">Add new Activity</p> -->

                        </div>
                    </div>
                </div>
                <div *ngIf="showAddDropDown " class="drp-style ">
                    <div style="width:8em;background-color: white;height:4em;z-index: 100; ">
                        <div (click)="addNewActivity('Video') " class="drp-item ">
                            <img class="svg-style " src="../../../../assets/svgIcons/video.svg " alt=" " srcset=" ">
                            Video
                        </div>
                        <div (click)="addNewActivity('Quiz') " class="drp-item ">
                            <img class="svg-style " src="../../../../assets/svgIcons/quiz.svg " alt=" " srcset=" "> Quiz
                        </div>
                    </div>
                </div>
                <div class="no-data-available " *ngIf="listOfActivity.length==0 ">
                    <div>
                        No Activity Available. Start Adding new activity
                    </div>
                </div>
                <div *ngIf="listOfActivity.length>0">
                    <div *ngFor="let activity of listOfActivity" (click)="closeshowAddDropDown()">
                        <div class="row module-box">
                            <div class="col-md-8" style="font-size: small;font-weight: 600;word-break: break-all;">
                                <div style="display: flex;">
                                    <div>
                                        <img class="svg-style-activity-icon"
                                            [src]="activity['activityType']=='Quiz'?'../../../../assets/svgIcons/quiz.svg':'../../../../assets/svgIcons/video.svg'"
                                            alt="" srcset="">
                                    </div>
                                    <span>
                                        {{activity['name']}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4" style="display:flex;justify-content:space-around;">
                                <div *ngIf="!activity['isChangingStatus']" class="delete-module"
                                    (click)="changeActivieStatus(activity)">
                                    <img class="svg-style-action-icon"
                                        [src]="activity['isActive']?'../../../../assets/svgIcons/visibility.svg':'../../../../assets/svgIcons/hide.svg'"
                                        alt="" srcset="">
                                    <span>
                                        {{activity['isActive']?'Published':'Unpublished'}}
                                    </span>

                                </div>
                                <div *ngIf="activity['isChangingStatus']" class="delete-module">
                                    <mat-progress-spinner mode="indeterminate" diameter="20"
                                        style="margin:0 auto;height:20px;width:20px;color: white;">
                                    </mat-progress-spinner>
                                </div>
                                <div *ngIf="!activity['isDeleting']" class="delete-module"
                                    (click)="deleteActivity(activity)">
                                    <img class="svg-style-action-icon" src="../../../../assets/svgIcons/delete.svg"
                                        alt="" srcset="">
                                    <span>
                                        Delete
                                    </span>
                                </div>
                                <div *ngIf="activity['isDeleting']" class="delete-module">
                                    <mat-progress-spinner mode="indeterminate" diameter="20"
                                        style="margin:0 auto;height:20px;width:20px;color: white;">
                                    </mat-progress-spinner>
                                </div>
                                <div class="edit-module" (click)="editActivity(activity)">
                                    <img class="svg-style-action-icon" src="../../../../assets/svgIcons/edit.svg" alt=""
                                        srcset="">
                                    <span>
                                        Edit
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 10em;">

                    </div>
                </div>
            </div>
        </ng-sidebar-container>
    </div>
</div>