import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewOffersComponent } from './add-new-offers/add-new-offers.component';
import { MainSpecialOffersComponent } from './main-special-offers/main-special-offers.component';

const routes: Routes = [
  {
    path: '',
    component: MainSpecialOffersComponent,
  },
  {
    path: 'new-offer',
    component: AddNewOffersComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'catalog/specialOffers' },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SpecialOffersRoutingModule {}
