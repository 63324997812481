import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-course-detail',
  templateUrl: './add-course-detail.component.html',
  styleUrls: ['./add-course-detail.component.scss'],
})
export class AddCourseDetailComponent implements OnInit {
  @Input() courseDetailToEdit: any;
  @Output() moveToActivity: EventEmitter<any> = new EventEmitter();

  listOfModule: any = ['Hllo'];
  listOfTags: any = [];

  listOfRequirement: any = [];
  radioSelectedValue: any;
  selectedAuthorId: any;
  showPriceBox: boolean = false;
  selectedFile: any = null;
  listOfAuthorToDisplay: any = [];
  listOfAuthor: any = [];
  selectedFileName: any = 'No File Selected';
  myHeaders = new Headers();
  formdata = new FormData();
  url: any;
  format: any;
  isAddindCourse: boolean = false;
  isAddindSuccessFully: boolean = false;
  isLoading: boolean = true;
  defaultCoverImage =
    'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
  courseDetail: any;

  userDetails: any;
  httpHeaders: any;

  tagFormControl = new FormControl('');
  requirementFormControl = new FormControl('');
  courseNameFormControl = new FormControl('', [Validators.required]);
  courseSlugFormControl = new FormControl('', [Validators.required]);
  coursePriceFormControl = new FormControl('');
  courseCommissionFormControl = new FormControl('');
  // courseWPIDFormControl = new FormControl('', [Validators.required]);
  authorNameFormControl = new FormControl('', [Validators.required]);
  courseLevelFormControl = new FormControl('', [Validators.required]);
  courseLanguageControl = new FormControl('', [Validators.required]);
  coursePeriodControl = new FormControl('', [Validators.required]);
  learnFormControl = new FormControl('', [Validators.required]);
  positionControl = new FormControl('', [Validators.required]);
  descriptionFormControl = new FormControl('', [Validators.required]);
  addModule: boolean = false;

  courseForm: FormGroup = new FormGroup({
    tag: this.tagFormControl,
    requirement: this.requirementFormControl,
    courseName: this.courseNameFormControl,
    courseSlug: this.courseSlugFormControl,
    authorName: this.authorNameFormControl,
    level: this.courseLevelFormControl,
    language: this.courseLanguageControl,
    learn: this.learnFormControl,
    coursePeriod: this.coursePeriodControl,
    courseDescription: this.descriptionFormControl,
    position: this.positionControl,
    courseCommission: this.courseCommissionFormControl,
  });
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {}
  setValuesForEdit() {
    this.isAddindSuccessFully = true;
    this.courseForm
      .get('courseName')
      ?.setValue(this.courseDetail['courseName']);
    this.courseForm
      .get('courseSlug')
      ?.setValue(this.courseDetail['courseSlug'] ?? '');
    if (this.courseDetail['courseType'] == 'Free') {
      this.radioSelectedValue = 'Free';
      this.showPriceBox = false;
    } else {
      this.radioSelectedValue = 'Paid';
      this.showPriceBox = true;
      this.courseForm
        .get('coursePrice')
        ?.setValue(this.courseDetail['coursePrice']);
    }
    this.courseForm
      .get('courseDescription')
      ?.setValue(this.courseDetail['courseDescription']);
    this.courseForm.get('position')?.setValue(this.courseDetail['position']);
    this.listOfTags = this.courseDetail['tags'];
    this.listOfRequirement = this.courseDetail['requirement'];
    this.courseForm.get('language')?.setValue(this.courseDetail['langauge']);
    this.courseForm
      .get('learn')
      ?.setValue(this.courseDetail['shortDescription']);
    this.courseForm.get('level')?.setValue(this.courseDetail['levels']);
    this.courseForm
      .get('coursePeriod')
      ?.setValue(this.courseDetail['coursePeriod']);
    this.courseForm
      .get('courseCommission')
      ?.setValue(this.courseDetail['courseCommissionRate']);
    this.selectedAuthorId = this.courseDetail['authorId']['_id'];
    this.courseForm
      .get('authorName')
      ?.setValue(this.courseDetail['authorId']['_id']);
    this.defaultCoverImage =
      this.courseDetail['courseImageUrl'] != null
        ? this.courseDetail['courseImageUrl']
        : 'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.courseForm = this.formBuilder.group({
      tag: this.tagFormControl,
      requirement: this.requirementFormControl,
      courseName: this.courseNameFormControl,
      courseSlug: this.courseSlugFormControl,
      coursePrice: this.coursePriceFormControl,
      courseCommission: this.courseCommissionFormControl,
      coursePeriod: this.coursePeriodControl,
      // courseWPId: this.courseWPIDFormControl,
      authorName: this.authorNameFormControl,
      level: this.courseLevelFormControl,
      language: this.courseLanguageControl,
      learn: this.learnFormControl,
      position: this.positionControl,
      courseDescription: this.descriptionFormControl,
    });
    if (this.courseDetailToEdit != null) {
      this.courseDetail = this.courseDetailToEdit;
      this.setValuesForEdit();
    }

    this.fetchAllAuthor();
  }
  onCourseNameChangeEvent(event: any) {
    console.log(event.target.value);
    var data = this.slugify(event.target.value);
    this.courseForm.get('courseSlug')?.setValue(data);
  }
  slugify(string: any) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    // console.log(event);
    if (this.radioSelectedValue == 'Free') {
      this.showPriceBox = false;
    } else {
      this.showPriceBox = true;
    }
  }
  addTags() {
    if (this.courseForm.get('tag')?.value != '') {
      this.listOfTags.push(this.courseForm.get('tag')?.value);
      this.courseForm.get('tag')?.setValue('');
    }
  }

  removeTag(tag: String) {
    this.listOfTags = this.listOfTags.filter((item: String) => item != tag);
  }

  addRequirement() {
    this.listOfRequirement.push(this.courseForm.get('requirement')?.value);
    this.courseForm.get('requirement')?.setValue('');
    // console.log(this.listOfRequirement.toString());
  }

  removeRequirement(tag: String) {
    this.listOfRequirement = this.listOfRequirement.filter(
      (item: String) => item != tag
    );
  }
  async fetchAllAuthor() {
    this.isLoading = true;
    this.listOfAuthorToDisplay = [];
    this.listOfAuthor = [];

    const URL = `${webApi.domain + webApi.endPoint.fetchAuthors}`;
    await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfAuthor = data['result'];
        this.listOfAuthorToDisplay = this.listOfAuthor;
        this.isLoading = false;
      }
    });
  }
  selectionChange(deviceValue: any) {
    // console.log(deviceValue.value);
    this.selectedAuthorId = deviceValue.value;
  }
  displayPhoto(event: any) {
    this.selectedFile = event.target.files[0];
    this.selectedFileName = this.selectedFile['name'];

    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCoverImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }
  getAuthorName(id: any): string {
    let isExists = false;
    let authorName = '';
    this.listOfAuthor.forEach((author: any) => {
      if (author['_id'] == id) {
        isExists = true;
        authorName = author['authorName'];
      }
    });
    if (!isExists) {
      authorName = '';
    }
    return authorName;
  }
  async createEditCourse() {
    this.isAddindCourse = true;
    this.formdata = new FormData();
    if (this.courseDetailToEdit) {
      this.formdata.set('courseId', this.courseDetail['_id']);
    } else {
      this.formdata.set('courseId', '0');
    }
    this.formdata.set('tags', JSON.stringify(this.listOfTags));

    if (this.selectedFile == null) {
      this.formdata.set('courseImageUrl', this.courseDetail['courseImageUrl']);
    } else {
      this.formdata.set(
        'courseImageUrl',
        this.selectedFile,
        this.selectedFileName
      );
    }

    this.formdata.set('courseName', this.courseForm.get('courseName')?.value);
    this.formdata.set('courseSlug', this.courseForm.get('courseSlug')?.value);
    this.formdata.set('position', this.courseForm.get('position')?.value);
    this.formdata.set(
      'courseCommissionRate',
      this.courseForm.get('courseCommission')?.value
    );
    this.formdata.set('courseType', this.radioSelectedValue);
    this.formdata.set(
      'coursePrice',
      this.radioSelectedValue == 'Free'
        ? 0
        : this.courseForm.get('coursePrice')?.value
    );
    this.formdata.set('authorId', this.selectedAuthorId);
    this.formdata.set('levels', this.courseForm.get('level')?.value);
    this.formdata.set('language', this.courseForm.get('language')?.value);
    this.formdata.set(
      'coursePeriod',
      this.courseForm.get('coursePeriod')?.value
    );

    this.formdata.set(
      'courseDescription',
      this.courseForm.get('courseDescription')?.value
    );
    this.formdata.set('shortDescription', this.courseForm.get('learn')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };

    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateCourse}`;

    fetch(URL, requestOptions)
      .then((result) => {
        if (result) {
          this.isAddindCourse = false;
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.courseDetailToEdit = res['result'];
              this.courseDetail = res['result'];
              this.myHeaders = new Headers();
              this.isAddindSuccessFully = true;
              if (this.courseDetailToEdit == null) {
                this.showSuccessToaster(
                  'Course Created Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Course Updated Successfully',
                  'Success'
                );
              }
            } else {
              this.myHeaders = new Headers();
            }
          });
        }
      })
      .catch((error) => console.log('error', error));
    return;
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  gotoModules() {
    this.moveToActivity.emit(false);
  }
}
