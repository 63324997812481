<div class="center-loading" *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
</div>
<div *ngIf="!isLoading" class="main-content">
    <div style="margin: 1em 1em;">
        <div class="row">
            <div class="col-md-12 heading">
                Comment
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <img [src]="courseDetail['courseImageUrl']" class="courseImage">
            </div>
            <div class="col-md-6" style="border: solid 1px black;    padding: 1em 1em;">
                <div style="border-bottom: solid 1px black;">
                    <div style="font-size: 17px;font-weight: 500;">
                        Course Details
                    </div>
                    <div>
                    </div>
                </div>
                <div style="margin-top: 10px;font-size: 13px;color: black;">
                    Course : <span
                        style="font-size: 15px;font-weight: 500;color: gray;margin-left: 10px;">{{courseDetail['courseName']}}</span>
                </div>
                <div style="margin-top: 10px;font-size: 13px;color: black;"
                    *ngIf="selectedActivityInfo!=null && selectedActivityInfo!=undefined">
                    Lesson :<span style="font-size: 15px;font-weight: 500;color: gray;margin-left: 10px;">
                        {{selectedActivityInfo['module']['name']}}</span>
                </div>
                <div style="margin-top: 10px;font-size: 13px;color: black;"
                    *ngIf="selectedActivityInfo!=null && selectedActivityInfo!=undefined">
                    Activity :<span style="font-size: 15px;font-weight: 500;color: gray;margin-left: 10px;">
                        {{selectedActivityInfo['name']}}</span>
                </div>
                <div style="margin-top: 10px;font-size: 13px;color: black;"
                    *ngIf="selectedActivityInfo!=null && selectedActivityInfo!=undefined">
                    Created Date :<span style="font-size: 15px;font-weight: 500;color: gray;margin-left: 10px;">
                        {{selectedActivityInfo['createdAt'] | date}}</span>
                </div>
            </div>
        </div>
        <hr>
        <div class="row" style="justify-content: space-between;">
            <div class="col-md-6" style="color: #3199D8;font-size: 23px;display: flex;align-items: center;">
                Comments <div
                    style="color: #3199D8;padding: 0px 10px;font-size: 20px;background-color: #98CCEC;margin-left: 1em;max-width: 2em;border-radius: 10px;text-align: center;">
                    {{listOfComment.length}} </div>
            </div>
            <div class="col-md-6" style="    display: flex;align-items: center;justify-content: end;">
                <div class="add-comment" (click)="postComment()">
                    Add Comment
                </div>
            </div>
        </div>

        <div class="row comment-div">
            <div class="col-md-12" *ngFor="let comment of listOfComment">
                <div
                    style="display: flex;align-items: flex-start;justify-content: space-between; border-top: solid 1px #DBDBE3;margin-top: 10px;padding-top: 10px;cursor: pointer;">
                    <div style="display: flex;align-items: flex-start;" (click)="selectComment(comment)">
                        <div class="avatar" *ngIf="comment['sentBy']=='Admin'">
                            <img src="../../../../assets/img/tg-team.png"
                                style="height: 100%;width: 100%;border-radius: 100%;">
                        </div>
                        <div class="avatar"
                            *ngIf="comment['sentBy']=='User' && (comment['user']['avatar']!=null && comment['user']['avatar']!=undefined)">
                            <img [src]="comment['user']['avatar']"
                                style="height: 100%;width: 100%;border-radius: 100%;">
                        </div>
                        <div class="no-avatar"
                            *ngIf="comment['sentBy']=='User' && (comment['user']['avatar']==null ||  comment['user']['avatar']==undefined)">
                            {{this.appService.getAvatarText(comment['user']['fullName'])}}
                        </div>
                        <div style="margin-left: 1em;">
                            <div style="display: flex;align-items: center;margin-bottom: 10px;">
                                <div class="name">
                                    {{comment['sentBy']=='Admin'?'TG Team':comment['user']['fullName']}}
                                </div>
                                <div
                                    style="background-color: #D9D9D9;height: 7px;width: 7px;border-radius: 100%;margin-left: 0.75em;">
                                </div>
                                <div style="color: #84858E;font-size: 12px;margin-left: 0.75em;">
                                    {{getTime(comment['createdAt'])}}
                                </div>
                            </div>
                            <div style="color: #515259;font-size: 12px;" *ngIf="comment['content']['type']=='Text'">
                                {{comment['content']['text']}}
                            </div>
                            <div style="color: #515259;font-size: 12px;" *ngIf="comment['content']['type']=='Image'">
                                <img [src]="comment['content']['url']" style="height: 10em;width: 15em;">
                                <div *ngIf="comment['content']['text']!=null && comment['content']['text']!=undefined && comment['content']['text']!=''">
                                    {{comment['content']['text']}}
                                </div>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <div [ngClass]="{'reply':!comment['showInputField'],'reply-on':comment['showInputField']}"
                                    (click)="showReplyInputHandler(comment._id)">
                                    Reply
                                </div>
                                <div
                                    style="background-color: #D9D9D9;height: 7px;width: 7px;border-radius: 100%;margin-left: 0.75em;">
                                </div>
                                <div [ngStyle]="{'color':comment.replyCount==0?'#AAABB5':'#3199D8'}"
                                    style="cursor: pointer;font-size: 11px;font-weight: 500;display: flex;align-items: center;margin-left: 1em;">
                                    <div>
                                        {{comment.replyCount}}
                                    </div>
                                    <div style="margin-left: 1em;display: flex;align-items: center;">
                                        <div (click)="getAllReplies(comment._id)"
                                            style="display: flex;align-items: center;">
                                            Answers
                                            <div *ngIf="comment['isReplyLoading']">
                                                <mat-progress-spinner mode="indeterminate" diameter="10"
                                                    style="margin:0 auto;">
                                                </mat-progress-spinner>
                                            </div>
                                            <div *ngIf="!comment['isReplyLoading']">
                                                <img *ngIf="comment.replyCount>0 && !comment['showReply']"
                                                    src="../../../../assets/img/arrow-down.png" style="width: 2em;">
                                                <img *ngIf="comment.replyCount>0 && comment['showReply']"
                                                    src="../../../../assets/img/arrow-up.png" style="width: 2em;">
                                                <img *ngIf="comment.replyCount==0"
                                                    src="../../../../assets/img/arrow-down.svg" style="width: 2em;">
                                            </div>
                                        </div>
                                        <div>
                                            <button mat-button [matMenuTriggerFor]="menu">
                                                <img src="../../../../assets/img/vertical.png" style="height: 1em;">
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button (click)="deleteForumComment(comment._id,'parent')"
                                                    mat-menu-item>
                                                    <div>Delete</div>
                                                    <div *ngIf="comment['isDeleting']">
                                                        <mat-progress-spinner mode="indeterminate" diameter="10"
                                                            style="margin:0 auto;">
                                                        </mat-progress-spinner>
                                                    </div>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="color: #E53E3F;font-size: 12px;" (click)="showReportReasonHandler(comment._id)">
                        {{comment['reportCount']}}
                        <img src="../../../../assets/img/outlined_flag.png" style="height: 1.5em;">
                    </div>
                </div>
                <div *ngIf="comment['showInputField']"
                    style="display: flex;align-items: flex-start;margin-top: 10px;margin-left: 2em;padding-top: 10px;">
                    <div class="avatar">
                        <img src="../../../../assets/img/tg-team.png"
                            style="height: 100%;width: 100%;border-radius: 100%;">
                    </div>
                    <div>
                        <input type="text" class="input-style" placeholder="Type here..." [(ngModel)]="replyText">
                        <div
                            style="color: #3199D8;font-size: 12px;font-weight: 500;margin-top: 10px;    text-align: end;">
                            <span style="cursor: pointer;margin-right: 1em;"
                                (click)="hideReplyInputHandler()">Cancel</span> <span
                                style="cursor: pointer;margin-right: 1em;"
                                (click)="sendReplyHandler(comment['_id'])">Post</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="comment['showReply']">
                    <div class="col-md-12" *ngFor="let reply of listOfReply"
                        style="display: flex;align-items: flex-start;margin-top: 10px;margin-left: 2em;padding-top: 10px;    border-top: solid 1px #dddddd;">
                        <div class="avatar" *ngIf="reply['sentBy']=='Admin'">
                            <img src="../../../../assets/img/tg-team.png"
                                style="height: 100%;width: 100%;border-radius: 100%;">
                        </div>
                        <div class="avatar"
                            *ngIf="reply['sentBy']=='User' && (reply['user']['avatar']!=null && reply['user']['avatar']!=undefined)">
                            <img [src]="reply['user']['avatar']" style="height: 100%;width: 100%;border-radius: 100%;">
                        </div>
                        <div class="no-avatar"
                            *ngIf="reply['sentBy']=='User' && (reply['user']['avatar']==null ||  reply['user']['avatar']==undefined)">
                            {{this.appService.getAvatarText(reply['user']['fullName'])}}
                        </div>
                        <div style="margin-left: 1em;">
                            <div style="display: flex;align-items: center;margin-bottom: 10px;">
                                <div class="name">
                                    {{reply['sentBy']=='Admin'?'TG Team':reply['user']['fullName']}}
                                </div>
                                <div
                                    style="background-color: #D9D9D9;height: 7px;width: 7px;border-radius: 100%;margin-left: 0.75em;">

                                </div>
                                <div style="color: #84858E;font-size: 12px;margin-left: 0.75em;">
                                    {{getTime(reply['createdAt'])}}
                                </div>
                            </div>

                            <div style="color: #515259;font-size: 12px;" *ngIf="reply['content']['type']=='Text'">
                                {{reply['content']['text']}}
                            </div>
                            <div style="color: #515259;font-size: 12px;" *ngIf="reply['content']['type']=='Image'">
                                <img [src]="reply['content']['url']" style="height: 10em;width: 15em;">
                            </div>
                            <div style="display: flex;align-items: center;justify-content: space-between;">
                                <div style="cursor: pointer;color: #3199D8;font-size: 11px;font-weight: 500;"
                                    (click)="showReplyInputHandler(comment._id)">
                                    Reply
                                </div>

                                <div style="margin-left: 1em;">
                                    <div>
                                        <button mat-button [matMenuTriggerFor]="menu">
                                            <img src="../../../../assets/img/vertical.png" style="height: 1em;">
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button (click)="deleteForumComment(reply._id,'child')" mat-menu-item>
                                                <div>Delete</div>
                                                <div *ngIf="reply['isDeleting']">
                                                    <mat-progress-spinner mode="indeterminate" diameter="10"
                                                        style="margin:0 auto;">
                                                    </mat-progress-spinner>
                                                </div>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="height: 10em;">

    </div>
</div>