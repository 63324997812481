<div mat-dialog-title
    style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
    <div style="font-size: 18px;font-weight: 600;">
        Performance PDF
    </div>
    <div>
        <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
    </div>
</div>
<mat-dialog-content
    style="max-height: 45vh;min-height: 43vh;overflow-y: scroll;max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
    <div class="container__filter" *ngIf="!isLoading">
        <div class="mb" style="margin-bottom: 2px">
            <label class="label required" for="">Upload PDF</label>
            <div *ngIf="defaultImageUrl==null || defaultImageUrl==undefined || defaultImageUrl==''"
                style="height: 15.6rem" class="uploadBox">
                <div style="text-align: center;">
                    <label for="uploadImage" style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                        class="uploadImage">+ Add New File</label>
                    <input type="file" id="uploadImage" for="uploadImage" style="display: none" accept=".pdf"
                        (change)="selectPDFFile($event)" required />
                    <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                        Upload File
                    </div>
                    <img style="width: 3.3rem; margin-bottom: 2rem" src="../../../../../assets/img/upload-file.png"
                        alt="" srcset="" />
                </div>
            </div>
            <div *ngIf="defaultImageUrl!=null && defaultImageUrl!=undefined && defaultImageUrl!=''" class="uploadBox"
                style="position: relative; height: 15.6rem">
                <img (click)="removePDF()" class="removeImage" src="../../../../../assets/svgIcons/close.svg" alt=""
                    srcset="" />
                <img style="max-height: 13rem; max-width: 20rem;padding: 1rem;border-radius: 25px;"
                    src="../../../../assets/img/image-pdf.png" alt="" srcset="" />
                <div class="fileName">
                    {{selectedPDFName}}
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-2" type="button" (click)="onSubmit()">
            Save
        </button>
    </div>

</mat-dialog-actions>