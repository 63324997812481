import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ParticipantAddDeleteDialogComponent } from '../participant-add-delete-dialog/participant-add-delete-dialog.component';
import { ExcelService } from '../../excel.service';

@Component({
  selector: 'app-view-community-participant',
  templateUrl: './view-community-participant.component.html',
  styleUrls: ['./view-community-participant.component.scss']
})
export class ViewCommunityParticipantComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  downloadReport: boolean = false;
  isLoading: boolean = false;
  userDetail: any;
  httpHeaders: any;
  listOfParticipant: any = [];
  constructor(
    public dialogRef: MatDialogRef<ViewCommunityParticipantComponent>,
    private toastr: ToastrService,
    public as: AppService, public excelExport: ExcelService,
    @Inject(MAT_DIALOG_DATA) public data: any, private matDialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
    if (this.data['source'] == 'Message Seen') {
      this.fetchAllMessageSeenUser();
    } else {
      this.fetchAllParticipant();
    }
  }
  downloadMessageSeenDataData() {
    this.downloadReport = true;
    var toExport: any = [];
    this.listOfParticipant.forEach((member: any, i: number) => {
      toExport.push({
        SrNo: i + 1,
        UserName: (member['user']['fullName'] ?? '') + ' ' + (member['user']['lastName'] ?? ''),
        UserEmail: member['user']['email'],
        UserMobileNo: member['user']['phone'],
        UserId: member['_id'],
        Date: this.formatDate(new Date(member['createdAt'])),
      });

    });
    this.downloadReport = false;
    this.excelExport.exportAsExcelFile(toExport, `message_seen_user_${new Date().getTime()}`);
  }
  downloadParticipantData() {
    this.downloadReport = true;
    var toExport: any = [];
    this.listOfParticipant.forEach((member: any, i: number) => {
      toExport.push({
        SrNo: i + 1,
        UserName: (member['user']['fullName'] ?? '') + ' ' + (member['user']['lastName'] ?? ''),
        UserEmail: member['user']['email'],
        UserMobileNo: member['user']['phone'],
        CommunityName: this.data['communityInfo']['name'],
        JoinDate: this.formatDate(new Date(member['createdAt'])),
        EndDate: member['endDate'] == null || member['endDate'] == undefined ? 'NA' : this.formatDate(new Date(member['endDate'])),
      });

    });
    this.downloadReport = false;
    this.excelExport.exportAsExcelFile(toExport, `${this.data['communityInfo']['name']}_participant_info_${new Date().getTime()}`);
  }

  formatDate(date: any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  async fetchAllMessageSeenUser() {
    this.isLoading = true;
    try {

      const URL = `${webApi.domain + webApi.endPoint.fetchAllMessageSeenUser}/${this.data['id']}`;
      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfParticipant = data['result'];
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  async fetchAllParticipant() {
    this.isLoading = true;
    try {

      const URL = `${webApi.domain + webApi.endPoint.fetchAllParticipant}/${this.data['id']}`;
      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfParticipant = data['result'];
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  addPartcipant() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Add Participant', community: this.data['communityInfo'] };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(ParticipantAddDeleteDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result'] != null && res['result'] != undefined) {
            this.listOfParticipant.splice(0, 0, res['result']);
          }
        }
      }
    );

  }
  removePartcipant(info: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { info, source: 'Remove Participant' };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(ParticipantAddDeleteDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['success']) {
            let idx = -1;
            for (let index = 0; index < this.listOfParticipant.length; index++) {
              const element = this.listOfParticipant[index];
              if (element['_id'] == info['_id']) {
                idx = index;
              }
            }
            if (idx != -1) {
              this.listOfParticipant.splice(idx, 1);
            }
            this.showSuccessToaster('Member removed successfully.', 'Success');
          }
        }
      }
    );

  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
