import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-pin-unpin-dialog',
  templateUrl: './pin-unpin-dialog.component.html',
  styleUrls: ['./pin-unpin-dialog.component.scss']
})
export class PinUnpinDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  userDetail: any;
  httpHeaders: any;
  constructor(
    public dialogRef: MatDialogRef<PinUnpinDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }


  //Community
  async pinUnpinCommunity() {
    this.isLoading = true;
    try {

      const URL = `${webApi.domain + webApi.endPoint.pinCommunityForAdmin}/${this.data['source']['community']}/${this.data['source']['isPin'] ? 0 : 1}`;
      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data1: any) => {
        if (data1['success']) {
          this.dialogRef.close();
          this.closeActivityAtDialog.emit({
            success: true,
          });
          this.showSuccessToaster('Community' + this.data['source']['isPin'] ? 'Unpin ' : 'Pin ' + ' successfully.', 'Success');
        } else {
          this.showWarningToaster('Unable to ' + this.data['source']['isPin'] ? 'Unpin ' : 'Pin ' + '  community. Try again', 'Warning');
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.showWarningToaster('Unable to ' + this.data['source']['isPin'] ? 'Unpin ' : 'Pin ' + '  community. Try again', 'Warning');
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
