<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                Create Subscription
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addSubscription" [formGroup]="addSubscription">

        <div  class="row" style="margin-bottom:2rem">
            <div class="col-md-12">
                <label for="franchise" class="label required" style="margin-bottom: 1em;">Select Courses</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Courses'"
                    [ngModelOptions]="{standalone: true}" [settings]="courseDropdownSettings" [data]="listOfCourses"
                    [(ngModel)]="selectedCourses">
                </ng-multiselect-dropdown>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="plan" class="label">Plan Name</label>
                <select id="plan" name="plan" class="input-style" (change)="typeSelected($event.target)" formControlName="plan" >
                        <option value="Select Plan" selected disabled>Select Plan</option>
                        <option *ngFor="let plan of listOfPlansType" [value]="plan" class="levels">
                            {{plan}}</option>
                    </select>
            </div>

            <div *ngIf="selectedPlan!='None'" class="col-md-6">
                <label for="phone" class="label">Plan SubType</label>
                <select id="plan" name="plan" class="input-style" formControlName="subType" >
                    <option value="Select Plan SubType" selected disabled>Select Plan SubType</option>
                    <option *ngFor="let plan of listOfPlanSubType" [value]="plan['_id']" class="levels">
                        {{plan['subType']}}</option>
                </select>
            </div>
        </div>

        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addSubscription.invalid || selectedCourses.length==0 ,'save-btn':addSubscription.valid && selectedCourses!=0 }"
                value="{{'Save'}}" [disabled]="addSubscription.invalid  || selectedCourses.length==0" (click)="editUser()" />
        </div>
    </form>
</div>