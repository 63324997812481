<div class="main-container">
    <div class="row align-center" style="width: 100%;">
        <div class="top-heading col-md-3" style="display: flex;align-items: center;    font-size: 22px; ">
            <div style="margin-right: 0.5em;cursor: pointer;" (click)="goBack()">
                <!-- <mat-icon>
                    arrow_back_ios
                </mat-icon> -->
            </div> {{appService.typeOfReport}} List
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

                <div class="col-md-5" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here" (input)="searchData($event.target)">
                </div>
                <div class="col-md-3">
                    <button class="add-new-emp" (click)="generateReport()">
                        Generate Report
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">
        <div *ngIf="listOfReportsToDisplay.length==0">
            <div class="no-available">
                No Report found
            </div>
        </div>
        <div *ngIf="listOfReportsToDisplay.length>0">
            <table class="table-style">
                <thead class="table-heading">
                    <th class="table-heading-content" style="cursor: pointer;text-align: center;" [appSort]="listOfReportsToDisplay" data-order="desc" data-name="createdAt" scope="col">
                        Generation Date</th>
                    <th class="table-heading-content" style="cursor: pointer;text-align: center;" [appSort]="listOfReportsToDisplay" data-order="desc" data-name="name" scope="col">
                        File Name</th>
                    <th class="table-heading-content" style="cursor: pointer;text-align: center;" [appSort]="listOfReportsToDisplay" data-order="desc" data-name="name" scope="col">
                        Time Taken to Generate</th>
                    <th class="table-heading-content" style="cursor: pointer;text-align: center;" [appSort]="listOfReportsToDisplay" data-order="desc" data-name="status" scope="col">
                        Status</th>

                    <th class="table-heading-content">ACTION</th>

                </thead>
                <div style="height: 0.6em;"> </div>
                <tbody>
                    <tr class="table-data" *ngFor="let report of listOfReportsToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                        <th class="table-data-content">{{report.createdAt|date: 'dd/MM/yyyy hh:mm a'}}</th>
                        <th class="table-data-content">
                            {{report.name}}</th>
                        <th class="table-data-content">
                            {{report.status=='GENERATING'?report.status :getTimeDifference(report.startedAt,report.endedAt).toFixed(2)+' sec'}} </th>
                        <th class="table-data-content">
                            {{report.status}}</th>

                        <th class="table-data-content" style="width: 10em;text-align: center;">
                            <!-- <img (click)="downloadReportXl(report)" style="height: 2rem;margin-right: 1em;cursor: pointer;" src='../../../assets/img/file.png'> -->
                            <span *ngIf="report.status=='FAILED'">NA</span> <span *ngIf="report.status=='GENERATING'">Waiting</span> <img *ngIf="report.status=='SUCCESS'" (click)="downloadReportXl(report)" style="height: 1.3rem;margin-right: 1em;cursor: pointer;"
                                src='../../../assets/img/file.png'>

                        </th>
                    </tr>
                </tbody>
            </table>
            <div class="text-right" style="font-size: medium;margin: 1em;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
        <div style="height: 10em;">

        </div>
    </div>