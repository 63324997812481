import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-list-webhook-search',
  templateUrl: './list-webhook-search.component.html',
  styleUrls: ['./list-webhook-search.component.scss']
})
export class ListWebhookSearchComponent implements OnInit {
  isLoading: boolean = false;
  listOfResult: any = [];
  searchQuery: any = '';
  userDetail: any;
  constructor(public routes: ActivatedRoute,
    private router: Router, private matDialog: MatDialog, private toastr: ToastrService,
    public appService: AppService) {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  ngOnInit(): void {
  }
  async serachWebhookLog() {
    let searchQueryData = this.searchQuery;
    if (searchQueryData != '') {
      this.isLoading = true;
      this.listOfResult = [];
      let param = {
        searchQuery: this.searchQuery
      };
      try {
        let URL = `${webApi.domain + webApi.endPoint.searchWebhookLogs}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetail['accessToken']}`,
        });
        await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
          if (data['type']) {
            this.listOfResult = data['result'];
            this.isLoading = false;
          } else {
            this.listOfResult = [];
          } this.isLoading = false;

        });
      } catch (error) {
        this.listOfResult = [];
        this.isLoading = false;

      }
    }
  }
  getDate(num: number) {
    return new Date(num * 1000);
  }
}
