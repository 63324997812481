<div mat-dialog-title
    style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
    <div style="font-size: 18px;font-weight: 600;">
        {{this.data['source'] == 'Message Seen'?'User List':'Participant List (' +  totalCount + ' )'}}
    </div>
    <div style="    display: flex;align-items: center;">
        <div *ngIf="this.data['source'] != 'Message Seen'" (click)="addPartcipant()"
            style="color: white;cursor: pointer;font-size: 13px;margin-right: 2em;text-decoration: underline;">
            Add Member
        </div>
        <div>
            <img src="../../../../assets/svgIcons/download.svg" class="close-icon" style="margin-right: 1em;"
                (click)="data['source'] == 'Message Seen'?downloadMessageSeenDataData():downloadParticipantData()">
            <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
        </div>
    </div>
</div>
<mat-dialog-content style="max-width: inherit;margin: 0px;padding: 0em 1em  2em  1em !important;">
    <div class="container__filter" *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin: 0 auto;">
        </mat-progress-spinner>
    </div>
    <div class="container__filter" *ngIf="!isLoading">


        <div *ngIf="listOfParticipant.length==0">
            No detail available
        </div>
        <div *ngIf="listOfParticipant.length>0">
            <div style="max-height: calc(100vh - 42vh);overflow-y: auto;">
                <div *ngFor="let member of listOfParticipant | paginate: { id: 'All-Chat', itemsPerPage: count, currentPage:currentPage }"
                    style="padding: 0.7em 0.5em;border: solid 1px gray;border-radius: 3px;margin-bottom: 10px;font-size: 13px;display: flex;align-items: center;justify-content: space-between;">
                    <div>
                        {{member['user']['fullName']!=null &&
                        member['user']['fullName']!=undefined?(member['user']['fullName']+' -\
                        '+member['user']['phone']):member['user']['phone']}} - ( <span
                            style="font-size: 12px;">{{member['createdAt'] | date: 'dd/MM/yyyy hh:mm a'}}</span> )
                    </div>
                    <!-- <div
                    *ngIf="this.data['source'] == 'View Participant' && this.data['type'] == 'Sample' && this.data['subType'] != 'Buddy Group'">
                    <img (click)="removePartcipant(member)" src="../../../../assets/img/delete.png"
                    style="height: 1.5em;margin-right: 1em;cursor: pointer;">
                </div> -->
                </div>
            </div>
            <div class="pagination-div">
                <div *ngIf="!isLoadingExtraData" class="text-right" style="margin: 1em;">
                    <pagination-controls id='All-Chat' [responsive]="true" (pageChange)="onPageChange($event)">
                    </pagination-controls>
                </div>

                <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>

<!-- <mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-1" type="button" (click)="close()">
            No
        </button>
        <button class="btn btn-2" type="button"
            (click)="this.data['source']=='Delete Community'? deleteCommunity():deleteMessage()">
            Yes, Delete
        </button>
    </div>

</mat-dialog-actions> -->