import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-webinar-detail',
  templateUrl: './add-edit-webinar-detail.component.html',
  styleUrls: ['./add-edit-webinar-detail.component.scss', 'stepper.scss']
})
export class AddEditWebinarDetailComponent implements OnInit {

  isTemplateLoading: boolean = false;

  isLoading: boolean = true;
  isGetTargetLoading: boolean = false;
  targetUserCountCalled: boolean = false;
  targetUserCount: number = 0;

  isSaveWebinarLoading: boolean = false;
  showCampaignDateTime: boolean = true;

  dataToEdit: any;

  myHeaders = new Headers();
  formdata = new FormData();
  radioSelectedValue: any;

  // WhatsApp Variables

  listOfTemplate: any = [];
  listOfTemplateToDisplay: any = {
    'before-15-Min': [],
    'before-30-Min': [],
    'before-1-Hr': [],
    'before-3-Hr': [],
    'before-6-Hr': [],
    'before-1-Day': [],
    'before-2-Day': [],

    'after-15-Min': [],
    'after-30-Min': [],
    'after-1-Hr': [],
    'after-3-Hr': [],
    'after-6-Hr': [],
    'after-1-Day': [],
    'after-2-Day': []
  };
  selectedTemplate: any = {
    'before-15-Min': [],
    'before-30-Min': [],
    'before-1-Hr': [],
    'before-3-Hr': [],
    'before-6-Hr': [],
    'before-1-Day': [],
    'before-2-Day': [],

    'after-15-Min': [],
    'after-30-Min': [],
    'after-1-Hr': [],
    'after-3-Hr': [],
    'after-6-Hr': [],
    'after-1-Day': [],
    'after-2-Day': [],
  };
  template: any = {
    'before-15-Min': [],
    'before-30-Min': [],
    'before-1-Hr': [],
    'before-3-Hr': [],
    'before-6-Hr': [],
    'before-1-Day': [],
    'before-2-Day': [],

    'after-15-Min': [],
    'after-30-Min': [],
    'after-1-Hr': [],
    'after-3-Hr': [],
    'after-6-Hr': [],
    'after-1-Day': [],
    'after-2-Day': [],
  };
  showWhatsAppContent: boolean = false;

  showTemplateBody: any = {
    'before-15-Min': false,
    'before-30-Min': false,
    'before-1-Hr': false,
    'before-3-Hr': false,
    'before-6-Hr': false,
    'before-1-Day': false,
    'before-2-Day': false,

    'after-15-Min': false,
    'after-30-Min': false,
    'after-1-Hr': false,
    'after-3-Hr': false,
    'after-6-Hr': false,
    'after-1-Day': false,
    'after-2-Day': false,
  };

  // showHeaderOfTemplate: boolean = false;
  showHeaderOfTemplate: any = {
    'before-15-Min': false,
    'before-30-Min': false,
    'before-1-Hr': false,
    'before-3-Hr': false,
    'before-6-Hr': false,
    'before-1-Day': false,
    'before-2-Day': false,

    'after-15-Min': false,
    'after-30-Min': false,
    'after-1-Hr': false,
    'after-3-Hr': false,
    'after-6-Hr': false,
    'after-1-Day': false,
    'after-2-Day': false,
  };
  // showHeaderMediaOfTemplate: boolean = false;
  showHeaderMediaOfTemplate: any = {
    'before-15-Min': false,
    'before-30-Min': false,
    'before-1-Hr': false,
    'before-3-Hr': false,
    'before-6-Hr': false,
    'before-1-Day': false,
    'before-2-Day': false,

    'after-15-Min': false,
    'after-30-Min': false,
    'after-1-Hr': false,
    'after-3-Hr': false,
    'after-6-Hr': false,
    'after-1-Day': false,
    'after-2-Day': false,
  };
  // showHeaderTextOfTemplate: boolean = false;
  showHeaderTextOfTemplate: any = {
    'before-15-Min': false,
    'before-30-Min': false,
    'before-1-Hr': false,
    'before-3-Hr': false,
    'before-6-Hr': false,
    'before-1-Day': false,
    'before-2-Day': false,

    'after-15-Min': false,
    'after-30-Min': false,
    'after-1-Hr': false,
    'after-3-Hr': false,
    'after-6-Hr': false,
    'after-1-Day': false,
    'after-2-Day': false,
  };
  // showBodyOfTemplate: boolean = false;
  showBodyOfTemplate: any = {
    'before-15-Min': false,
    'before-30-Min': false,
    'before-1-Hr': false,
    'before-3-Hr': false,
    'before-6-Hr': false,
    'before-1-Day': false,
    'before-2-Day': false,

    'after-15-Min': false,
    'after-30-Min': false,
    'after-1-Hr': false,
    'after-3-Hr': false,
    'after-6-Hr': false,
    'after-1-Day': false,
    'after-2-Day': false,
  };
  // showFooterOfTemplate: boolean = false;
  showFooterOfTemplate: any = {
    'before-15-Min': false,
    'before-30-Min': false,
    'before-1-Hr': false,
    'before-3-Hr': false,
    'before-6-Hr': false,
    'before-1-Day': false,
    'before-2-Day': false,

    'after-15-Min': false,
    'after-30-Min': false,
    'after-1-Hr': false,
    'after-3-Hr': false,
    'after-6-Hr': false,
    'after-1-Day': false,
    'after-2-Day': false,
  };
  // showButtonOfTemplate: boolean = false;
  showButtonOfTemplate: any = {
    'before-15-Min': false,
    'before-30-Min': false,
    'before-1-Hr': false,
    'before-3-Hr': false,
    'before-6-Hr': false,
    'before-1-Day': false,
    'before-2-Day': false,

    'after-15-Min': false,
    'after-30-Min': false,
    'after-1-Hr': false,
    'after-3-Hr': false,
    'after-6-Hr': false,
    'after-1-Day': false,
    'after-2-Day': false,
  };
  selectedCampaignMedium: any;

  bodyVariableCount: any = {
    'before-15-Min': 0,
    'before-30-Min': 0,
    'before-1-Hr': 0,
    'before-3-Hr': 0,
    'before-6-Hr': 0,
    'before-1-Day': 0,
    'before-2-Day': 0,

    'after-15-Min': 0,
    'after-30-Min': 0,
    'after-1-Hr': 0,
    'after-3-Hr': 0,
    'after-6-Hr': 0,
    'after-1-Day': 0,
    'after-2-Day': 0,
  };

  // Image For WhatsApp
  selectedHeaderMedia: any = null;
  selectedHeaderMediaName: any = null;
  defaultHeaderMedia: any;


  // WhatsApp Variables



  listOfStepper: any = [
    {
      label: 'Webinar Detail',
      content: '1',
    },
    {
      label: 'Target & Schedule',
      content: '2',
    },
    {
      label: 'Content',
      content: '3',
    },

  ];
  listOfMedium: any = [

    {
      label: 'In App', content: '1',
    },
    {
      label: 'Push Notification', content: '1',
    },
    {
      label: 'Popup', content: '1',
    },
    {
      label: 'WhatsApp', content: '1',
    }
  ];

  listOfBeforeDuration: any = [
    {
      type: 'Minutes',
      duration: 15,
      title: '15 Minutes',
    },
    {
      type: 'Minutes',
      duration: 30,
      title: '30 Minutes',
    },
    {
      type: 'Hour',
      duration: 1,
      title: '1 Hour',
    },
    {
      type: 'Hour',
      duration: 3,
      title: '3 Hour',
    },
    {
      type: 'Hour',
      duration: 6,
      title: '6 Hour',
    },
    {
      type: 'Day',
      duration: 1,
      title: '1 Day',
    },
    {
      type: 'Day',
      duration: 2,
      title: '2 Day',
    },
  ];

  listOfAfterDuration: any = [
    {
      type: 'Minutes',
      duration: 15,
      title: '15 Minutes',
    },
    {
      type: 'Minutes',
      duration: 30,
      title: '30 Minutes',
    },
    {
      type: 'Hour',
      duration: 1,
      title: '1 Hour',
    },
    {
      type: 'Hour',
      duration: 3,
      title: '3 Hour',
    },
    {
      type: 'Hour',
      duration: 6,
      title: '6 Hour',
    },
    {
      type: 'Day',
      duration: 1,
      title: '1 Day',
    },
    {
      type: 'Day',
      duration: 2,
      title: '2 Day',
    },
  ];

  listOfCourse: any = [];
  listOfAuthor: any = [];

  // Medium
  selectedMedium: any = [];

  // Author
  selectedAuthor: any = [];

  // Course
  selectedCourse: any = [];

  // Before
  selectedBeforeTime: any = [];

  // After
  selectedAfterTime: any = [];

  beforeSelected: boolean = false;
  afterSelected: boolean = false;

  timeSetting: IDropdownSettings = {
    singleSelection: false,
    idField: 'title',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  mediumDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'label',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  authorDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };


  courseDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'courseName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  course1DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'courseName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };
  selectedWhatsAppImage: any = null;
  selectedWhatsAppImageName: any = null;
  defaultWhatsAppImage: any;

  selectedStepper: any = 'Webinar Detail';
  selectedType: any;
  selectedScheduleFor: any;
  selectedUserTarget: any;

  isFreeExcluded: boolean = false;
  isPaidExcluded: boolean = false;

  // Campaign Form Controls
  nameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  linkTypeControl = new FormControl('Select Link Type', [
    Validators.required,
  ]);
  linkControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  limitedSeatControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  webinarDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  lobbyTimeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  startTimeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  endTimeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  authorControl = new FormControl('', [
    Validators.required,
  ]);
  descriptionControl = new FormControl('', [
    Validators.required,
  ]);
  webinarForControl = new FormControl('', [
    Validators.required,
  ]);
  mediumControl = new FormControl('', [
    Validators.required,
  ]);



  // Target Form Controls
  targetUserControl = new FormControl('Select User Group', [
    Validators.required,
  ]);
  courseControl = new FormControl('', [
    Validators.required,
  ]);
  beforeDurationControl = new FormControl('', [
    Validators.required,
  ]);
  afterDurationControl = new FormControl('', [
    Validators.required,
  ]);


  // Content Form Controls
  messageTitleBefore15MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageBefore15MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefBefore15MinControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpBefore15MinControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleBefore30MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageBefore30MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefBefore30MinControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpBefore30MinControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleBefore1HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageBefore1HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefBefore1HrControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpBefore1HrControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleBefore3HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageBefore3HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefBefore3HrControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpBefore3HrControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleBefore6HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageBefore6HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefBefore6HrControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpBefore6HrControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleBefore1DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageBefore1DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefBefore1DayControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpBefore1DayControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleBefore2DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageBefore2DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefBefore2DayControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpBefore2DayControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleAfter15MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageAfter15MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefAfter15MinControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpAfter15MinControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleAfter30MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageAfter30MinControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefAfter30MinControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpAfter30MinControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleAfter1HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageAfter1HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefAfter1HrControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpAfter1HrControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleAfter3HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageAfter3HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefAfter3HrControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpAfter3HrControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleAfter6HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageAfter6HrControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefAfter6HrControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpAfter6HrControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleAfter1DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageAfter1DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefAfter1DayControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpAfter1DayControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageTitleAfter2DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageAfter2DayControl = new FormControl('', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messagePopUpPrefAfter2DayControl = new FormControl('1', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  messageIsPopUpAfter2DayControl = new FormControl('false', [
    Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  selectedImage: any = {};
  selectedImageName: any = {};
  defaultImage: any = {};

  selectedThumbnailImage: any;
  selectedThumbnailImageName: any = {};
  defaultThumbnailImage: any;

  selectedPdfFile: any;
  selectedPdfFileName: any = '';
  defaultPdfFile: any;

  // selectedInAppImage: any = null;
  // selectedInAppImageName: any = null;
  // defaultInAppImage: any;

  // selectedPushNotificationImage: any = null;
  // selectedPushNotificationImageName: any = null;
  // defaultPushNotificationImage: any;

  // selectedPopupNotificationImage: any = null;
  // selectedPopupNotificationImageName: any = null;
  // defaultPopupNotificationImage: any;

  // selectedEmailImage: any = null;
  // selectedEmailImageName: any = null;
  // defaultEmailImage: any;

  // Campaign Form Group
  addCampaignDetail: FormGroup = new FormGroup({
    name: this.nameControl,
    linkType: this.linkTypeControl,
    link: this.linkControl,
    limitedSeat: this.limitedSeatControl,
    medium: this.mediumControl,
    author: this.authorControl,
    webinarDate: this.webinarDateControl,
    lobbyTime: this.lobbyTimeControl,
    startTime: this.startTimeControl,
    endTime: this.endTimeControl,
    description: this.descriptionControl,
    webinarFor: this.webinarForControl,
  });

  // Target User Form Group
  addTargetUserDetail: FormGroup = new FormGroup({
    targetUser: this.targetUserControl,
  });

  // Schedule Webinar Detail Form Group
  scheduleWebinarFormDetail: FormGroup = new FormGroup({
    // targetUser: this.targetUserControl,
  });

  // Content Form Group
  addContentDetail: FormGroup = new FormGroup({
  });
  userDetail: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router, private toastr: ToastrService,
    public appService: AppService, private formBuilder: FormBuilder
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }

  ngOnInit(): void {
    this.selectedStepper = 'Webinar Detail';
    this.addCampaignDetail = this.formBuilder.group({
      name: this.nameControl,
      linkType: this.linkTypeControl,
      link: this.linkControl,
      medium: this.mediumControl,
      author: this.authorControl,
      limitedSeat: this.limitedSeatControl,
      webinarDate: this.webinarDateControl,
      lobbyTime: this.lobbyTimeControl,
      startTime: this.startTimeControl,
      endTime: this.endTimeControl,
      description: this.descriptionControl,
      webinarFor: this.webinarForControl,
    });
    this.fetchCourse();
  }
  async fetchCourse() {
    this.isLoading = true;
    this.listOfCourse = [];
    try {
      const param = {
        courseId: '0',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllCourse}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
          this.fetchAuthor();
        } else {
          this.listOfCourse = [];
          this.fetchAuthor();
        }
      });
    } catch (error) {
      this.listOfCourse = [];
      this.fetchAuthor();

    }
  }
  async fetchAuthor() {
    this.isLoading = true;
    this.listOfAuthor = [];
    try {

      const URL = `${webApi.domain + webApi.endPoint.fetchAuthors}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfAuthor = data['result'];
          this.fetchAllTemplate();
        } else {
          this.listOfAuthor = [];
          this.fetchAllTemplate();
        }
      });
    } catch (error) {
      this.listOfAuthor = [];
      this.fetchAllTemplate();

    }
  }
  async fetchAllTemplate() {
    this.isLoading = true;
    this.listOfTemplate = [];
    try {
      let param = {
        phone: '9152615121',
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      let URL = `${WA_API.domain + WA_API.endPoint.fetchAllTemplates}`;

      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfTemplate = data['result'];
          this.listOfTemplate.forEach((template: any) => {
            template['nameWithLanguage'] =
              template['name'] + ' (language :- ' + template['language'] + ')';
          });
          this.listOfTemplateToDisplay = {
            'before-15-Min': this.listOfTemplate,
            'before-30-Min': this.listOfTemplate,
            'before-1-Hr': this.listOfTemplate,
            'before-3-Hr': this.listOfTemplate,
            'before-6-Hr': this.listOfTemplate,
            'before-1-Day': this.listOfTemplate,
            'before-2-Day': this.listOfTemplate,

            'after-15-Min': this.listOfTemplate,
            'after-30-Min': this.listOfTemplate,
            'after-1-Hr': this.listOfTemplate,
            'after-3-Hr': this.listOfTemplate,
            'after-6-Hr': this.listOfTemplate,
            'after-1-Day': this.listOfTemplate,
            'after-2-Day': this.listOfTemplate
          };
          this.assignValueToEdit();
        } else {
          this.listOfTemplate = [];
          // this.listOfTemplateToDisplay = this.listOfTemplate;
          this.assignValueToEdit();
        }
      });
    } catch (error) {
      this.listOfTemplate = [];
      // this.listOfTemplateToDisplay = this.listOfTemplate;
      this.assignValueToEdit();
    }
  }
  assignValueToEdit() {
    if (this.appService.webinarEdit != null) {
      this.dataToEdit = this.appService.webinarEdit;
      this.appService.webinarEdit = null;

      // Webinar Detail Assignment

      this.addCampaignDetail.get('name')?.setValue(this.dataToEdit['title']);
      this.addCampaignDetail.get('linkType')?.setValue(this.dataToEdit['linkType']);
      this.addCampaignDetail.get('link')?.setValue(this.dataToEdit['link']);
      this.addCampaignDetail.get('limitedSeat')?.setValue(this.dataToEdit['limitedSeat'] == null || this.dataToEdit['limitedSeat'] == undefined ? 0 : this.dataToEdit['limitedSeat']);
      this.addCampaignDetail.get('description')?.setValue(this.dataToEdit['description']);
      this.addCampaignDetail.get('webinarFor')?.setValue(this.dataToEdit['webinarFor']);
      this.addCampaignDetail
        .get('webinarDate')
        ?.setValue(formatDate(new Date(this.dataToEdit['webinarDate']), 'yyyy-MM-dd', 'en-GB'));

      let lobbyTimeArray = String(this.dataToEdit['lobbyTime']).length > 4 ? String(this.dataToEdit['lobbyTime']).replace('.', ':') : (String(this.dataToEdit['lobbyTime']).length <= 2 ? String(this.dataToEdit['lobbyTime'] + '.00').replace('.', ':') : String(this.dataToEdit['lobbyTime'] + '0').replace('.', ':'));
      let lobbyAry = lobbyTimeArray.split(':');
      if (lobbyAry[0].length < 2) {
        lobbyAry[0] = '0' + lobbyAry[0];
      }
      lobbyTimeArray = lobbyAry[0] + ':' + lobbyAry[1];
      this.addCampaignDetail.get('lobbyTime')?.setValue(lobbyTimeArray);

      let startTimeArray = String(this.dataToEdit['startTime']).length > 4 ? String(this.dataToEdit['startTime']).replace('.', ':') : (String(this.dataToEdit['startTime']).length <= 2 ? String(this.dataToEdit['startTime'] + '.00').replace('.', ':') : String(this.dataToEdit['startTime'] + '0').replace('.', ':'));
      let startTimeAry = startTimeArray.split(':');
      if (startTimeAry[0].length < 2) {
        startTimeAry[0] = '0' + startTimeAry[0];
      }
      startTimeArray = startTimeAry[0] + ':' + startTimeAry[1];
      this.addCampaignDetail.get('startTime')?.setValue(startTimeArray);

      let endTimeArray = String(this.dataToEdit['endTime']).length > 4 ? String(this.dataToEdit['endTime']).replace('.', ':') : (String(this.dataToEdit['endTime']).length <= 2 ? String(this.dataToEdit['endTime'] + '.00').replace('.', ':') : String(this.dataToEdit['endTime'] + '0').replace('.', ':'));
      let endTimeAry = endTimeArray.split(':');
      if (endTimeAry[0].length < 2) {
        endTimeAry[0] = '0' + endTimeAry[0];
      }
      endTimeArray = endTimeAry[0] + ':' + endTimeAry[1];
      this.addCampaignDetail.get('endTime')?.setValue(endTimeArray);

      if (this.dataToEdit['webinarMedium'].length > 0) {
        this.dataToEdit['webinarMedium'].forEach((medium: any) => {
          this.selectedMedium.push({ label: medium });
        });
        this.addCampaignDetail.get('medium')?.setValue(this.selectedMedium);
      }
      if (this.dataToEdit['author'] != null && this.dataToEdit['author'] != undefined) {
        this.listOfAuthor.forEach((author: any) => {
          this.selectedAuthor.push({ _id: author['_id'], name: author['name'] });
        });
        this.addCampaignDetail.get('author')?.setValue(this.selectedAuthor);
      }

      if (this.dataToEdit['thumbnail'] != null && this.dataToEdit['thumbnail'] != undefined) {
        this.defaultThumbnailImage = this.dataToEdit['thumbnail'];
      }
      if (this.dataToEdit['pdfLink'] != null && this.dataToEdit['pdfLink'] != undefined) {
        this.defaultPdfFile = this.dataToEdit['pdfLink'];
        let fileNameArray = String(this.dataToEdit['pdfLink']).split('/');
        this.selectedPdfFileName = fileNameArray[fileNameArray.length - 1];
      }


      // Target User Detail Assignment


      // Exclusion
      if (this.dataToEdit['criteria'] != null && this.dataToEdit['criteria'] != undefined) {

        if (this.dataToEdit['criteria']['isFreeExcluded'] != null && this.dataToEdit['criteria']['isFreeExcluded'] != undefined && this.dataToEdit['criteria']['isFreeExcluded']) {
          this.isFreeExcluded = true;
          this.isPaidExcluded = false;
        }
        if (this.dataToEdit['criteria']['isPaidExcluded'] != null && this.dataToEdit['criteria']['isPaidExcluded'] != undefined && this.dataToEdit['criteria']['isPaidExcluded']) {
          this.isFreeExcluded = false;
          this.isPaidExcluded = true;
        }
      }

      if (this.dataToEdit['type'] == 'ALL USERS') {
        this.addTargetUserDetail.get('targetUser')?.setValue('All User');
        this.selectedUserTarget = 'All User';
      }
      else if (this.dataToEdit['type'] == 'SUBSCRIPTION') {
        this.addTargetUserDetail.get('targetUser')?.setValue('Subscribed User');
        this.selectedUserTarget = 'Subscribed User';
      }
      else {
        this.addTargetUserDetail.get('targetUser')?.setValue('Course');
        this.selectedUserTarget = 'Course';
        this.addTargetUserDetail.addControl('course', this.courseControl);
        if (this.dataToEdit['courses'].length > 0) {
          this.listOfCourse.forEach((course: any) => {
            this.dataToEdit['courses'].forEach((element: any) => {
              if (course['_id'] == element['_id']) {
                this.selectedCourse.push({
                  _id: course['_id'],
                  courseName: course['courseName']
                });
              }
            });
          });
        }
      }


      // Schedular Detail Assignment

      this.dataToEdit['schedulerId'].forEach((data: any) => {
        if (data['type'] == 'Before') {
          this.scheduleWebinarFormDetail.addControl('beforeDuration', this.beforeDurationControl)
          this.selectedBeforeTime.push({
            type: data['schedule']['type'],
            duration: data['schedule']['duration'],
            title: `${data['schedule']['duration']} ${data['schedule']['type']}`,
          });
          this.beforeSelected = true;
        } else if (data['type'] == 'After') {
          this.scheduleWebinarFormDetail.addControl('afterDuration', this.afterDurationControl)
          this.selectedAfterTime.push({
            type: data['schedule']['type'],
            duration: data['schedule']['duration'],
            title: `${data['schedule']['duration']} ${data['schedule']['type']}`,
          });
          this.afterSelected = true;
        }
      });


      // // Content Detail Assignment

      this.dataToEdit['schedulerId'].forEach((notificationSchedularData: any) => {
        if (notificationSchedularData['type'] == 'Before') {
          if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 15) {
            this.addContentDetail.addControl(`messageTitleBefore15Min`, this.messageTitleBefore15MinControl);
            this.addContentDetail.addControl(`messageIsPopUpBefore15Min`, this.messageIsPopUpBefore15MinControl);
            this.addContentDetail.addControl(`messagePopUpPrefBefore15Min`, this.messagePopUpPrefBefore15MinControl);
            this.addContentDetail.addControl(`messageBefore15Min`, this.messageBefore15MinControl);

            this.addContentDetail.get('messageTitleBefore15Min')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageBefore15Min')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpBefore15Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefBefore15Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['Before - 15 Minutes'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'before-15-Min');
                if (this.showHeaderOfTemplate['before-15-Min'] && this.showHeaderTextOfTemplate['before-15-Min']) {
                  this.addContentDetail.setControl('headerText-before-15-Min', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-before-15-Min')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['before-15-Min']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-before-15-Min-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-before-15-Min-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 30) {
            this.addContentDetail.addControl(`messageTitleBefore30Min`, this.messageTitleBefore30MinControl);
            this.addContentDetail.addControl(`messageIsPopUpBefore30Min`, this.messageIsPopUpBefore30MinControl);
            this.addContentDetail.addControl(`messagePopUpPrefBefore30Min`, this.messagePopUpPrefBefore30MinControl);
            this.addContentDetail.addControl(`messageBefore30Min`, this.messageBefore30MinControl);

            this.addContentDetail.get('messageTitleBefore30Min')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageBefore30Min')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpBefore30Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefBefore30Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['Before - 30 Minutes'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'before-30-Min');
                if (this.showHeaderOfTemplate['before-30-Min'] && this.showHeaderTextOfTemplate['before-30-Min']) {
                  this.addContentDetail.setControl('headerText-before-30-Min', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-before-30-Min')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['before-30-Min']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-before-30-Min-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-before-30-Min-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 1) {
            this.addContentDetail.addControl(`messageTitleBefore1Hr`, this.messageTitleBefore1HrControl);
            this.addContentDetail.addControl(`messageIsPopUpBefore1Hr`, this.messageIsPopUpBefore1HrControl);
            this.addContentDetail.addControl(`messagePopUpPrefBefore1Hr`, this.messagePopUpPrefBefore1HrControl);
            this.addContentDetail.addControl(`messageBefore1Hr`, this.messageBefore1HrControl);

            this.addContentDetail.get('messageTitleBefore1Hr')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageBefore1Hr')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpBefore1Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefBefore1Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['Before - 1 Hour'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'before-1-Hr');
                if (this.showHeaderOfTemplate['before-1-Hr'] && this.showHeaderTextOfTemplate['before-1-Hr']) {
                  this.addContentDetail.setControl('headerText-before-1-Hr', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-before-1-Hr')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['before-1-Hr']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-before-1-Hr-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-before-1-Hr-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 3) {
            this.addContentDetail.addControl(`messageTitleBefore3Hr`, this.messageTitleBefore3HrControl);
            this.addContentDetail.addControl(`messageIsPopUpBefore3Hr`, this.messageIsPopUpBefore3HrControl);
            this.addContentDetail.addControl(`messagePopUpPrefBefore3Hr`, this.messagePopUpPrefBefore3HrControl);
            this.addContentDetail.addControl(`messageBefore3Hr`, this.messageBefore3HrControl);

            this.addContentDetail.get('messageTitleBefore3Hr')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageBefore3Hr')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpBefore3Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefBefore3Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['Before - 3 Hour'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'before-3-Hr');
                if (this.showHeaderOfTemplate['before-3-Hr'] && this.showHeaderTextOfTemplate['before-3-Hr']) {
                  this.addContentDetail.setControl('headerText-before-3-Hr', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-before-3-Hr')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['before-3-Hr']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-before-3-Hr-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-before-3-Hr-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 6) {
            this.addContentDetail.addControl(`messageTitleBefore6Hr`, this.messageTitleBefore6HrControl);
            this.addContentDetail.addControl(`messageIsPopUpBefore6Hr`, this.messageIsPopUpBefore6HrControl);
            this.addContentDetail.addControl(`messagePopUpPrefBefore6Hr`, this.messagePopUpPrefBefore6HrControl);
            this.addContentDetail.addControl(`messageBefore6Hr`, this.messageBefore6HrControl);

            this.addContentDetail.get('messageTitleBefore6Hr')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageBefore6Hr')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpBefore6Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefBefore6Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['Before - 6 Hour'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'before-6-Hr');
                if (this.showHeaderOfTemplate['before-6-Hr'] && this.showHeaderTextOfTemplate['before-6-Hr']) {
                  this.addContentDetail.setControl('headerText-before-6-Hr', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-before-6-Hr')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['before-6-Hr']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-before-6-Hr-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-before-6-Hr-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Day' && notificationSchedularData['schedule']['duration'] == 1) {
            this.addContentDetail.addControl(`messageTitleBefore1Day`, this.messageTitleBefore1DayControl);
            this.addContentDetail.addControl(`messageIsPopUpBefore1Day`, this.messageIsPopUpBefore1DayControl);
            this.addContentDetail.addControl(`messagePopUpPrefBefore1Day`, this.messagePopUpPrefBefore1DayControl);
            this.addContentDetail.addControl(`messageBefore1Day`, this.messageBefore1DayControl);

            this.addContentDetail.get('messageTitleBefore1Day')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageBefore1Day')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpBefore1Day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefBefore1Day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['Before - 1 Day'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'before-1-Day');
                if (this.showHeaderOfTemplate['before-1-Day'] && this.showHeaderTextOfTemplate['before-1-Day']) {
                  this.addContentDetail.setControl('headerText-before-1-Day', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-before-1-Day')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['before-1-Day']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-before-1-Day-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-before-1-Day-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Day' && notificationSchedularData['schedule']['duration'] == 2) {
            this.addContentDetail.addControl(`messageTitleBefore2Day`, this.messageTitleBefore2DayControl);
            this.addContentDetail.addControl(`messageIsPopUpBefore2Day`, this.messageIsPopUpBefore2DayControl);
            this.addContentDetail.addControl(`messagePopUpPrefBefore2Day`, this.messagePopUpPrefBefore2DayControl);
            this.addContentDetail.addControl(`messageBefore2Day`, this.messageBefore2DayControl);

            this.addContentDetail.get('messageTitleBefore2day')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageBefore2day')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpBefore2day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefBefore2day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['Before - 2 Day'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'before-2-Day');
                if (this.showHeaderOfTemplate['before-2-Day'] && this.showHeaderTextOfTemplate['before-2-Day']) {
                  this.addContentDetail.setControl('headerText-before-2-Day', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-before-2-Day')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['before-2-Day']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-before-2-Day-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-before-2-Day-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
        } else {
          if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 15) {
            this.addContentDetail.addControl(`messageTitleAfter15Min`, this.messageTitleAfter15MinControl);
            this.addContentDetail.addControl(`messageIsPopUpAfter15Min`, this.messageIsPopUpAfter15MinControl);
            this.addContentDetail.addControl(`messagePopUpPrefAfter15Min`, this.messagePopUpPrefAfter15MinControl);
            this.addContentDetail.addControl(`messageAfter15Min`, this.messageAfter15MinControl);

            this.addContentDetail.get('messageTitleAfter15Min')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageAfter15Min')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpAfter15Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefAfter15Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['After - 15 Minutes'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'after-15-Min');
                if (this.showHeaderOfTemplate['after-15-Min'] && this.showHeaderTextOfTemplate['after-15-Min']) {
                  this.addContentDetail.setControl('headerText-after-15-Min', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-after-15-Min')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['after-15-Min']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-after-15-Min-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-after-15-Min-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 30) {
            this.addContentDetail.addControl(`messageTitleAfter30Min`, this.messageTitleAfter30MinControl);
            this.addContentDetail.addControl(`messageIsPopUpAfter30Min`, this.messageIsPopUpAfter30MinControl);
            this.addContentDetail.addControl(`messagePopUpPrefAfter30Min`, this.messagePopUpPrefAfter30MinControl);
            this.addContentDetail.addControl(`messageAfter30Min`, this.messageAfter30MinControl);

            this.addContentDetail.get('messageTitleAfter30Min')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageAfter30Min')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpAfter30Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefAfter30Min')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['After - 30 Minutes'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'after-30-Min');
                if (this.showHeaderOfTemplate['after-30-Min'] && this.showHeaderTextOfTemplate['after-30-Min']) {
                  this.addContentDetail.setControl('headerText-after-30-Min', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-after-30-Min')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['after-30-Min']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-after-30-Min-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-after-30-Min-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 1) {
            this.addContentDetail.addControl(`messageTitleAfter1Hr`, this.messageTitleAfter1HrControl);
            this.addContentDetail.addControl(`messageIsPopUpAfter1Hr`, this.messageIsPopUpAfter1HrControl);
            this.addContentDetail.addControl(`messagePopUpPrefAfter1Hr`, this.messagePopUpPrefAfter1HrControl);
            this.addContentDetail.addControl(`messageAfter1Hr`, this.messageAfter1HrControl);

            this.addContentDetail.get('messageTitleAfter1Hr')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageAfter1Hr')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpAfter1Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefAfter1Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['After - 1 Hour'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'after-1-Hr');
                if (this.showHeaderOfTemplate['after-1-Hr'] && this.showHeaderTextOfTemplate['after-1-Hr']) {
                  this.addContentDetail.setControl('headerText-after-1-Hr', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-after-1-Hr')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['after-1-Hr']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-after-1-Hr-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-after-1-Hr-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 3) {
            this.addContentDetail.addControl(`messageTitleAfter3Hr`, this.messageTitleAfter3HrControl);
            this.addContentDetail.addControl(`messageIsPopUpAfter3Hr`, this.messageIsPopUpAfter3HrControl);
            this.addContentDetail.addControl(`messagePopUpPrefAfter3Hr`, this.messagePopUpPrefAfter3HrControl);
            this.addContentDetail.addControl(`messageAfter3Hr`, this.messageAfter3HrControl);

            this.addContentDetail.get('messageTitleAfter3Hr')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageAfter3Hr')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpAfter3Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefAfter3Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['After - 3 Hour'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'after-3-Hr');
                if (this.showHeaderOfTemplate['after-3-Hr'] && this.showHeaderTextOfTemplate['after-3-Hr']) {
                  this.addContentDetail.setControl('headerText-after-3-Hr', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-after-3-Hr')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['after-3-Hr']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-after-3-Hr-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-after-3-Hr-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 6) {
            this.addContentDetail.addControl(`messageTitleAfter6Hr`, this.messageTitleAfter6HrControl);
            this.addContentDetail.addControl(`messageIsPopUpAfter6Hr`, this.messageIsPopUpAfter6HrControl);
            this.addContentDetail.addControl(`messagePopUpPrefAfter6Hr`, this.messagePopUpPrefAfter6HrControl);
            this.addContentDetail.addControl(`messageAfter6Hr`, this.messageAfter6HrControl);

            this.addContentDetail.get('messageTitleAfter6Hr')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageAfter6Hr')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpAfter6Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefAfter6Hr')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['After - 6 Hour'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'after-6-Hr');
                if (this.showHeaderOfTemplate['after-6-Hr'] && this.showHeaderTextOfTemplate['after-6-Hr']) {
                  this.addContentDetail.setControl('headerText-after-6-Hr', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-after-6-Hr')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['after-6-Hr']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-after-6-Hr-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-after-6-Hr-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Day' && notificationSchedularData['schedule']['duration'] == 1) {
            this.addContentDetail.addControl(`messageTitleAfter1Day`, this.messageTitleAfter1DayControl);
            this.addContentDetail.addControl(`messageIsPopUpAfter1Day`, this.messageIsPopUpAfter1DayControl);
            this.addContentDetail.addControl(`messagePopUpPrefAfter1Day`, this.messagePopUpPrefAfter1DayControl);
            this.addContentDetail.addControl(`messageAfter1Day`, this.messageAfter1DayControl);

            this.addContentDetail.get('messageTitleAfter1day')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageAfter1day')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpAfter1day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefAfter1day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['After - 1 Day'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'after-1-Day');
                if (this.showHeaderOfTemplate['after-1-Day'] && this.showHeaderTextOfTemplate['after-1-Day']) {
                  this.addContentDetail.setControl('headerText-after-1-Day', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-after-1-Day')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['after-1-Day']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-after-1-Day-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-after-1-Day-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
          else if (notificationSchedularData['schedule']['type'] == 'Day' && notificationSchedularData['schedule']['duration'] == 2) {
            this.addContentDetail.addControl(`messageTitleAfter2Day`, this.messageTitleAfter2DayControl);
            this.addContentDetail.addControl(`messageIsPopUpAfter2Day`, this.messageIsPopUpAfter2DayControl);
            this.addContentDetail.addControl(`messagePopUpPrefAfter2Day`, this.messagePopUpPrefAfter2DayControl);
            this.addContentDetail.addControl(`messageAfter2Day`, this.messageAfter2DayControl);

            this.addContentDetail.get('messageTitleAfter2day')?.setValue(notificationSchedularData['template']['app']['subject']);
            this.addContentDetail.get('messageAfter2day')?.setValue(notificationSchedularData['template']['app']['body']);
            this.addContentDetail.get('messageIsPopUpAfter2day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? true : false);
            this.addContentDetail.get('messagePopUpPrefAfter2day')?.setValue(notificationSchedularData['template']['popup'] != null && notificationSchedularData['template']['popup'] != undefined ? notificationSchedularData['template']['popup']['popupPref'] : '1');
            this.defaultImage['After - 2 Day'] = notificationSchedularData['template']['app']['image'] == '' ? undefined : notificationSchedularData['template']['app']['image'];

            for (const key in notificationSchedularData['template']) {
              if (key == 'whatsApp') {
                let templateSelected: any;
                this.listOfTemplate.forEach((temp: any) => {
                  if (temp['_id'] == notificationSchedularData['template'][key]['template']) {
                    templateSelected = temp;
                  }
                });
                this.selectTemplate(templateSelected, 'after-2-Day');
                if (this.showHeaderOfTemplate['after-2-Day'] && this.showHeaderTextOfTemplate['after-2-Day']) {
                  this.addContentDetail.setControl('headerText-after-2-Day', new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ]));
                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'header') {
                      content['parameters'].forEach((param: any) => {
                        if (param['type'] == 'text') {
                          this.addContentDetail.get('headerText-after-2-Day')!.setValue(param['text']);
                        }
                      });
                    }
                  });
                }
                if (this.showBodyOfTemplate['after-2-Day']) {

                  notificationSchedularData['template'][key]['whatsAppContent'].forEach((content: any) => {
                    if (content['type'] == 'body') {

                      let variableCount = content['parameters'].length;
                      if (variableCount > 0) {
                        while (variableCount > 0) {
                          this.addContentDetail.addControl(
                            `bodyText-after-2-Day-${variableCount}`,
                            new FormControl('', [
                              Validators.required,
                              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                            ])
                          );
                          variableCount--;
                        }
                      }
                      let paramCount = content['parameters'].length;
                      if (paramCount > 0) {
                        while (paramCount > 0) {
                          this.addContentDetail.get(`bodyText-after-2-Day-${paramCount}`)?.setValue(content['parameters'][content['parameters'].length - paramCount]['text']);
                          paramCount--;
                        }
                      }
                    }
                  });

                }

                if (notificationSchedularData['template'][key]['image'] != '' && notificationSchedularData['template'][key]['image'] != null && notificationSchedularData['template'][key]['image'] != undefined) {
                  this.defaultHeaderMedia = notificationSchedularData['template'][key]['image'];
                }
              }
            }

          }
        }
      });



    }
    this.isLoading = false;
  }
  onStepperClick(label: string) {
    this.listOfStepper.forEach((element: any) => {
      if (element['label'] == label) {
        this.selectedStepper = element['label'];
      }
    });
    if (this.selectedStepper != 'Content') {
      this.addContentDetail.reset();
    }
  }
  gotoStepper(label: string) {
    this.listOfStepper.forEach((element: any) => {
      if (element['label'] == label) {
        this.selectedStepper = element['label'];
      }
    });
    if (this.selectedStepper != 'Content') {
      // this.addContentDetail.reset();
    }
    if (this.selectedStepper == 'Content') {
      let isExists = false;
      this.selectedMedium.forEach((medium: any) => {
        if (medium['label'] == 'WhatsApp') {
          isExists = true;
        }

      });
      if (isExists) {
        this.showWhatsAppContent = true;
      } else {
        this.showWhatsAppContent = false;
      }
      // this.addContentDetail.reset();
    }
  }
  toggleBeforeTime() {
    this.beforeSelected = !this.beforeSelected;
    if (!this.beforeSelected) {
      this.scheduleWebinarFormDetail.removeControl('beforeDuration');
    } else {
      this.scheduleWebinarFormDetail.addControl('beforeDuration', this.beforeDurationControl);

    }
  }

  toggleAfterTime() {
    this.afterSelected = !this.afterSelected;
    if (!this.afterSelected) {
      this.scheduleWebinarFormDetail.removeControl('afterDuration');
    } else {
      this.scheduleWebinarFormDetail.addControl('afterDuration', this.afterDurationControl);

    }
  }
  getDurationForContent() {
    let data: any = [];
    // this.defaultImage={};
    if (this.beforeSelected) {
      this.listOfBeforeDuration.forEach((element: any) => {
        this.selectedBeforeTime.forEach((time: any) => {
          if (element['title'] == time['title']) {
            element['type-title'] = 'Before - ' + element['title'];
            data.push(element);
            switch (element['title']) {
              case '15 Minutes':
                this.addContentDetail.addControl(`messageTitleBefore15Min`, this.messageTitleBefore15MinControl);
                this.addContentDetail.addControl(`messageIsPopUpBefore15Min`, this.messageIsPopUpBefore15MinControl);
                this.addContentDetail.addControl(`messagePopUpPrefBefore15Min`, this.messagePopUpPrefBefore15MinControl);
                this.addContentDetail.addControl(`messageBefore15Min`, this.messageBefore15MinControl);
                break;
              case '30 Minutes':
                this.addContentDetail.addControl(`messageTitleBefore30Min`, this.messageTitleBefore30MinControl);
                this.addContentDetail.addControl(`messageIsPopUpBefore30Min`, this.messageIsPopUpBefore30MinControl);
                this.addContentDetail.addControl(`messagePopUpPrefBefore30Min`, this.messagePopUpPrefBefore30MinControl);
                this.addContentDetail.addControl(`messageBefore30Min`, this.messageBefore30MinControl);
                break;
              case '1 Hour':
                this.addContentDetail.addControl(`messageTitleBefore1Hr`, this.messageTitleBefore1HrControl);
                this.addContentDetail.addControl(`messageIsPopUpBefore1Hr`, this.messageIsPopUpBefore1HrControl);
                this.addContentDetail.addControl(`messagePopUpPrefBefore1Hr`, this.messagePopUpPrefBefore1HrControl);
                this.addContentDetail.addControl(`messageBefore1Hr`, this.messageBefore1HrControl);
                break;
              case '3 Hour':
                this.addContentDetail.addControl(`messageTitleBefore3Hr`, this.messageTitleBefore3HrControl);
                this.addContentDetail.addControl(`messageIsPopUpBefore3Hr`, this.messageIsPopUpBefore3HrControl);
                this.addContentDetail.addControl(`messagePopUpPrefBefore3Hr`, this.messagePopUpPrefBefore3HrControl);
                this.addContentDetail.addControl(`messageBefore3Hr`, this.messageBefore3HrControl);
                break;
              case '6 Hour':
                this.addContentDetail.addControl(`messageTitleBefore6Hr`, this.messageTitleBefore6HrControl);
                this.addContentDetail.addControl(`messageIsPopUpBefore6Hr`, this.messageIsPopUpBefore6HrControl);
                this.addContentDetail.addControl(`messagePopUpPrefBefore6Hr`, this.messagePopUpPrefBefore6HrControl);
                this.addContentDetail.addControl(`messageBefore6Hr`, this.messageBefore6HrControl);
                break;
              case '1 Day':
                this.addContentDetail.addControl(`messageTitleBefore1Day`, this.messageTitleBefore1DayControl);
                this.addContentDetail.addControl(`messageIsPopUpBefore1Day`, this.messageIsPopUpBefore1DayControl);
                this.addContentDetail.addControl(`messagePopUpPrefBefore1Day`, this.messagePopUpPrefBefore1DayControl);
                this.addContentDetail.addControl(`messageBefore1Day`, this.messageBefore1DayControl);
                break;
              case '2 Day':
                this.addContentDetail.addControl(`messageTitleBefore2Day`, this.messageTitleBefore2DayControl);
                this.addContentDetail.addControl(`messageIsPopUpBefore2Day`, this.messageIsPopUpBefore2DayControl);
                this.addContentDetail.addControl(`messagePopUpPrefBefore2Day`, this.messagePopUpPrefBefore2DayControl);
                this.addContentDetail.addControl(`messageBefore2Day`, this.messageBefore2DayControl);
                break;
              default:
                break;
            }

          }
        });
      });
    }
    if (this.afterSelected) {

      this.listOfAfterDuration.forEach((element: any) => {
        this.selectedAfterTime.forEach((time: any) => {
          if (element['title'] == time['title']) {
            element['type-title'] = 'After - ' + element['title'];
            data.push(element);
            switch (element['title']) {
              case '15 Minutes':
                this.addContentDetail.addControl(`messageTitleAfter15Min`, this.messageTitleAfter15MinControl);
                this.addContentDetail.addControl(`messageIsPopUpAfter15Min`, this.messageIsPopUpAfter15MinControl);
                this.addContentDetail.addControl(`messagePopUpPrefAfter15Min`, this.messagePopUpPrefAfter15MinControl);
                this.addContentDetail.addControl(`messageAfter15Min`, this.messageAfter15MinControl);
                break;
              case '30 Minutes':
                this.addContentDetail.addControl(`messageTitleAfter30Min`, this.messageTitleAfter30MinControl);
                this.addContentDetail.addControl(`messageIsPopUpAfter30Min`, this.messageIsPopUpAfter30MinControl);
                this.addContentDetail.addControl(`messagePopUpPrefAfter30Min`, this.messagePopUpPrefAfter30MinControl);
                this.addContentDetail.addControl(`messageAfter30Min`, this.messageAfter30MinControl);
                break;
              case '1 Hour':
                this.addContentDetail.addControl(`messageTitleAfter1Hr`, this.messageTitleAfter1HrControl);
                this.addContentDetail.addControl(`messageIsPopUpAfter1Hr`, this.messageIsPopUpAfter1HrControl);
                this.addContentDetail.addControl(`messagePopUpPrefAfter1Hr`, this.messagePopUpPrefAfter1HrControl);
                this.addContentDetail.addControl(`messageAfter1Hr`, this.messageAfter1HrControl);
                break;
              case '3 Hour':
                this.addContentDetail.addControl(`messageTitleAfter3Hr`, this.messageTitleAfter3HrControl);
                this.addContentDetail.addControl(`messageIsPopUpAfter3Hr`, this.messageIsPopUpAfter3HrControl);
                this.addContentDetail.addControl(`messagePopUpPrefAfter3Hr`, this.messagePopUpPrefAfter3HrControl);
                this.addContentDetail.addControl(`messageAfter3Hr`, this.messageAfter3HrControl);
                break;
              case '6 Hour':
                this.addContentDetail.addControl(`messageTitleAfter6Hr`, this.messageTitleAfter6HrControl);
                this.addContentDetail.addControl(`messageIsPopUpAfter6Hr`, this.messageIsPopUpAfter6HrControl);
                this.addContentDetail.addControl(`messagePopUpPrefAfter6Hr`, this.messagePopUpPrefAfter6HrControl);
                this.addContentDetail.addControl(`messageAfter6Hr`, this.messageAfter6HrControl);
                break;
              case '1 Day':
                this.addContentDetail.addControl(`messageTitleAfter1Day`, this.messageTitleAfter1DayControl);
                this.addContentDetail.addControl(`messageIsPopUpAfter1Day`, this.messageIsPopUpAfter1DayControl);
                this.addContentDetail.addControl(`messagePopUpPrefAfter1Day`, this.messagePopUpPrefAfter1DayControl);
                this.addContentDetail.addControl(`messageAfter1Day`, this.messageAfter1DayControl);
                break;
              case '2 Day':
                this.addContentDetail.addControl(`messageTitleAfter2Day`, this.messageTitleAfter2DayControl);
                this.addContentDetail.addControl(`messageIsPopUpAfter2Day`, this.messageIsPopUpAfter2DayControl);
                this.addContentDetail.addControl(`messagePopUpPrefAfter2Day`, this.messagePopUpPrefAfter2DayControl);
                this.addContentDetail.addControl(`messageAfter2Day`, this.messageAfter2DayControl);
                break;

              default:
                break;
            }
          }
        });
      });
    }
    return data;
  }
  isPopUpContent(val: any) {
    return this.addContentDetail.value[val];
  }
  typeSelected(event: any) {
    // this.selectedType = event.target.value;
    // if (this.selectedType == 'One-Time') {
    //   this.addCampaignDetail.addControl('campaignDateTime', this.campaignDateTimeControl);

    //   this.addCampaignDetail.removeControl('scheduleFor');
    //   this.scheduleForControl.setValue('Select Schedule For');
    //   this.addCampaignDetail.removeControl('scheduleDay');
    //   this.scheduleDayControl.setValue('Select Day');
    //   this.addCampaignDetail.removeControl('scheduleDate');
    //   this.scheduleDateControl.setValue('');
    //   this.addCampaignDetail.removeControl('scheduleTime');
    //   this.scheduleTimeControl.setValue('');
    //   this.selectedScheduleFor = null;
    // } else {
    //   this.addCampaignDetail.addControl('scheduleFor', this.scheduleForControl);
    //   this.addCampaignDetail.removeControl('campaignDateTime');
    //   this.campaignDateTimeControl.setValue('');
    // }
  }
  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    // console.log(event);
    if (this.radioSelectedValue == 'Schedule') {
      this.showCampaignDateTime = true;
    } else {
      this.addCampaignDetail.removeControl('campaignDateTime');
      this.showCampaignDateTime = false;
    }
  }
  scheduleForSelected(event: any) {
    // this.selectedScheduleFor = event.target.value;
    // if (this.selectedScheduleFor == 'Daily') {
    //   this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
    //   this.addCampaignDetail.removeControl('scheduleDay');
    //   this.addCampaignDetail.removeControl('scheduleDate');
    // } else if (this.selectedScheduleFor == 'Weekly') {
    //   this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
    //   this.addCampaignDetail.addControl('scheduleDay', this.scheduleDayControl);
    //   this.addCampaignDetail.removeControl('scheduleDate');
    // } else {
    //   this.addCampaignDetail.addControl('scheduleTime', this.scheduleTimeControl);
    //   this.addCampaignDetail.addControl('scheduleDate', this.scheduleDateControl);
    //   this.addCampaignDetail.removeControl('scheduleDay');
    // }
  }
  userTargetSelected(event: any) {
    this.selectedUserTarget = event.target.value;
    this.targetUserCountCalled = false;
    if (this.selectedUserTarget == 'Course') {
      this.addTargetUserDetail.addControl('course', this.courseControl);
    } else {
      this.addTargetUserDetail.removeControl('course');
    }
  }
  freeExcludedChange(event: any) {
    if (event.currentTarget.checked) {
      this.isFreeExcluded = true;
      this.isPaidExcluded = false;
    } else {
      this.isFreeExcluded = false;

    }
  }
  paidExcludedChange(event: any) {
    if (event.currentTarget.checked) {
      this.isFreeExcluded = false;
      this.isPaidExcluded = true;
    } else {
      this.isPaidExcluded = false;
    }
  }
  getSelectTitle(title: any) {
    switch (title) {
      case 'Course':
        return 'Select Enrolled Course';
      case 'Course Consumption':
        return 'Select Course';
      case 'Course Recommendation':
        return 'Select Enrolled Course';
      case 'Wishlist':
        return 'Select Course';
      case 'Expiry':
        return 'Select Course';
      default:
        return 'Select Course';

    }
  }
  onDaySelect(day: any) {
    console.log(day);
  }
  onDayDeSelect(day: any) {
    console.log(day);
  }
  onDaySelectAll(day: any) {
    console.log(day);
  }
  onDurationSelect(duartion: any, source: any) {
    console.log(duartion);
    if (source == 'Before') {
      switch (duartion['title']) {
        case '15 Minutes':
          this.addContentDetail.addControl(`messageTitleBefore15Min`, this.messageTitleBefore15MinControl);
          this.addContentDetail.addControl(`messageIsPopUpBefore15Min`, this.messageIsPopUpBefore15MinControl);
          this.addContentDetail.addControl(`messagePopUpPrefBefore15Min`, this.messagePopUpPrefBefore15MinControl);
          this.addContentDetail.addControl(`messageBefore15Min`, this.messageBefore15MinControl);
          break;
        case '30 Minutes':
          this.addContentDetail.addControl(`messageTitleBefore30Min`, this.messageTitleBefore30MinControl);
          this.addContentDetail.addControl(`messageIsPopUpBefore30Min`, this.messageIsPopUpBefore30MinControl);
          this.addContentDetail.addControl(`messagePopUpPrefBefore30Min`, this.messagePopUpPrefBefore30MinControl);
          this.addContentDetail.addControl(`messageBefore30Min`, this.messageBefore30MinControl);
          break;
        case '1 Hour':
          this.addContentDetail.addControl(`messageTitleBefore1Hr`, this.messageTitleBefore1HrControl);
          this.addContentDetail.addControl(`messageIsPopUpBefore1Hr`, this.messageIsPopUpBefore1HrControl);
          this.addContentDetail.addControl(`messagePopUpPrefBefore1Hr`, this.messagePopUpPrefBefore1HrControl);
          this.addContentDetail.addControl(`messageBefore1Hr`, this.messageBefore1HrControl);
          break;
        case '3 Hour':
          this.addContentDetail.addControl(`messageTitleBefore3Hr`, this.messageTitleBefore3HrControl);
          this.addContentDetail.addControl(`messageIsPopUpBefore3Hr`, this.messageIsPopUpBefore3HrControl);
          this.addContentDetail.addControl(`messagePopUpPrefBefore3Hr`, this.messagePopUpPrefBefore3HrControl);
          this.addContentDetail.addControl(`messageBefore3Hr`, this.messageBefore3HrControl);
          break;
        case '6 Hour':
          this.addContentDetail.addControl(`messageTitleBefore6Hr`, this.messageTitleBefore6HrControl);
          this.addContentDetail.addControl(`messageIsPopUpBefore6Hr`, this.messageIsPopUpBefore6HrControl);
          this.addContentDetail.addControl(`messagePopUpPrefBefore6Hr`, this.messagePopUpPrefBefore6HrControl);
          this.addContentDetail.addControl(`messageBefore6Hr`, this.messageBefore6HrControl);
          break;
        case '1 Day':
          this.addContentDetail.addControl(`messageTitleBefore1Day`, this.messageTitleBefore1DayControl);
          this.addContentDetail.addControl(`messageIsPopUpBefore1Day`, this.messageIsPopUpBefore1DayControl);
          this.addContentDetail.addControl(`messagePopUpPrefBefore1Day`, this.messagePopUpPrefBefore1DayControl);
          this.addContentDetail.addControl(`messageBefore1Day`, this.messageBefore1DayControl);
          break;
        case '2 Day':
          this.addContentDetail.addControl(`messageTitleBefore2Day`, this.messageTitleBefore2DayControl);
          this.addContentDetail.addControl(`messageIsPopUpBefore2Day`, this.messageIsPopUpBefore2DayControl);
          this.addContentDetail.addControl(`messagePopUpPrefBefore2Day`, this.messagePopUpPrefBefore2DayControl);
          this.addContentDetail.addControl(`messageBefore2Day`, this.messageBefore2DayControl);
          break;

        default:
          break;
      }
    } else {
      switch (duartion['title']) {
        case '15 Minutes':
          this.addContentDetail.addControl(`messageTitleAfter15Min`, this.messageTitleAfter15MinControl);
          this.addContentDetail.addControl(`messageIsPopUpAfter15Min`, this.messageIsPopUpAfter15MinControl);
          this.addContentDetail.addControl(`messagePopUpPrefAfter15Min`, this.messagePopUpPrefAfter15MinControl);
          this.addContentDetail.addControl(`messageAfter15Min`, this.messageAfter15MinControl);
          break;
        case '30 Minutes':
          this.addContentDetail.addControl(`messageTitleAfter30Min`, this.messageTitleAfter30MinControl);
          this.addContentDetail.addControl(`messageIsPopUpAfter30Min`, this.messageIsPopUpAfter30MinControl);
          this.addContentDetail.addControl(`messagePopUpPrefAfter30Min`, this.messagePopUpPrefAfter30MinControl);
          this.addContentDetail.addControl(`messageAfter30Min`, this.messageAfter30MinControl);
          break;
        case '1 Hour':
          this.addContentDetail.addControl(`messageTitleAfter1Hr`, this.messageTitleAfter1HrControl);
          this.addContentDetail.addControl(`messageIsPopUpAfter1Hr`, this.messageIsPopUpAfter1HrControl);
          this.addContentDetail.addControl(`messagePopUpPrefAfter1Hr`, this.messagePopUpPrefAfter1HrControl);
          this.addContentDetail.addControl(`messageAfter1Hr`, this.messageAfter1HrControl);
          break;
        case '3 Hour':
          this.addContentDetail.addControl(`messageTitleAfter3Hr`, this.messageTitleAfter3HrControl);
          this.addContentDetail.addControl(`messageIsPopUpAfter3Hr`, this.messageIsPopUpAfter3HrControl);
          this.addContentDetail.addControl(`messagePopUpPrefAfter3Hr`, this.messagePopUpPrefAfter3HrControl);
          this.addContentDetail.addControl(`messageAfter3Hr`, this.messageAfter3HrControl);
          break;
        case '6 Hour':
          this.addContentDetail.addControl(`messageTitleAfter6Hr`, this.messageTitleAfter6HrControl);
          this.addContentDetail.addControl(`messageIsPopUpAfter6Hr`, this.messageIsPopUpAfter6HrControl);
          this.addContentDetail.addControl(`messagePopUpPrefAfter6Hr`, this.messagePopUpPrefAfter6HrControl);
          this.addContentDetail.addControl(`messageAfter6Hr`, this.messageAfter6HrControl);
          break;
        case '1 Day':
          this.addContentDetail.addControl(`messageTitleAfter1Day`, this.messageTitleAfter1DayControl);
          this.addContentDetail.addControl(`messageIsPopUpAfter1Day`, this.messageIsPopUpAfter1DayControl);
          this.addContentDetail.addControl(`messagePopUpPrefAfter1Day`, this.messagePopUpPrefAfter1DayControl);
          this.addContentDetail.addControl(`messageAfter1Day`, this.messageAfter1DayControl);
          break;
        case '2 Day':
          this.addContentDetail.addControl(`messageTitleAfter2Day`, this.messageTitleAfter2DayControl);
          this.addContentDetail.addControl(`messageIsPopUpAfter2Day`, this.messageIsPopUpAfter2DayControl);
          this.addContentDetail.addControl(`messagePopUpPrefAfter2Day`, this.messagePopUpPrefAfter2DayControl);
          this.addContentDetail.addControl(`messageAfter2Day`, this.messageAfter2DayControl);
          break;

        default:
          break;
      }
    }
  }
  onDurationDeSelect(duartion: any, source: any) {
    console.log('onDurationDeSelect', duartion);
    if (source == 'Before') {
      switch (duartion['title']) {
        case '15 Minutes':
          this.addContentDetail.removeControl(`messageTitleBefore15Min`,);
          this.addContentDetail.removeControl(`messageIsPopUpBefore15Min`,);
          this.addContentDetail.removeControl(`messagePopUpPrefBefore15Min`,);
          this.addContentDetail.removeControl(`messageBefore15Min`,);
          break;
        case '30 Minutes':
          this.addContentDetail.removeControl(`messageTitleBefore30Min`,);
          this.addContentDetail.removeControl(`messageIsPopUpBefore30Min`,);
          this.addContentDetail.removeControl(`messagePopUpPrefBefore30Min`,);
          this.addContentDetail.removeControl(`messageBefore30Min`,);
          break;
        case '1 Hour':
          this.addContentDetail.removeControl(`messageTitleBefore1Hr`,);
          this.addContentDetail.removeControl(`messageIsPopUpBefore1Hr`,);
          this.addContentDetail.removeControl(`messagePopUpPrefBefore1Hr`,);
          this.addContentDetail.removeControl(`messageBefore1Hr`,);
          break;
        case '3 Hour':
          this.addContentDetail.removeControl(`messageTitleBefore3Hr`,);
          this.addContentDetail.removeControl(`messageIsPopUpBefore3Hr`,);
          this.addContentDetail.removeControl(`messagePopUpPrefBefore3Hr`,);
          this.addContentDetail.removeControl(`messageBefore3Hr`,);
          break;
        case '6 Hour':
          this.addContentDetail.removeControl(`messageTitleBefore6Hr`,);
          this.addContentDetail.removeControl(`messageIsPopUpBefore6Hr`,);
          this.addContentDetail.removeControl(`messagePopUpPrefBefore6Hr`,);
          this.addContentDetail.removeControl(`messageBefore6Hr`,);
          break;
        case '1 Day':
          this.addContentDetail.removeControl(`messageTitleBefore1Day`,);
          this.addContentDetail.removeControl(`messageIsPopUpBefore1Day`,);
          this.addContentDetail.removeControl(`messagePopUpPrefBefore1Day`,);
          this.addContentDetail.removeControl(`messageBefore1Day`,);
          break;
        case '2 Day':
          this.addContentDetail.removeControl(`messageTitleBefore2Day`,);
          this.addContentDetail.removeControl(`messageIsPopUpBefore2Day`,);
          this.addContentDetail.removeControl(`messagePopUpPrefBefore2Day`,);
          this.addContentDetail.removeControl(`messageBefore2Day`,);
          break;
        default:
          break;
      }
    } else {
      switch (duartion['title']) {
        case '15 Minutes':
          this.addContentDetail.removeControl(`messageTitleAfter15Min`,);
          this.addContentDetail.removeControl(`messageIsPopUpAfter15Min`,);
          this.addContentDetail.removeControl(`messagePopUpPrefAfter15Min`,);
          this.addContentDetail.removeControl(`messageAfter15Min`,);
          break;
        case '30 Minutes':
          this.addContentDetail.removeControl(`messageTitleAfter30Min`,);
          this.addContentDetail.removeControl(`messageIsPopUpAfter30Min`,);
          this.addContentDetail.removeControl(`messagePopUpPrefAfter30Min`,);
          this.addContentDetail.removeControl(`messageAfter30Min`,);
          break;
        case '1 Hour':
          this.addContentDetail.removeControl(`messageTitleAfter1Hr`,);
          this.addContentDetail.removeControl(`messageIsPopUpAfter1Hr`,);
          this.addContentDetail.removeControl(`messagePopUpPrefAfter1Hr`,);
          this.addContentDetail.removeControl(`messageAfter1Hr`,);
          break;
        case '3 Hour':
          this.addContentDetail.removeControl(`messageTitleAfter3Hr`,);
          this.addContentDetail.removeControl(`messageIsPopUpAfter3Hr`,);
          this.addContentDetail.removeControl(`messagePopUpPrefAfter3Hr`,);
          this.addContentDetail.removeControl(`messageAfter3Hr`,);
          break;
        case '6 Hour':
          this.addContentDetail.removeControl(`messageTitleAfter6Hr`,);
          this.addContentDetail.removeControl(`messageIsPopUpAfter6Hr`,);
          this.addContentDetail.removeControl(`messagePopUpPrefAfter6Hr`,);
          this.addContentDetail.removeControl(`messageAfter6Hr`,);
          break;
        case '1 Day':
          this.addContentDetail.removeControl(`messageTitleAfter1Day`,);
          this.addContentDetail.removeControl(`messageIsPopUpAfter1Day`,);
          this.addContentDetail.removeControl(`messagePopUpPrefAfter1Day`,);
          this.addContentDetail.removeControl(`messageAfter1Day`,);
          break;
        case '2 Day':
          this.addContentDetail.removeControl(`messageTitleAfter2Day`,);
          this.addContentDetail.removeControl(`messageIsPopUpAfter2Day`,);
          this.addContentDetail.removeControl(`messagePopUpPrefAfter2Day`,);
          this.addContentDetail.removeControl(`messageAfter2Day`,);
          break;
        default:
          break;
      }
    }
  }
  onDurationSelectAll(duartion: any, source: any) {
    console.log(duartion);
  }
  async getTargetUserCount() {
    this.isGetTargetLoading = true;
    this.targetUserCountCalled = true;
    this.targetUserCount = 0;

    let criteria: any = {};
    if (this.addTargetUserDetail.get('targetUser')?.value == 'All User') {
      criteria['allUser'] = true; criteria['coure'] = false; criteria['subscribedUser'] = false;
    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Course') {
      criteria['allUser'] = false; criteria['coure'] = true; criteria['subscribedUser'] = false;
      criteria['course'] = [];
      this.selectedCourse.forEach((element: any) => {
        criteria['course'].push(element['_id']);
      });
    } else if (this.addTargetUserDetail.get('targetUser')?.value == 'Subscribed User') {
      criteria['allUser'] = false; criteria['coure'] = false; criteria['subscribedUser'] = true;
    }
    if (this.isFreeExcluded) {
      criteria['isFreeExcluded'] = true;
    }
    if (this.isPaidExcluded) {
      criteria['isPaidExcluded'] = true;
    }
    try {
      const param = {
        criteria: criteria,
      };
      const URL = `${webApi.domain + webApi.endPoint.getTargetCountForWebinar}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.targetUserCount = data['count'];
          this.isGetTargetLoading = false;
        } else {
          this.isGetTargetLoading = false;
        }
      });
    } catch (error) {
      this.isGetTargetLoading = false;

    }
  }
  displayImage(event: any, type: any) {
    if (event.target.files.length > 0) {
      this.selectedImage[type] = event.target.files[0];
      this.selectedImageName[type] = this.selectedImage[type]['name'];
      var reader = new FileReader();
      reader.onload = (event1: any) => {
        this.defaultImage[type] = event1.target.result;
        console.log('sdsd');
      };
      reader.readAsDataURL(this.selectedImage[type]);
    }
  }
  downloadImage(url: any) {
    window.open(url);
  }
  displayPdfFile(event: any) {
    if (event.target.files.length > 0) {
      this.selectedPdfFile = event.target.files[0];
      this.selectedPdfFileName = this.selectedPdfFile['name'];
      var reader = new FileReader();
      reader.onload = (event1: any) => {
        this.defaultPdfFile = event1.target.result;
      };
      reader.readAsDataURL(this.selectedPdfFile);
    }
  }
  displayThumbnail(event: any) {
    if (event.target.files.length > 0) {
      this.selectedThumbnailImage = event.target.files[0];
      this.selectedThumbnailImageName = this.selectedThumbnailImage['name'];
      var reader = new FileReader();
      reader.onload = (event1: any) => {
        this.defaultThumbnailImage = event1.target.result;
      };
      reader.readAsDataURL(this.selectedThumbnailImage);
    }
  }
  downloadThumbnailImage(url: any) {
    window.open(url);
  }


  // WhatsApp Function 

  async syncTemplate() {
    this.isTemplateLoading = true;

    try {
      let param = {
        clientPhone: "9152615121"
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      let URL = `${WA_API.domain + WA_API.endPoint.syncAllTemplates}`;

      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.fetchAllTemplateAfterSync();
        } else {
          this.fetchAllTemplateAfterSync();
        }
      });
    } catch (error) {
      this.fetchAllTemplateAfterSync();
    }
  }
  async fetchAllTemplateAfterSync() {
    this.isTemplateLoading = true;
    this.listOfTemplate = [];
    try {
      let param = {
        phone: '9152615121',
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      let URL = `${WA_API.domain + WA_API.endPoint.fetchAllTemplates}`;

      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfTemplate = data['result'];
          this.listOfTemplate.forEach((template: any) => {
            template['nameWithLanguage'] =
              template['name'] + ' (language :- ' + template['language'] + ')';
          });
          this.listOfTemplateToDisplay = {
            'before-15-Min': this.listOfTemplate,
            'before-30-Min': this.listOfTemplate,
            'before-1-Hr': this.listOfTemplate,
            'before-3-Hr': this.listOfTemplate,
            'before-6-Hr': this.listOfTemplate,
            'before-1-Day': this.listOfTemplate,
            'before-2-Day': this.listOfTemplate,

            'after-15-Min': this.listOfTemplate,
            'after-30-Min': this.listOfTemplate,
            'after-1-Hr': this.listOfTemplate,
            'after-3-Hr': this.listOfTemplate,
            'after-6-Hr': this.listOfTemplate,
            'after-1-Day': this.listOfTemplate,
            'after-2-Day': this.listOfTemplate
          }; this.isTemplateLoading = false;
        } else {
          this.listOfTemplate = [];
          // this.listOfTemplateToDisplay = this.listOfTemplate;
          this.isTemplateLoading = false;
        }
      });
    } catch (error) {
      this.listOfTemplate = [];
      // this.listOfTemplateToDisplay = this.listOfTemplate;
      this.isTemplateLoading = false;
    }
  }
  // onItemSelect(event: any) {
  selectTemplate(temp: any, source: string) {
    this.selectedTemplate[source] = [];
    this.template[source] = [];
    this.showHeaderOfTemplate[source] = false;
    this.showHeaderMediaOfTemplate[source] = false;
    this.showHeaderTextOfTemplate[source] = false;
    this.showBodyOfTemplate[source] = false;
    this.showFooterOfTemplate = false;
    this.showButtonOfTemplate = false;
    this.selectedTemplate[source].push(temp);
    console.log(this.selectedTemplate[source]);
    this.listOfTemplate.forEach((tmplt: any) => {
      if (tmplt['_id'] == temp['_id']) {
        this.template[source] = tmplt;
      }
    });
    this.template[source]['components'].forEach((component: any) => {
      if (component['type'] == 'HEADER') {
        this.showHeaderOfTemplate[source] = true;
        if (
          component['format'] == 'VIDEO' ||
          component['format'] == 'IMAGE' ||
          component['format'] == 'DOCUMENT'
        ) {
          this.showHeaderMediaOfTemplate[source] = true;
          this.showHeaderTextOfTemplate[source] = false;
        } else {
          this.showHeaderMediaOfTemplate[source] = false;
          this.showHeaderTextOfTemplate[source] = true;

          switch (source) {
            case 'before-15-Min':
              this.addContentDetail.addControl(
                `headerText-before-15-Min`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'before-30-Min':
              this.addContentDetail.addControl(
                `headerText-before-30-Min`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'before-1-Hr':
              this.addContentDetail.addControl(
                `headerText-before-1-Hr`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'before-3-Hr':
              this.addContentDetail.addControl(
                `headerText-before-3-Hr`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'before-6-Hr':
              this.addContentDetail.addControl(
                `headerText-before-6-Hr`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'before-1-Day':
              this.addContentDetail.addControl(
                `headerText-before-1-Day`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'before-2-Day':
              this.addContentDetail.addControl(
                `headerText-before-2-Day`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            default:
              break;

          }

          switch (source) {
            case 'after-15-Min':
              this.addContentDetail.addControl(
                `headerText-after-15-Min`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'after-30-Min':
              this.addContentDetail.addControl(
                `headerText-after-30-Min`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'after-1-Hr':
              this.addContentDetail.addControl(
                `headerText-after-1-Hr`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'after-3-Hr':
              this.addContentDetail.addControl(
                `headerText-after-3-Hr`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'after-6-Hr':
              this.addContentDetail.addControl(
                `headerText-after-6-Hr`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'after-1-Day':
              this.addContentDetail.addControl(
                `headerText-after-1-Day`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            case 'after-2-Day':
              this.addContentDetail.addControl(
                `headerText-after-2-Day`,
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                ])
              );
              break;
            default:
              break;

          }
        }
      } else if (component['type'] == 'BODY') {
        this.showBodyOfTemplate[source] = true;
        this.bodyVariableCount[source] = component['text'].split('{{').length - 1;
        let variableCount = this.bodyVariableCount[source];
        if (variableCount > 0) {
          while (variableCount > 0) {

            switch (source) {
              case 'before-15-Min':
                this.addContentDetail.addControl(
                  `bodyText-before-15-Min-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'before-30-Min':
                this.addContentDetail.addControl(
                  `bodyText-before-30-Min-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'before-1-Hr':
                this.addContentDetail.addControl(
                  `bodyText-before-1-Hr-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'before-3-Hr':
                this.addContentDetail.addControl(
                  `bodyText-before-3-Hr-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'before-6-Hr':
                this.addContentDetail.addControl(
                  `bodyText-before-6-Hr-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'before-1-Day':
                this.addContentDetail.addControl(
                  `bodyText-before-1-Day-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'before-2-Day':
                this.addContentDetail.addControl(
                  `bodyText-before-2-Day-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              default:
                break;

            }

            switch (source) {
              case 'after-15-Min':
                this.addContentDetail.addControl(
                  `bodyText-after-15-Min-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'after-30-Min':
                this.addContentDetail.addControl(
                  `bodyText-after-30-Min-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'after-1-Hr':
                this.addContentDetail.addControl(
                  `bodyText-after-1-Hr-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'after-3-Hr':
                this.addContentDetail.addControl(
                  `bodyText-after-3-Hr-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'after-6-Hr':
                this.addContentDetail.addControl(
                  `bodyText-after-6-Hr-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'after-1-Day':
                this.addContentDetail.addControl(
                  `bodyText-after-1-Day-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              case 'after-2-Day':
                this.addContentDetail.addControl(
                  `bodyText-after-2-Day-${variableCount}`,
                  new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
                  ])
                );
                break;
              default:
                break;

            }


            // this.addContentDetail.addControl(
            //   `bodyText${variableCount}`,
            //   new FormControl('', [
            //     Validators.required,
            //     Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
            //   ])
            // );
            variableCount--;
          }
        }
      } else if (component['type'] == 'FOOTER') {
        this.showFooterOfTemplate = true;
      } else {
        this.showButtonOfTemplate = true;
      }
    });
    this.showTemplateBody[source] = true;
  }
  searchTemplateData(target: any, source: string) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfTemplateToDisplay[source] = [];

      this.listOfTemplate.forEach((tempate: any, index: number) => {
        if (
          String(tempate['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(this.getTemplateBody(tempate, source))
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(tempate);
        }
      });
      this.listOfTemplateToDisplay[source] = [];
      this.listOfTemplateToDisplay[source] = tempSearchList;
    } else {
      this.listOfTemplateToDisplay[source] = this.listOfTemplate;
    }
  }
  counter(i: any) {
    return new Array(i);
  }
  getTemplateBody(template: any, source: string) {
    if (template != null || template != undefined) {
      let toReturn = '';
      if (template.components.length > 0) {
        template.components.forEach((comp: any) => {
          if (comp['type'] == 'BODY') {
            toReturn = comp['text'];
          }
        });
      }
      return toReturn;
    } return '';
  }
  hideTemplateBody(source: string) {
    this.showHeaderOfTemplate[source] = false;
    this.showHeaderMediaOfTemplate[source] = false;
    this.showHeaderTextOfTemplate[source] = false;
    this.showBodyOfTemplate[source] = false;
    this.showFooterOfTemplate = false;
    this.showButtonOfTemplate = false;
    this.showTemplateBody[source] = false;
    this.template[source]['components'].forEach((component: any) => {
      if (component['type'] == 'HEADER') {
        this.showHeaderOfTemplate[source] = false;
        if (
          component['format'] == 'VIDEO' ||
          component['format'] == 'IMAGE' ||
          component['format'] == 'DOCUMENT'
        ) {
          this.showHeaderMediaOfTemplate[source] = false;
          this.showHeaderTextOfTemplate[source] = false;
        } else {
          this.showHeaderMediaOfTemplate[source] = false;
          this.showHeaderTextOfTemplate[source] = false;
          this.addContentDetail.removeControl(
            `headerText-${source}`
          );
        }
      } else if (component['type'] == 'BODY') {
        this.showBodyOfTemplate[source] = false;
        this.bodyVariableCount[source] = component['text'].split('{{').length - 1;
        let variableCount = this.bodyVariableCount[source];
        if (variableCount > 0) {
          while (variableCount > 0) {
            this.addContentDetail.removeControl(
              `bodyText-${source}-${variableCount}`
            );
            variableCount--;
          }
        }
      } else if (component['type'] == 'FOOTER') {
        this.showFooterOfTemplate = false;
      } else {
        this.showButtonOfTemplate = false;
      }
    });
    this.selectedTemplate[source] = [];

    this.listOfTemplateToDisplay[source] = this.listOfTemplate;
  }
  displayHeaderMedia(event: any) {
    this.selectedHeaderMedia = event.target.files[0];
    this.selectedHeaderMediaName = this.selectedHeaderMedia['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      if (this.selectedHeaderMedia['type'].split('/')[0] == 'image') {
        this.defaultHeaderMedia = event1.target.result;
      } else {
        let extension =
          this.selectedHeaderMedia['name'].split('.')[
          this.selectedHeaderMedia['name'].split('.').length - 1
          ];
        if (extension == 'mp4' || extension == 'mp4') {
          this.defaultHeaderMedia = '../../../../../assets/img/video.jpg';
        } else if (
          extension == 'doc' ||
          extension == 'docx' ||
          extension == 'pdf'
        ) {
          this.defaultHeaderMedia = '../../../../../assets/img/doc.png';
        } else if (extension == 'mp3') {
          this.defaultHeaderMedia = '../../../../../assets/img/audio.jpg';
        } else {
          this.defaultHeaderMedia = '../../../../../assets/img/excel.png';
        }
      }
    };
    reader.readAsDataURL(this.selectedHeaderMedia);
  }
  displayWhatsApp(event: any) {
    this.selectedWhatsAppImage = event.target.files[0];
    this.selectedWhatsAppImageName = this.selectedWhatsAppImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultWhatsAppImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedWhatsAppImage);
  }
  downloadWhatsAppImage(url: any) {
    window.open(url);
  }



  // 


  saveWebinar() {
    this.isSaveWebinarLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.set('webinarId', this.dataToEdit['_id']);
    } else {
      this.formdata.set('webinarId', '0');
    }

    if (this.selectedImage != null) {
      for (const key in this.selectedImage) {
        switch (key) {
          case 'Before - 15 Minutes':
            this.formdata.set('before-15-Min-image', this.selectedImage[key]);

            break;
          case 'Before - 30 Minutes':
            this.formdata.set('before-30-Min-image', this.selectedImage[key]);

            break;
          case 'Before - 1 Hour':
            this.formdata.set('before-1-Hr-image', this.selectedImage[key]);

            break;
          case 'Before - 6 Hour':
            this.formdata.set('before-6-Hr-image', this.selectedImage[key]);

            break;
          case 'Before - 3 Hour':
            this.formdata.set('before-3-Hr-image', this.selectedImage[key]);

            break;
          case 'Before - 1 Day':
            this.formdata.set('before-1-Day-image', this.selectedImage[key]);

            break;
          case 'Before - 2 Day':
            this.formdata.set('before-2-Day-image', this.selectedImage[key]);

            break;
          case 'After - 15 Minutes':
            this.formdata.set('after-15-Min-image', this.selectedImage[key]);

            break;
          case 'After - 30 Minutes':
            this.formdata.set('after-30-Min-image', this.selectedImage[key]);

            break;
          case 'After - 1 Hour':
            this.formdata.set('after-1-Hr-image', this.selectedImage[key]);

            break;
          case 'After - 6 Hour':
            this.formdata.set('after-6-Hr-image', this.selectedImage[key]);

            break;
          case 'After - 3 Hour':
            this.formdata.set('after-3-Hr-image', this.selectedImage[key]);

            break;
          case 'After - 1 Day':
            this.formdata.set('after-1-Day-image', this.selectedImage[key]);

            break;
          default:
            this.formdata.set('after-2-Day-image', this.selectedImage[key]);

            break;
        }
      }
    }
    if (this.selectedThumbnailImage != null) {
      this.formdata.set('thumbnail-image', this.selectedThumbnailImage);
    } else {
      if (this.defaultThumbnailImage != null && this.defaultThumbnailImage != undefined) {
        this.formdata.set('thumbnail', this.defaultThumbnailImage);
      }
    }
    if (this.selectedPdfFile != null && this.selectedPdfFile != undefined) {
      this.formdata.set('pdfLink-image', this.selectedPdfFile);
    } else {
      if (this.defaultPdfFile != null && this.defaultPdfFile != undefined) {
        this.formdata.set('pdfLink', this.defaultPdfFile);
      }
    }
    if (this.selectedHeaderMedia != null) {
      this.formdata.set('whatsApp-image', this.selectedHeaderMedia);
    }

    this.formdata.set('title', this.addCampaignDetail.get('name')?.value);
    this.formdata.set('type', this.addTargetUserDetail.get('targetUser')?.value == 'All User' ? 'ALL USERS' : (this.addTargetUserDetail.get('targetUser')?.value == 'Course' ? 'COURSE' : 'SUBSCRIPTION'));
    this.formdata.set('linkType', this.addCampaignDetail.get('linkType')?.value);
    this.formdata.set('link', this.addCampaignDetail.get('link')?.value);
    this.formdata.set('limitedSeat', this.addCampaignDetail.get('limitedSeat')?.value);
    this.formdata.set('webinarDate', this.addCampaignDetail.get('webinarDate')?.value);
    this.formdata.set('lobbyTime', this.addCampaignDetail.get('lobbyTime')?.value);
    this.formdata.set('startTime', this.addCampaignDetail.get('startTime')?.value);
    this.formdata.set('endTime', this.addCampaignDetail.get('endTime')?.value);
    this.formdata.set('webinarFor', this.addCampaignDetail.get('webinarFor')?.value);
    this.formdata.set('description', this.addCampaignDetail.get('description')?.value);

    this.formdata.set('takeAway', JSON.stringify([]));

    if (this.selectedAuthor.length > 0) {
      this.formdata.set('author', this.selectedAuthor[0]['_id']);
    }

    if (this.selectedUserTarget == 'Course') {
      let courseIds: any = [];
      this.selectedCourse.forEach((course: any) => {
        courseIds.push(course['_id']);
      });
      this.formdata.set('courses', JSON.stringify(courseIds));
    }

    if (this.selectedMedium.length > 0) {
      let medium: any = [];
      this.selectedMedium.forEach((element: any) => {
        medium.push(element['label']);
      });
      this.formdata.set('webinarMedium', JSON.stringify(medium));
    }


    let template: any = {};
    if (this.beforeSelected && this.selectedBeforeTime.length > 0) {
      this.listOfBeforeDuration.forEach((element: any) => {
        this.selectedBeforeTime.forEach((beforeTime: any) => {
          if (element['title'] == beforeTime['title']) {
            if (element['title'] == '15 Minutes') {
              template['before-15-Min'] = {};
              template['before-15-Min']['subject'] = this.addContentDetail.get('messageTitleBefore15Min')?.value;
              template['before-15-Min']['body'] = this.addContentDetail.get('messageBefore15Min')?.value;
              template['before-15-Min']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpBefore15Min')?.value;
              template['before-15-Min']['popupPref'] = this.addContentDetail.get('messagePopUpPrefBefore15Min')?.value;
              template['before-15-Min']['image'] = '';
              template['before-15-Min']['whatsAppImage'] = '';
              template['before-15-Min']['title'] = element['title'];
              template['before-15-Min']['type'] = element['type'];
              template['before-15-Min']['title-type'] = 'Before';

              if (this.selectedTemplate['before-15-Min'].length > 0) {
                template['before-15-Min']['template'] = this.template['before-15-Min']['_id'];
                template['before-15-Min']['templateName'] = this.template['before-15-Min']['name'];
                template['before-15-Min']['templateLanguage'] = this.template['before-15-Min']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['before-15-Min'] && this.showHeaderMediaOfTemplate['before-15-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['before-15-Min'] && this.showHeaderTextOfTemplate['before-15-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-before-15-Min')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['before-15-Min']) {
                  if (this.bodyVariableCount['before-15-Min'] > 0) {
                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['before-15-Min'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-before-15-Min-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['before-15-Min']['whatsAppContent'] = JSON.stringify(components);

              }


            } else if (element['title'] == '30 Minutes') {
              template['before-30-Min'] = {};
              template['before-30-Min']['subject'] = this.addContentDetail.get('messageTitleBefore30Min')?.value;
              template['before-30-Min']['body'] = this.addContentDetail.get('messageBefore30Min')?.value;
              template['before-30-Min']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpBefore30Min')?.value;
              template['before-30-Min']['popupPref'] = this.addContentDetail.get('messagePopUpPrefBefore30Min')?.value;
              template['before-30-Min']['image'] = '';
              template['before-30-Min']['whatsAppImage'] = '';
              template['before-30-Min']['title'] = element['title'];
              template['before-30-Min']['type'] = element['type'];
              template['before-30-Min']['title-type'] = 'Before';

              if (this.selectedTemplate['before-30-Min'].length > 0) {
                template['before-30-Min']['template'] = this.template['before-30-Min']['_id'];
                template['before-30-Min']['templateName'] = this.template['before-30-Min']['name'];
                template['before-30-Min']['templateLanguage'] = this.template['before-30-Min']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['before-30-Min'] && this.showHeaderMediaOfTemplate['before-30-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['before-30-Min'] && this.showHeaderTextOfTemplate['before-30-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-before-30-Min')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['before-30-Min']) {
                  if (this.bodyVariableCount['before-30-Min'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['before-30-Min'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-before-30-Min-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['before-30-Min']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '1 Hour') {
              template['before-1-Hr'] = {};
              template['before-1-Hr']['subject'] = this.addContentDetail.get('messageTitleBefore1Hr')?.value;
              template['before-1-Hr']['body'] = this.addContentDetail.get('messageBefore1Hr')?.value;
              template['before-1-Hr']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpBefore1Hr')?.value;
              template['before-1-Hr']['popupPref'] = this.addContentDetail.get('messagePopUpPrefBefore1Hr')?.value;
              template['before-1-Hr']['image'] = '';
              template['before-1-Hr']['whatsAppImage'] = '';
              template['before-1-Hr']['title'] = element['title'];
              template['before-1-Hr']['type'] = element['type'];
              template['before-1-Hr']['title-type'] = 'Before';

              if (this.selectedTemplate['before-1-Hr'].length > 0) {
                template['before-1-Hr']['template'] = this.template['before-1-Hr']['_id'];
                template['before-1-Hr']['templateName'] = this.template['before-1-Hr']['name'];
                template['before-1-Hr']['templateLanguage'] = this.template['before-1-Hr']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['before-1-Hr'] && this.showHeaderMediaOfTemplate['before-1-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['before-1-Hr'] && this.showHeaderTextOfTemplate['before-1-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-before-1-Hr')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['before-1-Hr']) {
                  if (this.bodyVariableCount['before-1-Hr'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['before-1-Hr'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-before-1-Hr-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['before-1-Hr']['whatsAppContent'] = JSON.stringify(components);
              }


            } else if (element['title'] == '3 Hour') {
              template['before-3-Hr'] = {};
              template['before-3-Hr']['subject'] = this.addContentDetail.get('messageTitleBefore3Hr')?.value;
              template['before-3-Hr']['body'] = this.addContentDetail.get('messageBefore3Hr')?.value;
              template['before-3-Hr']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpBefore3Hr')?.value;
              template['before-3-Hr']['popupPref'] = this.addContentDetail.get('messagePopUpPrefBefore3Hr')?.value;
              template['before-3-Hr']['image'] = '';
              template['before-3-Hr']['whatsAppImage'] = '';
              template['before-3-Hr']['title'] = element['title'];
              template['before-3-Hr']['type'] = element['type'];
              template['before-3-Hr']['title-type'] = 'Before';

              if (this.selectedTemplate['before-3-Hr'].length > 0) {
                template['before-3-Hr']['template'] = this.template['before-3-Hr']['_id'];
                template['before-3-Hr']['templateName'] = this.template['before-3-Hr']['name'];
                template['before-3-Hr']['templateLanguage'] = this.template['before-3-Hr']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['before-3-Hr'] && this.showHeaderMediaOfTemplate['before-3-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['before-3-Hr'] && this.showHeaderTextOfTemplate['before-3-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-before-3-Hr')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['before-3-Hr']) {
                  if (this.bodyVariableCount['before-3-Hr'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['before-3-Hr'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-before-3-Hr-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['before-3-Hr']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '6 Hour') {
              template['before-6-Hr'] = {};
              template['before-6-Hr']['subject'] = this.addContentDetail.get('messageTitleBefore6Hr')?.value;
              template['before-6-Hr']['body'] = this.addContentDetail.get('messageBefore6Hr')?.value;
              template['before-6-Hr']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpBefore6Hr')?.value;
              template['before-6-Hr']['popupPref'] = this.addContentDetail.get('messagePopUpPrefBefore6Hr')?.value;
              template['before-6-Hr']['image'] = '';
              template['before-6-Hr']['whatsAppImage'] = '';
              template['before-6-Hr']['title'] = element['title'];
              template['before-6-Hr']['type'] = element['type'];
              template['before-6-Hr']['title-type'] = 'Before';

              if (this.selectedTemplate['before-6-Hr'].length > 0) {
                template['before-6-Hr']['template'] = this.template['before-6-Hr']['_id'];
                template['before-6-Hr']['templateName'] = this.template['before-6-Hr']['name'];
                template['before-6-Hr']['templateLanguage'] = this.template['before-6-Hr']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['before-6-Hr'] && this.showHeaderMediaOfTemplate['before-6-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['before-6-Hr'] && this.showHeaderTextOfTemplate['before-6-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-before-6-Hr')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['before-6-Hr']) {
                  if (this.bodyVariableCount['before-6-Hr'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['before-6-Hr'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-before-6-Hr-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['before-6-Hr']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '1 Day') {
              template['before-1-Day'] = {};
              template['before-1-Day']['subject'] = this.addContentDetail.get('messageTitleBefore1Day')?.value;
              template['before-1-Day']['body'] = this.addContentDetail.get('messageBefore1Day')?.value;
              template['before-1-Day']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpBefore1Day')?.value;
              template['before-1-Day']['popupPref'] = this.addContentDetail.get('messagePopUpPrefBefore1Day')?.value;
              template['before-1-Day']['image'] = '';
              template['before-1-Day']['whatsAppImage'] = '';
              template['before-1-Day']['title'] = element['title'];
              template['before-1-Day']['type'] = element['type'];
              template['before-1-Day']['title-type'] = 'Before';

              if (this.selectedTemplate['before-1-Day'].length > 0) {
                template['before-1-Day']['template'] = this.template['before-1-Day']['_id'];
                template['before-1-Day']['templateName'] = this.template['before-1-Day']['name'];
                template['before-1-Day']['templateLanguage'] = this.template['before-1-Day']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['before-1-Day'] && this.showHeaderMediaOfTemplate['before-1-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['before-1-Day'] && this.showHeaderTextOfTemplate['before-1-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-before-1-Day')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['before-1-Day']) {
                  if (this.bodyVariableCount['before-1-Day'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['before-1-Day'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-before-1-Day-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['before-1-Day']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '2 Day') {
              template['before-2-Day'] = {};
              template['before-2-Day']['subject'] = this.addContentDetail.get('messageTitleBefore2Day')?.value;
              template['before-2-Day']['body'] = this.addContentDetail.get('messageBefore2Day')?.value;
              template['before-2-Day']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpBefore2Day')?.value;
              template['before-2-Day']['popupPref'] = this.addContentDetail.get('messagePopUpPrefBefore2Day')?.value;
              template['before-2-Day']['image'] = '';
              template['before-2-Day']['whatsAppImage'] = '';
              template['before-2-Day']['title'] = element['title'];
              template['before-2-Day']['type'] = element['type'];
              template['before-2-Day']['title-type'] = 'Before';

              if (this.selectedTemplate['before-2-Day'].length > 0) {
                template['before-2-Day']['template'] = this.template['before-2-Day']['_id'];
                template['before-2-Day']['templateName'] = this.template['before-2-Day']['name'];
                template['before-2-Day']['templateLanguage'] = this.template['before-2-Day']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['before-2-Day'] && this.showHeaderMediaOfTemplate['before-2-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['before-2-Day'] && this.showHeaderTextOfTemplate['before-2-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-before-2-Day')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['before-2-Day']) {
                  if (this.bodyVariableCount['before-2-Day'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['before-2-Day'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-before-2-Day-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['before-2-Day']['whatsAppContent'] = JSON.stringify(components);
              }

            }
          }
        });
      });
    }
    if (this.afterSelected && this.selectedAfterTime.length > 0) {
      this.listOfAfterDuration.forEach((element: any) => {
        this.selectedAfterTime.forEach((afterTime: any) => {
          if (element['title'] == afterTime['title']) {
            if (element['title'] == '15 Minutes') {
              template['after-15-Min'] = {};
              template['after-15-Min']['subject'] = this.addContentDetail.get('messageTitleAfter15Min')?.value;
              template['after-15-Min']['body'] = this.addContentDetail.get('messageAfter15Min')?.value;
              template['after-15-Min']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpAfter15Min')?.value;
              template['after-15-Min']['popupPref'] = this.addContentDetail.get('messagePopUpPrefAfter15Min')?.value;
              template['after-15-Min']['image'] = '';
              template['after-15-Min']['whatsAppImage'] = '';
              template['after-15-Min']['title'] = element['title'];
              template['after-15-Min']['type'] = element['type'];
              template['after-15-Min']['title-type'] = 'After';

              if (this.selectedTemplate['after-15-Min'].length > 0) {
                template['after-15-Min']['template'] = this.template['after-15-Min']['_id'];
                template['after-15-Min']['templateName'] = this.template['after-15-Min']['name'];
                template['after-15-Min']['templateLanguage'] = this.template['after-15-Min']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['after-15-Min'] && this.showHeaderMediaOfTemplate['after-15-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['after-15-Min'] && this.showHeaderTextOfTemplate['after-15-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-after-15-Min')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['after-15-Min']) {
                  if (this.bodyVariableCount['after-15-Min'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['after-15-Min'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-after-15-Min-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['after-15-Min']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '30 Minutes') {
              template['after-30-Min'] = {};
              template['after-30-Min']['subject'] = this.addContentDetail.get('messageTitleAfter30Min')?.value;
              template['after-30-Min']['body'] = this.addContentDetail.get('messageAfter30Min')?.value;
              template['after-30-Min']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpAfter30Min')?.value;
              template['after-30-Min']['popupPref'] = this.addContentDetail.get('messagePopUpPrefAfter30Min')?.value;
              template['after-30-Min']['image'] = '';
              template['after-30-Min']['whatsAppImage'] = '';
              template['after-30-Min']['title'] = element['title'];
              template['after-30-Min']['type'] = element['type'];
              template['after-30-Min']['title-type'] = 'After';

              if (this.selectedTemplate['after-30-Min'].length > 0) {
                template['after-30-Min']['template'] = this.template['after-30-Min']['_id'];
                template['after-30-Min']['templateName'] = this.template['after-30-Min']['name'];
                template['after-30-Min']['templateLanguage'] = this.template['after-30-Min']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['after-30-Min'] && this.showHeaderMediaOfTemplate['after-30-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['after-30-Min'] && this.showHeaderTextOfTemplate['after-30-Min']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-after-30-Min')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['after-30-Min']) {
                  if (this.bodyVariableCount['after-30-Min'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['after-30-Min'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-after-30-Min-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['after-30-Min']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '1 Hour') {
              template['after-1-Hr'] = {};
              template['after-1-Hr']['subject'] = this.addContentDetail.get('messageTitleAfter1Hr')?.value;
              template['after-1-Hr']['body'] = this.addContentDetail.get('messageAfter1Hr')?.value;
              template['after-1-Hr']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpAfter1Hr')?.value;
              template['after-1-Hr']['popupPref'] = this.addContentDetail.get('messagePopUpPrefAfter1Hr')?.value;
              template['after-1-Hr']['image'] = '';
              template['after-1-Hr']['whatsAppImage'] = '';
              template['after-1-Hr']['title'] = element['title'];
              template['after-1-Hr']['type'] = element['type'];
              template['after-1-Hr']['title-type'] = 'After';


              if (this.selectedTemplate['after-1-Hr'].length > 0) {
                template['after-1-Hr']['template'] = this.template['after-1-Hr']['_id'];
                template['after-1-Hr']['templateName'] = this.template['after-1-Hr']['name'];
                template['after-1-Hr']['templateLanguage'] = this.template['after-1-Hr']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['after-1-Hr'] && this.showHeaderMediaOfTemplate['after-1-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['after-1-Hr'] && this.showHeaderTextOfTemplate['after-1-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-after-1-Hr')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['after-1-Hr']) {
                  if (this.bodyVariableCount['after-1-Hr'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['after-1-Hr'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-after-1-Hr-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['after-1-Hr']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '3 Hour') {
              template['after-3-Hr'] = {};
              template['after-3-Hr']['subject'] = this.addContentDetail.get('messageTitleAfter3Hr')?.value;
              template['after-3-Hr']['body'] = this.addContentDetail.get('messageAfter3Hr')?.value;
              template['after-3-Hr']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpAfter3Hr')?.value;
              template['after-3-Hr']['popupPref'] = this.addContentDetail.get('messagePopUpPrefAfter3Hr')?.value;
              template['after-3-Hr']['image'] = '';
              template['after-3-Hr']['whatsAppImage'] = '';
              template['after-3-Hr']['title'] = element['title'];
              template['after-3-Hr']['type'] = element['type'];
              template['after-3-Hr']['title-type'] = 'After';


              if (this.selectedTemplate['after-3-Hr'].length > 0) {
                template['after-3-Hr']['template'] = this.template['after-3-Hr']['_id'];
                template['after-3-Hr']['templateName'] = this.template['after-3-Hr']['name'];
                template['after-3-Hr']['templateLanguage'] = this.template['after-3-Hr']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['after-3-Hr'] && this.showHeaderMediaOfTemplate['after-3-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['after-3-Hr'] && this.showHeaderTextOfTemplate['after-3-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-after-3-Hr')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['after-3-Hr']) {
                  if (this.bodyVariableCount['after-3-Hr'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['after-3-Hr'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-after-3-Hr-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['after-3-Hr']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '6 Hour') {
              template['after-6-Hr'] = {};
              template['after-6-Hr']['subject'] = this.addContentDetail.get('messageTitleAfter6Hr')?.value;
              template['after-6-Hr']['body'] = this.addContentDetail.get('messageAfter6Hr')?.value;
              template['after-6-Hr']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpAfter6Hr')?.value;
              template['after-6-Hr']['popupPref'] = this.addContentDetail.get('messagePopUpPrefAfter6Hr')?.value;
              template['after-6-Hr']['image'] = '';
              template['after-6-Hr']['whatsAppImage'] = '';
              template['after-6-Hr']['title'] = element['title'];
              template['after-6-Hr']['type'] = element['type'];
              template['after-6-Hr']['title-type'] = 'After';


              if (this.selectedTemplate['after-6-Hr'].length > 0) {
                template['after-6-Hr']['template'] = this.template['after-6-Hr']['_id'];
                template['after-6-Hr']['templateName'] = this.template['after-6-Hr']['name'];
                template['after-6-Hr']['templateLanguage'] = this.template['after-6-Hr']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['after-6-Hr'] && this.showHeaderMediaOfTemplate['after-6-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['after-6-Hr'] && this.showHeaderTextOfTemplate['after-6-Hr']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-after-6-Hr')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['after-6-Hr']) {
                  if (this.bodyVariableCount['after-6-Hr'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['after-6-Hr'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-after-6-Hr-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['after-6-Hr']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '1 Day') {
              template['after-1-Day'] = {};
              template['after-1-Day']['subject'] = this.addContentDetail.get('messageTitleAfter1Day')?.value;
              template['after-1-Day']['body'] = this.addContentDetail.get('messageAfter1Day')?.value;
              template['after-1-Day']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpAfter1Day')?.value;
              template['after-1-Day']['popupPref'] = this.addContentDetail.get('messagePopUpPrefAfter1Day')?.value;
              template['after-1-Day']['image'] = '';
              template['after-1-Day']['whatsAppImage'] = '';
              template['after-1-Day']['title'] = element['title'];
              template['after-1-Day']['type'] = element['type'];
              template['after-1-Day']['title-type'] = 'After';


              if (this.selectedTemplate['after-1-Day'].length > 0) {
                template['after-1-Day']['template'] = this.template['after-1-Day']['_id'];
                template['after-1-Day']['templateName'] = this.template['after-1-Day']['name'];
                template['after-1-Day']['templateLanguage'] = this.template['after-1-Day']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['after-1-Day'] && this.showHeaderMediaOfTemplate['after-1-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['after-1-Day'] && this.showHeaderTextOfTemplate['after-1-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-after-1-Day')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['after-1-Day']) {
                  if (this.bodyVariableCount['after-1-Day'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['after-1-Day'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-after-1-Day-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['after-1-Day']['whatsAppContent'] = JSON.stringify(components);
              }

            } else if (element['title'] == '2 Day') {
              template['after-2-Day'] = {};
              template['after-2-Day']['subject'] = this.addContentDetail.get('messageTitleAfter2Day')?.value;
              template['after-2-Day']['body'] = this.addContentDetail.get('messageAfter2Day')?.value;
              template['after-2-Day']['isPopUpPref'] = this.addContentDetail.get('messageIsPopUpAfter2Day')?.value;
              template['after-2-Day']['popupPref'] = this.addContentDetail.get('messagePopUpPrefAfter2Day')?.value;
              template['after-2-Day']['image'] = '';
              template['after-2-Day']['whatsAppImage'] = '';
              template['after-2-Day']['title'] = element['title'];
              template['after-2-Day']['type'] = element['type'];
              template['after-2-Day']['title-type'] = 'After';

              if (this.selectedTemplate['after-2-Day'].length > 0) {
                template['after-2-Day']['template'] = this.template['after-2-Day']['_id'];
                template['after-2-Day']['templateName'] = this.template['after-2-Day']['name'];
                template['after-2-Day']['templateLanguage'] = this.template['after-2-Day']['language'];

                let components: any = [];
                if (this.showHeaderOfTemplate['after-2-Day'] && this.showHeaderMediaOfTemplate['after-2-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: '',
                        },
                      },
                    ],
                  });
                }
                if (this.showHeaderOfTemplate['after-2-Day'] && this.showHeaderTextOfTemplate['after-2-Day']) {
                  components.push({
                    type: 'header',
                    parameters: [
                      {
                        type: 'text',
                        text: {
                          link: this.addContentDetail.get('headerText-after-2-Day')!.value,
                        },
                      },
                    ],
                  });
                }
                if (this.showBodyOfTemplate['after-2-Day']) {
                  if (this.bodyVariableCount['after-2-Day'] > 0) {

                    components.push({
                      type: 'body',
                      parameters: [],
                    });

                    for (let index = 0; index < components.length; index++) {
                      if (components[index]['type'] == 'body') {
                        let variableCount = this.bodyVariableCount['after-2-Day'];
                        if (variableCount > 0) {
                          while (variableCount > 0) {
                            components[index]['parameters'].push({
                              type: 'text',
                              text: this.addContentDetail.get(`bodyText-after-2-Day-${variableCount}`)!
                                .value,
                            });

                            variableCount--;
                          }
                        }
                      }
                    }
                  }

                }
                template['after-2-Day']['whatsAppContent'] = JSON.stringify(components);
              }

            }
          }
        });
      });
    }
    if (this.dataToEdit != null) {

      this.dataToEdit['schedulerId'].forEach((notificationSchedularData: any) => {
        if (notificationSchedularData['type'] == 'Before') {
          if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 15) {
            if (template['before-15-Min'] != null && template['before-15-Min'] != undefined) {
              template['before-15-Min']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['before-15-Min']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 30) {
            if (template['before-30-Min'] != null && template['before-30-Min'] != undefined) {
              template['before-30-Min']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['before-30-Min']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 1) {
            if (template['before-1-Hr'] != null && template['before-1-Hr'] != undefined) {
              template['before-1-Hr']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['before-1-Hr']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 3) {
            if (template['before-3-Hr'] != null && template['before-3-Hr'] != undefined) {
              template['before-3-Hr']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['before-3-Hr']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 6) {
            if (template['before-6-Hr'] != null && template['before-6-Hr'] != undefined) {
              template['before-6-Hr']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['before-6-Hr']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Day' && notificationSchedularData['schedule']['duration'] == 1) {
            if (template['before-1-Day'] != null && template['before-1-Day'] != undefined) {
              template['before-1-Day']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['before-1-Day']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else {
            if (template['before-2-Day'] != null && template['before-2-Day'] != undefined) {
              template['before-2-Day']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['before-2-Day']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
        } else {
          if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 15) {
            if (template['after-15-Min'] != null && template['after-15-Min'] != undefined) {
              template['after-15-Min']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['after-15-Min']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Minutes' && notificationSchedularData['schedule']['duration'] == 30) {
            if (template['after-30-Min'] != null && template['after-30-Min'] != undefined) {
              template['after-30-Min']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['after-30-Min']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 1) {
            if (template['after-1-Hr'] != null && template['after-1-Hr'] != undefined) {
              template['after-1-Hr']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['after-1-Hr']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 3) {
            if (template['after-3-Hr'] != null && template['after-3-Hr'] != undefined) {
              template['after-3-Hr']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['after-3-Hr']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Hour' && notificationSchedularData['schedule']['duration'] == 6) {
            if (template['after-6-Hr'] != null && template['after-6-Hr'] != undefined) {
              template['after-6-Hr']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['after-6-Hr']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else if (notificationSchedularData['schedule']['type'] == 'Day' && notificationSchedularData['schedule']['duration'] == 1) {
            if (template['after-1-Day'] != null && template['after-1-Day'] != undefined) {
              template['after-1-Day']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['after-1-Day']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
          else {
            if (template['after-2-Day'] != null && template['after-2-Day'] != undefined) {
              template['after-2-Day']['image'] = notificationSchedularData['template']['app']['image'];
              if(notificationSchedularData['template']['whatsApp']!=null && notificationSchedularData['template']['whatsApp']!=undefined){
                template['after-2-Day']['whatsAppImage'] = notificationSchedularData['template']['whatsApp']['image'];
              }
            }
          }
        }
      });
    }


    // if (this.dataToEdit != null) {
    //   for (const key in this.dataToEdit['template']) {
    //     if (key == 'whatsApp') {
    //       if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
    //         template['whatsApp']['image'] = this.dataToEdit['template'][key]['image'];
    //       }
    //     } else if (key == 'app') {
    //       if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
    //         template['app']['image'] = this.dataToEdit['template'][key]['image'];
    //       }
    //     } else if (key == 'push') {
    //       if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
    //         template['push']['image'] = this.dataToEdit['template'][key]['image'];
    //       }
    //     }
    //     else if (key == 'popup') {
    //       if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
    //         template['popup']['image'] = this.dataToEdit['template'][key]['image'];
    //       }
    //     }
    //     else if (key == 'email') {
    //       if (this.dataToEdit['template'][key]['image'] != '' && this.dataToEdit['template'][key]['image'] != null && this.dataToEdit['template'][key]['image'] != undefined) {
    //         template['email']['image'] = this.dataToEdit['template'][key]['image'];
    //       }
    //     }
    //   }
    // }
    this.formdata.set('template', JSON.stringify(template));

    let criteria: any = {};
    if (this.isFreeExcluded) {
      criteria['isFreeExcluded'] = true;
    }
    if (this.isPaidExcluded) {
      criteria['isPaidExcluded'] = true;
    }

    this.formdata.set('criteria', JSON.stringify(criteria));

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateWebinar}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isSaveWebinarLoading = false;
                if (this.dataToEdit != null) {
                  this.showSuccessToaster('Webinar Edited successfully', 'Success');
                } else {
                  this.showSuccessToaster('Webinar Added successfully', 'Success');
                }
                this.router.navigate(['/catalog/webinar']);
              } else {
                this.isSaveWebinarLoading = false;
                if (this.dataToEdit != null) {
                  this.showWarningToaster('Unable to edit Webinar. Try again.', 'Warning');
                } else {
                  this.showWarningToaster('Unable to add Webinar. Try again.', 'Warning');
                }
              }
            });
          }
        })
        .catch((error) => {
          this.isSaveWebinarLoading = false;
          console.log('error', error);
          if (this.dataToEdit != null) {
            this.showWarningToaster('Unable to edit webinar. Try again.', 'Warning');
          } else {
            this.showWarningToaster('Unable to add webinar. Try again.', 'Warning');
          }
        });
      return;
    } catch (error) {
      this.isSaveWebinarLoading = false;
      if (this.dataToEdit != null) {
        this.showWarningToaster('Unable to edit webinar. Try again.', 'Warning');
      } else {
        this.showWarningToaster('Unable to add webinar. Try again.', 'Warning');
      }
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
