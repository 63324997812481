import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-referral-popup',
  templateUrl: './referral-popup.component.html',
  styleUrls: ['./referral-popup.component.scss'],
})
export class ReferralPopupComponent implements OnInit {
  constructor(public as: AppService, private formBuilder: FormBuilder) {
    this.listOfUser = this.as.listOfReferralsUser;
  }

 
  ngOnInit(): void {
    
  }

  listOfUser: any = [
    
  ];
}
