import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { Location } from '@angular/common';
import { DeleteModuleActivityDialogComponent } from '../delete-module-activity-dialog/delete-module-activity-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ReportReasonComponent } from '../report-reason/report-reason.component';
import { PostCommentComponent } from '../post-comment/post-comment.component';

@Component({
  selector: 'app-course-forum',
  templateUrl: './course-forum.component.html',
  styleUrls: ['./course-forum.component.scss']
})
export class CourseForumComponent implements OnInit {

  replyText: string = '';
  userDetails: any;
  myHeaders = new Headers();
  formdata = new FormData();
  selectedActivityInfo: any;
  courseId: any;
  courseDetail: any;
  listOfComment: any = [];
  listOfReply: any = [];
  isLoading: boolean = true;
  isReplyLoading: boolean = false;
  isAddReplyLoading: boolean = false;
  constructor(private fb: FormBuilder, public appService: AppService, private _location: Location, private router: Router, private matDialog: MatDialog, private toastr: ToastrService,
    private _activatedRoute: ActivatedRoute) {
    this._activatedRoute.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.courseId = allParam['params']['id'];
    });
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }

  ngOnInit(): void {
    if (this.courseId) {
      this.fetchData();
    } else {
      this._location.back();
    }

  }
  fetchData() {
    try {
      this.isLoading = true;
      let URL = `${webApi.domain + webApi.endPoint.fetchForumMainCommentWithCourseId}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.postMethodWithToken({ courseId: this.courseId }, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.courseDetail = data['result']['courseDetail'];
          this.listOfComment = data['result']['commentDetail'];
          this.listOfComment.forEach((element: any) => {
            element['isDeleting'] = false;
            element['isReplyLoading'] = false;
            element['showReportReason'] = false;
            element['showReply'] = false;
            element['showInputField'] = false;
          });
          console.log(this.listOfComment)
        }
        this.isLoading = false;
      });
    } catch (err) {
      console.log(err);
      this.isLoading = false;
    }
  }
  selectComment(comment: any) {
    this.selectedActivityInfo = comment.activity;
  }
  getAllReplies(id: any) {
    try {
      let toClose: boolean = false;
      this.listOfComment.forEach((element: any) => {
        element['isReplyLoading'] = false;
        if (element['_id'] == id) {
          element['isReplyLoading'] = true;
          if (element['showReply']) {
            toClose = true;
            element['showReply'] = false;
          } else {
            element['showReply'] = true;
          }
        }

      });
      if (toClose) {
        this.listOfComment.forEach((element: any) => {
          element['isReplyLoading'] = false;
        });
        return;
      }
      this.isReplyLoading = true;
      this.listOfReply = [];
      let URL = `${webApi.domain + webApi.endPoint.fetchForumSubComment}/${id}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfReply = data['result'];
          this.listOfReply.forEach((element: any) => {
            element['isDeleting'] = false;
          });
          console.log(this.listOfReply)
        }
        this.listOfComment.forEach((element: any) => {
          if (data['success']) {
            if (element['_id'] == id) {
              element['showReply'] = true;
            }
          }
          element['isReplyLoading'] = false;
        });
      });
    } catch (err) {
      console.log(err);
      this.listOfComment.forEach((element: any) => {
        element['showReply'] = false;
        element['isReplyLoading'] = false;
      });
    }
  }
  getTime(dt: any) {
    let commentDate: any = new Date(dt);
    let today: any = new Date();
    let diffMs = (today - commentDate);
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffDays > 0) {
      return `${diffDays > 0 ? diffDays + ' day' : ''} ago`;
    } else {
      return `${diffHrs > 0 ? diffHrs + ' H' : ''} ${diffMins > 0 ? diffMins + ' min' : 'few sec '} ago`;
    }
  }
  showReportReasonHandler(id: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { id };
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(
      ReportReasonComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {


      }
    });
  }

  showReplyInputHandler(id: any) {
    this.listOfComment.forEach((comment: any) => {
      if (comment['_id'] == id) {
        if (comment['showInputField']) {
          comment['showInputField'] = false;
        } else {
          comment['showInputField'] = true;
        }
      } else {
        comment['showInputField'] = false;
      }
    });
  }
  hideReplyInputHandler() {
    this.replyText = '';
    this.listOfComment.forEach((comment: any) => {
      comment['showInputField'] = false;
    });
  }
  deleteForumComment(id: any, type: string) {
    if (type == 'parent') {
      this.listOfComment.forEach((comment: any) => {
        comment['isDeleting'] = false;
        if (comment['_id'] == id) {
          comment['isDeleting'] = true;
        }
      });
    } else {
      this.listOfReply.forEach((comment: any) => {
        comment['isDeleting'] = false;
        if (comment['_id'] == id) {
          comment['isDeleting'] = true;
        }
      });
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Delete Comment' };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfComment.forEach((comment: any) => {
          comment['isDeleting'] = false;
        });
        return;
      } else {

        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.deleteForumComment}/${id}`;
        await this.appService
          .fetchDetailViaGet(URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              if (type == 'parent') {
                this.listOfComment.forEach((comment: any, index: number) => {
                  if (comment['_id'] == id) {
                    this.listOfComment.splice(index, 1);
                  }
                });
              } else {
                let parentId: any;
                this.listOfReply.forEach((comment: any, index: number) => {
                  if (comment['_id'] == id) {
                    this.listOfReply.splice(index, 1);
                    parentId = comment.parent['_id'];
                  }
                });
                if (parentId != null && parentId != undefined) {
                  this.listOfComment.forEach((element: any) => {
                    if (element['_id'] == parentId) {
                      if (element['replyCount'] > 0) {
                        element['replyCount'] = element['replyCount'] - 1;
                      }
                    }
                  });
                }
              }
              this.showSuccessToaster('Comment Delete Successfully', 'Success');
            } else {
              if (type == 'parent') {
                this.listOfComment.forEach((comment: any, index: number) => {
                  if (comment['_id'] == id) {
                    comment['isDeleting'] = false;
                  }
                });
              } else {
                this.listOfReply.forEach((comment: any, index: number) => {
                  if (comment['_id'] == id) {
                    comment['isDeleting'] = false;
                  }
                });
              }
              this.showErrorToaster(
                'Error Occurred while deleteing Comment. Try again',
                'Error'
              );
            }
          });
      }
    });
  }
  goBack() {
    this._location.back();
  }

  postComment() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { course: this.courseId };
    console.log(dialogConfig.data);
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '40vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '80vh';
    let dialogRef = this.matDialog.open(PostCommentComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        if (res == null) {
          return;
        } else {
          console.log(res);
          if (res['result']) {
            this.fetchData();
          }
        }
      }
    );
  }

  sendReplyHandler(id: any) {
    if (this.replyText == '') {
      return;
    }
    try {
      this.isAddReplyLoading = true;


      this.formdata = new FormData();

      let messageDetail = {
        type: 'Text',
        text: this.replyText,
      };

      this.formdata.set('course', this.courseDetail['_id']);
      this.formdata.set('content', JSON.stringify(messageDetail));
      this.formdata.set('parent', id);
      this.formdata.set('type', 'Reply');

      this.myHeaders.set(
        'Authorization',
        `Bearer ${this.userDetails['accessToken']}`
      );

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };

      const URL = `${webApi.domain + webApi.endPoint.createForumMessage}`;

      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            this.isAddReplyLoading = false;
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isAddReplyLoading = true;
                this.replyText = '';
                this.listOfComment.forEach((comment: any) => {
                  comment['showInputField'] = false;
                });
                if (res['result'] != null && res['result'] != undefined) {
                  if (res['result']['parent'] != null && res['result']['parent'] != undefined) {
                    this.listOfReply.splice(0, 0, res['result']);
                    this.listOfComment.forEach((comment: any) => {
                      if (comment['_id'] == res['result']['parent']['_id']) {
                        comment['replyCount']++;
                      }
                    });
                  } else {
                    this.listOfComment.splice(0, 0, res['result']);
                  }
                }
                this.showSuccessToaster(
                  'Reply comment Updated Successfully',
                  'Success'
                );

              } else {
                this.myHeaders = new Headers();
                this.showWarningToaster(
                  'Unable to update Reply comment. Please try again.',
                  'Warning'
                );

              }
            });
          }
        })
        .catch((error) => console.log('error', error));
      return;
    } catch (error) {
      this.isAddReplyLoading = false;

      this.showWarningToaster(
        'Unable to update Reply comment. Please try again.',
        'Warning'
      );

    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
