import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-add-edit-utm',
  templateUrl: './add-edit-utm.component.html',
  styleUrls: ['./add-edit-utm.component.scss']
})
export class AddEditUtmComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  course1DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'courseName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };

  isLoading: boolean = false;
  sponsored: boolean = false;

  listOfRoutes = [];
  listOfCourse = [];
  selectedCourse: any = [];

  otherParameters: any = [];

  selectedRoute: any;
  selectedPlan: any;

  myHeaders = new Headers();
  formdata = new FormData();

  mediumControl = new FormControl('', [Validators.required]);
  sourceControl = new FormControl('', [Validators.required]);
  campaignControl = new FormControl('', [Validators.required]);
  contentControl = new FormControl('', [Validators.required]);
  termControl = new FormControl('', [Validators.required]);
  routeControl = new FormControl('Select Route', [Validators.required]);
  courseControl = new FormControl('',);

  addUtmForm: FormGroup = new FormGroup({
    medium: this.mediumControl,
    source: this.sourceControl,
    campaign: this.campaignControl,
    content: this.contentControl,
    term: this.termControl,
    route: this.routeControl,
    course: this.courseControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.fetchCourse();
    this.addUtmForm = this.formBuilder.group({
      medium: this.mediumControl,
      source: this.sourceControl,
      campaign: this.campaignControl,
      content: this.contentControl,
      term: this.termControl,
      route: this.routeControl,
      course: this.courseControl,

    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addUtmForm = this.formBuilder.group({
        medium: this.mediumControl,
        source: this.sourceControl,
        campaign: this.campaignControl,
        content: this.contentControl,
        term: this.termControl,
        route: this.routeControl,
        course: this.courseControl,
      });

      this.addUtmForm.get('medium')?.setValue(this.dataToEdit['medium']);
      this.addUtmForm.get('source')?.setValue(this.dataToEdit['source']);
      this.addUtmForm.get('campaign')?.setValue(this.dataToEdit['campaign']);
      this.addUtmForm.get('content')?.setValue(this.dataToEdit['content']);
      this.addUtmForm.get('term')?.setValue(this.dataToEdit['term']);
      this.addUtmForm.get('route')?.setValue(this.dataToEdit['route']);
      this.selectedRoute = this.dataToEdit['route'];

      if (this.dataToEdit['otherParameter'] = null) {
        this.otherParameters = this.dataToEdit['otherParameter'];
      }

      if (this.selectedRoute == 'Subscription') {
        this.selectedPlan = this.dataToEdit['planName'];
      } else if (this.selectedRoute == 'Course') {
        var index = this.listOfCourse.findIndex((data: any) => data['_id'] == this.dataToEdit['subRouteId']);
        this.selectedCourse.push({
          _id: this.listOfCourse[index]['_id'],
          courseName: this.listOfCourse[index]['courseName'],
        })
      }

    }
  }

  addRemoveParameter(add: boolean, index: number) {
    if (add) {
      this.otherParameters.push({ key: '', value: '' });
    } else {
      this.otherParameters.splice(index, 1);
    }
  }

  async fetchCourse() {
    this.isLoading = true;
    this.listOfCourse = [];
    try {

      const URL = `${webApi.domain + webApi.endPoint.fetchCoursesForWebinar}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
          this.isLoading = false;
        } else {
          this.listOfCourse = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfCourse = [];
      this.isLoading = false;

    }
  }

  typeSelected(event: any) {
    this.selectedRoute = event.target.value;
    this.addUtmForm.get('route')?.setValue(this.selectedRoute);
    if (this.selectedRoute == 'Subscription') {
      this.selectedCourse = [];
      this.selectedPlan = 'Gold';
    } else {
      this.selectedPlan = null;
    }
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addEditUTM() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('utmId', this.dataToEdit['_id']);
    } else {
      this.formdata.append('utmId', '0');
    }

    this.formdata.append('medium', this.addUtmForm.get('medium')?.value);
    this.formdata.append('source', this.addUtmForm.get('source')?.value);
    this.formdata.append('campaign', this.addUtmForm.get('campaign')?.value);
    this.formdata.append('content', this.addUtmForm.get('content')?.value);
    this.formdata.append('term', this.addUtmForm.get('term')?.value);
    this.formdata.append('route', this.addUtmForm.get('route')?.value);

    this.formdata.append('otherParameter', JSON.stringify(this.otherParameters));

    if (this.selectedRoute == 'Subscription') {
      this.formdata.append('planName', this.selectedPlan);
    } else if (this.selectedRoute == 'Course') {
      this.formdata.append('subRouteId', this.selectedCourse[0]['_id']);
    }

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createUTM}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addUtmForm.reset();
                this.selectedRoute = '';
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
