import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-participant-add-delete-dialog',
  templateUrl: './participant-add-delete-dialog.component.html',
  styleUrls: ['./participant-add-delete-dialog.component.scss']
})
export class ParticipantAddDeleteDialogComponent implements OnInit {

  phone: any;
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  userDetail: any;
  httpHeaders: any;
  listOfParticipant: any = [];
  isBulkUpload: boolean = false;

  // Excel Bulk
  selectedCampaignExcel: any = null;
  selectedCampaignExcelName: any = null;
  defaultCampaignExcel: any;
  excelTarget: any;


  myHeaders = new Headers();
  formdata = new FormData();

  constructor(
    public dialogRef: MatDialogRef<ParticipantAddDeleteDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });

  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  displayExcel(event: any) {
    this.selectedCampaignExcel = event.target.files[0];
    this.selectedCampaignExcelName = this.selectedCampaignExcel['name'];
    var reader = new FileReader();
    this.excelTarget = event.target;
    reader.onload = (event1: any) => {
      this.defaultCampaignExcel = '../../../../assets/img/excel.png';
    };
    reader.readAsDataURL(this.selectedCampaignExcel);
  }


  async addMember() {
    this.isLoading = true;
    try {
      if (this.isBulkUpload) {
        this.bulkMemberUpload();
      } else {
        this.singleMemberUpload();
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  async singleMemberUpload() {
    const URL = `${webApi.domain + webApi.endPoint.addMemberInCommunity}`;
    await this.as.postMethodWithToken({ phone: this.phone, communityId: this.data['community']['_id'] }, URL, this.httpHeaders).then((data: any) => {
      if (data['success']) {
        this.dialogRef.close();
        this.closeActivityAtDialog.emit({ success: true });
        // this.closeActivityAtDialog.emit({ result: data['result'] });
      }
      this.isLoading = false;
    });
  }

  async bulkMemberUpload() {
    if (this.excelTarget.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(this.excelTarget.files[0]);

    var excelList: any = [];
    var userData: any = [];
    reader.onload = async (e: any) => {
      const storeNewxls: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(storeNewxls, {
        type: 'binary',
        cellDates: true,
      });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      excelList = data;
      userData = [];
      excelList.forEach((data: any) => {
        if (data['mobileNo']) {
          userData.push(data['mobileNo']);
        }
      });
      console.log(userData);

      this.formdata.set('communityId', this.data['community']['_id']);
      this.formdata.set('userData', JSON.stringify(userData));

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };
      let URL: any;
      URL = `${webApi.domain + webApi.endPoint.addMemberInCommunity}`;
      try {
        fetch(URL, requestOptions)
          .then((result) => {
            if (result) {
              let data = result.json();
              data.then((res) => {
                if (res['success']) {
                  this.dialogRef.close();
                  this.closeActivityAtDialog.emit({ success: true });
                }
                this.isLoading = false;
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log('error', error);
          });
        return;
      } catch (error) {
        console.log('error', error);
        this.isLoading = false;
      }
    };
  }

  async deleteMember() {
    this.isLoading = true;
    try {

      const URL = `${webApi.domain + webApi.endPoint.removeMemberFromCommunity}`;
      await this.as.postMethodWithToken({ memberId: this.data['info']['_id'] }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.dialogRef.close();
          this.closeActivityAtDialog.emit({ success: true });
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  async addParticipantTypeChange(event: any) {
    var value = event.target.value;
    if (value == 'single') {
      this.isBulkUpload = false;
    } else {
      this.isBulkUpload = true;
    }
    console.log('addParticipantTypeChange', value);
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
