import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-bulk-subscription',
  templateUrl: './bulk-subscription.component.html',
  styleUrls: ['./bulk-subscription.component.scss'],
})
export class BulkSubscriptionComponent implements OnInit {
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  httpHeaders: any;

  myHeaders = new Headers();
  formdata = new FormData();

  selectedPlan = '';

  listOfCourses: any = [];
  selectedCourses: any = [];

  listOfPlans: any = [];
  listOfPlansType: any = [];
  listOfPlanSubType: any = [];

  courseDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'courseName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    limitSelection: 1,
  };

  planControl = new FormControl('Select Plan', []);
  subTypeControl = new FormControl('Select Plan SubType', []);

  addSubscription: FormGroup = new FormGroup({
    plan: this.planControl,
    subType: this.subTypeControl,
  });

  userDetails: any;
  constructor(
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.addSubscription = this.formBuilder.group({
      plan: this.planControl,
      subType: this.subTypeControl,
    });
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    await this.fetchPlan();
    await this.fetchCourses();
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  async fetchCourses() {
    this.isLoading = true;
    const URL = `${webApi.domain + webApi.endPoint.fetchCoursesForWebinar}`;
    await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
      if (data['success']) {
        this.listOfCourses = data['result'];
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  async fetchPlan() {
    this.isLoading = true;
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.fetchPlans}`;

      await this.as
        .fetchDetail({ admin: true, all: false }, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfPlans = data['result'];
            this.listOfPlans.forEach((data: any) => {
              var i1 = this.listOfPlansType.findIndex(
                (type: any) => type == data.name
              );
              if (i1 == -1) {
                this.listOfPlansType.push(data.name);
              }
            });
            this.isLoading = false;
          } else {
            this.listOfPlans = [];
            this.listOfPlanSubType = [];
            this.listOfPlansType = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfPlans = [];
      this.listOfPlanSubType = [];
      this.listOfPlansType = [];
      this.isLoading = false;
    }
  }

  typeSelected(target: any) {
    let value = target.value;
    this.selectedPlan = value;
    this.listOfPlanSubType = [];
    if (value != 'None') {
      this.listOfPlanSubType = this.listOfPlans.filter(
        (data: any) => data.name == value
      );
    }
  }

  editUser() {
    this.isLoading = true;

    var formdata = new FormData();

    formdata.append('plan', this.addSubscription.get('subType')?.value);
    formdata.append('courseId', this.selectedCourses[0]['_id']);
    formdata.append('courseName', this.selectedCourses[0]['courseName']);

    var headers = new Headers();

    headers.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: formdata,
      redirect: 'follow',
    };
    const URL = `${
      webApi.domain + webApi.endPoint.enrolSubscriptionToEnroledUser
    }`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addSubscription.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
            
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
