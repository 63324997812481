import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListShortVideosComponent } from './list-short-videos/list-short-videos.component';
import { AddEditShortVideoComponent } from './add-edit-short-video/add-edit-short-video.component';
import { ShortVideosComponent } from './short-videos.component';
import { ShortVideosRoutingModule } from './short-videos-routing.module';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from 'ng-sidebar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    ListShortVideosComponent,
    AddEditShortVideoComponent,
    ShortVideosComponent,
  ],
  imports: [
    CommonModule,
    ShortVideosRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    MatProgressSpinnerModule,
    NgMultiSelectDropDownModule,
  ],
})
export class ShortVideosModule {}
