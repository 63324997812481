import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { tgAdvisoryApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';
import { ExcelService } from '../../excel.service';
import { UserReportDialogComponent } from '../../user/user-report-dialog/user-report-dialog.component';

@Component({
  selector: 'app-list-tgadvisory-user',
  templateUrl: './list-tgadvisory-user.component.html',
  styleUrls: ['./list-tgadvisory-user.component.scss']
})
export class ListTgadvisoryUserComponent implements OnInit {

  isAddEditCalled: boolean = false;
  isFilter: boolean = false;
  opened: boolean = false;

  userDataToEdit: any;

  dataToEdit: any;
  filerToEdit: any;

  p: any = 1;
  count: any = 50;
  limit: any = 200;
  skip: any = 0;
  totalCount: any = 0;
  index: any = 1;
  picker: any;
  dateResult: any;

  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  delete: boolean = false;
  isLoadingExtraData: boolean = false;
  isLoadingData: boolean = false;
  listOfUserOriginal: any = [];
  listOfUser: any = [];
  searchedUser: any = [];
  startDate: any;
  endDate: any;
  searched: boolean = false;
  searchString: any = '';
  timer: any;
  userDetails: any;
  httpHeaders: any;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchUsers();
  }
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public excelExport: ExcelService,
    public datepipe: DatePipe
  ) { }

  userDetail: any;
  isLoading: boolean = true;
  myHeaders = new Headers();

  exportIndividualUserSummary(user: any) {
    var toExport: any = [];
    if (user['enrolled'].length >= 1) {
      toExport.push({
        Name: user['userName'],
        Email: user['email'],
        MobileNo: user['mobileNo'],
        UserId: user['_id'],
        CourseName: user['enrolled'][0]['courseName'],
        CourseId: user['enrolled'][0]['courseId'],
        CourseEnrolledDate: user['enrolled'][0]['enrolledDate'],
        CourseExpiryDate: user['enrolled'][0]['endDate'],
        CourseCompleteDate:
          user['enrolled'][0]['completeDate'] == null ||
            user['enrolled'][0]['completeDate'] == ''
            ? '-'
            : user['enrolled'][0]['completeDate'],
      });
      if (user['enrolled'].length >= 1) {
        for (let index = 1; index < user['enrolled'].length; index++) {
          var userData: any = {};
          userData = {
            CourseName: user['enrolled'][index]['courseName'],
            CourseId: user['enrolled'][index]['courseId'],
            CourseEnrolledDate: user['enrolled'][index]['enrolledDate'],
            CourseExpiryDate: user['enrolled'][index]['endDate'],
            CourseCompleteDate:
              user['enrolled'][index]['completeDate'] == null ||
                user['enrolled'][index]['completeDate'] == ''
                ? '-'
                : user['enrolled'][index]['completeDate'],
          };
          toExport.push(userData);
        }
      }
    }

    this.excelExport.exportAsExcelFile(toExport, user['userName']);
    this.listOfUser.forEach((userDetails: any) => {
      if (userDetails['_id'] == user['_id']) {
        userDetails['isDownloading'] = false;
      }
    });
  }

  exportAllUserSummary() {
    this.downloadReport = true;
    var toExport: any = [];
    var index: any = 1;
    this.listOfUser.forEach((user: any) => {
      if (user['enrolled'].length > 0) {
        toExport.push({
          SrNo: index,
          Name: user['userName'],
          Email: user['email'],
          MobileNo: user['mobileNo'],
          AppInstallationDate: user['createdAt'],
          Id: user['_id'],
          CourseName: user['enrolled'][0]['courseName'],
          CourseId: user['enrolled'][0]['courseId'],
          CourseEnrolledDate: user['enrolled'][0]['enrolledDate'],
          CourseEndDate: user['enrolled'][0]['endDate'],
          CourseCompleteDate:
            user['enrolled'][0]['completeDate'] == null ||
              user['enrolled'][0]['completeDate'] == ''
              ? '-'
              : user['enrolled'][0]['completeDate'],
        });
        if (user['enrolled'].length > 1) {
          for (let index = 1; index < user['enrolled'].length; index++) {
            var userData: any = {};
            userData = {
              Name: user['userName'],
              Email: user['email'],
              MobileNo: user['mobileNo'],
              AppInstallationDate: moment(user['createdDate']).format(
                'DD/MM/YYYY'
              ),
              Id: user['_id'],
              CourseName: user['enrolled'][index]['courseName'],
              CourseId: user['enrolled'][index]['courseId'],
              CourseEnrolledDate: user['enrolled'][index]['enrolledDate'],
              CourseEndDate: user['enrolled'][index]['endDate'],
              CourseCompleteDate:
                user['enrolled'][index]['completeDate'] == null ||
                  user['enrolled'][index]['completeDate'] == ''
                  ? '-'
                  : user['enrolled'][index]['completeDate'],
            };
            toExport.push(userData);
          }
        }
      } else {
        toExport.push({
          SrNo: index,
          Name: user['userName'],
          Email: user['email'],
          MobileNo: user['mobileNo'],
          AppInstallationDate: moment(user['createdDate']).format('DD/MM/YYYY'),
          Id: user['_id'],
          CourseName: '-',
          CourseId: '-',
          CourseEnrolledDate: '-',
          CourseEndDate: '-',
          CourseCompleteDate: '-',
        });
      }
      index = index + 1;
    });
    this.excelExport.exportAsExcelFile(toExport, 'Report Summary');
    this.downloadReport = false;
  }

  async fetchUsers() {
    this.isLoading = true;
    this.index = 1;
    const param = {
      skip: this.skip,
      limit: this.limit,
    };
    this.listOfUser = [];
    this.listOfUserOriginal = [];
    const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchAllUser}`;
    await this.as
      .fetchDetail(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          data['result']['userDetail'].forEach((user: any) => {
            user['isDownloading'] = false;
            user['isDeleting'] = false;
            user['srno'] = this.index;
            this.listOfUser.push(user);
            this.index++;
          });
          this.totalCount = data['result']['totalCount'];
          this.listOfUserOriginal = this.listOfUser;
        }
        this.isLoading = false;
      });
  }

  async fetchExtraUsers(load: boolean) {
    this.isLoadingExtraData = load;
    let param: any = {
      skip: this.skip,
      limit: this.limit,
    };
    if (this.searchString != '') {
      param['searchedString'] = this.searchString;
    }
    let filterObject: any = {};
    if (this.startDate != null) {
      filterObject['startDate'] = new Date(this.startDate.setHours(0, 0, 0, 0));
    }
    if (this.endDate != null) {
      filterObject['endDate'] = new Date(this.endDate.setHours(23, 59, 59, 59));
    }
    if (Object.keys(filterObject).length > 0) {
      param['filterObject'] = filterObject;
    }
    const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchAllUser}`;
    await this.as
      .fetchDetail(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          data['result']['userDetail'].forEach((user: any) => {
            user['isDownloading'] = false;
            user['isDeleting'] = false;
            user['srno'] = this.index;
            this.listOfUser.push(user);
            this.index++;
          });
          this.listOfUserOriginal = this.listOfUser;
          this.isLoadingExtraData = false;
        } else {
          this.isLoadingExtraData = false;
        }
      });
  }

  pageChanged(event: any) {
    this.p = event;
    console.log(event);
    if (this.startDate == null && this.endDate == null) {

      if (this.p * this.count >= this.listOfUser.length - this.count * 3) {
        this.skip = this.skip + this.limit;
        if (!this.isLoadingExtraData) this.fetchExtraUsers(false);
      } else if (this.p * this.count >= this.listOfUser.length) {
        this.skip = this.skip + this.limit;
        this.fetchExtraUsers(true);
      }

    }
  }

  emptySearchString(event: any) {
    this.searchString = this.searchString.trim();
    if (event.value == '') {
      this.searchedUser = [];
      this.searched = false;
    }
  }

  async searchUser() {
    if (this.searchString != '') {
      this.skip = 0;
      this.limit = 200;
      try {
        clearTimeout(this.timer);
        this.isLoading = true;
        this.searched = true;
        const param = {
          skip: this.skip,
          limit: this.limit,
          searchedString: String(this.searchString).toLowerCase(),
        };
        const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchAllUser}`;
        await this.as
          .fetchDetail(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.searchedUser = data['result']['userDetail'];
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
          });
      } catch (error) {
        this.isLoading = false;
        this.searchedUser = [];
      }
    } else {
      this.searchedUser = [];
    }
    this.dateResult = null;
  }

  async deleteUser(user: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Delete User', user: user };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        user['isDeleting'] = true;
        const param = {
          userManualId: user['userManualId'],
        };
        const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.deleteUser}`;
        await this.as.deleteUserFromService(param, URL).then((data: any) => {
          if (data['success']) {
            this.listOfUser.forEach((user: any, index: any) => {
              if (user['userManualId'] == user['userManualId']) {
                this.listOfUser.splice(index, 1);
              }
            });
            this.showSuccessToaster('User Deleted Successfully', 'Success');
          } else {
            user['isDeleting'] = false;
            this.showErrorToaster(
              'Error Occurred while deleting user. Try again',
              'Error'
            );
          }
        });
      }
    });
  }

  clearDateRange() {
    // this.listOfUser = [...this.listOfUserOriginal];
    // this.dateResult = null;
    if (this.endDate != null && this.startDate != null) {
      this.endDate = null;
      this.startDate = null;
      this.fetchUsers();
      this.p = 1;
      this.totalCount = 0;
      this.index = 1;
    }
    this.searched = false;
    clearTimeout(this.timer);
    this.searchString = '';
    this.searchedUser = [];
  }

  setDateNull() {
    this.startDate = null;
    this.endDate = null;
    this.searched = false;
    clearTimeout(this.timer);
    this.searchedUser = [];
    this.searchString = '';
  }

  async dateChanged(type: string, event: any) {
    this.searched = false;
    this.searchString = '';
    clearTimeout(this.timer);
    this.searchedUser = [];
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    let filterObject: any = {};
    if (this.startDate != null) {
      filterObject['startDate'] = new Date(this.startDate.setHours(0, 0, 0, 0));
    }
    if (this.endDate != null) {
      filterObject['endDate'] = new Date(this.endDate.setHours(23, 59, 59, 59));
    }

    if (this.startDate != null && this.endDate != null) {
      this.skip = 0;
      this.limit = 200;
      let param: any = {
        skip: this.skip,
        limit: this.limit,
        filterObject: filterObject,
      };
      this.isLoading = true;
      this.totalCount = 0;
      this.index = 1;
      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchAllUser}`;
      await this.as
        .fetchDetail(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfUser = [];
            this.listOfUserOriginal = [];
            data['result']['userDetail'].forEach((user: any) => {
              user['isDownloading'] = false;
              user['isDeleting'] = false;
              user['srno'] = this.index;
              this.listOfUser.push(user);
              this.index++;
            });
            this.totalCount = data['result']['totalCount'] ?? this.listOfUser.length;
            this.listOfUserOriginal = this.listOfUser;
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
    }

  }

  addNewUser() {
    this.dataToEdit = null;
    this.toggleOpened();
  }

  editUser(user: any) {
    this.dataToEdit = user;
    this.toggleOpened();
  }

  toggleOpened(): void {
    this.isFilter = false;
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isFilter = false;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing user details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding user details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        // if (this.listOfUser.length > 0) {
        //   const index = this.listOfUser.findIndex(
        //     (user: any) => user['_id'] == event['_id']
        //   );

        //   if (index == -1) {
        //     this.listOfUser.push({ ...event, srno: this.listOfUser.length + 1 });
        //     this.listOfUserOriginal = [...this.listOfUser];
        //     this.showSuccessToaster('User added successfully', 'Success');
        //   } else {
        //     this.listOfUser[index] = { ...event, srno: this.listOfUser[index]['srno'] };
        //     // this.listOfUser['srno'] = index;
        //     this.listOfUserOriginal = [...this.listOfUser];
        //     this.showSuccessToaster('User Updated successfully', 'Success');
        //   }
        // }

        // if (this.searchedUser.length > 0) {
        //   const index1 = this.searchedUser.findIndex(
        //     (user: any) => user['_id'] == event['_id']
        //   );

        //   if (index1 == -1) {
        //     this.searchedUser.push(event);
        //     this.showSuccessToaster('User added successfully', 'Success');
        //   } else {
        //     this.searchedUser[index1] = event;
        //     this.showSuccessToaster('User Updated successfully', 'Success');
        //   }
        // }

        // const index1 = (this.dataToEdit = null);
        // this.isLoading = false;
        this.skip = 0;
        this.limit = 200;
        this.fetchUsers();
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  async downloadUserReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      criteria: this.filerToEdit['criteria'],
    };
    dialogConfig.maxHeight = `95vh`;
    let dialogRef = this.matDialog.open(
      UserReportDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        return;
      } else if (res['error'] != null || res['error'] != undefined) {
        return;
      } else {
        return;
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
