<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                Add General Chat
            </h1>
        </div>
        <div (click)="cancel()">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <div style="margin-bottom: 1.5rem;max-width: 37em;padding: 0;" class="col-md-12">
        <label for="chat" class="label">Chat:</label>
        <quill-editor class="content-editor" placeholder="Type here...." name="chat" [(ngModel)]="chat">
        </quill-editor>
    </div>
    <div class="save" >
        <input type="submit"
            [ngClass]="{'save-btn-disabled':chat==null ,'save-btn':chat!=null}" value="{{'Save'}}" [disabled]="chat==null"(click)="addGeneralChat()" />
    </div>
</div>