import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ExcelService } from '../../excel.service';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';
import { AddGeneralChatComponent } from '../add-general-chat/add-general-chat.component';
import { ViewAdvisoryDescriptionComponent } from '../view-advisory-description/view-advisory-description.component';
import { Clipboard } from '@angular/cdk/clipboard';

export interface TradeAdvisory {
  _id: string;
  createdAt: Date;
  isActive: boolean;
  title: String;
  description: String;
  plans: any;
  planSubType: any;
  image: string;
  advisoryType: string;
  tradeResult: string;
  opening: number;
  closing: number;
  type: string;
  tradeType: string;
  publishDate: Date;
}
@Component({
  selector: 'app-list-trade-advisory',
  templateUrl: './list-trade-advisory.component.html',
  styleUrls: ['./list-trade-advisory.component.scss'],
})
export class ListTradeAdvisoryComponent implements OnInit {
  isLoading: boolean = false;
  isLoadingExtraData: boolean = false;
  selectedAdvisoryType: any = 'All';
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;
  generalChat: boolean = false;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    public excelExport: ExcelService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchTradeAdvisory();
  }

  // firebaseDynamicLinks: any = new FirebaseDynamicLinks(
  //   'AIzaSyBa08SXQh6cIpz6NLUq0iOLjaG76ESLXJA'
  // );

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  limit: any = 500;
  skip: any = 0;

  endDate: any;
  startDate: any;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfTradeAdvisoryToDisplay: Array<TradeAdvisory> = [];
  listOfTradeAdvisory: Array<TradeAdvisory> = [];
  listOfTradeAdvisoryOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];

  myHeaders = new Headers();

  async fetchTradeAdvisory() {
    this.listOfTradeAdvisory = [];
    this.listOfTradeAdvisoryToDisplay = [];
    try {
      this.skip = 0;
      this.limit = 500;
      this.isLoading = true;
      let param: any = {};
      if (
        this.selectedAdvisoryType != null &&
        this.selectedAdvisoryType != undefined &&
        this.selectedAdvisoryType != 'All'
      ) {
        param['skip'] = this.skip;
        param['limit'] = this.limit;
        param['advisoryType'] = this.selectedAdvisoryType;
      } else {
        param['skip'] = this.skip;
        param['limit'] = this.limit;
      }
      if (this.startDate != null && this.endDate != null) {
        param['startDate'] = new Date(this.startDate.setHours(0, 0, 0, 0));
        param['endDate'] = new Date(this.endDate.setHours(23, 59, 59, 59));
      }
      let URL = `${webApi.domain + webApi.endPoint.fetchAdvisoryForAdmin}`;

      await this.as
        .postMethodWithToken(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfTradeAdvisory = data['result'];
            this.listOfTradeAdvisoryToDisplay = [...this.listOfTradeAdvisory];
            this.isLoading = false;
          } else {
            this.listOfTradeAdvisory = [];
            this.listOfTradeAdvisoryToDisplay = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfTradeAdvisory = [];
      this.listOfTradeAdvisoryToDisplay = [];
      this.isLoading = false;
    }
  }
  async fetchExtraTradeAdvisory(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param: any = {};
      if (
        this.selectedAdvisoryType != null &&
        this.selectedAdvisoryType != undefined &&
        this.selectedAdvisoryType != 'All'
      ) {
        param['skip'] = this.skip;
        param['limit'] = this.limit;
        param['advisoryType'] = this.selectedAdvisoryType;
      } else {
        param['skip'] = this.skip;
        param['limit'] = this.limit;
      }
      if (this.startDate != null && this.endDate != null) {
        param['startDate'] = new Date(this.startDate.setHours(0, 0, 0, 0));
        param['endDate'] = new Date(this.endDate.setHours(23, 59, 59, 59));
      }
      const URL = `${webApi.domain + webApi.endPoint.fetchUserForAdmin}`;
      await this.as
        .postMethodWithToken(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfTradeAdvisory = data['result'];
            data['result'].forEach((user: any) => {
              this.listOfTradeAdvisory.push(user);
            });
            this.listOfTradeAdvisoryToDisplay = [...this.listOfTradeAdvisory];
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.isLoading = false;
    }
  }
  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfTradeAdvisoryToDisplay = [];
      this.listOfTradeAdvisory.forEach((advisory: any, index: number) => {
        if (
          String(advisory.language)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(advisory.categoryId.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(advisory);
        }
      });
      this.listOfTradeAdvisoryToDisplay = tempSearchList;
    } else {
      this.listOfTradeAdvisoryToDisplay = [];
      this.listOfTradeAdvisoryToDisplay = this.listOfTradeAdvisory;
    }
  }

  updateActiveStatus(advisory: TradeAdvisory, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { advisory: advisory, source: 'Active TradeAdvisory' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          advisoryId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };

        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusAdvisory
        }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfTradeAdvisoryToDisplay.forEach(
                (advisory: any, index: number) => {
                  if (advisory['_id'] == res['value']['_id']) {
                    advisory.isActive = isActive;
                  }
                }
              );
              this.listOfTradeAdvisory = [...this.listOfTradeAdvisoryToDisplay];
              if (isActive) {
                this.showSuccessToaster(
                  'Advisory Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Advisory Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating advisory. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  deleteTradeAdvisory(advisory: TradeAdvisory) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { advisory: advisory, source: 'Delete TradeAdvisory' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          advisoryId: res['value']['_id'],
          action: 'Delete',
        };

        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusAdvisory
        }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfTradeAdvisoryToDisplay.forEach(
                (advisory: any, i: number) => {
                  if (advisory['_id'] == res['value']['_id']) {
                    index = i;
                  }
                }
              );
              this.listOfTradeAdvisoryToDisplay.splice(index, 1);
              this.listOfTradeAdvisory = [...this.listOfTradeAdvisoryToDisplay];

              this.showSuccessToaster(
                'Advisory Deleted Successfully',
                'Success'
              );
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting advisory. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  async createLink(tradeId: string) {
    try {
      let URL = `${webApi.domain + webApi.endPoint.createDynamicLinksForTrade}`;

      await this.as
        .postMethodWithToken({ tradeId }, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.clipboard.copy(data['result']['shortLink']);
            this.showSuccessToaster('Link Copied', 'Success');
          } else {
            this.showErrorToaster('Error occur while creating link', 'Error');
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }

  addNewTradeAdvisory() {
    this.toggleOpened();
  }

  editTradeAdvisory(magazine: any) {
    let magazineToEdit;
    this.listOfTradeAdvisoryToDisplay.forEach((mgz: any) => {
      if (mgz['_id'] == magazine._id) {
        magazineToEdit = mgz;
      }
    });
    this.dataToEdit = magazineToEdit;
    this.toggleOpened();
  }
  selectAdvisoryType(target: any) {
    let value = target.value;
    this.selectedAdvisoryType = value;
    this.fetchTradeAdvisory();
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.generalChat = false;
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }

  toggleChatOpened(): void {
    this.generalChat = true;
    this.isAddEditCalled = false;
    this.opened = !this.opened;
  }

  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    this.closeSideBar(event, false);

    this.dataToEdit = null;
    this.isLoading = false;
  }

  toggleCloseChat(event: any) {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.generalChat = false;
    this.closeSideBar(event, true);
  }

  closeSideBar(event: any, list: boolean) {
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing advisory details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding advisory details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        if (!list) {
          const index = this.listOfTradeAdvisoryToDisplay.findIndex(
            (advisory: any) => advisory['_id'] == event['_id']
          );

          if (index == -1) {
            this.listOfTradeAdvisoryToDisplay.unshift(event);
            this.listOfTradeAdvisory = [...this.listOfTradeAdvisoryToDisplay];
            this.showSuccessToaster(
              'TradeAdvisory added successfully',
              'Success'
            );
          } else {
            this.listOfTradeAdvisoryToDisplay[index] = event;
            this.listOfTradeAdvisory = [...this.listOfTradeAdvisoryToDisplay];
            this.showSuccessToaster(
              'TradeAdvisory Updated successfully',
              'Success'
            );
          }
        } else {
          this.listOfTradeAdvisoryToDisplay = event.concat(
            this.listOfTradeAdvisoryToDisplay
          );
          this.listOfTradeAdvisory = [...this.listOfTradeAdvisoryToDisplay];
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  viewAdvisory(advisory: TradeAdvisory) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { advisory: advisory };
    dialogConfig.width = '40vw';
    dialogConfig.maxHeight = '70vh';
    let dialogRef = this.matDialog.open(
      ViewAdvisoryDescriptionComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
      }
    });
  }
  pageChanged(event: any) {
    this.p = event;
    console.log(event);
    if (this.startDate == null && this.endDate == null) {
      if (
        this.p * this.count >=
        this.listOfTradeAdvisory.length - this.count * 3
      ) {
        this.skip = this.skip + this.limit;
        if (!this.isLoadingExtraData) this.fetchExtraTradeAdvisory(false);
      } else if (this.p * this.count >= this.listOfTradeAdvisory.length) {
        this.skip = this.skip + this.limit;
        this.fetchExtraTradeAdvisory(true);
      }
    }
  }

  async dateChanged(type: string, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }
    try {
      if (this.startDate != null && this.endDate != null) {
        this.listOfTradeAdvisory = [];
        this.listOfTradeAdvisoryToDisplay = [];
        this.skip = 0;
        this.limit = 500;
        let param: any = {};
        param['startDate'] = new Date(this.startDate.setHours(0, 0, 0, 0));
        param['endDate'] = new Date(this.endDate.setHours(23, 59, 59, 59));
        if (
          this.selectedAdvisoryType != null &&
          this.selectedAdvisoryType != undefined &&
          this.selectedAdvisoryType != 'All'
        ) {
          param['skip'] = this.skip;
          param['limit'] = this.limit;
          param['advisoryType'] = this.selectedAdvisoryType;
        } else {
          param['skip'] = this.skip;
          param['limit'] = this.limit;
        }
        this.isLoading = true;
        const URL = `${webApi.domain + webApi.endPoint.fetchAdvisoryForAdmin}`;
        await this.as
          .postMethodWithToken(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfTradeAdvisory = data['result'];
              this.listOfTradeAdvisoryToDisplay = [...this.listOfTradeAdvisory];
              this.isLoading = false;
            } else {
              this.listOfTradeAdvisory = [];
              this.listOfTradeAdvisoryToDisplay = [];
              this.isLoading = false;
            }
          });
      }
    } catch (error) {
      this.listOfTradeAdvisory = [];
      this.listOfTradeAdvisoryToDisplay = [];
      this.isLoading = false;
    }
  }
  clearDateRange() {
    this.endDate = null;
    this.startDate = null;
    this.fetchTradeAdvisory();
  }

  downloadTrade() {
    this.downloadReport = true;
    var toExport: any = [];
    var index: any = 1;
    this.listOfTradeAdvisoryToDisplay.forEach((trade: any) => {
      if (trade.type == 'Trading') {
        var plAmount: any =
          trade.closing == 0 ||
          trade.closing == null ||
          trade.closing == undefined ||
          trade.opening == 0 ||
          trade.opening == null ||
          trade.opening == undefined
            ? 'N.A.'
            : trade['opening'] - trade['closing'];
        var plPer: any;
        if (plAmount == 'N.A.') {
          plPer = 'N.A.';
        } else {
          plPer = (plAmount / trade['opening']) * 100;
        }
        let query: any = {
          SrNo: index,
          Title: trade['title'],
          Type: trade['advisoryType'] ?? 'N.A.',
          Plans: trade['plans'].toString(),
          EntryPoint: trade['entryPoint'],
          NoOfShares: trade['noOfShares'],
          Target: trade['targets'].toString(),
          StopLoss: trade['stopLoss'],
          LossAtStopLoss: trade['lossAtStopLoss'],
          ExpectedTarget: trade['expectedTargets'].toString(),
          PublishDate: new Date(trade['publishDate'])
            .toISOString()
            .slice(0, 10),
          TradeResult:
            trade.tradeResult == null || trade.tradeResult == undefined
              ? 'N.A.'
              : trade.tradeResult,
          ClosingPrice:
            trade.closing == 0 ||
            trade.closing == null ||
            trade.closing == undefined
              ? 'N.A.'
              : trade.closing,
          OpeningPrice:
            trade.opening == 0 ||
            trade.opening == null ||
            trade.opening == undefined
              ? 'N.A.'
              : trade.opening,
          ProfitOrLossInAmount: plAmount,
          ProfitOrLossInPercentage: plPer + '%',
          Description:
            trade['description'].trim() == ''
              ? 'N.A.'
              : trade['description'].replace(/<(?:.|\n)*?>/gm, ''),
        };

        trade['updates'].forEach((data: any) => {
          query[`update${data['position']}`] = data['update'].replace(
            /<(?:.|\n)*?>/gm,
            ''
          );
        });

        toExport.push(query);
        index = index + 1;
      }
    });
    this.excelExport.exportAsExcelFile(toExport, 'Trade Report');
    this.downloadReport = false;
  }

  setDateNull() {
    // this.startDate = null;
    // this.endDate = null;
  }
}
