import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-help-video',
  templateUrl: './add-help-video.component.html',
  styleUrls: ['./add-help-video.component.scss'],
})
export class AddHelpVideoComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();
  selectedBannerImage: any = null;
  selectedBannerImageName: any = null;
  defaultbannerImage: any;

  isLoading: boolean = false;

  myHeaders = new Headers();
  formdata = new FormData();

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.defaultbannerImage =
        'https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png';
    }
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayAdhaar(event: any) {
    this.selectedBannerImage = event.target.files[0];
    this.selectedBannerImageName = this.selectedBannerImage['startDate'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultbannerImage =
        'https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png';
    };
    reader.readAsDataURL(this.selectedBannerImage);
  }

  addEditBrand() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('helpId', this.dataToEdit['_id']);
      this.formdata.append(
        'content',
        JSON.stringify(this.dataToEdit['content'])
      );
      this.formdata.append('name', this.dataToEdit['name']);
    } else {
      this.formdata.append('helpId', '0');
    }

    if (this.selectedBannerImage != null) {
      this.formdata.append('content', this.selectedBannerImage);
    }

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateVideo}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
