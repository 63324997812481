<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                Create Campaign
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addCampaignForm" [formGroup]="addCampaignForm">
        <div class="row">
            <div class="col-md-12">
                <label for="name" class="label required">Campaign Name</label>
                <input type="text" placeholder="Campaign Name" id="name" formControlName="campaignName"
                    class="input-style">
            </div>

        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <label for="name" class="label required" style="margin-bottom: 0px;">Campaign Medium</label>
            </div>
            <div class="col-md-4" style="display: flex;align-items: center;">
                <input type="radio" name="campaignMedium" formControlName="campaignMedium" value="email" class="input-checkbox-style" (change)="handleCampaignMediumChange($event)">
                <label for="name" class="label" style="display: inline-block;margin: 1em;margin-top: 1.2em;">Email</label>
            </div>
            <div class="col-md-4" style="display: flex;align-items: center;">
                <input type="radio" name="campaignMedium" formControlName="campaignMedium" value="whatsApp" class="input-checkbox-style" (change)="handleCampaignMediumChange($event)">
                <label for="name" class="label" style="display: inline-block;margin: 1em;margin-top: 1.2em;">WhatsApp</label>
            </div>
            <div class="col-md-4" style="display: flex;align-items: center;">
                <input type="radio" name="campaignMedium" formControlName="campaignMedium" value="sms" class="input-checkbox-style" (change)="handleCampaignMediumChange($event)" [disabled]="true">
                <label for="name" class="label" style="display: inline-block;margin: 1em;margin-top: 1.2em;">SMS</label>
            </div>

        </div> -->

        <!-- **START** Email Campaign -->
        <div *ngIf="(selectedCampaignMedium=='email' || selectedCampaignMedium=='sms') && selectedCampaignMedium!=null">
            <div class="row">
                <div class="col-md-12">
                    <label for="subject" class="label required">Subject</label>
                    <input type="text" placeholder="Subject" id="subject" formControlName="subject" class="input-style">
                </div>
            </div>
            <div style="margin-bottom: 1.5rem;max-width: 41em;">
                <label for="terms" class="label required">Body:</label>

                <quill-editor class="content-editor" style="height: 12rem;" placeholder="Body" name="terms"
                    formControlName="body">
                </quill-editor>
            </div>
            <div style="margin-bottom: 1.5rem;max-width: 41em;">
                <label for="terms" class="label required">Body2:</label>
                <div class="display-flex" style="    font-size: 13px;margin-bottom: 1em;">
                    <div (click)="changeView('VIEW')" [ngStyle]="{'border-bottom':isView ?'solid 2px #57d38c':'solid 2px #dededeb3'}" style="padding-bottom: 6px;">
                        VIEW
                    </div>
                    <div (click)="changeView('HTML')" [ngStyle]="{'border-bottom':!isView ?'solid 2px #57d38c':'solid 2px #dededeb3'}" style="margin-left: 1em;padding-bottom: 6px;">
                        HTML
                    </div>
                </div>
                <div *ngIf="isView">
                    <quill-editor class="content-editor" style="height: 12rem;" placeholder="Body" name="terms"
                        formControlName="body2">
                    </quill-editor>
                </div>
                <div *ngIf="!isView">
                    <textarea placeholder="Enter html" (input)="autoGrow($event)"  name="htmlContent" id="" cols="30" rows="10" style="width: 100%;" [(ngModel)]="htmlValue" [ngModelOptions]="{standalone: true}"></textarea>
                </div>

            </div>
        </div>
        <!-- **END** Email Campaign -->

        <!-- **START** WhatsApp Campaign -->

        <!-- <div *ngIf="selectedCampaignMedium=='whatsApp' && selectedCampaignMedium!=null" style="margin-bottom: 1em;">
            <div class="row" style="margin-bottom: 1em;">
                <div class="col-md-12">
                   




                    <div *ngIf="!isLoading " class="template-list">
                        <div *ngIf="!isLoading && !showTemplateBody">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div>

                                    <span style="font-size: 18px;font-weight: 500;">
                                        Choose Template
                                    </span>
                                </div>
                                <div style="display: flex;align-items: center;">
                                    <input type="text" class="search-box" placeholder="Search template" (input)="searchTemplateData($event.target)">
                                    <span (click)="syncTemplate()" style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                </div>
                            </div>
                            <hr>
                            <div style="overflow: auto;max-height: 14em;">

                                <div *ngFor="let template of listOfTemplateToDisplay">
                                    <div (click)="selectTemplate(template)" style="cursor: pointer;">
                                        <span style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                        <div style="margin-top: 10px;">
                                            {{getTemplateBody(template)}}
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isLoading &&  showTemplateBody">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div>

                                    <span style="font-size: 18px;font-weight: 500;">
                                        Selected Template
                                    </span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/images/wa-img/close.png" alt="" srcset="" style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;" (click)="hideTemplateBody()">
                                </div>
                            </div>
                            <hr>
                            <div style="overflow: auto;max-height: 14em;">

                                <div style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                    <span style="font-weight: 600;">{{selectedTemplate[0]['nameWithLanguage']}}</span>
                                    <div style="margin-top: 10px;">
                                        {{getTemplateBody(selectedTemplate[0])}}
                                    </div>
                                </div>
                                <hr>


                            </div>
                        </div>
                    </div>




                </div>

            </div>
            <div class="row" *ngIf="showHeaderOfTemplate">
                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate">
                    <label for="" class="label required">Herder Media:</label>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="image">
                                <div class="proof">
                                    <img *ngIf="defaultHeaderMedia!=null" src="{{defaultHeaderMedia}}" class="proofImage" style="height: 10em;">
                                    <div *ngIf="defaultHeaderMedia!=null" style="font-size: 10px;
                                    margin: 1em;
                                    word-break: break-word;text-align: center;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;" [title]="selectedHeaderMediaName">
                                        {{selectedHeaderMediaName}}
                                    </div>
                                    <label *ngIf="defaultHeaderMedia==null" class="imageLabel"> Upload
                                        <input (change)="displayHeaderMedia($event)" type="file"
                                            accept=".xlsx,video/*,image/*">
                                    </label>
                                    <label *ngIf="defaultHeaderMedia!=null" class="imageLabelEdit">
                                        <mat-icon class="matIcon">edit</mat-icon>
                                        <input (change)="displayHeaderMedia($event)" type="file"
                                            accept=".xlsx,video/*,image/*">
                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate">
                    <div style="margin-bottom: 1.5rem;">
                        <label for="header-text" class="label required">Header Text:</label>
                        <quill-editor class="content-editor" style="height: 12rem;" placeholder="Body" name="header-text" formControlName="headerText">
                        </quill-editor>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showBodyOfTemplate" style="margin-top: 1em;">
                <div class="col-md-6" *ngFor='let val of counter(bodyVariableCount) ;let i = index'>
                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                    <input type="text" [placeholder]="'Enter body text '+(i+1)" id="name" [formControlName]="'bodyText'+(i+1)" class="input-style">
                </div>
            </div>
            <div class="row" *ngIf="showFooterOfTemplate">

            </div>
            <div class="row" *ngIf="showButtonOfTemplate">

            </div>
        </div> -->
        <!-- **END** WhatsApp Campaign -->


        <div class="row">
            <div class="col-md-6">
                <label for="Brand Image" class="label required">Excel File:</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultCampaignExcel!=null" src="{{defaultCampaignExcel}}"
                                    class="proofImage">
                                <label *ngIf="defaultCampaignExcel==null" class="imageLabel"> Upload
                                    <input (change)="displayExcel($event)" type="file" accept=".xlsx">
                                </label>
                                <label *ngIf="defaultCampaignExcel!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayExcel($event)" type="file" accept=".xlsx">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6"
                *ngIf="(selectedCampaignMedium=='email' || selectedCampaignMedium=='sms') && selectedCampaignMedium!=null">
                <label for="Content Image" class="label ">Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultImage!=null" src="{{defaultImage}}" class="proofImage">
                                <label *ngIf="defaultImage==null" class="imageLabel"> Upload
                                    <input (change)="displayImage($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayImage($event)" type="file" accept="image/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="save"
            *ngIf="(selectedCampaignMedium=='email' || selectedCampaignMedium=='sms') && selectedCampaignMedium!=null">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addCampaignForm.invalid|| defaultCampaignExcel==null  ,'save-btn':addCampaignForm.valid  && defaultCampaignExcel!=null}"
                value="{{'Send'}}" [disabled]="addCampaignForm.invalid || defaultCampaignExcel==null "
                (click)="addCampaign()" />
        </div>
        <div class="save" *ngIf="selectedCampaignMedium=='whatsApp' && selectedCampaignMedium!=null">
            <input *ngIf="showHeaderMediaOfTemplate" type="submit"
                [ngClass]="{'save-btn-disabled':addCampaignForm.invalid|| defaultHeaderMedia==null || selectedTemplate.length==0 || defaultCampaignExcel==null,'save-btn':addCampaignForm.valid  && defaultHeaderMedia!=null &&selectedTemplate.length!=0&& defaultCampaignExcel!=null}"
                value="{{'Send'}}"
                [disabled]="addCampaignForm.invalid || defaultHeaderMedia==null || selectedTemplate.length==0 || defaultCampaignExcel==null"
                (click)="addCampaign()" />
            <input *ngIf="!showHeaderMediaOfTemplate" type="submit"
                [ngClass]="{'save-btn-disabled':addCampaignForm.invalid || selectedTemplate.length==0 || defaultCampaignExcel==null,'save-btn':addCampaignForm.valid &&selectedTemplate.length!=0 && defaultCampaignExcel!=null}"
                value="{{'Send'}}"
                [disabled]="addCampaignForm.invalid  || selectedTemplate.length==0 || defaultCampaignExcel==null"
                (click)="addCampaign()" />
        </div>

    </form>
</div>