import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-youtube-video',
  templateUrl: './add-edit-youtube-video.component.html',
  styleUrls: ['./add-edit-youtube-video.component.scss']
})
export class AddEditYoutubeVideoComponent implements OnInit {

  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  myHeaders = new Headers();
  formdata = new FormData();

  selectedType: any;

  typeControl = new FormControl('Select Type', [Validators.required]);
  titleControl = new FormControl('', [Validators.required]);
  durationControl = new FormControl('', [Validators.required]);
  videoLinkControl = new FormControl('', [Validators.required]);

  addVideoForm: FormGroup = new FormGroup({
    type: this.typeControl,
    title: this.titleControl,
    duration: this.durationControl,
    videoLink: this.videoLinkControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.addVideoForm = this.formBuilder.group({
      type: this.typeControl,
      title: this.titleControl,
      duration: this.durationControl,
      videoLink: this.videoLinkControl,

    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addVideoForm = this.formBuilder.group({
        type: this.typeControl,
        title: this.titleControl,
        duration: this.durationControl,
        videoLink: this.videoLinkControl,
      });

      this.addVideoForm.get('title')?.setValue(this.dataToEdit['title']);
      this.addVideoForm.get('duration')?.setValue(this.dataToEdit['duration']);
      this.addVideoForm.get('videoLink')?.setValue(this.dataToEdit['videoLink']);
      this.addVideoForm.get('type')?.setValue(this.dataToEdit['type']);
      this.selectedType = this.dataToEdit['type'];
    }
  }

  typeSelected(event: any) {
    this.selectedType = event.target.value;
    this.addVideoForm.get('type')?.setValue(this.selectedType);
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  createOrUpdateVideo() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('videoId', this.dataToEdit['_id']);
    } else {
      this.formdata.append('videoId', '');
    }

    this.formdata.append('type', this.addVideoForm.get('type')?.value);
    this.formdata.append('title', this.addVideoForm.get('title')?.value);
    this.formdata.append('duration', this.addVideoForm.get('duration')?.value);
    this.formdata.append('videoLink', this.addVideoForm.get('videoLink')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createOrUpdateVideo}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addVideoForm.reset();
                this.selectedType = '';
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
