import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesRoutingModule } from './courses-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddNewCourseComponent } from './add-new-course/add-new-course.component';
import { QuillModule } from 'ngx-quill';
import { AddModuleComponent } from './add-module/add-module.component';
import { AddActivityComponent } from './add-activity/add-activity.component';
import { CourseUserListComponent } from './course-user-list/course-user-list.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { AddCourseDetailComponent } from './add-course-detail/add-course-detail.component';
import { SidebarModule } from 'ng-sidebar';
import { ModuleComponent } from './module/module.component';
import { ActivityComponent } from './activity/activity.component';
import { DeleteModuleActivityDialogComponent } from './delete-module-activity-dialog/delete-module-activity-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewReviewsComponent } from './view-reviews/view-reviews.component';
import { SortDirective } from './sort.directive';
import { CourseForumComponent } from './course-forum/course-forum.component';
import { ReportReasonComponent } from './report-reason/report-reason.component';
import { PostCommentComponent } from './post-comment/post-comment.component';
@NgModule({
  declarations: [AddNewCourseComponent, AddModuleComponent,SortDirective, AddActivityComponent, CourseUserListComponent, AddCourseDetailComponent, ModuleComponent, ActivityComponent, DeleteModuleActivityDialogComponent, ViewReviewsComponent, CourseForumComponent, ReportReasonComponent, PostCommentComponent],
  imports: [
    CommonModule,
    CoursesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    NgxPaginationModule,
    SidebarModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link', 'image', 'video'],
        ],
      },
    }),
  ],
})
export class CoursesModule { }
