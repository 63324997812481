<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading" class="row top-row-sb" style="    width: 100%;">
        <div class="user-heading-text">
            Enroll Advisory User to Community
        </div>
    </div>
    <hr *ngIf="!isLoading">
    <div *ngIf="!isLoading" class="secondary-container">
        <div *ngIf="isUserInfoLoading">
            <div class="center-loading">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </div>
        <div *ngIf="!isUserInfoLoading" ng-sidebar-content style="overflow: hidden;" [formGroup]="userInfoForm">
            <div class="row top-row-sb" style="    width: 100%;">
                <div class="search">
                    <div class="search-field">
                        <input type="text" class="search__input" style="padding:1.3rem"
                            placeholder="Search user by phone & email" formControlName="searchedString"
                            (keyup.enter)="searchUser()">

                    </div>
                </div>
                <!-- class="searchUser" -->
                <div [ngClass]="{'save-btn-disabled':userInfoForm.invalid,'save-btn-enabled':userInfoForm.valid}"
                    (click)="searchUser()">
                    <h5 style="margin-bottom:0; font-size: .9rem;">Search User</h5>
                </div>
            </div>
            <div *ngIf="userInfo==null">
                <div class="no-user-found">
                    {{noUser}}
                </div>
            </div>
            <div *ngIf="userInfo!=null">
                <table class="table-style" style="margin-top: 1rem;">
                    <thead class="table-heading">
                        <th class="table-heading-content">Name</th>
                        <th class="table-heading-content">Email</th>
                        <th *ngIf="userInfo['phone']!=null" class="table-heading-content">Phone Number</th>
                        <th *ngIf="userInfo['phone']!=null" class="table-heading-content">Community Access</th>
                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data">
                            <th class="table-data-content">{{userInfo['fullName']}}</th>
                            <th class="table-data-content">{{userInfo['email']}}</th>
                            <th *ngIf="userInfo['phone']!=null" class="table-data-content">{{userInfo['phone']}}
                            </th>
                            <th class="table-data-content">{{userInfo['communityDetail']!=null
                                &&userInfo['communityDetail']!=undefined ?userInfo['communityDetail'].length:0}}</th>

                    </tbody>
                </table>
                <div style="display: flex;align-items: center;justify-content: center;margin-top: 3em;">

                    <div *ngIf="!isLoading && userInfo!=null" class="addCourseEnroll" (click)="updateCommunityAccess()">
                        <h5 style="margin-bottom:0;font-size: .9rem; ">
                            <div *ngIf="isGiveRemoveAccessLoading">
                                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                                </mat-progress-spinner>
                            </div>
                            <div *ngIf="!isGiveRemoveAccessLoading">
                                Update Community Access
                            </div>
                        </h5>
                    </div>
                </div>

            </div>

        </div>

        <div ng-sidebar-content style="margin-top: -5rem;">
            <div class="course-row" *ngIf="listOfCommunity.length>0">
                <table class="table-style">
                    <thead class="table-heading">
                        <th class="table-heading-content">Advisory Community</th>
                        <th *ngIf="userInfo!=null" class="table-heading-content">Start Date</th>
                        <th *ngIf="userInfo!=null" class="table-heading-content">End Date</th>
                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data" *ngFor="let community of listOfCommunity">
                            <th style="text-align: left;" class="table-data-content">
                                <input type="checkbox" class="checkbox" [id]="community['name']" value="Course"
                                    [checked]="community['hasAccess']" [(ngModel)]="community['checked']"
                                    (click)="giveAccessToCommunity(community,$event)">
                                <label class="name" [for]="community['name']"
                                    style="margin-left: 10px;">{{community['name']}}</label><br>
                            </th>
                            <th class="table-data-content" *ngIf="userInfo!=null">
                                <input class="date" type="date" [(ngModel)]="community['startDate']" [min]="todayDate"
                                    (change)="communityStartDateChangeHandler($event.target,community)"
                                    [disabled]="community['hasAccess'] && !isStartDateInFuture(community['startDate'])">
                            </th>
                            <th class="table-data-content" *ngIf="userInfo!=null">
                                <input class="date" type="date" [(ngModel)]="community['endDate']" [disabled]="!community['checked']"
                                    [min]="community['startDate']"
                                    (change)="communityEndDateChangeHandler($event.target,community)">
                            </th>

                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
    </div>

</div>