<div mat-dialog-title
    style="background: #465FBE;color: white;display: flex;align-items: center;justify-content: space-between;padding: 0.6em 1em;">
    <div style="font-size: 18px;font-weight: 600;">
        Report
    </div>
    <div>
        <img src="../../../../assets/svgIcons/close.svg" class="close-icon" (click)="close()">
    </div>
</div>
<mat-dialog-content
    style="max-height: 45vh;min-height: 43vh;overflow-y: scroll;max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
    <div class="container__filter" *ngIf="!isLoading">


        <div *ngIf="listOfReportedMessage.length>0">

            <div class="mb br" style="margin-bottom: 2px;" *ngFor="let msg of listOfReportedMessage">
                <div style="display: flex;align-items: center;justify-content: flex-start;">
                    <div>
                        <div class="avatar" *ngIf=" msg['user']['avatar']!=null && msg['user']['avatar']!=undefined">
                            <img [src]="msg['user']['avatar']" style="height: 100%;width: 100%;border-radius: 100%;">
                        </div>
                        <div class="no-avatar"
                            *ngIf=" msg['user']['avatar']==null ||  msg['user']['avatar']==undefined">
                            {{this.as.getAvatarText(msg['user']['fullName'])}}
                        </div>
                    </div>
                    <div  style="margin-left: 1em;font-size: 12px;font-weight: 600;">
                        {{msg['user']['fullName']}} - ({{msg['community']['name']}})
                    </div>
                </div>
                <div class="report-reason"  [ngClass]="{'report-reason-show-all':msg['showMore']}">
                    {{msg['reason']}}
                </div>
                <div (click)="toggleShowMore(msg,!msg['showMore'])" style="color: blue;font-size: 10px; margin-left: 3em;cursor: pointer;">
                    {{msg['showMore']? 'Show Less': 'Show more'}}
                </div>
            </div>
        </div>
        <div *ngIf="listOfReportedMessage.length==0">
            No Report reason available.
        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-1" type="button" (click)="close()">
            Close
        </button>
        <!-- <button class="btn btn-2" type="button" (click)="onSubmit()">
            Send
        </button> -->
    </div>

</mat-dialog-actions>