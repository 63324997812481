import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { AnalyticReportDialogComponent } from './analytic-report-dialog/analytic-report-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isLoading: boolean = true;
  isDownLoading: boolean = false;


  listOfUser: any = [];
  totalUserCount: any = 0;

  isStep1: boolean = true;
  isStep1FunnelDataLoading: boolean = true;
  step1DataCount: number = 0;
  isStep2: boolean = false;
  isStep2FunnelDataLoading: boolean = false;
  step2DataCount: number = 0;
  isStep3: boolean = false;
  isStep3FunnelDataLoading: boolean = false;
  step3DataCount: number = 0;
  isStep4: boolean = false;
  isStep4FunnelDataLoading: boolean = false;
  step4DataCount: number = 0;
  isStep5: boolean = false;
  isStep5FunnelDataLoading: boolean = false;
  step5DataCount: number = 0;
  isStep6: boolean = false;
  isStep6FunnelDataLoading: boolean = false;
  step6DataCount: number = 0;
  isStep7: boolean = false;
  isStep7FunnelDataLoading: boolean = false;
  step7DataCount: number = 0;

  userDetails: any;
  fileName: any;

  startDate: any = new Date(new Date().setDate(new Date().getDate() - 28));
  endDate: any = new Date(new Date().setDate(new Date().getDate() - 1));
  currentStep: string = 'Step 2';

  constructor(private router: Router,
    public as: AppService,
    private toastr: ToastrService,
    private matDialog: MatDialog) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }

  ngOnInit(): void {
    this.getOnlyFunnelCount();
  }
  async getOnlyFunnelCount() {
    try {
      this.isStep1FunnelDataLoading = true;
      this.isStep2FunnelDataLoading = true;
      this.isStep3FunnelDataLoading = true;
      this.isStep4FunnelDataLoading = true;
      this.isStep5FunnelDataLoading = true;
      this.isStep6FunnelDataLoading = true;
      this.isStep7FunnelDataLoading = true;
      this.step1DataCount = 0;
      this.step2DataCount = 0;
      this.step3DataCount = 0;
      this.step4DataCount = 0;
      this.step5DataCount = 0;
      this.step6DataCount = 0;
      this.step7DataCount = 0;
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getOnlyFunnelCount}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['result'] != null && data['result'] != undefined) {

            this.step1DataCount = data['result']['step1DataCount'] ?? 0;
            this.step2DataCount = data['result']['step2DataCount'] ?? 0;
            this.step3DataCount = data['result']['step3DataCount'] ?? 0;
            this.step4DataCount = data['result']['step4DataCount'] ?? 0;
            this.step5DataCount = data['result']['step5DataCount'] ?? 0;
            this.step6DataCount = data['result']['step6DataCount'] ?? 0;
            this.step7DataCount = data['result']['step7DataCount'] ?? 0;
          }
          this.isStep1FunnelDataLoading = false;
          this.isStep2FunnelDataLoading = false;
          this.isStep3FunnelDataLoading = false;
          this.isStep4FunnelDataLoading = false;
          this.isStep5FunnelDataLoading = false;
          this.isStep6FunnelDataLoading = false;
          this.isStep7FunnelDataLoading = false;
          this.callStepFunction(this.currentStep);
        });
    } catch (error) {
      this.isStep1FunnelDataLoading = false;
      this.isStep2FunnelDataLoading = false;
      this.isStep3FunnelDataLoading = false;
      this.isStep4FunnelDataLoading = false;
      this.isStep5FunnelDataLoading = false;
      this.isStep6FunnelDataLoading = false;
      this.isStep7FunnelDataLoading = false;
      this.callStepFunction(this.currentStep);
    }
  }
  async dateChanged(type: string, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      this.getOnlyFunnelCount();
    }
  }
  clearDateRange() {
    if (this.endDate != null && this.startDate != null) {
      this.endDate = null;
      this.startDate = null;
      this.getOnlyFunnelCount();
    }
  }
  stepClickHandler(val: string) {
    this.currentStep = val;
    // this.getOnlyFunnelCount();
    this.callStepFunction(this.currentStep);
  }
  callStepFunction(val: string) {

    this.isStep1 = false;
    this.isStep2 = false;
    this.isStep3 = false;
    this.isStep4 = false;
    this.isStep5 = false;
    this.isStep6 = false;
    this.isStep7 = false;
    switch (val) {
      case 'Step 1':
        this.isStep1 = true;
        this.getAppInstallData(false);
        break;
      case 'Step 2':
        this.isStep2 = true;
        this.getSingnUpData(false);
        break;
      case 'Step 3':
        this.isStep3 = true;
        this.getProfilingData(false);
        break;
      case 'Step 4':
        this.isStep4 = true;
        this.getNumberOfAppOpenAttemptData(false);
        break;
      case 'Step 5':
        this.isStep5 = true;
        this.getActivityCompletedData(false);
        break;
      case 'Step 6':
        this.isStep6 = true;
        this.getQuizData(false);
        break;

      default:
        this.isStep7 = true;
        this.getCoursePurchaseData(false);
        break;
    }
  }

  async getAppInstallData(isDownload: boolean) {
    try {
      if (isDownload) {
        this.isDownLoading = true;
      } else {
        this.isLoading = true;
        this.isStep1FunnelDataLoading = true;
        this.listOfUser = [];
        this.totalUserCount = 0;
      }
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate, fileName: this.fileName, isDownload: isDownload,
        limit: 10,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getAppInstallData}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['result'] != null && data['result'] != undefined) {
            if (isDownload) {
              this.showSuccessToaster(
                'Report is being generated.It will reflect within few minutes',
                'Please Wait'
              );
            } else {
              if (data['result']['userDetail'] != null && data['result']['userDetail'] != undefined) {
                this.listOfUser = data['result']['userDetail'];
              }
              if (data['result']['userCount'] != null && data['result']['userCount'] != undefined) {
                this.totalUserCount = data['result']['userCount'];
                this.step1DataCount = data['result']['userCount'] ?? 0;
              }

            }
          }
          if (isDownload) {
            this.isDownLoading = false;
          } else {
            this.isLoading = false;
            this.isStep1FunnelDataLoading = false;
          }
        });
    } catch (error) {
      if (isDownload) {
        this.isDownLoading = false;
      } else {
        this.isLoading = false;
        this.isStep1FunnelDataLoading = false;
      }
    }
  }
  async getSingnUpData(isDownload: boolean) {
    try {
      if (isDownload) {
        this.isDownLoading = true;
      } else {
        this.isLoading = true;
        this.isStep2FunnelDataLoading = true;
        this.listOfUser = [];
        this.totalUserCount = 0;
      }
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        limit: 10, fileName: this.fileName, isDownload: isDownload,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getSingnUpData}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            if (isDownload) {
              this.showSuccessToaster(
                'Report is being generated.It will reflect within few minutes',
                'Please Wait'
              );
            } else {
              if (data['result'] != null && data['result'] != undefined) {
                if (data['result']['userDetail'] != null && data['result']['userDetail'] != undefined) {
                  this.listOfUser = data['result']['userDetail'];
                }
                if (data['result']['userCount'] != null && data['result']['userCount'] != undefined) {
                  this.totalUserCount = data['result']['userCount'];
                  this.step2DataCount = data['result']['userCount'] ?? 0;
                }
              }
            }
          }
          if (isDownload) {
            this.isDownLoading = false;
          } else {
            this.isLoading = false;
            this.isStep2FunnelDataLoading = false;
          }
        });
    } catch (error) {
      if (isDownload) {
        this.isDownLoading = false;
      } else {
        this.isLoading = false;
        this.isStep2FunnelDataLoading = false;
      }
    }
  }
  async getProfilingData(isDownload: boolean) {
    try {
      if (isDownload) {
        this.isDownLoading = true;
      } else {
        this.isLoading = true;
        this.isStep3FunnelDataLoading = true;
        this.listOfUser = [];
        this.totalUserCount = 0;
      }
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        limit: 10, fileName: this.fileName, isDownload: isDownload,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getProfilingData}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['result'] != null && data['result'] != undefined) {
            if (isDownload) {
              this.showSuccessToaster(
                'Report is being generated.It will reflect within few minutes',
                'Please Wait'
              );
            } else {
              if (data['result']['userDetail'] != null && data['result']['userDetail'] != undefined) {
                this.listOfUser = data['result']['userDetail'];
              }
              if (data['result']['userCount'] != null && data['result']['userCount'] != undefined) {
                this.totalUserCount = data['result']['userCount'];
                this.step3DataCount = data['result']['userCount'] ?? 0;
              }
            }
          }
          if (isDownload) {
            this.isDownLoading = false;
          } else {
            this.isLoading = false;
            this.isStep3FunnelDataLoading = false;
          }
        });
    } catch (error) {
      if (isDownload) {
        this.isDownLoading = false;
      } else {
        this.isLoading = false;
        this.isStep3FunnelDataLoading = false;
      }
    }
  }
  async getNumberOfAppOpenAttemptData(isDownload: boolean) {
    try {
      if (isDownload) {
        this.isDownLoading = true;
      } else {
        this.isLoading = true;
        this.isStep4FunnelDataLoading = true;
        this.listOfUser = [];
        this.totalUserCount = 0;
      }
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        limit: 10, fileName: this.fileName, isDownload: isDownload,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getNumberOfAppOpenAttemptData}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['result'] != null && data['result'] != undefined) {
            if (isDownload) {
              this.showSuccessToaster(
                'Report is being generated.It will reflect within few minutes',
                'Please Wait'
              );
            } else {
              if (data['result']['userDetail'] != null && data['result']['userDetail'] != undefined) {
                this.listOfUser = data['result']['userDetail'];
              }
              if (data['result']['userCount'] != null && data['result']['userCount'] != undefined) {
                this.totalUserCount = data['result']['userCount'];
                this.step4DataCount = data['result']['userCount'] ?? 0;
              }
            }
          }
          if (isDownload) {
            this.isDownLoading = false;
          } else {
            this.isLoading = false;
            this.isStep4FunnelDataLoading = false;
          }
        });
    } catch (error) {
      if (isDownload) {
        this.isDownLoading = false;
      } else {
        this.isLoading = false;
        this.isStep4FunnelDataLoading = false;
      }
    }
  }
  async getActivityCompletedData(isDownload: boolean) {
    try {
      if (isDownload) {
        this.isDownLoading = true;
      } else {
        this.isLoading = true;
        this.isStep5FunnelDataLoading = true;
        this.listOfUser = [];
        this.totalUserCount = 0;
      }
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        limit: 10, fileName: this.fileName, isDownload: isDownload,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getActivityCompletedData}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['result'] != null && data['result'] != undefined) {
            if (isDownload) {
              this.showSuccessToaster(
                'Report is being generated.It will reflect within few minutes',
                'Please Wait'
              );
            } else {
              if (data['result']['userDetail'] != null && data['result']['userDetail'] != undefined) {
                this.listOfUser = data['result']['userDetail'];
              }
              if (data['result']['userCount'] != null && data['result']['userCount'] != undefined) {
                this.totalUserCount = data['result']['userCount'];
                this.step5DataCount = data['result']['userCount'] ?? 0;
              }
            }
          }
          if (isDownload) {
            this.isDownLoading = false;
          } else {
            this.isLoading = false;
            this.isStep5FunnelDataLoading = false;
          }
        });
    } catch (error) {
      if (isDownload) {
        this.isDownLoading = false;
      } else {
        this.isLoading = false;
        this.isStep5FunnelDataLoading = false;
      }
    }
  }
  async getQuizData(isDownload: boolean) {
    try {
      if (isDownload) {
        this.isDownLoading = true;
      } else {
        this.isLoading = true;
        this.isStep6FunnelDataLoading = true;
        this.listOfUser = [];
        this.totalUserCount = 0;
      }

      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        limit: 10, fileName: this.fileName, isDownload: isDownload,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getQuizData}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['result'] != null && data['result'] != undefined) {
            if (isDownload) {
              this.showSuccessToaster(
                'Report is being generated.It will reflect within few minutes',
                'Please Wait'
              );
            } else {
              if (data['result']['userDetail'] != null && data['result']['userDetail'] != undefined) {
                this.listOfUser = data['result']['userDetail'];
              }
              if (data['result']['userCount'] != null && data['result']['userCount'] != undefined) {
                this.totalUserCount = data['result']['userCount'];
                this.step6DataCount = data['result']['userCount'] ?? 0;
              }
            }
          }
          if (isDownload) {
            this.isDownLoading = false;
          } else {
            this.isLoading = false;
            this.isStep6FunnelDataLoading = false;
          }
        });
    } catch (error) {
      if (isDownload) {
        this.isDownLoading = false;
      } else {
        this.isLoading = false;
        this.isStep6FunnelDataLoading = false;
      }
    }
  }
  async getCoursePurchaseData(isDownload: boolean) {
    try {
      if (isDownload) {
        this.isDownLoading = true;
      } else {
        this.isLoading = true;
        this.isStep7FunnelDataLoading = true;
        this.listOfUser = [];
        this.totalUserCount = 0;
      }

      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        limit: 10, fileName: this.fileName, isDownload: isDownload,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getCoursePurchaseData}`;
      await this.as
        .fetchDetail(params, URL, httpHeaders)
        .then((data: any) => {
          if (data['result'] != null && data['result'] != undefined) {
            if (isDownload) {
              this.showSuccessToaster(
                'Report is being generated.It will reflect within few minutes',
                'Please Wait'
              );
            } else {
              if (data['result']['userDetail'] != null && data['result']['userDetail'] != undefined) {
                this.listOfUser = data['result']['userDetail'];
              }
              if (data['result']['userCount'] != null && data['result']['userCount'] != undefined) {
                this.totalUserCount = data['result']['userCount'];
                this.step7DataCount = data['result']['userCount'] ?? 0;
              }
            }
          }
          if (isDownload) {
            this.isDownLoading = false;
          } else {
            this.isLoading = false;
            this.isStep7FunnelDataLoading = false;
          }
        });
    } catch (error) {
      if (isDownload) {
        this.isDownLoading = false;
      } else {
        this.isLoading = false;
        this.isStep7FunnelDataLoading = false;
      }
    }
  }
  downloadListData() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
    };
    dialogConfig.maxHeight = `95vh`;
    let dialogRef = this.matDialog.open(
      AnalyticReportDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        this.fileName = null;
        return;
      } else if (res['error'] != null || res['error'] != undefined) {
        this.fileName = null;
        return;
      } else {
        if (res['fileName'] != null) {
          this.fileName = res['fileName'];
          switch (this.currentStep) {
            case 'Step 1':
              this.getAppInstallData(true);
              break;
            case 'Step 2':
              this.getSingnUpData(true);
              break;
            case 'Step 3':
              this.getProfilingData(true);
              break;
            case 'Step 4':
              this.getNumberOfAppOpenAttemptData(true);
              break;
            case 'Step 5':
              this.getActivityCompletedData(true);
              break;
            case 'Step 6':
              this.getQuizData(true);
              break;

            default:
              this.getCoursePurchaseData(true);
              break;
          }
        }
      }
    });
  }
  // downloadListData() {
  //   switch (this.currentStep) {
  //     case 'Step 1':
  //       this.getAppInstallData(true);
  //       break;
  //     case 'Step 2':
  //       this.getSingnUpData(true);
  //       break;
  //     case 'Step 3':
  //       this.getProfilingData(true);
  //       break;
  //     case 'Step 4':
  //       this.getNumberOfAppOpenAttemptData(true);
  //       break;
  //     case 'Step 5':
  //       this.getActivityCompletedData(true);
  //       break;

  //     default:
  //       this.getQuizData(true);
  //       break;
  //   }
  // }
  viewAllUserDetail() {
    switch (this.currentStep) {
      case 'Step 1':
        this.router.navigate(['/catalog/user-list']);
        break;
      case 'Step 2':
        this.router.navigate(['/catalog/user-list']);
        break;
      case 'Step 3':
        this.router.navigate(['/catalog/user-list']);
        break;
      case 'Step 4':
        this.router.navigate(['/catalog/user-list']);
        break;
      case 'Step 5':
        this.router.navigate(['/catalog/user-list']);
        break;

      default:
        this.router.navigate(['/catalog/user-list']);
        break;
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarninigToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
