import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListTradeAdvisoryComponent } from './list-trade-advisory/list-trade-advisory.component';

const routes: Routes = [
  {
    path: '',
    component: ListTradeAdvisoryComponent,
  },
  { pathMatch: 'full', path: '', redirectTo: 'catalog/trade-analysis' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TradeAdvisoryRoutingModule {}
