import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AclComponent } from './acl/acl.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { BannerManagementComponent } from './banner-management/banner-management.component';
import { CampaignManagementComponent } from './campaign-management/campaign-management.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { AddNewCourseComponent } from './courses/add-new-course/add-new-course.component';
import { CoursesComponent } from './courses/courses.component';
import { EnrollAddUserComponent } from './enroll-add-user/enroll-add-user.component';
import { ForumComponent } from './forum/forum.component';
import { HelpComponent } from './help/help.component';
import { LeadsComponent } from './leads/leads.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationModule } from './notification/notification.module';
import { PayoutComponent } from './payout/payout.component';
import { PlanComponent } from './plan/plan.component';
import { AddNewQuizComponent } from './quizzes/add-new-quiz/add-new-quiz.component';
import { QuizzesComponent } from './quizzes/quizzes.component';
import { ReferralDashboardComponent } from './referral-dashboard/referral-dashboard.component';
import { ReferralComponent } from './referral/referral.component';
import { ReportsComponent } from './reports/reports.component';
import { ShortVideosComponent } from './short-videos/short-videos.component';
import { SpecialOffersComponent } from './special-offers/special-offers.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TickerComponent } from './ticker/ticker.component';
import { TradeAdvisoryComponent } from './trade-advisory/trade-advisory.component';
import { TransactionLogComponent } from './transaction-log/transaction-log.component';
import { UserComponent } from './user/user.component';
import { WebhookSearchComponent } from './webhook-search/webhook-search.component';
import { WebinarManagementComponent } from './webinar-management/webinar-management.component';
import { YoutubeVideoComponent } from './youtube-video/youtube-video.component';
import { BatchComponent } from './batch/batch.component';
import { CommunityComponent } from './community/community.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'specialOffers',
    component: SpecialOffersComponent,
    children: [
      {
        path: '',
        loadChildren:
          './special-offers/special-offers.module#SpecialOffersModule',
      },
    ],
  },
  {
    path: 'banner',
    component: BannerManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './banner-management/banner-management.module#BannerManagementModule',
      },
    ],
  },

  {
    path: 'help',
    component: HelpComponent,
    children: [
      {
        path: '',
        loadChildren: './help/help.module#HelpModule',
      },
    ],
  },
  {
    path: 'courses',
    component: CoursesComponent,
  },
  {
    path: 'enroll',
    component: EnrollAddUserComponent,
    children: [
      {
        path: '',
        loadChildren:
          './enroll-add-user/enroll-add-user.module#EnrollAddUserModule',
      },
    ],
  },
  {
    path: 'user',
    component: UserComponent,
    children: [
      {
        path: '',
        loadChildren: './user/user.module#UserModule',
      },
    ],
  },
  {
    path: 'webinar',
    component: WebinarManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './webinar-management/webinar-management.module#WebinarManagementModule',
      },
    ],
  },
  {
    path: 'community',
    component: CommunityComponent,
    children: [
      {
        path: '',
        loadChildren:
          './community/community.module#CommunityModule',
      },
    ],
  },
  {
    path: 'add-new-quiz',
    component: AddNewQuizComponent,
  },

  {
    path: 'quizzes',
    component: QuizzesComponent,
  },
  {
    path: 'webhook',
    component: WebhookSearchComponent,
    children: [
      {
        path: '',
        loadChildren:
          './webhook-search/webhook-search.module#WebhookSearchModule',
      },
    ],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    children: [
      {
        path: '',
        loadChildren: './reports/reports.module#ReportsModule',
      },
    ],
  },
  {
    path: 'referral',
    component: ReferralDashboardComponent,
    children: [
      {
        path: '',
        loadChildren:
          './referral-dashboard/referral-dashboard.module#ReferralDashboardModule',
      },
    ],
  },
  {
    path: 'campaign',
    component: NotificationComponent,
    children: [
      {
        path: '',
        loadChildren: './notification/notification.module#NotificationModule',
      },
    ],
  },
  {
    path: 'transaction',
    component: TransactionLogComponent,
    children: [
      {
        path: '',
        loadChildren: './transaction-log/transaction-log.module#TransactionLogModule',
      },
    ],
  },
  {
    path: 'batch',
    component: BatchComponent,
    children: [
      {
        path: '',
        loadChildren: './batch/batch.module#BatchModule',
      },
    ],
  },
  {
    path: 'leads',
    component: LeadsComponent,
    children: [
      {
        path: '',
        loadChildren: './leads/leads.module#LeadsModule',
      },
    ],
  },
  {
    path: 'auditlog',
    component: AuditLogComponent,
    children: [
      {
        path: '',
        loadChildren: './audit-log/audit-log.module#AuditLogModule',
      },
    ],
  },
  {
    path: 'manual-campaign',
    component: CampaignManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './campaign-management/campaign-management.module#CampaignManagementModule',
      },
    ],
  },
  {
    path: 'webinar',
    component: WebinarManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './webinar-management/webinar-management.module#WebinarManagementModule',
      },
    ],
  },
  {
    path: 'plan',
    component: PlanComponent,
    children: [
      {
        path: '',
        loadChildren: './plan/plan.module#PlanModule',
      },
    ],
  },
  {
    path: 'trade-analysis',
    component: TradeAdvisoryComponent,
    children: [
      {
        path: '',
        loadChildren:
          './trade-advisory/trade-advisory.module#TradeAdvisoryModule',
      },
    ],
  },
  {
    path: 'ticker',
    component: TickerComponent,
    children: [
      {
        path: '',
        loadChildren: './ticker/ticker.module#TickerModule',
      },
    ],
  },
  {
    path: 'payout',
    component: PayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './payout/payout.module#PayoutModule',
      },
    ],
  },
  {
    path: 'employee',
    component: PayoutComponent,
    children: [
      {
        path: '',
        loadChildren:
          './employee-management/employee-management.module#EmployeeManagementModule',
      },
    ],
  },
  {
    path: 'shortVideos',
    component: ShortVideosComponent,
    children: [
      {
        path: '',
        loadChildren: './short-videos/short-videos.module#ShortVideosModule',
      },
    ],
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    children: [
      {
        path: '',
        loadChildren: './subscription/subscription.module#SubscriptionModule',
      },
    ],
  },
  {
    path: 'utm',
    component: SubscriptionComponent,
    children: [
      {
        path: '',
        loadChildren: './utm/utm.module#UtmModule',
      },
    ],
  },
  {
    path: 'compliance',
    component: ComplianceComponent,
    children: [
      {
        path: '',
        loadChildren: './compliance/compliance.module#ComplianceModule',
      },
    ],
  },
  {
    path: 'youtube',
    component: YoutubeVideoComponent,
    children: [
      {
        path: '',
        loadChildren: './youtube-video/youtube-video.module#YoutubeVideoModule',
      },
    ],
  },
  {
    path: 'leaderboard',
    component: LeaderboardComponent,
    children: [
      {
        path: '',
        loadChildren: './leaderboard/leaderboard.module#LeaderboardModule',
      },
    ],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
    ],
  },

  { path: '', pathMatch: 'full', redirectTo: '/catalog/user' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainCatalogRoutingModule { }
