import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { GenerateReportDialofComponent } from '../generate-report-dialof/generate-report-dialof.component';
export interface IFile {
  _id: string;
  id: string;

  type: string;
  fileLink: string;
  name: string;
  status: string;
  endedAt: string;
  startedAt: string;
  createdAt: string;
}
@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit {
  @Input() listOfVendor: any;
  @Input() listOfPrice: any;
  @Output() closeTab: EventEmitter<any> = new EventEmitter();

  isAddEditCalled: boolean = false;
  userDataToEdit: any;
  p: any = 1;
  count: any = 50;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfReportsToDisplay: Array<IFile> = [];
  listOfReports: Array<IFile> = [];
  userDetails: any;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if (this.appService.typeOfReport == null) {
      this.router.navigate(['/catalog/reports']);
    }

    this.fetchAllReport();
  }

  constructor(
    private matDialog: MatDialog,
    public appService: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  userDetail: any;
  isLoading: boolean = true;
  myHeaders = new Headers();

  async fetchAllReport() {
    this.listOfReports = [];
    let URL = '';
    let param = {};
    if (this.appService.typeOfReport == 'Registered User') {
      param = { type: 'Registered User' };
    } else if (this.appService.typeOfReport == 'Last Active User') {
      param = { type: 'Last Active User' };
    } else if (this.appService.typeOfReport == 'Analytics Report') {
      param = { type: 'Analytics Report' };
    } else {
      param = { type: 'UserCSR' };
    }
    URL = `${webApi.domain + webApi.endPoint.fetchAllReport}`;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    await this.appService
      .postMethodWithToken(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          data['result'].forEach((report: any) => {
            this.listOfReports.push({
              _id: report['_id'],
              id: report['_id'],
              type: report['type'],
              createdAt: report['createdAt'],
              startedAt: report['startedAt'],
              endedAt: report['endedAt'],
              status: report['status'],
              fileLink: report['fileLink'],
              name: report['fileName'],
            });
          });
          this.listOfReportsToDisplay = this.listOfReports;
          this.isLoading = false;
        }
      });
  }
  getTimeDifference(end: string, start: string) {
    var startDate = new Date(end);
    var endDate = new Date(start);
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return seconds;
  }
  downloadReportXl(purchaseOrder: any) {
    window.open(purchaseOrder['fileLink'], '_blank');
  }
  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfReportsToDisplay = [];

      this.listOfReports.forEach((report: any, index: number) => {
        if (
          String(report['type'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(report['createdAt'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(report['fileLink'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(report['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(report);
        }
      });
      this.listOfReportsToDisplay = [];
      this.listOfReportsToDisplay = tempSearchList;
    } else {
      this.listOfReportsToDisplay = this.listOfReports;
    }
  }
  goBack() {
    this.closeTab.emit();
  }
  async generateReport() {
    let param: any = {};
    if (this.appService.typeOfReport == 'Registered User') {
      param = { type: 'User Registration' };
    } else if (this.appService.typeOfReport == 'Last Active User') {
      param = { type: 'User Last Active' };
    }else if (this.appService.typeOfReport == 'Analytics Report') {
      param = { type: 'Analytics Report' };
    } else if (this.appService.typeOfReport == 'Course') {
      param = { type: 'Course' };
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      type: param['type'],
      listOfPrice: this.listOfPrice,
      listOfVendor: this.listOfVendor,
    };
    dialogConfig.maxHeight = `95vh`;
    let dialogRef = this.matDialog.open(
      GenerateReportDialofComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        return;
      } else if (res['error'] != null || res['error'] != undefined) {
        // this.showWarninigToaster(
        //   'Not able to genearte report at this moment. Try again',
        //   'Warninig'
        // );
        return;
      } else {
        this.listOfReports.splice(0, 0, {
          _id: res['result']['_id'],
          id: res['result']['_id'],
          type: res['result']['type'],
          createdAt: res['result']['createdAt'],
          status: res['result']['status'],
          fileLink: res['result']['fileLink'],
          name: res['result']['fileName'],
          startedAt: res['result']['startedAt'],
          endedAt: res['result']['endedAt'],
        });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarninigToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
