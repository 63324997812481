import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTransactionLogComponent } from './list-transaction-log/list-transaction-log.component';
import { TransactionLogComponent } from './transaction-log.component';
import { TransactionLogRoutingModule } from './transaction-log-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { ViewTransactionLogComponent } from './view-transaction-log/view-transaction-log.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UpdateUtmSourceDialogComponent } from './update-utm-source-dialog/update-utm-source-dialog.component';

@NgModule({
  declarations: [ListTransactionLogComponent,TransactionLogComponent, ViewTransactionLogComponent, UpdateUtmSourceDialogComponent],
  imports: [
    CommonModule,TransactionLogRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    NgxPaginationModule,MatDatepickerModule,MatNativeDateModule,
    ToastrModule.forRoot(),
    SidebarModule.forRoot(),
  ]
})
export class TransactionLogModule { }
