<div style="height: 100vh;margin-top: 2rem;margin-left: 2rem;    overflow-y: auto;">
            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;    width: 100%;">
                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;">
                        Payout Management
                    </span>
                </div>
                <div class="col-md-3" >
                        <div class="row" style="align-items: baseline;width: 100%;">
                            <label for="advisoryType" class="label required" style="margin-right: .5rem;">Status:</label>
                            <select id="advisoryType" name="advisoryType" class="input-style" (change)="selectStatus($event.target)">
                                <option value="All">All</option>
                                <option value="Processing">Processing</option>
                                <option value="Success">Success</option>
                                <option value="Rejected">Rejected</option>
                              
                            </select>
                        </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfPayoutToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Payout Request Found
                </div>
            </div>
            <div *ngIf="listOfPayoutToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Name</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Email
                        </th>

                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Phone
                        </th>

                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">UPI ID
                        </th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Transaction Fee</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Amount To Credit</th>


                        <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                            class="table-heading-content">
                            Status</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                        class="table-heading-content">
                        Requested Date</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                        class="table-heading-content">
                        Update Status</th>


                    </thead>
                    <!-- <div style="height: 0.6em;"> </div> -->
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let payout of listOfPayoutToDisplay | paginate: { itemsPerPage: count, currentPage: p },let i=index">

                            <th class="table-data-content">{{payout.user.fullName}}
                            </th>
                            <th class="table-data-content">{{payout.email}}</th>
                            <th class="table-data-content">{{payout.phone}}</th>
                            <th class="table-data-content">{{payout.upi_id}}</th>
                            <th class="table-data-content">{{'\u20b9'+payout.transactionFee}}
                            </th>
                            <th class="table-data-content">{{'\u20b9'+payout.amountToCredit}}
                            </th>
                            <th class="table-data-content">{{payout.status}}</th>
                            <th class="table-data-content">{{payout.createdAt |date}}</th>
                            <th *ngIf="payout.status=='Processing'" class="table-data-content" style="width: 10em;">
                                <div  style="display: contents;">
                                    <img (click)="editPayout(payout)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                </div>
                            </th>
                            <th *ngIf="payout.status!='Processing'"class="table-data-content">N.A.</th>

                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div style="height: 10em;"> </div>
            </div>

</div>