<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div *ngIf="!isLoading">

        <form class="form-style" name="batchAddEditForm" [formGroup]="batchAddEditForm">
            <div class="row">
                <div class="col-md-6">
                    <label for="title" class="label required">Batch Title</label>
                    <input type="text" placeholder="Enter a title" id="title" formControlName="title"
                        class="title-input" required>
                </div>
                <div class="col-md-6">
                    <label for="name" class="label required">Batch Name</label>
                    <input type="text" placeholder="Enter name" id="name" formControlName="batchName"
                        class="title-input" required>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="course" class="label required">Select Course</label>
                    <select name="course" id="course" class="title-input" formControlName="course" required>
                        <option value="Select Course" selected disabled>Select Course</option>
                        <option *ngFor="let course of listOfCourse" [value]="course['_id']" class="levels">
                            {{course['courseName']}}</option>

                    </select>
                </div>
            </div>
            <div class="row" style="margin: 1em 0px;">
                <div class="col-md-6">
                    <div>
                        <label class="label required" for="">Upload Video</label>
                        <div *ngIf="defaultVideoUrl==null || defaultVideoUrl==undefined || defaultVideoUrl==''"
                            style="height: 15.6rem" class="uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadVideo"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadVideo">+ Add New File</label>
                                <input type="file" id="uploadVideo" for="uploadVideo" style="display: none"
                                    accept="video/*" (change)="selectVideoFile($event)" required />
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>

                        </div>
                        <div *ngIf="defaultVideoUrl!=null && defaultVideoUrl!=undefined && defaultVideoUrl!=''"
                            class="uploadBox" style="position: relative; height: 15.6rem">
                            <img (click)="removeVideo()" class="removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 20rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultVideoUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedVideoName}}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">
                    <div>
                        <label class="label required" for="">Upload Video Thumbnail</label>
                        <div *ngIf="defaultVideoThumbnailUrl==null || defaultVideoThumbnailUrl==undefined || defaultVideoThumbnailUrl==''"
                            style="height: 15.6rem" class="uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadVideoThumbnail"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadVideoThumbnail">+ Add New File</label>
                                <input type="file" id="uploadVideoThumbnail" for="uploadVideoThumbnail"
                                    style="display: none" accept="image/*" (change)="selectVideoThumbnailFile($event)"
                                    required />
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>
                        </div>
                        <div *ngIf="defaultVideoThumbnailUrl!=null && defaultVideoThumbnailUrl!=undefined && defaultVideoThumbnailUrl!=''"
                            class="uploadBox" style="position: relative; height: 15.6rem">
                            <img (click)="removeVideoThumbnail()" class="removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 20rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultVideoThumbnailUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedVideoThumbnailName}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label for="author" class="label required">Author</label>
                    <select name="author" id="author" class="title-input" formControlName="author" required>
                        <option value="Select Author" selected disabled>Select Author</option>
                        <option *ngFor="let author of listOfAuthor" [value]="author['_id']" class="levels">
                            {{author['name']}}</option>

                    </select>
                </div>
                <div class="col-md-6">
                    <label for="userType" class="label required">User Type</label>
                    <select name="userType" id="userType" class="title-input" formControlName="userType" required>
                        <option value="Select User Type" selected disabled>Select User Type</option>
                        <!-- <option value="New">New User</option> -->
                        <option value="MMTC">MMTC User</option>
                        <option value="MMTC + MMOC">MMTC + MMOC User</option>

                    </select>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <label for="language" class="label required">Course Language</label>
                    <select name="language" id="language" class="title-input" formControlName="language">
                        <option value="Select Language" selected disabled>Select Language</option>
                        <option value="Hindi" class="langauge">Hindi</option>
                        <option value="English" class="langauge">English</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label for="listingPrice" class="label required">Listing Price</label>
                    <input type="number" id="listingPrice" placeholder="&#x20b9; Enter listing price" id="listingPrice"
                        formControlName="listingPrice" class="title-input">
                </div>
                <div class="col-md-6">
                    <label for="sellingPrice" class="label required">Selling Price</label>
                    <input type="number" id="sellingPrice" placeholder="&#x20b9; Enter selling price" id="sellingPrice"
                        formControlName="sellingPrice" class="title-input">
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <label class="label required" for="">Upload Live Session Demo</label>
                        <div *ngIf="defaultLiveSessionDemoUrl==null || defaultLiveSessionDemoUrl==undefined || defaultLiveSessionDemoUrl==''"
                            style="height: 15.6rem" class="uploadBox">
                            <div style="text-align: center;">
                                <label for="uploadLiveSessionDemo"
                                    style="color: #3199D8; font-size: 1rem; font-weight: 500;cursor: pointer;"
                                    class="uploadLiveSessionDemo">+ Add New File</label>
                                <input type="file" id="uploadLiveSessionDemo" for="uploadLiveSessionDemo"
                                    style="display: none" accept="video/*" (change)="selectLiveSessionDemoFile($event)"
                                    required />
                                <div style="color: #BFC0C8;font-size: 1.1rem;font-weight: 500;">
                                    Upload File
                                </div>
                                <img style="width: 3.3rem; margin-bottom: 2rem"
                                    src="../../../../../assets/img/upload-file.png" alt="" srcset="" />
                            </div>
                        </div>
                        <div *ngIf="defaultLiveSessionDemoUrl!=null && defaultLiveSessionDemoUrl!=undefined && defaultLiveSessionDemoUrl!=''"
                            class="uploadBox" style="position: relative; height: 15.6rem">
                            <img (click)="removeLiveSessionDemo()" class="removeImage"
                                src="../../../../../assets/svgIcons/close.svg" alt="" srcset="" />
                            <img style="max-height: 13rem; max-width: 20rem;padding: 1rem;border-radius: 25px;"
                                [src]="defaultLiveSessionDemoUrl" alt="" srcset="" />
                            <div class="fileName">
                                {{selectedLiveSessionDemoName}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <div class="row">
                <div class="col-md-6" style="font-weight: 600;">
                    Features
                </div>
                <div class="col-md-6">
                    <!-- <div class="addNewField" (click)="addNewFeature()">
                        <img style="width: 1.6rem; margin-right: 1rem" src="../../../../../assets/svgIcons/addIcon.svg"
                            alt="" srcset="" /> Add New Feature
                    </div> -->
                </div>
                <div class="col-md-6">
                    <label for="totalHours" class="label required">Total Hours</label>
                    <input type="text" placeholder="Enter total Hours" id="totalHours" formControlName="totalHours"
                        class="title-input" required>
                </div>
                <div class="col-md-6">
                    <label for="totalLiveSessions" class="label required">Total Live Sessions</label>
                    <input type="text" placeholder="Enter total Live Sessions" id="totalLiveSessions"
                        formControlName="totalLiveSessions" class="title-input" required>
                </div>
                <div class="col-md-6">
                    <label for="totalModules" class="label required">Total Module</label>
                    <input type="text" placeholder="Enter total module" id="totalModules" formControlName="totalModules"
                        class="title-input" required>
                </div>
                <div class="col-md-6">
                    <label for="totalBacktestedStrategies" class="label required">Total Backtested Strategies</label>
                    <input type="text" placeholder="Enter total BacktestedS trategies" id="totalBacktestedStrategies"
                        formControlName="totalBacktestedStrategies" class="title-input" required>
                </div>
                <div class="col-md-6">
                    <label for="batchValidity" class="label required">Batch Validity</label>
                    <input type="text" placeholder="Enter batch Validity" id="batchValidity"
                        formControlName="batchValidity" class="title-input" required>
                </div>
                <div class="col-md-6" style="display: flex;align-items: center;margin-top: 2em;">
                    <input type="checkbox" id="assignmentAndQuiz" formControlName="assignmentAndQuiz"
                        style="margin-right: 1em;">
                    <label for="assignmentAndQuiz" class="label required">Assignment And Quiz</label>
                </div>
                <!-- <div class="col-md-12 no-data" *ngIf="listOfFeature.length==0">
                    No Feature Available
                </div>
                <div class="col-md-12" *ngIf="listOfFeature.length>0">

                    <div *ngFor="let data of listOfFeature;let i=index;" class="data-tile">
                        <div style="display: flex;align-items: center;">
                            <img [src]="data['url']" style="height: 1em;width: 1em;margin-right: 1em;">
                            <div class="fileName">
                                {{data['title']}}
                            </div>
                        </div>
                        <div class="removeForm" (click)="deleteFeature(i)">
                            <img style="width: 1.3rem; margin-right: 0.5rem"
                                src="../../../../../assets/svgIcons/removeField.svg" alt="" srcset="" />Remove
                        </div>

                    </div>
                </div> -->
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    Advantages
                </div>
                <div class="col-md-6">
                    <div class="addNewField" (click)="addNewAdvantage()">
                        <img style="width: 1.6rem; margin-right: 1rem" src="../../../../../assets/svgIcons/addIcon.svg"
                            alt="" srcset="" /> Add New Advantage
                    </div>
                </div>
                <div class="col-md-12 no-data" *ngIf="listOfAdvantage.length==0">
                    No Advantages Available
                </div>
                <div class="col-md-12" *ngIf="listOfAdvantage.length>0">

                    <div *ngFor="let data of listOfAdvantage;let i=index;" class="data-tile">
                        <div style="display: flex;align-items: center;">
                            <img [src]="data['url']" style="height: 1em;width: 1em;margin-right: 1em;">
                            <div class="fileName">
                                {{data['title']}}
                            </div>
                        </div>
                        <div class="removeForm" (click)="deleteAdvantage(i)">
                            <img style="width: 1.3rem; margin-right: 1rem"
                                src="../../../../../assets/svgIcons/removeField.svg" alt="" srcset="" />Remove
                        </div>

                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    Review Videos
                </div>
                <div class="col-md-6">
                    <div class="addNewField" (click)="addNewReviewVideo()">
                        <img style="width: 1.6rem; margin-right: 1rem" src="../../../../../assets/svgIcons/addIcon.svg"
                            alt="" srcset="" />
                        Add New Review Video
                    </div>
                </div>
                <div class="col-md-12 no-data" *ngIf="listOfReviewVideo.length==0">
                    No Review Video Available
                </div>
                <div class="col-md-12" *ngIf="listOfReviewVideo.length>0">
                    <div *ngFor="let data of listOfReviewVideo;let i=index;" class="data-tile">
                        <div style="display: flex;align-items: center;">
                            <img [src]="data['url']" style="height: 1em;width: 1em;margin-right: 1em;">
                            <div class="fileName">
                                {{data['batchYear']}} - {{data['batchMonth']}} {{getStar(data['batchRating'])}}
                            </div>
                        </div>
                        <div class="removeForm" (click)="deleteReviewVideo(i)">
                            <img style="width: 1.3rem; margin-right: 1rem"
                                src="../../../../../assets/svgIcons/removeField.svg" alt="" srcset="" />Remove
                        </div>
                    </div>

                </div>
            </div>
            <div style="display: flex;justify-content:space-between;">
                <div *ngIf="!isAddindBatch" class="create" (click)="createEditCourse()">
                    <button
                        [ngClass]="{'create-btn-disabled':batchAddEditForm.invalid,'create-btn':batchAddEditForm.valid}"
                        type="submit" [disabled]="batchAddEditForm.invalid">{{batchId==null? 'Save\
                        Batch Detail':'Save\
                        Batch Detail'}}</button>
                </div>
                <div class="loading-comp" *ngIf="isAddindBatch">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="batchId!=null" class="create" (click)="gotoModules()">
                    <button class="goto-module-btn">{{'Go to Module'}}</button>
                </div>
            </div>
        </form>
    </div>

</div>