import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss']
})
export class BroadcastMessageComponent implements OnInit {


  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = true;
  isSendMessageLoading: boolean = false;
  sendPushNotification: boolean = false;
  message: string = '';
  userDetail: any;
  httpHeaders: any;

  // Image 
  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImageUrl: any;
  selectedFileContentExtension: any = null;


  listOfCommunity: any = [];
  selectedCommunity: any = [];
  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  communityDetail: any;
  constructor(
    public dialogRef: MatDialogRef<BroadcastMessageComponent>, public socketService: SocketioService,
    public service: AppService, private formBuilder: FormBuilder, private toastr: ToastrService,
    public appService: AppService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });


  }
  ngOnInit(): void {

    this.fetchAllCommunity();
  }

  async fetchAllCommunity() {
    this.isLoading = true;
    try {
      this.listOfCommunity = [];

      const URL = `${webApi.domain + webApi.endPoint.fetchAllCommunity}`;
      await this.appService.fetchDetail({ courseId: '0', }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCommunity = data['result'];

        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  removeImage() {
    this.selectedImage = null;
    this.selectedImageName = null;
    this.defaultImageUrl = '';
  }
  selectImageFile(event: any) {
    this.selectedImage = event.target.files[0];
    this.selectedImageName = this.selectedImage['name'];
    this.selectedFileContentExtension = String(this.selectedImage['name']).split('.')[String(this.selectedImage['name']).split('.').length - 1];

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultImageUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedImage);

  }

  sendPushNotificationChange(event: any) {
    this.sendPushNotification = event.target.checked;
  }

  onSubmit() {
    this.isSendMessageLoading = true;

    if (this.message == '') {
      // return false;
    }
    let contentType = 'Text';
    if (this.selectedFileContentExtension == 'mp3') {
      contentType = 'Audio';
    } else if (this.selectedFileContentExtension == 'mp4') {
      contentType = 'Video';
    } else if (this.selectedFileContentExtension == "doc" ||
      this.selectedFileContentExtension == "pdf" ||
      this.selectedFileContentExtension == "docx" ||
      this.selectedFileContentExtension == "csv" ||
      this.selectedFileContentExtension == "xls" ||
      this.selectedFileContentExtension == "ods" ||
      this.selectedFileContentExtension == "xlsx") {
      contentType = 'Document';
    } else if (this.selectedFileContentExtension == "png" ||
      this.selectedFileContentExtension == "jpg" ||
      this.selectedFileContentExtension == "jpeg" ||
      this.selectedFileContentExtension == "gif") {
      contentType = 'Image';
    }
    if (this.selectedImage != null) {
      this.formdata.set('url', this.selectedImage);
    }
    let content: any = {
      type: contentType,
      docName: this.selectedImageName ?? '',
      text: this.message
    };
    let match = this.message.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    if (match != null && match != undefined && match.length > 0) {
      this.formdata.set('isLink', 'true');
    }
    this.formdata.set('sentBy', 'Admin');
    this.formdata.set('sendPushNotification', this.sendPushNotification.toString());


    let community: any = [];
    this.selectedCommunity.forEach((dys: any, i: number) => {
      community.push(dys._id);
    });
    this.formdata.set('community', JSON.stringify(community));

    this.formdata.set('content', JSON.stringify(content));

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.boadcastMessage}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isSendMessageLoading = false;
                this.closeActivityAtDialog.emit({ result: true })
                this.dialogRef.close();
                this.message = '';
                this.showSuccessToaster('Message Broadcasted successfully.', 'Success');

              } else {
                this.isSendMessageLoading = false;
                this.showWarningToaster('Unable to broadcast message.', 'Warning');
              }
            });
          }
        })
        .catch((error) => {
          this.showWarningToaster('Unable to broadcast message.', 'Warning');
          this.isSendMessageLoading = false;
          console.log('error', error);
        });

      return;
    } catch (error) {
      this.showWarningToaster('Unable to broadcast message.', 'Warning');
      this.isSendMessageLoading = false;
    }
    return;
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  handleKeyDownEvent(e: any) {
    if (e.key == 'Enter' && !e.shiftKey) {
      // prevent default behavior
      e.preventDefault();
    }
  }
}
