import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListFilterSubscriptionComponent } from './list-filter-subscription/list-filter-subscription.component';
import { ListSubscriptionComponent } from './list-subscription/list-subscription.component';
import { SubDashboardComponent } from './sub-dashboard/sub-dashboard.component';

const routes: Routes = [
  { path: '', component: SubDashboardComponent },
  { path: 'list-subscription', component: ListSubscriptionComponent },
  { path: 'filter-subscription', component: ListFilterSubscriptionComponent },
  { path: '', pathMatch: 'full', redirectTo: '/catalog/subscription' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriptionRoutingModule { }
