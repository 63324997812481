import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { ComplianceRoutingModule } from '../compliance/compliance-routing.module';
import { CommunityComponent } from './community.component';
import { CommunityRoutingModule } from './community-routing.module';
import { ListCommunityComponent } from './list-community/list-community.component';
import { SocketioService } from './socketio.service';
import { MediaDialogComponent } from './media-dialog/media-dialog.component';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { AddEditCommunityComponent } from './add-edit-community/add-edit-community.component';
import { BroadcastMessageComponent } from './broadcast-message/broadcast-message.component';
import { ReportedCommunityComponent } from './reported-community/reported-community.component';
import { DeleteMessageComponent } from './delete-message/delete-message.component';
import { ReportedMsgComponent } from './reported-msg/reported-msg.component';
import { FilterCommunityComponent } from './filter-community/filter-community.component';
import { PinUnpinDialogComponent } from './pin-unpin-dialog/pin-unpin-dialog.component';
import { ViewCommunityParticipantComponent } from './view-community-participant/view-community-participant.component';
import { ParticipantAddDeleteDialogComponent } from './participant-add-delete-dialog/participant-add-delete-dialog.component';



@NgModule({
  declarations: [CommunityComponent, ListCommunityComponent, AudioRecorderComponent, MediaDialogComponent, AddEditCommunityComponent, BroadcastMessageComponent, ReportedCommunityComponent, DeleteMessageComponent, ReportedMsgComponent, FilterCommunityComponent, PinUnpinDialogComponent, ViewCommunityParticipantComponent, ParticipantAddDeleteDialogComponent],
  imports: [
    CommonModule, CommunityRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    ComplianceRoutingModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          // ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          // ['blockquote', 'code-block'],

          // [{ header: 1 }, { header: 2 }], // custom button values
          // [{ list: 'ordered' }, { list: 'bullet' }],
          // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          // [{ direction: 'rtl' }], // text direction

          // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],

          // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          // [{ font: [] }],
          // [{ align: [] }],

          // ['clean'], // remove formatting button

          // ['link'],
        ],
      },
    }),
  ],
  providers: [SocketioService]
})
export class CommunityModule { }
