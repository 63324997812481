import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { SocketioService } from '../../community/socketio.service';

@Component({
  selector: 'app-add-batch-announcement',
  templateUrl: './add-batch-announcement.component.html',
  styleUrls: ['./add-batch-announcement.component.scss']
})
export class AddBatchAnnouncementComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = true;
  isAddEditAnnouncementLoading: boolean = false;

  userDetail: any;
  httpHeaders: any;

  // Image 
  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImageUrl: any;

  addEditBatchAnnouncementForm: FormGroup;
  batchAnnouncementDetail: any;
  constructor(
    public dialogRef: MatDialogRef<AddBatchAnnouncementComponent>, public socketService: SocketioService,
    public service: AppService, private formBuilder: FormBuilder, private toastr: ToastrService,
    public appService: AppService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
    this.addEditBatchAnnouncementForm = this.formBuilder.group({
      caption: new FormControl(null, [Validators.required]),
    });

  }
  ngOnInit(): void {
    if (this.data['announcement'] != null && this.data['announcement'] != undefined) {
      this.fetchAnnouncementWithId();
    } else {
      this.isLoading = false;
    }
  }
  async fetchAnnouncementWithId() {
    this.isLoading = true;
    try {
      this.batchAnnouncementDetail = null;

      const URL = `${webApi.domain + webApi.endPoint.fetchBatchAnnouncementWithId}/${this.data['announcement']['_id']}`;
      await this.appService.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.batchAnnouncementDetail = data['result'];

          if (this.batchAnnouncementDetail != null && this.batchAnnouncementDetail != undefined) {
            this.addEditBatchAnnouncementForm.get('caption')?.setValue(this.batchAnnouncementDetail['caption']);





            this.defaultImageUrl =
              this.batchAnnouncementDetail['image'] != null
                ? this.batchAnnouncementDetail['image']
                : null;
          }
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  removeImage() {
    this.selectedImage = null;
    this.selectedImageName = null;
    this.defaultImageUrl = '';
  }
  selectImageFile(event: any) {
    this.selectedImage = event.target.files[0];
    this.selectedImageName = this.selectedImage['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultImageUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedImage);

  }

  onSubmit() {
    this.isAddEditAnnouncementLoading = true;
    try {


      this.formdata = new FormData();
      if (this.data['announcement'] != null && this.data['announcement'] != undefined) {
        this.formdata.set('announcementId', this.data['announcement']['_id']);
      } else {
        this.formdata.set('announcementId', '0');
      }

      if (this.selectedImage == null || this.selectedImage == undefined) {
        if (this.batchAnnouncementDetail != null && this.batchAnnouncementDetail != undefined) {
          if (this.batchAnnouncementDetail['image'] != null && this.batchAnnouncementDetail['image'] != undefined && this.batchAnnouncementDetail['image'] != '') {
            this.formdata.set('image', this.batchAnnouncementDetail['image']);
          }
        }
      } else {
        this.formdata.set('image', this.selectedImage);
      }


      this.formdata.set('caption', this.addEditBatchAnnouncementForm.get('caption')?.value);
      this.formdata.set('batch', this.data['batchId']);

      this.myHeaders.append(
        'Authorization',
        `Bearer ${this.userDetail['accessToken']}`
      );

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };

      const URL = `${webApi.domain + webApi.endPoint.createOrUpdateBatchAnnouncement}`;

      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            this.isAddEditAnnouncementLoading = false;
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.dialogRef.close();
                this.closeActivityAtDialog.emit({ result: res['result'] });
                this.isAddEditAnnouncementLoading = true;
                if (this.data['announcement'] != null && this.data['announcement'] != undefined) {
                  this.showSuccessToaster(
                    'Batch Announcement Updated Successfully',
                    'Success'
                  );
                } else {
                  this.showSuccessToaster(
                    'Batch Announcement Created Successfully',
                    'Success'
                  );
                }
              } else {
                this.myHeaders = new Headers();
                if (this.data['announcement'] != null && this.data['announcement'] != undefined) {
                  this.showWarningToaster(
                    'Unable to update Batch Announcement. Please try again.',
                    'Warning'
                  );
                } else {
                  this.showWarningToaster(
                    'Unable to create Batch Announcement. Please try again.',
                    'Warning'
                  );
                }
              }
            });
          }
        })
        .catch((error) => console.log('error', error));
      return;
    } catch (error) {
      this.isAddEditAnnouncementLoading = false;
      if (this.data['announcement'] != null && this.data['announcement'] != undefined) {
        this.showWarningToaster(
          'Unable to update Batch Announcement. Please try again.',
          'Warning'
        );
      } else {
        this.showWarningToaster(
          'Unable to create Batch Announcement. Please try again.',
          'Warning'
        );
      }
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
