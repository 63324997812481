import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-management',
  templateUrl: './banner-management.component.html',
  styleUrls: ['./banner-management.component.scss']
})
export class BannerManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
