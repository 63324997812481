<div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-3">
            Compliance
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;margin-top: 2em;" *ngIf="!isLoading">
        <div class="row" style="margin-right: 2em;">
            <div class="col-md-3" (click)="moveToPrivacyPolicy()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/privacy.jpg' alt="Privacy Policy" srcset="">
                    <div class="name-on-card">
                        Privacy Policy
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="moveToTermsAndCondition()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/terms.jpeg' alt="TC" srcset="">
                    <div class="name-on-card">
                        Terms and Condition
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="addEditPerformance()">
                <div class="main-card">
                    <img class="img-card" src='../../../../../assets/img/performance.png' alt="Performance Dashboard"
                        srcset="">
                    <div class="name-on-card">
                        Performance Dashboard
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
