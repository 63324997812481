<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <!-- [dataToEdit]="couponDataToEdit" -->
            <app-add-new-offers *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-new-offers>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;width: 100%">

                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;cursor: pointer;">
                        Coupons
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">
                        <div class="col-md-6" style="text-align: right;">
                            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                placeholder="Search Here" (input)="searchData($event.target)">
                        </div>
                        <div class="col-md-2" style="text-align: center;">
                            <button class="add-new-emp" (click)="addNewCoupon()" style="width: 9rem;">
                                Add Coupon
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfCouponToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Coupon found
                </div>
            </div>
            <div *ngIf="listOfCouponToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Code</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Type</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                            class="table-heading-content">
                            Discount</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                            class="table-heading-content">
                            Max Discount</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                            class="table-heading-content">
                           Access</th>
                           
                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Start Date
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">End Date
                        </th>

                        <th style="cursor: pointer;" data-order="desc" data-name="description" scope="col"
                            class="table-heading-content">Active
                        </th>
                        <th class="table-heading-content">Action</th>

                    </thead>
                    <!-- <div style="height: 0.6em;"> </div> -->
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let coupon of listOfCouponToDisplay | paginate: { itemsPerPage: count, currentPage: p }">

                            <th class="table-data-content">{{coupon.code}}</th>
                            <th class="table-data-content">{{coupon.type}}</th>
                            <th class="table-data-content">{{coupon.benefit}}</th>
                            <th class="table-data-content">{{coupon.type=='DAYS'?'N.A.' :coupon.maxDiscountAmount}}
                            </th>
                            <th class="table-data-content">{{coupon.access}}</th>
                            <th class="table-data-content">{{coupon.startDate |date}}</th>
                            <th class="table-data-content">{{coupon.endDate |date}}</th>
                            <th class="table-data-content">
                                <img *ngIf="coupon.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(coupon,false)" slot="start" />
                                <img *ngIf="!coupon.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(coupon,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">
                                    <img (click)="editCoupon(coupon)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img (click)="deleteCoupon(coupon)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>


        </div>
    </ng-sidebar-container>
</div>