import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.user = this.as.userInfo;
  }

  user: any;
  amount: string = '';
  errorMessage: String = '';

  ngOnInit(): void {}

  onNoClick(): void {
    this.matDialog.closeAll();
  }
  async payCommission() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Pay Commission', user: this.user };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        console.log(res);
        if (parseInt(this.amount) <= res['value']['totalUnpaidCommission']) {
          const param = {
            userId: res['value']['userManualId'],
            amount: parseInt(this.amount),
          };
          const URL = `${webApi.domain + webApi.endPoint.payUserCommission}`;
          await this.as
            .payUserCommissionFromService(param, URL)
            .then((data: any) => {
              if (data['success']) {
                this.onNoClick();
                this.showSuccessToaster(
                  'Commission Paid Successfully',
                  'Success'
                );
                let amount = this.amount == '' ? 0 : parseInt(this.amount);
                this.closeActivityAtDialog.emit({
                  action: 'Amount',
                  value: amount,
                });
                dialogRef.close();
              } else {
                this.showErrorToaster(
                  'Error Occurred while paying User. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.errorMessage = 'Amount is more than commission';
        }
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
