import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';

export interface Ticker {
  _id: string;
  createdAt: Date;
  isActive: boolean;
  title: String;
  route: String;
  icon: string;
  type: string;
  position:number;
}

@Component({
  selector: 'app-list-ticker',
  templateUrl: './list-ticker.component.html',
  styleUrls: ['./list-ticker.component.scss'],
})
export class ListTickerComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchTicker();
  }

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfTickerToDisplay: Array<Ticker> = [];
  listOfTicker: Array<Ticker> = [];
  listOfTickerOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];

  myHeaders = new Headers();

  async fetchTicker() {
    this.isLoading=true;
    this.listOfTickerToDisplay = [];
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.fetchTickers}`;

      await this.as
        .fetchDetail({ admin: true }, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfTickerToDisplay = data['result'];
            this.listOfTicker = [...this.listOfTickerToDisplay];
            this.isLoading = false;
          } else {
            this.listOfTickerToDisplay = [];
            this.listOfTicker = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfTickerToDisplay = [];
      this.listOfTicker = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfTickerToDisplay = [];
      this.listOfTicker.forEach((ticker: any, index: number) => {
        if (
          String(ticker.language)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(ticker.categoryId.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(ticker);
        }
      });
      this.listOfTickerToDisplay = tempSearchList;
    } else {
      this.listOfTickerToDisplay = [];
      this.listOfTickerToDisplay = this.listOfTicker;
    }
  }

  updateActiveStatus(ticker: Ticker, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { ticker: ticker, source: 'Active Ticker' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          tickerId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };

        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusTicker
        }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfTickerToDisplay.forEach(
                (ticker: any, index: number) => {
                  if (ticker['_id'] == res['value']['_id']) {
                    ticker.isActive = isActive;
                  }
                }
              );
              this.listOfTicker = [...this.listOfTickerToDisplay];
              if (isActive) {
                this.showSuccessToaster(
                  'Ticker Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Ticker Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating ticker. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  deleteTicker(ticker: Ticker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { ticker: ticker, source: 'Delete Ticker' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          tickerId: res['value']['_id'],
          action: 'Delete',
        };

        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusTicker
        }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfTickerToDisplay.forEach((ticker: any, i: number) => {
                if (ticker['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfTickerToDisplay.splice(index, 1);
              this.listOfTicker = [...this.listOfTickerToDisplay];

              this.showSuccessToaster(
                'Ticker Deleted Successfully',
                'Success'
              );
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting ticker. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  addNewTicker() {
    this.toggleOpened();
  }

  editTicker(magazine: any) {
    let magazineToEdit;
    this.listOfTickerToDisplay.forEach((mgz: any) => {
      if (mgz['_id'] == magazine._id) {
        magazineToEdit = mgz;
      }
    });
    this.dataToEdit = magazineToEdit;
    this.toggleOpened();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing ticker details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding ticker details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfTickerToDisplay.findIndex(
          (ticker: any) => ticker['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfTickerToDisplay.push(event);
          this.listOfTicker = [...this.listOfTickerToDisplay];
          this.showSuccessToaster('Ticker added successfully', 'Success');
        } else {
          this.listOfTickerToDisplay[index] = event;
          this.listOfTicker = [...this.listOfTickerToDisplay];
          this.showSuccessToaster('Ticker Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
}
