import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';

@Component({
  selector: 'app-list-leaderboard',
  templateUrl: './list-leaderboard.component.html',
  styleUrls: ['./list-leaderboard.component.scss']
})
export class ListLeaderboardComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;

  isLoadingExtraData: boolean = false;

  isAddEditCalled: boolean = false;
  searchString: any = '';

  Page = 1;
  count = 50;
  limit = 100;
  skip = 0;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfLeaderboardDataToDisplay: any = [];
  listOfLeaderboardData: any = [];

  myHeaders = new Headers();

  startDate: any;
  endDate: any;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchForAdminLeaderBoardUsers();
  }

  fetchForAdminLeaderBoardUsers() {
    this.isLoading = true;
    try {
      let URL = '';
      let param = {
        limit: this.limit, startDate: this.startDate,
        endDate: this.endDate,
        skip: this.skip,
      };
      URL = `${webApi.domain + webApi.endPoint.fetchForAdminLeaderBoardUsers}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.as
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfLeaderboardData = data.result;
            this.listOfLeaderboardDataToDisplay = [...this.listOfLeaderboardData];
            console.log(this.listOfLeaderboardData);
          }
          this.isLoading = false;
        });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  fetchExtraListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        limit: this.limit,
        searchedString: this.searchString.trim(),
        skip: this.skip, startDate: this.startDate,
        endDate: this.endDate,
      };
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchForAdminLeaderBoardUsers}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.as
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          console.log(data);
          if (data['success']) {
            data['result'].forEach((corporate: any) => {
              this.listOfLeaderboardData.push(corporate);
            });
            this.listOfLeaderboardDataToDisplay = [...this.listOfLeaderboardData];
          }
          this.isLoadingExtraData = false;
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  pageChanged(event: any) {
    this.Page = event;
    console.log(event);

    if (this.Page * this.count >= this.listOfLeaderboardData.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraListData(false);
    } else if (this.Page * this.count >= this.listOfLeaderboardData.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraListData(true);
    }
  }

  async dateChanged(type: string, event: any) {
    this.searchString = '';
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      this.fetchForAdminLeaderBoardUsers();
    }
  }
  setDateNull() {
    this.startDate = null;
    this.endDate = null;
    this.clearDateRange();
  }
  clearDateRange() {
    if (this.endDate != null && this.startDate != null) {
      this.endDate = null;
      this.startDate = null;
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      this.fetchForAdminLeaderBoardUsers();
    }
    this.searchString = '';
  }
  async searchLeaderboardData() {
    this.isLoading = true;
    if (this.searchString == '') {
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      this.fetchForAdminLeaderBoardUsers();
    } else {
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      try {
        this.listOfLeaderboardData = [];
        this.listOfLeaderboardDataToDisplay = this.listOfLeaderboardData;
        let param = {
          searchedString: this.searchString.trim(),
          limit: this.limit,
          skip: this.skip, startDate: this.startDate,
          endDate: this.endDate,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.fetchForAdminLeaderBoardUsers}`;
        await this.as
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              data['result'].forEach((patient: any) => {
                this.listOfLeaderboardData.push(patient);
              });
              this.listOfLeaderboardDataToDisplay = [...this.listOfLeaderboardData];
            }
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
  searchData(target: any) {
    let serachQuery = target.value;
    if (serachQuery == '') {
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      this.searchString = '';
      this.fetchForAdminLeaderBoardUsers();
    }
  }



  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }



  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

}
