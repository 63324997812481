<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Content
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addVideoForm" [formGroup]="addVideoForm">
        <div class="row">
            <div class="col-md-12">
                <label for="caption" class="label required" style="margin-bottom: 1rem;">Caption</label>
                <textarea type="text" placeholder="Caption" id="caption"
                    style="padding-top:.6rem;padding-right: 0;height: 7rem;" formControlName="caption"
                    class="input-style"></textarea>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="description" class="label required">Author</label>
                <select id="type" name="type" class="input-style" formControlName="author">
                    <option *ngFor="let author of listOfAuthor" [value]="author['_id']">{{author['name']}}
                    </option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="description" class="label required">Type</label>
                <select id="type" name="type" class="input-style" formControlName="type"
                    (change)="selectType($event.target)">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="Image">Image</option>
                    <option value="Video">Video</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="description" class="label required">Text Color</label>
                <select id="textColor" name="textColor" class="input-style" formControlName="textColor">
                    <option value="Select Color" selected disabled>Select Color</option>
                    <option value="Black">Black</option>
                    <option value="White">White</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="Thumbnail Image" class="label required">Thumbnail Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultContentImage!=null" src="{{defaultContentImage}}" class="proofImage">
                                <label *ngIf="defaultContentImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultContentImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="selectedType=='Video'">
                <label for="Image" class="label required">Video'</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultVideoImage!=null" src="{{defaultVideoImage}}" class="proofImage">
                                <label *ngIf="defaultVideoImage==null" class="imageLabel"> Upload

                                    <input (change)="selectVideo($event)" type="file" accept="video/*">
                                </label>
                                <label *ngIf="defaultVideoImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>

                                    <input (change)="selectVideo($event)" type="file" accept="video/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save">
            <input *ngIf="selectedType=='Video'" type="submit"
                [ngClass]="{'save-btn-disabled':addVideoForm.invalid|| defaultContentImage==null || defaultVideoImage==null || selectType==null   ,'save-btn':addVideoForm.valid  && defaultContentImage!=null && defaultVideoImage!=null && selectType!=null} "
                value="{{'Save'}}"
                [disabled]="addVideoForm.invalid || defaultContentImage==null || defaultVideoImage==null || selectType==null "
                (click)="addEditVideo()" (keyup.enter)="disable()" />

            <input *ngIf="selectedType=='Image'" type="submit"
                [ngClass]="{'save-btn-disabled':addVideoForm.invalid|| defaultContentImage==null  || selectType==null   ,'save-btn':addVideoForm.valid  && defaultContentImage!=null && selectType!=null} "
                value="{{'Save'}}" [disabled]="addVideoForm.invalid || defaultContentImage==null  || selectType==null "
                (click)="addEditVideo()" (keyup.enter)="disable()" />
        </div>

    </form>
</div>