<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Video
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addVideoForm" [formGroup]="addVideoForm">
        <div class="row">
            <div class="col-md-12">
                <label for="title" class="label required">Title</label>
                <input type="text" placeholder="Title" id="title" formControlName="title" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="videoLink" class="label required">Youtube Link</label>
                <input type="text" placeholder="Youtube Link" id="videoLink" formControlName="videoLink"
                    class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="duration" class="label required">Duration</label>
                <input type="number" placeholder="Duration in hours" id="duration" formControlName="duration"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Type</label>
                <select id="type" name="type" class="input-style" (change)="typeSelected($event)"
                    formControlName="type">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Advance">Advance</option>
                </select>
            </div>
        </div>


        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addVideoForm.invalid || selectedType==null ,'save-btn':addVideoForm.valid && selectedType!=null}"
                value="{{'Save'}}" [disabled]="addVideoForm.invalid || selectedType==null "
                (click)="createOrUpdateVideo()" />
        </div>

    </form>
</div>