<div class="main-container">
    <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
    <div *ngIf="!isLoading">


        <div class="row main-card">
            <div class="col-md-12 pl-4">
                <label class="heading">Campaign Detail</label>
                <div class="mb-2">
                    <span class="data-label"> Campaign Name:</span> <span class="data">{{campaign['name']}}</span>
                </div>
                <div class="mb-2">
                    <span class="data-label"> Campaign Type:</span> <span class="data">{{campaign['type']}}</span>
                </div>
                <div class="mb-2">
                    <span class="data-label"> Campaign Date:</span> <span
                        class="data">{{campaign['campaignDateTime']==null||
                        campaign['campaignDateTime']==undefined?'-': getDate(campaign['campaignDateTime']) |
                        date :
                        'dd/MM/yyyy' }}</span>
                </div>
                <div class="mb-2">
                    <span class="data-label"> Campaign Time:</span> <span
                        class="data">{{campaign['campaignDateTime']==null||
                        campaign['campaignDateTime']==undefined?(getOnlyTime(campaign['campaignSchedule']['time']) |
                        date
                        :'hh:mm a'): getDate(campaign['campaignDateTime']) |
                        date :'hh:mm a' }}</span>
                </div>
                <div class="mb-2">
                    <span class="data-label"> Campaign Medium:</span> <span
                        class="data">{{campaign['campaignMedium']}}</span>
                </div>
                <div class="mb-2">
                    <span class="data-label"> Campaign Status:</span> <span class="data">{{campaign['status']}}</span>
                </div>
            </div>
        </div>
        <div class="row main-card">
            <div class="col-md-12 pl-4">
                <label class="heading">Campaign Delivery Report</label>

                <div *ngIf="listOfCampaignDeliveryReport.length==0">
                    <div class="display-flex align-items-center justify-content-center" style="height: 5em;">
                        No Delivery Report found
                    </div>
                </div>
                <div *ngIf="listOfCampaignDeliveryReport.length>0">


                    <table cellspacing="0" cellpadding="0" style="width: 95%;padding-right: 10px;">
                        <tr class="table-heading tr-style">
                            <th class="th-weight">
                                Date</th>
                            <th class="th-weight">
                                Medium</th>
                            <th class="th-weight">
                                Sent Count</th>
                            <th class="th-weight">
                                Received Count</th>
                            <th class="th-weight">
                                Open Count</th>

                        </tr>
                        <tbody *ngIf=" listOfCampaignDeliveryReport.length>0">
                            <tr class="table-data" *ngFor="let campaign of listOfCampaignDeliveryReport ">
                                <th class="table-data-content">{{campaign['dateTime'] | date:'dd/MM/yyyy hh:mm a'}}
                                </th>
                                <th class="table-data-content">{{campaign['medium']}}
                                </th>
                                <th class="table-data-content">{{campaign['sentCount']}}
                                </th>
                                <th class="table-data-content">{{campaign['receiveCount']}}
                                </th>
                                <th class="table-data-content">{{campaign['openedCount']}}
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>