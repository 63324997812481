import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  @Input() courseDtls: any;
  @Output() moveToPrevious: EventEmitter<any> = new EventEmitter();
  @Output() moveToActivity: EventEmitter<any> = new EventEmitter();
  moduleDetail: any;
  selectedActivityType: any;
  listOfActivity: any = [];
  showAddDropDown: boolean = false;
  // isChangingStatus: boolean = false;
  isLoading: boolean = true;
  callAddActivity: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  activityToEdit: any;
  userDetails: any;
  httpHeaders: any;

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    // console.log('constructor called activity page');
  }

  disable: boolean = true;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    if (this.as.moduleDetailForActivity != null) {
      this.moduleDetail = this.as.moduleDetailForActivity;
      this.fetchModuleActivity();
    }
  }
  callManual() {
    if (this.as.moduleDetailForActivity != null) {
      this.moduleDetail = this.as.moduleDetailForActivity;
      this.fetchModuleActivity();
    }
  }
  backToModule() {
    this.moveToPrevious.emit(null);
  }
  async fetchModuleActivity() {
    this.isLoading = true;
    this.listOfActivity = [];
    const param = {
      module: this.moduleDetail['_id'],
      admin: true,
    };
    const URL = `${webApi.domain + webApi.endPoint.fetchActivityByModuleId}`;
    await this.as
      .fetchDetail(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfActivity = data['result'];
          this.listOfActivity.forEach((act: any) => {
            act['isDeleting'] = false;
            act['isChangingStatus'] = false;
          });
          this.listOfActivity.sort((b: any, a: any) => b.position - a.position);
          this.isLoading = false;
        }
      });
  }
  addNewActivityClicked() {
    this.showAddDropDown = !this.showAddDropDown;
  }
  closeshowAddDropDown() {
    this.showAddDropDown = false;
  }
  async changeActivieStatus(activity: any) {
    this.listOfActivity.forEach((act: any) => {
      if (act['_id'] == activity['_id']) {
        act['isChangingStatus'] = false;
      }
    });
    const param = {
      activityId: activity['_id'],
      isActive: activity['isActive'] ? false : true,
      action: 'Active',
    };
    const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteActivity}`;
    await this.as
      .updateDataViaPut(param, URL, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfActivity.forEach((act: any) => {
            if (act['_id'] == activity['_id']) {
              act['isActive'] = activity['isActive'] ? false : true;
            }
          });
          this.listOfActivity.forEach((act: any) => {
            if (act['_id'] == activity['_id']) {
              act['isChangingStatus'] = false;
            }
          });
          this.listOfActivity.sort((b: any, a: any) => b.position - a.position);

          this.showSuccessToaster(
            !activity['isActive']
              ? 'Activity Unpublished Successfully'
              : 'Activity Published Successfully',
            'Success'
          );
        }
      });
  }
  addNewActivity(source: any) {
    this.showAddDropDown = false;
    this.callAddActivity = true;
    this.activityToEdit = null;
    this.selectedActivityType = source;
    this.toggleOpened(null);
  }

  editActivity(detail: any) {
    this.callAddActivity = true;
    this.selectedActivityType = detail['type'];
    this.activityToEdit = detail;
    this.toggleOpened(null);
  }

  showWarningToUser() {
    this.openAlertWhenContactNotConfirmed(null, 'Activity Warning');
  }
  openAlertWhenContactNotConfirmed(activity: any, source: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: source, activity: activity };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfActivity.forEach((act: any) => {
          act['isDeleting'] = false;
        });
        return;
      } else {
        this.listOfActivity.forEach((mod: any) => {
          if (mod['_id'] == activity['_id']) {
            mod['isDeleting'] = true;
          }
        });
        if (res['action'] == 'Delete Activity') {
          const param = {
            activityId: res['value']['_id'],
            action: 'Delete',
          };
          const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteActivity
            }`;

          await this.as
            .updateDataViaPut(param, URL, this.httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfActivity.forEach((activity: any, index: number) => {
                  if (activity['_id'] == res['value']['_id']) {
                    this.listOfActivity.splice(index, 1);
                  }
                });
                this.showSuccessToaster(
                  'Activity Delete Successfully',
                  'Success'
                );
              } else {
                this.listOfActivity.forEach((activity: any, index: number) => {
                  if (activity['_id'] == res['value']['_id']) {
                    activity['isDeleting'] = false;
                  }
                });
                this.showErrorToaster(
                  'Error Occurred while deleteing Activity. Try again',
                  'Error'
                );
              }
              this.listOfActivity.sort(
                (b: any, a: any) => b.position - a.position
              );
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  deleteActivity(activity: any) {
    this.openAlertWhenContactNotConfirmed(activity, 'Activity');
  }
  toggleOpened(event: any): void {
    // console.log(event);
    this.opened = !this.opened;
    if (event) {
    }
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.callAddActivity = false;
    if (event != null) {
      let isExists = false;
      this.listOfActivity.forEach((act: any) => {
        if (act['_id'] == event['_id']) {
          isExists = true;
          act['activityName'] = event['activityName'];
          act['position'] = event['position'];
          act['activityContent'] = event['activityContent'];
          act['videoContent'] = event['videoContent'];
          act['resources'] = event['resources'];
          act['description'] = event['description'];
        }
      });
      if (!isExists) {
        this.listOfActivity.push(event);
      }
      this.listOfActivity.sort((b: any, a: any) => b.position - a.position);
    }
  }
  toggleDock(): void {
    this.dock = !this.dock;
  }
  toggleCloseOnClickOutside(): void {
    this.closeOnClickOutside = !this.closeOnClickOutside;
  }
  toggleCloseOnClickBackdrop(): void {
    this.closeOnClickBackdrop = !this.closeOnClickBackdrop;
  }
  toggleShowBackdrop(): void {
    this.showBackdrop = !this.showBackdrop;
  }
  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
}
