<div>
    <div mat-dialog-title class="display-flex align-items-center justify-content-space-between">
        <div class="m-0" style="font-weight: 600;">
            Edit UTM Source
        </div>
        <div class="display-flex align-items-center">
            <!-- <img src="../../../../assets/image/chat-icons/wa-img/close.png" (click)="close()"
                style="height: 1em;cursor: pointer;" alt="" srcset=""> -->
        </div>
    </div>
    <div style="border-bottom: solid 1px gray;margin: 10px 0px 10px 0px;"></div>
    <mat-dialog-content class="mat-typography" style="overflow: hidden;">
        <div *ngIf="isLoading">
            <div class="display-flex align-items-center justify-content-center">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </div>
        <div *ngIf="!isLoading" class="row">
            <div class="col-md-12">
                <form name="addEditcategoryForm" [formGroup]="addEditcategoryForm">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="utmSource" class="label required">UTM Source</label>
                            <input type="text" placeholder="Enter UTM Source" id="utmSource" formControlName="utmSource"
                                class="input-style">
                        </div>

                    </div>
                </form>
            </div>

        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="cancel-btn" mat-dialog-close>Cancel</button>
        <button [ngClass]="{'save-enabled':addEditcategoryForm.valid,'save-disabled':addEditcategoryForm.invalid}"
            (click)="addEditcategoryForm.invalid?null:editUTMSource()">Save</button>
    </mat-dialog-actions>
</div>