<div *ngIf="isLoading">
  <div class="center-loading">
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin: 0 auto">
    </mat-progress-spinner>
  </div>
</div>
<div *ngIf="!isLoading" class="container-fluid main-content" id="container-wrapper">
  <div class="row mb-3">
    <div class="col-md-12 heading-text" style="justify-content: space-between; display: flex;align-items: baseline;">
      <div class="dashboard-div" style="font-size: 1.4rem">
        Subscription Detail
        <!-- ({{ listOfSubscription.length }}) -->
      </div>

      <div>
        <div style="display: inline">
          <mat-form-field appearance="outline">
            <mat-label>Pick a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" [value]="startDate"
                (dateChange)="dateChanged('start', $event)" />
              <input matEndDate placeholder="End date" [value]="endDate" (dateChange)="dateChanged('end', $event)" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <!-- <div style="text-align: center;display: inline-block;">
                    <button class="add-btn">
                        Add General Chat
                    </button>
                </div> -->
        <div *ngIf="listOfSubscription.length > 0" style="
            position: relative;
            cursor: pointer;
            display: inline-block;
            margin-left: 2rem;
          ">
          <!-- <img class="mr-4" src="../../../../assets/svgIcons/Save.svg" title="Download Report" alt="" srcset=""
            (click)="generateReport()" /> -->
        </div>
      </div>
    </div>
    <!--MMTC COURSE FREE SILVER DETAIL  -->

    <div class="col-md-12" style="font-weight: 600;margin-bottom: 1em;">
      <!-- MMTC Detail ({{mmtcCourseTotalCount}}) -->

      <div class="display-flex">
        MMTC Detail
        <span *ngIf="isTopContentLoading">
          <mat-progress-spinner mode="indeterminate" diameter="20">
          </mat-progress-spinner>
        </span>
        <div *ngIf="!isTopContentLoading" (click)="downloadCourseReport('MMTC')"
          style="cursor: pointer;color: blue;margin-left: 10px;"> ({{mmtcCourseTotalCount}})</div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Free Count
              </div>
              <div *ngIf="isTopContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <div *ngIf="!isTopContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <div style="margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsReport('FREE','Active','MMTC')">
                  {{silverFreeCount}}
                </div>
                /
                <div style="margin-left: 5px;margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsActiveTillDateReport('FREE','Active','MMTC')">
                  {{silverFreeValidTillDateCount}}
                </div> <span style="font-size: 12px;font-weight: 500;"> valid till date</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Paid Count
              </div>
              <div *ngIf="isTopContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isTopContentLoading" class="h5 mb-0 mr-3 font-weight-bold text-gray-800"
                style="font-size: 1.5rem">
                {{silverPaidCount}} / {{silverPaidValidTillDateCount}}
                <span style="font-size: 12px;font-weight: 500;"> valid till date</span>

              </div> -->
              <div *ngIf="!isTopContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <div style="margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsReport('PAID','Active','MMTC')">
                  {{silverPaidCount}}
                </div>
                /
                <div style="margin-left: 5px;margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsActiveTillDateReport('PAID','Active','MMTC')">
                  {{silverPaidValidTillDateCount}}
                </div> <span style="font-size: 12px;font-weight: 500;"> valid till date</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Revenue
              </div>
              <div *ngIf="isTopContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <div *ngIf="!isTopContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem">
                &#x20b9; {{silverPaidAmount}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--MMOC COURSE FREE GOLD DETAIL  -->

    <div class="col-md-12" style="font-weight: 600;margin-bottom: 1em;">
      <div class="display-flex">
        MMOC Detail
        <span *ngIf="isTopContentLoading">
          <mat-progress-spinner mode="indeterminate" diameter="20">
          </mat-progress-spinner>
        </span>
        <div *ngIf="!isTopContentLoading" (click)="downloadCourseReport('MMOC')"
          style="cursor: pointer;color: blue;margin-left: 10px;"> ({{mmocCourseTotalCount}})</div>
      </div>

    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Free Count
              </div>
              <div *ngIf="isTopContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isTopContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem">
                {{goldFreeCount}} / {{goldFreeValidTillDateCount}}
                <span style="font-size: 12px;font-weight: 500;"> valid till date</span>

              </div> -->

              <div *ngIf="!isTopContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <div style="margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsReport('FREE','Active','MMOC')">
                  {{goldFreeCount}}
                </div>
                /
                <div style="margin-left: 5px;margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsActiveTillDateReport('FREE','Active','MMOC')">
                  {{goldFreeValidTillDateCount}}
                </div> <span style="font-size: 12px;font-weight: 500;"> valid till date</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Paid Count
              </div>
              <div *ngIf="isTopContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isTopContentLoading" class="h5 mb-0 mr-3 font-weight-bold text-gray-800"
                style="font-size: 1.5rem">
                {{goldPaidCount}} / {{goldPaidValidTillDateCount}}
                <span style="font-size: 12px;font-weight: 500;"> valid till date</span>

              </div> -->
              <div *ngIf="!isTopContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <div style="margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsReport('PAID','Active','MMOC')">
                  {{goldPaidCount}}
                </div>
                /
                <div style="margin-left: 5px;margin-right: 5px;cursor: pointer;color: blue;"
                  (click)="downloadSubsActiveTillDateReport('PAID','Active','MMOC')">
                  {{goldPaidValidTillDateCount}}
                </div> <span style="font-size: 12px;font-weight: 500;"> valid till date</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Revenue
              </div>
              <div *ngIf="isTopContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <div *ngIf="!isTopContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem">
                &#x20b9; {{goldPaidAmount}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <hr>
  <div class="row mb-3">
    <div class="col-md-12" style="font-weight: 600;margin-bottom: 1em;">
      MMTC Detail
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                MMTC Count
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;cursor: pointer;color: blue;"
                (click)="downloadCoursePart2Report('MMTC','All','All')">
                {{mmtcCourseCount}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Course Detail
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <div (click)="downloadCoursePart2Report('MMTC','Active','Active')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{mmtcCourseActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;margin-right: 10px;"> Active</span>
                /
                <div (click)="downloadCoursePart2Report('MMTC','All','InActive')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{mmtcCourseInActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;"> Inactive</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4" style="cursor: pointer;">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="display-flex align-items-center justify-content-space-between">
                <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                  Subscription
                </div>
                <div (click)="gotoFilterSubs('Silver','All')">
                  <img style="height: 1.3em;" src="../../../../assets/img/view.png" alt="">
                </div>
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                (click)="gotoFilterSubs('Silver','All')" style="font-size: 1.5rem">
                {{silverSubsActiveCount}} <span style="font-size: 12px;font-weight: 500;"> Active</span> /
                {{silverSubsInActiveCount}} <span style="font-size: 12px;font-weight: 500;"> Inactive</span>
              </div> -->

              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <!-- (click)="gotoFilterSubs('Silver','All')" -->
                <div (click)="generateReportSubsPart2('Silver','Active')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{silverSubsActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;margin-right: 10px;"> Active</span>
                /
                <div (click)="generateReportSubsPart2('Silver','InActive')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{silverSubsInActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;"> Inactive</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4" style="cursor: pointer;">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="display-flex align-items-center justify-content-space-between">
                <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                  Subscription Detail
                </div>
                <div (click)="gotoFilterSubs('Silver','Active')">
                  <img style="height: 1.3em;" src="../../../../assets/img/view.png" alt="">
                </div>
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                (click)="gotoFilterSubs('Silver','Active')" style="font-size: 1.5rem">
                {{silverSubsPaidCount}} <span style="font-size: 12px;font-weight: 500;"> Paid</span> /
                {{silverSubsFreeCount}} <span style="font-size: 12px;font-weight: 500;"> Free</span>
              </div> -->

              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <!-- (click)="gotoFilterSubs('Silver','Active')" -->
                <div (click)="generateReportSubsFreePaidPart2('Silver','PAID')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{silverSubsPaidCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;margin-right: 10px;"> Paid</span>
                /
                <div (click)="generateReportSubsFreePaidPart2('Silver','FREE')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{silverSubsFreeCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;"> Free</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col-md-12" style="font-weight: 600;margin-bottom: 1em;">
      MMOC Detail
    </div>

    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                MMOC Count
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;cursor: pointer;color: blue;"
                (click)="downloadCoursePart2Report('MMOC','All','All')">
                {{mmocCourseCount}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                Course Detail
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem">
                {{mmocCourseActiveCount}} <span style="font-size: 12px;font-weight: 500;"> Active</span> /
                {{mmocCourseInActiveCount}} <span style="font-size: 12px;font-weight: 500;"> Inactive</span>
              </div> -->

              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <div (click)="downloadCoursePart2Report('MMOC','Active','Active')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{mmocCourseActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;margin-right: 10px;"> Active</span>
                /
                <div (click)="downloadCoursePart2Report('MMOC','All','InActive')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{mmocCourseInActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;"> Inactive</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4" style="cursor: pointer;">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="display-flex align-items-center justify-content-space-between">
                <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                  Subscription
                </div>
                <div (click)="gotoFilterSubs('Gold','All')">
                  <img style="height: 1.3em;" src="../../../../assets/img/view.png" alt="">
                </div>
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                (click)="gotoFilterSubs('Gold','All')" style="font-size: 1.5rem">
                {{goldSubsActiveCount}} <span style="font-size: 12px;font-weight: 500;"> Active</span> /
                {{goldSubsInActiveCount}} <span style="font-size: 12px;font-weight: 500;"> Inactive</span>
              </div> -->

              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <!-- (click)="gotoFilterSubs('Gold','All')" -->
                <div (click)="generateReportSubsPart2('Gold','Active')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{goldSubsActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;margin-right: 10px;"> Active</span>
                /
                <div (click)="generateReportSubsPart2('Gold','InActive')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{goldSubsInActiveCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;"> Inactive</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4" style="cursor: pointer;">
      <div class="card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col mr-2">
              <div class="display-flex align-items-center justify-content-space-between">
                <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem">
                  Subscription Detail
                </div>
                <div (click)="gotoFilterSubs('Gold','Active')">
                  <img style="height: 1.3em;" src="../../../../assets/img/view.png" alt="">
                </div>
              </div>
              <div *ngIf="isBottomContentLoading">
                <mat-progress-spinner mode="indeterminate" diameter="20">
                </mat-progress-spinner>
              </div>
              <!-- <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                (click)="gotoFilterSubs('Gold','Active')" style="font-size: 1.5rem">
                {{goldSubsPaidCount}} <span style="font-size: 12px;font-weight: 500;"> Paid</span> /
                {{goldSubsFreeCount}} <span style="font-size: 12px;font-weight: 500;"> Free</span>
              </div> -->

              <div *ngIf="!isBottomContentLoading" class="h5 mb-0 font-weight-bold text-gray-800"
                style="font-size: 1.5rem;display: flex;align-items: baseline;">
                <!-- (click)="gotoFilterSubs('Gold','Active')" -->
                <div (click)="generateReportSubsFreePaidPart2('Gold','PAID')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{goldSubsPaidCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;margin-right: 10px;"> Paid</span>
                /
                <div (click)="generateReportSubsFreePaidPart2('Gold','FREE')"
                  style="margin-right: 10px;cursor: pointer;color: blue;">
                  {{goldSubsFreeCount}}
                </div>
                <span style="font-size: 12px;font-weight: 500;"> Free</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- <div *ngIf="listOfSubscription.length == 0" class="no-available" style="width: 90rem">
    No Subscription found
  </div>
  <div  style="
      display: flex;
      justify-content: flex-end;
      margin-right: 1rem;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    " (click)="navigateToSubList()">
    <p style="margin-bottom: 0rem">See All Subscription</p>
  </div> -->
  <!-- <div class="row mb-3" style="justify-content: end">
   
    <table *ngIf="listOfSubscription.length > 0" class="table-style" cellspacing="0" cellpadding="0"
      style="margin-left: 1rem">
      <thead class="table-heading">
        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Sr.No
        </th>
        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Name & Phone
        </th>

        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Plan
        </th>
        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Type
        </th>
        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Razorpay Subscription Id
        </th>
        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Status
        </th>
        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Start Date
        </th>
        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          End Date
        </th>

        <th style="cursor: pointer" data-order="desc" class="table-heading-content">
          Created Date
        </th>
      </thead>

      <tbody>
        <tr class="table-data" *ngFor="
            let subscription of listOfSubscription
              | paginate: { itemsPerPage: count, currentPage: p }
          ">
          <th class="table-data-content">{{ subscription.srNo }}</th>
          <th class="table-data-content">
            {{
            subscription.user["fullName"] +
            " (" +
            subscription.user["phone"] +
            ")"
            }}
          </th>
          <th class="table-data-content">
            {{
            subscription.plan.name + " (" + subscription.plan.subType + ")"
            }}
          </th>
          <th class="table-data-content">{{ subscription.type }}</th>
          <th class="table-data-content">
            {{ subscription.rzpSubscriptionId }}
          </th>
          <th class="table-data-content">
            {{ subscription.subscriptionStatus }}
          </th>
          <th class="table-data-content">
            {{ subscription.startDate | date }}
          </th>
          <th class="table-data-content">{{ subscription.endDate | date }}</th>
          <th class="table-data-content">
            {{ subscription.createdAt | date }}
          </th>
        </tr>
      </tbody>
    </table>
    <div class="text-right" style="font-size: medium; margin: 1.5em; float: right">
      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
  </div> -->
</div>