import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from 'src/app/main-catalog/courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss']
})
export class ActivityDetailComponent implements OnInit {

  batchId: any;
  userDetails: any;
  isLoading: boolean = true;

  isAssessment: boolean = true;
  isNote: boolean = false;
  isPoll: boolean = false;

  listOfAssessment: any = [];
  listOfAssessmentToDisplay: any = [];

  listOfNote: any = [];
  listOfNoteToDisplay: any = [];

  listOfPoll: any = [];
  listOfPollToDisplay: any = [];
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public _activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this._activatedRoute.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.batchId = allParam['params']['batchId'];
    });
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.fetchBatchActivity();
  }
  changeHandler(src: string) {
    if (src == 'Assessment') {
      this.isAssessment = true;
      this.isNote = false;
      this.isPoll = false;
    }
    else if (src == 'Notes') {
      this.isAssessment = false;
      this.isNote = true;
      this.isPoll = false;
    } else {
      this.isAssessment = false;
      this.isNote = false;
      this.isPoll = true;
    }
    this.fetchBatchActivity();
  }
  async fetchBatchActivity() {
    this.isLoading = true;
    if (this.isAssessment) {
      this.listOfAssessment = [];
      this.listOfAssessmentToDisplay = [];
    }
    else if (this.isNote) {
      this.listOfNote = [];
      this.listOfNoteToDisplay = [];
    }
    else {
      this.listOfPoll = [];
      this.listOfPollToDisplay = [];
    }

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchBatchActivity}`;
    await this.as
      .postMethodWithToken({ batchId: this.batchId, type: this.isAssessment ? 'Assessments' : (this.isNote ? 'Notes' : 'Polls') }, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          if (this.isAssessment) {
            this.listOfAssessment = data['result'];
            this.listOfAssessmentToDisplay = this.listOfAssessment;
          }
          else if (this.isNote) {
            this.listOfNote = data['result'];
            this.listOfNoteToDisplay = this.listOfNote;
          }
          else {
            this.listOfPoll = data['result'];
            this.listOfPollToDisplay = this.listOfPoll;
          }
          this.isLoading = false;
        }
      });
  }
  searchBatch(event: any) {
    if (this.isAssessment) {
      let serachQuery = event.target.value;
      let tempBatchList: any = [];
      this.listOfAssessmentToDisplay = [...this.listOfAssessment];
      if (serachQuery != '') {
        this.listOfAssessmentToDisplay.forEach((batch: any) => {
          if (
            String(batch['title'])
              .toLowerCase()
              .includes(String(serachQuery).toLowerCase()) ||
            String(batch['batchName'])
              .toLowerCase()
              .includes(String(serachQuery).toLowerCase())
          ) {
            tempBatchList.push(batch);
          }
        });
        this.listOfAssessmentToDisplay = [...tempBatchList];
      } else {
        this.listOfAssessmentToDisplay = [...this.listOfAssessment];
      }
    } else if (this.isNote) {
      let serachQuery = event.target.value;
      let tempBatchList: any = [];
      this.listOfNoteToDisplay = [...this.listOfNote];
      if (serachQuery != '') {
        this.listOfNoteToDisplay.forEach((batch: any) => {
          if (
            String(batch['title'])
              .toLowerCase()
              .includes(String(serachQuery).toLowerCase()) ||
            String(batch['batchName'])
              .toLowerCase()
              .includes(String(serachQuery).toLowerCase())
          ) {
            tempBatchList.push(batch);
          }
        });
        this.listOfNoteToDisplay = [...tempBatchList];
      } else {
        this.listOfNoteToDisplay = [...this.listOfNote];
      }
    } else {
      let serachQuery = event.target.value;
      let tempBatchList: any = [];
      this.listOfPollToDisplay = [...this.listOfPoll];
      if (serachQuery != '') {
        this.listOfPollToDisplay.forEach((batch: any) => {
          if (
            String(batch['title'])
              .toLowerCase()
              .includes(String(serachQuery).toLowerCase()) ||
            String(batch['batchName'])
              .toLowerCase()
              .includes(String(serachQuery).toLowerCase())
          ) {
            tempBatchList.push(batch);
          }
        });
        this.listOfPollToDisplay = [...tempBatchList];
      } else {
        this.listOfPollToDisplay = [...this.listOfPoll];
      }
    }


  }


  addNewBatch() {
    if (this.isAssessment) {
      this.router.navigate(['/catalog/add-activity'], { queryParams: { batchId: this.batchId, type: 'Assessment' } });
    } else if (this.isNote) {
      this.router.navigate(['/catalog/add-activity'], { queryParams: { batchId: this.batchId, type: 'Notes' } });
    } else {
      this.router.navigate(['/catalog/add-activity'], { queryParams: { batchId: this.batchId, type: 'Poll' } });
    }
  }
  gotoBatchEdit(info: any) {
    if (this.isAssessment) {
      this.router.navigate(['/catalog/edit-activity'], { queryParams: { batchId: this.batchId, type: 'Assessment', activityId: info._id } });
    } else if (this.isNote) {
      this.router.navigate(['/catalog/edit-activity'], { queryParams: { batchId: this.batchId, type: 'Notes', activityId: info._id } });
    } else {
      this.router.navigate(['/catalog/edit-activity'], { queryParams: { batchId: this.batchId, type: 'Poll', activityId: info._id } });
    }
  }


  deleteActivity(activity: any) {
    const dialogConfig = new MatDialogConfig();
    if (this.isAssessment) {
      dialogConfig.data = { source: 'Assessment Delete', assessment: activity };
    }
    else if (this.isNote) {
      dialogConfig.data = { source: 'Notes Delete', notes: activity };
    }
    else {
      dialogConfig.data = { source: 'Poll Delete', poll: activity };
    }
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfAssessmentToDisplay.forEach((course: any) => {
          course['isDeleting'] = false;
        });
        return;
      } else {
        const param = {
          activityId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteBatchActivity}`;
        await this.as
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {

              if (this.isAssessment) {
                this.listOfAssessmentToDisplay.forEach((assessment: any, index: number) => {
                  if (assessment['_id'] == res['value']['_id']) {
                    this.listOfAssessmentToDisplay.splice(index, 1);
                  }
                });
              }
              else if (this.isNote) {
                this.listOfNoteToDisplay.forEach((note: any, index: number) => {
                  if (note['_id'] == res['value']['_id']) {
                    this.listOfNoteToDisplay.splice(index, 1);
                  }
                });
              }
              else {
                this.listOfPollToDisplay.forEach((poll: any, index: number) => {
                  if (poll['_id'] == res['value']['_id']) {
                    this.listOfPollToDisplay.splice(index, 1);
                  }
                });
              }


              this.showSuccessToaster('Activity Delete Successfully', 'Success');
            } else {

              if (this.isAssessment) {
                this.listOfAssessmentToDisplay.forEach((assessment: any, index: number) => {
                  if (assessment['_id'] == res['value']['_id']) {
                    assessment['isDeleting'] = false;
                  }
                });
              }
              else if (this.isNote) {
                this.listOfNoteToDisplay.forEach((note: any, index: number) => {
                  if (note['_id'] == res['value']['_id']) {
                    note['isDeleting'] = false;
                  }
                });
              }
              else {
                this.listOfPollToDisplay.forEach((poll: any, index: number) => {
                  if (poll['_id'] == res['value']['_id']) {
                    poll['isDeleting'] = false;
                  }
                });
              }
              this.showErrorToaster(
                'Error Occurred while deleteing Activity. Try again',
                'Error'
              );
            }
          });
      }
    });
  }
  async updateActivityStatus(activity: any) {

    const dialogConfig = new MatDialogConfig();
    if (this.isAssessment) {
      dialogConfig.data = { source: 'Assessment Status', assessment: activity };
    }
    else if (this.isNote) {
      dialogConfig.data = { source: 'Notes Status', notes: activity };
    }
    else {
      dialogConfig.data = { source: 'Poll Status', poll: activity };
    }
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfAssessmentToDisplay.forEach((course: any) => {
          course['isDeleting'] = false;
        });
        return;
      } else {
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const param = {
          activityId: activity['_id'],
          isActive: activity['isActive'] ? false : true,
          action: 'Active',
        };
        const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteBatchActivity}`;
        await this.as
          .postMethodWithToken(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {


              if (this.isAssessment) {
                this.listOfAssessmentToDisplay.forEach((assessment: any) => {
                  if (assessment['_id'] == activity['_id']) {
                    assessment['isActive'] = activity['isActive'] ? false : true;
                  }
                });
              }
              else if (this.isNote) {
                this.listOfNoteToDisplay.forEach((note: any) => {
                  if (note['_id'] == activity['_id']) {
                    note['isActive'] = activity['isActive'] ? false : true;
                  }
                });
              }
              else {
                this.listOfPollToDisplay.forEach((poll: any) => {
                  if (poll['_id'] == activity['_id']) {
                    poll['isActive'] = activity['isActive'] ? false : true;
                  }
                });
              }


              this.showSuccessToaster(
                !activity['isActive']
                  ? 'Activity Unpublished Successfully'
                  : 'Activity Published Successfully',
                'Success'
              );
            }
          });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
