import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogComponent } from '../active-delete-dialog/active-delete-dialog.component';

export interface Coupon {
  _id: string;
  createdAt: Date;
  code: string;
  type: string;
  access:string;
  benefit: number;
  maxDiscountAmount: number;
  couponQty: number;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
}
@Component({
  selector: 'app-main-special-offers',
  templateUrl: './main-special-offers.component.html',
  styleUrls: ['./main-special-offers.component.scss'],
})
export class MainSpecialOffersComponent implements OnInit {
  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfCouponToDisplay: Array<Coupon> = [];
  listOfCoupon: Array<Coupon> = [];
  listOfCouponOriginal: any = [];
  listOfProduct: any = [];
  listOfCategory: any = [];
  userDetails: any;
  isLoading: boolean = true;
  myHeaders = new Headers();

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.fetchVouchers();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async fetchVouchers() {
    this.listOfCouponToDisplay = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchAllCoupons}`;
      const param = {
        admin: true,
      };
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCouponToDisplay = data['result'];
          this.listOfCoupon = [...this.listOfCouponToDisplay];
          this.isLoading = false;
        } else {
          this.listOfCouponToDisplay = [];
          this.listOfCoupon = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfCouponToDisplay = [];
      this.listOfCoupon = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfCouponToDisplay = [];
      this.listOfCoupon.forEach((coupon: any, index: number) => {
        if (
          String(coupon['code'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(coupon['type'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())

            ||
            String(coupon['access'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(coupon);
        }
      });
      this.listOfCouponToDisplay = tempSearchList;
    } else {
      this.listOfCouponToDisplay = [];
      this.listOfCouponToDisplay = this.listOfCoupon;
    }
  }

  addNewCoupon() {
    this.toggleOpened();
  }

  deleteCoupon(coupon: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { coupon: coupon, source: 'Delete Coupon' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          couponId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusCoupon
        }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfCouponToDisplay.forEach((coupon: any, i: number) => {
                if (coupon['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfCouponToDisplay.splice(index, 1);
              this.listOfCoupon = [...this.listOfCouponToDisplay];

              this.showSuccessToaster('Coupon Deleted Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting coupon. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  editCoupon(coupon: any) {
    let couponToEdit;
    this.listOfCouponToDisplay.forEach((ofr: any) => {
      if (ofr['_id'] == coupon._id) {
        couponToEdit = ofr;
      }
    });
    this.dataToEdit = couponToEdit;
    this.toggleOpened();
  }

  updateActiveStatus(coupon: any, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { coupon: coupon, source: 'Active Coupon' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          couponId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${
          webApi.domain + webApi.endPoint.updateActiveOrDeleteStatusCoupon
        }`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfCouponToDisplay.forEach(
                (coupon: any, index: number) => {
                  if (coupon['_id'] == res['value']['_id']) {
                    coupon.isActive = isActive;
                  }
                }
              );
              if (isActive) {
                this.showSuccessToaster(
                  'Coupon Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Coupon Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating coupon. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing coupon details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding coupon details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfCouponToDisplay.findIndex(
          (team: any) => team['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfCouponToDisplay.push(event);
          this.listOfCoupon = [...this.listOfCouponToDisplay];
          this.showSuccessToaster('Coupon added successfully', 'Success');
        } else {
          this.listOfCouponToDisplay[index] = event;
          this.listOfCoupon = [...this.listOfCouponToDisplay];
          this.showSuccessToaster('Coupon Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  gotoBrandPage() {
    this.as.gotoComponent('Brand Management');
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
}
