import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from './api';

export const firebaseConfig = {
  apiKey: 'AIzaSyBa08SXQh6cIpz6NLUq0iOLjaG76ESLXJA',
  authDomain: 'tg-app-a95b2.firebaseapp.com',
  projectId: 'tg-app-a95b2',
  storageBucket: 'tg-app-a95b2.appspot.com',
  messagingSenderId: '102018769671',
  appId: '1:102018769671:web:a95bb3efdf326e8826dba2',
  measurementId: 'G-2L4SGFS6YM',
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // Variables
  batchDetail: any;
  typeOfReport: any;
  subsStatus: any;
  onlyActive: boolean = false;
  planType: any;
  startDate: any;
  endDate: any;
  webinarEdit: any;
  webinarDetailToView: any;
  campaignEdit: any;
  campaignDetailToView: any;
  validUsersDetail: any;
  usersDetailForSignUp: any;
  courseDetailToEdit: any;
  moduleDetailForActivity: any;
  userId: String = '';
  listOfCourseForReport: any;
  listOfQuizToEditFromQuizSection: any;
  questionToAccessFromComment: any;
  listOfReferralsUser: any;
  userDetails: any;
  appSidebarState: any = {
    '/catalog/user': false,
    '/catalog/courses': false,
    '/catalog/quizzes': false,
    '/catalog/specialOffers': false,
  };
  userDetail: any;
  userInfo: any;

  sidebarOptions: any = [
    {
      title: 'Dashboard',
      svgUrl: '../assets/svgIcons/home.svg',
      route: '/catalog/dashboard',
      isSelected: false,
    },
    {
      title: 'User',
      svgUrl: '../assets/svgIcons/users.svg',
      route: '/catalog/user',
      isSelected: false,
    },
    {
      title: 'Courses',
      svgUrl: '../assets/svgIcons/mycourse1.svg',
      route: '/catalog/courses',
      isSelected: false,
    },
    {
      title: 'Quizzes',
      svgUrl: '../assets/svgIcons/quiz1.svg',
      route: '/catalog/quizzes',
      isSelected: false,
    },
    {
      title: 'Coupons',
      svgUrl: '../assets/svgIcons/offers.svg',
      route: '/catalog/specialOffers',
      isSelected: false,
    },
    {
      title: 'Employees',
      svgUrl: '../assets/svgIcons/users.svg',
      route: '/catalog/employee',
      isSelected: false,
    },
    {
      title: 'Leaderboard',
      svgUrl: '../assets/svgIcons/leaderboard.svg',
      route: '/catalog/leaderboard',
      isSelected: false,
    },
    {
      title: 'Banner',
      svgUrl: '../assets/img/banner.png',
      route: '/admin/banner',
      isSelected: false,
    },
    {
      title: 'Help',
      svgUrl: '../assets/svgIcons/help.svg',
      route: '/catalog/help',
      isSelected: false,
    },
    {
      title: 'Enroll User',
      svgUrl: '../assets/svgIcons/enroll.svg',
      route: '/catalog/enroll',
      isSelected: false,
    },
    {
      title: 'Payout',
      svgUrl: '../assets/img/payout.png',
      route: '/catalog/payout',
      isSelected: false,
    },
    {
      title: 'Campaign',
      svgUrl: '../assets/svgIcons/bell.svg',
      route: '/catalog/campaign',
      isSelected: false,
    },
    {
      title: 'Manual Campaign',
      svgUrl: '../assets/svgIcons/bell.svg',
      route: '/catalog/manual-campaign',
      isSelected: false,
    },
    {
      title: 'Referral Dashboard',
      svgUrl: '../assets/svgIcons/referral-link.svg',
      route: '/catalog/referral',
      isSelected: false,
    },
    {
      title: 'Webinar',
      svgUrl: '../assets/svgIcons/forum.svg',
      route: '/catalog/webinar',
      isSelected: false,
    },
    {
      title: 'Subscription',
      svgUrl: '../assets/svgIcons/sub.svg',
      route: '/catalog/subscription',
      isSelected: false,
    },
    {
      title: 'Plan',
      svgUrl: '../assets/svgIcons/rupee.svg',
      route: '/catalog/plan',
      isSelected: false,
    },
    {
      title: 'Trade Analysis',
      svgUrl: '../assets/svgIcons/sub.svg',
      route: '/catalog/trade-analysis',
      isSelected: false,
    },
    {
      title: 'Ticker',
      svgUrl: '../assets/svgIcons/trade.svg',
      route: '/catalog/ticker',
      isSelected: false,
    },
    {
      title: 'Batches',
      svgUrl: '../assets/svgIcons/batch.svg',
      route: '/catalog/batch',
      isSelected: false,
    },
    {
      title: 'Youtube Video',
      svgUrl: '../assets/svgIcons/mycourse1.svg',
      route: '/catalog/youtube',
      isSelected: false,
    },
    {
      title: 'Short Videos',
      svgUrl: '../assets/svgIcons/mycourse1.svg',
      route: '/catalog/shortVideos',
      isSelected: false,
    },
    {
      title: 'Reports',
      svgUrl: '../assets/svgIcons/collection.svg',
      route: '/catalog/reports',
      isSelected: false,
    },
    {
      title: 'UTM',
      svgUrl: '../assets/svgIcons/collection.svg',
      route: '/catalog/utm',
      isSelected: false,
    },
    {
      title: 'Webhook Search',
      svgUrl: '../assets/svgIcons/ic_Search.svg',
      route: '/catalog/webhook',
      isSelected: false,
    },
    {
      title: 'Compliance',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/catalog/compliance',
      isSelected: false,
    },
    {
      title: 'Transaction',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/catalog/transaction',
      isSelected: false,
    },
    {
      title: 'Community',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/catalog/community',
      isSelected: false,
    },
    {
      title: 'Leads',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/catalog/leads',
      isSelected: false,
    },
    {
      title: 'Audit Log',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/catalog/auditlog',
      isSelected: false,
    },
  ];

  sideBarCopy = [...this.sidebarOptions];

  //constructor
  constructor(
    private _httpClient: HttpClient,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    // this.sideBar();
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  get windowRef() {
    return window;
  }

  checkIfUrlIsValid() {
    let urlPath = window.location.href.split('/');
    let index = this.sidebarOptions.findIndex(
      (data: any) => data['route'] == '/' + urlPath[3] + '/' + urlPath[4]
    );
    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }

  async sideBar() {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
      return false;
    } else {
      this.sidebarOptions = [...this.sideBarCopy];
      this.sidebarOptions.forEach((data: any) => {
        if (data != null && data != undefined) {
          data['isSelected'] = false;
        }
      });
      const param = {
        email: this.userDetails['email'].trim(),
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAdmin}`;
      await this.fetchAdminFromService(param, URL).then((data: any) => {
        if (data['success']) {
          if (data['data'] != null) {
            this.userInfo = data['data'];
            var list: any = [];
            if (this.userInfo != null) {
              if (this.userInfo['accessControlList'].length > 0) {
                this.userInfo['accessControlList'].forEach((access: any) => {
                  var side = this.sidebarOptions.filter(
                    (sidebar: any) => sidebar['title'] == access
                  );
                  if (access != 'CSR') {
                    list.push(side[0]);
                  }
                });
                this.sidebarOptions = [...list];
                // this.gotoComponent(this.sidebarOptions [0]['title']);
              }
              return true;
            } else {
              this.router.navigate(['/auth/login']);
              return false;
            }
          }
        }
        return true;
      });
      return true;
    }
  }

  async setSideBarState(urlPath: any) {
    let valid = await this.sideBar();
    if (!valid) {
      return;
    }
    if (urlPath == undefined) {
      this.sidebarOptions[0].isSelected = true;
    } else {
      let valid = this.checkIfUrlIsValid();
      if (!valid) {
        this.gotoComponent(this.sidebarOptions[0].title);
        this.sidebarOptions[0]['isSelected'] = true;
      } else {
        this.sidebarOptions.forEach((option: any) => {
          if (option != null && option != undefined) {
            if (option.route == '/' + urlPath[3] + '/' + urlPath[4]) {
              option['isSelected'] = true;
            }
          }
        });
      }
    }
  }

  gotoComponent(title: String) {
    this.sidebarOptions.forEach((option: any) => {
      if (option != null && option != undefined) {
        if (option['title'] == title) {
          option['isSelected'] = true;
        } else {
          option['isSelected'] = false;
        }
      }
    });

    switch (title) {
      case 'User':
        this.router.navigate(['/catalog/user']);
        break;
      case 'Courses':
        this.router.navigate(['/catalog/courses']);
        break;
      case 'Quizzes':
        this.router.navigate(['/catalog/quizzes']);
        break;

      case 'Enroll User':
        this.router.navigate(['/catalog/enroll']);
        break;
      case 'Campaign':
        this.router.navigate(['/catalog/campaign/']);
        break;
      case 'Manual Campaign':
        this.router.navigate(['/catalog/manual-campaign/']);
        break;

      case 'Help':
        this.router.navigate(['/catalog/help/']);
        break;
      case 'Coupons':
        this.router.navigate(['/catalog/specialOffers']);
        break;
      case 'Banner':
        this.router.navigate(['/catalog/banner']);
        break;
      case 'Plan':
        this.router.navigate(['/catalog/plan']);
        break;
      case 'Logout':
        localStorage.removeItem('TGV2WebAdminDetails');
        this.router.navigate(['/auth/login']);
        break;
      case 'Webinar':
        this.router.navigate(['/catalog/webinar']);
        break;
      case 'Trade Analysis':
        this.router.navigate(['/catalog/trade-analysis']);
        break;
      case 'Ticker':
        this.router.navigate(['/catalog/ticker']);
        break;
      case 'Subscription':
        this.router.navigate(['/catalog/subscription']);
        break;
      case 'UTM':
        this.router.navigate(['/catalog/utm']);
        break;
      case 'Reports':
        this.router.navigate(['/catalog/reports']);
        break;
      case 'Leads':
        this.router.navigate(['/catalog/leads']);
        break;
      case 'Compliance':
        this.router.navigate(['/catalog/compliance']);
        break;
      case 'Transaction':
        this.router.navigate(['/catalog/transaction']);
        break;
      case 'Community':
        this.router.navigate(['/catalog/community']);
        break;
      case 'Audit Log':
        this.router.navigate(['/catalog/auditlog']);
        break;
      case 'Payout':
        this.router.navigate(['/catalog/payout']);
        break;
      case 'Referral Dashboard':
        this.router.navigate(['/catalog/referral']);
        break;
      case 'Employees':
        this.router.navigate(['/catalog/employee']);
        break;
      case 'Youtube Video':
        this.router.navigate(['/catalog/youtube']);
        break;
      case 'Batches':
        this.router.navigate(['/catalog/batch']);
        break;
      case 'Short Videos':
        this.router.navigate(['/catalog/shortVideos']);
        break;
      case 'Webhook Search':
        this.router.navigate(['/catalog/webhook']);
        break;
      case 'Dashboard':
        this.router.navigate(['/catalog/dashboard']);
        break;
      case 'Leaderboard':
        this.router.navigate(['/catalog/leaderboard']);
        break;
      default:
        this.router.navigate(['/catalog/user']);
        break;
    }
  }

  getTimePeriod(time: number) {
    if (time >= 12) {
      return `${time.toFixed(2)} pm`;
    } else {
      return `${time.toFixed(2)} am`;
    }
  }

  toHoursAndMinutes(totalMinutes: any) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)} Hours`;
  }
  padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }
  getAvatarText(str: String) {
    return str.substring(0, 1);
  }
  fetchCourseFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchUser(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  fetchModuleByCourseIdFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchOfferFromService(url: string) {
    return new Promise((resolve) => {
      this._httpClient.get(url).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  getMethodWithTokenAndParams(url: string, params: any, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.get(url, { headers: httpHeaders, params }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchQuizFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  insertOfferFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  insertCourseService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  insertQuizService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  fetchUsersFromService(param: object, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateUsersFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateUsersEnrollmentFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteSingleModuleFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteSingleActivityFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchCourseReviewFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteReviewFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateFeatureStatusFromReview(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchForumFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteQuestionFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  actionOnQuestionFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  suspendUserFromForumFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchCommentFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteCommentFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  getUserDetailsFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  payUserCommissionFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  findUserByEmailFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  insertUserDetailFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  enrollCourseFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  fetchUserFromMySqlFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  insertCommentFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  insertReplyFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchUserForNotificationFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  sendMailFromWebFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  sendMsgForAppFromWebFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  sendSmsFromWebFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  unEnrollUserFromWebFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchAdminFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateUserAccessFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateHelpVideoFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchHelpVideoFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  createHelpVideoFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteHelpVideoFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  sendReceiptMailFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteResourceFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  deleteUserFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  insertPaymentLogFromService(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  putMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethodWithToken(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  fetchDetailViaGet(url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.get(url, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  putMethodWithToken(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDetail(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  addData(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteData(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDataFromDB(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDataFromDBGet(url: string) {
    return new Promise((resolve) => {
      this._httpClient.get(url).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateDataViaPut(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
}
