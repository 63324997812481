import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-general-chat',
  templateUrl: './add-general-chat.component.html',
  styleUrls: ['./add-general-chat.component.scss'],
})
export class AddGeneralChatComponent implements OnInit {
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  chat: any;
  userDetails: any;

  myHeaders = new Headers();
  formdata = new FormData();
  listOfChat: any = [];

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  ngOnInit(): void {}

  cancel() {
    this.closeSideForm.emit(this.listOfChat);
  }

  async addGeneralChat() {
    this.isLoading = true;

    var formdata = new FormData();

    formdata.append('advisoryId', '0');

    formdata.append('title', 'General Chat!');

    let medium: any = ['In App', 'Push Notification'];

    formdata.set('medium', JSON.stringify(medium));

    formdata.append('publishDate', new Date().toString());

    formdata.append('description', this.chat);

    formdata.append('sendNotificationOnUpdate', JSON.stringify(true));

    formdata.append('type', 'Chat');

    formdata.append('closing', '0');
    formdata.append('opening', '0');

    var accessList: any = ['Silver', 'Gold', 'Free', 'Diamond'];

    formdata.append('plans', JSON.stringify(accessList));

    var headers = new Headers();

    headers.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateAdvisory}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                // this.cancel(res['result']);
                this.chat = null;
                this.listOfChat.push(res['result']);
                this.showSuccessToaster('Chat added successfully', 'Success');
              } else {
                this.isLoading = false;
                // this.cancel('Error');
                this.chat = null;
                this.showErrorToaster(
                  'Error Occurred while adding chat. Try again',
                  'Error'
                );
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          // this.cancel('Error');
          this.chat = null;
          this.showErrorToaster(
            'Error Occurred while adding chat. Try again',
            'Error'
          );
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.chat = null;
      this.showErrorToaster(
        'Error Occurred while adding chat. Try again',
        'Error'
      );
      console.log('error', error);
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
