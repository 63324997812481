<div class="main-container">
    <div class="contents">
        <div class="row">
            <div class="col-md-6" style="align-self: center;margin-bottom: 1em;">
                <div class="w-60">
                    <h2 class="welcome-text">Welcome to Tushar Ghone Web Admin</h2>
                </div>
                <div class="mini-welcome w-60">
                    Enter OTP and start managing
                </div>
                <form style="height: 5em;     margin-left: -4rem;
            ">
                    <div style="width: 70%; display: flex; margin: auto;">
                        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                    </div>
                </form>
                <div class="w-60">
                    <button *ngIf="!isConfirmClicked" [ngClass]="{ 'continue-btn-disabled': mobileNo==null, 'continue-btn-enabled': mobileNo!=null }" type="submit" (click)="confirmOtp()" [disabled]="mobileNo==null">Continue</button>
                    <div class="continue-btn-enabled" *ngIf="isConfirmClicked">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;height:20px;width:20px;color: white;">
                        </mat-progress-spinner>

                    </div>
                </div>
                <!-- <div class="w-60">
                    <div class="resend-otp">
                        Resend OTP
                    </div>
                </div> -->
                <div class="w-60">
                    <div class="policies m-auto">By continuing you agree to the <strong style="color:black">Terms of
                            Services</strong> and
                        <strong style="color:black">Privacy
                            policy.</strong>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img class="phone-img-size" src="../../../assets/img/login.jpg" alt="phoneimg" srcset="">
            </div>
        </div>
    </div>
</div>