import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tgAdvisoryApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import CryptoJS from "crypto-js";

@Component({
  selector: 'app-tgadvisory-community-user-mapping',
  templateUrl: './tgadvisory-community-user-mapping.component.html',
  styleUrls: ['./tgadvisory-community-user-mapping.component.scss']
})
export class TgadvisoryCommunityUserMappingComponent implements OnInit {

  userDetails: any;
  httpHeaders: any;

  todayDate: string | undefined;
  isLoading: boolean = false;
  isGiveRemoveAccessLoading: boolean = false;
  isUserInfoLoading: boolean = false;
  listOfCommunity: any = [];
  userInfo: any;

  accessToCommunity: any = [];
  noUser: String = 'Search user';

  userFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(
      '^(\\+\\d{1,3}[- ]?)?\\d{10}$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
    ),
  ]);
  userInfoForm: FormGroup = new FormGroup({
    searchedString: this.userFormControl,
  });

  constructor(
    private router: Router,
    public as: AppService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.todayDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.userInfoForm = this.formBuilder.group({
      searchedString: this.userFormControl,
    });
    this.fetchAllAdvisoryCommunity();
  }

  async fetchAllAdvisoryCommunity() {
    this.isLoading = true;
    try {
      this.listOfCommunity = [];
      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchAllCommunity}`;
      await this.as
        .fetchDetail({}, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfCommunity = data['result'];
            this.listOfCommunity.forEach((crs: any) => {
              crs['hasAccess'] = false;
            });
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
    }
  }

  giveAccessToCommunity(community: any, event: any) {
    console.log('event.target.checked', event.target.checked);
    if (!event.target.checked) {
      this.accessToCommunity = this.accessToCommunity.filter(
        (cInfo: any) => cInfo['_id'] != community['_id']
      );
      return;
    }
    var exist = false;
    this.accessToCommunity.forEach((cInfo: any) => {
      if (cInfo['_id'] == community['_id']) {
        exist = true;
      }
    });
    if (!exist) {
      this.accessToCommunity.push(community);
    } else {
      this.accessToCommunity = this.accessToCommunity.filter(
        (cInfo: any) => cInfo['_id'] != community['_id']
      );
    }
  }

  communityStartDateChangeHandler(event: any, community: any) {
    console.log(event);
    community['startDate'] = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
    this.accessToCommunity.forEach((cInfo: any) => {
      if (cInfo['checked'] && cInfo['_id'] == community['_id']) {
        cInfo['startDate'] = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
      }
    });
  }

  communityEndDateChangeHandler(event: any, community: any) {
    console.log(event);
    community['endDate'] = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
    this.accessToCommunity.forEach((cInfo: any) => {
      if (cInfo['checked'] && cInfo['_id'] == community['_id']) {
        cInfo['endDate'] = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
      }
    });
  }

  async searchUser() {
    var searchedString = this.userInfoForm.get('searchedString')?.value;
    if (!this.userInfoForm.invalid) {
      this.listOfCommunity.forEach((community: any) => {
        community['hasAccess'] = false;
        community['checked'] = false;
        community['startDate'] = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
        community['endDate'] = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
      });
      this.isUserInfoLoading = true;
      this.userInfo = {};
      const param = {
        searchedString: searchedString,
      };
      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.findUserByPhoneAndEmail}`;
      await this.as.fetchDetail(param, URL, this.httpHeaders).then((data: any) => {
        if (data['success'] && data['result'] != null) {
          this.userInfo = data['result'];
          this.accessToCommunity = [];
          this.isUserInfoLoading = false;
          if (this.userInfo['communityDetail'].length > 0) {
            this.userInfo['communityDetail'].forEach((userCommunity: any) => {
              this.listOfCommunity.forEach((community: any) => {
                if (community['_id'] == userCommunity['community']) {
                  community['hasAccess'] = true;
                  community['checked'] = true;
                  community['startDate'] = formatDate(userCommunity['startDate'], 'yyyy-MM-dd', 'en-US');
                  community['endDate'] = formatDate(userCommunity['endDate'], 'yyyy-MM-dd', 'en-US');
                  this.accessToCommunity.push(community);
                }
              });
            });
          }
        } else {
          this.noUser = 'User not found';
          this.isUserInfoLoading = false;
          this.userInfo = null;
        }
      });
    }
  }

  async updateCommunityAccess() {
    try {
      this.isGiveRemoveAccessLoading = true;
      let encryptUserId = CryptoJS.AES.encrypt(
        this.userInfo['_id'].toString(),
        "$Future!@^*^&35"
      ).toString();
      var communityList: any = [];
      this.accessToCommunity.forEach((community: any) => {
        communityList.push({ _id: community['_id'], startDate: community['startDate'], endDate: community['endDate'], });
      });
      const param = {
        accessToCommunity: communityList,
        userId: encryptUserId,
      };
      const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.setUnsetUserCommunity}`;
      await this.as.addData(param, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.userInfo = data['result'];
          this.showSuccessToaster('Community Access Given/Revoke Successfully', 'Success');
        } else {
          this.showErrorToaster(
            'Some Error occurred while giving/revoking access to community,try again',
            'Error Occurred'
          );
        }
        this.isGiveRemoveAccessLoading = false;
      });
    } catch (error) {
      this.isGiveRemoveAccessLoading = false;
    }

    this.listOfCommunity.forEach((community: any) => {
      community['hasAccess'] = false;
    });
    if (this.userInfo['communityDetail'].length > 0) {
      this.accessToCommunity = [];
      this.userInfo['communityDetail'].forEach((userCommunity: any) => {
        this.listOfCommunity.forEach((community: any) => {
          if (community['_id'] == userCommunity['community']) {
            community['hasAccess'] = true;
            community['checked'] = true;
            community['startDate'] = formatDate(userCommunity['startDate'], 'yyyy-MM-dd', 'en-US');
            community['endDate'] = formatDate(userCommunity['endDate'], 'yyyy-MM-dd', 'en-US');
            this.accessToCommunity.push(community);
          }
        });
      });
    }
  }

  isStartDateInFuture(startDate: any) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight for accurate comparison

    const start = new Date(startDate);
    return start > today;
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
