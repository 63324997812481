import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ExcelService } from '../../excel.service';

@Component({
  selector: 'app-sub-dashboard',
  templateUrl: './sub-dashboard.component.html',
  styleUrls: ['./sub-dashboard.component.scss']
})
export class SubDashboardComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  listOfSubscription: any = [];
  subTypeData: any;
  subStatusData: any;
  goldCount: number = 0;
  silverCount: number = 0;
  mmtcCount: number = 0;
  mmocCount: number = 0;
  activeCount: number = 0;
  inactiveCount: number = 0;



  isReportGenerating: boolean = false;

  isTopContentLoading: boolean = true;
  silverFreeCount: number = 0;
  goldFreeCount: number = 0;

  silverFreeValidTillDateCount: number = 0;
  goldFreeValidTillDateCount: number = 0;

  silverPaidCount: number = 0;
  goldPaidCount: number = 0;

  silverPaidValidTillDateCount: number = 0;
  goldPaidValidTillDateCount: number = 0;

  mmtcCourseTotalCount: number = 0;
  mmocCourseTotalCount: number = 0;

  silverPaidAmount: number = 0;
  goldPaidAmount: number = 0;

  isBottomContentLoading: boolean = true;
  mmtcCourseCount: number = 0;
  mmtcCourseActiveCount: number = 0;
  mmtcCourseInActiveCount: number = 0;
  silverSubsActiveCount: number = 0;
  silverSubsInActiveCount: number = 0;
  silverSubsPaidCount: number = 0;
  silverSubsFreeCount: number = 0;

  mmocCourseCount: number = 0;
  mmocCourseActiveCount: number = 0;
  mmocCourseInActiveCount: number = 0;
  goldSubsActiveCount: number = 0;
  goldSubsInActiveCount: number = 0;
  goldSubsPaidCount: number = 0;
  goldSubsFreeCount: number = 0;

  startDate: any;
  endDate: any;
  p: any = 1;
  count: any = 10;

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public excelExport: ExcelService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
    }
  }

  ngOnInit(): void {
    if (this.appService.startDate != null && this.appService.endDate != null) {
      this.startDate = this.appService.startDate;
      this.endDate = this.appService.endDate;
    }
    this.filterSubscriptionPart1();
  }

  async filterSubscriptionPart1() {
    this.isLoading = false;
    this.isTopContentLoading = true;
    try {
      let URL = '';

      this.silverFreeCount = 0;
      this.goldFreeCount = 0;

      this.silverFreeValidTillDateCount = 0;
      this.goldFreeValidTillDateCount = 0;

      this.silverPaidCount = 0;
      this.goldPaidCount = 0;

      this.silverPaidValidTillDateCount = 0;
      this.goldPaidValidTillDateCount = 0;

      this.silverPaidAmount = 0;
      this.goldPaidAmount = 0;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.filterSubscriptionPart1}`;
      const param = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isTopContentLoading = false;

            this.silverFreeCount = data['result']['silverFreeCount'];
            this.goldFreeCount = data['result']['goldFreeCount'];

            this.silverFreeValidTillDateCount = data['result']['silverFreeValidTillDateCount'];
            this.goldFreeValidTillDateCount = data['result']['goldFreeValidTillDateCount'];

            this.silverPaidCount = data['result']['silverPaidCount'];
            this.goldPaidCount = data['result']['goldPaidCount'];

            this.silverPaidValidTillDateCount = data['result']['silverPaidValidTillDateCount'];
            this.goldPaidValidTillDateCount = data['result']['goldPaidValidTillDateCount'];

            this.silverPaidAmount = data['result']['silverPaidAmount'];
            this.goldPaidAmount = data['result']['goldPaidAmount'];

            this.startDate = data['result']['startDate'];
            this.endDate = data['result']['endDate'];

            this.mmtcCourseTotalCount = data['result']['mmtcCourseTotalCount'];
            this.mmocCourseTotalCount = data['result']['mmocCourseTotalCount'];
          } else {
            this.isTopContentLoading = false;
          }
          this.filterSubscriptionPart2();
        });
    } catch (error) {
      this.isTopContentLoading = false;
      this.filterSubscriptionPart2();
    }
  }
  async filterSubscriptionPart2() {
    this.isBottomContentLoading = true;
    try {
      let URL = '';

      this.mmtcCourseCount = 0;
      this.mmtcCourseActiveCount = 0;
      this.mmtcCourseInActiveCount = 0;
      this.silverSubsActiveCount = 0;
      this.silverSubsInActiveCount = 0;
      this.silverSubsPaidCount = 0;
      this.silverSubsFreeCount = 0;

      this.mmocCourseCount = 0;
      this.mmocCourseActiveCount = 0;
      this.mmocCourseInActiveCount = 0;
      this.goldSubsActiveCount = 0;
      this.goldSubsInActiveCount = 0;
      this.goldSubsPaidCount = 0;
      this.goldSubsFreeCount = 0;

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.filterSubscriptionPart2}`;
      const param = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isBottomContentLoading = false;

            this.mmtcCourseCount = data['result']['mmtcCourseCount'];
            this.mmtcCourseActiveCount = data['result']['mmtcCourseActiveCount'];
            this.mmtcCourseInActiveCount = data['result']['mmtcCourseInActiveCount'];
            this.silverSubsActiveCount = data['result']['silverSubsActiveCount'];
            this.silverSubsInActiveCount = data['result']['silverSubsInActiveCount'];
            this.silverSubsPaidCount = data['result']['silverSubsPaidCount'];
            this.silverSubsFreeCount = data['result']['silverSubsFreeCount'];

            this.mmocCourseCount = data['result']['mmocCourseCount'];
            this.mmocCourseActiveCount = data['result']['mmocCourseActiveCount'];
            this.mmocCourseInActiveCount = data['result']['mmocCourseInActiveCount'];
            this.goldSubsActiveCount = data['result']['goldSubsActiveCount'];
            this.goldSubsInActiveCount = data['result']['goldSubsInActiveCount'];
            this.goldSubsPaidCount = data['result']['goldSubsPaidCount'];
            this.goldSubsFreeCount = data['result']['goldSubsFreeCount'];
          } else {
            this.isBottomContentLoading = false;
          }
        });
    } catch (error) {
      this.isBottomContentLoading = false;
    }
  }
  async fetchDashboardDetails() {
    this.isLoading = true;
    try {
      let URL = '';
      this.subStatusData = null;
      this.subTypeData = null;
      this.goldCount = 0;
      this.silverCount = 0;
      this.mmtcCount = 0;
      this.mmocCount = 0;
      this.listOfSubscription = [];
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchSubscriptionAfterFilter}`;
      const param = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isLoading = false;
            data['result'].forEach((sub: any, index: number) => {
              this.listOfSubscription.push({
                srNo: index + 1,
                _id: sub['_id'],
                user: sub['user'],
                startDate: sub['startDate'],
                endDate: sub['endDate'],
                totalAmount: sub['totalAmount'],
                plan: sub['plan'],
                visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],
                priceAndDuration: sub['priceAndDuration'],
                subsriptionStatus: sub['subsriptionStatus'],
                rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                subscriptionStatus: sub['subscriptionStatus'],
                totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                type: sub['type'],
                createdAt: sub['createdAt'],
              });
            });
            this.subTypeData = data['subTypeData'];
            this.subStatusData = data['subStatusData'];
            this.goldCount = data['goldCount'];
            this.silverCount = data['silverCount'];
            this.mmtcCount = data['mmtcCount'];
            this.mmocCount = data['mmocCount'];
            this.startDate = data['startDate'];
            this.endDate = data['endDate'];
            this.activeCount = data['activeCount'];
            this.inactiveCount = data['inactiveCount'];
          } else {
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.isLoading = false;
    }
  }
  gotoFilterSubs(planType: string, status: string) {
    if (status == 'Active') {
      this.appService.subsStatus = ['ACTIVE'];
    } else {
      this.appService.subsStatus = ['ACTIVE', 'INACTIVE'];
    }
    this.appService.onlyActive = status == 'Active' ? true : false;
    this.appService.planType = planType;
    this.router.navigate(['/catalog/subscription/filter-subscription']);
  }


  setDateNull() {
    this.startDate = null;
    this.endDate = null;
  }
  dateChanged(type: any, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      this.filterSubscriptionPart1();
    }
  }

  getDataByType(type: string): any {
    let index = this.subTypeData.findIndex((data: any) => data._id == type);
    if (index == -1) {
      return { 'count': 0, 'totalAmount': 0 }
    } else {
      return this.subTypeData[index];
    }
  }


  navigateToSubList() {
    // this.appService.startDate = new Date(this.startDate);
    // this.appService.endDate = new Date(this.endDate);
    this.router.navigate(['/catalog/subscription/list-subscription']);
  }



  async downloadCourseReport(courseName: string,) {
    this.isReportGenerating = true;
    let listOfCourseDetail: any = [];
    try {
      let URL = '';
      let param: any;
      param = {
        isMMTC: courseName == 'MMTC' ? true : false,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      URL = `${webApi.domain + webApi.endPoint.fetchAllCourseDetailPart1ForReport}`;
      let httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            let index = 0;
            data['result'].forEach((enrolment: any,) => {
              if (enrolment['user'] != null && enrolment['user'] != undefined) {
                enrolment['user']['fullName'] = enrolment['user']['fullName'] ?? 'N.A.';
              }
              listOfCourseDetail.push({
                srNo: index,
                _id: enrolment['_id'],
                user: enrolment['user'],
                startDate: enrolment['startDate'],
                endDate: enrolment['endDate'],
                course: enrolment['course']['courseName'],
                createdAt: enrolment['createdAt'],
                courseCompleted: enrolment['courseCompleted'],
                courseCompletedDate: enrolment['courseCompletedDate'],
              });
              index++;
            });
            var toExport: any = [];
            listOfCourseDetail.forEach(
              (enrolment: any, index: number) => {
                let query: any = {
                  SrNo: index + 1,
                  Name:enrolment.user == null || enrolment.user == undefined ? 'NA' : enrolment.user['fullName'],
                  Phone:enrolment.user == null || enrolment.user == undefined ? 'NA' : enrolment.user['phone'],
                  Email:enrolment.user == null || enrolment.user == undefined ? 'NA' : enrolment.user['email'],
                  Course: enrolment['course'],
                  StartDate: enrolment.startDate == null || enrolment.startDate == undefined ? 'NA' : new Date(enrolment.startDate)
                    .toISOString()
                    .slice(0, 10),
                  EndDate: enrolment.endDate == null || enrolment.endDate == undefined ? 'NA' : new Date(enrolment.endDate).toISOString().slice(0, 10),
                  EnrolmentDate: enrolment.createdAt == null || enrolment.createdAt == undefined ? 'NA' : new Date(enrolment.createdAt).toISOString().slice(0, 10),
                  CourseCompleted: enrolment['courseCompleted'],
                  CourseCompletedDate: enrolment.courseCompletedDate == null || enrolment.courseCompletedDate == undefined ? 'NA' : new Date(enrolment.courseCompletedDate).toISOString().slice(0, 10),

                };
                toExport.push(query);
              }
            );
            this.excelExport.exportAsExcelFile(toExport, `${courseName} Report`);
            this.isReportGenerating = false;
          } else {
            listOfCourseDetail = [];
            this.isReportGenerating = false;
          }
        });
    } catch (error) {
      console.log(error);
      listOfCourseDetail = [];
      this.isReportGenerating = false;
    }



  }

  async downloadCoursePart2Report(courseName: string, status: string, type: string) {
    this.isReportGenerating = true;
    let listOfCourseDetail: any = [];
    try {
      let URL = '';
      let param: any;
      let subsStatus: any = [];
      if (status == 'All') {
        subsStatus = ['SUCCESS', 'UNENROLED'];
      } else {
        subsStatus = ['SUCCESS'];
      }
      param = {
        isMMTC: courseName == 'MMTC' ? true : false,
        status: subsStatus,
        type: type
      };
      URL = `${webApi.domain + webApi.endPoint.fetchAllCourseDetailPart2ForReport}`;
      let httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            let index = 0;
            data['result'].forEach((enrolment: any,) => {
              if (enrolment['user'] != null && enrolment['user'] != undefined) {

                if (enrolment['user'] != null && enrolment['user'] != undefined) {
                  enrolment['user']['fullName'] = enrolment['user']['fullName'] ?? 'N.A.';
                }
                listOfCourseDetail.push({
                  srNo: index,
                  _id: enrolment['_id'],
                  user: enrolment['user'],
                  startDate: enrolment['startDate'],
                  endDate: enrolment['endDate'],
                  course: enrolment['course']['courseName'],
                  createdAt: enrolment['createdAt'],
                  courseCompleted: enrolment['courseCompleted'],
                  courseCompletedDate: enrolment['courseCompletedDate'],
                });
                index++;
              }
            });
            var toExport: any = [];
            listOfCourseDetail.forEach(
              (enrolment: any, index: number) => {
                let query: any = {
                  SrNo: index + 1,
                  Name: enrolment.user == null || enrolment.user == undefined ? 'NA' : enrolment.user['fullName'],
                  Phone: enrolment.user == null || enrolment.user == undefined ? 'NA' : enrolment.user['phone'],
                  Email: enrolment.user == null || enrolment.user == undefined ? 'NA' : enrolment.user['email'],
                  Course: enrolment['course'],
                  StartDate: enrolment.startDate == null || enrolment.startDate == undefined ? 'NA' : new Date(enrolment.startDate)
                    .toISOString()
                    .slice(0, 10),
                  EndDate: enrolment.endDate == null || enrolment.endDate == undefined ? 'NA' : new Date(enrolment.endDate).toISOString().slice(0, 10),
                  EnrolmentDate: enrolment.createdAt == null || enrolment.createdAt == undefined ? 'NA' : new Date(enrolment.createdAt).toISOString().slice(0, 10),
                  CourseCompleted: enrolment['courseCompleted'],
                  CourseCompletedDate: enrolment.courseCompletedDate == null || enrolment.courseCompletedDate == undefined ? 'NA' : new Date(enrolment.courseCompletedDate).toISOString().slice(0, 10),

                };
                toExport.push(query);
              }
            );
            this.excelExport.exportAsExcelFile(toExport, `All ${courseName} Report`);
            this.isReportGenerating = false;
          } else {
            listOfCourseDetail = [];
            this.isReportGenerating = false;
          }
        });
    } catch (error) {
      console.log(error);
      listOfCourseDetail = [];
      this.isReportGenerating = false;
    }



  }
  async downloadSubsReport(type: string, status: string, courseName: string,) {
    this.isReportGenerating = true;
    let subsStatus: any = [];
    if (status == 'Active') {
      subsStatus = ['ACTIVE'];
    } else {
      subsStatus = ['ACTIVE', 'INACTIVE'];
    }

    let listOfSubscriptionToDisplay: any = [];
    try {
      let URL = '';
      let param: any;
      param = {
        type: type,
        isMMTC: courseName == 'MMTC' ? true : false,
        status: subsStatus,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      URL = `${webApi.domain + webApi.endPoint.fetchAllSubsDetailPart1ForReport}`;
      let httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            let index = 0;
            data['result'].forEach((sub: any,) => {
              sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';
              listOfSubscriptionToDisplay.push({
                srNo: index,
                _id: sub['_id'],
                user: sub['user'],
                startDate: sub['startDate'],
                endDate: sub['endDate'],
                totalAmount: sub['totalAmount'],
                plan: sub['plan'],
                visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],
                priceAndDuration: sub['priceAndDuration'],
                rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                subscriptionStatus: sub['subscriptionStatus'],
                totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                type: sub['type'],
                createdAt: sub['createdAt'],
              });
              index++;
            });
            var toExport: any = [];
            listOfSubscriptionToDisplay.forEach(
              (subscription: any, index: number) => {
                let query: any = {
                  SrNo: index + 1,
                  Name: subscription.user['fullName'],
                  Phone: subscription.user['phone'],
                  Email: subscription.user['email'],
                  Plan: subscription.plan.name + ' (' + subscription.plan.subType + ')',
                  Type: subscription.type,
                  VisitCount: subscription.visitCount,
                  Rzp_Sub_Id: subscription.rzpSubscriptionId,
                  StartDate: new Date(subscription.startDate)
                    .toISOString()
                    .slice(0, 10),
                  EndDate: new Date(subscription.endDate).toISOString().slice(0, 10),
                  Duration: `${subscription.priceAndDuration['duration'] ?? ''} ${subscription.priceAndDuration['durationType'] ?? ''
                    }`,
                  Amount: subscription.priceAndDuration['price'] ?? 0,
                  Status: subscription.subscriptionStatus
                };
                toExport.push(query);
              }
            );
            this.excelExport.exportAsExcelFile(toExport, `${courseName == 'MMTC' ? 'Silver' : 'Gold'} ${type} Subscription Report`);
            this.isReportGenerating = false;
          } else {
            listOfSubscriptionToDisplay = [];
            this.isReportGenerating = false;
          }
        });
    } catch (error) {
      listOfSubscriptionToDisplay = [];
      this.isReportGenerating = false;
    }



  }
  async downloadSubsActiveTillDateReport(type: string, status: string, courseName: string,) {
    this.isReportGenerating = true;
    let subsStatus: any = [];
    if (status == 'Active') {
      subsStatus = ['ACTIVE', 'DEACTIVATED'];
    } else {
      subsStatus = ['ACTIVE', 'INACTIVE'];
    }

    let listOfSubscriptionToDisplay: any = [];
    try {
      let URL = '';
      let param: any;
      param = {
        type: type,
        isMMTC: courseName == 'MMTC' ? true : false,
        status: subsStatus,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      URL = `${webApi.domain + webApi.endPoint.fetchAllSubsDetailAboutToExpirePart1ForReport}`;
      let httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            let index = 0;
            data['result'].forEach((sub: any,) => {
              sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';
              listOfSubscriptionToDisplay.push({
                srNo: index,
                _id: sub['_id'],
                user: sub['user'],
                startDate: sub['startDate'],
                endDate: sub['endDate'],
                totalAmount: sub['totalAmount'],
                plan: sub['plan'],
                visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],
                priceAndDuration: sub['priceAndDuration'],
                rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                subscriptionStatus: sub['subscriptionStatus'],
                totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                type: sub['type'],
                createdAt: sub['createdAt'],
              });
              index++;
            });
            var toExport: any = [];
            listOfSubscriptionToDisplay.forEach(
              (subscription: any, index: number) => {
                let query: any = {
                  SrNo: index + 1,
                  Name: subscription.user['fullName'],
                  Phone: subscription.user['phone'],
                  Email: subscription.user['email'],
                  Plan: subscription.plan.name + ' (' + subscription.plan.subType + ')',
                  Type: subscription.type,
                  VisitCount: subscription.visitCount,
                  Rzp_Sub_Id: subscription.rzpSubscriptionId,
                  StartDate: new Date(subscription.startDate)
                    .toISOString()
                    .slice(0, 10),
                  EndDate: new Date(subscription.endDate).toISOString().slice(0, 10),
                  Duration: `${subscription.priceAndDuration['duration'] ?? ''} ${subscription.priceAndDuration['durationType'] ?? ''
                    }`,
                  Amount: subscription.priceAndDuration['price'] ?? 0,
                  Status: subscription.subscriptionStatus
                };
                toExport.push(query);
              }
            );
            this.excelExport.exportAsExcelFile(toExport, `${courseName == 'MMTC' ? 'Silver' : 'Gold'} ${type} Subscription Report`);
            this.isReportGenerating = false;
          } else {
            listOfSubscriptionToDisplay = [];
            this.isReportGenerating = false;
          }
        });
    } catch (error) {
      listOfSubscriptionToDisplay = [];
      this.isReportGenerating = false;
    }



  }
  async generateReportSubsPart2(planType: string, status: string) {
    this.isReportGenerating = true;
    let type: any;
    if (status == 'Active') {
      type = 'ACTIVE';
    } else {
      type = 'INACTIVE';
    }

    let listOfSubscriptionToDisplay: any = [];
    try {
      let URL = '';
      let param: any;
      param = {
        planType: planType,
        type: type
      };
      URL = `${webApi.domain + webApi.endPoint.fetchAllSubscriptionPart2ForReport}`;
      let httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            let index = 0;
            data['result'].forEach((sub: any,) => {
              sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';
              listOfSubscriptionToDisplay.push({
                srNo: index,
                _id: sub['_id'],
                user: sub['user'],
                startDate: sub['startDate'],
                endDate: sub['endDate'],
                totalAmount: sub['totalAmount'],
                plan: sub['plan'],
                visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],
                priceAndDuration: sub['priceAndDuration'],
                rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                subscriptionStatus: sub['subscriptionStatus'],
                totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                type: sub['type'],
                createdAt: sub['createdAt'],
              });
              index++;
            });
            var toExport: any = [];
            listOfSubscriptionToDisplay.forEach(
              (subscription: any, index: number) => {
                let query: any = {
                  SrNo: index + 1,
                  Name: subscription.user['fullName'],
                  Phone: subscription.user['phone'],
                  Email: subscription.user['email'],
                  Plan: subscription.plan.name + ' (' + subscription.plan.subType + ')',
                  Type: subscription.type,
                  VisitCount: subscription.visitCount,
                  Rzp_Sub_Id: subscription.rzpSubscriptionId,
                  StartDate: new Date(subscription.startDate)
                    .toISOString()
                    .slice(0, 10),
                  EndDate: new Date(subscription.endDate).toISOString().slice(0, 10),
                  Duration: `${subscription.priceAndDuration['duration'] ?? ''} ${subscription.priceAndDuration['durationType'] ?? ''
                    }`,
                  Amount: subscription.priceAndDuration['price'] ?? 0,
                  Status: subscription.subscriptionStatus
                };
                toExport.push(query);
              }
            );
            this.excelExport.exportAsExcelFile(toExport, `${planType} ${status} Subscription Report`);
            this.isReportGenerating = false;
          } else {
            listOfSubscriptionToDisplay = [];
            this.isReportGenerating = false;
          }
        });
    } catch (error) {
      listOfSubscriptionToDisplay = [];
      this.isReportGenerating = false;
    }



  }
  async generateReportSubsFreePaidPart2(planType: string, type: string) {
    this.isReportGenerating = true;


    let listOfSubscriptionToDisplay: any = [];
    try {
      let URL = '';
      let param: any;
      param = {
        planType: planType,
        type: type
      };
      URL = `${webApi.domain + webApi.endPoint.fetchAllSubscriptionFreePaidPart2ForReport}`;
      let httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            let index = 0;
            data['result'].forEach((sub: any,) => {
              sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';
              listOfSubscriptionToDisplay.push({
                srNo: index,
                _id: sub['_id'],
                user: sub['user'],
                startDate: sub['startDate'],
                endDate: sub['endDate'],
                totalAmount: sub['totalAmount'],
                plan: sub['plan'],
                visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],
                priceAndDuration: sub['priceAndDuration'],
                rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                subscriptionStatus: sub['subscriptionStatus'],
                totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                type: sub['type'],
                createdAt: sub['createdAt'],
              });
              index++;
            });
            var toExport: any = [];
            listOfSubscriptionToDisplay.forEach(
              (subscription: any, index: number) => {
                let query: any = {
                  SrNo: index + 1,
                  Name: subscription.user['fullName'],
                  Phone: subscription.user['phone'],
                  Email: subscription.user['email'],
                  Plan: subscription.plan.name + ' (' + subscription.plan.subType + ')',
                  Type: subscription.type,
                  VisitCount: subscription.visitCount,
                  Rzp_Sub_Id: subscription.rzpSubscriptionId,
                  StartDate: new Date(subscription.startDate)
                    .toISOString()
                    .slice(0, 10),
                  EndDate: new Date(subscription.endDate).toISOString().slice(0, 10),
                  Duration: `${subscription.priceAndDuration['duration'] ?? ''} ${subscription.priceAndDuration['durationType'] ?? ''
                    }`,
                  Amount: subscription.priceAndDuration['price'] ?? 0,
                  Status: subscription.subscriptionStatus
                };
                toExport.push(query);
              }
            );
            this.excelExport.exportAsExcelFile(toExport, `${type} ${planType} Subscription Report`);
            this.isReportGenerating = false;
          } else {
            listOfSubscriptionToDisplay = [];
            this.isReportGenerating = false;
          }
        });
    } catch (error) {
      listOfSubscriptionToDisplay = [];
      this.isReportGenerating = false;
    }



  }
  async generateReportSubs(planType: string, status: string) {
    this.isReportGenerating = true;
    let subsStatus: any = [];
    if (status == 'Active') {
      subsStatus = ['ACTIVE'];
    } else {
      subsStatus = ['ACTIVE', 'INACTIVE'];
    }

    let listOfSubscriptionToDisplay: any = [];
    try {
      let URL = '';
      let param: any;
      param = {
        planType: planType,
        status: subsStatus
      };
      URL = `${webApi.domain + webApi.endPoint.fetchAllFilterSubscriptionForReport}`;
      let httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            let index = 0;
            data['result'].forEach((sub: any,) => {
              sub['user']['fullName'] = sub['user']['fullName'] ?? 'N.A.';
              listOfSubscriptionToDisplay.push({
                srNo: index,
                _id: sub['_id'],
                user: sub['user'],
                startDate: sub['startDate'],
                endDate: sub['endDate'],
                totalAmount: sub['totalAmount'],
                plan: sub['plan'],
                visitCount: sub['visitCount'] == null || sub['visitCount'] == undefined ? 0 : sub['visitCount'],
                priceAndDuration: sub['priceAndDuration'],
                rzpSubscriptionId: sub['rzpSubscriptionId'] ?? 'N.A.',
                subscriptionStatus: sub['subscriptionStatus'],
                totalBilligCycle: sub['totalBilligCycle'] ?? 0,
                remainingCycleCount: sub['remainingCycleCount'] ?? 0,
                totalCyclePaidCount: sub['totalCyclePaidCount'] ?? 0,
                type: sub['type'],
                createdAt: sub['createdAt'],
              });
              index++;
            });
            var toExport: any = [];
            listOfSubscriptionToDisplay.forEach(
              (subscription: any, index: number) => {
                let query: any = {
                  SrNo: index + 1,
                  Name: subscription.user['fullName'],
                  Phone: subscription.user['phone'],
                  Email: subscription.user['email'],
                  Plan: subscription.plan.name + ' (' + subscription.plan.subType + ')',
                  Type: subscription.type,
                  VisitCount: subscription.visitCount,
                  Rzp_Sub_Id: subscription.rzpSubscriptionId,
                  StartDate: new Date(subscription.startDate)
                    .toISOString()
                    .slice(0, 10),
                  EndDate: new Date(subscription.endDate).toISOString().slice(0, 10),
                  Duration: `${subscription.priceAndDuration['duration'] ?? ''} ${subscription.priceAndDuration['durationType'] ?? ''
                    }`,
                  Amount: subscription.priceAndDuration['price'] ?? 0,
                  Status: subscription.subscriptionStatus
                };
                toExport.push(query);
              }
            );
            this.excelExport.exportAsExcelFile(toExport, 'Subscription Report');
            this.isReportGenerating = false;
          } else {
            listOfSubscriptionToDisplay = [];
            this.isReportGenerating = false;
          }
        });
    } catch (error) {
      listOfSubscriptionToDisplay = [];
      this.isReportGenerating = false;
    }



  }
  pageChanged(event: any) {
    this.p = event;
    console.log(event);
  }
}
