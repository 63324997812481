import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-module-detail',
  templateUrl: './add-module-detail.component.html',
  styleUrls: ['./add-module-detail.component.scss']
})
export class AddModuleDetailComponent implements OnInit {
  isLoading: boolean = false;
  @Input() batchDetail: any;
  @Input() moduleDetailToEdit: any;
  listOfResources: any = [];
  defaultCoverImage = null;
  showAddNewResources: boolean = false;
  disable: boolean = true;
  selectedFile: any = null;
  selectedFileName: any = 'No File Selected';
  editResources: boolean = false;
  editLinks: boolean = false;
  listOfDescription: any = [];
  myHeaders = new Headers();
  userDetails: any;
  formdata = new FormData();
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  moduleNameFormControl = new FormControl('', [Validators.required]);
  meetingLinkFormControl = new FormControl('', []);
  publishDateFormControl = new FormControl('', [Validators.required]);
  startTimeFormControl = new FormControl('', []);
  endTimeFormControl = new FormControl('', []);
  positionFormControl = new FormControl('', [Validators.required]);
  durationFormControl = new FormControl('', [Validators.required]);
  addModuleForm: FormGroup = new FormGroup({
    moduleName: this.moduleNameFormControl,
    position: this.positionFormControl,
    duration: this.durationFormControl,
  });
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.addModuleForm = this.formBuilder.group({
      moduleName: this.moduleNameFormControl,
      position: this.positionFormControl,
      duration: this.durationFormControl,
      meetingLink: this.meetingLinkFormControl,
      publishDate: this.publishDateFormControl,
      startTime: this.startTimeFormControl,
      endTime: this.endTimeFormControl,
    });
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if (this.moduleDetailToEdit != null) {
      this.addModuleForm
        .get('moduleName')
        ?.setValue(this.moduleDetailToEdit['title']);
      this.addModuleForm
        .get('meetingLink')
        ?.setValue(this.moduleDetailToEdit['meetingLink']);
      this.addModuleForm
        .get('publishDate')
        ?.setValue(
          new Date(this.moduleDetailToEdit['publishDate']).toISOString().slice(0, 10)
        );
      this.addModuleForm
        .get('publishDate')
        ?.setValue(
          formatDate(this.moduleDetailToEdit['publishDate'], 'yyyy-MM-dd', 'en')
        );
      this.addModuleForm
        .get('startTime')
        ?.setValue(this.getTimeFormat(String(this.moduleDetailToEdit['startTime'])));
      this.addModuleForm
        .get('endTime')
        ?.setValue(this.getTimeFormat(String(this.moduleDetailToEdit['endTime'])));

      this.addModuleForm
        .get('position')
        ?.setValue(this.moduleDetailToEdit['position']);
      this.addModuleForm
        .get('duration')
        ?.setValue(this.moduleDetailToEdit['duration']);
    } else {
      this.showAddNewResources = true;
      this.addModuleForm.get('moduleName')?.setValue('');
    }
  }
  getTimeFormat(time: string) {
    let dt = time.split('.');
    let val = '';
    if (dt.length > 1) {
      if (dt[0].length == 1) {
        val = `0${dt[0]}:`;
      } else {
        val = `${dt[0]}:`;
      }
      if (dt[1].length == 1) {
        val = val + `${dt[1]}0`;
      } else {
        val = val + `${dt[1]}`;
      }
    } else {
      if (dt[0].length == 1) {
        val = `0${dt[0]}`;
      } else {
        val = `${dt[0]}`;
      }
    }

    return val;
  }
  getModuleNameErrorMessage() {
    return this.moduleNameFormControl.hasError('required')
      ? 'Please enter module name'
      : '';
  }
  getMeetingLinkErrorMessage() {
    return this.meetingLinkFormControl.hasError('required')
      ? 'Please enter meeting link'
      : '';
  }
  getPublishDateErrorMessage() {
    return this.publishDateFormControl.hasError('required')
      ? 'Please select publish date'
      : '';
  }
  getStartTimeErrorMessage() {
    return this.startTimeFormControl.hasError('required')
      ? 'Please select start time'
      : '';
  }
  getEndTimeErrorMessage() {
    return this.endTimeFormControl.hasError('required')
      ? 'Please select end Time'
      : '';
  }
  getPositionErrorMessage() {
    return this.positionFormControl.hasError('required')
      ? 'Please enter module position'
      : '';
  }
  getDurationErrorMessage() {
    return this.durationFormControl.hasError('required')
      ? 'Please enter module duration'
      : '';
  }
  handlePickedFile(event: any) {
    this.selectedFile = event.target.files[0];
    this.selectedFileName = this.selectedFile['name'];
    this.formdata.set('offerImage', this.selectedFile, this.selectedFileName);
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCoverImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }
  addNewResources() {
    this.showAddNewResources = true;
  }

  async addNewModule() {
    this.isLoading = true;
    var param;
    var URL;
    this.formdata = new FormData();

    if (this.moduleDetailToEdit != null) {
      this.formdata.set('moduleId', this.moduleDetailToEdit['_id']);
    } else {
      this.formdata.set('moduleId', '0');
    }
    this.formdata.set('batch', this.batchDetail['_id']);
    this.formdata.set('title', this.addModuleForm.get('moduleName')?.value);
    this.formdata.set('position', this.addModuleForm.get('position')?.value);
    this.formdata.set('duration', this.addModuleForm.get('duration')?.value);
    if (this.addModuleForm.get('meetingLink')?.value != null && this.addModuleForm.get('meetingLink')?.value != undefined && this.addModuleForm.get('meetingLink')?.value != '') {
      this.formdata.set('meetingLink', this.addModuleForm.get('meetingLink')?.value);
    }
    this.formdata.set('publishDate', this.addModuleForm.get('publishDate')?.value);
    if (this.addModuleForm.get('startTime')?.value != null && this.addModuleForm.get('startTime')?.value != undefined && this.addModuleForm.get('startTime')?.value != '') {
      this.formdata.set('startTime', parseFloat(String(this.addModuleForm.get('startTime')?.value).replace(':', '.')).toString());
    }
    if (this.addModuleForm.get('endTime')?.value != null && this.addModuleForm.get('endTime')?.value != undefined && this.addModuleForm.get('startTime')?.value != '') {
      this.formdata.set('endTime', parseFloat(String(this.addModuleForm.get('endTime')?.value).replace(':', '.')).toString());
    }

    URL = `${webApi.domain + webApi.endPoint.createOrUpdateBatchModule}`;

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };

    fetch(URL, requestOptions)
      .then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isLoading = false;
              this.defaultCoverImage = null;
              this.selectedFile = null;
              this.myHeaders = new Headers();
              if (this.moduleDetailToEdit == null) {
                this.showSuccessToaster(
                  'Module Created Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Module Updated Successfully',
                  'Success'
                );
              }
              this.closeSideForm.emit(res['result']);
            } else {
              this.isLoading = false;
              this.myHeaders = new Headers();

              this.showErrorToaster(
                'Some Error occurred while saving data, try again',
                'Error Occurred'
              );
            }
          });
        }
      })
      .catch((error) => {
        this.isLoading = false;
        console.log('error', error);
        this.showErrorToaster(
          'Some Error occurred while saving data, try again',
          'Error Occurred'
        );
        this.myHeaders = new Headers();
      });
  }

  cancelEdit(desc: any) {
    this.listOfDescription.forEach((link: any) => {
      link['isEdit'] = false;
    });
    this.editLinks = false;
    desc['isEdit'] = false;
    this.addModuleForm.get('link')?.setValue('');
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  close(source: any) {
    this.addModuleForm.get('moduleName')?.setValue('');
    this.closeSideForm.emit(this.moduleDetailToEdit);
    this.moduleDetailToEdit = null;
  }
}
