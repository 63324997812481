import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-fliter-user',
  templateUrl: './fliter-user.component.html',
  styleUrls: ['./fliter-user.component.scss']
})
export class FliterUserComponent implements OnInit {


  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();
  @Input() filerToEdit: any;
  isLoading: boolean = true;


  listOfBranch: any = [];
  listOfAgent: any = [];
  listOfAgentToDisplay: any = [];

  minStartDate: any;
  minEndDate: any;
  maxStartDate: any;
  maxEndDate: any


  listOfDuartion: any = [
    {
      label: '3 Days',
      duration: 3,
    },
    {
      label: '7 Days',
      duration: 7,
    },
    {
      label: '15 Days',
      duration: 15,
    },
    {
      label: '30 Days',
      duration: 30,
    },
    {
      label: '90 Days',
      duration: 90,
    },

  ];
  listOfRange: any = [
    {
      label: '0%-10%',
      value: '0-10',
      lowerRange: 0,
      upperRange: 10,
    },
    {
      label: '10%-20%',
      value: '10-20',
      lowerRange: 10,
      upperRange: 20,
    },
    {
      label: '20%-40%',
      value: '20-40',
      lowerRange: 20,
      upperRange: 40,
    },
    {
      label: '40%-60%',
      value: '40-60',
      lowerRange: 40,
      upperRange: 60,
    },
    {
      label: '60%-80%',
      value: '60-80',
      lowerRange: 60,
      upperRange: 80,
    },
    {
      label: '80%-99%',
      value: '80-99',
      lowerRange: 80,
      upperRange: 99,
    },
  ];
  listOfCourse: any = [];

  listOfRouteType: any = [];

  listOfMainRoutes: any = [];
  listOfRoutes: any = [];
  showRoute: boolean = false;

  // Days
  selectedDays: any = [];

  // Medium
  selectedMedium: any = [];

  // Course
  selectedCourse: any = [];

  // Duration
  selectedDuration: any = [];

  // Range
  selectedRange: any = [];

  // Recommended Course
  selectedRecommendedCourse: any = [];

  isFreeExcluded: boolean = false;
  isPaidExcluded: boolean = false;


  mediumDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'label',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'label',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  rangeDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  duartionDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'duration',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  duartion1DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'duration',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    limitSelection: 1,
    allowSearchFilter: true,
  };
  courseDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'courseName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  course1DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'courseName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    limitSelection: 1,
    allowSearchFilter: true,
  };

  selectedUserTarget: any;

  targetUserControl = new FormControl('Select User Group', [
    Validators.required,
  ]);
  routeControl = new FormControl('Select Route', [
    Validators.required,
  ]);
  routeTypeControl = new FormControl('Select Screen', [
    Validators.required,
  ]);
  courseControl = new FormControl('', [
    Validators.required,
  ]);
  recommendedCourseControl = new FormControl('', [
    Validators.required,
  ]);
  rangeControl = new FormControl('', [
    Validators.required,
  ]);
  expiryDurationControl = new FormControl('', [
    Validators.required,
  ]);
  durationControl = new FormControl('', [
    // Validators.required,
  ]);
  // startDateControl = new FormControl('', []);
  // endDateControl = new FormControl('', []);

  filterUserForm: FormGroup = new FormGroup({
    targetUser: this.targetUserControl,
    // startDate: this.startDateControl,
    // endDate: this.endDateControl,
  });

  userDetail: any;
  constructor(public appService: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }

  ngOnInit(): void {
    this.filterUserForm = this.formBuilder.group({
      targetUser: this.targetUserControl,
      // startDate: this.startDateControl,
      // endDate: this.endDateControl,
    });
    this.fetchCourse();
  }


  async fetchCourse() {
    this.listOfCourse = [];
    this.isLoading = true;
    let param = {};
    try {
      const param = {
        courseId: '0',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllCourse}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
          this.assignValues();
        } else {
          this.listOfCourse = [];
          this.assignValues();
        }
      });
    } catch (error) {
      this.listOfBranch = [];
      this.assignValues();
    }
  }

  startDateChange(event: any) {
    console.log(event.target.value);
    this.minEndDate = event.target.value;
  }
  endDateChange(event: any) {
    console.log(event.target.value);
    this.maxStartDate = event.target.value;
  }

  assignValues() {
    if (this.filerToEdit != null) {

      if (Object.keys(this.filerToEdit['criteria']).length > 0) {

        // Exclusion
        if (this.filerToEdit['criteria']['isFreeExcluded'] != null && this.filerToEdit['criteria']['isFreeExcluded'] != undefined && this.filerToEdit['criteria']['isFreeExcluded']) {
          this.isFreeExcluded = true;
          this.isPaidExcluded = false;
        }
        if (this.filerToEdit['criteria']['isPaidExcluded'] != null && this.filerToEdit['criteria']['isPaidExcluded'] != undefined && this.filerToEdit['criteria']['isPaidExcluded']) {
          this.isFreeExcluded = false;
          this.isPaidExcluded = true;
        }


        if (this.filerToEdit['criteria']['allUser']) {
          this.filterUserForm.get('targetUser')?.setValue('All User');
          this.selectedUserTarget = 'All User';
        }
        else if (this.filerToEdit['criteria']['courseEnrolled']) {
          this.filterUserForm.get('targetUser')?.setValue('Course Enrolled');
          this.selectedUserTarget = 'Course Enrolled';
          this.filterUserForm.addControl('course', this.courseControl);
          this.filterUserForm.addControl('duration', this.durationControl);
          if (this.filerToEdit['criteria']['course'].length > 0) {
            this.listOfCourse.forEach((course: any) => {
              this.filerToEdit['criteria']['course'].forEach((element: any) => {
                if (course['_id'] == element) {
                  this.selectedCourse.push({
                    _id: course['_id'],
                    courseName: course['courseName']
                  });
                }
              });
            });
          }
           // 
           if (this.filerToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.filerToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }
        else if (this.filerToEdit['criteria']['coureConsumption']) {
          this.filterUserForm.addControl('course', this.courseControl);
          this.filterUserForm.addControl('range', this.rangeControl);
          this.filterUserForm.addControl('duration', this.durationControl);

          this.filterUserForm.get('targetUser')?.setValue('Course Consumption');
          this.selectedUserTarget = 'Course Consumption';

          if (this.filerToEdit['criteria']['course'].length > 0) {
            this.listOfCourse.forEach((course: any) => {
              this.filerToEdit['criteria']['course'].forEach((element: any) => {
                if (course['_id'] == element) {
                  this.selectedCourse.push({
                    _id: course['_id'],
                    courseName: course['courseName']
                  });
                }
              });
            });
          }
          this.listOfRange.forEach((element: any) => {
            if (this.filerToEdit['criteria']['lowerRange'] == element['lowerRange'] || this.filerToEdit['criteria']['upperRange'] == element['upperRange']) {
              this.selectedRange.push({
                value: element['value'],
                label: element['label'],
              });
            }
          });
           // 
           if (this.filerToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.filerToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }
        else if (this.filerToEdit['criteria']['coureRecommendation']) {
          this.filterUserForm.addControl('course', this.courseControl);
          this.filterUserForm.addControl('recommendedCourse', this.recommendedCourseControl);
          this.filterUserForm.addControl('duration', this.durationControl);

          this.filterUserForm.get('targetUser')?.setValue('Course Recommendation');
          this.selectedUserTarget = 'Course Recommendation';

          if (this.filerToEdit['criteria']['course'].length > 0) {
            this.listOfCourse.forEach((course: any) => {
              this.filerToEdit['criteria']['course'].forEach((element: any) => {
                if (course['_id'] == element) {
                  this.selectedCourse.push({
                    _id: course['_id'],
                    courseName: course['courseName']
                  });
                }
              });
            });
          }
          if (this.filerToEdit['criteria']['recommendedCourse'].length > 0) {
            this.listOfCourse.forEach((course: any) => {
              this.filerToEdit['criteria']['recommendedCourse'].forEach((element: any) => {
                if (course['_id'] == element) {
                  this.selectedRecommendedCourse.push({
                    _id: course['_id'],
                    courseName: course['courseName']
                  });
                }
              });
            });
          }
           // 
           if (this.filerToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.filerToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }
        else if (this.filerToEdit['criteria']['coureWishlist']) {
          this.filterUserForm.addControl('course', this.courseControl);
          this.filterUserForm.addControl('duration', this.durationControl);

          this.filterUserForm.get('targetUser')?.setValue('Wishlist');
          this.selectedUserTarget = 'Wishlist';

          if (this.filerToEdit['criteria']['course'].length > 0) {
            this.listOfCourse.forEach((course: any) => {
              this.filerToEdit['criteria']['course'].forEach((element: any) => {
                if (course['_id'] == element) {
                  this.selectedCourse.push({
                    _id: course['_id'],
                    courseName: course['courseName']
                  });
                }
              });
            });
          }
          // 
          if (this.filerToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.filerToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }
        else if (this.filerToEdit['criteria']['courseExpiry']) {
          this.filterUserForm.addControl('course', this.courseControl);
          this.filterUserForm.addControl('expiryDuration', this.expiryDurationControl);
          this.filterUserForm.get('targetUser')?.setValue('Expiry');
          this.selectedUserTarget = 'Expiry';

          if (this.filerToEdit['criteria']['course'].length > 0) {
            this.listOfCourse.forEach((course: any) => {
              this.filerToEdit['criteria']['course'].forEach((element: any) => {
                if (course['_id'] == element) {
                  this.selectedCourse.push({
                    _id: course['_id'],
                    courseName: course['courseName']
                  });
                }
              });
            });
          }
          if (this.filerToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.filerToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }
        else if (this.filerToEdit['criteria']['inActiveUser']) {
          this.filterUserForm.get('targetUser')?.setValue('Inactive User');
          this.selectedUserTarget = 'Inactive User';
          this.filterUserForm.addControl('duration', this.durationControl);

          if (this.filerToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.filerToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }
        else if (this.filerToEdit['criteria']['activeUser']) {
          this.filterUserForm.get('targetUser')?.setValue('Active User');
          this.selectedUserTarget = 'Active User';
          this.filterUserForm.addControl('duration', this.durationControl);

          if (this.filerToEdit['criteria']['duration'].length > 0) {
            this.listOfDuartion.forEach((duration: any) => {
              this.filerToEdit['criteria']['duration'].forEach((element: any) => {
                if (duration['duration'] == element) {
                  this.selectedDuration.push({
                    duration: duration['duration'],
                    label: duration['label']
                  });
                }
              });
            });
          }
        }

      }

      // if (this.filerToEdit['startDate'] != null && this.filerToEdit['startDate'] != undefined) {
      //   this.filterUserForm
      //     .get('startDate')
      //     ?.setValue(formatDate(new Date(this.filerToEdit['startDate']), 'yyyy-MM-ddTHH:mm', 'en-GB'));
      // }
      // if (this.filerToEdit['endDate'] != null && this.filerToEdit['endDate'] != undefined) {
      //   this.filterUserForm
      //     .get('endDate')
      //     ?.setValue(formatDate(new Date(this.filerToEdit['endDate']), 'yyyy-MM-ddTHH:mm', 'en-GB'));
      // }

    }
    this.isLoading = false;
  }
  freeExcludedChange(event: any) {
    if (event.currentTarget.checked) {
      this.isFreeExcluded = true;
      this.isPaidExcluded = false;
    } else {
      this.isFreeExcluded = false;

    }
  }
  paidExcludedChange(event: any) {
    if (event.currentTarget.checked) {
      this.isFreeExcluded = false;
      this.isPaidExcluded = true;
    } else {
      this.isPaidExcluded = false;
    }
  }
  userTargetSelected(event: any) {
    this.selectedUserTarget = event.target.value;
    if (this.selectedUserTarget == 'Course Enrolled' || this.selectedUserTarget == 'Course Consumption' || this.selectedUserTarget == 'Course Recommendation' || this.selectedUserTarget == 'Wishlist' || this.selectedUserTarget == 'Expiry') {
      this.filterUserForm.addControl('course', this.courseControl);
      this.filterUserForm.removeControl('range');
      // this.filterUserForm.removeControl('duration');
      this.filterUserForm.addControl('duration', this.durationControl);
      this.filterUserForm.removeControl('recommendedCourse');
      this.filterUserForm.removeControl('expiryDuration');
    }
    if (this.selectedUserTarget == 'Course Recommendation') {
      this.filterUserForm.addControl('recommendedCourse', this.recommendedCourseControl);
      this.filterUserForm.removeControl('range');
      this.filterUserForm.removeControl('expiryDuration');
      // this.filterUserForm.removeControl('duration');
      this.filterUserForm.addControl('duration', this.durationControl);
    }
    if (this.selectedUserTarget == 'Course Consumption') {
      this.filterUserForm.addControl('range', this.rangeControl);
      // this.filterUserForm.removeControl('duration');
      this.filterUserForm.addControl('duration', this.durationControl);
      this.filterUserForm.removeControl('recommendedCourse');
      this.filterUserForm.removeControl('expiryDuration');
    }
    if (this.selectedUserTarget == 'Expiry') {
      this.filterUserForm.addControl('expiryDuration', this.expiryDurationControl);
      this.filterUserForm.removeControl('duration');
      this.filterUserForm.removeControl('recommendedCourse');
      this.filterUserForm.removeControl('range');
    }
    if (this.selectedUserTarget == 'Inactive User') {
      this.filterUserForm.addControl('duration', this.durationControl);
      this.filterUserForm.removeControl('recommendedCourse');
      this.filterUserForm.removeControl('range');
      this.filterUserForm.removeControl('course');
      this.filterUserForm.removeControl('expiryDuration');
    }
    if (this.selectedUserTarget == 'Active User') {
      this.filterUserForm.addControl('duration', this.durationControl);
      this.filterUserForm.removeControl('recommendedCourse');
      this.filterUserForm.removeControl('range');
      this.filterUserForm.removeControl('course');
      this.filterUserForm.removeControl('expiryDuration');
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  onBranchSelect(branch: any) {
    console.log(branch);
  }
  onBranchDeSelect(branch: any) {
    console.log(branch);

  }
  onBranchSelectAll(branch: any) {
    console.log(branch);
  }
  getSelectTitle(title: any) {
    switch (title) {
      case 'Course Enrolled':
        return 'Select Enrolled Course';
      case 'Course Consumption':
        return 'Select Course';
      case 'Course Recommendation':
        return 'Select Enrolled Course';
      case 'Wishlist':
        return 'Select Course';
      case 'Expiry':
        return 'Select Course';
      default:
        return 'Select Course';

    }
  }
  applyFilter() {
    let toEmit: any = {};

    // if (this.filterUserForm.get('startDate')?.value != '') {
    //   let startDateVal = new Date(
    //     this.filterUserForm.get('startDate')?.value
    //   );
    //   let startDate = new Date(startDateVal);
    //   toEmit['startDate'] = startDate;
    // }
    if (this.filterUserForm.get('endDate')?.value != '') {
      let endDateVal = new Date(this.filterUserForm.get('endDate')?.value);
      let endDate = new Date(endDateVal);
      toEmit['endDate'] = endDate;
    }

    let criteria: any = {};
    if (this.filterUserForm.get('targetUser')?.value == 'All User') {
      criteria['allUser'] = true; criteria['coureEnrolled'] = false; criteria['coureConsumption'] = false; criteria['coureRecommendation'] = false; criteria['activeUser'] = true;
      criteria['coureWishlist'] = false; criteria['courseExpiry'] = false; criteria['inActiveUser'] = false;
    } else if (this.filterUserForm.get('targetUser')?.value == 'Course Enrolled') {
      criteria['allUser'] = false;
      criteria['courseEnrolled'] = true; criteria['coureConsumption'] = false; criteria['coureRecommendation'] = false; criteria['activeUser'] = true;
      criteria['coureWishlist'] = false; criteria['courseExpiry'] = false; criteria['inActiveUser'] = false;
      criteria['course'] = [];
      this.selectedCourse.forEach((element: any) => {
        criteria['course'].push(element['_id']);
      });
      // 
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });
    } else if (this.filterUserForm.get('targetUser')?.value == 'Course Consumption') {
      criteria['allUser'] = false; criteria['coureEnrolled'] = false; criteria['coureRecommendation'] = false; criteria['activeUser'] = true;
      criteria['coureWishlist'] = false; criteria['courseExpiry'] = false; criteria['inActiveUser'] = false;
      criteria['coureConsumption'] = true;
      criteria['course'] = [];
      this.selectedCourse.forEach((element: any) => {
        criteria['course'].push(element['_id']);
      });

      criteria['lowerRange'] = [];
      criteria['upperRange'] = [];
      this.listOfRange.forEach((element: any) => {
        this.selectedRange.forEach((amount: any) => {
          if (amount['label'] == element['label']) {
            amount['lowerRange'] = element['lowerRange'];
            amount['upperRange'] = element['upperRange'];
          }
        });
      });
      let amounts: any = [];
      this.selectedRange.forEach((amount: any) => {
        amounts.push(amount.lowerRange);
        amounts.push(amount.upperRange);
      });
      amounts.sort((a: any, b: any) => a - b);
      criteria['lowerRange'] = amounts[0];
      criteria['upperRange'] = amounts[amounts.length - 1];
      // 
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });

    } else if (this.filterUserForm.get('targetUser')?.value == 'Course Recommendation') {
      criteria['allUser'] = false; criteria['coureEnrolled'] = false; criteria['coureConsumption'] = false; criteria['activeUser'] = true;
      criteria['coureWishlist'] = false; criteria['courseExpiry'] = false; criteria['inActiveUser'] = false;
      criteria['coureRecommendation'] = true;
      criteria['course'] = [];
      this.selectedCourse.forEach((element: any) => {
        criteria['course'].push(element['_id']);
      });
      criteria['recommendedCourse'] = [];
      this.selectedRecommendedCourse.forEach((element: any) => {
        criteria['recommendedCourse'].push(element['_id']);
      });
      // 
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });
    } else if (this.filterUserForm.get('targetUser')?.value == 'Wishlist') {
      criteria['allUser'] = false; criteria['coureEnrolled'] = false; criteria['coureConsumption'] = false; criteria['activeUser'] = true;
      criteria['coureRecommendation'] = false; criteria['courseExpiry'] = false; criteria['inActiveUser'] = false;
      criteria['coureWishlist'] = true;
      criteria['course'] = [];
      this.selectedCourse.forEach((element: any) => {
        criteria['course'].push(element['_id']);
      });
      // 
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });
    } else if (this.filterUserForm.get('targetUser')?.value == 'Expiry') {
      criteria['allUser'] = false; criteria['coureEnrolled'] = false; criteria['coureConsumption'] = false; criteria['activeUser'] = true;
      criteria['coureRecommendation'] = false; criteria['coureWishlist'] = false; criteria['inActiveUser'] = false;
      criteria['courseExpiry'] = true;
      criteria['course'] = [];
      this.selectedCourse.forEach((element: any) => {
        criteria['course'].push(element['_id']);
      });
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });

    } else if (this.filterUserForm.get('targetUser')?.value == 'Inactive User') {
      criteria['allUser'] = false; criteria['coureEnrolled'] = false; criteria['coureConsumption'] = false;
      criteria['coureRecommendation'] = false; criteria['coureWishlist'] = false; criteria['courseExpiry'] = false; criteria['inActiveUser'] = true; criteria['activeUser'] = true;
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });
    }
    else if (this.filterUserForm.get('targetUser')?.value == 'Active User') {
      criteria['allUser'] = false; criteria['coureEnrolled'] = false; criteria['coureConsumption'] = false;
      criteria['coureRecommendation'] = false; criteria['coureWishlist'] = false; criteria['courseExpiry'] = false; criteria['inActiveUser'] = false; criteria['activeUser'] = true;
      criteria['duration'] = [];
      this.selectedDuration.forEach((element: any) => {
        criteria['duration'].push(element['duration']);
      });
    }
    if (this.isFreeExcluded) {
      criteria['isFreeExcluded'] = true;
    }
    if (this.isPaidExcluded) {
      criteria['isPaidExcluded'] = true;
    }


    toEmit['criteria'] = criteria;

    // console.log(toEmit);
    this.cancel(toEmit);
  }
}
