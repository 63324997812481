import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ViewUserReferralComponent } from '../view-user-referral/view-user-referral.component';
import { ExcelService } from '../../excel.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
})
export class MainDashboardComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;

  selectedDuration: string = 'Monthly';
  totalAppInstalledCount: number = 0;
  mmtcCourseCount: number = 0;
  coursePrice: number = 0;
  totalWalletAmountUser: number = 0;
  listOfUser: any = [];

  startDate: any;
  endDate: any;

  listOfDuration: any = [
    'Today',
    'Yesterday',
    'Weekly',
    'Monthly',
    'Current Quarter',
    'Yearly',
  ];

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public excelExport: ExcelService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
    }
  }

  ngOnInit(): void {
    this.fetchDashboardDetails(null, null);
  }

  changeDuration(event: any) {
    this.selectedDuration = event.value;
    this.fetchDashboardDetails(null, null);
  }

  async fetchDashboardDetails(startDate: any, endDate: any) {
    this.isLoading = true;
    try {
      let URL = '';
      this.totalAppInstalledCount = 0;
      this.mmtcCourseCount = 0;
      this.coursePrice = 0;
      this.totalWalletAmountUser = 0;
      this.listOfUser = [];
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchReferralDashboardDetails}`;
      const param = {
        duration: this.selectedDuration,
        startDateFilter: startDate,
        endDateFilter: endDate,
      };
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isLoading = false;
            this.totalAppInstalledCount = data['totalAppInstalledCount'];
            this.mmtcCourseCount = data['mmtcCourseCount'];
            this.coursePrice = data['coursePrice'];
            this.totalWalletAmountUser = data['totalWalletAmountUser'];
            this.startDate = data['startDate'];
            this.endDate = data['endDate'];
            this.listOfUser = data['topEarners'];
            ('');
          } else {
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.isLoading = false;
    }
  }

  setDateNull() {
    this.startDate = null;
    this.endDate = null;
  }
  dateChanged(type: any, event: any) {
    if (type == 'start' && event.value != null) {
      this.startDate = new Date(event.value);
    } else if (type == 'end' && event.value != null) {
      this.endDate = new Date(event.value);
    }

    if (this.startDate != null && this.endDate != null) {
      this.fetchDashboardDetails(this.startDate, this.endDate);
    }
  }

  calculateDiff(startDate: Date, endDate: Date) {
    startDate = new Date(startDate);

    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  viewReferral(user: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { user: user['referredTo'] };
    dialogConfig.maxWidth = '80vw';
    dialogConfig.maxHeight = '70vh';
    let dialogRef = this.matDialog.open(
      ViewUserReferralComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
      }
    });
  }

  downloadReferralReport() {
    var toExport: any = [];
    var index: any = 1;
    this.listOfUser.forEach((user: any) => {
      if (user['referredTo'].length > 0) {
        toExport.push({
          SrNo: index,
          Name: user['referredBy']['fullName'],
          Email: user['referredBy']['email'],
          PhoneNumber: user['referredBy']['phone'],
          ReferralCount: user['count'],
          TotalAmount: '\u20b9' + user['totalAmount'],
          AppInstallation: new Date(user['referredBy']['createdAt'])
            .toISOString()
            .slice(0, 10),
          ReferredTo_Name: user['referredTo'][0]['user']['fullName'],
          ReferredTo_Email: user['referredTo'][0]['user']['email'],
          ReferredTo_Phone: user['referredTo'][0]['user']['phone'],
        });
        if (user['referredTo'].length > 1) {
          for (let index = 1; index < user['referredTo'].length; index++) {
            var userData: any = {};
            userData = {
              Name: '',
              Email: '',
              PhoneNumber: '',
              ReferralCount: '',
              TotalAmount: '',
              AppInstallation: '',
              ReferredTo_Name: user['referredTo'][index]['user']['fullName'],
              ReferredTo_Email: user['referredTo'][index]['user']['email'],
              ReferredTo_Phone: user['referredTo'][index]['user']['phone'],
              ReferredTo_AppInstallation: new Date(
                user['referredTo'][index]['user']['createdAt']
              )
                .toISOString()
                .slice(0, 10),
            };
            toExport.push(userData);
          }
        }
      } else {
        toExport.push({
          SrNo: index,
          Name: user['referredBy']['fullName'],
          Email: user['referredBy']['email'],
          PhoneNumber: user['referredBy']['phone'],
          ReferralCount: user['count'],
          TotalAmount: '\u20b9' + user['totalAmount'],
          AppInstallation: new Date(user['referredBy']['createdAt'])
            .toISOString()
            .slice(0, 10),
          ReferredTo_Name: '-',
          ReferredTo_Email: '-',
          ReferredTo_Phone: '-',
        });
      }
      index = index + 1;
    });
    this.excelExport.exportAsExcelFile(toExport, 'Report Summary');
  }
}
