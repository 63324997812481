import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from './delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  isLoading: boolean = true;
  listOfCourseOriginal: any = [];
  listOfCourse: any = [];
  userDetails: any;

  constructor(
    private router: Router,
    public as: AppService,
    private toastr: ToastrService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.fetchCourse();
  }

  async fetchCourse() {
    this.isLoading = true;
    this.listOfCourseOriginal = [];
    this.listOfCourse = [];
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchCoursesForAdmin}`;
    await this.as
      .fetchDetail({ fetchAll: true }, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
          this.listOfCourseOriginal = this.listOfCourse;

          this.isLoading = false;
        }
      });
  }
  searchCourse(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let newCourseList: any = [];
    this.listOfCourse = [...this.listOfCourseOriginal];
    if (serachQuery != '') {
      this.listOfCourse.forEach((course: any) => {
        if (
          String(course['courseName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          newCourseList.push(course);
        }
      });
      this.listOfCourse = [...newCourseList];
    } else {
      this.listOfCourse = [...this.listOfCourseOriginal];
    }
  }

  deleteCourse(course: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Course', course: course };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfCourse.forEach((course: any) => {
          course['isDeleting'] = false;
        });
        return;
      } else {
        const param = {
          courseId: res['value']['_id'],
          action: 'Delete',
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteCourse}`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfCourse.forEach((course: any, index: number) => {
                if (course['_id'] == res['value']['_id']) {
                  this.listOfCourse.splice(index, 1);
                }
              });
              this.showSuccessToaster('Course Delete Successfully', 'Success');
            } else {
              this.listOfCourse.forEach((course: any, index: number) => {
                if (course['_id'] == res['value']['_id']) {
                  course['isDeleting'] = false;
                }
              });
              this.showErrorToaster(
                'Error Occurred while deleteing Course. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  addNewCourse() {
    this.router.navigate(['/catalog/new-course']);
  }
  viewReviews(courseId: String) {
    this.router.navigate(['/catalog/view-reviews', { courseId: courseId }]);
  }

  courseUserList(courseId: String) {
    this.router.navigate(['/catalog/course-user-list', { courseId: courseId }]);
  }
  gotoCourseEdit(detail: any) {
    this.as.courseDetailToEdit = detail;
    this.router.navigate(['/catalog/new-course']);
  }
  gotoForum(detail: any) {
    this.router.navigate(['/catalog/course-forum'], { queryParams: { id: detail._id } });
  }
  async updateCourseStatus(course: any) {


    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Course Status', course: course };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {

        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        const param = {
          courseId: course['_id'],
          isActive: course['isActive'] ? false : true,
          action: 'Active',
        };
        const URL = `${webApi.domain + webApi.endPoint.activeAndDeleteCourse}`;
        await this.as
          .updateDataViaPut(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfCourse.forEach((crs: any) => {
                if (crs['_id'] == course['_id']) {
                  crs['isActive'] = course['isActive'] ? false : true;
                }
              });
              this.showSuccessToaster(
                !course['isActive']
                  ? 'Course Unpublished Successfully'
                  : 'Course Published Successfully',
                'Success'
              );
            }
          });
      }
    });




  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
