<div class="main-container">
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 90vh;" *ngIf="!isLoading">


        <div ng-sidebar-content style="overflow: hidden;padding: 2em;">
            <div class="row top-row-sb">
                <div class="offer-heading-text">
                    Questions
                </div>
                <div class="search">
                    <div class="search-field">
                        <input type="text" class="search__input" style="width: 100%;" placeholder="Search"
                            (input)="searchForum($event)">
                        
                    </div>
                    <!-- <button class="search__filter">
                        <img class="search__filter-logo" src="../../../assets/svgIcons/filters.svg" alt="Filter">
                    </button> -->

                </div>
            </div>
            <mat-tab-group>
                <mat-tab label="All" class="mat-tab">
                    <div class="no-offer" *ngIf="listOfQuestions.length==0">
                        No Questions found
                    </div>
                    <div *ngIf="listOfQuestions.length>0">
                        <table class="table-style">
                            <thead class="table-heading">
                                <th class="table-heading-content">Name</th>
                                <th class="table-heading-content">Question</th>
                                <th class="table-heading-content">Date</th>
                                <th class="table-heading-content">Likes</th>
                                <th class="table-heading-content">Comments</th>
                                <th class="table-heading-content">Report Count</th>
                                <th class="table-heading-content">Action</th>
                            </thead>
                            <div style="height: 0.6em;"> </div>
                            <tbody>
                                <tr class="table-data"
                                    *ngFor="let question of listOfQuestions | paginate: { itemsPerPage: count, currentPage: p }">
                                    <th class="table-data-content">{{question['userName']}}</th>
                                    <th class="table-data-content" style="
                                    max-width: 40rem;
                                    word-break: break-all;">{{question['question']}}</th>

                                    <th class="table-data-content" (click)="questionDetails(question)">
                                        {{question['creationDate']|date}}</th>
                                    <th class="table-data-content" (click)="questionDetails(question)">
                                        {{question['likes']}}</th>
                                    <th class="table-data-content" (click)="questionDetails(question)">
                                        {{question['comments']}}</th>
                                    <th class="table-data-content" (click)="questionDetails(question)">
                                        {{question['reportCount']}}</th>

                                    <th class="table-data-content">

                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="suspendUserFromForum(question,true)">
                                                <mat-icon>report</mat-icon>
                                                <span>Suspend User</span>
                                            </button>
                                            <button mat-menu-item (click)="deleteQuestion(question,false)">
                                                <mat-icon>delete</mat-icon>
                                                <span>Delete</span>
                                            </button>
                                            <button mat-menu-item (click)="suspendQuestion(question)">
                                                <mat-icon *ngIf="!question['isSuspended']">visibility_off</mat-icon>
                                                <mat-icon *ngIf="question['isSuspended']">visibility</mat-icon>
                                                <span>{{question['isSuspended']?'Publish': 'Unpublish'}}</span>
                                            </button>

                                        </mat-menu>

                                    </th>
                                </tr>

                            </tbody>
                        </table>
                        <div class="text-right" style="font-size: medium;margin: 1em;bottom: 0.1em;right: 2em;">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>

                </mat-tab>
                <mat-tab label="Reported" class="mat-tab">
                    <div class="no-offer" *ngIf="listOfReportedQuestions.length==0">
                        No Questions found
                    </div>
                    <div *ngIf="listOfReportedQuestions.length>0">
                        <div *ngFor="let question of listOfReportedQuestions">
                            <table class="table-style">
                                <thead class="table-heading">
                                    <th class="table-heading-content">Name</th>
                                    <th class="table-heading-content">Question</th>
                                    <th class="table-heading-content">Date</th>
                                    <th class="table-heading-content">Likes</th>
                                    <th class="table-heading-content">Comments</th>
                                    <th class="table-heading-content">Report Count</th>
                                    <th class="table-heading-content">Action</th>
                                </thead>
                                <div style="height: 0.6em;"> </div>
                                <tbody>
                                    <tr class="table-data"
                                        *ngFor="let question of listOfReportedQuestions | paginate: { itemsPerPage: count, currentPage: p }">
                                        <th class="table-data-content" (click)="questionDetails(question)">
                                            {{question['userName']}}</th>
                                        <th class="table-data-content" style="
                                        max-width: 40rem;
                                        word-break: break-all;" (click)="questionDetails(question)">
                                            {{question['question']}}</th>

                                        <th class="table-data-content" (click)="questionDetails(question)">
                                            {{question['creationDate']|date}}</th>
                                        <th class="table-data-content" (click)="questionDetails(question)">
                                            {{question['likes']}}</th>
                                        <th class="table-data-content" (click)="questionDetails(question)">
                                            {{question['comments']}}</th>
                                        <th class="table-data-content" (click)="questionDetails(question)">
                                            {{question['reportCount']}}</th>

                                        <th class="table-data-content">

                                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="suspendUserFromForum(question,true)">
                                                    <mat-icon>report</mat-icon>
                                                    <span>Suspend User</span>
                                                </button>
                                                <button mat-menu-item (click)="deleteQuestion(question,true)">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Delete</span>
                                                </button>
                                                <button mat-menu-item (click)="suspendReportedQuestion(question)">
                                                    <mat-icon *ngIf="!question['isSuspended']">visibility_off</mat-icon>
                                                    <mat-icon *ngIf="question['isSuspended']">visibility</mat-icon>
                                                    <span>{{question['isSuspended']?'Publish': 'Unpublish'}}</span>
                                                </button>

                                            </mat-menu>

                                        </th>
                                    </tr>

                                </tbody>
                            </table>
                            <div class="text-right" style="font-size: medium;margin: 1em;bottom: 0.1em;right: 2em;">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>

                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>

    </div>
</div>