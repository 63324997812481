<div *ngIf="isLoading">
    <!-- <div class="center-loading"> -->
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
    <!-- </div> -->
</div>
<div *ngIf="!isLoading" style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Add Money</h2>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <div>
                <label for="amount" class="label required">Enter Amount</label>
                <input type="number" id="amount" class="input-style" placeholder="Enter amount" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}" (input)="checkForValidAmount($event.target)">
            </div>
            <div *ngIf="!isValidAmount" class="error-div">
                Please Enter valid amount (in range of 1 to {{addMoneyLimit}}).
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button [ngClass]="{'pay-btn-disable':amount==null || amount=='' || !isValidAmount,'pay-btn-enable':amount!=null && amount!='' && isValidAmount,'pay-btn-white':isAddingMoneyLoading}" [disabled]="amount==null || amount=='' || !isValidAmount" (click)="amount==null || amount==''|| !isValidAmount? null:payToRazorPay()">
                <div *ngIf="isAddingMoneyLoading">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!isAddingMoneyLoading">
                    Pay
                </div>
            </button>

        </div>
    </div>
</div>