<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit?'Edit':'Add'}} User
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="editUserForm" [formGroup]="editUserForm">
        <div class="row">
            <div class="col-md-12">
                <label for="fullName" class="label required">Full Name</label>
                <input type="text" placeholder="Full Name" id="fullName" formControlName="fullName" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="email" class="label required">Email</label>
                <input type="email" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="phone" class="label required">Phone Number</label>
                <input type="number" placeholder="Phone Number" id="phone" formControlName="phone" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="dob" class="label required">DOB</label>
                <input type="date" placeholder="Phone Number" id="dob" formControlName="dob" class="input-style">
            </div>
            <div class="col-md-12">
                <label for="gender" class="label">Gender</label>
                <select id="gender" name="gender" class="input-style" formControlName="gender">
                    <option value="Select Gender" selected disabled>Select Gender</option>
                    <option *ngFor="let gender of ['Male','Female','Other']" [value]="gender" class="levels">
                        {{gender}}</option>
                </select>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-6">
                <label for="plan" class="label">Plan Name</label>
                <select id="plan" name="plan" class="input-style" (change)="typeSelected($event.target)"
                    formControlName="plan">
                    <option value="Select Plan" selected disabled>Select Plan</option>
                    <option *ngFor="let plan of listOfPlansType" [value]="plan" class="levels">
                        {{plan}}</option>
                </select>
            </div>

            <div *ngIf="selectedPlan!='None'" class="col-md-6">
                <label for="phone" class="label">Plan SubType</label>
                <select id="plan" name="plan" class="input-style" formControlName="subType">
                    <option value="Select Plan SubType" selected disabled>Select Plan SubType</option>
                    <option *ngFor="let plan of listOfPlanSubType" [value]="plan['_id']" class="levels">
                        {{plan['subType']}}</option>
                </select>
            </div>
        </div> -->
        <!-- <div class="row">
            <div *ngIf="selectedPlan!='None'" class="col-md-6">
                <label for="date" class="label">Start Date</label>
                <input type="date" placeholder="Start Date" id="phone" formControlName="startDate" class="input-style">
            </div>
            <div *ngIf="selectedPlan!='None'" class="col-md-6">
                <label for="amount" class="label">Amount</label>
                <input type="number" placeholder="Amount" id="amount" formControlName="amount" class="input-style">
            </div>
        </div> -->

        <!-- <div class="row">
            <div class="col-md-6">
                <label for="type" class="label">Select Type</label>
                <select id="type" name="plan" class="input-style" formControlName="type">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="PAID">PAID</option>
                    <option value="FREE">FREE</option>
                </select>
            </div>
        </div> -->

        <div class="save">
            <input type="submit" [ngClass]="{'save-btn-disabled':editUserForm.invalid  ,'save-btn':editUserForm.valid }"
                value="{{'Save'}}" [disabled]="editUserForm.invalid" (click)="editUser()" />
        </div>
    </form>
</div>