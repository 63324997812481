<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">


        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-campaign *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-campaign>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!--Top Data  -->
            <div class="row align-center" style="justify-content: space-between;margin-bottom: 2rem;width: 100%;">
                <div class="col-md-4 top-heading">
                    Campaign
                </div>
                <div class="col-md-8"
                    style="text-align: end;    display: flex;  align-items: center; justify-content: end;">
                    <div>

                        <span *ngIf="clientDetail!=null && !isLoading" style="margin-right: 1em;font-size: 11px;">
                            BuildBy Free Conversation: {{roundTo(clientDetail['freeConversation'],2)}}
                        </span><br>
                        <span *ngIf="clientDetail!=null && !isLoading" style="margin-right: 1em;font-weight: 600;">
                            BuildBy Chat Balance: &#x20B9; {{roundTo(clientDetail['balance'],2)}}
                        </span>
                    </div>

                    <!-- <button *ngIf="clientDetail!=null" class="recharge-wallet" (click)="rechargeWallet()">
                        Recharge Wallet
                    </button> -->
                    <button class="add-new-emp" (click)="addNewCampaign()">
                        Create Campaign
                    </button>
                </div>

            </div>

            <!-- Table data -->

            <div *ngIf="isLoading">
                <div class="center-loading">
                    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
            </div>
            <div *ngIf="listOfCampaign.length==0 && !isLoading">
                <div class="no-available">
                    No campaign available!
                </div>
            </div>
            <div *ngIf="listOfCampaign.length!=0 && !isLoading">

                <table class="table-style">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="campaignName" scope="col"
                            class="table-heading-content">
                            Campaign Name</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="createdAt" scope="col"
                            class="table-heading-content">
                            Created Date</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="campaignType" scope="col"
                            class="table-heading-content">
                            Campaign Type</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="sentCount" scope="col"
                            class="table-heading-content">
                            Total User</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="receiveCount" scope="col"
                            class="table-heading-content">
                            Sent</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Undelivered</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            View</th>


                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let campaign of listOfCampaign | paginate: { itemsPerPage: count, currentPage: p }">

                            <th class="table-data-content">{{campaign.campaignName}}</th>
                            <th class="table-data-content">{{campaign.createdAt |date}}</th>
                            <th class="table-data-content">{{campaign.campaignType}}</th>
                            <th class="table-data-content">{{campaign.userData}}</th>
                            <th class="table-data-content">
                                {{campaign.campaignType=='WhatsApp'?campaign.campaignViewDetail['sentCount']:campaign.receiveCount}}
                            </th>
                            <th class="table-data-content">{{campaign.userData-
                                (campaign.campaignType=='WhatsApp'?campaign.campaignViewDetail['sentCount']:campaign.receiveCount)}}
                            </th>
                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">
                                    <img (click)="viewCampaign(campaign)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/view.png'>

                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>

                <div class="space-10"> </div>
            </div>
        </div>
    </ng-sidebar-container>

</div>