import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-filter-community',
  templateUrl: './filter-community.component.html',
  styleUrls: ['./filter-community.component.scss']
})
export class FilterCommunityComponent implements OnInit {

  userDetails: any;
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  filterForm: FormGroup;
  isLoading: boolean = true;
  filteredMandiRate: any;
  isApplyFilterLoading: boolean = false;
  listOfType: any = [
    { name: "Course" },
    { name: "Free" },
    { name: "Paid" },
    { name: "Buddy Group" },
  ];
  selectedTypeList: any = [];

  minEndDate: any;
  maxStartDate: any;
  startDate: any;
  endDate: any;

  statusSettings: any = {
    singleSelection: false,
    idField: 'name',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  constructor(
    public service: AppService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<FilterCommunityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filterForm = this.formBuilder.group({
      type: new FormControl(null, [Validators.required]),
    });
    this.userDetails = JSON.parse(localStorage.getItem('Carf-Admin')!);
  }

  ngOnInit(): void {
    if (this.data != null && this.data != undefined) {
      if (this.data.filterObject != null && this.data.filterObject != undefined) {

        if (this.data.filterObject['type'] != null && this.data.filterObject['type'] != undefined && this.data.filterObject['type'].length > 0) {
          this.listOfType.forEach((right: any) => {
            this.data.filterObject.type.forEach((st: any) => {
              if (st == right['name']) {
                this.selectedTypeList.push(right);
              }
            });
          });
        }

      }
    }
    this.isLoading = false; // TODO
  }


  startDateChange(event: any) {
    console.log(event.target.value);
    this.minEndDate = event.target.value;
  }
  endDateChange(event: any) {
    console.log(event.target.value);
    this.maxStartDate = event.target.value;
  }
 
  onSubmit() {
    this.isApplyFilterLoading = true;
    try {

      var type: any = [];
      this.selectedTypeList.forEach((st: any) => {
        type.push(st.name);
      });



      let param: any = {};

      if (type.length > 0) {
        param['type'] = type;
      }
      

      console.log(param);
      this.dialogRef.close();
      this.closeActivityAtDialog.emit({ param: param });
      this.isApplyFilterLoading = false;
    } catch (err) {
      this.isApplyFilterLoading = false;
      console.log(err);
    }
  }

  clearFilter() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({ clear: true });
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.success(message, title, {
      titleClass: 'titleClass',
      messageClass: 'messageClass',
    });
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.warning(message, title, {
      titleClass: 'titleClass',
      messageClass: 'messageClass',
    });
  }

}
