import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-active-delete-dialog',
  templateUrl: './active-delete-dialog.component.html',
  styleUrls: ['./active-delete-dialog.component.scss'],
})
export class ActiveDeleteDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ActiveDeleteDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void { }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  //Announcement
  deleteAnnouncement() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Announcement',
      value: this.data['announcement'],
    });
  }
  activeAnnouncement() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Announcement',
      value: this.data['announcement'],
    });
  }

  //Coupons
  deleteCoupon() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Coupon',
      value: this.data['coupon'],
    });
  }
  activeCoupon() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Coupon',
      value: this.data['coupon'],
    });
  }

  //Banners
  deleteBanner() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Banner',
      value: this.data['banner'],
    });
  }
  activeBanner() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Banner',
      value: this.data['banner'],
    });
  }

  //Help Videos
  deleteHelp() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Help',
      value: this.data['help'],
    });
  }
  activeHelp() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Help',
      value: this.data['help'],
    });
  }
  // Webinars
  deleteWebinar() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Webinar',
      value: this.data['webinar'],
    });
  }
  activeWebinar() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Webinar',
      value: this.data['webinar'],
    });
  }

  // Plans
  deletePlan() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Plan',
      value: this.data['plan'],
    });
  }
  activePlan() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Plan',
      value: this.data['plan'],
    });
  }

  // Advisory
  deleteTradeAdvisory() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete TradeAdvisory',
      value: this.data['advisory'],
    });
  }
  activeTradeAdvisory() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active TradeAdvisory',
      value: this.data['advisory'],
    });
  }

  // Ticker
  deleteTicker() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Ticker',
      value: this.data['ticker'],
    });
  }
  activeTicker() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Ticker',
      value: this.data['ticker'],
    });
  }

  // YoutubeVideo
  deleteYoutubeVideo() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete YoutubeVideo',
      value: this.data['video'],
    });
  }
  activeYoutubeVideo() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active YoutubeVideo',
      value: this.data['video'],
    });
  }

  // Employee
  deleteEmployee() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Employee',
      value: this.data['employee'],
    });
  }
  activeEmployee() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Employee',
      value: this.data['employee'],
    });
  }

  // Short Video
  deleteVideo() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Employee',
      value: this.data['video'],
    });
  }
  activeVideo() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Active Employee',
      value: this.data['video'],
    });
  }
}
