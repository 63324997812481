<h1 mat-dialog-title>How much amount you want to pay?</h1>
<div mat-dialog-content>
    <!-- <p></p> -->
    <mat-form-field>
        <!-- <mat-label></mat-label> -->
        <input matInput placeholder="Ex. 100" [(ngModel)]="amount" inputmode="numeric">
    </mat-form-field>
    <h5 *ngIf="errorMessage!=''" style="color: red;">{{errorMessage}}</h5>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Close</button>
    <button mat-button (click)="payCommission()" cdkFocusInitial>Pay</button>
</div>