import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-money-dialog',
  templateUrl: './add-money-dialog.component.html',
  styleUrls: ['./add-money-dialog.component.scss'],
})
export class AddMoneyDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  windowRef: any;
  userDetails: any;
  razorpay_payment_id: any = 'rzp_live_KEoQGsfK9zx5aQ';
  addMoneyLimit: any = 30000;
  isLoading: boolean = true;
  isAddingMoneyLoading: boolean = false;
  amount: any;
  isValidAmount: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddMoneyDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    public appService: AppService,
    public routes: ActivatedRoute,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.windowRef = this.as.windowRef;
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  ngOnInit(): void {
    // this.getRazorPayId();
    this.isLoading = false;
  }
  // async getRazorPayId() {
  //   this.isLoading = true;
  //   try {
  //     const param = { type: 'razorpayId' };
  //     const URL = `${webApi.domain + webApi.endPoint.fetchRazorpayId}`;
  //     const httpHeaders = new HttpHeaders({
  //       Authorization: `Bearer ${this.userDetails['accessToken']}`,
  //     });
  //     await this.appService
  //       .deleteData(param, URL, httpHeaders)
  //       .then((data: any) => {
  //         if (data['type']) {
  //           this.razorpay_payment_id = data['detail'][0]['value'];
  //           this.getAddMoneyLimit();
  //         } else {
  //           this.showErrorToaster(
  //             'Some Error occured. Please try after sometime or contact support team.',
  //             'Error'
  //           );
  //         }
  //       });
  //   } catch (error) {
  //     this.showErrorToaster(
  //       'Some Error occured. Please try after sometime or contact support team.',
  //       'Error'
  //     );
  //   }
  // }
  // async getAddMoneyLimit() {
  //   try {
  //     const param = { type: 'addMoneyLimit' };
  //     const URL = `${webApi.domain + webApi.endPoint.addMoneyLimit}`;
  //     const httpHeaders = new HttpHeaders({
  //       Authorization: `Bearer ${this.userDetails['accessToken']}`,
  //     });
  //     await this.appService
  //       .deleteData(param, URL, httpHeaders)
  //       .then((data: any) => {
  //         if (data['type']) {
  //           this.addMoneyLimit = data['detail'][0]['value'];
  //           this.isLoading = false;
  //         } else {
  //           this.isLoading = false;
  //         }
  //       });
  //   } catch (error) {
  //     this.isLoading = false;
  //   }
  // }
  checkForValidAmount(target: any) {
    let enteredAmount = target.value;
    if (parseInt(enteredAmount) > parseInt(this.addMoneyLimit)) {
      this.isValidAmount = false;
    } else {
      this.isValidAmount = true;
    }
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  async payToRazorPay() {
    if (!this.isAddingMoneyLoading) {
      this.isAddingMoneyLoading = true;

      const param = {
        clientPhone: '8976802882',
        amount: this.amount,
      };
      try {
        const URL = `${WA_API.domain + WA_API.endPoint.createOrder}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        await this.appService
          .deleteData(param, URL, httpHeaders)
          .then(async (data: any) => {
            if (data['success']) {
              let options = {
                key: this.razorpay_payment_id,
                amount: this.amount * 100,
                currency: 'INR',
                order_id:data['result']['rzpOrderId'],
                name: 'BuildBy.AI',
                description: 'BuildBy.AI recharge of ' + this.amount,
                prefill: {
                  name: this.userDetails['name'],
                  contact: this.userDetails['phone'],
                },
                handler: (res: any) => {
                  // console.log(res);
                  this.updateAmountInfo(res, this.amount);
                  rzp1.close();
                },
                modal: {
                  ondismiss: () => {
                    window.location.reload();
                    console.log('Checkout form closed');
                  },
                },
              };
              var rzp1 = new this.windowRef.Razorpay(options);
              rzp1.open();
              rzp1.on('payment.failed', this._handlePaymentError);
              // console.log('works');
            } else {
              this.isAddingMoneyLoading = false;
              this.showErrorToaster(
                'Some Error occured while adding money, If money deducted kindly contact support team for help.',
                'Error'
              );
            }
          });
      } catch (error) {
        this.isAddingMoneyLoading = false;
        this.showErrorToaster(
          'Some Error occured while adding money, If money deducted kindly contact support team for help.',
          'Error'
        );
      }
    }
  }
  _handlePaymentError(res: any) {
    this.isAddingMoneyLoading = false;
    console.log(res);
  }
  async updateAmountInfo(res: any, amount: any) {
    this.isAddingMoneyLoading = true;
    try {
      this.isAddingMoneyLoading = false;
      window.location.reload();
      this.showSuccessToaster(
        'Money added successfully',
        'Success'
      );
      this.closeActivityAtDialog.emit({ amount: amount });
    } catch (error) {
      this.showErrorToaster(
        'Some Error occured while adding money, If money deducted kindly contact support team for help.',
        'Error'
      );
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
