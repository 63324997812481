import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tgAdvisoryApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-tgadvisory-user',
  templateUrl: './add-edit-tgadvisory-user.component.html',
  styleUrls: ['./add-edit-tgadvisory-user.component.scss']
})
export class AddEditTgadvisoryUserComponent implements OnInit {

  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  httpHeaders: any;

  myHeaders = new Headers();
  formdata = new FormData();

  // selectedPlan = '';

  // listOfPlans: any = [];
  // listOfPlansType: any = [];
  // listOfPlanSubType: any = [];

  fullNameControl = new FormControl('', [
    Validators.required,
  ]);
  // planControl = new FormControl('Select Plan', []);
  // subTypeControl = new FormControl('Select Plan SubType', []);
  // typeControl = new FormControl('Select Type', []);
  // amountControl = new FormControl('', []);
  // startDateControl = new FormControl('', []);

  emailControl = new FormControl('', [
    Validators.required,
  ]);

  phoneControl = new FormControl('', [Validators.required]);
  dobControl = new FormControl('', []);
  genderControl = new FormControl('Select Gender', []);

  editUserForm: FormGroup = new FormGroup({
    fullName: this.fullNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
    dob: this.dobControl,
    gender: this.genderControl,
    // plan: this.planControl,
    // subType: this.subTypeControl,
    // startDate: this.startDateControl,
    // type: this.typeControl,
    // amount: this.amountControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.editUserForm = this.formBuilder.group({
      fullName: this.fullNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      dob: this.dobControl,
      gender: this.genderControl,
      // plan: this.planControl,
      // subType: this.subTypeControl,
      // startDate: this.startDateControl,
      // type: this.typeControl,
      // amount: this.amountControl,
    });
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.editUserForm.get('fullName')?.setValue(this.dataToEdit['fullName']);
      this.editUserForm.get('email')?.setValue(this.dataToEdit['email']);
      this.editUserForm.get('phone')?.setValue(this.dataToEdit['phone']);
      if (this.dataToEdit['dob']) {
        this.editUserForm
          .get('dob')
          ?.setValue(
            new Date(this.dataToEdit['dob'])
              .toISOString()
              .slice(0, 10)
          );
      }
      if (this.dataToEdit['gender']) {
        this.editUserForm.get('gender')?.setValue(this.dataToEdit['gender']);
      }
      // if (this.dataToEdit['activeSubscription'] != null) {
      //   this.editUserForm
      //     .get('plan')
      //     ?.setValue(this.dataToEdit['activeSubscription']['plan']['name']);
      //   this.selectedPlan =
      //     this.dataToEdit['activeSubscription']['plan']['name'];

      //   this.listOfPlanSubType = this.listOfPlans.filter(
      //     (data: any) =>
      //       data.name == this.dataToEdit['activeSubscription']['plan']['name']
      //   );

      //   this.listOfPlanSubType.forEach((plan: any) => {
      //     if (
      //       plan['_id'] == this.dataToEdit['activeSubscription']['plan']['_id']
      //     ) {
      //       this.editUserForm.get('subType')?.setValue(plan['_id']);
      //     }
      //   });

      //   this.editUserForm
      //     .get('type')
      //     ?.setValue(this.dataToEdit['activeSubscription']['type']);

      //   this.editUserForm
      //     .get('amount')
      //     ?.setValue(
      //       this.dataToEdit['activeSubscription']['actualtotalAmount'] ==
      //         null ||
      //         this.dataToEdit['activeSubscription']['actualtotalAmount'] ==
      //         undefined
      //         ? this.dataToEdit['activeSubscription']['totalAmount']
      //         : this.dataToEdit['activeSubscription']['actualtotalAmount']
      //     );

      //   this.editUserForm
      //     .get('startDate')
      //     ?.setValue(
      //       new Date(this.dataToEdit['activeSubscription']['startDate'])
      //         .toISOString()
      //         .slice(0, 10)
      //     );
      // }
    }
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  // async fetchPlan() {
  //   this.isLoading = true;
  //   try {
  //     let URL = '';

  //     URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchPlans}`;

  //     await this.as
  //       .fetchDetail({ admin: true, all: false }, URL, this.httpHeaders)
  //       .then((data: any) => {
  //         if (data['success']) {
  //           this.listOfPlans = data['result'];
  //           this.listOfPlans.forEach((data: any) => {
  //             var i1 = this.listOfPlansType.findIndex(
  //               (type: any) => type == data.name
  //             );
  //             if (i1 == -1) {
  //               this.listOfPlansType.push(data.name);
  //             }
  //           });
  //           this.listOfPlansType.push('None');
  //           this.isLoading = false;
  //         } else {
  //           this.listOfPlans = [];
  //           this.listOfPlanSubType = [];
  //           this.listOfPlansType = [];
  //           this.isLoading = false;
  //         }
  //       });
  //   } catch (error) {
  //     this.listOfPlans = [];
  //     this.listOfPlanSubType = [];
  //     this.listOfPlansType = [];
  //     this.isLoading = false;
  //   }
  // }

  // typeSelected(target: any) {
  //   let value = target.value;
  //   this.selectedPlan = value;
  //   this.listOfPlanSubType = [];
  //   if (value != 'None') {
  //     this.listOfPlanSubType = this.listOfPlans.filter(
  //       (data: any) => data.name == value
  //     );
  //     this.editUserForm.get('subType')?.setValue('Select Plan SubType');
  //   }
  // }

  editUser() {
    this.isLoading = true;

    var formdata = new FormData();

    if (this.dataToEdit != null) {
      formdata.set('id', this.dataToEdit['_id']);
    } else {
      formdata.set('id', '0');
    }

    formdata.set('fullName', this.editUserForm.get('fullName')?.value);
    formdata.set('email', this.editUserForm.get('email')?.value);
    formdata.set('phone', this.editUserForm.get('phone')?.value);
    formdata.set('dob', this.editUserForm.get('dob')?.value);
    if (this.editUserForm.get('dob')?.value) {
      formdata.set('dob', this.editUserForm.get('dob')?.value);
    }
    if (this.editUserForm.get('gender')?.value && this.editUserForm.get('gender')?.value != 'Select Gender') {
      formdata.set('gender', this.editUserForm.get('gender')?.value);
    }


    // let plan = this.editUserForm.get('plan')?.value;
    // let subType = this.editUserForm.get('subType')?.value;

    // if (plan == 'Select Plan' || subType == 'Select Plan SubType') {
    //   formdata.set('plan', '0');
    // } else if (plan == 'None') {
    //   formdata.set('plan', 'None');
    // } else {
    //   if (this.dataToEdit['activeSubscription'] == null) {
    //     formdata.set('plan', subType);
    //   } else {
    //     if (this.dataToEdit['activeSubscription']['plan']['_id'] == subType) {
    //       formdata.set('plan', '0');
    //     } else {
    //       formdata.set('plan', subType);
    //     }
    //   }
    // }

    var headers = new Headers();

    headers.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: formdata,
      redirect: 'follow',
    };
    const URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.addEditUser}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.editUserForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                if (res['message'] == 'PHONE_EMAIL_ANY_EXIST') {
                  this.showErrorToaster(
                    'Phone Number or Email is already in use.',
                    'Failed'
                  );
                }
                // else if (res['message'] == 'EMAIL_EXIST') {
                //   this.showErrorToaster(
                //     'Email Address is already in use.',
                //     'Failed'
                //   );
                // } 
                else {
                  this.showErrorToaster('Something went wrong', 'Failed');
                }
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
