<div class="main-container">
    <div>
        <div class="row top-row-sb">
            <div class="offer-heading-text">
                Reviews
            </div>
            <div class="search">
                <div class="search-field">
                    <input type="text" style="width: 100%;" class="search__input" placeholder="Search"
                        (input)="searchReviewList($event)">

                </div>
                <!-- <input type="text" class="search__input" placeholder="&#x26B2; Search" (input)="searchCourseUserList($event)"> -->
                <button class="search__filter">
                    <img class="search__filter-logo" src="../../../assets/svgIcons/filters.svg" alt="Filter">
                </button>

            </div>
        </div>
        <div *ngIf="listOfReviews.length==0 && !isLoading">
            <div class="no-user-found">
                No Reviews found
            </div>
        </div>
        <div *ngIf="listOfReviews.length>0 && !isLoading">
            <table class="table-style">
                <thead class="table-heading">
                    <th class="table-heading-content">Name</th>
                    <th class="table-heading-content">Reviews</th>
                    <th class="table-heading-content">Date</th>
                    <th class="table-heading-content">Featured</th>
                    <th class="table-heading-content">Action</th>
                </thead>
                <div style="height: 0.6em;"> </div>
                <tbody>
                    <tr class="table-data"
                        *ngFor="let review of listOfReviews | paginate: { itemsPerPage: count, currentPage: p }">
                        <th class="table-data-content">{{review['user']==null || review['user']==undefined?'NA':
                            (review['user']['fullName'])}}</th>

                        <th class="table-data-content">
                            <div style="    width: 15em;
                            word-break: break-all;
                            text-align: center;
                            margin: auto;">

                                {{review['content']}}
                            </div>
                        </th>
                        <th class="table-data-content">{{review['createdAt'] |date }}</th>
                        <th class="table-data-content">
                            <img *ngIf="review['isFeatured'] == true" (click)="featureReview(review['_id'],false)"
                                class="table-data-content__status-icon table-data-content__status-icon-on"
                                src="../../../assets/svgIcons/switch-on.svg" slot="start" />
                            <img *ngIf="review['isFeatured'] == false" (click)="featureReview(review['_id'],true)"
                                class="table-data-content__status-icon table-data-content__status-icon-off"
                                src="../../../assets/svgIcons/switch-off.svg" slot="start" />
                        </th>
                        <th class="table-data-content">
                            <img *ngIf="review['isDeleted'] == false" (click)="deleteReview(review['_id'])"
                                class="delete-icon" src="../../../../assets/svgIcons/delete1.svg" slot="start" />
                            <!-- <img *ngIf="user['isDeleted'] == false" (click)="deleteView(user['_id'])"
                                class="table-data-content__status-icon table-data-content__status-icon-off"
                                src="../../../assets/svgIcons/switch-off.svg" slot="start" /> -->
                        </th>
                    </tr>
                </tbody>
            </table>
            <div class="text-right" style="font-size: medium;margin: 1em;position: absolute;bottom: 0.1em;right: 2em;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>

    </div>
</div>