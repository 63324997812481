import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReferralRoutingModule } from './referral-routing.module';
import { MainReferralComponent } from './main-referral/main-referral.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarModule } from 'ng-sidebar';
import { ToastrModule } from 'ngx-toastr';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReferralPopupComponent } from './referral-popup/referral-popup.component';
import { PaymentComponent } from './payment/payment.component';

@NgModule({
  declarations: [MainReferralComponent, ReferralPopupComponent, PaymentComponent],
  imports: [
    CommonModule,
    ReferralRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    NgxPaginationModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    SidebarModule.forRoot(),
  ],
})
export class ReferralModule {}
