import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-main-acl',
  templateUrl: './main-acl.component.html',
  styleUrls: ['./main-acl.component.scss'],
})
export class MainAclComponent implements OnInit {
  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  isLoading: Boolean = false;
  userStatus: String = 'Search Admin';
  userInfo: any;
  searchString: String = '';
  accessToGive: any = [];
  accessToRemove: any = [];
  accessList: any = [
    { title: 'User', permission: false },
    { title: 'Courses', permission: false },
    { title: 'Quizzes', permission: false },
    { title: 'Special Offers', permission: false },
    { title: 'Forum', permission: false },
    { title: 'Referrals', permission: false },
    { title: 'Enroll & Add User', permission: false },
    { title: 'Notification', permission: false },
    { title: 'Help', permission: false },
    { title: 'ACL', permission: false },
  ];
  accessListOriginal: any = [
    { title: 'User', permission: false },
    { title: 'Courses', permission: false },
    { title: 'Quizzes', permission: false },
    { title: 'Special Offers', permission: false },
    { title: 'Forum', permission: false },
    { title: 'Referrals', permission: false },
    { title: 'Enroll & Add User', permission: false },
    { title: 'Notification', permission: false },
    { title: 'Help', permission: false },
    { title: 'ACL', permission: false },
  ];

  addAccess(accessToAdd: any) {
    var exist = false;
    this.accessToGive.forEach((access: any) => {
      if (access == accessToAdd['title']) {
        exist = true;
      }
    });
    if (!exist) {
      this.accessToGive.push(accessToAdd['title']);
    } else {
      this.accessToGive = this.accessToGive.filter(
        (access: any) => access != accessToAdd['title']
      );
    }
  }
  removeAccess(accessToDelete: any) {
    var exist = false;
    this.accessToRemove.forEach((access: any) => {
      if (access == accessToDelete['title']) {
        exist = true;
      }
    });
    if (!exist) {
      this.accessToRemove.push(accessToDelete['title']);
    } else {
      this.accessToRemove = this.accessToRemove.filter(
        (access: any) => access != accessToDelete['title']
      );
    }
  }

  async searchUser() {
    this.accessList.forEach((data: any) => {
      data['permission'] = false;
    });
    if (this.searchString.trim() != '') {
      this.isLoading = true;
      const param = {
        email: this.searchString.trim(),
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAdmin}`;
      await this.as.fetchAdminFromService(param, URL).then((data: any) => {
        if (data['success']) {
          if (data['data'] != null) {
            this.userInfo = data['data'];
            this.isLoading = false;
            // if (this.userInfo['accessControlList'].length != 0) {
            //   this.userInfo['accessControlList'].forEach((userAccess: any) => {
            //     this.accessList = this.accessList.filter(
            //       (access: any) => access != userAccess
            //     );
            //   });
            // } else {
            //   this.accessList = this.accessListOriginal;
            // }
            this.userInfo['accessControlList'].forEach((access: any) => {
              this.accessList.forEach((list: any) => {
                if (list['title'] == access) {
                  list['permission'] = true;
                }
              });
            });
          } else {
            this.isLoading = false;
            this.userStatus = 'Admin not found';
          }
        } else {
          this.isLoading = false;
        }
      });
    }
  }

  async updateAccess() {
    if (this.accessToGive.length != 0) {
      this.accessList.forEach((data: any) => {
        data['permission'] = false;
      });
      this.isLoading = true;
      const param = {
        accessList: this.accessToGive,
        email: this.userInfo['email'].toString(),
        update: true,
      };
      const URL = `${webApi.domain + webApi.endPoint.updateUserAccess}`;
      await this.as
        .updateUserAccessFromService(param, URL)
        .then((data: any) => {
          if (data['success']) {
            this.userInfo = data['data'];
            this.accessToGive = [];
            // if (this.userInfo['accessControlList'].length != 0) {
            //   this.userInfo['accessControlList'].forEach((userAccess: any) => {
            //     this.accessList = this.accessList.filter(
            //       (access: any) => access != userAccess
            //     );
            //   });
            // } else {
            //   this.accessList = this.accessListOriginal;
            // }

            this.isLoading = false;
            this.showSuccessToaster(
              'User Access Updated Successfully',
              'Success'
            );
          } else {
            this.showErrorToaster(
              'Some Error occurred while updating user access,try again',
              'Error Occurred'
            );
          }
        });
    }
    if (this.accessToRemove.length != 0) {
      this.accessList.forEach((data: any) => {
        data['permission'] = false;
      });
      const param = {
        accessList: this.accessToRemove,
        email: this.userInfo['email'].toString(),
        update: false,
      };
      const URL = `${webApi.domain + webApi.endPoint.updateUserAccess}`;
      await this.as
        .unEnrollUserFromWebFromService(param, URL)
        .then((data: any) => {
          if (data['success']) {
            this.userInfo = data['data'];
            // if (this.userInfo['enrolled'].length == 0) {
            //   this.enrolled = false;
            // }
            // this.accessToRemove.forEach((userAccess: any) => {
            //   this.accessListOriginal.forEach((access: any) => {
            //     if (access == userAccess) {
            //       this.accessList.push(access);
            //     }
            //   });
            // });
            // this.userInfo['accessControlList'].forEach((access: any) => {
            //   this.accessList.forEach((list: any) => {
            //     if (list['title'] == access) {
            //       list['permission'] = true;
            //     } else {
            //       list['permission'] = false;
            //     }
            //   });
            // });
            this.accessToRemove = [];
            this.showSuccessToaster(
              'User Access Removed Successfully',
              'Success'
            );
          } else {
            this.isLoading = false;
            this.showErrorToaster(
              'Some Error occurred while unerolment,try again',
              'Error Occurred'
            );
          }
        });
    }
    this.userInfo['accessControlList'].forEach((access: any) => {
      this.accessList.forEach((list: any) => {
        if (list['title'] == access) {
          list['permission'] = true;
        }
      });
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
