<div class="main-popup-div">
    <div class="popup-heding">
        <div style="font-size: medium;">
            Add New Activity
        </div>
        <div (click)="close(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div>
        <div class="popup-body">
            <div *ngIf="actionToPerform=='Video'">
                <form name="addVideoForm" [formGroup]="addVideoForm">
                    <label class="required">Activity Name</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Activity Name" type="text" formControlName="activityName">
                                <mat-error *ngIf="activityNameFormControl.invalid">
                                    {{getActivityNameErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <label class="required">Position</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Enter position" type="number" formControlName="position">
                                <mat-error *ngIf="positionFormControl.invalid">
                                    {{getPositionErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <label class="required">Web video Link</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Video Link" type="text" formControlName="webLink">
                                <mat-error *ngIf="webLinkFormControl.invalid">
                                    {{getWebLinkErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <label class="required">Mobile Video Link</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Video Link" type="text" formControlName="mobileLink">
                                <mat-error *ngIf="mobileLinkFormControl.invalid">
                                    {{getMobileLinkErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <label class="required">Converted Video Link</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Video Link" type="text" formControlName="convertedVideo">
                                <mat-error *ngIf="convertedVideoFormControl.invalid">
                                    {{getConvertedLinkErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 1rem;margin-top: .5rem;">
                        <div class="col-md-12" style="display: flex;align-items: center;">
                            <label for="isSponsored" class="label "
                                style="margin-bottom: 0px;margin-right: 1em;font-weight: 500;">Use
                                Youtube Video?</label>
                            <input type="checkbox" placeholder="Is Sponsored?" id="isSponsored" [checked]="isYoutube"
                                (change)="setYoutubeVideo($event)">
                        </div>
                    </div>

                    <label *ngIf="isYoutube" class="required">Youtube Link</label>
                    <div class="row" *ngIf="isYoutube">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Youtube Link" type="text" formControlName="youtubeLink">
                            </mat-form-field>
                        </div>
                    </div>


                    <label class="required">Video Duration</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Enter duration in minutes" type="text"
                                    formControlName="videoDuration">
                                <mat-error *ngIf="videoDurationFormControl.invalid">
                                    {{getVideoDurationErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <!-- Resource and Description -->


                    <div *ngIf="listOfResources.length>0">
                        <label class="non-required">Attachment:</label>
                        <div class="row" *ngFor="let res of listOfResources, index as i"
                            [ngClass]="{ 'link-style-disable': res['isEdit'], 'link-style-enable':!res['isEdit']}">
                            <!-- <div class="col-md-2" *ngIf="!res['isEdit']">
                                <img class="svg-style"
                                    [src]="res['resourceName'].toString().endsWith('.pdf')?'../../../../assets/img/image-pdf.png' : res['resourceLink']"
                                    alt="" srcset="">
                            </div> -->
                            <div class="col-md-10" *ngIf="!res['isEdit']"
                                style="font-size: 14px;font-weight: 500; word-break: break-all;">
                                {{res['resourceName']}}
                            </div>
                            <div class="col-md-12" *ngIf="res['isEdit']" style="font-size: 14px;font-weight: 500;">
                                <input (change)="handlePickedFile($event)" type="file"
                                    accept=".pdf,jpg,.png,.xls,.csv,.xlsx">
                            </div>


                            <div class="row" *ngIf="!res['isEdit']" style="margin-right: 0px;">
                                <div class="edit-delete-div" style="margin-right: .5rem;">
                                    <mat-icon class="edit-delete-icon" (click)="deleteResource(res)">delete</mat-icon>
                                </div>
                                <div class="edit-delete-div">
                                    <mat-icon class="edit-delete-icon" (click)="editResourceLink(res)">edit</mat-icon>
                                </div>
                            </div>
                            <div class="row" *ngIf="res['isEdit'] && !res['isDeleting']"
                                style="float: right;margin-top: 2rem;margin-right: .1rem;">
                                <div style="margin-right:1.5rem">
                                    <button class='save-btn-enabled'
                                        style="background-color: white;color: #1a86ae; box-shadow: 0px 0px 0px;margin-left: 1.1em;"
                                        type="submit" (click)="cancelResourceEdit(res)">Cancel</button>
                                </div>
                                <div>
                                    <button class='save-btn-enabled' type="submit" style="width: 4em;"
                                        (click)="saveResources(res,i)">Save</button>
                                </div>
                            </div>
                            <div *ngIf="res['isEdit'] && res['isDeleting']" class="delete-spinner"
                                style="margin-top: 1rem;margin-left: 1rem;">
                                <mat-progress-spinner mode="indeterminate" diameter="20"
                                    style="margin:0 auto;height:20px;width:20px;color: #1a86ae;">
                                </mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="listOfDescription.length>0">
                        <label class="non-required">Links:</label>
                        <div class="row" *ngFor="let desc of listOfDescription index as i"
                            [ngClass]="{ 'link-style-disable': desc['isEdit'], 'link-style-enable':!desc['isEdit']}">
                            <div class="col-md-12">

                                <div *ngIf="!desc['isEdit']"
                                    style="display: flex;width: 100%;justify-content: space-between;overflow-wrap: anywhere;align-items: center;">
                                    <div style="max-width: 90%;">
                                        <h4 [innerHTML]="desc['link']" style="margin: 0;padding: 0;font-size: 1rem;">
                                        </h4>
                                    </div>
                                    <div class="row" style="margin-right: 0px;">
                                        <div class="edit-delete-div" style="margin-right: .5rem;">
                                            <mat-icon class="edit-delete-icon" (click)="deleteDesc(desc)">delete
                                            </mat-icon>
                                        </div>
                                        <div class="edit-delete-div">
                                            <mat-icon class="edit-delete-icon" (click)="editDescriptionLink(desc)">edit
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="desc['isEdit']" style="margin-bottom: 2rem;">
                                    <label class="non-required">Activity Links</label>
                                    <div style="height: 5rem;">
                                        <quill-editor class="content-editor" placeholder="" name="aboutUsText"
                                            formControlName="link">
                                        </quill-editor>
                                    </div>
                                    <div class="row" style="    float: right;margin-top: 13rem;margin-right: .1rem;">
                                        <div style="margin-right:1.5rem">
                                            <button class='save-btn-enabled'
                                                style="background-color: white;color: #1a86ae; box-shadow: 0px 0px 0px;"
                                                type="submit" (click)="cancelEdit(desc)">Cancel</button>
                                        </div>
                                        <div>
                                            <button class='save-btn-enabled' type="submit"
                                                (click)="saveLink(desc,i)">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="showAddNewResources">
                        <label class="non-required">Activity Attachment</label>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="image">
                                    <div
                                        [ngClass]="{'filePicked':selectedFile==null,'filePicked-done':selectedFile!=null}">
                                        <img *ngIf="defaultCoverImage!=null"
                                            [src]="(selectedFile['type']).toString().includes('image')? defaultCoverImage: '../../../../assets/img/image-pdf.png'"
                                            class="courseImage">
                                        <label *ngIf="defaultCoverImage==null" class="imageLabel"> Upload
                                            <input (change)="handlePickedFile($event)" type="file"
                                                accept=".pdf,jpg,.png,.xls,.csv,.xlsx">
                                        </label>
                                        <label *ngIf="defaultCoverImage!=null" class="imageLabelEdit">
                                            <mat-icon class="edit-icon">edit</mat-icon>
                                            <input (change)="handlePickedFile($event)" type="file"
                                                accept=".pdf,jpg,.png,.xls,.csv,.xlsx">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label class="non-required">Activity Links</label>
                        <quill-editor class="content-editor" placeholder="" name="aboutUsText" formControlName="link">
                        </quill-editor>
                    </div>
                    <div *ngIf="!showAddNewResources" (click)="addNewResources()">
                        <div class="add-reaource">
                            + Add New Resource
                        </div>
                    </div>




                    <!-- Resource and Description -->

                    <div *ngIf="!editResources && !editLinks" class="row" style="margin-top:2em;">
                        <div class="col-md-12">
                            <button class="mat-raised-button cancel-btn" (click)="close(null)">Cancel</button>
                            <button
                                [ngClass]="{ 'save-btn-disabled': addVideoForm.invalid, 'save-btn-enabled': addVideoForm.valid }"
                                style="padding: 6px;" type="submit" (click)="addVideoActivity()"
                                [disabled]="addVideoForm.invalid">Save</button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="actionToPerform=='Quiz'">
                <form name="addQuizForm" [formGroup]="addQuizForm">
                    <label class="required">Activity Name</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Activity Name" type="text" formControlName="activityName">
                                <mat-error *ngIf="activityNameFormControl.invalid">
                                    {{getActivityNameErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <label class="required">Position</label>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field style="width:100%; line-height: 0;" appearance="outline">
                                <input matInput placeholder="Enter position" type="text" formControlName="position">
                                <mat-error *ngIf="positionFormControl.invalid">
                                    {{getPositionErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="addMoreQuizes">

                        <label class="required" style="margin-top: 1em;">Enter Quiz Detail</label>
                        <div class="question">
                            <div>
                                <!-- <textarea id="questionTitle" placeholder="Enter a question" formControlName="question" itemid="questionTitle" class="questionTitle"></textarea> -->
                                <quill-editor class="content-editor" placeholder="Enter a question" name="aboutUsText"
                                    formControlName="question">
                                </quill-editor>
                            </div>
                            <div class="answerText">
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="0"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==0"
                                        required>
                                    <input type="text" placeholder="Add an answer" formControlName="option1"
                                        class="answer">
                                </div>
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="1"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==1"
                                        required>
                                    <input type="text" placeholder="Add an answer" formControlName="option2"
                                        class="answer">
                                </div>
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="2"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==2"
                                        required>
                                    <input type="text" placeholder="Add an answer" formControlName="option3"
                                        class="answer">
                                </div>
                                <div class="answer-row">
                                    <input type="radio" class="radio" name="correctAnswerRadio" value="3"
                                        (change)="radioChangeHandler($event)" [checked]="radioSelectedValue==3"
                                        required>
                                    <input type="text" placeholder="Add an answer" formControlName="option4"
                                        class="answer">
                                </div>
                                <div style="display: flex;justify-content: flex-end;margin: 1em;">
                                    <button style="font-size: small;" class="cancel-btn"
                                        (click)="closeQuizAddEditSection()">
                                        {{'Close'}}
                                    </button>
                                    <button style="font-size: small;"
                                        [ngClass]="{'save-btn-disabled':addQuizForm.invalid,'save-btn-enabled':addQuizForm.valid}"
                                        [disabled]="addQuizForm.invalid" (click)="addQuizActivity()">
                                        {{isEditingExistingQuiz?'Save':'Save'}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top:1em;" *ngIf="listOfAddedQuizzes.length>0">
                        <div class="row quiz-div" *ngFor="let quiz of listOfAddedQuizzes;let i=index;">
                            <div class="col-md-8" style="font-size: 12px;">
                                <span style="font-weight: 600;font-size: medium;" [innerHTML]="quiz['question']"></span>
                            </div>
                            <div class="col-md-4" style="display: flex;justify-content: space-around;">
                                <div class="edit-delete-div" (click)="editQuiz(quiz)">
                                    <mat-icon class="edit-delete-icon">edit</mat-icon>
                                </div>
                                <div *ngIf="!quiz['isDeleting']" class="edit-delete-div" (click)="deleteQuiz(quiz)">
                                    <mat-icon class="edit-delete-icon">delete</mat-icon>
                                </div>
                                <div *ngIf="quiz['isDeleting']" class="delete-spinner">
                                    <mat-progress-spinner mode="indeterminate" diameter="20"
                                        style="margin:0 auto;height:20px;width:20px;color: white;">
                                    </mat-progress-spinner>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!addMoreQuizes" class="row" style="margin-top:2em;margin-left: 0em;"
                        (click)="addMoreQuiz()">
                        <span class="add-more">
                            <mat-icon class="edit-delete-icon">add</mat-icon> Add New Quiz
                        </span>
                    </div>
                    <!-- <div class="row" style="margin-top:2em;">
                        <div class="col-md-12">
                            <button class="mat-raised-button cancel-btn" (click)="close(null)">Cancel</button>
                            <button
                                [ngClass]="{ 'save-btn-disabled': addQuizForm.invalid, 'save-btn-enabled': addQuizForm.valid }"
                                type="submit" (click)="addQuizActivity()" [disabled]="addQuizForm.invalid">Save & Add
                                more</button>
                        </div>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
</div>