import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webinar-management',
  templateUrl: './webinar-management.component.html',
  styleUrls: ['./webinar-management.component.scss']
})
export class WebinarManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
