import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-add-edit-trade-advisory',
  templateUrl: './add-edit-trade-advisory.component.html',
  styleUrls: ['./add-edit-trade-advisory.component.scss'],
})
export class AddEditTradeAdvisoryComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  selectedBannerImage: any = null;
  selectedBannerImageName: any = null;
  defaultbannerImage: any;

  isLoading: boolean = false;
  listOfRoutes = [];
  selectedRoute: string = '';
  selectedType: any;
  sponsored: boolean = false;
  sendNotificationOnUpdate: boolean = true;

  myHeaders = new Headers();
  formdata = new FormData();

  accessDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'title',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  mediumDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'label',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };
  accessToList: any = [
    { title: 'Silver' },
    { title: 'Gold' },
    { title: 'Diamond' },
    { title: 'Free' },
  ];
  accessToListSubtype: any = [
    { title: 'Monthly' },
    { title: 'Quarterly' },
    { title: 'Half Yearly' },
    { title: 'Ten Days' },
    { title: 'Seven Days' },
    // { title: 'Unpaid Users' },
  ];

  listOfMedium: any = [
    {
      label: 'WhatsApp',
      content: '1',
    },

    {
      label: 'In App',
      content: '1',
    },
    {
      label: 'Push Notification',
      content: '1',
    },
  ];
  selectedAccessList: any = [];
  // selectedAccessListSubtype: any = [];

  // Medium
  selectedMedium: any = [];

  listOfUpdates: any = [];

  titleControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  descriptionControl = new FormControl('');
  typeControl = new FormControl('Select Type', [Validators.required]);
  publishDateControl = new FormControl('', [Validators.required]);
  mediumControl = new FormControl('', [Validators.required]);
  targetList: any = [];
  // expectedTargetList: any = [];
  // entryPointControl = new FormControl('', [Validators.required]);
  // noOfSharesControl = new FormControl('', [Validators.required]);
  // stopLossControl = new FormControl('', [Validators.required]);
  // lossAtStopLossControl = new FormControl('', [Validators.required]);
  // targetControl = new FormControl('');
  // expectedTargetControl = new FormControl('');
  // advisoryTypeControl = new FormControl('Select Type', [Validators.required]);

  addAdvisoryForm: FormGroup = new FormGroup({
    title: this.titleControl,
    description: this.descriptionControl,
    publishDate: this.publishDateControl,
    type: this.typeControl,
    medium: this.mediumControl,
    // advisoryType: this.advisoryTypeControl,
    // entryPoint: this.entryPointControl,
    // noOfShares: this.noOfSharesControl,
    // stopLoss: this.stopLossControl,
    // lossAtStopLoss: this.lossAtStopLossControl,
    // target: this.targetControl,
    // expectedTarget: this.expectedTargetControl,
  });

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.addAdvisoryForm = this.formBuilder.group({
      title: this.titleControl,
      description: this.descriptionControl,
      publishDate: this.publishDateControl,
      type: this.typeControl,
      medium: this.mediumControl,
      // advisoryType: this.advisoryTypeControl,
      // entryPoint: this.entryPointControl,
      // noOfShares: this.noOfSharesControl,
      // stopLoss: this.stopLossControl,
      // lossAtStopLoss: this.lossAtStopLossControl,
      // target: this.targetControl,
      // expectedTarget: this.expectedTargetControl,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addAdvisoryForm = this.formBuilder.group({
        title: this.titleControl,
        description: this.descriptionControl,
        publishDate: this.publishDateControl,
        type: this.typeControl,
        medium: this.mediumControl,
        // advisoryType: this.advisoryTypeControl,
        // entryPoint: this.entryPointControl,
        // noOfShares: this.noOfSharesControl,
        // stopLoss: this.stopLossControl,
        // lossAtStopLoss: this.lossAtStopLossControl,
        // target: this.targetControl,
        // expectedTarget: this.expectedTargetControl,
      });

      this.selectedType = this.dataToEdit['type'];

      if (this.dataToEdit['medium'].length > 0) {
        this.dataToEdit['medium'].forEach((medium: any) => {
          this.selectedMedium.push({ label: medium });
        });
        this.addAdvisoryForm.get('medium')?.setValue(this.selectedMedium);
      }
      if (this.dataToEdit['sendNotificationOnUpdate']) {
        this.sendNotificationOnUpdate =
          this.dataToEdit['sendNotificationOnUpdate'];
      } else {
        this.sendNotificationOnUpdate = false;
      }

      this.defaultbannerImage =
        this.dataToEdit['image'] == null || this.dataToEdit['image'] == ''
          ? null
          : this.dataToEdit['image'];

      if (this.dataToEdit['type'] == 'Trading') {
        this.addTradingFields();
        this.addAdvisoryForm
          .get('entryPoint')
          ?.setValue(this.dataToEdit['entryPoint']);
        this.addAdvisoryForm
          .get('stopLoss')
          ?.setValue(this.dataToEdit['stopLoss']);
        // this.addAdvisoryForm
        //   .get('lossAtStopLoss')
        //   ?.setValue(this.dataToEdit['lossAtStopLoss']);
        this.addAdvisoryForm
          .get('noOfShares')
          ?.setValue(this.dataToEdit['noOfShares']);

        this.addAdvisoryForm
          .get('tradeType')
          ?.setValue(this.dataToEdit['tradeType']);

        this.targetList = this.dataToEdit['targets'];
        // this.expectedTargetList = this.dataToEdit['expectedTargets'];

        this.dataToEdit['updates'].forEach((data: any) => {
          let controller = `update${data['position'] - 1}`;
          this.listOfUpdates.push({
            position: data['position'] - 1,
            createdAt: data['createdAt'],
            delete: false,
            controller,
          });
          this.addAdvisoryForm.addControl(
            controller,
            this.formBuilder.control('')
          );
          this.addAdvisoryForm.get(controller)?.setValue(data['update']);
        });
        if (this.dataToEdit['tradeResult'] != null) {
          this.addAdvisoryForm
            .get('tradeResult')
            ?.setValue(this.dataToEdit['tradeResult']);
        }
        if (this.dataToEdit['opening'] != 0) {
          this.addAdvisoryForm
            .get('opening')
            ?.setValue(this.dataToEdit['opening']);
        }
        if (this.dataToEdit['closing'] != 0) {
          this.addAdvisoryForm
            .get('closing')
            ?.setValue(this.dataToEdit['closing']);
        }
      }

      this.dataToEdit['plans'].forEach((data: any) => {
        this.selectedAccessList.push({ title: data });
      });

      this.addAdvisoryForm.get('title')?.setValue(this.dataToEdit['title']);
      this.addAdvisoryForm
        .get('publishDate')
        ?.setValue(
          new Date(this.dataToEdit['publishDate']).toISOString().slice(0, 10)
        );
      this.addAdvisoryForm
        .get('description')
        ?.setValue(this.dataToEdit['description']);
      this.addAdvisoryForm
        .get('advisoryType')
        ?.setValue(this.dataToEdit['advisoryType']);
      this.addAdvisoryForm.get('type')?.setValue(this.dataToEdit['type']);
    } else {
      this.addAdvisoryForm
        .get('publishDate')
        ?.setValue(new Date().toISOString().slice(0, 10));
    }
  }
  sendNotificationOnUpdateChange(event: any) {
    if (event.currentTarget.checked) {
      this.sendNotificationOnUpdate = true;
    } else {
      this.sendNotificationOnUpdate = false;
    }
  }
  addUpdateToList() {
    let controller = `update${this.listOfUpdates.length}`;
    this.listOfUpdates.push({
      position: this.listOfUpdates.length,
      createdAt: new Date(),
      update: '',
      controller: controller,
      delete: true,
    });
    this.addAdvisoryForm.addControl(controller, this.formBuilder.control(''));
  }

  removeFromToList(index: number) {
    let controller = `update${index}`;
    this.listOfUpdates.splice(index, 1);
    this.addAdvisoryForm.removeControl(controller);
  }

  displayAdhaar(event: any) {
    this.selectedBannerImage = event.target.files[0];
    this.selectedBannerImageName = this.selectedBannerImage['startDate'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultbannerImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedBannerImage);
  }

  typeSelected(event: any) {
    this.selectedType = event.target.value;
    if (event.target.value != 'Chat') {
      this.addTradingFields();
    } else {
      this.addAdvisoryForm.removeControl('entryPoint');
      this.addAdvisoryForm.removeControl('noOfShares');
      this.addAdvisoryForm.removeControl('stopLoss');
      this.addAdvisoryForm.removeControl('lossAtStopLoss');
      this.addAdvisoryForm.removeControl('target');
      this.addAdvisoryForm.removeControl('expectedTarget');
      this.addAdvisoryForm.removeControl('tradeResult');
      this.addAdvisoryForm.removeControl('tradeType');
      this.addAdvisoryForm.removeControl('opening');
      this.addAdvisoryForm.removeControl('closing');
    }
  }

  addTradingFields() {
    this.addAdvisoryForm.addControl(
      `entryPoint`,
      this.formBuilder.control('', [Validators.required])
    );
    this.addAdvisoryForm.addControl(
      `tradeResult`,
      this.formBuilder.control('Select Trade Result')
    );
    this.addAdvisoryForm.addControl(
      `tradeType`,
      this.formBuilder.control('Select Type', [Validators.required])
    );
    this.addAdvisoryForm.addControl(`opening`, this.formBuilder.control(''));
    this.addAdvisoryForm.addControl(`closing`, this.formBuilder.control(''));
    this.addAdvisoryForm.addControl(
      `noOfShares`,
      this.formBuilder.control('', [Validators.required])
    );
    this.addAdvisoryForm.addControl(
      `stopLoss`,
      this.formBuilder.control('', [Validators.required])
    );
    this.addAdvisoryForm.addControl(
      `advisoryType`,
      this.formBuilder.control('Select Advisory Type', [Validators.required])
    );
    this.addAdvisoryForm.addControl(`target`, this.formBuilder.control(''));
    // this.addAdvisoryForm.addControl(
    //   `lossAtStopLoss`,
    //   this.formBuilder.control('', [Validators.required])
    // );
    // this.addAdvisoryForm.addControl(
    //   `expectedTarget`,
    //   this.formBuilder.control('')
    // );
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  addEditAdvisory() {
    console.log(this.addAdvisoryForm);
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('advisoryId', this.dataToEdit['_id']);
    } else {
      this.formdata.append('advisoryId', '0');
    }

    this.formdata.append('title', this.addAdvisoryForm.get('title')?.value);

    if (this.selectedMedium.length > 0) {
      let medium: any = [];
      this.selectedMedium.forEach((element: any) => {
        medium.push(element['label']);
      });
      this.formdata.set('medium', JSON.stringify(medium));
    }
    this.formdata.append(
      'publishDate',
      this.addAdvisoryForm.get('publishDate')?.value
    );

    this.formdata.append(
      'description',
      this.addAdvisoryForm.get('description')?.value
    );

    this.formdata.append(
      'sendNotificationOnUpdate',
      JSON.stringify(this.sendNotificationOnUpdate)
    );

    if (this.selectedBannerImage != null) {
      this.formdata.append('image', this.selectedBannerImage);
    }

    this.formdata.append('type', this.addAdvisoryForm.get('type')?.value);

    if (this.selectedType == 'Trading') {
      this.formdata.append(
        'advisoryType',
        this.addAdvisoryForm.get('advisoryType')?.value
        // 'Trading'
      );
      this.formdata.append(
        'tradeType',
        this.addAdvisoryForm.get('tradeType')?.value
        // 'Trading'
      );
      if (this.dataToEdit != null) {
        this.formdata.append('image', JSON.stringify(this.dataToEdit['image']));
      } else {
        this.formdata.append('image', JSON.stringify(''));
      }

      this.formdata.append(
        'entryPoint',
        this.addAdvisoryForm.get('entryPoint')?.value
      );
      this.formdata.append(
        'noOfShares',
        this.addAdvisoryForm.get('noOfShares')?.value
      );
      this.formdata.append(
        'stopLoss',
        this.addAdvisoryForm.get('stopLoss')?.value
      );

      if (
        this.addAdvisoryForm.get('tradeResult')?.value != 'Select Trade Result'
      ) {
        this.formdata.append(
          'tradeResult',
          this.addAdvisoryForm.get('tradeResult')?.value
        );
      }

      this.formdata.append(
        'opening',
        this.addAdvisoryForm.get('opening')?.value
      );
      this.formdata.append(
        'closing',
        this.addAdvisoryForm.get('closing')?.value
      );

      this.formdata.append('targets', JSON.stringify(this.targetList));

      let updates: any = [];

      this.listOfUpdates.forEach((data: any, index: number) => {
        let controller = `update${data['position']}`;
        updates.push({
          position: index + 1,
          createdAt: data.createdAt,
          update: this.addAdvisoryForm.get(controller)?.value,
        });
      });

      this.formdata.append('updates', JSON.stringify(updates));
    }

    var accessList: any = [];
    this.selectedAccessList.forEach((data: any) => {
      accessList.push(data['title']);
    });

    this.formdata.append('plans', JSON.stringify(accessList));

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.createAndUpdateAdvisory}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addAdvisoryForm.reset();
                this.selectedRoute = '';
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  addTargetList() {
    if (this.addAdvisoryForm.get('target')?.value != '') {
      this.targetList.push(this.addAdvisoryForm.get('target')?.value);
      this.addAdvisoryForm.get('target')?.setValue('');
    }
  }

  removeTargetList(tag: String) {
    this.targetList = this.targetList.filter((item: String) => item != tag);
  }

  // addExpectedTargetList() {
  //   if (this.addAdvisoryForm.get('expectedTarget')?.value != '') {
  //     this.expectedTargetList.push(
  //       this.addAdvisoryForm.get('expectedTarget')?.value
  //     );
  //     this.addAdvisoryForm.get('expectedTarget')?.setValue('');
  //   }
  // }

  // removeExpectedTargetList(tag: String) {
  //   this.expectedTargetList = this.expectedTargetList.filter(
  //     (item: String) => item != tag
  //   );
  // }
}
