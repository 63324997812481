<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <!-- <app-add-edit-webinar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-webinar> -->
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;    width: 100%;">
                <div class="col-md-4 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;">
                        Webinar Management
                    </span>
                </div>
                <div class="col-md-8">
                    <div class="row align-center">

                        <div class="col-md-3" style="text-align: center;">
                            <button class="add-new-emp" (click)="gotoAddWebinar()">
                                Create Webinar
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfWebinarToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Webinar found
                </div>
            </div>
            <div *ngIf="listOfWebinarToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">


                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Thumbnail</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="title" scope="col"
                            class="table-heading-content">Title
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Author
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Type
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Webinar Date
                        </th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Lobby Time</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Start Time</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            End Time</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Total Duration</th>

                        <th style="cursor: pointer;" class="table-heading-content">
                            Active</th>

                        <th style="cursor: pointer;" class="table-heading-content">
                            Download</th>
                        <th style="cursor: pointer;" class="table-heading-content">
                            Upload Zoom File</th>

                        <th class="table-heading-content">Action</th>

                    </thead>
                    <!-- <div style="height: 0.6em;"> </div> -->
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let webinar of listOfWebinarToDisplay | paginate: { itemsPerPage: count, currentPage: p },let i=index">
                            <!-- <th class="table-data-content">{{i+1}}
                                </th> -->
                            <th class="table-data-content">
                                <div class="avatar rounded-circle">
                                    <img *ngIf="webinar.thumbnail==null || webinar.thumbnail==undefined || webinar.thumbnail=='' "
                                        style="height: 30px;object-fit: cover;
                                        width: 30px;
                                        border-radius: 50%;" src='../../../../assets/img/placeholder-course.jpg' alt="">
                                    <img *ngIf="webinar.thumbnail!=null && webinar.thumbnail!=undefined && webinar.thumbnail!='' "
                                        style="height: 30px;object-fit: cover;
                                        width: 30px;
                                        border-radius: 50%;" src={{webinar.thumbnail}} alt="">
                                </div>
                            </th>

                            <th class="table-data-content" style="width: 20em;">{{webinar.title}}</th>
                            <th class="table-data-content">{{webinar.author==null|| webinar.author==undefined? '-':
                                webinar.author['name']}}
                            </th>
                            <th class="table-data-content">{{webinar.linkType}}</th>
                            <th class="table-data-content">{{webinar.webinarDate |date}}</th>
                            <th class="table-data-content">{{this.as.getTimePeriod(webinar.lobbyTime)}}
                            </th>
                            <th class="table-data-content">{{this.as.getTimePeriod(webinar.startTime)}}</th>
                            <th class="table-data-content">{{this.as.getTimePeriod(webinar.endTime)}}</th>
                            <th class="table-data-content">{{webinar.totalDuration<60?webinar.totalDuration +'
                                    Minutes':this.as.toHoursAndMinutes(webinar.totalDuration)}}</th>
                            <th class="table-data-content">
                                <img *ngIf="webinar.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(webinar,false)" slot="start" />
                                <img *ngIf="!webinar.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(webinar,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 5em;">
                                <div style="display: contents;">

                                    <img (click)="downloadReport(webinar)" title="Download Interested User Report"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/download-circular-button.png'>

                                </div>
                            </th>
                            <th class="table-data-content" style="width: 5em;">
                                <div *ngIf="webinar['zoomFile']==null || webinar['zoomFile']==undefined"
                                    style="display: contents;color: blue;text-decoration: underline;cursor: pointer;"
                                    (click)="uploadZoomFile(webinar)">
                                    Upload File
                                </div>
                                <div *ngIf="webinar['zoomFile']!=null && webinar['zoomFile']!=undefined">
                                    Uploaded
                                </div>
                            </th>
                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">

                                    <img (click)="editWebinarDetail(webinar)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img (click)="viewWebinar(webinar)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../assets/img/view.png'>

                                    <img title="View" (click)="deleteWebinar(webinar)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div style="height: 10em;"> </div>
            </div>


        </div>
    </ng-sidebar-container>

</div>