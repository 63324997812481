import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';
import { UpdateStatusDialogComponent } from '../update-status-dialog/update-status-dialog.component';

export interface Payout {
  _id: string;
  createdAt: Date;
  user: any;
  transactionFee: number;
  amountToCredit: number;
  upi_id: string;
  email: string;
  phone: string;
  totalAmount: number;
  transaction: string;
  status: string;
}

@Component({
  selector: 'app-list-payout',
  templateUrl: './list-payout.component.html',
  styleUrls: ['./list-payout.component.scss'],
})
export class ListPayoutComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchPayout();
  }

  p: any = 1;
  count: any = 50;

  listOfPayoutToDisplay: Array<Payout> = [];
  listOfPayout: Array<Payout> = [];
  myHeaders = new Headers();

  async fetchPayout() {
    this.isLoading=true;
    this.listOfPayoutToDisplay = [];
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.fetchPayouts}`;

      await this.as
        .fetchDetailViaGet(URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfPayoutToDisplay = data['result'];
            this.listOfPayout = [...this.listOfPayoutToDisplay];
            this.isLoading = false;
          } else {
            this.listOfPayoutToDisplay = [];
            this.listOfPayout = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfPayoutToDisplay = [];
      this.listOfPayout = [];
      this.isLoading = false;
    }
  }

  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfPayoutToDisplay = [];
      this.listOfPayout.forEach((plan: any, index: number) => {
        if (
          String(plan.language)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(plan.categoryId.name)
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(plan);
        }
      });
      this.listOfPayoutToDisplay = tempSearchList;
    } else {
      this.listOfPayoutToDisplay = [];
      this.listOfPayoutToDisplay = this.listOfPayout;
    }
  }

  editPayout(payout: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { payout: payout };
    dialogConfig.width = '30vw';
    dialogConfig.maxHeight = '70vh';
    let dialogRef = this.matDialog.open(
      UpdateStatusDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        let URL = `${webApi.domain + webApi.endPoint.updatePayoutStatus}`;

        let param = {
          payoutId: payout._id,
          userId: payout.user._id,
          status: res['result'],
        };

        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = this.listOfPayoutToDisplay.findIndex(
                (data: any) => data['_id'] == payout._id
              );
              if (index != -1) {
                this.listOfPayoutToDisplay[index].status =
                  data['result']['status'];
              }
              payout.status = data['result']['status'];
              this.showSuccessToaster('Status updated Successfully', 'Success');
            } else {
              this.showErrorToaster(
                'Error occured while updating status',
                'Error'
              );
            }
          });
      }
    });
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  selectStatus(target: any) {
    this.listOfPayoutToDisplay = [];
    let value = target.value;
    if (value == 'All') {
      this.listOfPayoutToDisplay = this.listOfPayout;
    } else {
      this.listOfPayoutToDisplay = this.listOfPayout.filter(
        (data: any) => data.status == value
      );
    }
  }
}
