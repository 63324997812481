<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Video
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <div class="row">
        <div class="col-md-6">
            <label for="Image" class="label required">Video'</label>
            <div class="row">
                <div class="col-md-12">
                    <div class="image">
                        <div class="proof">
                            <img *ngIf="defaultbannerImage!=null" src="{{defaultbannerImage}}" class="proofImage">
                            <label *ngIf="defaultbannerImage==null" class="imageLabel"> Upload

                                <input (change)="displayAdhaar($event)" type="file" accept="video/*">
                            </label>
                            <label *ngIf="defaultbannerImage!=null" class="imageLabelEdit">
                                <mat-icon class="matIcon">edit</mat-icon>

                                <input (change)="displayAdhaar($event)" type="file" accept="video/*">
                            </label>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="save">
        <input type="submit"
            [ngClass]="{'save-btn-disabled':defaultbannerImage==null,'save-btn':defaultbannerImage!=null}"
            value="{{'Save'}}" [disabled]="defaultbannerImage==null" (click)="addEditBrand()" />
    </div>
</div>