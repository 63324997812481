import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-reported-msg',
  templateUrl: './reported-msg.component.html',
  styleUrls: ['./reported-msg.component.scss']
})
export class ReportedMsgComponent implements OnInit {

  userDetail: any;
  httpHeaders: any;
  isLoading: boolean = true;
  listOfReportedMessage: any = [];
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ReportedMsgComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log(data);
    dialogRef.disableClose = true;

    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });
  }
  ngOnInit(): void {
    this.fetchAllCommunity();
  }
  async fetchAllCommunity() {
    this.isLoading = true;
    try {
      this.listOfReportedMessage = [];

      const URL = `${webApi.domain + webApi.endPoint.fetchReportedMessage}`;
      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfReportedMessage = data['result'];
          this.listOfReportedMessage.forEach((element: any) => {
            element['showMore'] = false;
          });
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  toggleShowMore(msg: any, val: boolean) {
    this.listOfReportedMessage.forEach((element: any) => {
      element['showMore'] = false;
      if (element['_id'] == msg['_id']) {
        element['showMore'] = val;
      }
    });
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

}
