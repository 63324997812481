import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'tg-admin-web';
  constructor(private router: Router, public as: AppService) {
    this.as.setSideBarState(window.location.href.split('/'));
  }

  ngOnInit() {
    // console.log(this.router)
    // console.log(this.router.url);
    // console.log(window.location.href.split('#')[1]);
  }
}
