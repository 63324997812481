import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-add-new-quiz',
  templateUrl: './add-new-quiz.component.html',
  styleUrls: ['./add-new-quiz.component.scss'],
})
export class AddNewQuizComponent implements OnInit {
  courseDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'courseName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };

  // Course
  selectedCourse: any = [];
  assignedCourse: any = [];

  isChecked: boolean = false;
  isLoading: boolean = false;
  listOfQuiz: any = [];
  detailToEdit: any;
  listOfQuestion: any = [];
  addMoreQuizes: boolean = false;
  isEditingExistingQuiz: boolean = false;
  editingExistingQuizId: any;
  editingQuiz: any;
  selectedFile: any = null;
  selectedFileName: any = 'No File Selected';
  myHeaders = new Headers();
  // myHeaders = new HttpHeaders({'content-type': 'application/json'});
  formdata = new FormData();
  radioSelectedValue: any;
  isReadOnly: boolean = true;
  defaultCoverImage =
    'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
  userDetail: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }

  titleFormControl = new FormControl('', [Validators.required]);
  questionFormControl = new FormControl('', [Validators.required]);
  answer1FormControl = new FormControl('', [Validators.required]);
  answer2FormControl = new FormControl('', [Validators.required]);
  answer3FormControl = new FormControl('', [Validators.required]);
  answer4FormControl = new FormControl('', [Validators.required]);
  radioFormControl = new FormControl('', [Validators.required]);
  message1FormControl = new FormControl('', [Validators.required]);
  message2FormControl = new FormControl('', [Validators.required]);
  message3FormControl = new FormControl('', [Validators.required]);
  message4FormControl = new FormControl('', [Validators.required]);
  positionControl = new FormControl('', [Validators.required]);

  quizForm: FormGroup = new FormGroup({
    title: this.titleFormControl,
    question: this.questionFormControl,
    answer1: this.answer1FormControl,
    answer2: this.answer2FormControl,
    answer3: this.answer3FormControl,
    answer4: this.answer4FormControl,
    message1: this.message1FormControl,
    message2: this.message2FormControl,
    message3: this.message3FormControl,
    message4: this.message4FormControl,
    position: this.positionControl,
    // correctAnswerRadio: this.radioFormControl
  });
  listOfCourseOriginal: any = [];
  listOfCourse: any = [];
  selectedCourseName: String = '';
  ngOnInit(): void {
    this.quizForm = this.formBuilder.group({
      title: this.titleFormControl,
      question: this.questionFormControl,
      answer1: this.answer1FormControl,
      answer2: this.answer2FormControl,
      answer3: this.answer3FormControl,
      answer4: this.answer4FormControl,
      message1: this.message1FormControl,
      message2: this.message2FormControl,
      message3: this.message3FormControl,
      message4: this.message4FormControl,
      position: this.positionControl,
    });
    this.fetchCourse();
  }
  assignValue() {
    if (this.as.listOfQuizToEditFromQuizSection != null) {
      this.quizForm
        .get('title')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['name']);
      this.quizForm
        .get('position')
        ?.setValue(this.as.listOfQuizToEditFromQuizSection['position']);
      this.defaultCoverImage = this.as.listOfQuizToEditFromQuizSection['image'];

      this.listOfCourse.forEach((element: any) => {
        this.as.listOfQuizToEditFromQuizSection['recommendedCourses'].forEach(
          (course: any) => {
            if (course == element['_id']) {
              this.selectedCourse.push({
                _id: element['_id'],
                courseName: element['courseName'],
              });
            }
          }
        );
      });

      this.quizForm
        .get('message1')
        ?.setValue(
          this.as.listOfQuizToEditFromQuizSection['messages']['message1']
        );
      this.quizForm
        .get('message2')
        ?.setValue(
          this.as.listOfQuizToEditFromQuizSection['messages']['message2']
        );
      this.quizForm
        .get('message3')
        ?.setValue(
          this.as.listOfQuizToEditFromQuizSection['messages']['message3']
        );
      this.quizForm
        .get('message4')
        ?.setValue(
          this.as.listOfQuizToEditFromQuizSection['messages']['message4']
        );
      if (
        this.defaultCoverImage == undefined ||
        this.defaultCoverImage == '' ||
        this.defaultCoverImage == null
      ) {
        this.defaultCoverImage =
          'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
      }
      this.detailToEdit = this.as.listOfQuizToEditFromQuizSection;

      this.listOfQuiz = this.as.listOfQuizToEditFromQuizSection['quizContent'];
      this.listOfQuiz.forEach((quiz: any) => {
        quiz['isDeleting'] = false;
      });
      this.listOfQuiz.sort(
        (b: any, a: any) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );

      this.as.listOfQuizToEditFromQuizSection = null;
    } else {
      this.addMoreQuizes = true;
    }
    this.isLoading = false;
  }
  addMoreQuiz(id: any) {
    let el = document.getElementById(id);
    el?.scrollIntoView();
    this.addMoreQuizes = true;
  }

  selectionChange(deviceValue: any) {
    this.selectedCourseName = deviceValue.value;
  }

  backToQuiz() {
    this.router.navigate(['/catalog/quizzes']);
  }

  async fetchCourse() {
    this.isLoading = true;
    this.listOfCourseOriginal = [];
    this.listOfCourse = [];
    try {
      const param = {
        courseId: '0',
      };
      const URL = `${webApi.domain + webApi.endPoint.fetchAllCourse}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
          this.listOfCourseOriginal = this.listOfCourse;

          this.assignValue();
        } else {
          this.listOfCourse = [];
          this.assignValue();
        }
      });
    } catch (error) {
      this.listOfCourse = [];
      this.assignValue();
    }
  }

  displayPhoto(event: any) {
    console.log('test');
    this.selectedFile = event.target.files[0];
    this.selectedFileName = this.selectedFile['name'];

    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultCoverImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }

  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    // console.log(event);
  }
  onCourseSelect(course: any) {
    console.log(course);
  }
  onCourseDeSelect(course: any) {
    console.log(course);
  }
  onCourseSelectAll(course: any) {
    console.log(course);
  }
  closeQuizAddEditSection() {
    if (this.editingQuiz != null) {
      this.listOfQuiz.push(this.editingQuiz);
      this.editingQuiz = null;
    }
    this.listOfQuiz.sort(
      (b: any, a: any) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    this.addMoreQuizes = false;
    this.isEditingExistingQuiz = false;
    this.editingExistingQuizId = null;
    this.quizForm.get('question')?.setValue('');
    this.quizForm.get('answer1')?.setValue('');
    this.quizForm.get('answer2')?.setValue('');
    this.quizForm.get('answer3')?.setValue('');
    this.quizForm.get('answer4')?.setValue('');
    this.radioSelectedValue = null;
  }
  editQuiz(quiz: any) {
    if (this.editingQuiz != null) {
      this.listOfQuiz.push(this.editingQuiz);
      this.editingQuiz = null;
    }
    this.addMoreQuizes = true;
    this.editingQuiz = quiz;
    this.listOfQuiz.forEach((qz: any, index: number) => {
      if (qz['question'] == quiz['question']) {
        this.listOfQuiz.splice(index, 1);
      }
    });
    this.isEditingExistingQuiz = true;
    this.editingExistingQuizId = quiz._id;

    this.quizForm.get('question')?.setValue(quiz['question']);
    this.quizForm.get('answer1')?.setValue(quiz['options'][0]['value']);
    this.quizForm.get('answer2')?.setValue(quiz['options'][1]['value']);
    this.quizForm.get('answer3')?.setValue(quiz['options'][2]['value']);
    this.quizForm.get('answer4')?.setValue(quiz['options'][3]['value']);
    quiz['options'].forEach((option: any, index: number) => {
      if (option['isCorrect']) {
        this.radioSelectedValue = index;
        this.quizForm.get('correctAnswerRadio')?.setValue(index);
      }
    });
  }

  async insertEditQuiz() {
    this.isLoading = true;
    var URL;
    if (this.detailToEdit != null) {
      this.formdata = new FormData();
      var question = {
        question: this.quizForm.get('question')?.value,
        score: 1,
        questionType: 'Single',
        options: [
          {
            isCorrect: this.radioSelectedValue == '0' ? true : false,
            value: this.quizForm.get('answer1')?.value,
          },
          {
            isCorrect: this.radioSelectedValue == '1' ? true : false,
            value: this.quizForm.get('answer2')?.value,
          },
          {
            isCorrect: this.radioSelectedValue == '2' ? true : false,
            value: this.quizForm.get('answer3')?.value,
          },
          {
            isCorrect: this.radioSelectedValue == '3' ? true : false,
            value: this.quizForm.get('answer4')?.value,
          },
        ],
      };
      this.formdata.set('quizId', this.detailToEdit['_id']);
      if (this.selectedFile == null) {
        if (this.detailToEdit['image'] == undefined) {
          this.formdata.set('image', '');
        } else {
          this.formdata.set('image', this.detailToEdit['image']);
        }
      } else {
        this.formdata.set('image', this.selectedFile);
      }
      this.formdata.set('name', this.quizForm.get('title')?.value);
      this.formdata.set('position', this.quizForm.get('position')?.value);
      this.formdata.set(
        'isEditingExistingQuiz',
        this.isEditingExistingQuiz.toString()
      );
      if (
        this.editingExistingQuizId != null &&
        this.editingExistingQuizId != undefined
      ) {
        this.formdata.set(
          'editingExistingQuizId',
          this.editingExistingQuizId.toString()
        );
      }

      let courseId: any = [];
      this.selectedCourse.forEach((element: any) => {
        courseId.push(element._id);
      });
      this.formdata.set('recommendedCourses', JSON.stringify(courseId));
      let message = {
        message1: this.quizForm.get('message1')?.value,
        message2: this.quizForm.get('message2')?.value,
        message3: this.quizForm.get('message3')?.value,
        message4: this.quizForm.get('message4')?.value,
      };
      this.formdata.set('messages', JSON.stringify(message));
      this.formdata.set('quizContent', JSON.stringify(question));
      URL = `${webApi.domain + webApi.endPoint.createAndUpdateQuiz}`;
    } else {
      this.formdata = new FormData();
      var questions: any = [];
      if (this.quizForm.get('question')?.value != '') {
        questions = [
          {
            question: this.quizForm.get('question')?.value,
            score: 1,
            questionType: 'Single',
            options: [
              {
                isCorrect: this.radioSelectedValue == '0' ? true : false,
                value: this.quizForm.get('answer1')?.value,
              },
              {
                isCorrect: this.radioSelectedValue == '1' ? true : false,
                value: this.quizForm.get('answer2')?.value,
              },
              {
                isCorrect: this.radioSelectedValue == '2' ? true : false,
                value: this.quizForm.get('answer3')?.value,
              },
              {
                isCorrect: this.radioSelectedValue == '3' ? true : false,
                value: this.quizForm.get('answer4')?.value,
              },
            ],
          },
        ];
      }

      if (this.selectedFile != null) {
        this.formdata.set('image', this.selectedFile);
      }
      this.formdata.set('quizId', '0');
      this.formdata.set('isEditingExistingQuiz', 'false');
      this.formdata.set('name', this.quizForm.get('title')?.value);
      this.formdata.set('position', this.quizForm.get('position')?.value);
      this.formdata.set('quizContent', JSON.stringify(questions));
      let courseId: any = [];
      this.selectedCourse.forEach((element: any) => {
        courseId.push(element._id);
      });
      this.formdata.set('recommendedCourses', JSON.stringify(courseId));
      let message = {
        message1: this.quizForm.get('message1')?.value,
        message2: this.quizForm.get('message2')?.value,
        message3: this.quizForm.get('message3')?.value,
        message4: this.quizForm.get('message4')?.value,
      };
      this.formdata.set('messages', JSON.stringify(message));

      URL = `${webApi.domain + webApi.endPoint.createAndUpdateQuiz}`;
    }
    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    fetch(URL, requestOptions)
      .then((result) => {
        this.formdata.forEach((key: any) => {
          this.formdata.delete(key);
        });
        if (result) {
          let data = result.json();

          data.then((res) => {
            if (res['success']) {
              this.quizForm.reset();
              this.quizForm.get('title')?.setValue(res['result']['name']);
              this.quizForm.get('position')?.setValue(res['result']['position']);

              this.quizForm
                .get('message1')
                ?.setValue(res['result']['messages']['message1']);
              this.quizForm
                .get('message2')
                ?.setValue(res['result']['messages']['message2']);
              this.quizForm
                .get('message3')
                ?.setValue(res['result']['messages']['message3']);
              this.quizForm
                .get('message4')
                ?.setValue(res['result']['messages']['message4']);
              this.listOfQuiz = [];
              this.selectedFile = null;

              this.listOfQuiz = res['result']['quizContent'];
              this.listOfQuiz.sort(
                (b: any, a: any) =>
                  new Date(b.createdDate).getTime() -
                  new Date(a.createdDate).getTime()
              );

              this.listOfQuiz.forEach((quiz: any) => {
                quiz['isDeleting'] = false;
              });
              this.addMoreQuizes = false;
              this.selectedFileName = 'No File Selected';
              this.defaultCoverImage = res['result']['image'];
              this.detailToEdit = res['result'];
              if (this.isEditingExistingQuiz) {
                this.showSuccessToaster('Quiz Updated Successfully', 'Success');
              } else {
                this.showSuccessToaster('Quiz Added Successfully', 'Success');
              }
              this.isEditingExistingQuiz = false;
              this.editingExistingQuizId = null;
            }
            this.isLoading = false;
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        this.isLoading = false;
      });
    return;
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  deleteQuiz(quiz: any) {
    this.openAlertWhenContactNotConfirmed(quiz);
  }
  openAlertWhenContactNotConfirmed(quiz: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Quiz', quiz: quiz };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        this.listOfQuiz.forEach((quiz: any) => {
          quiz['isDeleting'] = false;
        });
        return;
      } else {
        const param = {
          quizId: this.detailToEdit['_id'],
          content: res['value'],
        };
        const URL = `${webApi.domain + webApi.endPoint.deleteSingleQuiz}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetail['accessToken']}`,
        });
        await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
          if (data['success']) {
            this.listOfQuiz.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                this.listOfQuiz.splice(index, 1);
              }
            });
            this.showSuccessToaster('Quiz Deleted Successfully', 'Success');
          } else {
            this.listOfQuiz.forEach((quiz: any, index: number) => {
              if (quiz['_id'] == res['value']['_id']) {
                quiz['isDeleting'] = false;
              }
            });
            this.showErrorToaster(
              'Error Occurred while deleteing quiz. Try again',
              'Error'
            );
          }
        });
      }
    });
  }
}
