<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-plan *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-edit-plan>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">

            <!-- Top data -->
            <div class="row align-center" style="justify-content: space-between;    width: 100%;">
                <div class="col-md-3 top-heading" style="cursor: pointer;">
                    <span style="font-size: 1.5rem;">
                        Plan Management
                    </span>
                </div>
                
                <div class="col-md-9">
                <div class="row align-center">
                            <label for="advisoryType" class="label required" style="margin-right: .5rem;">Plan:</label>
                            <select id="advisoryType" name="advisoryType" class="input-style" style="width: 20%;align-items: center;"  (change)="selectStatus($event.target)">
                                <option value="All">All</option>
                                <!-- <option value="Free">Free</option> -->
                                <option value="Silver">Silver</option>
                                <option value="Gold">Gold</option>
                                <option value="Diamond">Diamond</option>
                            </select>
                            <div class="col-md-3" style="text-align: center;">
                                <button class="add-new-emp" (click)="addNewPlan()">
                                    Create Plan
                                </button>
                            </div>
                        </div>
                </div>

            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfPlanToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Plan found
                </div>
            </div>
            <div *ngIf="listOfPlanToDisplay.length>0 && !isLoading">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Razorpay PlanId</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Name
                        </th>

                        <th style="cursor: pointer;" data-order="desc" data-name="startDate" scope="col"
                            class="table-heading-content">Sub Type
                        </th>
                        <!-- <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Description
                        </th> -->


                        <th style="cursor: pointer;" data-order="desc" data-name="endDate" scope="col"
                            class="table-heading-content">Listing Price
                        </th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Selling Price</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Popular</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Created Date</th>

                        <th style="cursor: pointer;" data-order="desc" data-name="couponQty" scope="col"
                            class="table-heading-content">
                            Active</th>

                        <th class="table-heading-content">Action</th>

                    </thead>
                    <!-- <div style="height: 0.6em;"> </div> -->
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let plan of listOfPlanToDisplay | paginate: { itemsPerPage: count, currentPage: p },let i=index">

                            <th class="table-data-content">{{plan.rzpPlanId==''?'N.A.':plan.rzpPlanId}}
                            </th>
                            <th class="table-data-content">{{plan.name}}</th>
                            <th class="table-data-content">{{plan.subType}}</th>
                            <!-- <th class="table-data-content">{{plan.description}}
                            </th> -->
                            <th class="table-data-content">{{'\u20b9'+plan.listingPrice}}
                            </th>
                            <th class="table-data-content">{{'\u20b9'+plan.sellingPrice}}
                            </th>
                            <th class="table-data-content">{{plan.isPopular?'Yes':'No'}}</th>
                            <th class="table-data-content">{{plan.createdAt |date}}</th>

                            <th class="table-data-content">
                                <img *ngIf="plan.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(plan,false)" slot="start" />
                                <img *ngIf="!plan.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg"
                                    style="width: 2rem;height: 1.7rem; cursor: pointer;"
                                    (click)="updateActiveStatus(plan,true)" slot="start" />
                            </th>

                            <th class="table-data-content" style="width: 10em;">
                                <div style="display: contents;">

                                    <img (click)="editPlan(plan)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img title="View" (click)="deletePlan(plan)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
                <div style="height: 10em;"> </div>
            </div>


        </div>
    </ng-sidebar-container>

</div>