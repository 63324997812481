import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { UserComponent } from './user.component';

const routes: Routes = [
  {
    path: '',
    component: ListUserComponent,
  },
  // {
  //   path: '',
  //   component: UserComponent,
  // },
  { path: '', pathMatch: 'full', redirectTo: 'catalog/user' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
