import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { tgAdvisoryApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';


export interface MessageInfo {
  _id: string;
  sentBy: string;
  user: any;
  admin: any;
  community: any;
  content: any;
  reportCount: any;
  contextMessage: any;
  viewCount:any;
  
  createdAt: Date;
  updatedAt: Date;

  isViewed: boolean;
  isActive: boolean;
  isDeleted: boolean;
}
// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class TGAdvisorySocketioService {
  socket: any;
  selectedCommunity: any;

  listOfCommunity: any = [];
  listOfCommunityToDisplay: any = [];
  limit = 100;
  skip = 0;
  Page = 1;
  count = 20;
  searchString: string = '';

  isLoading: boolean = true;

  listOfMessage: Array<MessageInfo> = [];
  listOfMessageToDisplay: Array<MessageInfo> = [];
  userDetails: any;
  constructor(private http: HttpClient, public as: AppService) {
    this.socket = io(environment.ADVISORY_SOCKET_ENDPOINT, {
      forceNew: true,
      transports: ["websocket"],
    });
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  ngOnInit(): void {
  }
  async fetchAllMessageByCommunityId() {
    this.isLoading = true;
    this.listOfMessage = [];
    try {
      let URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.fetchAllCommunityMessageByCommunityId + `/${this.selectedCommunity['_id']}`
        }`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.fetchDetailViaGet(URL, httpHeaders).then(async (data: any) => {
        if (data['success']) {
          this.listOfMessage = data['result'];
          this.listOfMessageToDisplay = this.listOfMessage;
          // await this.updateReadCount(this.selectedCommunity['_id']);
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.listOfMessage = [];
      this.isLoading = false;
    }
  }
  async updateReadCount(chatRoomId: string) {
    this.isLoading = true;
    try {
      let URL = `${tgAdvisoryApi.domain + tgAdvisoryApi.endPoint.updateReadCount}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.putMethodWithToken({ chatRoomId: chatRoomId, sentBy: 'Employee' }, URL, httpHeaders).then((data: any) => {
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  sortDateFunction(a: any, b: any) {
    var dateA = new Date(a.updatedAt).getTime();
    var dateB = new Date(b.updatedAt).getTime();
    return dateA < dateB ? 1 : -1;
  };
  setupSocketConnection() {

    this.socket.on('sendMessage', (message: any) => {
      if (this.selectedCommunity != null && this.selectedCommunity != undefined) {
        this.listOfCommunity.forEach(async (cmpt: any) => {
          if (cmpt['_id'] == message['community']) {
            cmpt['latestMessage'] = message;
            // await this.updateReadCount(this.selectedCommunity['_id']);
          }
        });
        if (this.selectedCommunity._id == message['community']) {
          let isExist = false;
          let index = -1;
          this.listOfMessage.forEach((messageEntry: MessageInfo, i: number) => {
            if (messageEntry['_id'] == message['_id']) {
              isExist = true;
              index = i;
            }
          });
          if (isExist) {
            this.listOfMessage[index] = message;
          } else {
            this.listOfMessage.push(message);
          }

          this.listOfCommunity.forEach((element: any) => {
            if (element._id == message['community']) {
              element.updatedAt = message['updatedAt'];
            }
          });
        }
        // this.listOfCommunity.sort(this.sortDateFunction);
        this.sortCommunity();
        this.listOfCommunityToDisplay = this.listOfCommunity;
      }
    });

  }

  sortCommunity() {
    let pinnedCom: any = [];
    this.listOfCommunity.forEach((element: any, index: number) => {
      if (element.isPinned) {
        pinnedCom.push(element);
        this.listOfCommunity.splice(index, 1);
      }
    });
    this.listOfCommunity.sort(this.sortDateFunction);

    this.listOfCommunity=[...pinnedCom,...this.listOfCommunity];
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
