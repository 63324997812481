<div class="main-container">
    <div class="row align-center" style="justify-content: space-between;margin-bottom: 2rem;">

        <div class="col-md-4 top-heading" style="cursor: pointer;">
            <span style="font-size: 1.5rem;cursor: pointer;">
                Courses
            </span>
        </div>
        <div class="col-md-8">
            <div class="row align-center">
                <div class="col-md-6" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchCourse($event)">
                </div>
                <div class="col-md-2" style="text-align: center;">
                    <button class="add-new-emp" style="width: 11rem;" (click)="addNewCourse()">
                        Add New Course
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div >
        <div class="no-course" *ngIf="listOfCourse.length==0">
            No course found
        </div>
        <div class="course-row" *ngIf="listOfCourse.length>0">

            <div class="course" *ngFor="let course of listOfCourse">
                <!--  (click)="gotoCourseEdit(course)" -->
                <div class="upper">
                    <div class="upper1">
                        <img [src]="course['courseImageUrl']==''? '../../../assets/img/placeholder-course.jpg':course['courseImageUrl']"
                            class="course-image" alt="Offer image">
                        <h1 class="level">{{course['levels']}}</h1>
                        <!-- <div class="row">
                            <div class="brand-icon-position" (click)="gotoCourseEdit(course)">
                                <mat-icon>edit</mat-icon>
                            </div>
                            <div class="brand-icon-position" style="top: 43px;" (click)="deleteCourse(course)">
                                <mat-icon>delete</mat-icon>
                            </div>
                            <div *ngIf="course.isActive" class="brand-icon-position" style="top: 82px;"
                                (click)="updateCourseStatus(course)">
                                <mat-icon>visibility</mat-icon>
                            </div>
                            <div *ngIf="!course.isActive" class="brand-icon-position" style="top: 82px;"
                                (click)="updateCourseStatus(course)">
                                <mat-icon>visibility_off</mat-icon>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="course-content">
                    <h1 class="name">{{course['courseName']}}</h1>
                    <div style="display: flex;justify-content:space-between;">
                        <h1 class="price">{{course['courseType']=='Free' ?'Free' :('Price:\
                            \u20b9'+course['coursePrice'])}}
                        </h1>
                        <p class="rating">Position: {{course['position']}}</p>
                    </div>
                    <div class="publish-row">
                        <p class="created">Created date: {{course['createdAt'] | date}}</p>
                        <h2 [ngClass]="{'status':course['isActive'],'inactive-status':!course['isActive']}">
                            {{course['isActive']? "Published":"Unpublished"}}</h2>
                    </div>


                    <div class="publish">
                        <div class="view-enrolled-user" (click)="courseUserList(course['_id'])">
                            View Enrolled User
                        </div>
                        <div class="view-enrolled-user" (click)="viewReviews(course['_id'])">
                            View Reviews
                        </div>

                    </div>

                    <div style="border-top: dotted 1px black;margin: 10px 0px;"></div>

                    <div class="row" style="justify-content: space-around;">
                        <div class="icon-div" (click)="gotoCourseEdit(course)">
                            <img src="../../../assets/img/batch-edit.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" style="top: 43px;" (click)="deleteCourse(course)">
                            <img src="../../../assets/img/batch-delete.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="course.isActive" style="top: 82px;"
                            (click)="updateCourseStatus(course)">
                            <img src="../../../assets/img/batch-publish.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" *ngIf="!course.isActive" style="top: 82px;"
                            (click)="updateCourseStatus(course)">
                            <img src="../../../assets/img/batch-unpublish.png" style="height: 1em;" alt="" srcset="">
                        </div>
                        <div class="icon-div" style="top: 43px;" (click)="gotoForum(course)">
                            <img src="../../../assets/img/message-2.png" style="height: 1em;" alt="" srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>