import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { AddNewFieldComponent } from '../add-new-field/add-new-field.component';
import { Location } from '@angular/common';
import { DeleteModuleActivityDialogComponent } from 'src/app/main-catalog/courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-add-edit-activity-detail',
  templateUrl: './add-edit-activity-detail.component.html',
  styleUrls: ['./add-edit-activity-detail.component.scss']
})
export class AddEditActivityDetailComponent implements OnInit {



  myHeaders = new Headers();
  formdata = new FormData();
  url: any;
  format: any;
  isAddindActivity: boolean = false;

  isLoading: boolean = true;
  defaultCoverImage =
    'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';

  userDetails: any;
  httpHeaders: any;

  titleFormControl = new FormControl('', [Validators.required]);
  moduleFormControl = new FormControl('Select Module', [Validators.required]);

  listOfModule: any = [];

  addMoreQuizes: boolean = false;
  isEditingExistingQuiz: boolean = false;
  listOfQuiz: any = [];
  radioSelectedValue: any;
  // editingQuiz: any;
  editingExistingQuizTimeStamp: any;

  // Image For Video Thumbnail
  selectedNote: any = null;
  selectedNoteName: any = null;
  defaultNoteUrl: any;


  activityAddEditForm: FormGroup = new FormGroup({
    title: this.titleFormControl,
    module: this.moduleFormControl,

  });
  activityDetail: any;
  batchId: any;
  activityId: any;
  activityType: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute, private _location: Location,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.routes.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.batchId = allParam['params']['batchId'];
      this.activityId = allParam['params']['activityId'];
      this.activityType = allParam['params']['type'];
    });
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.activityAddEditForm = this.formBuilder.group({
      title: this.titleFormControl,
      module: this.moduleFormControl,
    });
    if (this.activityType == 'Poll') {
      this.activityAddEditForm.removeControl('title');
      this.activityAddEditForm.addControl('question', new FormControl('', [Validators.required]));
      this.activityAddEditForm.addControl('option1', new FormControl('', [Validators.required]));
      this.activityAddEditForm.addControl('option2', new FormControl('', [Validators.required]));
      this.activityAddEditForm.addControl('option3', new FormControl('', []));
      this.activityAddEditForm.addControl('option4', new FormControl('', []));
    }
    if (this.activityId != null) {
      this.fetchActivityWithId();
    } else {
      this.fetchBatchModulesByBatchId()
    }
  }

  async fetchActivityWithId() {
    this.isLoading = true;
    try {
      let URL = `${webApi.domain + webApi.endPoint.fetchActivityWithId + `/${this.activityId}`}`;

      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.activityDetail = data['result'];
          if (this.activityType == 'Assessment') {
            this.listOfQuiz = this.activityDetail['assessment']['questions'];
            this.listOfQuiz.forEach((element: any, index: number) => {
              element['timeStamp'] = (new Date().getTime() + index);
            });
          }

        }
        this.fetchBatchModulesByBatchId();
      });
    } catch (error) {
      this.fetchBatchModulesByBatchId();
    }
  }
  async fetchBatchModulesByBatchId() {
    this.isLoading = true;
    try {
      this.listOfModule = [];

      const URL = `${webApi.domain + webApi.endPoint.fetchBatchModulesByBatchId}/${this.batchId}`;
      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfModule = data['result'];
        }
        this.setValuesForEdit();
      });
    } catch (error) {
      this.setValuesForEdit();
    }
  }

  setValuesForEdit() {
    if (this.activityDetail != null && this.activityDetail != undefined) {
      if (this.activityDetail['type'] == 'Assessments') {
        this.activityAddEditForm.get('title')?.setValue(this.activityDetail['assessment']['title']);
      } else if (this.activityDetail['type'] == 'Polls') {
        // this.activityAddEditForm.get('title')?.setValue(this.activityDetail['title']);
        this.activityAddEditForm.get('question')?.setValue(this.activityDetail['poll']['question']);
        for (let index = 0; index < this.activityDetail['poll']['options'].length; index++) {
          const element = this.activityDetail['poll']['options'][index];
          this.activityAddEditForm.get(`option${index + 1}`)?.setValue(element['option']);
        }
      } else {
        this.activityAddEditForm.get('title')?.setValue(this.activityDetail['note']['title']);
        if (this.activityDetail['note'] != null && this.activityDetail['note'] != undefined) {

          this.selectedNoteName = this.getFileName(this.activityDetail['note']['fileLink']);

          let extension =
            this.selectedNoteName.split('.')[
            this.selectedNoteName.split('.').length - 1
            ];
          if (extension == 'mp4' || extension == 'mp4') {
            this.defaultNoteUrl = '../../../../../assets/img/video.png';
          } else if (
            extension == 'doc' ||
            extension == 'docx'
          ) {
            this.defaultNoteUrl = '../../../../../assets/img/doc.png';
          } else if (
            extension == 'pdf'
          ) {
            this.defaultNoteUrl = '../../../../../assets/img/pdf.png';
          } else if (extension == 'mp3') {
            this.defaultNoteUrl = '../../../../../assets/img/audio.png';
          } else if (extension == 'png' || extension == 'jpg' || extension == 'jpeg') {
            this.defaultNoteUrl = this.activityDetail['note']['fileLink'];
          } else {
            this.defaultNoteUrl = '../../../../../assets/img/sheets.png';
          }

        }
      }
      this.activityAddEditForm.get('module')?.setValue(this.activityDetail['module']);

      // if (this.activityDetail['demoVideoLink'] != null && this.activityDetail['demoVideoLink'] != undefined) {
      //   this.defaultLiveSessionDemoUrl = '../../../../../assets/img/video.png';
      //   this.selectedLiveSessionDemoName = this.getFileName(this.activityDetail['demoVideoLink']);
      // }
      // this.defaultCoverImage =
      //   this.activityDetail['courseImageUrl'] != null
      //     ? this.activityDetail['courseImageUrl']
      //     : 'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';

    }

    this.isLoading = false;
  }

  getFileName(url: string) {
    let arrayurl = url.split('/');
    return arrayurl[arrayurl.length - 1];
  }

  removeNote() {
    this.selectedNote = null;
    this.selectedNoteName = null;
    this.defaultNoteUrl = '';
  }
  selectNoteFile(event: any) {
    this.selectedNote = event.target.files[0];
    this.selectedNoteName = this.selectedNote['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultNoteUrl = e.target.result;

      if (this.selectedNote['type'].split('/')[0] == 'image') {
        this.defaultNoteUrl = event.target.result;
      } else {
        let extension =
          this.selectedNote['name'].split('.')[
          this.selectedNote['name'].split('.').length - 1
          ];
        if (extension == 'mp4' || extension == 'mp4') {
          this.defaultNoteUrl = '../../../../../assets/img/video.png';
        } else if (
          extension == 'doc' ||
          extension == 'docx'
        ) {
          this.defaultNoteUrl = '../../../../../assets/img/doc.png';
        } else if (
          extension == 'pdf'
        ) {
          this.defaultNoteUrl = '../../../../../assets/img/pdf.png';
        } else if (extension == 'mp3') {
          this.defaultNoteUrl = '../../../../../assets/img/audio.png';
        } else {
          this.defaultNoteUrl = '../../../../../assets/img/sheets.png';
        }
      }
    };
    reader.readAsDataURL(this.selectedNote);

  }

  // Quiz FUnctions
  insertEditQuiz() {
    let question: any;
    if (this.editingExistingQuizTimeStamp != null && this.editingExistingQuizTimeStamp != undefined) {
      let idx = -1;
      let quizDt;
      for (let index = 0; index < this.listOfQuiz.length; index++) {
        const element = this.listOfQuiz[index];
        if (element['timeStamp'] == this.editingExistingQuizTimeStamp) {
          idx = index;
          quizDt = element;
        }
      }
      if (idx != -1) {
        question = {
          timeStamp: quizDt['timeStamp'],
          question: this.activityAddEditForm.get('question')?.value,
          score: 1,
          questionType: 'Single',
          options: [
            {
              isCorrect: this.radioSelectedValue == '0' ? true : false,
              value: this.activityAddEditForm.get('answer1')?.value,
            },
            {
              isCorrect: this.radioSelectedValue == '1' ? true : false,
              value: this.activityAddEditForm.get('answer2')?.value,
            },
            {
              isCorrect: this.radioSelectedValue == '2' ? true : false,
              value: this.activityAddEditForm.get('answer3')?.value,
            },
            {
              isCorrect: this.radioSelectedValue == '3' ? true : false,
              value: this.activityAddEditForm.get('answer4')?.value,
            },
          ],
        };
      } else {
        question = {
          timeStamp: new Date().getTime(),
          question: this.activityAddEditForm.get('question')?.value,
          score: 1,
          questionType: 'Single',
          options: [
            {
              isCorrect: this.radioSelectedValue == '0' ? true : false,
              value: this.activityAddEditForm.get('answer1')?.value,
            },
            {
              isCorrect: this.radioSelectedValue == '1' ? true : false,
              value: this.activityAddEditForm.get('answer2')?.value,
            },
            {
              isCorrect: this.radioSelectedValue == '2' ? true : false,
              value: this.activityAddEditForm.get('answer3')?.value,
            },
            {
              isCorrect: this.radioSelectedValue == '3' ? true : false,
              value: this.activityAddEditForm.get('answer4')?.value,
            },
          ],
        };
      }
      this.listOfQuiz[idx] = question;
    } else {
      question = {
        timeStamp: new Date().getTime(),
        question: this.activityAddEditForm.get('question')?.value,
        score: 1,
        questionType: 'Single',
        options: [
          {
            isCorrect: this.radioSelectedValue == '0' ? true : false,
            value: this.activityAddEditForm.get('answer1')?.value,
          },
          {
            isCorrect: this.radioSelectedValue == '1' ? true : false,
            value: this.activityAddEditForm.get('answer2')?.value,
          },
          {
            isCorrect: this.radioSelectedValue == '2' ? true : false,
            value: this.activityAddEditForm.get('answer3')?.value,
          },
          {
            isCorrect: this.radioSelectedValue == '3' ? true : false,
            value: this.activityAddEditForm.get('answer4')?.value,
          },
        ],
      };
      this.listOfQuiz.push(question);
    }
    this.activityAddEditForm.get('question')?.setValue('');
    this.activityAddEditForm.get('answer1')?.setValue('');
    this.activityAddEditForm.get('answer2')?.setValue('');
    this.activityAddEditForm.get('answer3')?.setValue('');
    this.activityAddEditForm.get('answer4')?.setValue('');
    this.radioSelectedValue = null;
    this.editingExistingQuizTimeStamp = null;
    this.activityAddEditForm.removeControl('question');
    this.activityAddEditForm.removeControl('answer1');
    this.activityAddEditForm.removeControl('answer2');
    this.activityAddEditForm.removeControl('answer3');
    this.activityAddEditForm.removeControl('answer4');
    this.addMoreQuizes = false;

  }
  closeQuizAddEditSection() {
    // if (this.editingQuiz != null) {
    //   this.listOfQuiz.push(this.editingQuiz);
    //   this.editingQuiz = null;
    // }
    this.listOfQuiz.sort(
      (b: any, a: any) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    this.isEditingExistingQuiz = false;
    this.editingExistingQuizTimeStamp = null;
    this.activityAddEditForm.get('question')?.setValue('');
    this.activityAddEditForm.get('answer1')?.setValue('');
    this.activityAddEditForm.get('answer2')?.setValue('');
    this.activityAddEditForm.get('answer3')?.setValue('');
    this.activityAddEditForm.get('answer4')?.setValue('');
    this.radioSelectedValue = null;
    this.activityAddEditForm.removeControl('question');
    this.activityAddEditForm.removeControl('answer1');
    this.activityAddEditForm.removeControl('answer2');
    this.activityAddEditForm.removeControl('answer3');
    this.activityAddEditForm.removeControl('answer4');
    this.addMoreQuizes = false;
  }

  radioChangeHandler(event: any) {
    this.radioSelectedValue = event.target.value;
    // console.log(event);
  }
  addMoreQuiz(id: any) {
    let el = document.getElementById(id);
    el?.scrollIntoView();
    this.activityAddEditForm.addControl('question', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer1', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer2', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer3', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer4', new FormControl('', [Validators.required]));
    this.addMoreQuizes = true;
  }
  editQuiz(quiz: any) {

    this.activityAddEditForm.addControl('question', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer1', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer2', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer3', new FormControl('', [Validators.required]));
    this.activityAddEditForm.addControl('answer4', new FormControl('', [Validators.required]));
    this.addMoreQuizes = true;

    this.isEditingExistingQuiz = true;
    this.editingExistingQuizTimeStamp = quiz.timeStamp;

    this.activityAddEditForm.get('question')?.setValue(quiz['question']);
    this.activityAddEditForm.get('answer1')?.setValue(quiz['options'][0]['value']);
    this.activityAddEditForm.get('answer2')?.setValue(quiz['options'][1]['value']);
    this.activityAddEditForm.get('answer3')?.setValue(quiz['options'][2]['value']);
    this.activityAddEditForm.get('answer4')?.setValue(quiz['options'][3]['value']);
    quiz['options'].forEach((option: any, index: number) => {
      if (option['isCorrect']) {
        this.radioSelectedValue = index;
        this.activityAddEditForm.get('correctAnswerRadio')?.setValue(index);
      }
    });
    console.log(this.listOfQuiz);
  }
  deleteQuiz(quiz: any) {
    this.openAlertWhenContactNotConfirmed(quiz);
  }
  openAlertWhenContactNotConfirmed(quiz: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Quiz', quiz: quiz };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        this.listOfQuiz.forEach((quiz: any) => {
          quiz['isDeleting'] = false;
        });
        return;
      } else {
        let idx = -1;
        for (let index = 0; index < this.listOfQuiz.length; index++) {
          const element = this.listOfQuiz[index];
          if (element['_id'] == quiz['_id']) {
            idx = index;
          }
        }
        if (idx != -1) {
          this.listOfQuiz.splice(idx, 1);
        }
        this.showSuccessToaster('Quiz Deleted Successfully', 'Success');
      }
    });
  }
  // End Multiple Fields


  async createEditActivity() {
    this.isAddindActivity = true;
    try {

      this.formdata = new FormData();
      if (this.activityId != null && this.activityId != undefined) {
        this.formdata.set('activityId', this.activityDetail['_id']);
      } else {
        this.formdata.set('activityId', '0');
      }


      if (this.activityType == 'Notes') {
        this.formdata.set('title', this.activityAddEditForm.get('title')?.value);

        if (this.selectedNote == null || this.selectedNote == undefined) {
          if (this.activityDetail != null && this.activityDetail != undefined) {
            this.formdata.set('fileLink', this.activityDetail['note']['fileLink']);
          }
        } else {
          this.formdata.set('note', this.selectedNote);
        }

      }
      if (this.activityType == 'Assessment') {
        this.formdata.set('title', this.activityAddEditForm.get('title')?.value);
        this.formdata.set('questions', JSON.stringify(this.listOfQuiz));
      }
      if (this.activityType == 'Poll') {
        let poll = {}
        poll = {
          question: this.activityAddEditForm.get('question')?.value,
          options: [
            {
              voteCount: 0, votePercentage: 0,
              option: this.activityAddEditForm.get('option1')?.value,
            },
            {
              voteCount: 0, votePercentage: 0,
              option: this.activityAddEditForm.get('option2')?.value,
            },
            {
              voteCount: 0, votePercentage: 0,
              option: this.activityAddEditForm.get('option3')?.value,
            },
            {
              voteCount: 0, votePercentage: 0,
              option: this.activityAddEditForm.get('option4')?.value,
            },
          ],
        };
        this.formdata.set('poll', JSON.stringify(poll));

      }

      this.formdata.set('module', this.activityAddEditForm.get('module')?.value);
      this.formdata.set('batch', this.batchId);

      this.myHeaders.append(
        'Authorization',
        `Bearer ${this.userDetails['accessToken']}`
      );

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };

      let URL;
      if (this.activityType == 'Assessment') {
        URL = `${webApi.domain + webApi.endPoint.createOrUpdateAssessmentActivity}`;
      }
      else if (this.activityType == 'Notes') {
        URL = `${webApi.domain + webApi.endPoint.createOrUpdateNotesActivity}`;
      }
      else {
        URL = `${webApi.domain + webApi.endPoint.createOrUpdatePollsActivity}`;
      }


      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            this.isAddindActivity = false;
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.activityDetail = res['result'];
                this.batchId = res['result']['_id'];
                this.myHeaders = new Headers();
                this._location.back();
                if (this.activityId != null && this.activityId != undefined) {
                  this.showSuccessToaster(
                    'Activity Updated Successfully',
                    'Success'
                  );
                } else {
                  this.showSuccessToaster(
                    'Activity Created Successfully',
                    'Success'
                  );
                }
              } else {
                this.myHeaders = new Headers();
                if (this.activityId != null && this.activityId != undefined) {
                  this.showWarningToaster(
                    'Unable to update activity. Please try again.',
                    'Warning'
                  );
                } else {
                  this.showWarningToaster(
                    'Unable to create activity. Please try again.',
                    'Warning'
                  );
                }
                this.isAddindActivity = false;
              }
            });
          }
        })
        .catch((error) => console.log('error', error));
      return;
    } catch (error) {
      this.isAddindActivity = false;
      if (this.activityId != null && this.activityId != undefined) {
        this.showWarningToaster(
          'Unable to update activity. Please try again.',
          'Warning'
        );
      } else {
        this.showWarningToaster(
          'Unable to create activity. Please try again.',
          'Warning'
        );
      }
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
