import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { EnroledUser } from '../../courses/course-user-list/course-user-list.component';
import { DeleteModuleActivityDialogComponent } from '../../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-view-batch-enrolled-user',
  templateUrl: './view-batch-enrolled-user.component.html',
  styleUrls: ['./view-batch-enrolled-user.component.scss']
})
export class ViewBatchEnrolledUserComponent implements OnInit {

  batchId: String = '';
  p: any = 1;
  count: any = 50;
  skip: any = 0;
  limit: any = 500;
  totalCount: any = 0;
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    console.log('Called Constructor');
    this.routes.params.subscribe((params) => {
      this.batchId = params['batchId'];
    });
  }

  userDetail: any;
  httpHeaders: any;
  isLoading: boolean = true;
  myHeaders = new Headers();
  listOfUserToDisplay: any = [];
  listOfUser: any = [];
  isLoadingExtraData: boolean = false;
  isLoadingData: boolean = false;

  backToCourse() {
    this.router.navigate(['/catalog/courses']);
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchBatchEnroledUsers();
  }

  async fetchBatchEnroledUsers() {
    this.listOfUser = [];

    const URL = `${webApi.domain + webApi.endPoint.fetchBatchEnroledUsers}`;
    const param = {
      limit: this.limit,
      skip: this.skip,
      batchId: this.batchId, latest: "false"
    };
    await this.as
      .getMethodWithTokenAndParams(URL, param, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.listOfUser = data['result'];
          this.totalCount = data['count'];

          this.listOfUserToDisplay = this.listOfUser;
        } else {
          this.isLoading = false;
        }
      });
  }

  async fetchExtraUsers(isLoadingExtraData: boolean) {
    const URL = `${webApi.domain + webApi.endPoint.fetchBatchEnroledUsers}`;
    const param = {
      limit: this.limit,
      skip: this.skip,
      batchId: this.batchId, latest: "false"
    };
    this.isLoadingExtraData = isLoadingExtraData;
    await this.as
      .getMethodWithTokenAndParams(URL, param, this.httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          data['result'].forEach((data: any) => {
            this.listOfUser.push(data);
          });
          this.listOfUserToDisplay = this.listOfUser;
        } else {
          this.isLoading = false;
        }
      });
  }

  pageChanged(event: any) {
    this.p = event;
    console.log(event);

    if (this.p * this.count >= this.listOfUser.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraUsers(false);
    } else if (this.p * this.count >= this.listOfUser.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraUsers(true);
    }


  }

  searchCourseUserList(event: any) {
    let serachQuery = event.target.value;
    let newCourseList: any = [];
    this.listOfUserToDisplay = [...this.listOfUser];
    if (serachQuery != '') {
      this.listOfUserToDisplay.forEach((course: any) => {
        if (
          String(course['userName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          newCourseList.push(course);
        }
      });
      this.listOfUserToDisplay = [...newCourseList];
    } else {
      this.listOfUserToDisplay = [...this.listOfUser];
    }
  }

  async updateUserEnrolmentStatus(
    enrolment: EnroledUser,
    enrolledStatus: boolean
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Enrolment', enrolment };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        return;
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          userId: res['value']['user']['_id'],
          isActive: enrolledStatus,
          course: this.batchId,
        };

        const URL = `${webApi.domain + webApi.endPoint.updateUserEnrolmentStatus
          }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfUser.forEach((user: any, index: number) => {
                if (user['user']['_id'] == res['value']['user']['_id']) {
                  user['isActive'] = enrolledStatus;
                }
              });
              this.listOfUserToDisplay = this.listOfUser;
              this.showSuccessToaster(
                'Enroled Status Updated Successfully',
                'Success'
              );
            } else {
              this.showSuccessToaster('Something went wrong', 'Success');
            }
          });
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }

}
