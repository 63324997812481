import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-user-report-dialog',
  templateUrl: './user-report-dialog.component.html',
  styleUrls: ['./user-report-dialog.component.scss']
})
export class UserReportDialogComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;

  fileNameControl = new FormControl('', [Validators.required]);


  generateReportForm: FormGroup = new FormGroup({
    fileName: this.fileNameControl,

  });
  userDetails: any;
  constructor(
    public dialogRef: MatDialogRef<UserReportDialogComponent>,
    private toastr: ToastrService,
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }

  ngOnInit(): void {
    this.generateReportForm = this.formBuilder.group({
      fileName: this.fileNameControl,

    });
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  async generateReport() {
    this.isLoading = true;
    let URL = '';
    let param: any = {};

    param = {
      fileName: this.generateReportForm.get('fileName')?.value,
      criteria: this.data['criteria'],
    };
    URL = `${webApi.domain + webApi.endPoint.generateUserCSRReport}`;

    this.showSuccessToaster(
      'Report is being generated.It will reflect within few minutes',
      'Please Wait'
    );
    this.dialogRef.close();
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    await this.appService
      .postMethodWithToken(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.closeActivityAtDialog.emit({
            result: data['result'],
          });
        } else {
          this.isLoading = false;

          this.closeActivityAtDialog.emit({ error: 'Error' });
        }
        this.dialogRef.close();
      });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarninigToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
