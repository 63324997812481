<div style="height: 100vh;overflow: scroll;">

    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">


        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- <div  style="cursor: pointer;" (click)="gotoSubDashboard()">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <span style="font-size: large;
                color: #1f5ce1;cursor: pointer;">
                    {{magazineDetails['name']}}
                </span>
            </div> -->
            <div class="row top-row-sb" style="margin-left: 0rem;margin-right: -2rem;">

                <div class="user-heading-text" *ngIf=" !searched" (click)="gotoSubDashboard()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    Subscriptions ({{isLoading?0:totalCount}})
                </div>
                <div class="user-heading-text" *ngIf=" searched" (click)="gotoSubDashboard()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    Subscriptions ({{searchedSubscription.length}})
                </div>

                <div *ngIf="!isLoading" class="search">
                    <!-- <div class="col-md-3"
                        style="text-align: right;display: flex;justify-content: space-around;align-items: center; margin-right: 4rem;font-size: 1em;">

                        <mat-form-field appearance="outline">
                            <mat-label>Pick a date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="Start date" [value]="startDate"
                                    (dateChange)="dateChanged( 'start',$event)">
                                <input matEndDate placeholder="End date" [value]="endDate"
                                    (dateChange)="dateChanged( 'end',$event)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                            </mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <div (click)="clearDateRange()"
                            style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 15px; margin-bottom: 1.3rem;">
                            Clear
                        </div>
                    </div> -->

                    <div class="search-field">
                        <input type="text" class="search__input" placeholder="Search" [(ngModel)]="searchString"
                            (input)="emptySearchString($event.target)" (keyup.enter)="searchSubscription()"
                            (keydown)="searchSubscriptionWhileTyping()">
                    </div>
                    <div class="searchUser" (click)="searchSubscription()">
                        <h5 style="margin-bottom:0;font-size: 1rem; ">Search</h5>
                    </div>
                    <!-- <div class="col-md-2" style="text-align: center;">
                        <button class="add-new-emp" (click)="bulkSubscription()">
                            Create Subscription
                        </button>
                    </div> -->
                    <!-- <div style="width: 5em; position: relative;cursor: pointer;margin-left: 3em;">
                        <img class="mr-4"
                            [src]="filterToEdit==null ||filterToEdit=='close' ? '.../../../../assets/svgIcons/Filter.svg':'.../../../../assets/svgIcons/filter-filled.svg'"
                            title="Apply Filter" alt="" srcset="" (click)="toggleOpenFilter()">
                      
                    </div>
                    <div *ngIf="filterToEdit!=null"
                        style="width: 5em; position: relative;cursor: pointer;">
                        <img class="mr-4" src="../../../../assets/svgIcons/Save.svg" title="Download Report"
                            alt="" srcset="" (click)="generateReport()">
                    </div> -->
                </div>
            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfSubscriptionToDisplay.length==0  && !isLoading">
                <div class="no-available">
                    No Subscription found
                </div>
            </div>
            <div *ngIf="searchedSubscription.length==0 && searched">
                <div class="no-available">
                    No Subscription found
                </div>
            </div>
            <div *ngIf="searchedSubscription.length>0 && !isLoading && searched">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            Sr.No</th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            Name & Phone</th>

                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Plan
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Type
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Razorpay
                            Subscription Id
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Status
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Visit Count
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Start Date
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            End Date</th>

                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            Created Date</th>


                        <th class="table-heading-content">Action</th>

                    </thead>
                    <!-- <div style="height: 0.6em;"> </div> -->
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let subscription of searchedSubscription | paginate: { itemsPerPage: count, currentPage: p }">
                            <th class="table-data-content">{{subscription.srNo}}
                            </th>
                            <th class="table-data-content">{{subscription.user['fullName']+'
                                ('+subscription.user['phone']+')'}}</th>
                            <th class="table-data-content">{{subscription.plan.name+' ('+subscription.plan.subType+')'}}
                            </th>
                            <th class="table-data-content">{{subscription.type}}</th>
                            <th class="table-data-content">{{subscription.rzpSubscriptionId}}</th>
                            <th class="table-data-content">{{subscription.subscriptionStatus}}</th>
                            <th class="table-data-content">{{subscription.visitCount}}</th>
                            <th class="table-data-content">{{subscription.startDate|date}}
                            </th>
                            <th class="table-data-content">{{subscription.endDate |date}}</th>
                            <th class="table-data-content">{{subscription.createdAt|date}}</th>
                            <th class="table-data-content" style="width: 6em;">
                                <div style="display: contents;">
                                    <img (click)="viewSubscription(subscription)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../assets/img/view.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!isLoadingExtraData" class="text-right"
                    style="font-size: medium;margin: 1.5em;float: right;">
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
                <div *ngIf="isLoadingExtraData" class="text-right"
                    style="font-size: medium;margin: 1.5em;float: right;">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
                <div style="height: 10em;"> </div>
            </div>
            <div *ngIf="listOfSubscriptionToDisplay.length>0 && !isLoading && !searched">
                <table class="table-style" cellspacing="0" cellpadding="0">
                    <thead class="table-heading">

                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            Sr.No</th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            Name & Phone</th>

                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Plan
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Type
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Razorpay
                            Subscription Id
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Status
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Visit Count
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">Start Date
                        </th>
                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            End Date</th>

                        <th style="cursor: pointer;" data-order="desc" class="table-heading-content">
                            Created Date</th>
                        <th class="table-heading-content">Action</th>
                    </thead>
                    <!-- <div style="height: 0.6em;"> </div> -->
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let subscription of listOfSubscriptionToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <th class="table-data-content">{{subscription.srNo}}
                            </th>
                            <th class="table-data-content">{{subscription.user['fullName']+'
                                ('+subscription.user['phone']+')'}}</th>
                            <th class="table-data-content">{{subscription.plan.name+' ('+subscription.plan.subType+')'}}
                            </th>
                            <th class="table-data-content">{{subscription.type}}</th>
                            <th class="table-data-content">{{subscription.rzpSubscriptionId}}</th>
                            <th class="table-data-content">{{subscription.subscriptionStatus}}</th>
                            <th class="table-data-content">{{subscription.visitCount}}</th>
                            <th class="table-data-content">{{subscription.startDate|date}}
                            </th>
                            <th class="table-data-content">{{subscription.endDate |date}}</th>
                            <th class="table-data-content">{{subscription.createdAt|date}}</th>
                            <th class="table-data-content" style="width: 6em;">
                                <div style="display: contents;">
                                    <img (click)="viewSubscription(subscription)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../assets/img/view.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!isLoadingExtraData" class="text-right"
                    style="font-size: medium;margin: 1.5em;float: right;">
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
                <div *ngIf="isLoadingExtraData" class="text-right"
                    style="font-size: medium;margin: 1.5em;float: right;">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                    </mat-progress-spinner>
                </div>
                <div style="height: 10em;"> </div>
            </div>
        </div>
    </ng-sidebar-container>
</div>