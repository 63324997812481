import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { AddMoneyDialogComponent } from '../add-money-dialog/add-money-dialog.component';
import { ViewCampaignDetailComponent } from '../view-campaign-detail/view-campaign-detail.component';

export interface ICampaign {
  _id: string;
  campaignName: string;
  campaignSubject: string;
  campaignBody: string;
  campaignType: string;
  userData: any;
  campaignViewDetail: any;
  openedCount: number;
  sentCount: number;
  linkOpenedCount: number;
  receiveCount: number;
  selectedCriteria: string;
  createdAt: Date;
}

@Component({
  selector: 'app-list-campaign',
  templateUrl: './list-campaign.component.html',
  styleUrls: ['./list-campaign.component.scss'],
})
export class ListCampaignComponent implements OnInit {
  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  selectedRole: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  clientDetail: any;
  isLoading: boolean = false;
  listOfCampaign: Array<ICampaign> = [];
  listOfCampaignToDisplay: Array<ICampaign> = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  ngOnInit(): void {
    this.selectedRole = 'All';
    this.getClientDetail();
  }

  searchData(event: any) {}

  async getClientDetail() {
    this.isLoading = true;
    this.clientDetail = null;
    try {
      let param = {
        phone: '9152615121',
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${WA_API.domain + WA_API.endPoint.getClientDetail}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.clientDetail = data['result'][0];
            this.fetchCampaigns();
          } else {
            this.fetchCampaigns();
          }
        });
    } catch (error) {
      this.fetchCampaigns();
    }
  }

  async fetchCampaigns() {
    this.isLoading = true;
    this.listOfCampaign = [];
    let param = {
      campaignId: 0,
    };

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.fetchCampaigns}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then(async (data: any) => {
        if (data['success']) {
          this.listOfCampaign = data['result'];
          for (let index = 0; index < this.listOfCampaign.length; index++) {
            if (this.listOfCampaign[index].campaignType == 'WhatsApp') {
              let data = await this.getcampaignViewDetail(
                this.listOfCampaign[index]
              );
              this.listOfCampaign[index].campaignViewDetail = data;
            }
          }

          this.listOfCampaignToDisplay = this.listOfCampaign;
          // console.log(this.listOfCampaignToDisplay);
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
  }

  async getcampaignViewDetail(campaignDetail: any) {
    this.isLoading = true;
    let campaignViewDetail = null;
    try {
      let param = {
        campaignId: campaignDetail['_id'],
      };

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${
        WA_API.domain + WA_API.endPoint.fetchMessageDetailByCampaignId
      }`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            campaignViewDetail = data['result'];
            return campaignViewDetail;
          } else {
            return undefined;
          }
        });
      return campaignViewDetail;
    } catch (error) {
      return undefined;
    }
  }
  roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  rechargeWallet() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { clientId: this.clientDetail['_id'] };
    dialogConfig.width = '30vw';
    dialogConfig.maxHeight = '40vh';
    let dialogRef = this.matDialog.open(AddMoneyDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
      }
    });
  }
  addNewCampaign() {
    this.toggleOpened();
  }

  editCampaign(employee: any) {
    this.dataToEdit = employee;
    this.toggleOpened();
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        this.showWarningToaster(
          'Warning while creating campaign.Kindly try again',
          'Warning'
        );

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfCampaignToDisplay.findIndex(
          (team: any) => team['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfCampaignToDisplay.push(event);
          this.listOfCampaign = [...this.listOfCampaignToDisplay];
          this.showSuccessToaster('Campaign added successfully', 'Success');
        } else {
          this.listOfCampaignToDisplay[index] = event;
          this.listOfCampaign = [...this.listOfCampaignToDisplay];
          this.showSuccessToaster('Campaign Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  viewCampaign(campaign: ICampaign) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { campaign: campaign };
    dialogConfig.width = '40vw';
    dialogConfig.maxHeight = '70vh';
    let dialogRef = this.matDialog.open(
      ViewCampaignDetailComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
      }
    });
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
