import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ViewAuditLogValueComponent } from '../view-audit-log-value/view-audit-log-value.component';

@Component({
  selector: 'app-list-audit-log',
  templateUrl: './list-audit-log.component.html',
  styleUrls: ['./list-audit-log.component.scss']
})
export class ListAuditLogComponent implements OnInit {
  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;


  listOfAuditLog: any = [];
  listOfAuditLogToDisplay: any = [];
  userDetails: any;
  isLoading: boolean = true;

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.fetchAuditLog();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }
  searchCourse(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let tempList: any = [];
    this.listOfAuditLogToDisplay = [...this.listOfAuditLog];
    if (serachQuery != '') {
      this.listOfAuditLogToDisplay.forEach((audit: any) => {
        if (
          String(audit['user']['fullName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(audit['user']['phone'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(audit['user']['email'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(audit['admin'] == null || audit['admin'] == undefined ? 'NA' : audit['admin']['fullName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempList.push(audit);
        }
      });
      this.listOfAuditLogToDisplay = [...tempList];
    } else {
      this.listOfAuditLogToDisplay = [...this.listOfAuditLog];
    }
  }
  async fetchAuditLog() {
    this.isLoading = true;
    this.listOfAuditLog = [];
    this.listOfAuditLogToDisplay = [];
    try {
      let URL = '';
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchAuditLog}`;
      const param = {
        admin: true,
      };
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfAuditLog = data['result'];
          this.listOfAuditLogToDisplay = this.listOfAuditLog;
          this.isLoading = false;
        } else {
          this.listOfAuditLog = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfAuditLog = [];
      this.isLoading = false;
    }
  }

  viewEditLog(audit: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { audit: audit };
    dialogConfig.width = '45vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '85vh';
    let dialogRef = this.matDialog.open(ViewAuditLogValueComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else { }
    });
  }



  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }


}
