<div class="main-container">

    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height:92vh;">

        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="overflow: hidden;">
            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [closeOnClickOutside]="true" [dockedSize]="'50px'"
                [closeOnClickOutside]="closeOnClickOutside" [closeOnClickBackdrop]="closeOnClickBackdrop"
                [showBackdrop]="showBackdrop" [animate]="animate" [trapFocus]="trapFocus" [autoFocus]="autoFocus"
                [ariaLabel]="'My sidebar'">
                <div *ngIf="callAddModule">
                    <app-add-module [courseDetailToEdit]="courseDtls" [moduleDetailToEdit]="moduleToEdit"
                        (closeSideForm)="toggleClose($event)"></app-add-module>
                </div>
            </ng-sidebar>
            <div ng-sidebar-content *ngIf="!isLoading" style="margin-left: 4em;margin-right: 4em;">


                <div class="no-data-available" *ngIf="courseDtls==null">
                    <div>
                        Please add Course Details first then start adding modules
                    </div>
                </div>
                <div *ngIf="courseDtls!=null">

                    <div class="row" style="justify-content:space-between;align-items: center;">
                        <div class="goback" (click)="backToCourse()">
                            <mat-icon style="width: 15px;margin-right: -7px;font-size: 12px;">
                                arrow_back_ios
                            </mat-icon> Back to Course
                        </div>
                        <div class="add-module-text" (click)="addNewModule()">
                            <!-- <mat-icon class="add-module-icon">add</mat-icon> Add new Module -->
                            <p style="padding: .4rem;margin: 0;">Add new Module</p>
                        </div>
                    </div>
                    <div class="no-data-available" *ngIf="listOfModule.length==0">
                        <div>
                            No Modules Available. Start Adding new modules
                        </div>
                    </div>
                    <div *ngIf="listOfModule.length>0">
                        <div *ngFor="let module of listOfModule">
                            <div class="row module-box">
                                <div class="col-md-7" style="font-size: small;font-weight: 600;word-break: break-all;">
                                    {{module['name']}}
                                </div>
                                <div class="col-md-5" style="display:flex;justify-content:space-around;">
                                    <div class="view-activity" (click)="viewActivity(module)">
                                        <!-- <img class="svg-style-action-icon" src="../../../../assets/svgIcons/.svg" alt="" srcset=""> -->
                                        <span>
                                            View Activity
                                        </span>
                                    </div>
                                    <div *ngIf="!module['isChangingStatus']" class="delete-module"
                                        (click)="changeModuleStatus(module)">
                                        <img class="svg-style-action-icon"
                                            [src]="module['isActive']?'../../../../assets/svgIcons/visibility.svg':'../../../../assets/svgIcons/hide.svg'"
                                            alt="" srcset="">
                                        <span>
                                            {{module['isActive']?'Published':'Unpublished'}}
                                        </span>
                                    </div>
                                    <div *ngIf="module['isChangingStatus']" class="delete-module">
                                        <mat-progress-spinner mode="indeterminate" diameter="20"
                                            style="margin:0 auto;height:20px;width:20px;color: white;">
                                        </mat-progress-spinner>
                                    </div>
                                    <div *ngIf="!module['isDeleting']" class="delete-module"
                                        (click)="deleteModule(module)">
                                        <img class="svg-style-action-icon" src="../../../../assets/svgIcons/delete.svg"
                                            alt="" srcset="">
                                        <span>
                                            Delete
                                        </span>

                                    </div>
                                    <div *ngIf="module['isDeleting']" class="delete-module">
                                        <mat-progress-spinner mode="indeterminate" diameter="20"
                                            style="margin:0 auto;height:20px;width:20px;color: white;">
                                        </mat-progress-spinner>
                                    </div>
                                    <div class="edit-module" (click)="editModule(module)">
                                        <img class="svg-style-action-icon" src="../../../../assets/svgIcons/edit.svg"
                                            alt="" srcset="">
                                        <span>
                                            Edit
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 15em;"></div>
                </div>
            </div>
        </ng-sidebar-container>
    </div>
</div>