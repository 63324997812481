<div style="width: 35em;">
    <h1 mat-dialog-title style="font-weight: 500;">Generate Report</h1>

    <div>
        <form name="generateReportForm" [formGroup]="generateReportForm">



            <label for="type" class="label required">File Name</label>
            <input type="text" placeholder="Enter File Name" id="fileName" formControlName="fileName"
                class="input-style">



        </form>
    </div>
    <div mat-dialog-actions>
        <button class="cancel-btn" (click)="isLoading?null:close()">Cancel</button>

        <button *ngIf="isLoading"
            [ngClass]="{'ok-btn-disabled':generateReportForm.invalid ,'ok-btn-enabled':generateReportForm.valid }"
            [disabled]="generateReportForm.invalid ">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </button>


        <button *ngIf="!isLoading "
            [ngClass]="{'ok-btn-disabled':generateReportForm.invalid ,'ok-btn-enabled':generateReportForm.valid }"
            [disabled]="generateReportForm.invalid " (click)=" generateReport()">Save</button>

    </div>
</div>