import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActiveDeleteDialogComponent } from '../../special-offers/active-delete-dialog/active-delete-dialog.component';

export interface YoutubeVideo {
  _id: string;
  createdAt: Date;
  isActive: boolean;
  type: string;
  title: string;
  videoLink: string;
  duration: number;
}
@Component({
  selector: 'app-list-youtube-video',
  templateUrl: './list-youtube-video.component.html',
  styleUrls: ['./list-youtube-video.component.scss']
})
export class ListYoutubeVideoComponent implements OnInit {
  isLoading: boolean = false;
  userDetails: any;
  httpHeaders: any;
  magazineDetails: any;

  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.fetchAllYoutubeVideos();
  }

  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfVideoToDisplay: Array<YoutubeVideo> = [];
  listOfVideos: Array<YoutubeVideo> = [];

  myHeaders = new Headers();

  async fetchAllYoutubeVideos() {
    this.isLoading = true;
    this.listOfVideoToDisplay = [];
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.fetchAllYoutubeVideos}`;

      await this.as
        .fetchDetailViaGet(URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfVideoToDisplay = data['result'];
            this.listOfVideos = [...this.listOfVideoToDisplay];
            this.isLoading = false;
          } else {
            this.listOfVideoToDisplay = [];
            this.listOfVideos = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfVideoToDisplay = [];
      this.listOfVideos = [];
      this.isLoading = false;
    }
  }

  getYoutubeThumbnail(video: any) {
    let videoId = video.split('=')[1];
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  }

  addNewVideo() {
    this.toggleOpened();
  }

  editUTM(magazine: any) {
    let magazineToEdit;
    this.listOfVideoToDisplay.forEach((mgz: any) => {
      if (mgz['_id'] == magazine._id) {
        magazineToEdit = mgz;
      }
    });
    this.dataToEdit = magazineToEdit;
    this.toggleOpened();
  }
  viewAnalytic(video: any) {
    this.router.navigate(['/catalog/view-analytic'], { queryParams: { id: video._id } });

  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing video details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding video details.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        const index = this.listOfVideoToDisplay.findIndex(
          (video: any) => video['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfVideoToDisplay.push(event);
          this.listOfVideos = [...this.listOfVideoToDisplay];
          this.showSuccessToaster('Video added successfully', 'Success');
        } else {
          this.listOfVideoToDisplay[index] = event;
          this.listOfVideos = [...this.listOfVideoToDisplay];
          this.showSuccessToaster('Video Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  async createLink(utmId: string) {
    try {
      let URL = `${webApi.domain + webApi.endPoint.createDynamicLinksForUTM}`;

      await this.as
        .postMethodWithToken({ utmId }, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.clipboard.copy(data['result']['shortLink']);
            this.showSuccessToaster('Link Copied', 'Success');
          } else {
            this.showErrorToaster('Error occur while creating link', 'Error');
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }

  updateActiveStatus(video: YoutubeVideo, isActive: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'Active YoutubeVideo' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          videoId: res['value']['_id'],
          action: 'Status',
          isActive: isActive,
        };

        const URL = `${webApi.domain + webApi.endPoint.updateYoutubeVideoStatus
          }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfVideoToDisplay.forEach(
                (ticker: any, index: number) => {
                  if (ticker['_id'] == res['value']['_id']) {
                    ticker.isActive = isActive;
                  }
                }
              );
              this.listOfVideos = [...this.listOfVideoToDisplay];
              if (isActive) {
                this.showSuccessToaster(
                  'Video Activated Successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Video Inactivated Successfully',
                  'Success'
                );
              }
            } else {
              this.showErrorToaster(
                'Error Occurred while updating ticker. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  deleteVideo(video: YoutubeVideo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { video: video, source: 'Delete YoutubeVideo' };

    let dialogRef = this.matDialog.open(
      ActiveDeleteDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        const param = {
          videoId: res['value']['_id'],
          action: 'Delete',
        };

        const URL = `${webApi.domain + webApi.endPoint.updateYoutubeVideoStatus
          }`;
        await this.as
          .updateDataViaPut(param, URL, this.httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              let index = 0;
              this.listOfVideoToDisplay.forEach((ticker: any, i: number) => {
                if (ticker['_id'] == res['value']['_id']) {
                  index = i;
                }
              });
              this.listOfVideoToDisplay.splice(index, 1);
              this.listOfVideos = [...this.listOfVideoToDisplay];

              this.showSuccessToaster(
                'Ticker Deleted Successfully',
                'Success'
              );
            } else {
              this.showErrorToaster(
                'Error Occurred while deleting ticker. Try again',
                'Error'
              );
            }
          });
      }
    });
  }



  editTicker(video: any) {
    let videoToEdit;
    this.listOfVideoToDisplay.forEach((data: any) => {
      if (data['_id'] == video._id) {
        videoToEdit = data;
      }
    });
    this.dataToEdit = videoToEdit;
    this.toggleOpened();
  }
}
