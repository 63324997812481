<div class="display-flex align-items-center justify-content-end">
    <div class="close-img-div" (click)="close()">
        <img src="../../../../assets/svgIcons/close.svg" style="height: 1em;" alt="close-image" srcset="">
    </div>
</div>
<div class="main-container">
    <!-- <div class="media-div">
        <img [src]="defaultFileContent" alt="media-image" srcset="" style="height: 20em;">
    </div> -->
    <div class="media-div" *ngIf="selectedFileContentExtension=='png' || selectedFileContentExtension=='jpg' 
    || selectedFileContentExtension=='jpeg' ||selectedFileContentExtension=='svg'">
        <img [src]="defaultFileContent" alt="" srcset="" style="height: 20em;">
    </div>
    <div class="media-div" *ngIf="selectedFileContentExtension=='pdf' ">
        <img src="../../../../assets/wa-img/pdf.png" alt="" srcset="" style="height: 20em;">
    </div>
    <div class="media-div" *ngIf="selectedFileContentExtension=='doc' || selectedFileContentExtension=='docx' || selectedFileContentExtension=='json' ">
        <img src="../../../../assets/wa-img/doc.png" alt="" srcset="" style="height: 20em;">
    </div>
    <div class="media-div" *ngIf="selectedFileContentExtension == 'csv' ||
    selectedFileContentExtension == 'xls' ||
    selectedFileContentExtension == 'ods' ||
    selectedFileContentExtension == 'xlsx' ">
        <img src="../../../../assets/wa-img/sheets.png" alt="" srcset="" style="height: 20em;">
    </div>
    <div class="media-div" *ngIf="selectedFileContentExtension=='mp3'">
        <img src="../../../../assets/wa-img/audio.png" alt="" srcset="" style="height: 20em;">
    </div>
    <div class="media-div" *ngIf="selectedFileContentExtension=='mp4'">
        <img src="../../../../assets/wa-img/video.png" alt="" srcset="" style="height: 20em;">
    </div>
    <div class="media-div" *ngIf="selectedFileContentExtension=='txt'">
        <img src="./../../../assets/wa-img/txt.png" alt="" srcset="" style="height: 20em;">
    </div>
    <div class="fileName">
        {{selectedFileContentName}}
    </div>
    <hr>
    <div class="caption-div">
        <div style="width: 90%;">
            <textarea name="message" id="message" placeholder="Type a message" cols="30" rows="5" class="input-style"
                [(ngModel)]="caption"></textarea>
        </div>
        <button *ngIf="isLoading" class="send-btn">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </button>
        <button *ngIf="!isLoading" class="send-btn" (click)="sendMessage()">Send</button>
    </div>
</div>