<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div *ngIf="!isLoading" class="container-fluid main-content" id="container-wrapper">
    <div class="row mb-3">

        <div class="col-md-12 heading-text" style="justify-content: space-between;display: flex;align-items: baseline;">
            <div class="dashboard-div" style="font-size:1.4rem">
                Referral Dashboard
            </div>
            <div>
                <div style="margin-right: 2rem;display: inline;">

                    <mat-form-field appearance="outline">
                        <mat-label>Pick a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" [value]="startDate"
                                (dateChange)="dateChanged( 'start',$event)">
                            <input matEndDate placeholder="End date" [value]="endDate"
                                (dateChange)="dateChanged( 'end',$event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker" (click)="setDateNull()">
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <!-- <select id="type" name="type" class="input-style"
                    style="height: 3.2rem;width:15rem;font-size: 1.1rem;;border-radius: 5px;padding-left: 0.5rem;border: solid #dcd5d5 1px;"
                    (change)="changeDuration($event.target)" [(ngModel)]="selectedDuration">
                    <option *ngFor="let duration of listOfDuration" [value]="duration">{{duration}}
                    </option> 
                </select> -->
            </div>

        </div>

        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Total App Installed
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{totalAppInstalledCount}}
                            </div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/user.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Earnings (Annual) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Total MMTC Course Purchased
                                <!-- ({{selectedDuration}}) -->
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{mmtcCourseCount}}</div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/renew.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- New User Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Total Wallet Points By User
                                <!-- ({{selectedDuration}}) -->
                            </div>
                            <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                &#8377;{{totalWalletAmountUser}}
                            </div>

                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/money.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pending Requests Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1" style="font-size: 0.8rem;">
                                Total Income Generated 
                                <!-- ({{selectedDuration}}) -->
                            </div>
                            <div *ngIf="mmtcCourseCount!=0" class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{'\u20b9'+(coursePrice*mmtcCourseCount).toFixed(2)}} 
                            </div>
                            <div *ngIf="mmtcCourseCount==0" class="h5 mb-0 font-weight-bold text-gray-800" style="font-size: 1.5rem;">
                                {{'\u20b90'}} 
                             </div>
                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <img style="height: 3rem;" class="svg-style-selected" src="../assets/img/money.png" alt=""
                                srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Booking Table -->
        <div class="col-xl-10 col-lg-10 mb-4">
            <div class="card">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 *ngIf="listOfUser.length==0" class="m-0 font-weight-bold text-primary">Referrals
                    </h4>
                    <h4 *ngIf="listOfUser.length!=0" class="m-0 font-weight-w600 text-primary" style="font-size: 1.2rem;font-weight: 600;">
                        {{listOfUser.length}} Referrals</h4>
                    <button style="background-color: #0152ba;color: white;border: none;border-radius: 5px;padding: 6px 10px;" (click)="downloadReferralReport()" >Download Report</button>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th style="text-align: center;font-size: 13px;vertical-align: middle;">Name</th>
                                <th style="text-align: center;font-size: 13px;vertical-align: middle;">Email</th>
                                <th style="text-align: center;font-size: 13px;vertical-align: middle;">Phone</th>
                                <th style="text-align: center;font-size: 13px;vertical-align: middle;">Total Referred</th>
                                <th style="text-align: center;font-size: 13px;vertical-align: middle;">Amount Earned</th>
                                <th style="text-align: center;font-size: 13px;vertical-align: middle;">View Details</th>
                                <th style="text-align: center;font-size: 13px;vertical-align: middle;">App Installation Date</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listOfUser.length==0">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                    style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                                    User Not Found!
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="listOfUser.length>0">
                            <tr *ngFor="let user of listOfUser">
                                <td style="text-align: center;font-size: 13px;">{{user['referredBy']['fullName']}}</td>
                                <td style="text-align: center;font-size: 13px;">{{user['referredBy']['email']}}</td>
                                <td style="text-align: center;font-size: 13px;">{{user['referredBy']['phone']}}</td>
                                <td style="text-align: center;font-size: 13px;">{{user['count']}}</td>
                                <td style="text-align: center;font-size: 13px;">&#8377;{{user['totalAmount']}}</td>
                                <td style="text-align: center;font-size: 13px;">
                                    <img (click)="viewReferral(user)"
                                        style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../../assets/img/view.png'>
                                </td>
                                <td style="text-align: center;font-size: 13px;">{{user['referredBy']['createdAt']|date}}</td>

                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="card-footer"></div>
            </div>
        </div>

        <!-- Pie Chart -->
        <!-- <div class="col-xl-4 col-lg-5">
            <div class="card mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 *ngIf="listOfBooks.length==0" class="m-0 font-weight-bold text-primary">Top Selling Books</h4>
                    <h4 *ngIf="listOfBooks.length!=0" class="m-0 font-weight-bold text-primary">Top
                        {{listOfBooks.length}} Selling Books</h4>
                </div>
                <div class="card-body">

                    <div *ngIf="listOfBooks.length==0">
                        <div
                            style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                            No Books sold.
                        </div>
                    </div>
                    <div *ngIf="listOfBooks.length>0">

                        <div class="mb-3" *ngFor="let books of listOfBooks">
                            <div class="small text-gray-500"
                                style="    font-size: .95rem; font-weight: 600; margin-bottom: 0.3rem;">
                                {{books['title']}} ({{books['category']}})
                                <div class="small float-right"><b>{{books['totalCount']}} of {{bookCount}} Sold
                                    </b></div>
                            </div>
                            <div class="progress" style="height: 12px;">
                                <div class="bg-success" class="progress-bar"
                                    style="    font-size: .95rem; font-weight: 600;" role="progressbar"
                                    [ngStyle]="{'width.%': (books['totalCount']/bookCount)*100}">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div> -->

     
    </div>




</div>