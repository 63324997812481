import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss']
})
export class AudioRecorderComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  caption: string = '';


  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = false;
  isMessageSendLoading: boolean = false;
  showCaptionField: boolean = true;

  userDetail: any;
  selectedFileContent: any = [];
  selectedFileContent1st: any = null;
  selectedFileContentName: any = null;
  selectedFileContentExtension: any = null;
  defaultFileContent: any;
  //Lets declare Record OBJ
  record: any;
  //Will use this flag for toggeling recording
  recording = false;
  //URL of Blob
  url: any;
  blob: any;
  error: any;
  constructor(
    public dialogRef: MatDialogRef<AudioRecorderComponent>, public socketService: SocketioService, private domSanitizer: DomSanitizer, private toastrService: ToastrService,
    public service: AppService,
    public appService: AppService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  initiateRecording() {
    this.url = null;
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  successCallback(stream: any) {
    var options: any = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      // sampleRate: 96000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }
  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }
  processRecording(blob: Blob | MediaSource) {
    this.blob = blob;
    this.url = URL.createObjectURL(blob);
    console.log("blob", blob);
    console.log("url", this.url);
  }
  errorCallback(error: any) {
    this.error = 'Can not play audio in your browser';
  }
  async sendMessage() {
    this.isMessageSendLoading = true;

    if (this.recording) {
      return false;
    }
    let contentType = 'Audio';

    if (this.blob != null) {
      this.formdata.set('url', this.blob);
    }
    let content: any = {
      type: contentType,
      docName: `${new Date().getTime()}.wav`,
      text: this.caption
    };
    let match = this.caption.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    if (match != null && match != undefined && match.length > 0) {
      this.formdata.set('isLink', 'true');
    }
    this.formdata.set('sentBy', 'Admin');

    this.formdata.set('community', this.socketService.selectedCommunity['_id']);
    this.formdata.set('content', JSON.stringify(content));

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.sendMessageFromAdminEnd}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isMessageSendLoading = false;
                this.dialogRef.close();
                this.caption = '';
              } else {
                this.isMessageSendLoading = false;
                this.showWarningToaster('Unable to send message.', 'Warning');
              }
            });
          }
        })
        .catch((error) => {
          this.isMessageSendLoading = false;
          this.showWarningToaster('Unable to send message.', 'Warning');
          console.log('error', error);
        });

      return;
    } catch (error) {
      this.isMessageSendLoading = false;
      this.showWarningToaster('Unable to send message.', 'Warning');
    }
    return;
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.warning(message, title, {
      titleClass: "titleClass",
      messageClass: "messageClass"
    },);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.success(message, title, {
      titleClass: "titleClass",
      messageClass: "messageClass"
    },);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.error(message, title, {
      titleClass: "titleClass",
      messageClass: "messageClass"
    },);
  }
}
