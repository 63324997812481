import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ExcelService } from '../../excel.service';

@Component({
  selector: 'app-interested-user-report-dialog',
  templateUrl: './interested-user-report-dialog.component.html',
  styleUrls: ['./interested-user-report-dialog.component.scss']
})
export class InterestedUserReportDialogComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;

  fileNameControl = new FormControl('', [Validators.required]);


  generateReportForm: FormGroup = new FormGroup({
    fileName: this.fileNameControl,

  });
  userDetails: any;
  httpHeaders: any;


  listOfInterestedUser: any = [];
  paidCourseIds: any = [];

  constructor(
    public dialogRef: MatDialogRef<InterestedUserReportDialogComponent>,
    private toastr: ToastrService,
    public appService: AppService, private excelService: ExcelService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
  }

  ngOnInit(): void {
    this.generateReportForm = this.formBuilder.group({
      fileName: this.fileNameControl,
    });
    this.fetchWebinarsInterestedUserForAdmin();
  }
  async fetchWebinarsInterestedUserForAdmin() {
    this.isLoading = true;
    try {
      const param = {
        "webinarId": this.data['webinar']
      };
      this.listOfInterestedUser = [];
      const URL = `${webApi.domain + webApi.endPoint.fetchWebinarsInterestedUserForAdmin}`;
      await this.appService
        .postMethodWithToken(param, URL, this.httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.paidCourseIds = data['courseIds'];
            data['result'].forEach((element: any) => {
              this.listOfInterestedUser.push({ user: element['user'], isAttended: element['isAttended'] });
            });
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
          // console.log(this.listOfInterestedUser);
        });
    } catch (error) {
      this.listOfInterestedUser = [];
      this.isLoading = false;
    }
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  generateReport() {
    let formatedDataForExcel1: any = [];

    this.listOfInterestedUser.forEach((data: any, index: number) => {
      let isPaid = false;
      this.paidCourseIds.forEach((element: any) => {
        if (data['user'] != null && data['user'] !== undefined) {

          if (data['user']["enrolledCourse"] != null && data['user']["enrolledCourse"] != undefined) {
            data['user']["enrolledCourse"].forEach((course: any) => {
              if (course == element) {
                isPaid = true;
              }
            });
          }
        }
      });
      if (data['user'] != null && data['user'] !== undefined) {
        formatedDataForExcel1.push({
          'Sr. No': index + 1,
          'User Phone': data['user']["phone"],
          'User Name': data['user']["fullName"],
          'Email': data['user']["email"],
          'Attended Webinar': data["isAttended"],
          'Has Paid Course?': isPaid
        });
      }
    });


    this.excelService.exportAsExcelFile(formatedDataForExcel1, this.generateReportForm.get('fileName')?.value,);
    this.showSuccessToaster(
      'Report generated successfully',
      'Success'
    );
    this.close();
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarninigToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
