import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { AddNewFieldComponent } from '../add-new-field/add-new-field.component';

@Component({
  selector: 'app-batch-detail',
  templateUrl: './batch-detail.component.html',
  styleUrls: ['./batch-detail.component.scss']
})
export class BatchDetailComponent implements OnInit {
  @Input() batchId: any;
  @Output() moveToActivity: EventEmitter<any> = new EventEmitter();

  listOfModule: any = ['Hllo'];


  listOfAuthor: any = [];
  listOfCourse: any = [];

  listOfFeature: any = [];
  listOfAdvantage: any = [];
  listOfReviewVideo: any = [];

  myHeaders = new Headers();
  formdata = new FormData();
  url: any;
  format: any;
  isAddindBatch: boolean = false;
  isAddindSuccessFully: boolean = false;
  isLoading: boolean = true;
  defaultCoverImage =
    'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';
  batchDetail: any;

  userDetails: any;
  httpHeaders: any;

  titleFormControl = new FormControl('', [Validators.required]);
  batchNameFormControl = new FormControl('', [Validators.required]);
  courseFormControl = new FormControl('Select Course', [Validators.required]);
  authorFormControl = new FormControl('Select Author', [Validators.required]);
  userTypeFormControl = new FormControl('Select User Type', [Validators.required]);
  languageFormControl = new FormControl('Select Language', [Validators.required]);
  listingPriceFormControl = new FormControl('', [Validators.required]);
  sellingPriceFormControl = new FormControl('', [Validators.required]);

  totalHoursFormControl = new FormControl('', [Validators.required]);
  totalLiveSessionsFormControl = new FormControl('', [Validators.required]);
  totalModulesFormControl = new FormControl('', [Validators.required]);
  totalBacktestedStrategiesFormControl = new FormControl('', [Validators.required]);
  batchValidityFormControl = new FormControl('', [Validators.required]);
  assignmentAndQuizFormControl = new FormControl(false, [Validators.required]);


  addModule: boolean = false;

  featureFormArray!: FormArray;
  advantageFormArray!: FormArray;
  reviewVideoFormArray!: FormArray;

  // Video
  selectedVideo: any = null;
  selectedVideoName: any = null;
  defaultVideoUrl: any;

  // Image For Video Thumbnail
  selectedVideoThumbnail: any = null;
  selectedVideoThumbnailName: any = null;
  defaultVideoThumbnailUrl: any;

  // Live Session Demo
  selectedLiveSessionDemo: any = null;
  selectedLiveSessionDemoName: any = null;
  defaultLiveSessionDemoUrl: any;

  batchAddEditForm: FormGroup = new FormGroup({
    title: this.titleFormControl,
    batchName: this.batchNameFormControl,
    course: this.courseFormControl,
    author: this.authorFormControl,
    userType: this.userTypeFormControl,
    language: this.languageFormControl,
    listingPrice: this.listingPriceFormControl,
    sellingPrice: this.sellingPriceFormControl,
    totalHours: this.totalHoursFormControl,
    totalLiveSessions: this.totalLiveSessionsFormControl,
    totalModules: this.totalModulesFormControl,
    totalBacktestedStrategies: this.totalBacktestedStrategiesFormControl,
    batchValidity: this.batchValidityFormControl,
    assignmentAndQuiz: this.assignmentAndQuizFormControl,
  });
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.batchAddEditForm = this.formBuilder.group({
      title: this.titleFormControl,
      batchName: this.batchNameFormControl,
      course: this.courseFormControl,
      author: this.authorFormControl,
      userType: this.userTypeFormControl,
      language: this.languageFormControl,
      listingPrice: this.listingPriceFormControl,
      sellingPrice: this.sellingPriceFormControl,
      totalHours: this.totalHoursFormControl,
      totalLiveSessions: this.totalLiveSessionsFormControl,
      totalModules: this.totalModulesFormControl,
      totalBacktestedStrategies: this.totalBacktestedStrategiesFormControl,
      batchValidity: this.batchValidityFormControl,
      assignmentAndQuiz: this.assignmentAndQuizFormControl,
    });
    if (this.batchId != null) {
      this.fetchBatchWithId();
    } else {
      this.fetchAllCourse()
    }
  }

  async fetchBatchWithId() {
    this.isLoading = true;
    try {
      let URL = `${webApi.domain + webApi.endPoint.fetchBatchWithId + `/${this.batchId}`}`;

      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.batchDetail = data['result'];
          this.as.batchDetail=this.batchDetail;
        }
        this.fetchAllCourse();
      });
    } catch (error) {
      this.fetchAllCourse();
    }
  }
  async fetchAllCourse() {
    this.isLoading = true;
    try {
      this.listOfCourse = [];

      const URL = `${webApi.domain + webApi.endPoint.fetchAllCourse}`;
      await this.as.fetchDetail({ courseId: '0', }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
        }
        this.fetchAllAuthor();
      });
    } catch (error) {
      this.fetchAllAuthor();
    }
  }
  async fetchAllAuthor() {
    this.isLoading = true;
    try {
      this.listOfAuthor = [];

      const URL = `${webApi.domain + webApi.endPoint.fetchAuthors}`;
      await this.as.fetchDetailViaGet(URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfAuthor = data['result'];
        }
        this.setValuesForEdit();
      });
    } catch (error) {
      this.setValuesForEdit();
    }
  }
  setValuesForEdit() {
    if (this.batchDetail != null && this.batchDetail != undefined) {
      this.batchAddEditForm.get('title')?.setValue(this.batchDetail['title']);
      this.batchAddEditForm.get('batchName')?.setValue(this.batchDetail['batchName']);
      this.batchAddEditForm.get('course')?.setValue(this.batchDetail['course']['_id']);
      this.batchAddEditForm.get('author')?.setValue(this.batchDetail['author']['_id']);
      this.batchAddEditForm.get('userType')?.setValue(this.batchDetail['userType']);
      this.batchAddEditForm.get('language')?.setValue(this.batchDetail['language']);
      this.batchAddEditForm.get('listingPrice')?.setValue(this.batchDetail['listingPrice']);
      this.batchAddEditForm.get('sellingPrice')?.setValue(this.batchDetail['sellingPrice']);

      this.batchAddEditForm.get('totalHours')?.setValue(this.batchDetail['totalHours']);
      this.batchAddEditForm.get('totalLiveSessions')?.setValue(this.batchDetail['totalLiveSessions']);
      this.batchAddEditForm.get('totalModules')?.setValue(this.batchDetail['totalModules']);
      this.batchAddEditForm.get('totalBacktestedStrategies')?.setValue(this.batchDetail['totalBacktestedStrategies']);
      this.batchAddEditForm.get('batchValidity')?.setValue(this.batchDetail['batchValidity']);
      this.batchAddEditForm.get('assignmentAndQuiz')?.setValue(this.batchDetail['assignmentAndQuiz']);

      if (this.batchDetail['advantages'] != null && this.batchDetail['advantages'] != undefined && this.batchDetail['advantages'].length > 0) {
        this.batchDetail['advantages'].forEach((adv: any) => {
          this.listOfAdvantage.push({ title: adv['title'], url: adv['icon'], icon: adv['icon'] });
        });
      }
      if (this.batchDetail['review'] != null && this.batchDetail['review'] != undefined && this.batchDetail['review'].length > 0) {
        this.batchDetail['review'].forEach((adv: any) => {
          this.listOfReviewVideo.push({
            batchYear: adv['year'] ?? '2023',
            batchMonth: `${adv['month']}`, batchRating: parseInt(adv['rating']).toString(), url: adv['thumbnail'], videoLink: adv['videoLink'], thumbnail: adv['thumbnail']
          });
        });
      }
      if (this.batchDetail['image'] != null && this.batchDetail['image'] != undefined) {
        this.defaultVideoThumbnailUrl = this.batchDetail['image'];
        this.selectedVideoThumbnailName = this.getFileName(this.batchDetail['image']);
      }
      if (this.batchDetail['previewVideo'] != null && this.batchDetail['previewVideo'] != undefined) {
        this.defaultVideoUrl = '../../../../../assets/img/video.png';
        this.selectedVideoName = this.getFileName(this.batchDetail['previewVideo']);
      }
      if (this.batchDetail['demoVideoLink'] != null && this.batchDetail['demoVideoLink'] != undefined) {
        this.defaultLiveSessionDemoUrl = '../../../../../assets/img/video.png';
        this.selectedLiveSessionDemoName = this.getFileName(this.batchDetail['demoVideoLink']);
      }
      this.defaultCoverImage =
        this.batchDetail['courseImageUrl'] != null
          ? this.batchDetail['courseImageUrl']
          : 'https://summer.pes.edu/wp-content/uploads/2019/02/default-2.jpg';

    }

    this.isLoading = false;
  }

  getFileName(url: string) {
    let arrayurl = url.split('/');
    return arrayurl[arrayurl.length - 1];
  }
  removeVideo() {
    this.selectedVideo = null;
    this.selectedVideoName = null;
    this.defaultVideoUrl = '';
  }
  selectVideoFile(event: any) {
    this.selectedVideo = event.target.files[0];
    this.selectedVideoName = this.selectedVideo['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultVideoUrl = '../../../../../assets/img/video.png';
    };
    reader.readAsDataURL(this.selectedVideo);

  }
  removeVideoThumbnail() {
    this.selectedVideoThumbnail = null;
    this.selectedVideoThumbnailName = null;
    this.defaultVideoThumbnailUrl = '';
  }
  selectVideoThumbnailFile(event: any) {
    this.selectedVideoThumbnail = event.target.files[0];
    this.selectedVideoThumbnailName = this.selectedVideoThumbnail['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultVideoThumbnailUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedVideoThumbnail);

  }
  removeLiveSessionDemo() {
    this.selectedLiveSessionDemo = null;
    this.selectedLiveSessionDemoName = null;
    this.defaultLiveSessionDemoUrl = '';
  }

  selectLiveSessionDemoFile(event: any) {
    this.selectedLiveSessionDemo = event.target.files[0];
    this.selectedLiveSessionDemoName = this.selectedLiveSessionDemo['name'];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.defaultLiveSessionDemoUrl = '../../../../../assets/img/video.png';
    };
    reader.readAsDataURL(this.selectedLiveSessionDemo);

  }

  // Start Multiple Fields

  addNewFeature() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Feature' };
    dialogConfig.width = '50vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '60vh';
    let dialogRef = this.matDialog.open(AddNewFieldComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        console.log(res);
        this.listOfFeature.push(res);
      }
    });
  }
  deleteFeature(i: number) {
    this.listOfFeature.splice(i, 1);
  }
  // **
  addNewAdvantage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Advantage' };
    dialogConfig.width = '50vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '60vh';
    let dialogRef = this.matDialog.open(AddNewFieldComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        console.log(res);
        this.listOfAdvantage.push(res);
      }
    });
  }
  deleteAdvantage(i: number) {
    this.listOfAdvantage.splice(i, 1);
  }
  // ** 
  addNewReviewVideo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Review Video' };
    dialogConfig.width = '50vw';
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '60vh';
    let dialogRef = this.matDialog.open(AddNewFieldComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        console.log(res);
        this.listOfReviewVideo.push(res);
      }
    });
  }
  deleteReviewVideo(i: number) {
    this.listOfReviewVideo.splice(i, 1);
  }

  // End Multiple Fields

  getAuthorName(id: any): string {
    let isExists = false;
    let authorName = '';
    this.listOfAuthor.forEach((author: any) => {
      if (author['_id'] == id) {
        isExists = true;
        authorName = author['authorName'];
      }
    });
    if (!isExists) {
      authorName = '';
    }
    return authorName;
  }
  async createEditCourse() {
    this.isAddindBatch = true;
    try {


      this.formdata = new FormData();
      if (this.batchId != null && this.batchId != undefined) {
        this.formdata.set('batchId', this.batchDetail['_id']);
      } else {
        this.formdata.set('batchId', '0');
      }

      if (this.selectedVideo == null || this.selectedVideo == undefined) {
        if (this.batchDetail != null && this.batchDetail != undefined) {
          this.formdata.set('previewVideo', this.batchDetail['previewVideo']);
        }
      } else {
        this.formdata.set('previewVideo', this.selectedVideo);
      }

      if (this.selectedVideoThumbnail == null || this.selectedVideoThumbnail == undefined) {
        if (this.batchDetail != null && this.batchDetail != undefined) {
          this.formdata.set('image', this.batchDetail['image']);
        }
      } else {
        this.formdata.set('image', this.selectedVideoThumbnail);
      }

      if (this.selectedLiveSessionDemo == null || this.selectedLiveSessionDemo == undefined) {
        if (this.batchDetail != null && this.batchDetail != undefined) {
          this.formdata.set('demoVideoLink', this.batchDetail['demoVideoLink']);
        }
      } else {
        this.formdata.set('demoVideoLink', this.selectedLiveSessionDemo);
      }

      this.formdata.set('title', this.batchAddEditForm.get('title')?.value);
      this.formdata.set('batchName', this.batchAddEditForm.get('batchName')?.value);
      this.formdata.set('course', this.batchAddEditForm.get('course')?.value);
      this.formdata.set('author', this.batchAddEditForm.get('author')?.value);
      this.formdata.set('userType', this.batchAddEditForm.get('userType')?.value);
      this.formdata.set('language', this.batchAddEditForm.get('language')?.value);
      this.formdata.set('listingPrice', this.batchAddEditForm.get('listingPrice')?.value);
      this.formdata.set('sellingPrice', this.batchAddEditForm.get('sellingPrice')?.value);

      this.formdata.set('totalHours', this.batchAddEditForm.get('totalHours')?.value);
      this.formdata.set('totalLiveSessions', this.batchAddEditForm.get('totalLiveSessions')?.value);
      this.formdata.set('totalModules', this.batchAddEditForm.get('totalModules')?.value);
      this.formdata.set('totalBacktestedStrategies', this.batchAddEditForm.get('totalBacktestedStrategies')?.value);
      this.formdata.set('batchValidity', this.batchAddEditForm.get('batchValidity')?.value);
      this.formdata.set('assignmentAndQuiz', this.batchAddEditForm.get('assignmentAndQuiz')?.value);

      let advantages: any = [];
      this.listOfAdvantage.forEach((adv: any, i: number) => {
        advantages.push({ title: adv['title'], idx: `${i}_advantage_icon`, icon: adv['icon'] });
        if (adv['icon'] != null && adv['icon'] != undefined) {
          this.formdata.set(`${i}_advantage_icon`, adv['icon']);
        }
      });
      this.formdata.set('advantages', JSON.stringify(advantages));

      let reviews: any = [];
      this.listOfReviewVideo.forEach((adv: any, i: number) => {
        reviews.push({ thumbnail: adv['thumbnail'], videoLink: adv['videoLink'], idxVideo: `${i}_review_video`, idxThumbnail: `${i}_review_thumbnail`, year: adv['batchYear'], month: adv['batchMonth'], rating: parseInt(adv['batchRating']) });
        if (adv['video'] != null && adv['video'] != undefined) {
          this.formdata.set(`${i}_review_video`, adv['video']);
        }
        if (adv['videoThumbnail'] != null && adv['videoThumbnail'] != undefined) {
          this.formdata.set(`${i}_review_thumbnail`, adv['videoThumbnail']);
        }

      });
      this.formdata.set('reviews', JSON.stringify(reviews));

      this.myHeaders.append(
        'Authorization',
        `Bearer ${this.userDetails['accessToken']}`
      );

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };

      const URL = `${webApi.domain + webApi.endPoint.addEditBatch}`;

      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            this.isAddindBatch = false;
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.batchDetail = res['result'];
                this.batchId = res['result']['_id'];
                this.as.batchDetail=this.batchDetail;
                this.myHeaders = new Headers();
                this.isAddindSuccessFully = true;
                if (this.batchId != null && this.batchId != undefined) {
                  this.showSuccessToaster(
                    'Batch Updated Successfully',
                    'Success'
                  );
                } else {
                  this.showSuccessToaster(
                    'Batch Created Successfully',
                    'Success'
                  );
                }
              } else {
                this.myHeaders = new Headers();
                if (this.batchId != null && this.batchId != undefined) {
                  this.showWarningToaster(
                    'Unable to update batch. Please try again.',
                    'Warning'
                  );
                } else {
                  this.showWarningToaster(
                    'Unable to create batch. Please try again.',
                    'Warning'
                  );
                }
              }
            });
          }
        })
        .catch((error) => console.log('error', error));
      return;
    } catch (error) {
      this.isAddindBatch = false;
      if (this.batchId != null && this.batchId != undefined) {
        this.showWarningToaster(
          'Unable to update batch. Please try again.',
          'Warning'
        );
      } else {
        this.showWarningToaster(
          'Unable to create batch. Please try again.',
          'Warning'
        );
      }
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  gotoModules() {
    this.moveToActivity.emit(false);
  }
  getStar(val: any) {
    val = parseInt(val);
    let dt = '';
    switch (val) {
      case 1:
        dt = '⭐';
        break;
      case 2:
        dt = '⭐⭐';
        break;
      case 3:
        dt = '⭐⭐⭐';
        break;
      case 4:
        dt = '⭐⭐⭐⭐';
        break;
      default:
        dt = '⭐⭐⭐⭐⭐';
        break;
    }
    return dt;
  }
  getMonth(str: string) {
    let val = 0;
    switch (str) {
      case 'January':
        val = 1;
        break;
      case 'February':
        val = 2;
        break;
      case 'March':
        val = 3;
        break;
      case 'April':
        val = 4;
        break;
      case 'Mat':
        val = 5;
        break;
      case 'June':
        val = 6;
        break;
      case 'July':
        val = 7;
        break;
      case 'August':
        val = 8;
        break;
      case 'September':
        val = 9;
        break;
      case 'October':
        val = 10;
        break;
      case 'November':
        val = 11;
        break;

      default:
        val = 12;
        break;
    }
    return val;
  }
}
