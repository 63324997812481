import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss']
})
export class MediaDialogComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  caption: string = '';


  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = false;
  isMessageSendLoading: boolean = false;
  showCaptionField: boolean = true;

  userDetail: any;
  selectedFileContent: any = [];
  selectedFileContent1st: any = null;
  selectedFileContentName: any = null;
  selectedFileContentExtension: any = null;
  defaultFileContent: any;

  constructor(
    public dialogRef: MatDialogRef<MediaDialogComponent>, private toastrService: ToastrService, public socketService: SocketioService,
    public service: AppService,
    public appService: AppService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }
  ngOnInit(): void {
    this.displayAttachedFile();
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  displayAttachedFile() {
    this.selectedFileContent1st = this.data.source['doc'];
    console.log(this.selectedFileContent1st);
    console.log(this.defaultFileContent)
    this.selectedFileContentName = this.selectedFileContent1st['name'];
    this.selectedFileContentExtension = String(this.selectedFileContent1st['name']).split('.')[String(this.selectedFileContent1st['name']).split('.').length - 1];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultFileContent = event1.target.result
      // console.log(this.defaultFileContent);
    }
    reader.readAsDataURL(this.selectedFileContent1st);

  }

  async sendMessage() {
    this.isMessageSendLoading = true;

    if (this.caption == '') {
      // return false;
    }
    let contentType = 'Image';
    if (this.selectedFileContentExtension == 'mp3') {
      contentType = 'Audio';
    } else if (this.selectedFileContentExtension == 'mp4') {
      contentType = 'Video';
    } else if (this.selectedFileContentExtension == "doc" ||
      this.selectedFileContentExtension == "pdf" ||
      this.selectedFileContentExtension == "docx" ||
      this.selectedFileContentExtension == "csv" ||
      this.selectedFileContentExtension == "xls" ||
      this.selectedFileContentExtension == "ods" ||
      this.selectedFileContentExtension == "xlsx") {
      contentType = 'Document';
    }
    if (this.selectedFileContent1st != null) {
      this.formdata.set('url', this.selectedFileContent1st);
    }
    let content: any = {
      type: contentType,
      docName: this.selectedFileContentName ?? '',
      text: this.caption
    };
    let match = this.caption.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    if (match != null && match != undefined && match.length > 0) {
      this.formdata.set('isLink', 'true');
    }
    this.formdata.set('sentBy', 'Admin');

    this.formdata.set('community', this.socketService.selectedCommunity['_id']);
    this.formdata.set('content', JSON.stringify(content));
    if (this.data['source']['contextMessage'] != null && this.data['source']['contextMessage'] != undefined) {
      this.formdata.set('contextMessage', this.data['source']['contextMessage']);
    }
    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetail['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.sendMessageFromAdminEnd}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isMessageSendLoading = false;
                this.dialogRef.close();
                this.closeActivityAtDialog.emit({ success: true });
                this.caption = '';
              } else {
                this.isMessageSendLoading = false;
                this.showWarningToaster('Unable to send message.', 'Warning');
              }
            });
          }
        })
        .catch((error) => {
          this.showWarningToaster('Unable to send message.', 'Warning');
          this.isMessageSendLoading = false;
          console.log('error', error);
        });

      return;
    } catch (error) {
      this.showWarningToaster('Unable to send message.', 'Warning');
      this.isMessageSendLoading = false;
    }
    return;
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.warning(message, title, {
      titleClass: "titleClass",
      messageClass: "messageClass"
    },);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.success(message, title, {
      titleClass: "titleClass",
      messageClass: "messageClass"
    },);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastrService.error(message, title, {
      titleClass: "titleClass",
      messageClass: "messageClass"
    },);
  }

}
