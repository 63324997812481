export const webApi = {
  // domain: 'http://localhost:7046', // development
  // domain: 'https://mmtctestapi.nextlabsonline.com',//development
  domain: 'https://apiv2.tusharghone.com', //production

  endPoint: {
    // Dashboard APIs
    getOnlyFunnelCount: '/api/dashboard/getOnlyFunnelCount',
    getAppInstallData: '/api/dashboard/getAppInstallData',
    getSingnUpData: '/api/dashboard/getSingnUpData',
    getProfilingData: '/api/dashboard/getProfilingData',
    getNumberOfAppOpenAttemptData: '/api/dashboard/getNumberOfAppOpenAttemptData',
    getActivityCompletedData: '/api/dashboard/getActivityCompletedData',
    getQuizData: '/api/dashboard/getQuizData',
    getCoursePurchaseData: '/api/dashboard/getCoursePurchaseData',

    // Admin APIs
    fetchCourseAdmin: '/api/admin/course/fetchCourse',
    fetchActivityAdmin: '/api/admin/course/fetchActivity',
    fetchModuleAdmin: '/api/admin/course/fetchModule',
    fetchQuizAdmin: '/api/admin/course/fetchQuiz',
    fetchCouponAdmin: '/api/admin/course/fetchCoupon',
    fetchAdmin: '/api/adminAuthenticate/fetchAdmin',
    updateActiveOrDeleteStatusAdmin:
      '/api/adminAuthenticate/updateActiveOrDeleteStatusAdmin',
    fetchAllAdmins: '/api/adminAuthenticate/fetchAllAdmins',
    createAndUpdateAdmin: '/api/adminAuthenticate/createAndUpdateAdmin',

    //Campaign API
    fetchCampaigns: '/api/manualCampaign/fetchCampaigns',
    createCampaign: '/api/manualCampaign/createCampaign',

    //ADMIN NEW COURSE API
    fetchCoursesForAdmin: '/api/course/fetchCoursesForAdmin',
    activeAndDeleteCourse: '/api/course/activeAndDeleteCourse',
    fetchCourseEnroledUser: '/api/course/fetchCourseEnroledUser',
    updateUserEnrolmentStatus: '/api/course/updateUserEnrolmentStatus',
    createAndUpdateCourse: '/api/course/createAndUpdateCourse',
    fetchAuthors: '/api/author/fetchAuthors',
    fetchCoursesForWebinar: '/api/course/fetchCoursesForWebinar',
    fetchCoursesById: '/api/course/fetchCoursesById',

    fetchCourseReview: '/api/courseReview/fetchCourseReview',
    deleteCourseReview: '/api/courseReview/deleteCourseReview',
    featureReview: '/api/courseReview/featureReview',


    // Course Forum
    createForumMessage: '/api/forum/createForumMessage',
    fetchForumMainCommentWithCourseId: '/api/forum/fetchForumMainCommentWithCourseId',
    fetchForumSubComment: '/api/forum/fetchForumSubComment',
    deleteForumComment: '/api/forum/deleteForumComment',
    fetchCommentReportReason: '/api/forum/fetchCommentReportReason',

    //ADMIN NEW MODULE API
    fetchModulesByCourseId: '/api/module/fetchModulesByCourseId',
    createAndUpdateModule: '/api/module/createAndUpdateModule',
    activeAndDeleteModule: '/api/module/activeAndDeleteModule',

    //ADMIN NEW ACTIVITY API
    fetchActivityByModuleId: '/api/activity/fetchActivityByModuleId',
    createAndUpdateActivity: '/api/activity/createAndUpdateActivity',
    activeAndDeleteActivity: '/api/activity/activeAndDeleteActivity',
    updateOrInsertQuizQuestion: '/api/activity/updateOrInsertQuizQuestion',
    deleteQuestionFromQuiz: '/api/activity/deleteQuestionFromQuiz',
    deleteResourceOfActivity: '/api/activity/deleteResourceOfActivity',
    editResourcesOfActivity: '/api/activity/editResourcesOfActivity',

    //User
    // fetchUser: '/api/adminAuthenticate/fetchUser',
    isValidAdminUserExists: '/api/adminAuthenticate/isValidAdminUserExists',
    updateUser: '/api/adminAuthenticate/updateUser',
    insertUserDetail: '/api/appUser/user/insertUserDetail',
    enrollCourse: '/api/appUser/user/enrollCourse',
    fetchUserFromMySql: '/api/appUser/user/fetchUserFromMySql',

    //NEW ADMIN USER API
    fetchUserForAdmin: '/api/user/fetchUserForAdmin',
    fetchAllFilterUserForAdmin: '/api/user/fetchAllFilterUserForAdmin',
    fetchUserDateWise: '/api/user/fetchUserDateWise',
    searchUsers: '/api/user/searchUsers',
    enablePasswordLogin: '/api/adminAuthenticate/enablePasswordLogin',
    editUserDetailsFromAdmin: '/api/user/editUserDetailsFromAdmin',

    //NEW ADMIN ENROLL USER API
    findUserByEmail: '/api/enrolment/findUserByEmail',
    enrollCourseFromWeb: '/api/enrolment/enrollCourseFromWeb10kio1',
    unEnrollCourseFromWeb: '/api/enrolment/unEnrollCourseFromWeb',

    //Authetication
    insertAdmin: '/api/adminAuthenticate/insertAdmin',
    insertPaymentLog: '/api/appUser/user/insertPaymentLog',
    deleteUser: '/api/appUser/user/deleteUser',

    //NEW ADMIN QUIZ
    fetchAllCourse: '/api/admin/quiz/fetchAllCourse',
    fetchAllQuiz: '/api/admin/quiz/fetchAllQuiz',
    createAndUpdateQuiz: '/api/admin/quiz/createAndUpdateQuiz',
    deleteSingleQuiz: '/api/admin/quiz/deleteSingleQuiz',
    deleteQuiz: '/api/admin/quiz/deleteQuiz',
    updateQuizStatus: '/api/admin/quiz/updateQuizStatus',

    //OFFER
    updateCoupon: '/api/appUser/coupon/updateCoupon',
    updateCouponStatus: '/api/appUser/coupon/updateCouponStatus',
    createOffer: '/api/appUser/coupon/createCoupon',
    fetchCoupon: '/api/appUser/coupon/fetchCoupon',
    deleteCoupon: '/api/appUser/coupon/deleteCoupon',

    //OFFER NEW
    createAndUpdateCoupon: '/api/coupon/createAndUpdateCoupon',
    updateActiveOrDeleteStatusCoupon:
      '/api/coupon/updateActiveOrDeleteStatusCoupon',
    fetchAllCoupons: '/api/coupon/fetchAllCoupons',

    //FORUM
    fetchQuestion: '/api/admin/course/fetchQuestion',
    deleteQuestion: '/api/appUser/forum/askQuestion/deleteQuestion',
    actionOnQuestion: '/api/appUser/forum/askQuestion/actionOnQuestion',
    suspendUser: '/api/admin/course/suspendUser',
    fetchComment: '/api/appUser/forum/comment/fetchComment',
    deleteComment: '/api/admin/course/deleteComment',
    insertComment: '/api/admin/course/insertComment',
    insertReply: '/api/admin/course/insertReply',

    //CAMPAIGN
    createAndUpdateCampaign: '/api/campaign/createAndUpdateCampaign',
    changeCampaignStatus: '/api/campaign/changeCampaignStatus',
    getTargetCount: '/api/campaign/getTargetCount',
    fetchCampaign: '/api/campaign/fetchCampaign',
    fetchCampaignDeliveryReport: '/api/campaign/fetchCampaignDeliveryReport',

    //NOTIFICATION
    fetchUserForNotification: '/api/appUser/user/fetchUserForNotification',
    sendMailFromWeb: '/api/appUser/user/sendMailFromWeb',
    sendNotificationInApp: '/api/appUser/user/sendNotificationInApp',
    sendMessage: '/api/appUser/user/sendMessage',
    sendReceiptMail: '/api/appUser/user/sendReceiptMail',
    // sendNotification: '/api/appUser/user/sendNotification',
    sendNotificationFromAdmin: '/api/notification/sendNotificationFromAdmin',

    //HELP
    fetchHelpVideo: '/api/appUser/help/fetchHelpVideo',
    updateHelpVideo: '/api/appUser/help/updateHelpVideo',
    createHelpVideo: '/api/appUser/help/createHelpVideo',
    deleteHelpVideo: '/api/appUser/help/deleteHelpVideo',

    //HELP NEW
    createAndUpdateVideo: '/api/helpVideo/createAndUpdateVideo',
    updateActiveOrDeleteStatusVideo:
      '/api/helpVideo/updateActiveOrDeleteStatusVideo',
    fetchVideo: '/api/helpVideo/fetchVideo',

    //ACL
    updateUserAccess: '/api/adminAuthenticate/updateUserAccess',

    // Report APIs
    generateUserRegistrationReport:
      '/api/report/generateUserRegistrationReport',
    generateLastActiveReport: '/api/report/generateLastActiveReport',
    generateUserCSRReport: '/api/report/generateUserCSRReport',
    fetchAllReport: '/api/report/fetchAllReport',

    //REFERRAL
    getUserDetails: '/api/admin/course/getUserDetails',
    payUserCommission: '/api/admin/course/payUserCommission',

    //NEW REFERRAL
    fetchReferralDashboardDetails: '/api/user/fetchReferralDashboardDetails',

    //REVIEWS
    fetchAllReview: '/api/appUser/review/fetchAllReview',
    deleteReview: '/api/appUser/review/deleteReview',
    updateFeature: '/api/appUser/review/updateFeature',

    ///SEND OTP
    sendOTPtoUser: '/api/auth/sendOTPtoUser',
    verifyOTPofUser: '/api/auth/verifyOTPofUser',
    resendOTPtoUser: '/api/auth/resendOTPtoUser',

    //BANNER
    createAndUpdateBanner: '/api/banner/createAndUpdateBanner',
    updateActiveOrDeleteStatusBanner:
      '/api/banner/updateActiveOrDeleteStatusBanner',
    fetchBanner: '/api/banner/fetchBanner',

    //APPCONFIG
    fetchAppConfig: '/api/appConfig/fetchAppConfig',
    fetchAllRoutes: '/api/appConfig/fetchAllRoutes',
    updatePerformancePDF: '/api/appConfig/updatePerformancePDF',

    //WEBINAR
    updateActiveOrDeleteStatusWebinar:
      '/api/webinar/updateActiveOrDeleteStatusWebinar',
    updateZoomFileForWebinar: '/api/webinar/updateZoomFileForWebinar',
    fetchWebinarsForAdmin: '/api/webinar/fetchWebinarsForAdmin',
    fetchWebinarsInterestedUserForAdmin:
      '/api/webinar/fetchWebinarsInterestedUserForAdmin',
    createAndUpdateWebinar: '/api/webinar/createAndUpdateWebinar',
    getTargetCountForWebinar: '/api/webinar/getTargetCountForWebinar',

    //PLAN
    createAndUpdatePlan: '/api/plan/createAndUpdatePlan',
    updateActiveOrDeleteStatusPlan: '/api/plan/updateActiveOrDeleteStatusPlan',
    fetchPlans: '/api/plan/fetchPlans',

    //TRADE ADVISORY
    createAndUpdateAdvisory: '/api/tradeAdvisory/createAndUpdateAdvisory',
    updateActiveOrDeleteStatusAdvisory:
      '/api/tradeAdvisory/updateActiveOrDeleteStatusAdvisory',
    fetchAdvisoryForAdmin: '/api/tradeAdvisory/fetchAdvisoryForAdmin',
    createDynamicLinksForTrade: '/api/tradeAdvisory/createDynamicLinksForTrade',

    //TICKER
    createAndUpdateTicker: '/api/ticker/createAndUpdateTicker',
    updateActiveOrDeleteStatusTicker:
      '/api/ticker/updateActiveOrDeleteStatusTicker',
    fetchTickers: '/api/ticker/fetchTickers',

    //PAYOUT
    updatePayoutStatus: '/api/payout/updatePayoutStatus',
    fetchPayouts: '/api/payout/fetchPayouts',

    //SUBSCRIPTION
    fetchAllSubscription: '/api/subscription/fetchAllSubscription',
    fetchSubscriptionDateWise: '/api/subscription/fetchSubscriptionDateWise',
    searchSubscription: '/api/subscription/searchSubscription',
    searchFilterSubscription: '/api/subscription/searchFilterSubscription',
    fetchSubscriptionAfterFilter:
      '/api/subscription/fetchSubscriptionAfterFilter',
    enrolSubscriptionToEnroledUser:
      '/api/subscription/enrolSubscriptionToEnroledUser',
    filterSubscriptionPart1:
      '/api/subscription/filterSubscriptionPart1',
    filterSubscriptionPart2:
      '/api/subscription/filterSubscriptionPart2',
    fetchAllFilterSubscription:
      '/api/subscription/fetchAllFilterSubscription',
    fetchActiveFilterSubscription:
      '/api/subscription/fetchActiveFilterSubscription',
    fetchAllFilterSubscriptionForReport:
      '/api/subscription/fetchAllFilterSubscriptionForReport',
    fetchAllCourseDetailPart1ForReport:
      '/api/subscription/fetchAllCourseDetailPart1ForReport',
    fetchAllSubsDetailPart1ForReport:
      '/api/subscription/fetchAllSubsDetailPart1ForReport',
    fetchAllSubsDetailAboutToExpirePart1ForReport:
      '/api/subscription/fetchAllSubsDetailAboutToExpirePart1ForReport',
    fetchAllCourseDetailPart2ForReport:
      '/api/subscription/fetchAllCourseDetailPart2ForReport',
    fetchAllSubscriptionPart2ForReport:
      '/api/subscription/fetchAllSubscriptionPart2ForReport',
    fetchAllSubscriptionFreePaidPart2ForReport:
      '/api/subscription/fetchAllSubscriptionFreePaidPart2ForReport',

    // Webhook Search
    searchWebhookLogs: '/api/transaction/searchWebhookLogs',
    fetchTransactionLog: '/api/transaction/fetchTransactionLog',
    updateUTMSourceOfTransaction: '/api/transaction/updateUTMSourceOfTransaction',
    generateTransactionLogReport: '/api/transaction/generateTransactionLogReport',

    // General Info APIs
    createInfo: '/api/appGeneralInfo/createInfo',
    updateInfo: '/api/appGeneralInfo/updateInfo',
    fetchInfo: '/api/appGeneralInfo/fetchInfo',

    //Short Videos
    createAndUpdateShortVideo: '/api/shortVideos/createAndUpdateShortVideo',
    updateActiveOrDeleteStatusShortVideo:
      '/api/shortVideos/updateActiveOrDeleteStatusShortVideo',
    fetchVideoForAdmin: '/api/shortVideos/fetchVideoForAdmin',

    // Audit Log
    fetchAuditLog: '/api/auditLog/fetchAuditLog',

    //UTM
    createUTM: "/api/utm/createUTM",
    createDynamicLinksForUTM: "/api/utm/createDynamicLinksForUTM",
    fetchAllUTM: "/api/utm/fetchAllUTM",

    // LEADS

    fetchAllLead: '/api/landingpagelead/fetchAllLead',
    generateLeadReport: '/api/landingpagelead/generateLeadReport',

    //Youtube Video
    createOrUpdateVideo: '/api/youtubeVideo/createOrUpdateVideo',
    fetchAllYoutubeVideos: '/api/youtubeVideo/fetchAllYoutubeVideos',
    updateYoutubeVideoStatus: '/api/youtubeVideo/updateYoutubeVideoStatus',
    fetchAllYoutubeVideoLog: '/api/youtubeVideo/fetchAllYoutubeVideoLog',

    //Batch APIs
    addEditBatch: '/api/batches/addEditBatch',
    fetchAllBatches: '/api/batches/fetchAllBatches',
    fetchBatchWithId: '/api/batches/fetchBatchWithId',
    activeAndDeleteBatch: '/api/batches/activeAndDeleteBatch',
    fetchBatchModulesByBatchId: '/api/batches/fetchBatchModulesByBatchId',
    createOrUpdateBatchModule: '/api/batches/createOrUpdateBatchModule',
    fetchBatchActivity: '/api/batches/fetchBatchActivity',
    fetchActivityWithId: '/api/batches/fetchActivityWithId',
    createOrUpdateAssessmentActivity: '/api/batches/createOrUpdateAssessmentActivity',
    createOrUpdateNotesActivity: '/api/batches/createOrUpdateNotesActivity',
    createOrUpdatePollsActivity: '/api/batches/createOrUpdatePollsActivity',
    activeAndDeleteBatchActivity: '/api/batches/activeAndDeleteBatchActivity',
    createOrUpdateBatchAnnouncement: '/api/batches/createOrUpdateBatchAnnouncement',
    fetchBatchAnnouncements: '/api/batches/fetchBatchAnnouncements',
    updateBatchAnnouncementStatus: '/api/batches/updateBatchAnnouncementStatus',
    fetchBatchAnnouncementWithId: '/api/batches/fetchBatchAnnouncementWithId',
    activeAndDeleteBatchModule: '/api/batches/activeAndDeleteBatchModule',


    enrollBatchFromWeb: '/api/batch/enrollBatchFromWeb10kio1',
    unEnrollBatchFromWeb: '/api/batch/unEnrollBatchFromWeb',
    fetchBatchEnroledUsers: '/api/batch/fetchBatchEnroledUsers',

    // Community
    addEditCommunity: '/api/community/addEditCommunity',
    fetchAllCommunity: '/api/community/fetchAllCommunity',
    fetchCommunityWithId: '/api/community/fetchCommunityWithId',
    sendMessageFromAdminEnd: '/api/community/sendMessageFromAdminEnd',
    boadcastMessage: '/api/community/boadcastMessage',
    updateReadCount: '/api/community/updateReadCount',
    fetchAllCommunityMessageByCommunityId: '/api/community/fetchAllCommunityMessageByCommunityId',
    deleteMessage: '/api/community/deleteMessage',
    deleteCommunity: '/api/community/deleteCommunity',
    pinCommunityForAdmin: '/api/community/pinCommunityForAdmin',
    fetchReportedMessage: '/api/community/fetchReportedMessage',
    fetchAllParticipant: '/api/community/fetchAllParticipant',
    removeMemberFromCommunity: '/api/community/removeMemberFromCommunity',
    addMemberInCommunity: '/api/community/addMemberInCommunity',
    fetchAllMessageSeenUser: '/api/community/fetchAllMessageSeenUser',
    createDynamicLinksForCommunity: '/api/community/createDynamicLinksForCommunity',

    // Leaderboard
    fetchForAdminLeaderBoardUsers: '/api/leaderBoardLog/fetchForAdminLeaderBoardUsers',

  },
};

export const WA_API = {
  // domain: 'http://localhost:8040', //DEV
  domain: 'https://wawebhook.buildby.me', //PROD

  endPoint: {
    //TEMPLATES
    fetchAllTemplates: '/api/template/fetchAllTemplates',
    syncAllTemplates: '/api/template/syncAllTemplates',

    //CHATS
    fetchAllChats: '/api/chats/fetchAllChats',
    searchChat: '/api/chats/searchChat',

    //MESSAGES
    fetchMessagesByChatId: '/api/message/fetchMessagesByChatId',
    fetchMessageDetailByCampaignId:
      '/api/message/fetchMessageDetailByCampaignId',
    fetchActiveUserCountByClientId:
      '/api/message/fetchActiveUserCountByClientId',

    //Client
    getClientDetail: '/api/client/getClientDetail',

    //Transaction
    createOrder: '/api/transaction/createOrder',
    rechargeClient: '/api/transaction/rechargeClient',
    updateStatusOfClientPayment: '/api/transaction/updateStatusOfClientPayment',


  },
};


export const tgAdvisoryApi = {
  // domain: 'http://localhost:8046', // development
  domain: 'https://advisory-api.tusharghone.com', //production

  endPoint: {

    // User

    addEditUser: '/api/user/addEditUser',
    fetchAllUser: '/api/user/fetchAllUser',
    deleteUser: '/api/user/deleteUser',
    addBulkUser: '/api/user/addBulkUser',
    findUserByPhoneAndEmail: '/api/user/findUserByPhoneAndEmail',

    // Community
    addEditCommunity: '/api/community/addEditCommunity',
    fetchAllCommunity: '/api/community/fetchAllCommunity',
    fetchCommunityWithId: '/api/community/fetchCommunityWithId',
    sendMessageFromAdminEnd: '/api/community/sendMessageFromAdminEnd',
    boadcastMessage: '/api/community/boadcastMessage',
    updateReadCount: '/api/community/updateReadCount',
    fetchAllCommunityMessageByCommunityId: '/api/community/fetchAllCommunityMessageByCommunityId',
    deleteMessage: '/api/community/deleteMessage',
    deleteCommunity: '/api/community/deleteCommunity',
    pinCommunityForAdmin: '/api/community/pinCommunityForAdmin',
    fetchReportedMessage: '/api/community/fetchReportedMessage',
    fetchAllParticipant: '/api/community/fetchAllParticipant',
    removeMemberFromCommunity: '/api/community/removeMemberFromCommunity',
    addMemberInCommunity: '/api/community/addMemberInCommunity',
    fetchAllMessageSeenUser: '/api/community/fetchAllMessageSeenUser',
    createDynamicLinksForCommunity: '/api/community/createDynamicLinksForCommunity',
    
    setUnsetUserCommunity: '/api/community/setUnsetUserCommunity',
  },
};