<div class="main-container">
    <!-- Stepper Card -->
    <div class="main-card">
        <ol class="c-stepper">
            <li *ngFor="let data of listOfStepper" class="c-stepper__item" [attr.data-before]="[data['content']]"
                [ngStyle]="{'--featured-bgColor': 'lightgrey'}">
                <h3 class="c-stepper__title" (click)="null"
                    [ngStyle]="{color:selectedStepper==data['label']?'#088ada':'black'}">{{data['label']}}</h3>
                <!-- onStepperClick(data['label']) -->
            </li>
        </ol>
    </div>
    <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
    <div *ngIf="!isLoading">

        <!-- Target User Card -->
        <div *ngIf="selectedStepper=='Webinar Detail'">
            <div class="row justify-content-end">
                <div class="col-md-12" style="text-align-last: right;">
                    <button
                        [ngClass]="{'save-campaign-disabled': addCampaignDetail.invalid || addCampaignDetail.get('linkType')!.value=='Select Link Type'  ,'save-campaign-enabled': addCampaignDetail.valid && addCampaignDetail.get('linkType')!.value!='Select Link Type' }"
                        [disabled]=" addCampaignDetail.invalid  || addCampaignDetail.get('linkType')!.value=='Select Link Type' "
                        (click)=" addCampaignDetail.invalid ||addCampaignDetail.get('linkType')!.value=='Select Link Type'  ? null: gotoStepper('Target & Schedule')">Next</button>

                </div>

            </div>
        </div>
        <div *ngIf="selectedStepper=='Webinar Detail'" class="main-card">
            <div class="row">
                <div class="col-md-4">
                    <label class="heading">Webinar Detail</label>
                </div>
            </div>
            <form name="addCampaignDetail" [formGroup]="addCampaignDetail">
                <div class="row" style=" margin-bottom: 0.8rem;">
                    <div class="col-md-4">
                        <label for="name" class="label required">Webinar Name</label>
                        <input type="text" placeholder="Enter Webinar Name" id="name" formControlName="name"
                            class="input-style">
                    </div>
                    <div class="col-md-3">
                        <label for="medium" class="label required">Select Medium</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Medium'"
                            formControlName="medium" [settings]="mediumDropdownSettings" [data]="listOfMedium"
                            [(ngModel)]="selectedMedium" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>

                    </div>
                    <div class="col-md-3" *ngIf="!isLoading">
                        <label for="author" class="label required">Select Author</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Author'"
                            formControlName="author" [settings]="authorDropdownSettings" [data]="listOfAuthor"
                            [(ngModel)]="selectedAuthor" (onSelect)="onDaySelect($event)"
                            (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                        </ng-multiselect-dropdown>

                    </div>
                    <div class="col-md-2">
                        <label for="limitedSeat" class="label required">Limited Seat</label>
                        <input type="text" id="limitedSeat" formControlName="limitedSeat"
                            placeholder="Enter Limited Seat" class="input-style">
                    </div>
                </div>

                <div class="row" sty>
                    <div class="col-md-2">
                        <label for="link" class="label required">Webinar Link</label>
                        <input type="text" id="link" formControlName="link" placeholder="Enter webinar link"
                            class="input-style">
                    </div>
                    <div class="col-md-2">
                        <label for="linkType" class="label required">Select Link Type</label>
                        <select id="linkType" name="linkType" class="input-style" formControlName="linkType">
                            <option value="Select Link Type" disabled selected></option>
                            <option value="YOUTUBE">YouTube</option>
                            <option value="ZOOM">Zoom</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label for="webinarDate" class="label required">Select Date</label>
                        <input type="date" id="webinarDate" formControlName="webinarDate" class="input-style">
                    </div>
                    <div class="col-md-2">
                        <label for="lobbyTime" class="label required">Lobby Time</label>
                        <input type="time" id="lobbyTime" formControlName="lobbyTime" class="input-style">
                    </div>
                    <div class="col-md-2">
                        <label for="startTime" class="label required">Start Time</label>
                        <input type="time" id="startTime" formControlName="startTime" class="input-style" [step]="600">
                    </div>
                    <div class="col-md-2">
                        <label for="endTime" class="label required">End Time</label>
                        <input type="time" id="endTime" formControlName="endTime" class="input-style">
                    </div>
                </div>

                <div class="row" style="margin-bottom: 8em;">
                    <div class="col-md-6">
                        <label for="description" class="label required">Description</label>
                        <quill-editor class="content-editor" placeholder="" name="description"
                            formControlName="description">
                        </quill-editor>
                    </div>
                    <div class="col-md-6">
                        <label for="webinarFor" class="label required">Webinar For</label>
                        <quill-editor class="content-editor" placeholder="" name="webinarFor"
                            formControlName="webinarFor">
                        </quill-editor>
                    </div>
                </div>
                <div class="row" style="margin-top: 10em;">
                    <div class="col-md-6">
                        <label for="image" class="label">Thumbanail Image (1200 x 628)</label>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="image">
                                    <div class="proof">
                                        <img *ngIf="defaultThumbnailImage!=null && defaultThumbnailImage!=undefined"
                                            [src]="defaultThumbnailImage" class="proofImage">
                                        <label *ngIf="defaultThumbnailImage==null" class="imageLabel"> Upload
                                            <input (change)="displayThumbnail($event)" type="file" accept="image/*">
                                        </label>
                                        <label *ngIf="defaultThumbnailImage!=null && defaultThumbnailImage!=undefined"
                                            class="imageLabelEdit">
                                            <mat-icon class="matIcon">edit</mat-icon>
                                            <input (change)="displayThumbnail($event)" type="file" accept="image/*">
                                        </label>
                                        <label
                                            *ngIf="defaultThumbnailImage!=null && defaultThumbnailImage!=undefined  && defaultThumbnailImage.includes('https')"
                                            class="imageLabelDownload"
                                            (click)="downloadThumbnailImage(defaultThumbnailImage)">
                                            <mat-icon class="matIcon">download</mat-icon>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="image" class="label">PDF File</label>
                        <div>
                            <div class="d-flex">
                                <input type="text" placeholder="Choose file...." name="file-name" id="file-name"
                                    class="file-name" [(ngModel)]="selectedPdfFileName"
                                    [ngModelOptions]="{standalone: true}" readonly="readonly" accept="application/pdf">
                                <label class="btn">
                                    Select
                                    <input type="file" name="file" id="file" class="file"
                                        (change)="displayPdfFile($event)" accept="application/pdf">

                                </label>
                            </div>
                        </div>
                    </div>
                </div>


            </form>
        </div>


        <!-- Target & Schedule Card -->
        <div *ngIf="selectedStepper=='Target & Schedule'">
            <div class="row justify-content-end">
                <div class="col-md-12" style="text-align-last: right;">
                    <button class="back-button" (click)="gotoStepper('Webinar Detail')">Back</button>
                    <!-- <button
                        [ngClass]="{'save-campaign-disabled':  scheduleWebinarFormDetail.invalid  || (!afterSelected && !beforeSelected) ,'save-campaign-enabled':  scheduleWebinarFormDetail.valid && (afterSelected || beforeSelected)  }"
                        [disabled]="  scheduleWebinarFormDetail.invalid|| (!afterSelected && !beforeSelected) "
                        (click)="  scheduleWebinarFormDetail.invalid|| (!afterSelected && !beforeSelected)  ? null: gotoStepper('Content')">Next</button> -->

                    <button
                        [ngClass]="{'save-campaign-disabled': addTargetUserDetail.invalid || scheduleWebinarFormDetail.invalid  || (!afterSelected && !beforeSelected)|| addTargetUserDetail.get('targetUser')?.value=='Select User Group' ,'save-campaign-enabled': addTargetUserDetail.valid  && scheduleWebinarFormDetail.valid && (afterSelected || beforeSelected) && addTargetUserDetail.get('targetUser')?.value!='Select User Group' }"
                        [disabled]=" addTargetUserDetail.invalid || scheduleWebinarFormDetail.invalid|| (!afterSelected && !beforeSelected) || addTargetUserDetail.get('targetUser')?.value=='Select User Group'"
                        (click)=" addTargetUserDetail.invalid || scheduleWebinarFormDetail.invalid|| (!afterSelected && !beforeSelected) ||  addTargetUserDetail.get('targetUser')?.value=='Select User Group' ? null: gotoStepper('Content')">Next</button>


                </div>

            </div>
        </div>
        <div *ngIf="selectedStepper=='Target & Schedule' && !isLoading" class="main-card">
            <div class="row">
                <div class="col-md-4">
                    <label class="heading">Include Target audience</label>
                </div>
            </div>
            <form name="addTargetUserDetail" [formGroup]="addTargetUserDetail">

                <div class="row">
                    <div class="col-md-4">
                        <label for="campaignDateTime" class="label required">Select User Group</label>
                        <select id="type" name="type" class="input-style" (change)="userTargetSelected($event)"
                            formControlName="targetUser">
                            <option value="Select User Group" selected disabled>Select User Group</option>
                            <option value="All User">All User</option>
                            <option value="Course">Course</option>
                            <option value="Subscribed User">Subscribed User</option>

                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4" *ngIf="selectedUserTarget=='Course'">
                        <label for="course" class="label required">{{getSelectTitle(selectedUserTarget)}}</label>
                        <div style="width: 80%;">
                            <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Course'"
                                [settings]=" courseDropdownSettings" formControlName="course" [data]="listOfCourse"
                                [(ngModel)]="selectedCourse" (onSelect)="onDaySelect($event)"
                                (onDeSelect)="onDayDeSelect($event)" (onSelectAll)="onDaySelectAll($event)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-md-12" style="text-align-last: right;">
                        <button *ngIf="!isGetTargetLoading" class=" get-count-button"
                            [disabled]="addTargetUserDetail.get('targetUser')?.value=='Select User Group'"
                            (click)="addTargetUserDetail.get('targetUser')?.value=='Select User Group'? null: getTargetUserCount()">Show
                            Target Count</button>
                        <button *ngIf="isGetTargetLoading" class="get-count-button">
                            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </button>
                    </div>
                    <div *ngIf="targetUserCountCalled" class="col-md-12"
                        style="text-align-last: right; margin-top: 5px;font-size: 13px; ">
                        {{targetUserCount}} will get notified under this Campaign.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label class="heading">Exclude audience</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <input type="checkbox" style="font-size:medium;margin-right: 1em;" id="freeExcluded"
                            class="radio" value="Free" [checked]="isFreeExcluded" (change)="freeExcludedChange($event)">
                        <label for="freeExcluded" style="font-size:medium;margin-right: 1em;">Exclude User with Free
                            Course</label>
                        <br>
                        <div style="height:10px;"></div>
                        <input type="checkbox" style="font-size:medium;margin-right: 1em;" id="paidExcluded"
                            class="radio" value="Paid" [checked]="isPaidExcluded" (change)="paidExcludedChange($event)">
                        <label for="paidExcluded" style="font-size:medium;margin-right: 1em;">Exclude User with paid
                            Course</label>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="selectedStepper=='Target & Schedule' && !isLoading" class="main-card">
            <div class="row">
                <div class="col-md-4">
                    <label class="heading">Schedule Webinar</label>
                </div>
            </div>
            <form name="scheduleWebinarFormDetail" [formGroup]="scheduleWebinarFormDetail">

                <div class="row">
                    <div class="col-md-4">
                        <label class="label">Notification Schedule:</label>
                        <div class="row" style=" margin-left: .1rem;margin-top: 1rem;">
                            <div style=" display: flex;align-items: baseline; margin-right:4rem"
                                (click)="toggleBeforeTime()">
                                <input type="checkbox" class="checkbox" value="Before" id="Before"
                                    [checked]="beforeSelected">
                                <label style="font-size: 14px;margin-left: 0.5rem;" class="label"
                                    for="Before">Before</label><br>
                            </div>
                            <div style="display: flex;align-items: baseline;" (click)="toggleAfterTime()">
                                <input type="checkbox" class="checkbox" value="After" id="After"
                                    [checked]="afterSelected">
                                <label style=" font-size: 14px;margin-left: 0.5rem;" class="label"
                                    for="After">After</label><br>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="beforeSelected" class="row" style="margin-bottom:1rem">
                    <div class="col-md-12">
                        <label for="franchise" class="label required" style="margin-bottom: 1em;">Select Before
                            Duration</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Before Duration'"
                            formControlName="beforeDuration" [settings]="timeSetting" [data]="listOfBeforeDuration"
                            [(ngModel)]="selectedBeforeTime" (onSelect)="onDurationSelect($event,'Before')"
                            (onDeSelect)="onDurationDeSelect($event,'Before')"
                            (onSelectAll)="onDurationSelectAll($event,'Before')">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div *ngIf="afterSelected" class="row" style="margin-bottom:1rem">
                    <div class="col-md-12">
                        <label for="franchise" class="label required" style="margin-bottom: 1em;">Select After
                            Duration</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select After Duration'"
                            formControlName="afterDuration" [settings]="timeSetting" [data]="listOfAfterDuration"
                            [(ngModel)]="selectedAfterTime" (onSelect)="onDurationSelect($event,'After')"
                            (onDeSelect)="onDurationDeSelect($event,'After')"
                            (onSelectAll)="onDurationSelectAll($event,'After')">
                        </ng-multiselect-dropdown>
                    </div>
                </div>

            </form>
        </div>

        <!-- Content Card -->
        <div *ngIf="selectedStepper=='Content'">
            <div class="row justify-content-end">
                <div class="col-md-12" style="text-align-last: right;">
                    <button class="back-button" (click)="gotoStepper('Target & Schedule')">Back</button>

                    <button *ngIf="!isSaveWebinarLoading"
                        [ngClass]="{'save-campaign-disabled':addCampaignDetail.invalid  || addContentDetail.invalid ,'save-campaign-enabled':addCampaignDetail.valid  && addContentDetail.valid}"
                        [disabled]="addCampaignDetail.invalid  || addContentDetail.invalid"
                        (click)="addCampaignDetail.invalid  || addContentDetail.invalid? null: saveWebinar()">Save
                        Webinar</button>
                    <button *ngIf="isSaveWebinarLoading" class="get-count-button">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </button>
                </div>

            </div>
        </div>
        <div *ngIf="selectedStepper=='Content'" class="main-card">
            <div *ngIf="selectedBeforeTime.length==0 && selectedAfterTime ==0"
                class="display-flex align-items-center justify-content-center" style="height: 40vh;">
                Select Atleast one medium.
            </div>
            <div *ngIf="selectedMedium.length>0 && !isLoading">
                <form name="addContentDetail" [formGroup]="addContentDetail">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab *ngFor="let data of getDurationForContent()" [label]="data['type-title']">
                            <!-- Before -->
                            <div style="height: 60vh;padding: 2rem 0rem 2rem 2rem;overflow: hidden;"
                                *ngIf="data['type-title']=='Before - 15 Minutes'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleBefore15Min" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleBefore15Min"
                                                    formControlName="messageTitleBefore15Min" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpBefore15Min" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpBefore15Min"
                                                    name="messageIsPopUpBefore15Min"
                                                    formControlName="messageIsPopUpBefore15Min"
                                                    [checked]="addContentDetail.value['messageIsPopUpBefore15Min']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpBefore15Min')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefBefore15Min" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefBefore15Min"
                                                    formControlName="messagePopUpPrefBefore15Min" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageBefore15Min" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageBefore15Min">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['before-15-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'before-15-Min')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['before-15-Min']">
                                                                    <div (click)="selectTemplate(template,'before-15-Min')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'before-15-Min')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['before-15-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('before-15-Min')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['before-15-Min'].length>0?selectedTemplate['before-15-Min'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['before-15-Min'][0],'before-15-Min')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['before-15-Min']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['before-15-Min']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['before-15-Min']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-before-15-Min">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['before-15-Min']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['before-15-Min']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-before-15-Min-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>
                                </div>

                            </div>
                            <div style="height: 60vh;padding: 2rem 0rem 2rem 2rem;overflow: hidden;"
                                *ngIf="data['type-title']=='Before - 30 Minutes'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleBefore30Min" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleBefore30Min"
                                                    formControlName="messageTitleBefore30Min" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpBefore30Min" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpBefore30Min"
                                                    name="messageIsPopUpBefore30Min"
                                                    formControlName="messageIsPopUpBefore30Min"
                                                    [checked]="addContentDetail.value['messageIsPopUpBefore30Min']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpBefore30Min')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefBefore30Min" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefBefore30Min"
                                                    formControlName="messagePopUpPrefBefore30Min" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageBefore30Min" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageBefore30Min">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['before-30-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'before-30-Min')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['before-30-Min']">
                                                                    <div (click)="selectTemplate(template,'before-30-Min')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'before-30-Min')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['before-30-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('before-30-Min')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['before-30-Min'].length>0?selectedTemplate['before-30-Min'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['before-30-Min'][0],'before-30-Min')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['before-30-Min']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['before-30-Min']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['before-30-Min']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-before-30-Min">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['before-30-Min']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['before-30-Min']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-before-30-Min-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>
                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='Before - 1 Hour'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleBefore1Hr" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleBefore1Hr" formControlName="messageTitleBefore1Hr"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpBefore1Hr" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpBefore1Hr"
                                                    name="messageIsPopUpBefore1Hr"
                                                    formControlName="messageIsPopUpBefore1Hr"
                                                    [checked]="addContentDetail.value['messageIsPopUpBefore1Hr']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpBefore1Hr')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefBefore1Hr" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefBefore1Hr"
                                                    formControlName="messagePopUpPrefBefore1Hr" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageBefore1Hr" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageBefore1Hr">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['before-1-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'before-1-Hr')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['before-1-Hr']">
                                                                    <div (click)="selectTemplate(template,'before-1-Hr')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'before-1-Hr')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['before-1-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('before-1-Hr')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['before-1-Hr'].length>0?selectedTemplate['before-1-Hr'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['before-1-Hr'][0],'before-1-Hr')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['before-1-Hr']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['before-1-Hr']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['before-1-Hr']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-before-1-Hr">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['before-1-Hr']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['before-1-Hr']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-before-1-Hr-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>
                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='Before - 3 Hour'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleBefore3Hr" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleBefore3Hr" formControlName="messageTitleBefore3Hr"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpBefore3Hr" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpBefore3Hr"
                                                    name="messageIsPopUpBefore3Hr"
                                                    formControlName="messageIsPopUpBefore3Hr"
                                                    [checked]="addContentDetail.value['messageIsPopUpBefore3Hr']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpBefore3Hr')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefBefore3Hr" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefBefore3Hr"
                                                    formControlName="messagePopUpPrefBefore3Hr" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageBefore3Hr" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageBefore3Hr">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['before-3-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'before-3-Hr')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['before-3-Hr']">
                                                                    <div (click)="selectTemplate(template,'before-3-Hr')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'before-3-Hr')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['before-3-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('before-3-Hr')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['before-3-Hr'].length>0?selectedTemplate['before-3-Hr'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['before-3-Hr'][0],'before-3-Hr')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['before-3-Hr']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['before-3-Hr']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['before-3-Hr']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-before-3-Hr">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['before-3-Hr']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['before-3-Hr']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-before-3-Hr-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='Before - 6 Hour'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleBefore6Hr" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleBefore6Hr" formControlName="messageTitleBefore6Hr"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpBefore6Hr" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpBefore6Hr"
                                                    name="messageIsPopUpBefore6Hr"
                                                    formControlName="messageIsPopUpBefore6Hr"
                                                    [checked]="addContentDetail.value['messageIsPopUpBefore6Hr']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpBefore6Hr')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefBefore6Hr" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefBefore6Hr"
                                                    formControlName="messagePopUpPrefBefore6Hr" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageBefore6Hr" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageBefore6Hr">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['before-6-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'before-6-Hr')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['before-6-Hr']">
                                                                    <div (click)="selectTemplate(template,'before-6-Hr')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'before-6-Hr')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['before-6-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('before-6-Hr')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['before-6-Hr'].length>0?selectedTemplate['before-6-Hr'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['before-6-Hr'][0],'before-6-Hr')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['before-6-Hr']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['before-6-Hr']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['before-6-Hr']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-before-6-Hr">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['before-6-Hr']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['before-6-Hr']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-before-6-Hr-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='Before - 1 Day'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleBefore1Day" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleBefore1Day" formControlName="messageTitleBefore1Day"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpBefore1Day" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpBefore1Day"
                                                    name="messageIsPopUpBefore1Day"
                                                    formControlName="messageIsPopUpBefore1Day"
                                                    [checked]="addContentDetail.value['messageIsPopUpBefore1Day']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpBefore1Day')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefBefore1Day" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefBefore1Day"
                                                    formControlName="messagePopUpPrefBefore1Day" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageBefore1Day" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageBefore1Day">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['before-1-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'before-1-Day')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['before-1-Day']">
                                                                    <div (click)="selectTemplate(template,'before-1-Day')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'before-1-Day')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['before-1-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('before-1-Day')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['before-1-Day'].length>0?selectedTemplate['before-1-Day'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['before-1-Day'][0],'before-1-Day')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['before-1-Day']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['before-1-Day']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['before-1-Day']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-before-1-Day">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['before-1-Day']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['before-1-Day']); let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-before-1-Day-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='Before - 2 Day'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleBefore2Day" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleBefore2Day" formControlName="messageTitleBefore2Day"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpBefore2Day" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpBefore2Day"
                                                    name="messageIsPopUpBefore2Day"
                                                    formControlName="messageIsPopUpBefore2Day"
                                                    [checked]="addContentDetail.value['messageIsPopUpBefore2Day']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpBefore2Day')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefBefore2Day" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefBefore2Day"
                                                    formControlName="messagePopUpPrefBefore2Day" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageBefore2Day" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageBefore2Day">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['before-2-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'before-2-Day')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['before-2-Day']">
                                                                    <div (click)="selectTemplate(template,'before-2-Day')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'before-2-Day')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['before-2-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('before-2-Day')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['before-2-Day'].length>0?selectedTemplate['before-2-Day'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['before-2-Day'][0],'before-2-Day')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['before-2-Day']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['before-2-Day']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['before-2-Day']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-before-2-Day">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['before-2-Day']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['before-2-Day']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-before-2-Day-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <!-- After -->
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='After - 15 Minutes'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleAfter15Min" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleAfter15Min" formControlName="messageTitleAfter15Min"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpAfter15Min" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpAfter15Min"
                                                    name="messageIsPopUpAfter15Min"
                                                    formControlName="messageIsPopUpAfter15Min"
                                                    [checked]="addContentDetail.value['messageIsPopUpAfter15Min']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpAfter15Min')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefAfter15Min" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefAfter15Min"
                                                    formControlName="messagePopUpPrefAfter15Min" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageAfter15Min" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageAfter15Min">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['after-15-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'after-15-Min')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['after-15-Min']">
                                                                    <div (click)="selectTemplate(template,'after-15-Min')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'after-15-Min')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['after-15-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('after-15-Min')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['after-15-Min'].length>0?selectedTemplate['after-15-Min'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['after-15-Min'][0],'after-15-Min')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['after-15-Min']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['after-15-Min']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['after-15-Min']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-after-15-Min">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['after-15-Min']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['after-15-Min']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-after-15-Min-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='After - 30 Minutes'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleAfter30Min" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleAfter30Min" formControlName="messageTitleAfter30Min"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpAfter30Min" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpAfter30Min"
                                                    name="messageIsPopUpAfter30Min"
                                                    formControlName="messageIsPopUpAfter30Min"
                                                    [checked]="addContentDetail.value['messageIsPopUpAfter30Min']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpAfter30Min')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefAfter30Min" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefAfter30Min"
                                                    formControlName="messagePopUpPrefAfter30Min" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageAfter30Min" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageAfter30Min">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['after-30-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'after-30-Min')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['after-30-Min']">
                                                                    <div (click)="selectTemplate(template,'after-30-Min')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'after-30-Min')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['after-30-Min']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('after-30-Min')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['after-30-Min'].length>0?selectedTemplate['after-30-Min'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['after-30-Min'][0],'after-30-Min')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['after-30-Min']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['after-30-Min']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['after-30-Min']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-after-30-Min">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['after-30-Min']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['after-30-Min']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-after-30-Min-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='After - 1 Hour'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleAfter1Hr" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleAfter1Hr" formControlName="messageTitleAfter1Hr"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpAfter1Hr" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpAfter1Hr"
                                                    name="messageIsPopUpAfter1Hr"
                                                    formControlName="messageIsPopUpAfter1Hr"
                                                    [checked]="addContentDetail.value['messageIsPopUpAfter1Hr']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpAfter1Hr')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefAfter1Hr" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefAfter1Hr"
                                                    formControlName="messagePopUpPrefAfter1Hr" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageAfter1Hr" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageAfter1Hr">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['after-1-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'after-1-Hr')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['after-1-Hr']">
                                                                    <div (click)="selectTemplate(template,'after-1-Hr')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'after-1-Hr')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['after-1-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('after-1-Hr')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['after-1-Hr'].length>0?selectedTemplate['after-1-Hr'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['after-1-Hr'][0],'after-1-Hr')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['after-1-Hr']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['after-1-Hr']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['after-1-Hr']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-after-1-Hr">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['after-1-Hr']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['after-1-Hr']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-after-1-Hr-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='After - 3 Hour'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleAfter3Hr" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleAfter3Hr" formControlName="messageTitleAfter3Hr"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpAfter3Hr" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpAfter3Hr"
                                                    name="messageIsPopUpAfter3Hr"
                                                    formControlName="messageIsPopUpAfter3Hr"
                                                    [checked]="addContentDetail.value['messageIsPopUpAfter3Hr']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpAfter3Hr')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefAfter3Hr" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefAfter3Hr"
                                                    formControlName="messagePopUpPrefAfter3Hr" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageAfter3Hr" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageAfter3Hr">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['after-3-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'after-3-Hr')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['after-3-Hr']">
                                                                    <div (click)="selectTemplate(template,'after-3-Hr')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'after-3-Hr')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['after-3-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('after-3-Hr')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['after-3-Hr'].length>0?selectedTemplate['after-3-Hr'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['after-3-Hr'][0],'after-3-Hr')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['after-3-Hr']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['after-3-Hr']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['after-3-Hr']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-after-3-Hr">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['after-3-Hr']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['after-3-Hr']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-after-3-Hr-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='After - 6 Hour'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleAfter6Hr" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleAfter6Hr" formControlName="messageTitleAfter6Hr"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpAfter6Hr" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpAfter6Hr"
                                                    name="messageIsPopUpAfter6Hr"
                                                    formControlName="messageIsPopUpAfter6Hr"
                                                    [checked]="addContentDetail.value['messageIsPopUpAfter6Hr']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpAfter6Hr')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefAfter6Hr" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefAfter6Hr"
                                                    formControlName="messagePopUpPrefAfter6Hr" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageAfter6Hr" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageAfter6Hr">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['after-6-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'after-6-Hr')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['after-6-Hr']">
                                                                    <div (click)="selectTemplate(template,'after-6-Hr')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'after-6-Hr')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['after-6-Hr']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('after-6-Hr')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['after-6-Hr'].length>0?selectedTemplate['after-6-Hr'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['after-6-Hr'][0],'after-6-Hr')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['after-6-Hr']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['after-6-Hr']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['after-6-Hr']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-after-6-Hr">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['after-6-Hr']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['after-6-Hr']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-after-6-Hr-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='After - 1 Day'">
                                <div class="row">
                                    <div class="col-md-6 col-6-scroll">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleAfter1Day" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleAfter1Day" formControlName="messageTitleAfter1Day"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpAfter1Day" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpAfter1Day"
                                                    name="messageIsPopUpAfter1Day"
                                                    formControlName="messageIsPopUpAfter1Day"
                                                    [checked]="addContentDetail.value['messageIsPopUpAfter1Day']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpAfter1Day')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefAfter1Day" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefAfter1Day"
                                                    formControlName="messagePopUpPrefAfter1Day" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageAfter1Day" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageAfter1Day">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['after-1-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'after-1-Day')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['after-1-Day']">
                                                                    <div (click)="selectTemplate(template,'after-1-Day')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'after-1-Day')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['after-1-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('after-1-Day')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['after-1-Day'].length>0?selectedTemplate['after-1-Day'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['after-1-Day'][0],'after-1-Day')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['after-1-Day']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['after-1-Day']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['after-1-Day']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-after-1-Day">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['after-1-Day']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['after-1-Day']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-after-1-Day-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                            <div style="padding: 2rem 0rem 2rem 2rem;height: 60vh;overflow: hidden;"
                                *ngIf="data['type-title']=='After - 2 Day'">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="messageTitleAfter2Day" class="label required">Message
                                                    title</label>
                                                <input type="text" placeholder="Enter Message title"
                                                    id="messageTitleAfter2Day" formControlName="messageTitleAfter2Day"
                                                    class="input-style">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"
                                                style="display: flex;align-items: center;margin-bottom: 0.7em;">
                                                <label for="messageIsPopUpAfter2Day" class="label required">Message Is
                                                    PopUp</label>
                                                <input type="checkbox" id="messageIsPopUpAfter2Day"
                                                    name="messageIsPopUpAfter2Day"
                                                    formControlName="messageIsPopUpAfter2Day"
                                                    [checked]="addContentDetail.value['messageIsPopUpAfter2Day']"
                                                    style="width: 15px;margin-left: 10px;">
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isPopUpContent('messageIsPopUpAfter2Day')">
                                            <div class="col-md-12">
                                                <label for="messagePopUpPrefAfter2Day" class="label required">Message
                                                    PopUp Pref</label>
                                                <input type="number" placeholder="Enter PopUp Pref count" [value]="1"
                                                    id="messagePopUpPrefAfter2Day"
                                                    formControlName="messagePopUpPrefAfter2Day" class="input-style">
                                            </div>
                                        </div>
                                        <div class="row" style="margin-bottom: 10em;">
                                            <div class="col-md-12">
                                                <label for="messageAfter2Day" class="label required">Message</label>
                                                <quill-editor class="content-editor" placeholder="Enter Message"
                                                    name="message" formControlName="messageAfter2Day">
                                                </quill-editor>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="image" class="label">Image(<span style="color: red;">Image
                                                        size
                                                        should be less than 300kb</span>)</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="image">
                                                            <div class="proof">
                                                                <img *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    [src]="defaultImage[data['type-title']]"
                                                                    class="proofImage">
                                                                <label *ngIf="defaultImage[data['type-title']]==null"
                                                                    class="imageLabel"> Upload
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined"
                                                                    class="imageLabelEdit">
                                                                    <mat-icon class="matIcon">edit</mat-icon>
                                                                    <input
                                                                        (change)="displayImage($event,data['type-title'])"
                                                                        type="file" accept="image/*">
                                                                </label>
                                                                <label
                                                                    *ngIf="defaultImage[data['type-title']]!=null && defaultImage[data['type-title']]!=undefined  && defaultImage[data['type-title']].includes('https')"
                                                                    class="imageLabelDownload"
                                                                    (click)="downloadImage(defaultImage[data['type-title']])">
                                                                    <mat-icon class="matIcon">download</mat-icon>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-6 col-6-scroll" *ngIf="showWhatsAppContent">
                                        <!-- **START** WhatsApp Campaign -->

                                        <div *ngIf="isTemplateLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="40"
                                                style="margin:0 auto;">
                                            </mat-progress-spinner>
                                        </div>
                                        <div *ngIf="!isTemplateLoading" style="margin-bottom: 1em;">
                                            <div class="row" style="margin-bottom: 1em;">
                                                <div class="col-md-12">
                                                    <div *ngIf="!isTemplateLoading " class="template-list">
                                                        <div *ngIf="!isTemplateLoading && !showTemplateBody['after-2-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Choose WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div style="display: flex;align-items: center;">
                                                                    <input type="text" class="search-box"
                                                                        placeholder="Search template"
                                                                        (input)="searchTemplateData($event.target,'after-2-Day')">
                                                                    <span (click)="syncTemplate()"
                                                                        style="color: blue;cursor: pointer;text-decoration: underline;margin-right: 10px;">Sync</span>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div style="overflow: auto;">

                                                                <div *ngFor="let template of listOfTemplateToDisplay['after-2-Day']">
                                                                    <div (click)="selectTemplate(template,'after-2-Day')"
                                                                        style="cursor: pointer;">
                                                                        <span
                                                                            style="font-weight: 600;">{{template['nameWithLanguage']}}</span>
                                                                        <div style="margin-top: 10px;">
                                                                            {{getTemplateBody(template,'after-2-Day')}}
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isTemplateLoading &&  showTemplateBody['after-2-Day']">
                                                            <div
                                                                style="display: flex;justify-content: space-between;align-items: center;">
                                                                <div>

                                                                    <span style="font-size: 18px;font-weight: 500;">
                                                                        Selected WhatsAapp Template
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <img src="../../../../assets/wa-img/close.png"
                                                                        alt="" srcset=""
                                                                        style="height: 15px;width: 15px;cursor: pointer;margin-bottom: 4px; margin-right: 10px;"
                                                                        (click)="hideTemplateBody('after-2-Day')">
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <div style="overflow: auto;max-height: 14em;">

                                                                <div
                                                                    style="padding: 1em; border-radius: 5px; background: rgb(234, 238, 252);">
                                                                    <span
                                                                        style="font-weight: 600;">{{selectedTemplate['after-2-Day'].length>0?selectedTemplate['after-2-Day'][0]['nameWithLanguage']:''}}</span>
                                                                    <div style="margin-top: 10px;">
                                                                        {{getTemplateBody(selectedTemplate['after-2-Day'][0],'after-2-Day')}}
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showHeaderOfTemplate['after-2-Day']">
                                                <div class="col-md-6" *ngIf="showHeaderMediaOfTemplate['after-2-Day']">
                                                    <label for="" class="label required">Herder Media:</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="image">
                                                                <div class="proof">
                                                                    <img *ngIf="defaultHeaderMedia!=null"
                                                                        src="{{defaultHeaderMedia}}" class="proofImage"
                                                                        style="height: 10em;">
                                                                    <div *ngIf="defaultHeaderMedia!=null"
                                                                        style="font-size: 10px; margin: 1em; word-break: break-word;text-align: center;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;"
                                                                        [title]="selectedHeaderMediaName">
                                                                        {{selectedHeaderMediaName}}
                                                                    </div>
                                                                    <label *ngIf="defaultHeaderMedia==null"
                                                                        class="imageLabel">
                                                                        Upload
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>
                                                                    <label *ngIf="defaultHeaderMedia!=null"
                                                                        class="imageLabelEdit">
                                                                        <mat-icon class="matIcon">edit</mat-icon>
                                                                        <input (change)="displayHeaderMedia($event)"
                                                                            type="file" accept=".xlsx,video/*,image/*">
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="showHeaderTextOfTemplate['after-2-Day']">
                                                    <div style="margin-bottom: 1.5rem;">
                                                        <label for="header-text" class="label required">Header
                                                            Text:</label>
                                                        <quill-editor class="content-editor" style="height: 12rem;"
                                                            placeholder="Body" name="header-text"
                                                            formControlName="headerText-after-2-Day">
                                                        </quill-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showBodyOfTemplate['after-2-Day']" style="margin-top: 1em;">
                                                <div class="col-md-12">
                                                    For name enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'fullName'+'}'+'}'}}</span>
                                                    For phone enter:- <span
                                                        style="font-weight: 600;">{{'{'+'{'+'phone'+'}'+'}'}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngFor="let val of counter(bodyVariableCount['after-2-Day']) ;let i = index">
                                                    <label for="name" class="label required">Body Text {{(i+1)}}</label>
                                                    <input type="text" [placeholder]="'Enter body text '+(i+1)"
                                                        id="name" [formControlName]="'bodyText-after-2-Day-'+(i+1)"
                                                        class="input-style">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="showFooterOfTemplate">

                                            </div>
                                            <div class="row" *ngIf="showButtonOfTemplate">

                                            </div>
                                        </div>
                                        <!-- **END** WhatsApp Campaign -->
                                    </div>

                                </div>

                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </form>
            </div>
        </div>

    </div>
    <div style="height: 10em;">
    </div>
</div>