import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss'],
})
export class QuestionDetailsComponent implements OnInit {
  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
    public routes: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    console.log('Called Constructor');
    if (this.as.questionToAccessFromComment != null) {
      this.question = this.as.questionToAccessFromComment;
    } else {
      this.backToForum();
    }
  }

  commentFormControl = new FormControl('', [Validators.required]);
  replyFormControl = new FormControl('', [Validators.required]);

  forumForm: FormGroup = new FormGroup({
    comment: this.commentFormControl,
    reply: this.replyFormControl,

    // correctAnswerRadio: this.radioFormControl
  });

  ngOnInit(): void {
    this.fetchComments();
    this.forumForm = this.formBuilder.group({
      comment: this.commentFormControl,
      reply: this.replyFormControl,
    });
  }

  isLoading: boolean = false;
  listOfComments: any = [];
  listOfCommentsToDisplay: any = [];
  question: any;
  reply: Boolean = false;
  comment: String = '';
  replyText: String = '';
  replyId: String = '';
  commentDoc: any;
  searchComment(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let questionList: any = [];
    this.listOfComments = [...this.listOfCommentsToDisplay];
    if (serachQuery != '') {
      this.listOfComments.forEach((comment: any) => {
        if (
          String(comment['userName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(comment['comment'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          questionList.push(comment);
        }
      });
      this.listOfComments = [...questionList];
    } else {
      this.listOfComments = [...this.listOfCommentsToDisplay];
    }
  }
  async fetchComments() {
    this.isLoading = true;
    this.listOfCommentsToDisplay = [];
    this.listOfComments = [];
    const param = {
      questionId: this.question['_id'],
    };
    const URL = `${webApi.domain + webApi.endPoint.fetchComment}`;
    await this.as.fetchCommentFromService(param, URL).then((data: any) => {
      if (data['success']) {
        this.listOfComments = data['data'];
        this.listOfCommentsToDisplay = this.listOfComments;

        // console.log(this.listOfComments);
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  async deleteComment(comment: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Delete Comment', comment: comment };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        const param = {
          commentId: res['value']['_id'],
          questionId: this.question['_id'],
        };
        const URL = `${webApi.domain + webApi.endPoint.deleteComment}`;
        await this.as.deleteCommentFromService(param, URL).then((data: any) => {
          if (data['success']) {
            this.listOfComments = this.listOfComments.filter(
              (comment: any) => comment['_id'] != res['value']['_id']
            );
            this.showSuccessToaster('Comment Deleted  Successfully', 'Success');
          } else {
            this.showErrorToaster(
              'Error Occurred while Deleting Comment. Try again',
              'Error'
            );
          }
        });
      }
    });
  }
  async suspendUserFromForum(comment: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      source: 'Suspend User From Comment',
      comment: comment,
    };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        const param = {
          userId: res['value']['userId'],
          suspend: true,
        };

        const URL = `${webApi.domain + webApi.endPoint.suspendUser}`;
        await this.as
          .suspendUserFromForumFromService(param, URL)
          .then((data: any) => {
            if (data['success']) {
              this.showSuccessToaster(
                'User Suspended  Successfully',
                'Success'
              );
            } else {
              this.showErrorToaster(
                'Error Occurred while suspending User. Try again',
                'Error'
              );
            }
          });
      }
    });
  }
  async sendComment() {
    if (this.forumForm.get('comment')?.value != '') {
      const param = {
        userId: 0,
        comment: this.forumForm.get('comment')?.value,
        questionId: this.question['_id'],
        userName: 'Admin',
        type: 'text',
        admin: true,
      };
      console.log(param);

      const URL = `${webApi.domain + webApi.endPoint.insertComment}`;
      await this.as.insertCommentFromService(param, URL).then((data: any) => {
        if (data['success']) {
          this.comment = '';
          this.forumForm.get('comment')?.setValue('');
          this.listOfComments.push(data['data']);
        }
      });
    }
  }
  async sendReply() {
    if (this.forumForm.get('reply')?.value != '') {
      const param = {
        userId: 0,
        replyText: this.forumForm.get('reply')?.value,
        commentId: this.commentDoc['_id'],
        questionId: this.question['_id'],
        userName: 'Admin',
        type: 'text',
        admin: true,
      };
      const URL = `${webApi.domain + webApi.endPoint.insertReply}`;
      await this.as.insertReplyFromService(param, URL).then((data: any) => {
        if (data['success']) {
          this.comment = '';
          this.reply = false;
          this.replyText = '';
          this.forumForm.get('reply')?.setValue('');
          this.listOfComments.forEach((com: any) => {
            if (com['_id'] == this.commentDoc['_id']) {
              var reply = data['data']['reply'].pop();
              com['reply'].push(reply);
              com['viewReply'] = true;
              this.replyId = '';
            }
          });
        }
      });
    }
  }
  replyOnComment(comment: any) {
    this.reply = !this.reply;
    this.commentDoc = comment;
    if (this.reply) {
      this.replyId = comment['_id'];
    } else {
      this.replyId = '';
      this.replyText = '';
    }
  }
  backToForum() {
    this.router.navigate(['/catalog/forum']);
  }

  viewReply(commentId: String) {
    this.listOfComments.forEach((comment: any) => {
      if (comment['_id'] == commentId) {
        comment['viewReply'] = !comment['viewReply'];
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
