import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-filter-subscription',
  templateUrl: './filter-subscription.component.html',
  styleUrls: ['./filter-subscription.component.scss'],
})
export class FilterSubscriptionComponent implements OnInit {
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();
  @Input() filterToEdit: any;
  isLoading: boolean = false;

  httpHeaders: any;

  myHeaders = new Headers();
  formdata = new FormData();

  typeControl = new FormControl('Select Type', [Validators.required]);
  statusControl = new FormControl('Select Status', [Validators.required]);
  startDateControl = new FormControl('', [Validators.required]);
  endDateControl = new FormControl('', [Validators.required]);

  selectedType: any;
  selectedStatus: any;

  subscriptionFilter: FormGroup = new FormGroup({
    type: this.typeControl,
    status: this.statusControl,
    startDate: this.startDateControl,
    endDate: this.endDateControl,
  });

  userDetails: any;
  constructor(
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  async ngOnInit(): Promise<void> {
    this.subscriptionFilter = this.formBuilder.group({
      type: this.typeControl,
      status: this.statusControl,
      startDate: this.startDateControl,
      endDate: this.endDateControl,
    });

    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });

    if (this.filterToEdit != null) {
      this.selectedStatus = this.filterToEdit['status'];
      this.selectedType = this.filterToEdit['type'];
      this.subscriptionFilter.get('type')?.setValue(this.filterToEdit['type']);
      this.subscriptionFilter
        .get('status')
        ?.setValue(this.filterToEdit['status']);
      this.subscriptionFilter
        .get('startDate')
        ?.setValue(this.filterToEdit['startDate']);
      this.subscriptionFilter
        .get('endDate')
        ?.setValue(this.filterToEdit['endDate']);
    }
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  typeSelected(target: any) {
    let value = target.value;
    this.selectedType = value;
  }
  statusSelected(target: any) {
    let value = target.value;
    this.selectedStatus = value;
  }

  editUser() {
    this.isLoading = true;

    var formdata = new FormData();

    formdata.append('type', this.subscriptionFilter.get('type')?.value);
    formdata.append('status', this.subscriptionFilter.get('status')?.value);
    formdata.append(
      'startDate',
      this.subscriptionFilter.get('startDate')?.value
    );
    formdata.append('endDate', this.subscriptionFilter.get('endDate')?.value);

    var headers = new Headers();

    headers.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: formdata,
      redirect: 'follow',
    };
    const URL = `${
      webApi.domain + webApi.endPoint.fetchSubscriptionAfterFilter
    }`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                let data = {
                  data: res['result'],
                  type: this.selectedType,
                  status: this.selectedStatus,
                  startDate: this.subscriptionFilter.get('startDate')?.value,
                  endDate: this.subscriptionFilter.get('endDate')?.value,
                };
                this.selectedStatus = null;
                this.selectedType = null;
                this.cancel(data);
                this.subscriptionFilter.reset();
              } else {
                this.isLoading = false;
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
