import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { webApi } from 'src/app/api';
import { HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-add-new-offers',
  templateUrl: './add-new-offers.component.html',
  styleUrls: ['./add-new-offers.component.scss'],
})
export class AddNewOffersComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  myHeaders = new Headers();
  formdata = new FormData();

  codeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  startDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  endDateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  discountQuantityControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  descriptionControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  maxDiscountControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  typeControl = new FormControl('Select Type', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  accessControl = new FormControl('Select Access', [Validators.required]);

  addEditOfferForm: FormGroup = new FormGroup({
    code: this.codeControl,
    startDate: this.startDateControl,
    endDate: this.endDateControl,
    description: this.descriptionControl,
    type: this.typeControl,
    discountQuantity: this.discountQuantityControl,
    maxDiscountAmount: this.maxDiscountControl,
    access: this.accessControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }

  ngOnInit(): void {
    this.addEditOfferForm = this.formBuilder.group({
      code: this.codeControl,
      startDate: this.startDateControl,
      endDate: this.endDateControl,
      description: this.descriptionControl,
      type: this.typeControl,
      discountQuantity: this.discountQuantityControl,
      maxDiscountAmount: this.maxDiscountControl,
      access: this.accessControl,
    });

    if (this.dataToEdit != null) {
      this.addEditOfferForm.get('code')?.setValue(this.dataToEdit['code']);

      this.addEditOfferForm
        .get('description')
        ?.setValue(this.dataToEdit['description']);

      this.addEditOfferForm
        .get('startDate')
        ?.setValue(
          new Date(this.dataToEdit['startDate']).toISOString().slice(0, 10)
        );
      if (this.dataToEdit['endDate'] != undefined) {
        this.addEditOfferForm
          .get('endDate')
          ?.setValue(
            new Date(this.dataToEdit['endDate']).toISOString().slice(0, 10)
          );
      }
      this.addEditOfferForm
        .get('maxDiscountAmount')
        ?.setValue(parseInt(this.dataToEdit['maxDiscountAmount']));
      this.addEditOfferForm.get('access')?.setValue(this.dataToEdit['access']);

      this.addEditOfferForm.get('type')?.setValue(this.dataToEdit['type']);

      this.addEditOfferForm
        .get('discountQuantity')
        ?.setValue(this.dataToEdit['benefit']);
    }
  }

  brandChangeHandler(event: any) {
    this.addEditOfferForm.get('brand')?.setValue(event.value);
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  onCategorySelect(item: any) {}
  onCategoryDeSelect(item: any) {}
  onSelectAllCategory(items: any) {}
  onProductSelect(item: any) {}
  onProductDeSelect(item: any) {}
  onSelectAllProduct(items: any) {}

  addEditOffer() {
    if (this.dataToEdit != null) {
      this.formdata.append('couponId', this.dataToEdit['_id']);
    } else {
      this.formdata.append('couponId', '0');
    }

    this.formdata.append('code', this.addEditOfferForm.get('code')?.value);

    this.formdata.append(
      'startDate',
      this.addEditOfferForm.get('startDate')?.value
    );
    this.formdata.append(
      'endDate',
      this.addEditOfferForm.get('endDate')?.value
    );

    this.formdata.append(
      'maxDiscountAmount',
      this.addEditOfferForm.get('maxDiscountAmount')?.value
    );

    this.formdata.append(
      'description',
      this.addEditOfferForm.get('description')?.value
    );

    this.formdata.append(
      'benefit',
      this.addEditOfferForm.get('discountQuantity')?.value
    );

    this.formdata.append('type', this.addEditOfferForm.get('type')?.value);
    this.formdata.append('access', this.addEditOfferForm.get('access')?.value);
    
    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.createAndUpdateCoupon}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addEditOfferForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                if (Object.keys(res['message']['keyPattern'])[0] == 'code') {
                  this.showErrorToaster('Coupon Code cannot be same', 'Error');
                  this.cancel(null);
                }
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
