<div style="height: 100vh;">
    <!-- <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-help-video *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-help-video>
        </ng-sidebar> -->
    <div ng-sidebar-content class="sidebar-content">
        <!-- Top Data -->
        <div class="row align-center" style="justify-content: space-between;">

            <div class="col-md-4 top-heading" style="cursor: pointer;">
                <span style="font-size: 1.5rem;cursor: pointer;">
                    Audit Log List
                </span>
            </div>
            <div class="col-md-8">
                <div class="row align-center">
                    <div class="col-md-6" style="text-align: right;">
                        <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                            (input)="searchCourse($event)">
                    </div>
                </div>
            </div>

        </div>
        <!-- Table Data -->
        <div *ngIf="isLoading">
            <div class="center-loading">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </div>
        <div *ngIf="listOfAuditLogToDisplay.length==0 && !isLoading">
            <div class="no-available">
                No Log found
            </div>
        </div>
        <div *ngIf="listOfAuditLogToDisplay.length>0 && !isLoading">
            <table class="table-style" cellspacing="0" cellpadding="0">
                <thead class="table-heading">

                    <th style="cursor: pointer;text-align: left;padding-left:2rem;" class="table-heading-content">
                        Name</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        Phone</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        Email</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        Admin</th>
                    <th style="cursor: pointer;" class="table-heading-content"> Date
                    </th>
                    <th style="cursor: pointer;" class="table-heading-content"> View Changes
                    </th>



                </thead>
                <!-- <div style="height: 0.6em;"> </div> -->
                <tbody>
                    <tr class="table-data"
                        *ngFor="let audit of listOfAuditLogToDisplay | paginate: { itemsPerPage: count, currentPage: p };let i=index">
                        
                        <th class="table-data-content">{{audit['user']['fullName']}}</th>
                        <th class="table-data-content">{{audit['user']['phone']}}</th>
                        <th class="table-data-content">{{audit['user']['email']}}</th>
                        <th class="table-data-content">{{audit['admin']['fullName']}}</th>
                        <th class="table-data-content">{{audit.createdAt |date}}</th>
                        <th class="table-data-content">
                            <img (click)="viewEditLog(audit)"
                                style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                                src='../../../../assets/img/view.png'>
                        </th>
                    </tr>
                </tbody>
            </table>
            <div class="text-right" style="font-size: medium;margin: 1em;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>


    </div>
    <!-- </ng-sidebar-container> -->
</div>