<div *ngIf="data['source']=='Module'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this module?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteModule()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Activity'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this activity?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteActivity()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Quiz'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this quiz?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteQuiz()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Activity Warning'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>All unsaved changes will be lost.Are you sure you
        want to exit?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="exitActivity()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Course'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this Course?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteCourse()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Course Status'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['course']['isActive']?'publish': 'unpublish'}} this Course?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="publishCourse()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Quiz Module'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this quiz?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteQuizModule()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Offer'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this Offer?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteOffer()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Review'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this Review?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteReview()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Delete Question'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this Question?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteQuestion()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Report Question'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to report this Question?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="reportQuestion()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Suspend Question'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{data['question']['isSuspended']?'publish':'unpublish'}} this Question?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="suspendQuestion()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Suspend User'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to suspend the user from forum?
    </div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="suspendUser()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Delete Comment'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to delete this Comment?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteComment()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Suspend User From Comment'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to suspend this User?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="suspendUserFromComment()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Pay Commission'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want pay this User?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="payCommission()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Uneroll'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want Uneroll this User?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="unEnrollUser()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Delete User'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want delete this User?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteUser()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Enrolment'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['enrolment']['isActive']?'active': 'inactive'}} this enrolment?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeEnrolment()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Batch Module Status'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['batchModule']['isActive']?'active': 'inactive'}} this batch module?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeBatchModule()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Batch Module Delete'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this batch Module?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteBatchModule()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Batch Status'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['batch']['isActive']?'active': 'inactive'}} this batch?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeBatch()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Batch Delete'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this batch?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteBatch()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Notes Delete'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this notes?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteNotes()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Assessment Delete'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this Assessment?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteAssessment()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Poll Delete'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this Poll?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deletePoll()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Notes Status'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['notes']['isActive']?'active': 'inactive'}} this notes?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeNotes()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Assessment Status'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['assessment']['isActive']?'active': 'inactive'}} this Assessment?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeAssessment()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Poll Status'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['poll']['isActive']?'active': 'inactive'}} this Poll?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activePoll()">Yes</button>
    </div>
</div>