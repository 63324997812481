<div *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
    </mat-progress-spinner>
</div>
<div *ngIf="!isLoading" style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon" style="font-size: 1rem;">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Webinar Detail</h2>
    <hr>
    <div class="row">
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Title: </span> {{webinarDetail['title']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Author: </span> {{webinarDetail['author']['name']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Link: </span> <a [href]="webinarDetail['link']">Webinar Link</a>
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;" *ngIf="webinarDetail['zoomFile']!=null && webinarDetail['zoomFile']!=undefined&& webinarDetail['zoomFile']!=''">
            <span style="font-size: 15px;font-weight: 500;"> Uploaded Zoom File Link: </span><a [href]="webinarDetail['zoomFile']">Zoom File</a>
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Type: </span> {{webinarDetail['linkType']}}
        </div>
        <div class="col-md-12" style="margin-bottom: 1em;">
            <span style="font-size: 15px;font-weight: 500;"> Limited Seat: </span> {{webinarDetail['limitedSeat']}}
        </div>

        <div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Webinar Date : </span>
                {{webinarDetail['webinarDate']|date}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Lobby Time : </span>
                {{this.as.getTimePeriod(webinarDetail.lobbyTime)}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Start Time: </span>
                {{this.as.getTimePeriod(webinarDetail['startTime'])}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> End Time: </span>
                {{this.as.getTimePeriod(webinarDetail['endTime'])}}
            </div>
            <div class="col-md-12" style="margin-bottom: 1em;">
                <span style="font-size: 15px;font-weight: 500;"> Total Duration: </span>
                {{webinarDetail['totalDuration']<60?webinarDetail['totalDuration']
                    +'Minutes':this.as.toHoursAndMinutes(webinarDetail['totalDuration'])}} </div>
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <span style="font-size: 15px;font-weight: 500;"> Description : </span> <span
                            [innerHtml]="webinarDetail['description']"></span>
                    </div>

                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <span style="font-size: 15px;font-weight: 500;"> Who can attend this webinar? : </span> <span
                            [innerHtml]="webinarDetail['webinarFor']"></span>
                    </div>
                    
            </div>
        </div>

    </div>