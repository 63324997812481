import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss'],
})
export class AddCampaignComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isView: boolean = false;
  htmlValue: string = '';

  listOfTemplate: any = [];
  listOfTemplateToDisplay: any = [];
  selectedTemplate: any = [];
  template: any;
  showTemplateBody: boolean = false;
  showHeaderOfTemplate: boolean = false;
  showHeaderMediaOfTemplate: boolean = false;
  showHeaderTextOfTemplate: boolean = false;
  showBodyOfTemplate: boolean = false;
  showFooterOfTemplate: boolean = false;
  showButtonOfTemplate: boolean = false;
  selectedCampaignMedium: any = 'email';
  bodyVariableCount: number = 0;

  // Image For EMAIL & SMS
  selectedHeaderMedia: any = null;
  selectedHeaderMediaName: any = null;
  defaultHeaderMedia: any;

  // Image For EMAIL & SMS
  selectedCampaignExcel: any = null;
  selectedCampaignExcelName: any = null;
  defaultCampaignExcel: any;

  selectedImage: any = null;
  selectedImageName: any = null;
  defaultImage: any;

  isLoading: boolean = false;
  excelTarget: any;

  myHeaders = new Headers();
  formdata = new FormData();

  lngDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'nameWithLanguage',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    limitSelection: 1,
  };

  campaignNameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  campaignMediumControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  templateControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  subjectControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  bodyControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  body2Control = new FormControl('', [
    Validators.required,]);

  addCampaignForm: FormGroup = new FormGroup({
    campaignName: this.campaignNameControl,
    // campaignMedium: this.campaignMediumControl,
    subject: this.subjectControl,
    body: this.bodyControl,
    body2: this.body2Control,
  });
  userDetails: any;
  constructor(
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);
  }
  ngOnInit(): void {
    this.addCampaignForm = this.formBuilder.group({
      campaignName: this.campaignNameControl,
      // campaignMedium: this.campaignMediumControl,
      subject: this.subjectControl,
      body: this.bodyControl,
      body2: this.body2Control,
    });
    this.addCampaignForm
      .get('body2')?.disable();
    // this.addCampaignForm
    //   .get('body2')
    //   ?.setValue(`<img src="https://buildby-chat-ai.s3.ap-south-1.amazonaws.com/whatsApp-media-ce26f9cc-73aa-46bc-9756-0891e3da34c5-1666078295131.jpeg" alt="Workplace" usemap="#workmap">

    //   <map name="workmap">
    //       <area shape="rect" coords="384,1079,830,1160" alt="Register Now" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">
    //       <area shape="rect" coords="31,1200,260,1260" alt="Whatsapp connect" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">

    //       <area shape="rect" coords="302,1206,504,1260" alt="Play Store" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">
    //       <area shape="rect" coords="530,1210,730,1265" alt="Computer" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">

    //       <area shape="rect" coords="782,1247,796,1260" alt="Youtube" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">
    //       <area shape="rect" coords="812,1244,829,1260" alt="Twitter" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">

    //       <area shape="rect" coords="843,1246,860,1260" alt="Facebook" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">
    //       <area shape="rect" coords="874,1247,890,1262" alt="Telegram" href="https://html.com/images/how-to-make-an-image-map/" target="_blank">


    //   </map>`);
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  // async handleCampaignMediumChange(event: any) {
  //   var value = event.target.value;
  //   this.selectedCampaignMedium = value;
  //   console.log(value);
  //   if (value == 'email' || value == 'sms') {
  //     this.selectedTemplate = [];
  //     this.showHeaderOfTemplate = false;
  //     this.showHeaderMediaOfTemplate = false;
  //     this.showHeaderTextOfTemplate = false;
  //     this.showBodyOfTemplate = false;
  //     this.showFooterOfTemplate = false;
  //     this.showButtonOfTemplate = false;
  //     this.addCampaignForm = this.formBuilder.group({
  //       campaignName: this.campaignNameControl,
  //       campaignMedium: this.campaignMediumControl,
  //     });
  //     this.addCampaignForm.addControl('subject', this.subjectControl);
  //     this.addCampaignForm.addControl('body', this.bodyControl);
  //   } else if (value == 'whatsApp') {
  //     this.selectedTemplate = [];

  //     this.showHeaderOfTemplate = false;
  //     this.showHeaderMediaOfTemplate = false;
  //     this.showHeaderTextOfTemplate = false;
  //     this.showBodyOfTemplate = false;
  //     this.showFooterOfTemplate = false;
  //     this.showButtonOfTemplate = false;
  //     this.addCampaignForm = this.formBuilder.group({
  //       campaignName: this.campaignNameControl,
  //       campaignMedium: this.campaignMediumControl,
  //     });
  //     this.addCampaignForm.removeControl('subject');
  //     this.addCampaignForm.removeControl('body');
  //     // this.addCampaignForm.addControl('template', this.templateControl);
  //     await this.fetchAllTemplate();
  //   } else {
  //     this.selectedTemplate = [];

  //     this.showHeaderOfTemplate = false;
  //     this.showHeaderMediaOfTemplate = false;
  //     this.showHeaderTextOfTemplate = false;
  //     this.showBodyOfTemplate = false;
  //     this.showFooterOfTemplate = false;
  //     this.showButtonOfTemplate = false;
  //   }
  // }

  // async syncTemplate() {
  //   this.isLoading = true;

  //   try {
  //     let param = {
  //       clientPhone: "8976802882"
  //     };

  //     const httpHeaders = new HttpHeaders({
  //       Authorization: `Bearer ${this.userDetails['accessToken']}`,
  //     });
  //     let URL = `${WA_API.domain + WA_API.endPoint.syncAllTemplates}`;

  //     await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
  //       if (data['success']) {
  //         this.fetchAllTemplate();
  //       } else {
  //         this.fetchAllTemplate();
  //       }
  //     });
  //   } catch (error) {
  //     this.fetchAllTemplate();
  //   }
  // }
  // async fetchAllTemplate() {
  //   this.isLoading = true;
  //   this.listOfTemplate = [];
  //   try {
  //     let param = {
  //       phone: '8976802882',
  //     };

  //     const httpHeaders = new HttpHeaders({
  //       Authorization: `Bearer ${this.userDetails['accessToken']}`,
  //     });
  //     let URL = `${WA_API.domain + WA_API.endPoint.fetchAllTemplates}`;

  //     await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
  //       if (data['success']) {
  //         this.listOfTemplate = data['result'];
  //         this.listOfTemplate.forEach((template: any) => {
  //           template['nameWithLanguage'] =
  //             template['name'] + ' (language :- ' + template['language'] + ')';
  //         });
  //         this.listOfTemplateToDisplay = this.listOfTemplate;
  //         this.isLoading = false;
  //       } else {
  //         this.listOfTemplate = [];
  //         this.listOfTemplateToDisplay = this.listOfTemplate;

  //         this.isLoading = false;
  //       }
  //     });
  //   } catch (error) {
  //     this.listOfTemplate = [];
  //     this.listOfTemplateToDisplay = this.listOfTemplate;

  //     this.isLoading = false;
  //   }
  // }
  // onItemSelect(event: any) {
  selectTemplate(temp: any) {
    this.selectedTemplate = [];
    this.showHeaderOfTemplate = false;
    this.showHeaderMediaOfTemplate = false;
    this.showHeaderTextOfTemplate = false;
    this.showBodyOfTemplate = false;
    this.showFooterOfTemplate = false;
    this.showButtonOfTemplate = false;
    this.selectedTemplate.push(temp);
    console.log(this.selectedTemplate);
    this.listOfTemplate.forEach((tmplt: any) => {
      if (tmplt['_id'] == temp['_id']) {
        this.template = tmplt;
      }
    });
    this.template['components'].forEach((component: any) => {
      if (component['type'] == 'HEADER') {
        this.showHeaderOfTemplate = true;
        if (
          component['format'] == 'VIDEO' ||
          component['format'] == 'IMAGE' ||
          component['format'] == 'DOCUMENT'
        ) {
          this.showHeaderMediaOfTemplate = true;
          this.showHeaderTextOfTemplate = false;
        } else {
          this.showHeaderMediaOfTemplate = false;
          this.showHeaderTextOfTemplate = true;
          this.addCampaignForm.addControl(
            'headerText',
            new FormControl('', [
              Validators.required,
              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
            ])
          );
        }
      } else if (component['type'] == 'BODY') {
        this.showBodyOfTemplate = true;
        this.bodyVariableCount = component['text'].split('{{').length - 1;
        let variableCount = this.bodyVariableCount;
        if (variableCount > 0) {
          while (variableCount > 0) {
            this.addCampaignForm.addControl(
              `bodyText${variableCount}`,
              new FormControl('', [
                Validators.required,
                Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
              ])
            );
            variableCount--;
          }
        }
      } else if (component['type'] == 'FOOTER') {
        this.showFooterOfTemplate = true;
      } else {
        this.showButtonOfTemplate = true;
      }
    });
    this.showTemplateBody = true;
  }
  searchTemplateData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfTemplateToDisplay = [];

      this.listOfTemplate.forEach((tempate: any, index: number) => {
        if (
          String(tempate['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(this.getTemplateBody(tempate))
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(tempate);
        }
      });
      this.listOfTemplateToDisplay = [];
      this.listOfTemplateToDisplay = tempSearchList;
    } else {
      this.listOfTemplateToDisplay = this.listOfTemplate;
    }
  }
  counter(i: number) {
    return new Array(i);
  }
  getTemplateBody(template: any) {
    let toReturn = '';
    if (template.components.length > 0) {
      template.components.forEach((comp: any) => {
        if (comp['type'] == 'BODY') {
          toReturn = comp['text'];
        }
      });
    }
    return toReturn;
  }
  hideTemplateBody() {
    this.selectedTemplate = [];
    this.showHeaderOfTemplate = false;
    this.showHeaderMediaOfTemplate = false;
    this.showHeaderTextOfTemplate = false;
    this.showBodyOfTemplate = false;
    this.showFooterOfTemplate = false;
    this.showButtonOfTemplate = false;
    this.showTemplateBody = false;
    this.addCampaignForm = this.formBuilder.group({
      campaignName: this.campaignNameControl,
      campaignMedium: this.campaignMediumControl,
    });
  }
  displayHeaderMedia(event: any) {
    this.selectedHeaderMedia = event.target.files[0];
    this.selectedHeaderMediaName = this.selectedHeaderMedia['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      if (this.selectedHeaderMedia['type'].split('/')[0] == 'image') {
        this.defaultHeaderMedia = event1.target.result;
      } else {
        let extension =
          this.selectedHeaderMedia['name'].split('.')[
          this.selectedHeaderMedia['name'].split('.').length - 1
          ];
        if (extension == 'mp4' || extension == 'mp4') {
          this.defaultHeaderMedia = '../../../../../assets/img/video.jpg';
        } else if (
          extension == 'doc' ||
          extension == 'docx' ||
          extension == 'pdf'
        ) {
          this.defaultHeaderMedia = '../../../../../assets/img/doc.png';
        } else if (extension == 'mp3') {
          this.defaultHeaderMedia = '../../../../../assets/img/audio.jpg';
        } else {
          this.defaultHeaderMedia = '../../../../../assets/img/excel.png';
        }
      }
    };
    reader.readAsDataURL(this.selectedHeaderMedia);
  }
  displayExcel(event: any) {
    this.selectedCampaignExcel = event.target.files[0];
    this.selectedCampaignExcelName = this.selectedCampaignExcel['name'];
    var reader = new FileReader();
    this.excelTarget = event.target;
    reader.onload = (event1: any) => {
      this.defaultCampaignExcel = '../../../../assets/img/excel.png';
    };
    reader.readAsDataURL(this.selectedCampaignExcel);
  }

  displayImage(event: any) {
    this.selectedImage = event.target.files[0];
    this.selectedImageName = this.selectedImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }
  changeView(source: string) {
    if (source == 'VIEW') {
      this.isView = true;
    } else {
      this.isView = false;
    }
  }
  autoGrow(event: any) {
    this.htmlValue = event.target.value;
    this.addCampaignForm
      .get('body2')
      ?.setValue(this.htmlValue);
  }
  async addCampaign1() {
    console.log(this.addCampaignForm.get('body')?.value + '</br>' + this.addCampaignForm.get('body2')?.value);
    // console.log(this.addCampaignForm.get('body2')?.value);
  }
  async addCampaign() {
    this.isLoading = true;

    if (this.excelTarget.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(this.excelTarget.files[0]);

    var excelList: any = [];
    var userData: any = [];
    reader.onload = async (e: any) => {
      const storeNewxls: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(storeNewxls, {
        type: 'binary',
        cellDates: true,
      });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      excelList = data;
      userData = [];
      excelList.forEach((data: any) => {
        var params;
        if (this.selectedCampaignMedium == 'email') {
          params = {
            email: data['email'],
            firstName: data['firstName'],
            lastName: data['lastName'],
          };
          userData.push(params);
        } else {
          if (
            data['mobileNo'] != null &&
            data['mobileNo'] != undefined &&
            data['mobileNo'] != 'NULL'
          ) {
            params = {
              email: data['email'],
              firstName: data['firstName'],
              lastName: data['lastName'],
              mobileNo: data['mobileNo'],
            };
            userData.push(params);
          }
        }
      });
      console.log(userData);
      console.log(userData);

      this.formdata.set('userData', JSON.stringify(userData));
      this.formdata.set(
        'campaignName',
        this.addCampaignForm.get('campaignName')?.value
      );

      // const param = {
      //   userData: userData,
      //   campaignName: this.addCampaignForm.get('campaignName')?.value,
      //   subject: this.addCampaignForm.get('subject')?.value,
      //   body: this.addCampaignForm.get('body')?.value,
      // };

      if (this.selectedImage != null) {
        this.formdata.set('image', this.selectedImage);
      }
      if (this.selectedHeaderMedia != null) {
        this.formdata.set('headerMedia', this.selectedHeaderMedia);
      }
      if (this.selectedCampaignMedium == 'email') {
        this.formdata.set('campaignType', 'Email');
        this.formdata.set(
          'subject',
          this.addCampaignForm.get('subject')?.value
        );
        // this.formdata.set('body', this.addCampaignForm.get('body')?.value);
        this.formdata.set('body', this.addCampaignForm.get('body')?.value + '</br>' + this.addCampaignForm.get('body2')?.value);
      } else if (this.selectedCampaignMedium == 'whatsApp') {
        this.formdata.set('campaignType', 'WhatsApp');
        this.formdata.set('template', this.template['_id']);
        this.formdata.set('templateName', this.template['name']);
        this.formdata.set('templateLanguage', this.template['language']);

        let components: any = [];
        if (this.showHeaderOfTemplate && this.showHeaderMediaOfTemplate) {
          components.push({
            type: 'header',
            parameters: [
              {
                type: 'image',
                image: {
                  link: '',
                },
              },
            ],
          });
        }
        if (this.showHeaderOfTemplate && this.showHeaderTextOfTemplate) {
          components.push({
            type: 'header',
            parameters: [
              {
                type: 'text',
                text: {
                  link: this.addCampaignForm.get('headerText')!.value,
                },
              },
            ],
          });
        }
        if (this.showBodyOfTemplate) {
          components.push({
            type: 'body',
            parameters: [],
          });
          for (let index = 0; index < components.length; index++) {
            if (components[index]['type'] == 'body') {
              let variableCount = this.bodyVariableCount;
              if (variableCount > 0) {
                while (variableCount > 0) {
                  components[index]['parameters'].push({
                    type: 'text',
                    text: this.addCampaignForm.get(`bodyText${variableCount}`)!
                      .value,
                  });

                  variableCount--;
                }
              }
            }
          }

          // this.addCampaignForm.get('headerText')!.value,
        }
        this.formdata.set('whatsAppContent', JSON.stringify(components));
      } else {
        this.formdata.set('campaignType', 'SMS');
      }

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };
      let URL: any;
      URL = `${webApi.domain + webApi.endPoint.createCampaign}`;
      try {
        fetch(URL, requestOptions)
          .then((result) => {
            if (result) {
              let data = result.json();
              data.then((res) => {
                if (res['success']) {
                  this.isLoading = false;
                  this.addCampaignForm.reset();
                  this.cancel(res['result'][0]);
                } else {
                  this.isLoading = false;
                  this.cancel('Error');
                }
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.cancel('Error');
            console.log('error', error);
          });
        // const URL = `${webApi.domain + webApi.endPoint.createCampaign}`;
        // try {
        //   await this.as.addData(param, URL, httpHeaders).then((data: any) => {
        //     if (data['success']) {
        //       this.isLoading = false;
        //       this.addCampaignForm.reset();
        //       this.cancel(data['result']);
        //     } else {
        //       this.isLoading = false;
        //       this.cancel('Error');
        //     }
        //   });

        return;
      } catch (error) {
        this.isLoading = false;
        this.cancel('Error');
      }
    };
  }
}
