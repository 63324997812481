<div>
    <div mat-dialog-title class="display-flex align-items-center justify-content-space-between">
        <h4 class="m-0">
            Log Details
        </h4>
        <div class="display-flex align-items-center">
            <img src="../../../../assets/svgIcons/close.svg" (click)="close()" style="height: 1em;cursor: pointer;"
                alt="" srcset="">
        </div>
    </div>
    <div style="border-bottom: solid 1px gray;margin: 10px 0px 10px 0px;"></div>
    <mat-dialog-content class="mat-typography" style="overflow: hidden;">

        <div class="row mb-4" *ngIf="this.data['transaction']['user']!=null && this.data['transaction']['user']!=undefined">
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Name:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['user']['fullName']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Email:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['user']['email']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Phone:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['user']['phone']}}
                </span>
            </div>

        </div>

        <div class="row mb-4">
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Amount:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['paymentAmount']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Mode:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['transactionMode']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Status:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['transactionStatus']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Payment Source:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['transactionSource']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Payment Date:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['createdAt'] |date: 'dd/MM/yyyy hh:mm a'}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    Course:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['courses']==null || this.data['transaction']['courses']==undefined
                    ||
                    this.data['transaction']['courses'].length==0?'NA':
                    this.data['transaction']['courses'][0]['courseName']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center" *ngIf="this.data['transaction']['rzp_orderId']!=null && this.data['transaction']['rzp_orderId']!=undefined">
                <span class="detail-text">
                    RZP OrderId:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['rzp_orderId']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center" *ngIf="this.data['transaction']['rzp_paymentId']!=null && this.data['transaction']['rzp_paymentId']!=undefined">
                <span class="detail-text">
                    RZP PaymentId:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['rzp_paymentId']}}
                </span>
            </div>
            <!-- <div class="col-md-6 display-flex align-items-center" *ngIf="this.data['transaction']['paidSuccessOn']!=null && this.data['transaction']['paidSuccessOn']!=undefined">
                <span class="detail-text">
                    RZP PaymentId:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['paidSuccessOn']}}
                </span>
            </div> -->
        </div>

        <div class="row mb-4">
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    UTM Medium:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['utm_medium']==null ||
                    this.data['transaction']['utm_medium']==undefined?'NA':this.data['transaction']['utm_medium']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    UTM Source:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['utm_source']==null ||
                    this.data['transaction']['utm_source']==undefined?'NA':this.data['transaction']['utm_source']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    UTM Campaign:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['utm_campaign']==null ||
                    this.data['transaction']['utm_campaign']==undefined?'NA':this.data['transaction']['utm_campaign']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    UTM Content:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['utm_content']==null ||
                    this.data['transaction']['utm_content']==undefined?'NA':this.data['transaction']['utm_content']}}
                </span>
            </div>
            <div class="col-md-6 display-flex align-items-center">
                <span class="detail-text">
                    UTM Term:
                </span>
                <span class="detail-value">
                    {{this.data['transaction']['utm_term']==null ||
                    this.data['transaction']['utm_term']==undefined?'NA':this.data['transaction']['utm_term']}}
                </span>
            </div>

            <div class="col-md-6 display-flex align-items-center"
                *ngIf="this.data['transaction']['utm_term']==null || this.data['transaction']['utm_term']==undefined">
                <div class="row">
                    <div class="col-md-12 display-flex align-items-center"
                        *ngFor="let item  of this.data['transaction']['utm_params'] | keyvalue">
                        <span class="detail-text">
                            {{item.key}}:
                        </span>
                        <span class="detail-value">
                            {{item.value}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </mat-dialog-content>

</div>