import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListAdvisoryCommunityComponent } from './list-advisory-community/list-advisory-community.component';
import { TgadvisoryCommunityRoutingModule } from './tgadvisory-community-routing.module';
import { TgadvisoryCommunityComponent } from './tgadvisory-community.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { CommunityRoutingModule } from '../community/community-routing.module';
import { ComplianceRoutingModule } from '../compliance/compliance-routing.module';
import { TGAdvisorySocketioService } from './tgadvisory-socketio.service';
import { AddEditCommunityComponent } from './add-edit-community/add-edit-community.component';
import { BroadcastMessageComponent } from './broadcast-message/broadcast-message.component';
import { DeleteMessageComponent } from './delete-message/delete-message.component';
import { PinUnpinDialogComponent } from './pin-unpin-dialog/pin-unpin-dialog.component';
import { ViewCommunityParticipantComponent } from './view-community-participant/view-community-participant.component';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { MediaDialogComponent } from './media-dialog/media-dialog.component';
import { ParticipantAddDeleteDialogComponent } from './participant-add-delete-dialog/participant-add-delete-dialog.component';



@NgModule({
  declarations: [
    TgadvisoryCommunityComponent,
    ListAdvisoryCommunityComponent,
    AddEditCommunityComponent,
    BroadcastMessageComponent,
    DeleteMessageComponent,
    PinUnpinDialogComponent,
    ViewCommunityParticipantComponent,
    MediaDialogComponent,
    AudioRecorderComponent,
    ParticipantAddDeleteDialogComponent,
  ],
  imports: [
    CommonModule,
    TgadvisoryCommunityRoutingModule,
    CommonModule, CommunityRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    ComplianceRoutingModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [],
      },
    }),
  ],
  providers: [TGAdvisorySocketioService]
})
export class TgadvisoryCommunityModule { }
