import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteModuleActivityDialogComponent } from '../../courses/delete-module-activity-dialog/delete-module-activity-dialog.component';

@Component({
  selector: 'app-main-forum',
  templateUrl: './main-forum.component.html',
  styleUrls: ['./main-forum.component.scss'],
})
export class MainForumComponent implements OnInit {
  constructor(
    public as: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchQuestion();
    this.fetchReportedQuestion();
  }

  isLoading: boolean = false;
  p: any = 1;
  count: any = 50;
  listOfQuestions: any = [];
  listOfQuestionsToDisplay: any = [];
  listOfReportedQuestions: any = [];
  listOfReportedQuestionsToDisplay: any = [];

  searchForum(event: any) {
    // console.log(event.target.value);
    let serachQuery = event.target.value;
    let questionList: any = [];
    this.listOfQuestions = [...this.listOfQuestionsToDisplay];
    this.listOfReportedQuestions = [...this.listOfReportedQuestionsToDisplay];
    if (serachQuery != '') {
      this.listOfQuestions.forEach((question: any) => {
        if (
          String(question['userName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(question['question'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          questionList.push(question);
        }
      });
      this.listOfQuestions = [...questionList];
      this.listOfReportedQuestions = [...questionList];
    } else {
      this.listOfQuestions = [...this.listOfQuestionsToDisplay];
      this.listOfReportedQuestions = [...this.listOfReportedQuestionsToDisplay];
    }
  }

  async fetchQuestion() {
    this.isLoading = true;
    this.listOfQuestionsToDisplay = [];
    this.listOfQuestions = [];
    const param = {
      report: false,
    };
    const URL = `${webApi.domain + webApi.endPoint.fetchQuestion}`;
    await this.as.fetchForumFromService(param, URL).then((data: any) => {
      if (data['success']) {
        data['data'].forEach((data: any) => {
          if (data['isReported'] == true) {
          } else {
            this.listOfQuestions.push(data);
          }
        });
        this.listOfQuestionsToDisplay = this.listOfQuestions;

        // console.log(this.listOfQuestions);
        this.isLoading = false;
      }
    });
  }
  async fetchReportedQuestion() {
    this.isLoading = true;
    this.listOfReportedQuestions = [];
    this.listOfReportedQuestionsToDisplay = [];
    const param = {
      report: true,
    };
    const URL = `${webApi.domain + webApi.endPoint.fetchQuestion}`;
    await this.as.fetchForumFromService(param, URL).then((data: any) => {
      if (data['success']) {
        this.listOfReportedQuestions = data['data'];
        this.listOfReportedQuestionsToDisplay = this.listOfReportedQuestions;

        // console.log(this.listOfReportedQuestions);
        this.isLoading = false;
      }
    });
  }
  deleteQuestion(question: any, report: boolean) {
    if (report) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { source: 'Delete Question', question: question };
      let dialogRef = this.matDialog.open(
        DeleteModuleActivityDialogComponent,
        dialogConfig
      );
      dialogRef.updatePosition(dialogConfig.position);
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
        }
      });
      dialogRef.componentInstance.closeActivityAtDialog.subscribe(
        async (res) => {
          // console.log(res);
          if (res == null) {
            this.listOfReportedQuestions.forEach((question: any) => {
              question['isDeleting'] = false;
            });
            return;
          } else {
            this.listOfReportedQuestions.forEach((question: any) => {
              if (question['_id'] == res['value']['_id']) {
                question['isDeleting'] = true;
              }
            });
            const param = {
              questionId: res['value']['_id'],
            };
            const URL = `${webApi.domain + webApi.endPoint.deleteQuestion}`;
            await this.as
              .deleteQuestionFromService(param, URL)
              .then((data: any) => {
                if (data['success']) {
                  this.listOfReportedQuestions.forEach(
                    (question: any, index: number) => {
                      if (question['_id'] == res['value']['_id']) {
                        this.listOfReportedQuestions.splice(index, 1);
                      }
                    }
                  );
                  this.showSuccessToaster(
                    'Question Delete Successfully',
                    'Success'
                  );
                } else {
                  this.listOfReportedQuestions.forEach(
                    (question: any, index: number) => {
                      if (question['_id'] == res['value']['_id']) {
                        question['isDeleting'] = false;
                      }
                    }
                  );
                  this.showErrorToaster(
                    'Error Occurred while deleteing Question. Try again',
                    'Error'
                  );
                }
              });
          }
        }
      );
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { source: 'Delete Question', question: question };
      let dialogRef = this.matDialog.open(
        DeleteModuleActivityDialogComponent,
        dialogConfig
      );
      dialogRef.updatePosition(dialogConfig.position);
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
        }
      });
      dialogRef.componentInstance.closeActivityAtDialog.subscribe(
        async (res) => {
          // console.log(res);
          if (res == null) {
            this.listOfQuestions.forEach((question: any) => {
              question['isDeleting'] = false;
            });
            return;
          } else {
            this.listOfQuestions.forEach((question: any) => {
              if (question['_id'] == res['value']['_id']) {
                question['isDeleting'] = true;
              }
            });
            const param = {
              questionId: res['value']['_id'],
            };
            const URL = `${webApi.domain + webApi.endPoint.deleteQuestion}`;
            await this.as
              .deleteQuestionFromService(param, URL)
              .then((data: any) => {
                if (data['success']) {
                  this.listOfQuestions.forEach(
                    (question: any, index: number) => {
                      if (question['_id'] == res['value']['_id']) {
                        this.listOfQuestions.splice(index, 1);
                      }
                    }
                  );
                  this.showSuccessToaster(
                    'Question Delete Successfully',
                    'Success'
                  );
                } else {
                  this.listOfQuestions.forEach(
                    (question: any, index: number) => {
                      if (question['_id'] == res['value']['_id']) {
                        question['isDeleting'] = false;
                      }
                    }
                  );
                  this.showErrorToaster(
                    'Error Occurred while deleteing Question. Try again',
                    'Error'
                  );
                }
              });
          }
        }
      );
    }
  }

  suspendReportedQuestion(question: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Suspend Question', question: question };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        const param = {
          questionId: res['value']['_id'],
          report: '',
          suspend: res['value']['isSuspended'] ? false : true,
        };
        const URL = `${webApi.domain + webApi.endPoint.actionOnQuestion}`;
        await this.as
          .actionOnQuestionFromService(param, URL)
          .then((data: any) => {
            if (data['success']) {
              this.listOfReportedQuestions.forEach(
                (question: any, index: number) => {
                  if (question['_id'] == res['value']['_id']) {
                    question['isSuspended'] = !res['value']['isSuspended'];
                  }
                }
              );
              this.showSuccessToaster(
                `Question ${
                  res['value']['isSuspended'] ? 'Unpublishing' : 'Publishing'
                } Successfully`,
                'Success'
              );
            } else {
              this.listOfReportedQuestions.forEach(
                (question: any, index: number) => {
                  if (question['_id'] == res['value']['_id']) {
                    question['isSuspended'] = !res['value']['isSuspended'];
                  }
                }
              );
              this.showErrorToaster(
                `Error Occurred while ${
                  res['value']['isSuspended'] ? 'Unpublishing' : 'Publishing'
                } Question. Try again`,
                'Error'
              );
            }
          });
      }
    });
  }

  suspendQuestion(question: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Suspend Question', question: question };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        const param = {
          questionId: res['value']['_id'],
          report: '',
          suspend: res['value']['isSuspended'] ? false : true,
        };
        const URL = `${webApi.domain + webApi.endPoint.actionOnQuestion}`;
        await this.as
          .actionOnQuestionFromService(param, URL)
          .then((data: any) => {
            if (data['success']) {
              this.listOfQuestions.forEach((question: any, index: number) => {
                if (question['_id'] == res['value']['_id']) {
                  question['isSuspended'] = !res['value']['isSuspended'];
                }
              });
              this.showSuccessToaster(
                `Question ${
                  res['value']['isSuspended'] ? 'Unpublishing' : 'Publishing'
                } Successfully`,
                'Success'
              );
            } else {
              this.listOfQuestions.forEach((question: any, index: number) => {
                if (question['_id'] == res['value']['_id']) {
                  question['isSuspended'] = res['value']['isSuspended'];
                }
              });
              this.showErrorToaster(
                `Error Occurred while ${
                  res['value']['isSuspended'] ? 'Unpublishing' : 'Publishing'
                } Question. Try again`,
                'Error'
              );
            }
          });
      }
    });
  }

  async suspendUserFromForum(question: any, suspend: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { source: 'Suspend User', question: question };
    let dialogRef = this.matDialog.open(
      DeleteModuleActivityDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        this.listOfReportedQuestions.forEach((question: any) => {
          question['isDeleting'] = false;
        });
        return;
      } else {
        const param = {
          userId: res['value']['userId'],
          suspend: suspend,
          suspensionDate: Date.now().toString(),
        };

        const URL = `${webApi.domain + webApi.endPoint.suspendUser}`;
        await this.as
          .suspendUserFromForumFromService(param, URL)
          .then((data: any) => {
            if (data['success']) {
              this.showSuccessToaster(
                'User Suspended  Successfully',
                'Success'
              );
            } else {
              this.showErrorToaster(
                'Error Occurred while suspending User. Try again',
                'Error'
              );
            }
          });
      }
    });
  }

  questionDetails(question: any) {
    this.as.questionToAccessFromComment = question;
    this.router.navigate(['/catalog/forum/question-details']);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
