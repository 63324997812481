import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-webinar',
  templateUrl: './view-webinar.component.html',
  styleUrls: ['./view-webinar.component.scss'],
})
export class ViewWebinarComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  windowRef: any;
  webinarViewDetail: any;
  webinarDetail: any;
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ViewWebinarComponent>,
    public as: AppService,
    public appService: AppService,
    public routes: ActivatedRoute,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.webinarDetail = this.data['webinar'];
    this.windowRef = this.as.windowRef;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
}
