import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { ActivityComponent } from '../../courses/activity/activity.component';

@Component({
  selector: 'app-add-edit-batch',
  templateUrl: './add-edit-batch.component.html',
  styleUrls: ['./add-edit-batch.component.scss']
})
export class AddEditBatchComponent implements OnInit {
  @ViewChild('stepper') private myStepper?: MatStepper;
  @ViewChild(ActivityComponent) private activityComponent!: ActivityComponent;

  showCourseDetails: boolean = true;
  showModuleDetails: boolean = false;
  showActivityDetails: boolean = false;
  selectedStepIndex: any = 0;
  courseDetail: any;
  isLoading: boolean = true;
  batchId: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public _activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this._activatedRoute.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.batchId = allParam['params']['id'];
    });
  }


  ngOnInit(): void {
    this.isLoading = false;
  }

  stepClicked(source: string) {
    if (source == 'Course') {
      this.showCourseDetails = true;
      this.showModuleDetails = false;
      this.showActivityDetails = false;
    } else if (source == 'Module') {
      this.showCourseDetails = false;
      this.showModuleDetails = true;
      this.showActivityDetails = false;
    } else {
      this.showCourseDetails = false;
      this.showModuleDetails = false;
      this.showActivityDetails = true;
    }
  }

  moveToPrevious() {
    this.myStepper?.previous();
  }
  moveToModule(event: any) {
    this.myStepper?.next();
  }
  moveToActivity(event: any) {
    if (event) {
      this.as.moduleDetailForActivity = event;
      this.showActivityDetails = true;
    }
    this.activityComponent.callManual();
    this.myStepper?.next();
  }
}
