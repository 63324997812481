import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { WA_API, webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-update-utm-source-dialog',
  templateUrl: './update-utm-source-dialog.component.html',
  styleUrls: ['./update-utm-source-dialog.component.scss']
})
export class UpdateUtmSourceDialogComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  transactionDetail: any;
  userDetails: any;


  myHeaders = new Headers();
  formdata = new FormData();

  isLoading: boolean = false;

  utmSourceControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  addEditcategoryForm: FormGroup = new FormGroup({
    utmSource: this.utmSourceControl,
  });
  constructor(
    public dialogRef: MatDialogRef<UpdateUtmSourceDialogComponent>,
    public appService: AppService,
    public routes: ActivatedRoute, private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(data);
    this.transactionDetail = data['transaction'];
    this.userDetails = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

  }

  ngOnInit(): void {
    this.addEditcategoryForm = this.formBuilder.group({
      utmSource: this.utmSourceControl,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.transactionDetail != null) {
      this.addEditcategoryForm.get('utmSource')?.setValue(this.transactionDetail['utm_source'] == null ||
        this.transactionDetail['utm_source'] == undefined ? (this.transactionDetail['transactionSource'] == 'APP' ? 'In App' : (this.transactionDetail['transactionSource'] == 'WEB' ? 'In Web' : this.transactionDetail['transactionSource'])) : this.transactionDetail['utm_source']);
    }
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  cancel(value: any) {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(value);
  }
  editUTMSource() {
    this.isLoading = true;
    this.formdata.append('transactionId', this.transactionDetail['_id']);


    this.formdata.append('utm_source', this.addEditcategoryForm.get('utmSource')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.updateUTMSourceOfTransaction}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['type']) {
                this.isLoading = false;
                this.addEditcategoryForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel(null);
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel(null);
          console.log(null, error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel(null);
    }
  }
}
