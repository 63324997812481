<div>
    <div mat-dialog-title class="display-flex align-items-center justify-content-space-between">
        <h4 class="m-0">
            Value Change
        </h4>
        <div class="display-flex align-items-center">
            <img src="../../../../assets/svgIcons/close.svg" (click)="close()" style="height: 1em;cursor: pointer;"
                alt="" srcset="">
        </div>
    </div>
    <div style="border-bottom: solid 1px gray;margin: 10px 0px 10px 0px;"></div>
    <mat-dialog-content class="mat-typography" style="overflow: hidden;">

        <div *ngFor="let item  of data['audit']['value'] | keyvalue">
           <div>
            {{item.key}} : {{item.value}}
           </div>  
        </div>


    </mat-dialog-content>

</div>