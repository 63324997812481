<div style="height: 100vh;">
    <div ng-sidebar-content class="sidebar-content">
        <!-- Top Data -->
        <div class="row align-center" style="justify-content: space-between;">

            <div class="col-md-3 top-heading" style="cursor: pointer;">
                <span style="font-size: 1.5rem;cursor: pointer;">
                    Log ({{isLoading?'0':(listOfVideoLogToDisplay.length>0?listOfVideoLogToDisplay[0]['youtubeVideo']['viewCount']:'0' )}})
                </span>
            </div>
            <div class="col-md-9">
                <div class="row align-center">
                    <div class="col-md-6"
                        style="text-align: right;display: flex;align-items: center;justify-content: end;padding-top: 15px;">
                        <mat-form-field appearance="outline">
                            <mat-label>Pick a date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="Start date" [value]="startDate"
                                    (dateChange)="dateChanged( 'start',$event)">
                                <input matEndDate placeholder="End date" [value]="endDate"
                                    (dateChange)="dateChanged( 'end',$event)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker" >
                            </mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <div (click)="clearDateRange()"
                            style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 15px; margin-bottom: 1.3rem;">
                            Clear
                        </div>
                    </div>
                    <!-- <div class="col-md-5" style="text-align: right;">
                        <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                            [(ngModel)]="searchString" placeholder="Search Here" (input)="searchTransLog($event)">
                    </div> -->

                    <!-- <div class="col-md-1">
                        <div *ngIf="downloadReport">
                            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                        <div *ngIf="!downloadReport">

                            <img (click)="generateLogReport()" src="../../../../assets/img/download-circular-button.png"
                                style="height: 1.5em;cursor: pointer;" title="Download leads" alt="">
                        </div>
                    </div> -->
                </div>
            </div>

        </div>
        <!-- Table Data -->

        <div *ngIf="isLoading">
            <div class="center-loading">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </div>
        <div *ngIf="listOfVideoLogToDisplay.length==0 && !isLoading">
            <div class="no-available">
                No transaction found
            </div>
        </div>
        <div *ngIf="listOfVideoLogToDisplay.length>0 && !isLoading">
            <table class="table-style" cellspacing="0" cellpadding="0">
                <thead class="table-heading">

                    <th style="cursor: pointer;text-align: left;padding-left:2rem;" class="table-heading-content">
                        Name</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        Email</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        Phone</th>
                    <th style="cursor: pointer;" class="table-heading-content">
                        Date</th>



                </thead>
                <!-- <div style="height: 0.6em;"> </div> -->
                <tbody>
                    <tr class="table-data"
                        *ngFor="let transa of listOfVideoLogToDisplay | paginate: { itemsPerPage: count, currentPage: p };let i=index">

                        <th class="table-data-content">{{transa['user']['fullName']==null ||
                            transa['user']['fullName']==undefined?'NA':transa['user']['fullName']}}</th>
                        <th class="table-data-content">{{transa['user']['email']}}</th>
                        <th class="table-data-content">{{transa['user']==null || transa['user']==undefined?'NA':
                            transa['user']['phone']}}</th>
                        <th class="table-data-content">{{transa.createdAt |date: 'dd/MM/yyyy hh:mm a'}}</th>

                    </tr>
                </tbody>
            </table>
            <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
            <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>
        </div>



    </div>
    <!-- </ng-sidebar-container> -->
</div>