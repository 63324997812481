import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ActivityComponent } from '../activity/activity.component';

@Component({
  selector: 'app-add-new-course',
  templateUrl: './add-new-course.component.html',
  styleUrls: ['./add-new-course.component.scss'],
})
export class AddNewCourseComponent implements OnInit {
  @ViewChild('stepper') private myStepper?: MatStepper;
  @ViewChild(ActivityComponent) private activityComponent!: ActivityComponent;

  showCourseDetails: boolean = true;
  showModuleDetails: boolean = false;
  showActivityDetails: boolean = false;
  selectedStepIndex: any = 0;
  courseDetail: any;
  isLoading: boolean = true;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {

  }


  ngOnInit(): void {
    if (this.as.courseDetailToEdit != null) {
      this.courseDetail = this.as.courseDetailToEdit;
    }
    this.as.courseDetailToEdit = null;
    // console.log(this.courseDetail);
    this.isLoading = false;

  }

  stepClicked(source: string) {
    if (source == 'Course') {
      this.showCourseDetails = true;
      this.showModuleDetails = false;
      this.showActivityDetails = false;
    } else if (source == 'Module') {
      this.showCourseDetails = false;
      this.showModuleDetails = true;
      this.showActivityDetails = false;
    } else {
      this.showCourseDetails = false;
      this.showModuleDetails = false;
      this.showActivityDetails = true;
    }
  }

  moveToPrevious() {
    this.myStepper?.previous();
  }
  moveToModule(event: any) {
    this.myStepper?.next();
  }
  moveToActivity(event: any) {
    if (event) {
      this.as.moduleDetailForActivity = event;
      this.showActivityDetails = true;
    }
    this.activityComponent.callManual();
    this.myStepper?.next();
  }
}
