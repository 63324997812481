<div class="container__community">

    <div class="container__community__userList">
        <div class="container__community__userList__header">
            <div class="container__community__userList__header__title">
                Advisory Community
            </div>
            <div class="row" style="justify-content: space-around;">
                <div class="icon-div" (click)="broadcastMessage()">
                    <img src="../../../../assets/img/message-edit.png" style="height: 1.7em;">
                </div>
                <div class="icon-div" style="top: 43px;" (click)="addEditCommunity(null)">
                    <img src="../../../../assets/img/message-add.png" style="height: 1.7em;">
                </div>
                <!-- <div class="icon-div" style="top: 82px;" (click)="gotoReportedMessage()">
                    <img src="../../../../assets/img/message-report.png" style="height: 1.7em;">
                </div> -->

            </div>

        </div>
        <div style="display: flex;align-items: center;justify-content: space-around;    margin-bottom: 1em;">
            <div style="position: relative;    width: 75%;    max-width: 75%;">
                <input class="container__community__userList__searchBar" type="text"
                    placeholder="Search by advisory community name...." [(ngModel)]="this.socketService.searchString"
                    (keyup.enter)="searchCommunityData()" (input)="searchData($event.target)" />
                <img class="searchIcon" src="../../../../../assets/svgIcons/searchIcon.svg" />
            </div>
            <div style="background-color: #DBDBE3;padding: 10px 1em;border-radius: 10px;position: relative;"
                (click)="filterCommunity()">
                <div *ngIf="filterObject!=null"
                    style="position: absolute;height: 10px;width: 10px;border-radius: 100%;background-color: red;top: -3px;right: 0px;">
                </div>
                <img src="../../../../assets/img/sort.png" style="height: 1em;">
            </div>
        </div>
        <div class="userList" style="display: flex;align-items: center;" *ngIf="isLoading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
        <div class="userList" *ngIf="!isLoading && socketService.listOfCommunityToDisplay.length == 0 "
            style="display: flex; align-items: center; justify-content: center">
            No Data Available
        </div>
        <div class="userList" *ngIf="!isLoading && socketService.listOfCommunityToDisplay.length > 0 ">
            <div class="container__community__userList__users" style="cursor: pointer" (click)="selectCommunity(info)"
                *ngFor="let info of socketService.listOfCommunityToDisplay | paginate: {id: 'pagination', itemsPerPage: this.socketService.count, currentPage: this.socketService.Page }"
                [ngStyle]="{'background-color':(socketService.selectedCommunity != null &&socketService.selectedCommunity != undefined? socketService.selectedCommunity['_id']: '') == info['_id']? '#e9f6ee': 'white'}">
                <div>
                    <img *ngIf="info.image" style="width: 3rem; height: 3rem; margin-right: 1rem" class="text-2"
                        [src]="info.image" />
                    <div *ngIf="!info.image" class="text-2">
                        {{ service.getAvatarText(info.name) }}
                    </div>
                </div>
                <div class="dFlex">
                    <div style="display: flex">
                        <div class="container__community__userList__users__name">
                            {{ info.name }}
                        </div>
                    </div>
                    <div class="container__community__userList__users__msg"
                        [title]="info.latestMessage != null && info.latestMessage != undefined? (info.latestMessage.content.type == 'Text'? info.latestMessage.content['text']: 'Document'): ''"
                        [innerHtml]="info.latestMessage != null && info.latestMessage != undefined? (info.latestMessage.content.type
                        == 'Text'? URLReplacer(info.latestMessage.content['text']): 'Document'):''">
                        <!-- {{
                        info.latestMessage != null && info.latestMessage != undefined? (info.latestMessage.content.type
                        == "Text"? info.latestMessage.content["text"]: "Document"): ""}} -->
                    </div>
                </div>
                <div style="min-width: 3em; max-width: 4em">
                    <div style="display: flex">
                        <span class="container__community__userList__users__time">
                            {{
                            getDuration(info.updatedAt) == "TODAY"
                            ? (info.updatedAt | date : "shortTime")
                            : (info.updatedAt | date : "dd/MM/yyyy h:mm a")
                            }}
                        </span>

                        <img (click)="pinCommunity(info,false)" *ngIf="!info.isPinned"
                            src="../../../../assets/img/pin.png" style="height: 1em;">
                        <img (click)="pinCommunity(info,true)" *ngIf="info.isPinned"
                            src="../../../../assets/img/unpin.png" style="height: 1em;">

                    </div>
                    <div class="container__community__userList__users__tag"
                        [ngStyle]="{'background-color':(socketService.selectedCommunity != null && socketService.selectedCommunity != undefined ? socketService.selectedCommunity['_id']  : '') == info['_id']? '#cdecd9': '#E9F6EE'}"
                        *ngIf="info.tag != null && info.tag != undefined && info.tag != ''">
                        {{ info.tag }}
                    </div>
                </div>
            </div>


        </div>
        <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium; margin: 1em">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
            </mat-progress-spinner>
        </div>
        <div *ngIf="!isLoadingExtraData" class="container__community__pagination">
            <pagination-controls style="grid-column: 1/-1; font-size: 12px; justify-self: end" id="pagination"
                (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
    <div class="container__community__chatBox"
        *ngIf="socketService.selectedCommunity != null && socketService.selectedCommunity != undefined">
        <div class="container__community__chatBox__header">
            <div>
                <img *ngIf="socketService.selectedCommunity['image']"
                    style="width: 3rem; height: 3rem; margin-right: 1rem" class="text-2"
                    [src]="socketService.selectedCommunity.image" />
                <div *ngIf="!socketService.selectedCommunity['image']" class="text-2">
                    {{ service.getAvatarText(socketService.selectedCommunity.name) }}
                </div>
            </div>
            <div class="dFlex">
                <div class="container__community__chatBox__header__name">
                    {{
                    socketService.selectedCommunity.name
                    }}
                </div>
            </div>
            <div style="display: flex; align-items: center">
                <div mat-button [matMenuTriggerFor]="menu" style="display: flex;align-items: center;cursor: pointer;">
                    <img class="menu-icon" src="../../../../assets/img/vertical.png" alt="" srcset="" />
                </div>
                <mat-menu #menu="matMenu">
                    <div class="menu" mat-menu-item (click)="viewCommunityPaticipant(socketService.selectedCommunity)">
                        View Participant
                    </div>
                    <div class="menu" mat-menu-item (click)="getCommunityLink(socketService.selectedCommunity)">
                        Get Link
                    </div>
                    <div class="menu" mat-menu-item (click)="addEditCommunity(socketService.selectedCommunity['_id'])">
                        Edit
                    </div>
                    <div class="menu" mat-menu-item (click)="deleteCommunity(socketService.selectedCommunity)">
                        Delete
                    </div>
                </mat-menu>
                <!-- <img src="../../../../assets/img/info.png" style="height: 1.5em;cursor: pointer;"
                    (click)="addEditCommunity(socketService.selectedCommunity['_id'])"> -->

            </div>
        </div>
        <div class="container__community__content" *ngIf="this.socketService.isLoading">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
            </mat-progress-spinner>
        </div>
        <div class="container__community__chatBox__content" #content [scrollTop]="content.scrollHeight"
            *ngIf="!this.socketService.isLoading">
            <div *ngFor="let message of this.socketService.listOfMessage; let i = index"
                style="display: flex; flex-direction: column"
                [ngStyle]="{ 'align-items': message.sentBy == 'User' ? 'start' : 'end'}">
                <div class="duration" *ngIf="i == 0">
                    <div
                        style=" background-color: rgb(128 128 128 / 70%);color: white;padding: 0.3rem 0.6rem;border-radius: 8px;font-size: 13px;">
                        {{ getDuration(message.createdAt) }}
                    </div>
                </div>
                <div class="duration"
                    *ngIf="i > 0 && differenceDays(this.socketService.listOfMessage[i].createdAt,this.socketService.listOfMessage[i - 1].createdAt) >= 1">
                    <div
                        style="background-color: rgb(128 128 128 / 70%);color: white; padding: 0.3rem 0.6rem;border-radius: 8px;font-size: 13px;">
                        {{ getDuration(message.createdAt) }}
                    </div>
                </div>
                <div style="display: flex;align-items: flex-start;">
                    <div *ngIf="message.sentBy == 'User'">
                        <div class="avtr"
                            *ngIf="message['user']['avatar']!=null && message['user']['avatar']!=undefined && message['user']['avatar']!=''">
                            <img [src]="message['user']['avatar']"
                                style="height: 35px;width: 35px;object-fit: fill;border-radius: 100%;margin-right: 20px;">
                        </div>
                        <div class="avtr"
                            *ngIf="message['user']['avatar']==null || message['user']['avatar']==undefined || message['user']['avatar']==''"
                            style="margin-right: 15px; border-radius: 100%;    background: #d9d9d9;">
                            {{this.service.getAvatarText(message['user']['fullName']!=null &&
                            message['user']['fullName']!=undefined?message['user']['fullName']:message['user']['phone'])}}
                        </div>
                    </div>
                    <div class="message" style="position: relative;"
                        [ngClass]="{'sent':message.sentBy != 'User' ,'received':message.sentBy == 'User'}">
                        <div style="position: absolute;top: 0px;right: 0px;">



                            <div mat-button [matMenuTriggerFor]="menu"
                                style="display: flex;align-items: center;cursor: pointer;">
                                <img class="menu-icon" src="../../../../assets/svgIcons/arrow-down.svg" alt=""
                                    srcset="" />
                            </div>
                            <mat-menu #menu="matMenu">
                                <div class="menu" mat-menu-item (click)="setReplyMsg(message)">
                                    Reply
                                </div>
                                <div class="menu" mat-menu-item (click)="deleteMsg(message)">
                                    Delete
                                </div>
                            </mat-menu>
                            <img *ngIf="message.reportCount>0" src="../../../../assets/img/msg-report.png"
                                style="height: 1.5em;">
                        </div>
                        <div style="display: flex;align-items: center;justify-content: space-between;"
                            *ngIf="message.contextMessage!=null && message.contextMessage!=undefined">
                            <div class="contextmsg-content">
                                <div
                                    style="display: flex;align-items: center;justify-content: space-between;height: 2rem;">
                                    <div>
                                        <div *ngIf="message.contextMessage.sentBy == 'User'"
                                            style="font-size: 13px;font-weight: 600;color: #515259;">
                                            {{message.contextMessage['user']['fullName']}}
                                        </div>
                                        <div *ngIf="message.contextMessage.sentBy == 'Admin'"
                                            style="font-size: 13px;font-weight: 600;color: #515259;">
                                            You
                                        </div>
                                        <div style="display: flex;align-items: center;justify-content: flex-start;">
                                            <div *ngIf="message.contextMessage.content['text'] != null &&message.contextMessage.content['text'] != undefined &&message.contextMessage.content['text'] != ''"
                                                style="   width: 90%;" class="reply-message-content"
                                                [innerHtml]="URLReplacer(message.contextMessage.content['text'])"></div>
                                        </div>

                                    </div>
                                    <div *ngIf="message.contextMessage.content['type'] == 'Image'">
                                        <img class="contextmsg-image-content"
                                            [src]="message.contextMessage.content['url']" />
                                    </div>
                                    <div
                                        *ngIf="message.contextMessage.content['type'] == 'Document' ||message.contextMessage.content['type'] == 'Audio'">


                                        <img src="../../../../assets/wa-img/pdf.png"
                                            *ngIf="getFileExtension(message.contextMessage.content.url) == 'pdf'"
                                            class="contextmsg-image-content" />


                                        <img src="../../../../assets/wa-img/doc.png"
                                            *ngIf="getFileExtension(message.contextMessage.content.url) == 'doc' || getFileExtension(message.contextMessage.content.url) == 'docx'"
                                            class="contextmsg-image-content" />


                                        <img src="../../../../assets/wa-img/sheets.png"
                                            *ngIf="getFileExtension(message.contextMessage.content.url) == 'csv' ||getFileExtension(message.contextMessage.content.url) == 'xls' || getFileExtension(message.contextMessage.content.url) == 'ods' || getFileExtension(message.contextMessage.content.url) == 'xlsx'"
                                            class="contextmsg-image-content" />


                                        <img [src]="message.contextMessage.content.url"
                                            *ngIf="getFileExtension(message.contextMessage.content.url) == 'jpeg' || getFileExtension(message.contextMessage.content.url) == 'png' ||getFileExtension(message.contextMessage.content.url) == 'jpg'"
                                            style="max-height: 2rem;background: none;" />


                                        <audio controls style="width: 25em"
                                            *ngIf="getFileExtension(message.contextMessage.content.url) == 'mp3' ||getFileExtension(message.contextMessage.content.url) == 'wav'">
                                            <source [src]="sanitize(message.contextMessage.content.url)"
                                                type="audio/wav" />
                                        </audio>

                                        <img src="../../../../assets/wa-img/video.png"
                                            *ngIf="getFileExtension(message.contextMessage.content.url) == 'mp4'"
                                            class="contextmsg-image-content" />


                                        <img src="../../../../assets/wa-img/txt.png"
                                            *ngIf="getFileExtension(message.contextMessage.content.url) == 'txt'"
                                            class="contextmsg-image-content" />

                                    </div>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" message.sentBy == 'User'">
                            <span style="font-size: 12px;color: #515259;font-weight: 600;">
                                {{message['user']['fullName']}}</span>
                            <span
                                style="font-size: 11px;color: #84858E;font-weight: 400;margin-left: 1em;">{{message['user']['phone']}}</span>
                        </div>
                        <div *ngIf="message.content['type'] == 'Image'"
                            style="display: flex;align-items: center;justify-content: center;">
                            <img class="image-content" [src]="message.content['url']" />
                        </div>
                        <div *ngIf=" message.content['type'] == 'Document' || message.content['type'] == 'Audio'"
                            class="message-content" style="position: relative;padding-bottom: 1em;width: 21rem;"
                            [ngStyle]="{'border-bottom':message.content['text'] != null &&message.content['text'] != undefined &&message.content['text'] != ''?'solid 1px gray':'none'}">

                            <div class="media-div" *ngIf="getFileExtension(message.content.url) == 'pdf'">
                                <img src="../../../../assets/wa-img/pdf.png" alt="" srcset=""
                                    style="max-height: 2rem; background: none;" />
                                <div>
                                    {{message.content.docName}}
                                </div>
                            </div>
                            <div class="media-div"
                                *ngIf="getFileExtension(message.content.url) == 'doc' || getFileExtension(message.content.url) == 'docx'">
                                <img src="../../../../assets/wa-img/doc.png" alt="" srcset=""
                                    style="max-height: 2rem; background: none;" />
                                <div>
                                    {{message.content.docName}}
                                </div>
                            </div>
                            <div class="media-div"
                                *ngIf="getFileExtension(message.content.url) == 'csv' ||getFileExtension(message.content.url) == 'xls' || getFileExtension(message.content.url) == 'ods' || getFileExtension(message.content.url) == 'xlsx'">
                                <img src="../../../../assets/wa-img/sheets.png" alt="" srcset=""
                                    style="max-height: 2rem; background: none;" />
                                <div>
                                    {{message.content.docName}}
                                </div>
                            </div>
                            <div class="media-div"
                                *ngIf="getFileExtension(message.content.url) == 'jpeg' || getFileExtension(message.content.url) == 'png' ||getFileExtension(message.content.url) == 'jpg'">
                                <img [src]="message.content.url" alt="" srcset=""
                                    style="max-height: 2rem;background: none;" />
                                <div>
                                    {{message.content.docName}}
                                </div>
                            </div>
                            <div class="media-div"
                                *ngIf="getFileExtension(message.content.url) == 'mp3' ||getFileExtension(message.content.url) == 'wav'">
                                <audio controls style="width: 25em">
                                    <source [src]="sanitize(message.content.url)" type="audio/wav" />
                                </audio>
                            </div>
                            <div class="media-div" *ngIf="getFileExtension(message.content.url) == 'mp4'">
                                <img src="../../../../assets/wa-img/video.png" alt="" srcset=""
                                    style="max-height: 2rem; background: none;" />
                                <div>
                                    {{message.content.docName}}
                                </div>
                            </div>
                            <div class="media-div" *ngIf="getFileExtension(message.content.url) == 'txt'">
                                <img src="../../../../assets/wa-img/txt.png" alt="" srcset=""
                                    style="max-height: 2rem; background: none;" />
                                <div>
                                    {{message.content.docName}}
                                </div>
                            </div>

                            <label class="img-download" (click)="downloadImage(message.content.url)"
                                *ngIf="message.content['type'] != 'Audio'">
                                <img src="../../../../assets/img/download-circular-button.png" style="height: 2em;"
                                    srcset="">
                            </label>
                        </div>
                        <p *ngIf="message.content['text'] != null &&message.content['text'] != undefined &&message.content['text'] != ''"
                            style="    display: block;" class="message-content"
                            [innerHtml]="URLReplacer(message.content['text'])">
                            <!-- {{ URLReplacer(message.content["text"]) }} -->
                        </p>
                        <div class="time" style="display: flex; align-items: center;justify-content: space-between;">
                            <div style="font-size: 9px; color: black;cursor: pointer;"
                                (click)="viewMessageSeenUser(message._id)">
                                <img src="../../../../assets/img/batch-publish.png" class="visibility">
                                {{message.viewCount!=null && message.viewCount!=undefined?message.viewCount:0}}
                            </div>
                            <div style="display: flex; align-items: center;justify-content: end">
                                <div style="font-size: 9px; color: black">
                                    {{ message.createdAt | date : "h:mm a" }}
                                </div>
                                <img class="tick" src="../../../../assets/svgIcons/twotick.svg" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="message.sentBy == 'Admin'">
                        <div class="avtr"
                            *ngIf="this.socketService.selectedCommunity['image'] !=null && this.socketService.selectedCommunity['image'] !=undefined && this.socketService.selectedCommunity['image'] !=''">
                            <img [src]="this.socketService.selectedCommunity['image'] "
                                style="margin-left: 20px;height: 35px;width: 35px;object-fit: fill;border-radius: 100%;">
                        </div>
                        <div class="avtr"
                            *ngIf="this.socketService.selectedCommunity['image'] ==null || this.socketService.selectedCommunity['image'] ==undefined || this.socketService.selectedCommunity['image'] ==''"
                            style="margin-right: 15px; border-radius: 100%;    background: #d9d9d9;margin-left:10px;">
                            {{this.service.getAvatarText(message['admin']['fullName'])}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container__community__chatBox__footer" style="position: relative;">
            <div style="position: relative; width: 100%; padding: 0px 2rem 0rem 0px;">
                <div *ngIf="replyMessageDetail!=null && replyMessageDetail!=undefined" class="reply-main-div">
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div class="reply-content">
                            <div style="display: flex;align-items: center;justify-content: space-between;height: 4rem;">
                                <div>
                                    <div *ngIf="replyMessageDetail.sentBy == 'User'"
                                        style="font-size: 13px;font-weight: 600;">
                                        {{replyMessageDetail['user']['fullName']}}
                                    </div>
                                    <div *ngIf="replyMessageDetail.sentBy == 'Admin'"
                                        style="font-size: 13px;font-weight: 600;">
                                        You
                                    </div>
                                    <div style="display: flex;align-items: center;justify-content: flex-start;">
                                        <img *ngIf="replyMessageDetail.content['type'] == 'Image' || replyMessageDetail.content['type'] == 'Document'"
                                            src="../../../../assets/img/camera.png"
                                            style="height: 1.5em;margin-right: 10px;">
                                        <div *ngIf="replyMessageDetail.content['text'] != null &&replyMessageDetail.content['text'] != undefined &&replyMessageDetail.content['text'] != ''"
                                            class="reply-message-content"
                                            [innerHtml]="URLReplacer(replyMessageDetail.content['text'])"></div>
                                    </div>

                                </div>
                                <div *ngIf="replyMessageDetail.content['type'] == 'Image'">
                                    <img class="reply-image-content" [src]="replyMessageDetail.content['url']" />
                                </div>
                                <div
                                    *ngIf="replyMessageDetail.content['type'] == 'Document' ||replyMessageDetail.content['type'] == 'Audio'">


                                    <img src="../../../../assets/wa-img/pdf.png"
                                        *ngIf="getFileExtension(replyMessageDetail.content.url) == 'pdf'"
                                        class="reply-image-content" />


                                    <img src="../../../../assets/wa-img/doc.png"
                                        *ngIf="getFileExtension(replyMessageDetail.content.url) == 'doc' || getFileExtension(replyMessageDetail.content.url) == 'docx'"
                                        class="reply-image-content" />


                                    <img src="../../../../assets/wa-img/sheets.png"
                                        *ngIf="getFileExtension(replyMessageDetail.content.url) == 'csv' ||getFileExtension(replyMessageDetail.content.url) == 'xls' || getFileExtension(replyMessageDetail.content.url) == 'ods' || getFileExtension(replyMessageDetail.content.url) == 'xlsx'"
                                        class="reply-image-content" />


                                    <img [src]="replyMessageDetail.content.url"
                                        *ngIf="getFileExtension(replyMessageDetail.content.url) == 'jpeg' || getFileExtension(replyMessageDetail.content.url) == 'png' ||getFileExtension(replyMessageDetail.content.url) == 'jpg'"
                                        style="max-height: 2rem;background: none;" />


                                    <audio controls style="width: 25em"
                                        *ngIf="getFileExtension(replyMessageDetail.content.url) == 'mp3' ||getFileExtension(replyMessageDetail.content.url) == 'wav'">
                                        <source [src]="sanitize(replyMessageDetail.content.url)" type="audio/wav" />
                                    </audio>

                                    <img src="../../../../assets/wa-img/video.png"
                                        *ngIf="getFileExtension(replyMessageDetail.content.url) == 'mp4'"
                                        class="reply-image-content" />


                                    <img src="../../../../assets/wa-img/txt.png"
                                        *ngIf="getFileExtension(replyMessageDetail.content.url) == 'txt'"
                                        class="reply-image-content" />

                                </div>

                            </div>

                        </div>
                        <div (click)="unsetReplyMsg()" style="cursor: pointer;">
                            X
                        </div>
                    </div>
                </div>
                <div style="display: flex;align-items: center;">

                    <div style="position: relative; width: 100%; padding: 0px 2rem 0px 2rem;">
                        <quill-editor class="container__community__chatBox__footer__searchBar" [(ngModel)]="message"
                            placeholder="Type Here..." name="message" (keydown)="handleKeyDownEvent($event)"
                            *ngIf="selectedImage == null" (keyup.enter)="sendMessage()">
                        </quill-editor>
                        <div class="mb" style="margin-bottom: 2px">
                            <label for="sendPushNotification" class="label "
                                style="margin-bottom: 0px;margin-right: 1em;font-size: 12px;">Send Push
                                Notification?</label>
                            <input type="checkbox" placeholder="Send Push Notification?" id="sendPushNotification"
                                [checked]="sendPushNotification" (change)="sendPushNotificationChange($event)">
                        </div>

                        <label for="upload" style="display: block;">
                            <img class="pollIcon" src="../../../../assets/svgIcons/community-attachment.svg" />
                        </label>

                        <!-- <label for="upload">
                <img class="uploadIcon" src="../../../../assets/svgIcons/community-attachment.svg" />
            </label>

            <img class="pollIcon" src="../../../../assets/svgIcons/community-poll.svg" />


            <img class="micIcon" src="../../../../assets/svgIcons/community-mic.svg"
                (click)="openAudioRecorderDialog()" /> -->

                        <input type="file" for="upload" id="upload" style="display: none"
                            (change)="openMessageDialog($event)" />
                    </div>
                    <div style="background: #3199D8;padding: 0.7em 1em;border-radius: 10px;" (click)="sendMessage()">

                        <img style="cursor: pointer" src="../../../../assets/svgIcons/community-send.svg" />
                    </div>
                </div>
            </div>
            <div class="container__community__chatBox"
                style="display: flex; align-items: center; justify-content: center"
                *ngIf="socketService.selectedCommunity == null || socketService.selectedCommunity == undefined">
                Select Any chat to interact with user.
            </div>
        </div>