import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListAuditLogComponent } from './list-audit-log/list-audit-log.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: ListAuditLogComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/auditlog' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuditLogRoutingModule { }
