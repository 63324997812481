<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Analysis
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addAdvisoryForm" [formGroup]="addAdvisoryForm">
        <div class="row">
            <div class="col-md-12">
                <label for="title" class="label required">Title</label>
                <input type="text" placeholder="Title" id="title" formControlName="title" class="input-style">
            </div>
        </div>



        <div class="row">
            <div class="col-md-6">
                <label for="type" class="label required" style="margin-bottom: 1rem;">Select Type</label>
                <select id="type" name="type" class="input-style" formControlName="type"
                    (change)="typeSelected($event)">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="Trading">Trading</option>
                    <option value="Chat">Chat</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="publishDate" class="label required">Publish Date</label>
                <input type="date" placeholder="Publish Date" id="publishDate" formControlName="publishDate"
                    class="input-style">
            </div>

        </div>

        <div class="row">
            <div class="col-md-6" style="margin-bottom: 1rem;">
                <label for="franchise" class="label required">Select Plans</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Plans'"
                    [ngModelOptions]="{standalone: true}" [settings]="accessDropdownSettings" [data]="accessToList"
                    [(ngModel)]="selectedAccessList">
                </ng-multiselect-dropdown>
            </div>
           
            <div class="col-md-6">
                <label for="medium" class="label required">Select Medium</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Medium'" formControlName="medium"
                    [settings]="mediumDropdownSettings" [data]="listOfMedium" [(ngModel)]="selectedMedium">
                </ng-multiselect-dropdown>

            </div>
        </div>
        <div class="row mb-3">
           
            <div class="col-md-6" style="align-self: end;" >
                <input type="checkbox" style="font-size:small;margin-right: 1em;" id="sendNotificationOnUpdate" class="radio"
                    value="Free" [checked]="sendNotificationOnUpdate" (change)="sendNotificationOnUpdateChange($event)">
                <label for="sendNotificationOnUpdate" style="font-size:small;margin-right: 1em;">Send Notification on Update?
                    </label>
            </div>
        </div>

        <div *ngIf="selectedType=='Trading' && selectedType!=null" class="row">
            <div class="col-md-6">
                <label for="advisoryType" class="label required" style="margin-bottom: 1rem;">Select Trading
                    Type</label>
                <select id="advisoryType" name="advisoryType" class="input-style" formControlName="advisoryType">
                    <option value="Select Trading Type" selected disabled>Select Trading Type</option>
                    <option value="Swing">Swing</option>
                    <option value="Intraday">Intraday</option>
                    <option value="Positional">Positional</option>
                    <option value="Nifty & BNF Options">Nifty & BNF Options</option>
                    <option value="Equity Options">Equity Options</option>
                    <option value="Short Term Hourly Trades">Short Term Hourly Trades</option>
                    <option value="BTST Trades">BTST Trades</option>
                </select>
            </div>

            <div class="col-md-6">
                <label for="tradeType" class="label required" style="margin-bottom: 1rem;">Select
                    Type</label>
                <select id="tradeType" name="tradeType" class="input-style" formControlName="tradeType">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="Buy">Buy</option>
                    <option value="Sell">Sell</option>
                    <option value="Not Executed">Not Executed</option>
                </select>
            </div>
        </div>

        <div *ngIf="selectedType=='Trading' && selectedType!=null" class="row">
            <div class="col-md-6">
                <label for="entryPoint" class="label required">Entry Point</label>
                <input type="number" placeholder="Entry Point" id="entryPoint" formControlName="entryPoint"
                    class="input-style">
            </div>

            <div class="col-md-6">
                <label for="noOfShares" class="label required">Number of Shares</label>
                <input type="number" placeholder="Number of Shares" id="noOfShares" formControlName="noOfShares"
                    class="input-style">
            </div>
        </div>


        <div *ngIf="selectedType=='Trading' && selectedType!=null" class="row">
            <div class="col-md-6">
                <label for="stopLoss" class="label required">Stop Loss</label>
                <input type="number" placeholder="Stop Loss" id="stopLoss" formControlName="stopLoss"
                    class="input-style">
            </div>

            <div class="col-md-6">
                <label for="advisoryType" class="label" style="margin-bottom: 1rem;">Select Trade
                    Result</label>
                <select id="advisoryType" name="advisoryType" class="input-style" formControlName="tradeResult">
                    <option value="Select Trade Result" selected disabled>Select Trade Result</option>
                    <option value="Target Hit">Target Hit</option>
                    <option value="Stop Loss Hit">Stop Loss Hit</option>
                    <option value="Trailing Stop Loss Hit">Trailing Stop Loss Hit</option>
                </select>
            </div>

        </div>

        <div *ngIf="selectedType=='Trading' && selectedType!=null" class="row">
            <div class="col-md-6">
                <label for="opening" class="label">Opening Stock Price</label>
                <input type="number" placeholder="Opening Stock Price" id="opening" formControlName="opening"
                    class="input-style">
            </div>

            <div class="col-md-6">
                <label for="closing" class="label">Closing Stock Price</label>
                <input type="number" placeholder="Closing Stock Price" id="closing" formControlName="closing"
                    class="input-style">
            </div>
        </div>

        <div *ngIf="selectedType=='Trading' && selectedType!=null" class="row">
            <div class="col-md-10">
                <label for="Target" class="label required">Target</label>
                <input type="number" placeholder="Add" id="Target" style="width: 58%;" class="input-style"
                    formControlName="target">
            </div>
            <div class="col-md-2" style="margin-top:1rem">
                <input type="submit" class="add-update-btn" value="Add" (click)="addTargetList()" />
            </div>
        </div>

        <div *ngIf="selectedType=='Trading' && selectedType!=null" class="row" style="width: 39em;margin-left: .1rem;">
            <div class="tagList" *ngFor="let item of targetList">
                <div class="tag-row" (click)="removeTargetList(item)">
                    <span class="tag">{{item}}</span>
                    <img src="../../../../assets/svgIcons/close.svg" class="close">
                </div>
            </div>
        </div>

       

        <div style="margin-bottom: 1.5rem;max-width: 37em;padding: 0;" class="col-md-12">
            <label for="description" class="label">Description</label>
            <quill-editor class="content-editor" placeholder="Description" name="description" (dragover)="false"
                formControlName="description">
            </quill-editor>
        </div>

        <div *ngIf="selectedType=='Trading'">
            <div *ngFor="let update of listOfUpdates ,let i=index">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <label for="update" class="label" style="margin-top: .8rem;">Update {{i+1}}</label>
                    <div *ngIf="update['delete']" (click)="removeFromToList(i)" style="cursor: pointer;">
                        <mat-icon class="mat-clear-icon" style="font-size: 1rem;">clear</mat-icon>
                    </div>
                </div>
                <quill-editor class="content-editor" placeholder="Update" name="update" (dragover)="false"
                    [formControlName]="update['controller']">
                </quill-editor>
            </div>
        </div>

        <div *ngIf="selectedType=='Trading'" style="display: flex;justify-content: end;">
            <button class="add-update-btn"
                style="float: none;width: 6rem;font-size: 0.9rem;margin-top: 1rem;margin-bottom: 1rem;margin-right: 0;"
                (click)="addUpdateToList()">Add Update</button>
        </div>



        <div class="row">
            <div class="col-md-6">
                <label for="Image" class="label">Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultbannerImage!=null" src="{{defaultbannerImage}}" class="proofImage">
                                <label *ngIf="defaultbannerImage==null" class="imageLabel"> Upload
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultbannerImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayAdhaar($event)" type="file" accept="image/*">
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save" *ngIf="selectedType=='Trading' && selectedType!=null">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addAdvisoryForm.invalid || targetList.length==0  || selectedAccessList.length==0  ,'save-btn':addAdvisoryForm.valid && targetList.length!=0  && selectedAccessList.length!=0}"
                value="{{'Save'}}"
                [disabled]="addAdvisoryForm.invalid  || targetList.length==0  || selectedAccessList.length==0 "
                (click)="addEditAdvisory()" />
        </div>
        <div class="save" *ngIf="selectedType=='Chat'">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addAdvisoryForm.invalid ||selectedAccessList.length==0   ,'save-btn':addAdvisoryForm.valid && selectedAccessList.length!=0}"
                value="{{'Save'}}"
                [disabled]="addAdvisoryForm.invalid ||selectedAccessList.length==0  "
                (click)="addEditAdvisory()" />

        </div>
    </form>
</div>