import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-participant-add-delete-dialog',
  templateUrl: './participant-add-delete-dialog.component.html',
  styleUrls: ['./participant-add-delete-dialog.component.scss']
})
export class ParticipantAddDeleteDialogComponent implements OnInit {

  phone: any;
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  userDetail: any;
  httpHeaders: any;
  listOfParticipant: any = [];
  constructor(
    public dialogRef: MatDialogRef<ParticipantAddDeleteDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('TGV2WebAdminDetails')!);

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetail['accessToken']}`,
    });

  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }


  async addMember() {
    this.isLoading = true;
    try {

      const URL = `${webApi.domain + webApi.endPoint.addMemberInCommunity}`;
      await this.as.postMethodWithToken({ phone: this.phone, communityId: this.data['community']['_id'] }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.dialogRef.close();
          this.closeActivityAtDialog.emit({ result: data['result'] });
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  async deleteMember() {
    this.isLoading = true;
    try {

      const URL = `${webApi.domain + webApi.endPoint.removeMemberFromCommunity}`;
      await this.as.postMethodWithToken({ memberId: this.data['info']['_id'] }, URL, this.httpHeaders).then((data: any) => {
        if (data['success']) {
          this.dialogRef.close();
          this.closeActivityAtDialog.emit({ success: true });
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
